import React, { useEffect, useState } from 'react';
import { GMCRegistrationType } from '../../@types/gmc_registration';
import server from '../Utils';
import { testbookingtype } from '../../@types/testbookingtype';
import { training_details } from '../../@types/training_details';
import validator from 'validator';
import Loader from './Loader';

const UserProgressBar = () => {
  let projectid: string = JSON.parse(localStorage.getItem('user_id') || '{}');
  let id = +projectid;
  const [percentage, setPercentage] = useState<number>(0);
  const [gmc_registrations, setGMCRegistrations] = useState<Array<GMCRegistrationType>>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [bookingDate, setBookingDate] = useState<string>('');
  const [plab1TestDate, setPlab1TestDate] = useState<string>('');
  const [languageResultDate, setLanguageResultDate] = useState<string>('');
  const [resultStatus, setResultStatus] = useState<number>(0);
  const [plab1ResultDate, setPlab1ResultDate] = useState<string>('');
  const [plab2TestDate, setPlab2TestDate] = useState<string>('');
  const [plab2ResultDate, setPlab2ResultDate] = useState<string>('');
  const [gmcLicenseStatus, setGMCLicenseStatus] = useState<number>(0);
  const [cvReview, setCVReview] = useState<number>(0);
  const [applyForJobs, setApplyForJobs] = useState<number>(0);
  const [jobOfferDate, setJobOfferDate] = useState<string>('');
  const [visaIssuedDate, setVisaIssuedDate] = useState<string>('');
  const [onboarding_exists, setOnboardExists] = useState<number>(0);
  const [test_bookings, setTestBooking] = useState<Array<testbookingtype>>([]);
  const [trainings, setTraining] = useState<Array<training_details>>([]);
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState<string>('');
  const [current_sub_stage,setCurrentSubStage]=useState<string>('');
  
  
  
  useEffect(() => {

  
   
    const fetchData = async () => {
      const isBeforeCurrentDate = (dateString: string) => {
        const date = new Date(dateString);
        const currentDate = new Date();
        console.log('currentDate',currentDate)
        console.log('dateString',dateString)
        return date < currentDate; // Compares if the date is before the current date
      };
    //   const date = new Date();
    // const year = date.getFullYear();
    // const month = String(date.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-based.
    // const day = String(date.getDate()).padStart(2, '0');

    // let current_date= year+"-"+month+"-"+day;

      const substagearray:number[] =[];
      let projectid: string = JSON.parse(localStorage.getItem('user_id') || '{}');
      let user_id = +projectid;
  
      
      try {
        const onboardingResponse = await server.get(`/onboarding_exists/${id}`);
        const trainingResponse = await server.get(`/training/${id}`);
        const testBookingsResponse = await server.get(`/test_bookings/${id}`);
        const gmcResponse = await server.get(`/gmc_registration/${id}`);
       
        setTraining(trainingResponse.data.training);
        setTestBooking(testBookingsResponse.data.test_bookings);
        setGMCRegistrations(gmcResponse.data.gmc_registration);
        if (onboardingResponse.data.code === 200) {
          // setUserSubStage(2);
          substagearray.push(2);  
          setPercentage(10);
          getUserDetails(user_id)

        } else {
          // setUserSubStage(1);
          substagearray.push(1);  
          setPercentage(5);
          getUserDetails(user_id)
        }

        if (trainingResponse.data.training.length > 0 && trainingResponse.data.training[0].start_date !== null) {
          setStartDate(trainingResponse.data.training[0].start_date);
          // setUserSubStage(3);
          substagearray.push(3);  
           setPercentage(15);
           getUserDetails(user_id)
        }

        if (testBookingsResponse.data.test_bookings.length > 0) {
          const latestTestBooking = testBookingsResponse.data.test_bookings[testBookingsResponse.data.test_bookings.length - 1];

          if (latestTestBooking.service_category_type === 1 && latestTestBooking.booking_date !== null) {
            setBookingDate(latestTestBooking.booking_date);
            // setUserSubStage(4);
            substagearray.push(4);  
             setPercentage(20);
             getUserDetails(user_id)
          } 
           if (latestTestBooking.service_category_type === 1 && latestTestBooking.language_test_date !== null && isBeforeCurrentDate(latestTestBooking.test_date)) {
            // setUserSubStage(5);
            substagearray.push(5);  
           setPercentage(25);
           getUserDetails(user_id)

          } 
           if (latestTestBooking.service_category_type === 1 && latestTestBooking.language_result_date !== null && isBeforeCurrentDate(latestTestBooking.language_result_date)) {
            setLanguageResultDate(latestTestBooking.language_result_date);
            // setUserSubStage(6);
            substagearray.push(6);  
             setPercentage(30);
             getUserDetails(user_id)
          } 
           if (latestTestBooking.service_category_type === 1 && latestTestBooking.result_status === 2) {
            setResultStatus(latestTestBooking.result_status);
            // setUserSubStage(7);
            substagearray.push(7);  
             setPercentage(35);
             getUserDetails(user_id)
          } 
           if (latestTestBooking.service_category_type === 2 && latestTestBooking.booking_date !== null) {
            setBookingDate(latestTestBooking.booking_date);
            // setUserSubStage(8);
            substagearray.push(8);  
            setPercentage(40);
            getUserDetails(user_id)
          } 
           if (latestTestBooking.service_category_type === 2 && latestTestBooking.test_date !== null && isBeforeCurrentDate(latestTestBooking.test_date)) {
            
            setPlab1TestDate(latestTestBooking.plab1_test_date);
            // setUserSubStage(9);
            substagearray.push(9);  
             setPercentage(45);
             getUserDetails(user_id)
             
          } 
           if (latestTestBooking.service_category_type === 2 && latestTestBooking.plab1_result_date !== null && isBeforeCurrentDate(latestTestBooking.plab1_result_date)) {
            setPlab1ResultDate(latestTestBooking.plab1_result_date);
            // setUserSubStage(10);
            substagearray.push(10);  
             setPercentage(50);
             getUserDetails(user_id)
          } 
           if (latestTestBooking.service_category_type === 2 && latestTestBooking.result_status === 2) {
            setResultStatus(latestTestBooking.result_status);
            // setUserSubStage(11);
            substagearray.push(11);  
             setPercentage(55);
             getUserDetails(user_id)
          } 
           if (latestTestBooking.service_category_type === 3 && latestTestBooking.booking_date !== null) {
            setBookingDate(latestTestBooking.booking_date);
            // setUserSubStage(12);
            substagearray.push(12);  
             setPercentage(60);
             getUserDetails(user_id)
          } 
           if (latestTestBooking.service_category_type === 3 && latestTestBooking.test_date !== null && isBeforeCurrentDate(latestTestBooking.test_date)) {
            setPlab2TestDate(latestTestBooking.plab2_test_date);
            // setUserSubStage(13);
            substagearray.push(13);  
             setPercentage(65);
             getUserDetails(user_id)
          }
           if (latestTestBooking.service_category_type === 3 && latestTestBooking.plab2_result_date !== null && isBeforeCurrentDate(latestTestBooking.plab2_result_date)) {
            setPlab2ResultDate(latestTestBooking.plab2_result_date);
            // setUserSubStage(14);
            substagearray.push(14);  
            setPercentage(70);
            getUserDetails(user_id)
          }
        
        }


        if (gmcResponse.data.gmc_registration.length > 0) {
          const latestGMCRegistration = gmcResponse.data.gmc_registration[gmcResponse.data.gmc_registration.length - 1];

          if (latestGMCRegistration.gmc_license_status === 3) {
            setGMCLicenseStatus(latestGMCRegistration.gmc_license_status);
            // setUserSubStage(15);
            substagearray.push(15);  
             setPercentage(75);
             getUserDetails(user_id)
          } 
           if (latestGMCRegistration.gmc_license_status === 4) {
            setGMCLicenseStatus(latestGMCRegistration.gmc_license_status);
            // setUserSubStage(16);
            substagearray.push(16);  
             setPercentage(80);
             getUserDetails(user_id)
          } 
           if (latestGMCRegistration.cv_review === 1) {
            setCVReview(latestGMCRegistration.cv_review);
            // setUserSubStage(17);
            substagearray.push(17);  
             setPercentage(85);
             getUserDetails(user_id)
          } 
           if (latestGMCRegistration.apply_for_jobs === 1) {
            setApplyForJobs(latestGMCRegistration.apply_for_jobs);
            // setUserSubStage(18);
             setPercentage(90);
             getUserDetails(user_id)
          } 
           if (latestGMCRegistration.job_offer_date) {
            setJobOfferDate(latestGMCRegistration.job_offer_date);
            // setUserSubStage(19);
            substagearray.push(19);  
             setPercentage(95);
             getUserDetails(user_id)
          }  if (latestGMCRegistration.visa_issued_date) {
            setVisaIssuedDate(latestGMCRegistration.visa_issued_date);
            substagearray.push(20);  
            // setUserSubStage(20);

             setPercentage(100);
             getUserDetails(user_id)
          }
          
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      var highest=0;
      for(var i=0;i<substagearray.length;i++){
        if(substagearray[i]>highest){
          highest=substagearray[i];
        }
      }
      setUserSubStage(highest);
    };

      
    const  getUserDetails=($id:number)=>{
      server.get(`/operation_user_view/${id}`)
      .then((response)=>{
       
          setCurrentSubStage(response.data.user.current_sub_stage_name);
         
  
         
         
          
    })
    }
  
    const setUserSubStage=(id:number)=>{
     
      let projectid: string = JSON.parse(localStorage.getItem('user_id') || '{}');
      let user_id = +projectid;
  
      server.post(`/user_sub_stage/${user_id}/${id}`)
      .then((response)=>{
        console.log(response.data)
      })
    }
    fetchData();
    let projectid: string = JSON.parse(localStorage.getItem('user_id') || '{}');
      let user_id = +projectid;
   

  }, [id]);

  if (loading) {
    return <Loader />;
  }
  return (
    
    <div className="progress p-0">
      <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${percentage}%`,backgroundColor:"#233974"}}>
       <b> {percentage}%</b>
      </div>
      
      

    
    
    </div>
  );
  
};

export default UserProgressBar;
