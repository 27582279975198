import React,{useContext, useEffect, useState} from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import { LoginContext } from '../../context/actions/LoginAction';
import Onboarding from '../../components/Onboarding/Onboarding';
import { Link } from 'react-router-dom';
import './index.css';
import Loader from './Loader';
import server from '../../components/Utils';

const SuperAdminSecondaryDashboard = () => {
    const {loginstate} = useContext(LoginContext);
    var isloggedIn=localStorage.getItem("isLoggedIn");
    const [loading, setLoading] = useState(true);
    const [onboarding_number,setOnboardingNumber]=useState<number>(0);
    const [training_number,setTrainingNumber]=useState<number>(0);
    const [test_booking_number,setTestBookingNumber]=useState<number>(0);
    const [registration_number,setRegistrationNumber]=useState<number>(0);
    const [courses_allocated,setCoursesAllocated]=useState<number>(0);
    const [training_and_subscription_number,setTrainingAndSubscriptionNumber]=useState<number>(0);
    const [travelandaccomdation_number,setTravelAndAccomdattion]=useState<number>(0)
    const [portfolio_number,setPortfolioNumber]=useState<number>(0);
    const [tier4visa_number,setTier4VisaNumber]=useState<number>(0);
    const [gmc_registration_number,setGmcRegistrationNumber]=useState<number>(0)
    const [cab_number,setCabNumber]=useState<number>(0)
    let role =localStorage.getItem('role');
    
    useEffect(() => {
        setLoading(true)
        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let id = +projectid;
        server.get(`/get_onboarding_number/${id}`)
        .then((response)=>{
            setOnboardingNumber(response.data.onboarding_number);
        })
        server.get(`/get_training_number/${id}`)
        .then((response)=>{
            setTrainingNumber(response.data.training_number);
        })
        server.get(`/get_test_booking_number/${id}`)
        .then((response)=>{
            setTestBookingNumber(response.data.test_booking);
        })
        server.get(`/get_registrations/${id}`)
        .then((response)=>{
            setRegistrationNumber(response.data.registration_numbers);
        })
        server.get(`/courses_allocated_number/${id}`)
        .then((response)=>{
            setCoursesAllocated(response.data.courses_allocated_number);
        })
        server.get(`/training_and_subscriptions_number/${id}`)
        .then((response)=>{
            setTrainingAndSubscriptionNumber(response.data.training_and_subscriptions_number);
        })
        server.get(`/get_travelandaccomdation_number/${id}`)
        .then((response)=>{setTravelAndAccomdattion(response.data.travelandaccomdation_number)})

        server.get(`/get_portfolio_number/${id}`)
        .then((response)=>{
            setPortfolioNumber(response.data.portfolio_number)
        })
        server.get(`/get_tier4visa_number/${id}`)
        .then((response)=>{
            setTier4VisaNumber(response.data.tier4visa_number)
        })
        server.get(`/get_gmc_registration_number/${id}`)
        .then((response)=>{
            setGmcRegistrationNumber(response.data.gmc_registration_number)
        })
        server.get(`/get_cab_number/${id}`)
        .then((response)=>{
            setCabNumber(response.data.cab_number)
        })
        setTimeout(() => setLoading(false), 3000); // wait 3 seconds before "loading" is done
      }, []);
    if(isloggedIn!=="true")
    {
        window.location.href="/"
    }

    const redirectLink=(link:string)=>{
        window.location.href=link;
    }
    
    if (loading) {
        return <Loader />;
      }
      
  return (
    <div className='continer-fluid'>
        <div className='d-flex'>
            <SideBar/>
            <div className='headerbar'>
            <Header/>
            {/* Content Row */}

          <div className="d-sm-flex align-items-center justify-content-between mb-4">
  
         </div>

         {role==="1"|| role==="2"||role==="3"||role==="5"||role==="6"?<div className="row px-2">
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<Link to="/onboardinglist" className='nav-link'> <div className="card border-left-primary shadow h-100 py-2">
    <div className="card-body">
        <div className="row no-gutters text-left px-3">
        <div className="col mr-2">
            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
            Onboarding</div>
            <div className="h5 mb-0 font-weight-bold text-gray-800">{onboarding_number}</div>
            </div>
        <div className="col-auto">
            <i className="fa fa-user-plus fa-2x text-gray-300" />
        </div>
        </div>
    </div>
    </div></Link>
</div>
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
  <Link to={'/traininglist'} className='nav-link'>  <div className="card border-left-success shadow h-100 py-2">
    <div className="card-body">
        <div className="row no-gutters text-left px-3">
        <div className="col mr-2">
            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
            Training</div>
            <div className="h5 mb-0 font-weight-bold text-gray-800">{training_number}</div>
        </div>
        <div className="col-auto">
            <i className="fa fa-users fa-2x text-gray-300" />
        </div>
        </div>
    </div>
    </div></Link>
</div>
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
    <div className="card border-left-info shadow h-100 py-2">
  <Link to={'/bookinglist'} className='nav-link'>  <div className="card-body">
        <div className="row no-gutters text-left px-3  ">
        <div className="col mr-2">
            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Test Booking
            </div>
            
            <div className="col-auto">
                <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{test_booking_number}</div>
        
            
            </div>
        </div>
         <div className="col-auto">
            <i className="fa fa-address-card fa-2x text-gray-300" />
        </div>
       
        </div>
    </div></Link>
    </div>
</div>
{/* Pending Requests Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
   <Link to={"/registration_list"} className='nav-link'><div className="card border-left-warning shadow h-100 py-2">
    <div className="card-body">
        <div className="row no-gutters text-left px-3">
        <div className="col mr-2">
            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
            Registrations</div>
            <div className="h5 mb-0 font-weight-bold text-gray-800">{registration_number}</div>
        </div>
        <div className="col-auto">
            <i className="fa fa-check-circle fa-2x text-gray-300" />
        </div>
        </div>
    </div>
    </div></Link> 
</div>
</div>:""}
    {role==="1"|| role==="2"||role==="3"||role==="5"||role==="6"?<div className="row px-2">

 {/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
  <Link to={"/courses_allocated_list"}  className='nav-link' ><div className="card border-left-success shadow h-100 py-2">
    <div className="card-body">
        <div className="row no-gutters text-left px-3">
        <div className="col mr-2">
            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
           Courses Allocated</div>
            <div className="h5 mb-0 font-weight-bold text-gray-800">{courses_allocated}</div>
        </div>
        <div className="col-auto">
            <i className="fa fa-puzzle-piece fa-2x text-gray-300" />
        </div>
        
        </div>
    </div>
    </div></Link>
</div>   
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<Link to={'/training_and_subscription_list'} className='nav-link'> <div className="card border-left-primary shadow h-100 py-2">
    <div className="card-body">
        <div className="row no-gutters text-left px-3">
        <div className="col mr-2">
            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
            Training and Subscriptions</div>
            <div className="h5 mb-0 font-weight-bold text-gray-800">{training_and_subscription_number}</div>
        </div>
        <div className="col-auto">
            <i className="fa fa-cart-arrow-down fa-2x text-gray-300" />
        </div>
        </div>
    </div>
    </div></Link>
</div>

{/* Pending Requests Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<Link to={"/travel_and_accomdation_list"} className='nav-link' ><div className="card border-left-warning shadow h-100 py-2">
    
    <div className="card-body">
        <div className="row no-gutters align-items-center px-3">
        <div className="col mr-2 text-left">
            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
           Travelling & Accomdation</div>
            <div className="h5 mb-0 font-weight-bold text-gray-800">{travelandaccomdation_number}</div>
        </div>
        <div className="col-auto">
            <i className="fas fa-comments fa-2x text-gray-300" />
        </div>
        </div>
    </div>
    </div></Link>
</div>
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
    <Link to={"/portfolio_list"} className='nav-link'><div className="card border-left-info shadow h-100 py-2">
    <div className="card-body">
        <div className="row no-gutters text-left px-3  ">
        <div className="col mr-2">
            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Portfolio
            </div>
            <div className="h5 mb-0 font-weight-bold text-gray-800">{portfolio_number}</div>
            
        </div>
        <div className="col-auto">
            <i className="fa fa-cubes fa-2x text-gray-300" />
        </div>
        </div>
    </div>
    </div></Link>
</div>

</div> :""} 
{role==="1"|| role==="2"||role==="3"||role==="5"||role==="6"?<div className="row px-2">

 {/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
  <Link to={"/tier4visa_list"}  className='nav-link' ><div className="card border-left-success shadow h-100 py-2">
    <div className="card-body">
        <div className="row no-gutters text-left px-3">
        <div className="col mr-2">
            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
           Tier 4 Visa</div>
            <div className="h5 mb-0 font-weight-bold text-gray-800">{tier4visa_number}</div>
        </div>
        <div className="col-auto">
            <i className="fa fa-puzzle-piece fa-2x text-gray-300" />
        </div>
        
        </div>
    </div>
    </div></Link>
</div>   



{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
    <Link to={"/gmc_registration_list"} className='nav-link'><div className="card border-left-info shadow h-100 py-2">
    <div className="card-body">
        <div className="row no-gutters text-left px-3  ">
        <div className="col mr-2">
            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">GMC Registration
            </div>
            <div className="h5 mb-0 font-weight-bold text-gray-800">{gmc_registration_number}</div>
            
        </div>
        <div className="col-auto">
            <i className="fa fa-cubes fa-2x text-gray-300" />
        </div>
        </div>
    </div>
    </div></Link>
</div>
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<Link to={'/cablist'} className='nav-link'> <div className="card border-left-primary shadow h-100 py-2">
    <div className="card-body">
        <div className="row no-gutters text-left px-3">
        <div className="col mr-2">
            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
            Cab Lists</div>
            <div className="h5 mb-0 font-weight-bold text-gray-800">{cab_number}</div>
        </div>
        <div className="col-auto">
            <i className="fa fa-cart-arrow-down fa-2x text-gray-300" />
        </div>
        </div>
    </div>
    </div></Link>
</div>

</div> :""}        
            </div>
            {/* header bar ends here */}
           
      

        </div>
    </div>
  )
}



export default SuperAdminSecondaryDashboard