import React,{useState,useEffect,useRef,createRef} from 'react';
import { ServiceData } from '../../@types/servicetype';

import axios  from "axios";
import { ProjectData } from '../../@types/projecttype';
import { ProductData } from '../../@types/producttype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import server from '../Utils';
import Loader from './Loader';

const Addvendor = () => {
  const [services,setServices]=useState<Array<ServiceData>>([])
  const [products,setProducts]=useState<Array<ProductData>>([])
  const [service_categories,setServiceCategories]=useState<Array<ServiceCategoryType>>([])
  const [project_id,setProjectId]=useState<number>(0);
  const [product_id,setproductId]=useState<number>(0);
  const [vendor_name,setVendorName]=useState<string>("");
  const [contact_person_name,setContactPersonName]=useState<string>('');
  const [service_id,setServicesId]=useState<number>(0);
  const [contact_number,setContactNumber]=useState<string>('');
  const [contact_email,setContactEmail]=useState<string>('');
  const [line1,setLine1] =useState<string>('');
  const [line2,setLine2] =useState<string>('');
  const [city,setCity]=useState<string>('');
  const [state,setState]=useState<string>('');
  const [country,setCountry]=useState<string>('');
  const [pin,setPin]=useState<string>('');
  const[country_code,setCountryCode]=useState<number>(91)
  const [service_count,setSereviceCount]=useState<number>(1);
 const service_category_ref=useRef<Array<any>>([]);
 const service_ref=useRef<Array<any>>([]);
 const [services1,setServices1]=useState<Array<ServiceData>>([])
 const [services2,setServices2]=useState<Array<ServiceData>>([])
 const [services3,setServices3]=useState<Array<ServiceData>>([])
 const [services4,setServices4]=useState<Array<ServiceData>>([])
 const [services5,setServices5]=useState<Array<ServiceData>>([])
 const [services6,setServices6]=useState<Array<ServiceData>>([])
 const [services7,setServices7]=useState<Array<ServiceData>>([])
 const [services8,setServices8]=useState<Array<ServiceData>>([])
 const [services9,setServices9]=useState<Array<ServiceData>>([])
 const [services10,setServices10]=useState<Array<ServiceData>>([])
 const [services11,setServices11]=useState<Array<ServiceData>>([])
 const [services12,setServices12]=useState<Array<ServiceData>>([])
 const [services13,setServices13]=useState<Array<ServiceData>>([])
 const [services14,setServices14]=useState<Array<ServiceData>>([])
 const [services15,setServices15]=useState<Array<ServiceData>>([])
 const [services16,setServices16]=useState<Array<ServiceData>>([])
 const [services17,setServices17]=useState<Array<ServiceData>>([])
 const [services18,setServices18]=useState<Array<ServiceData>>([])
 const [services19,setServices19]=useState<Array<ServiceData>>([])
 const [services20,setServices20]=useState<Array<ServiceData>>([])
 const [loading, setLoading] = useState(false);

 let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
    useEffect(() => {
      
      let id= +projectid
      setProjectId(id)
      server.get(`/get_product/${id}`)
      .then((response)=>{
         
          if(response.data.product.length>0){
          setProducts(response.data.product)
          }else{
              setProducts([]);
          }
      })
     
        server.get(`/get_services/${id}`)
        .then((response)=>{
            
            setServices(response.data.services)
    })
   
    
      }, []);


const servicesSet=(i:number,value:number)=>{
  server.get(`/service_on_category/${value}`)
  .then((response)=>{
    if(i===1){
      setServices1(response.data.services)
      
    }else if(i===2){
      setServices2(response.data.services)
    }else if(i===3){
      setServices3(response.data.services)
    } else if(i===4){
      setServices4(response.data.services)
    }else if(i===5){
      setServices5(response.data.services)
    }else if(i===6){
      setServices6(response.data.services)
    }else if(i===7){
      setServices7(response.data.services)
    }
    else if(i===8){
      setServices8(response.data.services)
    }
    else if(i===9){
      setServices9(response.data.services)
    }
    else if(i===10){
      setServices10(response.data.services)
    }
    else if(i===11){
      setServices11(response.data.services)
    }
    else if(i===12){
      setServices12(response.data.services)
    }else if(i===13){
      setServices13(response.data.services)
    }
    else if(i===14){
      setServices14(response.data.services)
    }else if(i===15){
      setServices15(response.data.services)
    }else if(i===16){
      setServices16(response.data.services)
    }else if(i===17){
      setServices17(response.data.services)
    }else if(i===18){
      setServices18(response.data.services)
    }else if(i===19){
      setServices19(response.data.services)
    }else if(i===20){
      setServices20(response.data.services)
    }


  })
}


      let service_array=[];

      for(let i=1;i<=service_count;i++){
        service_category_ref.current[i]=createRef();
       service_array.push( <div id={"service"+i} className='row px-4 my-3'>
       {product_id!==0 && service_categories && service_categories.length>0?
       <div className='col-md-2'>
        <label>Service Category {i}</label>
        <select className={'form-control service_category_id'} id={'service_category_id'+i} ref={service_category_ref.current[i]} name={"service_category_id"+i} onChange={(e)=>servicesSet(i,parseInt(e.target.value))} >
          <option value="0">Select Service Categories</option>
        {service_categories.map((service_categories,index)=>(
          <option value={service_categories.id}>{service_categories.service_category_name}</option>
        ))}
        </select>
       </div>:""}

       {product_id!==0 && services && services.length>0?<div className='col-md-2'> 
       <label>Service  {i}</label>
        <select className='form-control service_id' name={'service_id'+i} id={"service_id"+i} ref={service_ref.current[i]}  onChange={(e)=>{setServicesId(parseInt(e.target.value))}} >
          <option value={0}>Choose Service Name</option>
         
          {i===1?services1.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===2?services2.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===3?services3.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===4?services4.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===5?services5.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===6?services6.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===7?services7.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===8?services8.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===9?services9.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===10?services10.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===11?services11.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===12?services12.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===13?services13.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===14?services14.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===15?services15.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===16?services16.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===17?services17.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===18?services18.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===19?services19.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):i===20?services20.map((service)=>(
            <option value={service.id}>{service.service_name}</option>
          )):""}
          
        </select>
        
       
       </div>:""}
       <div className='col-md-3 mt-4'>
       
        &nbsp;&nbsp;&nbsp;&nbsp;{i!==1?<button type="button" onClick={(e)=>sub(i)} className='btn btn-outline-danger'>-</button>:""}
       </div>
       </div>
      )
      }


      const add=(id:number)=>{
        console.log(service_category_ref.current[id].value)
        // var service_category=document.getElementById('service_category'+id) as HTMLInputElement
         
        setSereviceCount(service_count+1)
       
        
    }
        const sub=(id:number)=>{
            var x =document.getElementById('service'+id) as HTMLInputElement;
           
            x.remove();
          
            
        }
const validateServiceCategoryAndService=()=>{
  const service_categories= document.getElementsByClassName('service_category_id') as HTMLCollectionOf<HTMLInputElement>;
  const service_id=document.getElementsByClassName('service_id') as HTMLCollectionOf<HTMLInputElement>

  for( let i=0;i<service_categories.length;i++){
    if(service_categories[i].value==='0'){
      toast('Select the category'+(i-1))
      return false;
    }else if(service_id[i].value==='0'){
      toast('select the service'+i)
      return false;
    }else{
      return true;
    }
  }

}

     const addVendor=(e:React.FormEvent<HTMLFormElement>) =>{
      e.preventDefault();
     
      if(product_id===0){
        toast('Select Product')
      }else if(vendor_name===""||vendor_name===null){
        toast("Please Enter vendor Name")
      }else if(contact_number===""||contact_number===null){
        toast("Please enter Contact Number ")
      }else if(line1===""|| line2===null){
        toast("Enter Address Line One")
      }else if(city===""||city===null){
        toast('Enter city')
      }else if(state===""||state===null){
        toast('Enter State')
      }else if(country===""||country===null){
        toast('Enter Country ')
      }else if(validateServiceCategoryAndService()===false){
        console.log("not validated");
      }
      else{
        var form = document.getElementById('add_vendor_form') as HTMLFormElement
        var formData = new FormData(form);
        server.post(`/add_vendor`,formData)
        .then((response)=>{
          if(response.data.code===200){
         toast(response.data.message)
         setTimeout(function () { window.history.back(); }, 1000);
        }else{
          toast.error(response.data.message);
        }})
      }
         }  
      
         if (loading) {
          return <Loader />;
        } 

        const getServiceCategories=(id:string)=>{
          setproductId(parseInt(id))
          server.get(`/get_service_categories_all/${project_id}/${id}`)
          .then((response)=>{
             
              if(response.data.service_categories.length>0){
                setServiceCategories(response.data.service_categories)
              }else{
                setServiceCategories([]);
              }
          })
        }
  return (
    <form id="add_vendor_form"  onSubmit={addVendor} className='w-100 mt-5' >
         <h2 className='text-danger text-center mr-5 pr-5'>Add Vendor</h2>
    <div className='row my-4 px-4'>
        <input type="hidden" name="project_id" id="project_id" value={project_id} />
          <div className='col-md-2'>
            <label>Products</label>
            <select  className={"form-control"} value={product_id} onChange={(e)=>getServiceCategories(e.target.value)} id="product_id" name="product_id" >
            <option value="0">Select Product</option>
              {products.map((product,index)=>(
                <option value={product.id}>{product.product_name}</option>
              ))}
            </select>
          </div>
           
           </div>
           <input type="hidden" name="service_count" id="service_count" value={service_count}  />
         <div className='row px-4'>
         <div className='col-md-2'>
            <label>Vendor Name</label><br/>
                <input type="text" className='form-control' name="vendor_name"  id="vendor_name" value={vendor_name} onChange={(e)=>setVendorName(e.target.value)} placeholder='Vendor Name' required />
           </div>
          
        
          
           <div className='col-md-4'>
            <div className='row mt-1'>

           <label>Contact Number</label>
           <div className='col-md-3'>
           <select  className='form-control'  name="country_code" id="country_code"  value={country_code} onChange={(e)=>setCountryCode(parseInt(e.target.value))} required >
            <option value="213">+213</option>
            <option value="376">+376</option>
            <option value="244">+244</option>
            <option value="1264">+1264</option>
            <option value="1268">+1268</option>
            <option value="54">+54</option>
            <option value="374">+374</option>
            <option value="297">+297</option>
            <option value="61">+61</option>
            <option value="43">+43</option>
            <option value="994">+994</option>
            <option value="1242">+1242</option>
            <option value="973">+973</option>
            <option value="880">+880</option>
            <option value="1246">+1246</option>
            <option value="375">+375</option>
            <option value="32">+32</option>
            <option value="501">+501</option>
            <option value="229">+229</option>
            <option value="1441">+1441</option>
            <option value="975">+975</option>
            <option value="591">+591</option>
            <option value="387">+387</option>
            <option value="267">+267</option>
            <option value="55">+55</option>
            <option value="673">+673</option>
            <option value="359">+359</option>
            <option value="226">+226</option>
            <option value="257">+257</option>
            <option value="855">+855</option>
            <option value="237">+237</option>
            <option value="1">+1</option>
            <option value="238">+238</option>
            <option value="1345">+1345</option>
            <option value="236">+236</option>
            <option value="56">+56</option>
            <option value="86">+86</option>
            <option value="57">+57</option>
            <option value="269">+269</option>
            <option value="242">+242</option>
            <option value="682">+682</option>
            <option value="506">+506</option>
            <option value="385">+385</option>
            <option value="53">+53</option>
            <option value="90392">+90392</option>
            <option value="357">+357</option>
            <option value="42">+42</option>
            <option value="45">+45</option>
            <option value="253">+253</option>
            <option value="1809">+1809</option>
            <option value="1809">+1809</option>
            <option value="593">+593</option>
            <option value="20">+20</option>
            <option value="503">+503</option>
            <option value="240">+240</option>
            <option value="291">+291</option>
            <option value="372">+372</option>
            <option value="251">+251</option>
            <option value="500">+500</option>
            <option value="298">+298</option>
            <option value="679">+679</option>
            <option value="358">+358</option>
            <option value="33">+33</option>
            <option value="594">+594</option>
            <option value="689">+689</option>
            <option value="241">+241</option>
            <option value="220">+220</option>
            <option value="7880">+7880</option>
            <option value="49">+49</option>
            <option value="233">+233</option>
            <option value="350">+350</option>
            <option value="30">+30</option>
            <option value="299">+299</option>
            <option value="1473">+1473</option>
            <option value="590">+590</option>
            <option value="671">+671</option>
            <option value="502">+502</option>
            <option value="224">+224</option>
            <option value="245">+245</option>
            <option value="592">+592</option>
            <option value="509">+509</option>
            <option value="504">+504</option>
            <option value="852">+852</option>
            <option value="36">+36</option>
            <option value="354">+354</option>
            <option value="91">+91</option>
            <option value="62">+62</option>
            <option value="98">+98</option>
            <option value="964">+964</option>
            <option value="353">+353</option>
            <option value="972">+972</option>
            <option value="39">+39</option>
            <option value="1876">+1876</option>
            <option value="81">+81</option>
            <option value="962">+962</option>
            <option value="7">+7</option>
            <option value="254">+254</option>
            <option value="686">+686</option>
            <option value="850">+850</option>
            <option value="82">+82</option>
            <option value="965">+965</option>
            <option  value="996">+996</option>
            <option  value="856">+856</option>
            <option  value="371">+371</option>
            <option  value="961">+961</option>
            <option  value="266">+266</option>
            <option  value="231">+231</option>
            <option  value="218">+218</option>
            <option  value="417">+417</option>
            <option  value="370">+370</option>
            <option  value="352">+352</option>
            <option  value="853">+853</option>
            <option  value="389">+389</option>
            <option  value="261">+261</option>
            <option  value="265">+265</option>
            <option  value="60">+60</option>
            <option  value="960">+960</option>
            <option  value="223">+223</option>
            <option  value="356">+356</option>
            <option  value="692">+692</option>
            <option  value="596">+596</option>
            <option  value="222">+222</option>
            <option  value="269">+269</option>
            <option  value="52">+52</option>
            <option  value="691">+691</option>
            <option  value="373">+373</option>
            <option  value="377">+377</option>
            <option  value="976">+976</option>
            <option  value="1664">+1664</option>
            <option  value="212">+212</option>
            <option  value="258">+258</option>
            <option  value="95">+95</option>
            <option  value="264">+264</option>
            <option  value="674">+674</option>
            <option  value="977">+977</option>
            <option  value="31">+31</option>
            <option  value="687">+687</option>
            <option  value="64">+64</option>
            <option  value="505">+505</option>
            <option  value="227">+227</option>
            <option  value="234">+234</option>
            <option  value="683">+683</option>
            <option  value="672">+672</option>
            <option  value="670">+670</option>
            <option  value="47">+47</option>
            <option  value="968">+968</option>
            <option  value="680">+680</option>
            <option  value="507">+507</option>
            <option  value="675">+675</option>
            <option  value="595">+595</option>
            <option  value="51">+51</option>
            <option  value="63">+63</option>
            <option  value="48">+48</option>
            <option  value="351">+351</option>
            <option  value="1787">+1787</option>
            <option  value="974">+974</option>
            <option  value="262">+262</option>
            <option  value="40">+40</option>
            <option  value="7">+7</option>
            <option  value="250">+250</option>
            <option  value="378">+378</option>
            <option  value="239">+239</option>
            <option  value="966">+966</option>
            <option  value="221">+221</option>
            <option  value="381">+381</option>
            <option  value="248">+248</option>
            <option  value="232">+232</option>
            <option  value="65">+65</option>
            <option  value="421">+421</option>
            <option  value="386">+386</option>
            <option  value="677">+677</option>
            <option  value="252">+252</option>
            <option  value="27">+27</option>
            <option  value="34">+34</option>
            <option  value="94">+94</option>
            <option  value="290">+290</option>
            <option  value="1869">+1869</option>
            <option  value="1758">+1758</option>
            <option  value="249">+249</option>
            <option  value="597">+597</option>
            <option  value="268">+268</option>
            <option  value="46">+46</option>
            <option  value="41">+41</option>
            <option  value="963">+963</option>
            <option  value="886">+886</option>
            <option  value="7">+7</option>
            <option  value="66">+66</option>
            <option  value="228">+228</option>
            <option  value="676">+676</option>
            <option  value="1868">+1868</option>
            <option  value="216">+216</option>
            <option  value="90">+90</option>
            <option  value="7">+7</option>
            <option  value="993">+993</option>
            <option  value="1649">+1649</option>
            <option  value="688">+688</option>
            <option  value="256">+256</option>
            <option  value="44">+44</option>
            <option  value="380">+380</option>
            <option  value="971">+971</option>
            <option  value="598">+598</option>
            <option  value="1">+1</option>
            <option  value="7">+7</option>
            <option  value="678">+678</option>
            <option  value="379">+379</option>
            <option  value="58">+58</option>
            <option  value="84">+84</option>
            <option  value="84">+1284</option>
            <option  value="84">+1340</option>
            <option  value="681">+681</option>
            <option  value="969">+969</option>
            <option  value="967">+967</option>
            <option  value="260">+260</option>
            <option  value="263">+263</option>    
            </select>
            </div>
            <div className='col-md-8'>
           <input type="tel" className='form-control' name="contact_number" value={contact_number} onChange={(e)=>setContactNumber(e.target.value)} id="contact_number" placeholder='Contact Number' required />
           </div>
           </div>
           </div>
           <div className='col-md-3'>
                <label>Email</label>
                <input type="email" className='form-control' name="vendor_email" id="vendor_email" value={contact_email} onChange={(e)=>setContactEmail(e.target.value)}  placeholder='Email' required />  
           </div>
         </div>
         
           <div className=' my-4 px-4'>
           
                
                <div className='w-25'>
                  <label>Address Line 1</label>
                <input type="text" className='form-control' name="line1" id="line1" value={line1} onChange={(e)=>setLine1(e.target.value)}  placeholder='Address Line 1' required />  
                </div>
                <div className='w-25'>
                  <label>Address Line 2</label>
                <input type="text" className='form-control' name="line2" id="line2" value={line2} onChange={(e)=>setLine2(e.target.value)}  placeholder='Address Line 2' required />  
                </div>
                <div className='w-25'>
                  <label>City</label>
                <input type="text" className='form-control' name="city" id="city" value={city} onChange={(e)=>setCity(e.target.value)}  placeholder='City' required />  
                </div>
                <div className='w-25'>
                  <label>State</label>
                <input type="text" className='form-control' name="state" id="state" value={state} onChange={(e)=>setState(e.target.value)}  placeholder='State' required />  
                </div>
                <div className='w-25'>
                <label>Country</label>
                <input type="text"  className='form-control' value={country} name="country" id="country" onChange={(e)=>setCountry(e.target.value)} placeholder='country' />
              </div>
              <div className='w-25'>
              <label>Pin Code</label>
                <input type="text"  className='form-control' value={pin} name="pin" id="pin" onChange={(e)=>setPin(e.target.value)} placeholder="Pincode" />
              </div>
             </div>
            
             {service_array}
             {product_id!==0?<button type="button" className='btn btn-outline-primary ml-4 my-3' onClick={(e)=>add(service_count)}> +  Add Service</button>:""}
             <ToastContainer />
              <div className='text-center mt-5'><button type="submit" className='btn btn-primary w-25 mb-3'>Submit</button></div>
    </form>
  )
}

export default Addvendor