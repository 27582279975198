import React, { useEffect, useState } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import server from '../Utils';
import Loader from './Loader';
import { SubStageData } from '../../@types/substagetype';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GridApi, ColumnApi, ColDef } from 'ag-grid-community';
import { Select, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { ProductData } from '../../@types/producttype';

const { Option } = Select;

const AllSubStages = () => {
  const [substages, setSubStages] = useState<SubStageData[]>([]);
  const [filteredSubStages, setFilteredSubStages] = useState<SubStageData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductData[]>([]);
  const [productId, setProductId] = useState<number>(0);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const [searchText, setSearchText] = useState<string>('');

  let projectid: string = JSON.parse(localStorage.getItem("project_id") || '{}');
  let id: number = +projectid;

  useEffect(() => {
    setLoading(true);
    Promise.all([
      server.get(`/get_product/${id}`),
      server.get(`/get_sub_stages/${id}`)
    ]).then(([productResponse, subStagesResponse]) => {
      setLoading(false);
      if (productResponse.data.product.length > 0) {
        setProducts(productResponse.data.product);
      } else {
        setProducts([]);
      }
      setSubStages(subStagesResponse.data.substages);
      setFilteredSubStages(subStagesResponse.data.substages);
    }).catch(error => {
      setLoading(false);
      console.error('Error fetching data:', error);
    });
  }, [id]);

  const deleteSubStage = (deleteId: number) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });

    swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        server.delete(`/substage/${deleteId}`)
          .then((response) => {
            setSubStages(response.data.substages);
            setFilteredSubStages(response.data.substages);
            swalWithBootstrapButtons.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success"
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your imaginary file is safe :)",
          icon: "error"
        });
      }
    });
  };

  const displaySubStages = (productId: number) => {
    setProductId(productId);
    server.get(`/getallsubstages/${id}/${productId}`)
      .then((response) => {
        setSubStages(response.data.substages);
        setFilteredSubStages(response.data.substages);
      });
  };

  const columns: ColDef[] = [
    {
      headerName: 'SL No',
      valueGetter: "node.rowIndex + 1",
      sortable: true,
      filter: false,
      // floatingFilter: true,
      flex: 1
    },
    {
      headerName: 'Product Name',
      field: 'product_name',
      sortable: true,
      filter: true,
      floatingFilter: true,
      flex: 1,
      
    },
    {
      headerName: 'Stage Name',
      field: 'stage_name',
      sortable: true,
      filter: true,
      floatingFilter: true,
      flex: 1,
     
    },
    {
      headerName: 'SubStage Name',
      field: 'sub_stage_name',
      sortable: true,
      filter: true,
      floatingFilter: true,
      flex: 1,
      
    },
    {
      headerName: 'Actions',
      flex: 1,
      cellRenderer: (params: { data: SubStageData }) => (
        <div className="row">
          <div className="col-md-2">
            <Link to={`/sub_stage_edit/${params.data.id}`}>
              <i className="fa fa-edit text-success"></i>
            </Link>
          </div>
          <div className="col-md-2">
            <i onClick={() => deleteSubStage(params.data.id)} className="fa fa-trash text-danger"></i>
          </div>
        </div>
      ),
      sortable: false,
      filter: false,
    }
  ];

  const onGridReady = (params: { api: GridApi, columnApi: ColumnApi }) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = substages.filter(item => {
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          const val = item[key as keyof SubStageData];

          if (typeof val === 'string' && val.toLowerCase().includes(value)) {
            return true;
          } else if (typeof val === 'number' && val.toString().includes(value)) {
            return true;
          }
        }
      }
      return false;
    });

    setFilteredSubStages(filteredData);
    gridApi?.setRowData(filteredData);
  };

  const resetFilters = () => {
    setFilteredSubStages(substages);
    gridApi?.setFilterModel(null);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='container-fluid'>
      <div className='d-flex'>
        <SideBar />
        <div className='headerbar'>
          <Header />
          <h4 className='text-danger text-center'>Sub Stages</h4>
          <hr className='bg-danger px-1' />
          <div className='row my-5 px-2'>
            <div className='col-md-6 text-left'>
              <Select className='w-50 ml-3 my-3' value={productId} onChange={(value: number) => displaySubStages(value)}>
                <Option value={0}>Select Product</Option>
                {products.map((product, index) => (
                  <Option key={product.id} value={product.id}>{product.product_name}</Option>
                ))}
              </Select>
            </div>
            <div className="col-md-6 text-right">
              <Link to="/add_sub_stage" className='btn btn-outline-primary float-right my-3'>Add SubStage</Link>
              <Button className='btn btn-secondary float-right my-3 mr-2' onClick={resetFilters}>
                Reset Filters
              </Button>
             
            </div>
            <div className='col-md-12'>
              <div className='ag-theme-alpine' style={{ flex: 1, width: '100%' }}>
                <AgGridReact
                  onGridReady={onGridReady}
                  columnDefs={columns}
                  rowData={filteredSubStages}
                  defaultColDef={{
                    sortable: true,
                    filter: true,
                    floatingFilter: true,
                  }}
                  pagination={true}
                  paginationPageSize={10}
                  domLayout='autoHeight'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllSubStages;
