import React,{useState,useEffect, FormEvent} from 'react'
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link,useParams} from 'react-router-dom';
import axios from 'axios';
import server from '../Utils';
import Loader from './Loader';
const EditSpeciality = () => {
  const {id}=useParams();
  const specialid=id;
    const [speciality,setSpeciality]=useState<string>("");
    const [training_program,setTrainingProgram]=useState<number>(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      server.get(`/speciality/${specialid}`)
      .then((response)=>{
        
        setSpeciality(response.data.speciality.speciality)
        setTrainingProgram(response.data.speciality.type_of_training_program)
      })
    }, []);


    const addSpeciality=(e:React.FormEvent<HTMLFormElement>)=>{
        e.preventDefault();
        if(training_program===0)
        {
            toast('Select the training program');
        }else if(speciality===""||speciality===null)
        {
            toast('Enter the Speciality');
        }else{
            var form= document.getElementById('add_speciality_form') as HTMLFormElement;
            var formData =new FormData(form);
            server.post(`/speciality/${specialid}`,formData)
            .then((response)=>{
                if(response.data.code===200)
                {
                    toast(response.data.message);
                    setTimeout(function () { window.history.back(); }, 1000);
                }else{
                    toast(response.data.message);
                }
            })
        }


    }
    if (loading) {
        return <Loader />;
      }
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
            <div className='headerbar'>
        <Header/>
        {/* Content Row */}

    <h4 className='text-danger text-center'>Add Service</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5 px-5'>
   
    <div className='col-md-12'>
      
        <form id="add_speciality_form" className='w-100' onSubmit={addSpeciality}>
            <div className='row'>
                <div className='col-md-1'></div>
                <div className='col-md-5'>
                    <label>Training Program</label>
                    <select id="type_of_training_program" name="type_of_training_program" className='form-control' value={training_program} onChange={(e)=>setTrainingProgram(parseInt(e.target.value))} required>
                        <option value={0}>Select Training Program</option>
                        <option value={1}>GP</option>
                        <option value={2}>Run Through</option>
                        <option value={3}>UnCoupled</option>
                    </select>
                </div>
                <div className='col-md-5'>
                    <label>Speciality</label>
                    <input type="text" className='form-control' name="speciality" id="speciality" value={speciality}  onChange={(e)=>setSpeciality(e.target.value)} placeholder='Enter Speciality'/>
                </div>
            </div>
           <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Submit</button></div>
        </form>
     
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default EditSpeciality