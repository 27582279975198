import React, { useState, useEffect } from 'react';
import { useParams, Link, redirect } from 'react-router-dom';
import moment from 'moment';
import server from '../Utils';
import Loader from './Loader';
import { training_details } from '../../@types/training_details';
import UserSideBar from '../../pages/UserSideBar/UserSideBar';
import { FaRegQuestionCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { cabType } from '../../@types/cabtype';

const UserCab = () => {
    const [user_agreed, setUserAgreed] = useState<number>(0);
    const [records,setRecords]=useState<Array<cabType>>([])
    const [loading,setLoading]=useState<boolean>(false)
    // const { id } = useParams();
    const [first_name,setfirstName]=useState<string>("");
    const [last_name,setlastName]=useState<string>('');
    const [user_email,setUserEmail]=useState<string>('')
   let id= localStorage.getItem('ClientID');
    useEffect(() => {
        server.get(`/operationuser/${id}`).then((response) => {
            setfirstName(response.data.user.first_name)
            setlastName(response.data.user.last_name)
            setUserEmail(response.data.user.user_email)
            
            if (response.data.user.user_agreement_flag === 1) {
                setUserAgreed(1);

            } else {
                setUserAgreed(0);
            }
            if(response.data.user.user_agreement_flag===0 || response.data.user.user_agreement_flag===null){
                Swal.fire({
                  title: "Profile Incomplete",
                  text: "You won't be able to start services until profile is complete!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#233974",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Complete Profile"
                  
                }).then((result) => {
                 
                  if (result.isConfirmed) {
                    
                    
                    redirect("/user_profile");
                    window.location.href="/user_profile"
                  }
                });
              }
        });

        server.get(`/cabs/${id}`)
        .then((response)=>{
      
         setRecords(response.data.cabs)
     
        })
    }, [id]);

    
    return (

        <div className='container-fluid desktop_container' >
    <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name}/>
    <div className='horizontal-menu' style={{background:"transparent"}}>

      <div className='container-fluid mt-4 userpath_right_pad user_foarm_board'>
      <div className='d-flex mb-2 mt-3 prog-info'>
        <div className='w-75 text-left'>
            <h1><b className='blue-color'>Flight & Cabs</b></h1>
        </div>
        <div className='w-25 text-right blue-color fw-bold' style={{ fontSize: "29px" }}>
            <FaRegQuestionCircle className='mb-1' />
            <span className='support_hide'> Support</span>
        </div>
    </div>
    <div className='mt-4'>
        { user_agreed === 0 ? (
                        <p className="text-danger">
                            <Link to="/user_profile">Please Complete Profile here</Link>
                        </p>
                    ) : user_agreed === 1 && records.length > 0 ? (
                        <>
                         
                         {records.map((record, index) => (
    <div className='box-shadow p-4' style={{borderRadius:"20px"}} >  
      <div key={record.id} >
        {/* <Link to={`/edit_cab/${record.id}`} className='text-danger mr-5 pr-5' >Edit</Link>  */}
        
          <h3 className='mb-4 blue-color fw-bold'>Flight Information</h3>
          <hr/>
            <div className="row my-2">
                <div className='col-md-4'> <label className="blue-color">Flight Number</label>{' '}</div>
                <div className='col-md-4'> <span className=''>{record.flightNumber}</span></div>
            </div>
            <div className="row my-2">
                <div className='col-md-4'>  <label className="blue-color">Arrival Date</label>{' '}</div>
                <div className='col-md-4'> <span className=''>{moment(record.arrivalDate).format('Do MMMM YYYY')}</span></div>
           </div>
            <div className="row my-2">
            <div className='col-md-4'>  <label className="blue-color">Arrival Time</label>{' '}</div>
            <div className='col-md-4'> <span className=''> {moment(record.arrivalTime, 'HH:mm:ss').format('hh:mm A')} </span></div>
            </div>
          
          
     
          <h3 className='mb-4 blue-color fw-bold mt-4'>Cab Information  </h3>
          <hr/>
         <div className="row my-2">
            <div className='col-md-4'> <label className="blue-color">Vendor Name</label>{' '}</div>
            <div className='col-md-4'> <span >{record.vendor_name}</span></div>
         </div>
            {/* <div className="row my-2">
            <div className='col-md-2'> <label className="blue-color">Send Pickup Email:</label>{' '}</div>
            <div className='col-md-6'><span > {record.sendPickupEmail === 1 ? 'Yes' : 'No'}</span></div>
            </div> */}
            <div className="row my-2">
            <div className='col-md-4'> <label className="blue-color">Pickup Location</label>{' '}</div>
            <div className='col-md-4'><span >{record.pickupLocation}</span></div>
             
              
            </div>
            <div className="row my-2">
            <div className='col-md-4'> <label className="blue-color">Pickup Pin Code</label>{' '}</div>
            <div className='col-md-4'><span >{record.pickupPinCode}</span></div>
             </div>
            <div className="row my-2">
            <div className='col-md-4'> <label className="blue-color">Drop Location</label>{' '}</div>
            <div className='col-md-4'><span >{record.dropLocation}</span></div>
              
              
            </div>
            <div className="row my-2">
            <div className='col-md-4'> <label className="blue-color">Drop Pin Code</label>{' '}</div>
            <div className='col-md-4'><span className='blue-color '>{record.dropPinCode}</span></div>
            </div>
        
          
          
        </div>
      </div>
   
    ))}
                        </>
                    ) : user_agreed === 1 && records.length === 0 ? (
                        <div className="text-danger bold pl-3 pt-2" style={{ position: 'absolute', top: "12%" }}>
                            Hi there! You do not have any Flights And cabs booked at the moment, kindly contact <a style={{ display: 'content' }} className='text-left' href="tel:+919019925836">9019925836</a>
                        </div>
                    ) : null}
        </div>
        
        </div>
       
   

    </div>
  
</div>


        
    );
};

export default UserCab;
