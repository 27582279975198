import React,{useState,useEffect} from 'react'
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link, useParams} from 'react-router-dom';
import axios from 'axios';
import { StageData } from '../../@types/stagetype';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import server from '../Utils';
import Loader from './Loader';
import { admintype as Admintype } from '../../@types/admintype';
import { inchargetype } from '../../@types/inchargetype';
const EditIncharge = () => {
    const [stages,setStages]=useState<Array<StageData>>([])
    const[stage_id,setStageId]=useState<number>(0);
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [projects,setProjects]=useState<Array<ProjectData>>([])
    const [project_id,setProjectId]=useState<number>(0);
    const [product_id,setProductId]=useState<number>(0);
    const [substage,setSubStage]=useState<string>('')
    const [error,setError]=useState<string>('')
    const [loading, setLoading] = useState(false);
    const[primary_incharge,setPrimaryIncharge]=useState<number>(0);
    const[secondary_incharge,setSecondaryIncharge]=useState<number>(0);
    const [admins,setAdmins]=useState<Array<Admintype>>([]);
    const {id}= useParams();
   const incharge_id=id;
   const [incharges,setIncharges]=useState<Array<inchargetype>>([]);
    useEffect(() => {
        // axios.get('https://api.prod.goocampus.in/api/admin/get_stages')
        // .then((response)=>{
        //     setStages(response.data.stages)
  
        // })
        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let id= +projectid
        setProjectId(id)
        server.get(`/get_product/${id}`)
        .then((response)=>{
            
            setProducts(response.data.product)
            
        })

        setProjectId(id);
        server.get(`/get_product/${id}`)
        .then((response)=>{
            
            setProducts(response.data.product)
            
        })
        server.get(`/get_superadmins`)
        .then((response)=>{
            setAdmins(response.data.admins)
        })

        server.get(`/incharge/${incharge_id}`)
        .then((response)=>{
            setProductId(response.data.incharge.product_id)
            setProjectId(response.data.incharge.project_id)
            server.get(`/get_stage/${response.data.incharge.product_id}`)
            .then((response)=>{
                
              setStages(response.data.stage);
                
            })
            setStageId(response.data.incharge.stage_id)
            setPrimaryIncharge(response.data.incharge.primary_incharge)
            setSecondaryIncharge(response.data.incharge.secondary_incharge)
        })
      }, []);

      
    
      const setStage=(id:number)=>
      {
        console.log(id)
        
        setProductId(id);
        
          server.get(`/get_stage/${id}`)
          .then((response)=>{
              
            setStages(response.data.stage);
              
          })
      }
      
      const updateSubStage =(e:React.FormEvent<HTMLFormElement>)=>
      {
        e.preventDefault();
        var form=document.getElementById('add_sub_stage_form') as HTMLFormElement ;
        var formData= new FormData(form);
        var regEx = /^[A-Za-z]+$/;
        var name:any = substage;
        if(product_id===0)
        {
         setError("Please Select the package"); 
         toast(error);
        }
        else if(stage_id===0)
       {
        setError("Please Select the Stage"); 
        toast(error);
       }else if(primary_incharge===0){
        toast('Select Primary Incharge');
       }else if(secondary_incharge===0){
        toast('Select Secondary Incharge');
       }else{
        server.post(`/incharge/${incharge_id}`,formData)
        .then((response)=>
        {
            if(response.data.code===200)
            {
                toast(response.data.message)
                setTimeout(function () { window.location.href = "/incharges"; }, 100);
            }else
            {
                toast(response.data.message)
            }
        })
    }
      }


      if (loading) {
        return <Loader />;
      }
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
            <div className='headerbar'>
        <Header/>
        {/* Content Row */}

    <h4 className='text-danger text-center'>Add SubStage</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>
    <div className='col-md-1'></div>
    <div className='col-md-10'>
      
        <form id="add_sub_stage_form" className='w-100' onSubmit={updateSubStage}>
            <div className='row'>
            {/* <div className='col-md-3'>
            <label >Project</label>
            <select className='form-control' name='project_id' id="project_id" onChange={(e)=>setProject(parseInt(e.target.value))} required> 
                        <option value={0}>Select Project</option>
                        {projects.map((project)=>(
                            <option value={project.id}>{project.project_name}</option>))}
            </select>
            </div> */}
            <input type="hidden" name="project_id"  id="project_id" value={project_id} />
            {project_id!==0?<div className='col-md-3'>
            <label >Products</label>
                     <select className='form-control' name='product_id' id="product_id" value={product_id} onChange={(e)=>setStage(parseInt(e.target.value))} required>
                        <option value={0}>Select Product</option>
                        {products.map((product)=>(
                            <option value={product.id}>{product.product_name}</option>))}
            </select>
            </div>:""}
            {product_id!==0? <div className='col-md-3'>
                    <label >Stages</label>
                    <select className='form-control' name='stage_id' id="stage_id" value={stage_id} onChange={(e)=>setStageId(parseInt(e.target.value))} required>
                        <option value={0}>Select the stage</option>
                        {stages.map((stage,index)=>(
                            <option value={stage.id}>{stage.stage_name}</option>))}
                    </select>
                </div>:""}
               
                {stage_id!==0? <div className='col-md-3'>
                    <label>Primary Incharge</label>
                    <select className='form-control' id="primary_incharge" name="primary_incharge" value={primary_incharge} onChange={(e)=>setPrimaryIncharge(parseInt(e.target.value))}>
                       <option value="0">Select Executives</option>
                        {admins.map((admin)=>(
                            <option value={admin.id}>{admin.first_name}</option>
                        ))}
                    </select>
                </div>:""}
                {stage_id!==0? <div className='col-md-3'>
                    <label>Secondary Incharge</label>
                    <select className='form-control' id="secondary_incharge" name="secondary_incharge" value={secondary_incharge} onChange={(e)=>setSecondaryIncharge(parseInt(e.target.value))}>
                       <option value="0">Select Executives</option>
                        {admins.map((admin)=>(
                            <option value={admin.id}>{admin.first_name}</option>
                        ))}
                    </select>
                </div>
          :""}
                
            </div>
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Submit</button></div>
        </form>
     
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default EditIncharge;