import React, { useState, useEffect } from 'react';
import { useParams, Link, redirect } from 'react-router-dom';
import moment from 'moment';
import server from '../Utils';
import Loader from './Loader';
import { training_details } from '../../@types/training_details';
import UserSideBar from '../../pages/UserSideBar/UserSideBar';
import { FaRegQuestionCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { testbookingtype } from '../../@types/testbookingtype';
import { IoCheckmarkCircle } from "react-icons/io5";
import { FaCircleXmark } from "react-icons/fa6";
import Missed from '../../assets/images/Missed.png';
import booked from '../../assets/images/Booked.png';
import Attended from '../../assets/images/Attended.png';
const UserTestBookingDetails = () => {
    const [project_id,setProjectId]=useState<number>(0);
    const [user_exist,setUserExists]=useState<boolean>(false);
    const [training_exists,setTrainingExists]=useState<boolean>(false);
    const [test_booking_exists,setTestBookingExist]=useState<boolean>(false);
    const [test_bookings,setTestBooking]=useState<Array<testbookingtype>>([]);

    const [assesment_date,setAssesmentDate] =useState<string>('');
    const[service_category_name,setServiceCategoryName]=useState<string>('')
    const [service_name,setServiceName]=useState<string>('')
    const [vendor_name,setVendorName]=useState<string>('');
    const [vendor_id,setVendorId]=useState<number>(0);
    const [mode_of_training,setModeOfTraining]=useState(0);
    const [start_date,setStartDate]=useState<string>('')
    const [end_date,setEndDate]=useState<string>('');
    const [training_id,setTrainingId]=useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [user_agreed,setUserAgreed]=useState<number>(0);
    const [first_name,setfirstName]=useState<string>("");
    const [last_name,setlastName]=useState<string>('');
    const [user_email,setUserEmail]=useState<string>('')
   let id= localStorage.getItem('ClientID');
   var loggedIn=localStorage.getItem('loggedIn');
   useEffect(() => {
       if(loggedIn!=="true")
 {
   window.location.href="/userlogin";
 }

      setLoading(true)
        server.get(`/operationuser/${id}`)
        .then((response)=>{
            setfirstName(response.data.user.first_name)
            setlastName(response.data.user.last_name)
            setUserEmail(response.data.user.user_email)
           if(response.data.user.user_agreement_flag===1){
             setUserAgreed(1)
           }else{
             setUserAgreed(2)
           }
        })
      server.get(`/test_bookings/${id}`)
      .then((response)=>{
        setTestBooking(response.data.test_bookings)
      })
         server.get(`/onboarding_exists/${id}`)
         .then((response)=>{
          
          if(response.data.code===200){
            setUserExists(true)
          }else{
            setUserExists(false)
          }
         })
  
         server.get(`/training_exists/${id}`)
         .then((response)=>{
          
          if(response.data.code===200){
            setTrainingExists(true)
          }else{
            setTrainingExists(false)
          }
         })
         server.get(`/test_booking_exists/${id}`)
         .then((response)=>{
          
          if(response.data.code===200){
            setTestBookingExist(true)
          }else{
            setTestBookingExist(false)
          }
         })
         setLoading(false)
    }, [id]);

    
    return (

        <div className='container-fluid desktop_container' >
    <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name}/>
    <div className='horizontal-menu' style={{background:"transparent"}}>

      <div className='container-fluid mt-4 userpath_right_pad user_foarm_board'>
      <div className='d-flex my-3 mt-3 prog-info'>
        <div className='w-75 text-left'>
            <h1><b  className='blue-color'>Test Bookings</b ></h1>
        </div>
        <div className='w-25 text-right blue-color fw-bold' style={{ fontSize: "29px" }}>
            <FaRegQuestionCircle className='mb-1' />
            <span className='support_hide'> Support</span>
        </div>
    </div>
    <div className='mt-4'>
        { user_agreed === 2 ? (
                        <p className="text-danger">
                            <Link to="/user_profile">Please Complete Profile here</Link>
                        </p>
                    ) : user_agreed === 1 && test_bookings.length > 0 ? (
                        <>
                         
                            {test_bookings.map((test_booking,index)=>(
      <div className='my-4 p-4 box-shadow' style={{borderRadius:"20px"}}>
        <div className='row'><div className='col-md-6'><h2 className="blue-color fw-bold">{test_booking.service_name}</h2></div></div>
        <hr/>
         <div className='row mt-2'>
         <div className='col-md-5'>
         
         </div>
         <div className='col-md-5'>
          
         
         </div>   
         </div>  
      <div className='row'>
        <div className='col-md-6'>
        <div className='row my-3'>
        <div className='col-md-4'><label  className="blue-color">Test Status</label ></div>
        <div className='col-md-6 text-left'>{test_booking.test_status===1?<span>
          {/* <img className='my-3' src={booked} width="30px" height="30px" alt="booked"/>&nbsp;&nbsp;&nbsp;&nbsp; */}
           Booked
           </span> :test_booking.test_status===2?<span>
            {/* <img src={Attended} width="20px" height="20px" style={{color:"#1cc12a",fontSize:"23px"}} alt="Attended" /> */}
             Attended</span>:test_booking.test_status===3?
            //  <img src={Missed} width="20px" height="20px" alt="Missed"/>
            "Missed" :test_booking.test_status===4?"Cancelled by Authority":test_booking.test_status===5?"Cancelled by Client":test_booking.test_status===6?"Rescheduled":""}</div>
      </div>
      {test_booking.result_status!==null?<div className='row my-3'>
     <div className='col-md-4'><label  className="blue-color">Test Result</label ></div>
        <div className='col-md-6'>{test_booking.result_status===1?"Waiting For Result":test_booking.result_status===2?<span>
          {/* <IoCheckmarkCircle className='mb-1' style={{color:"#1cc12a",fontSize:"23px"}}/> */}
           Pass</span>:test_booking.result_status===3?<span>
            {/* <FaCircleXmark style={{color:"#FF4141",fontSize:"23px"}}/> */}
            Fail
            </span>:""}</div>
      </div>:""}
        <div className='row my-3'>
        <div className='col-md-4'><label  className="blue-color">Test Date</label ></div>
        <div className='col-md-6'>{moment(test_booking.test_date).format('Do MMMM YYYY')}</div>
      </div>
      <div className='row my-3'>
       <div className='col-md-4'><label  className="blue-color">Booking Date</label ></div>
       <div className='col-md-6'>{moment(test_booking.booking_date).format('Do MMMM YYYY')}</div>
      </div>
      {/* <div className='row my-3'>
        <div className='col-md-4'><label  className="blue-color">Test Type </label ></div>
        <div className='col-md-6'>{test_booking.service_category_name}</div>
      </div> */}
      {/* <div className='row my-3'>
       <div className='col-md-4'><label  className="blue-color">Test</label ></div>
       <div className='col-md-6'>{test_booking.service_name}</div>
      </div> */}
        </div>
        <div className='col-md-6'>
        <div className='row my-3'>
        <div className='col-md-4'><label  className="blue-color">Country</label ></div>
        <div className='col-md-6'>{test_booking.countryname}</div>
      </div>
      <div className='row my-3'>
       <div className='col-md-4'><label  className="blue-color">State</label ></div>
       <div className='col-md-6'>{test_booking.statename}</div>
      </div>
      <div className='row my-3'>
        <div className='col-md-4'><label  className="blue-color">City</label ></div>
        <div className='col-md-6'>{test_booking.cityname}</div>
      </div>
      <div className='row my-3'>
       <div className='col-md-4'><label  className="blue-color">Venue</label ></div>
       <div className='col-md-6'>{test_booking.venue_name}</div>
      </div>
        </div>
      </div>
      
      
     
   
      
    
      {/* <div className='row my-3'>
       <div className='col-md-2'><label  className="blue-color">Note</label ></div>
       <div className='col-md-8 text-left border rounded ml-2' style={{wordBreak:"break-word"}}>{test_booking.note}</div>
      </div> */}
  

</div>

    ))}
                        </>
                    ) : user_agreed === 1 && test_bookings.length === 0 ? (
                        <div className="text-danger bold pl-3 pt-2" style={{ position: 'absolute', top: "12%" }}>
                            Hi there! You do not have any Test Bookings allocated at the moment, kindly contact <a style={{ display: 'content' }} className='text-left' href="tel:+919019925836">9019925836</a>
                        </div>
                    ) : null}
        </div>
        
        </div>
       
   

    </div>
  
</div>


        
    );
};

export default UserTestBookingDetails;
