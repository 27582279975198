import React, { useState, useEffect } from 'react';
import { useParams, Link, redirect } from 'react-router-dom';
import moment from 'moment';
import server from '../Utils';
import Loader from './Loader';
import { training_details } from '../../@types/training_details';
import UserSideBar from '../../pages/UserSideBar/UserSideBar';
import { FaRegQuestionCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { tier4visatype } from '../../@types/tier4visatype';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { FaCircleXmark } from 'react-icons/fa6';

const UserTier4Visa = () => {
    const [user_agreed, setUserAgreed] = useState<number>(0);
   
    
    // const { id } = useParams();
    const [product_id,setProductId] = useState<number>(0);

    const [user_id,setUserid]=useState<number>(0);
    const [tier4visas,setTier4Visas]=useState<Array<tier4visatype>>([])
    const [first_name,setfirstName]=useState<string>("");
    const [last_name,setlastName]=useState<string>('');
    const [user_email,setUserEmail]=useState<string>('')
    let id= localStorage.getItem('ClientID');
    var loggedIn=localStorage.getItem('loggedIn');
    useEffect(() => {
        if(loggedIn!=="true")
  {
    window.location.href="/userlogin";
  }
        server.get(`/operationuser/${id}`).then((response) => {
            setfirstName(response.data.user.first_name)
            setlastName(response.data.user.last_name)
            setUserEmail(response.data.user.user_email)
            
            if (response.data.user.user_agreement_flag === 1) {
                setUserAgreed(1);

            } else {
                setUserAgreed(2);
            }
            if(response.data.user.user_agreement_flag===0 || response.data.user.user_agreement_flag===null){
                Swal.fire({
                  title: "Profile Incomplete",
                  text: "You won't be able to start services until profile is complete!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#233974",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Complete Profile"
                  
                }).then((result) => {
                 
                  if (result.isConfirmed) {
                    
                    
                    redirect("/user_profile");
                    window.location.href="/user_profile"
                  }
                });
              }
        });

        server.get(`/tier4visas/${id}`)
        .then((response)=>{
      
         setTier4Visas(response.data.tier4visa)
     
        })
    }, [id]);

    
    return (

        <div className='container-fluid desktop_container' >
    <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name}/>
    <div className='horizontal-menu' style={{background:"transparent"}}>

      <div className='container-fluid mt-4 userpath_right_pad user_foarm_board'>
      <div className='d-flex mb-2 mt-3 prog-info'>
        <div className='w-75 text-left'>
            <h1><b className='blue-color'>Tier 4-Visa</b></h1>
        </div>
        <div className='w-25 text-right blue-color fw-bold' style={{ fontSize: "29px" }}>
            <FaRegQuestionCircle className='mb-1' />
            <span className='support_hide'> Support</span>
        </div>
    </div>
    <div className='mt-4'>
        { user_agreed === 2 ? (
                        <p className="text-danger">
                            <Link to="/user_profile">Please Complete Profile here</Link>
                        </p>
                    ) : user_agreed === 1 && tier4visas.length > 0 ? (
                        <>
                         
                         {tier4visas.map((tier4visa, index) => (
        <div key={index} className='mt-4 box-shadow p-4' style={{borderRadius:"20px"}}>
         
          <br/>
          <h3 className='blue-color fw-bold mb-3'>Tier 4-visa</h3>
          <hr />
          <div className='row'>
          {/* {tier4visa.sendEmail !== 0?  <div className='col-md-4'>
            <div className="row">
            <div className='col-md-6 text-left'><label  className='text-left blue-color'>Email Sent</label ></div>
            <div className='col-md-6 text-left'>{tier4visa.sendEmail === 0 ? "Not Sent" : "Sent"}</div>
            </div></div>:""} */}
           
            {tier4visa.applicationStatus !== null?
            <div className="row my-1">
            <div className='col-md-4 text-left'><label  className='text-left blue-color'>Application Status</label ></div>
            <div className='col-md-6 text-left'>{tier4visa.applicationStatus === 1 ? "Yet to Start" : tier4visa.applicationStatus === 2?"In Progress":tier4visa.applicationStatus === 3?"Submitted":""}</div>
            </div>:""}
            
           
            { tier4visa.applicationStatus===3 && tier4visa.applicationStatus !== null?
            <div className="row my-1">
            <div className='col-md-4 text-left'><label  className='text-left blue-color'>Visa Status</label ></div>
            <div className='col-md-6 text-left'>{tier4visa.visaStatus === 1 ? "processing" : tier4visa.visaStatus === 2?<span>Attended</span>:tier4visa.visaStatus === 3?<span><FaCircleXmark style={{color:"#FF4141",fontSize:"23px"}}/>Rejected</span>:""}</div>
          </div>:""}
                
            
          </div>
       
          <div className='row'>
           
            
         
          {tier4visa.applicationStatus===3 && tier4visa.appointmentDate !== null?  <div className='row my-1'>
            <div className='col-md-4 text-left'><label  className='text-left blue-color'>Visa Appointment Date</label ></div>
            <div className='col-md-6 text-left'>{moment(tier4visa.appointmentDate).format('Do MMMM YYYY')}</div>
          </div>:""}
         
          
            
          {tier4visa.applicationStatus===3 && tier4visa.visaPeriod !== null && tier4visa.applicationStatus!==null?<div className='row my-1'>
            <div className='col-md-4 text-left'><label  className='text-left blue-color'>Visa Duration </label ></div>
            <div className='col-md-6 text-left'>{tier4visa.visaPeriod} {tier4visa.visaDuration === 1 ? "Days" : tier4visa.visaDuration === 2?"Weeks":tier4visa.visaDuration === 3?"Months":tier4visa.visaDuration===4?"years":""}</div>
          </div>:""}
          { tier4visa.vendor_name!==null? <div className='row my-1'>
            <div className='col-md-4 text-left'><label  className='text-left blue-color'>Visa Vendor </label ></div>
            <div className='col-md-6 text-left'>{tier4visa.vendor_name} </div>
          </div>:""}
          
          </div>
          </div>
      
      ))}
                        </>
                    ) : user_agreed === 1 && tier4visas.length === 0 ? (
                        <div className="text-danger bold pl-3 pt-2" style={{ position: 'absolute', top: "12%" }}>
                            Hi there! You do not have any Tier4visas at the moment, kindly contact <a style={{ display: 'content' }} className='text-left' href="tel:+919019925836">9019925836</a>
                        </div>
                    ) : null}
        </div>
        
        </div>
       
   

    </div>
  
</div>


        
    );
};

export default UserTier4Visa;
