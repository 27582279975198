import React, { useEffect, useState } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { ProductData } from '../../@types/producttype';
import server from '../Utils';
import Loader from './Loader';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { SearchOutlined } from '@ant-design/icons'; // Import SearchOutlined
import { GridApi, ColumnApi, ColDef } from 'ag-grid-community';

const AllPackages = () => {
    const [products, setProducts] = useState<Array<ProductData>>([]);
    const [loading, setLoading] = useState(false);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);

    let projectid: string = JSON.parse(localStorage.getItem("project_id") || '{}');
    let id = +projectid;

    useEffect(() => {
        setLoading(true);
        server.get(`/get_product/${id}`)
            .then((response) => {
                if (response.data.product.length > 0) {
                    setProducts(response.data.product);
                } else {
                    setProducts([]);
                }
            })
            .catch((error) => {
                console.error('Error fetching Products:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    const deleteProduct = (deleteId: number) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                server.delete(`/product/${deleteId}`)
                    .then((response) => {
                        if (response.data.products.length > 0) {
                            setProducts(response.data.product);
                            swalWithBootstrapButtons.fire({
                                title: "Deleted!",
                                text: "Your file has been deleted.",
                                icon: "success"
                            });
                        } else {
                            setProducts([]);
                        }
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire({
                    title: "Cancelled",
                    text: "Your imaginary file is safe :)",
                    icon: "error"
                });
            }
        });
    };

    const columns: ColDef[] = [
        {
            headerName: 'SL No',
            valueGetter: "node.rowIndex + 1",
            sortable: true,
            filter: false,


        },
        {
            headerName: 'Product Name',
            field: 'product_name',
            sortable: true,
            filter: true,
            floatingFilter:true,
        },
        {
            headerName: 'Product Price',
            field: 'product_price',
            sortable: true,
            filter: true,
            floatingFilter:true,
        },
        {
            headerName: 'Actions',
            cellRenderer: (params: { data: { id: number; }; }) => (
                <div className="row">
                    <div className="col-md-6">
                        <Link to={`/product_edit/${params.data.id}`}>
                            <i className="fa fa-edit text-success"></i>
                        </Link>
                    </div>
                    <div className="col-md-6">
                        <i onClick={() => deleteProduct(params.data.id)} className="fa fa-trash text-danger"></i>
                    </div>
                </div>
            ),
            sortable: false,
            filter: false,
        }
    ];

    const onGridReady = (params: { api: GridApi, columnApi: ColumnApi }) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className='container-fluid'>
            <div className='d-flex'>
                <SideBar />
                <div className='headerbar'>
                    <Header />
                    <h4 className='text-danger text-center'>Products</h4>
                    <hr className='bg-danger px-1' />
                    <div className='row mt-5'>
                        
                    <div className='col-md-10 text-right'>
                    <Link to="/add_product" className='btn btn-outline-primary float-right my-3'>Add Product</Link>
                    </div>
                        <div className='col-md-10 text-center ml-5 pl-5'>

                            <div className='ag-theme-alpine' style={{ height: '500px', width: '100%' }}>
                                <AgGridReact
                                    onGridReady={onGridReady}
                                    columnDefs={columns}
                                    rowData={products}
                                    pagination={true}
                                    paginationPageSize={10}
                                    domLayout='autoHeight'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllPackages;
