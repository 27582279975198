import React, { useState } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainSideBar from '../../pages/MainSideBar';
import MainHeader from '../../pages/MainHeader';
import server from '../Utils';
import Loader from './Loader';

const AddProject = () => {
    const [project_name, setprojectName] = useState<string>();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState(false);

    const addProgram = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // var regEx = /^[A-Za-z]+$/;
        var regEx = /^\d+$/gs;

        var name: any = project_name;
        if (regEx.test(name)) {
            setError("program should be alphabets only");
            toast(error);
        } else {
            setError('');
            var token = localStorage.getItem('auth_token');
            console.log(token)
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            var form = document.getElementById('add_program_form') as HTMLFormElement;
            var formdata = new FormData(form);
            server.post(`/add_project`, formdata, { headers: { 'Authorization': 'Bearer ' + token } })
                .then((response) => {
                    if (response.data.code === 200) {
                        toast(response.data.message)
                        setTimeout(function () { window.location.href = "/projects"; }, 1000);
                    } else {
                        toast(response.data.message)
                    }
                })

        }
    }

 if (loading) {
    return <Loader />;
  }
    return (
        <div className='conatiner-fluid'>
            <div className='d-flex'>
                <MainSideBar />
                <div className='headerbar' style={{ width: "100%" }}>
                    <MainHeader />
                    

                    <div className="row px-2">
                        {/* Earnings (Monthly) Card Example */}
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center px-3">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                Earnings (Monthly)</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">$40,000</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-calendar fa-2x text-gray-300" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Earnings (Monthly) Card Example */}
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-success shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center px-3">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                Earnings (Annual)</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">$215,000</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-dollar-sign fa-2x text-gray-300" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Earnings (Monthly) Card Example */}
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-info shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center px-3  ">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Tasks
                                            </div>
                                            <div className="row no-gutters align-items-center">
                                                <div className="col-auto">
                                                    <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">50%</div>
                                                </div>
                                                <div className="col">
                                                    <div className="progress progress-sm mr-2">
                                                        <div className="progress-bar bg-info" role="progressbar" style={{ width: '50%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-clipboard-list fa-2x text-gray-300" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Pending Requests Card Example */}
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-warning shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center px-3">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                                Pending Requests</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">18</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-comments fa-2x text-gray-300" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 className='text-danger text-center'>Add Project</h4>
                    <hr className='bg-danger px-1' />
                    <div className='row mt-5'>
                        <div className='col-md-1'></div>
                        <div className='col-md-8'>
                            <form id="add_program_form" onSubmit={addProgram}>
                                <label>Project Name</label>
                                <input className='form-control' type="text" name="project_name" id="project_name" value={project_name} onChange={(e) => setprojectName(e.target.value)} required />
                                <ToastContainer />
                                <button className='btn btn-primary mt-2'>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
                {/* header bar ends here */}

                {/* Content Row */}

            </div>

        </div>
    )

}

export default AddProject