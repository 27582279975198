import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import { ServiceData } from '../../@types/servicetype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import {Link,useParams} from 'react-router-dom';
import server from '../Utils';
import validator from 'validator';
import { SpecialityData } from '../../@types/specialitytype';
import { SubSpecialityData } from '../../@types/subspeciality';
import Loader from '../Stages/Loader';
import { citytype } from '../../@types/citytype';
import { countrytype } from '../../@types/contrytype';
import { statetype } from '../../@types/statetype';


const UserEmloymentAndSpecialityEdiT = () => {
    var loggedIn=localStorage.getItem('loggedIn');
    const [loading, setLoading] = useState(false);
    const [first_name,setfirstName]=useState<string>("");
    const [last_name,setlastName]=useState<string>('');
    const [user_email,setUserEmail]=useState<string>('')
    const [pmq_status,setPmQStatus]=useState<number>(0);
    const [emp_completion_status,setEmployCompletion]=useState<number>(0);
    const [emp_hospital_name,setEmployHospital]=useState<string>("");
    const [emp_country,setEmpCountry]=useState<number>(0);
    const [emp_city,setEmpCity] =useState<number>(0);
    const [emp_state,setState]=useState<number>(0);
    const [specialities,setSpecialities]=useState<Array<SpecialityData>>([]);
    const [speciality_id,setSpecialityId]=useState<number>(0);
    const [sub_specialities,setSubSpeciality]=useState<Array<SubSpecialityData>>([]);
    const [subspecialityid,setSubSpecialityId]=useState<number>(0);
    const [speciality_id2,setspecialities2]=useState<number>(0)
    const [countries,setCountries]=useState<Array<countrytype>>([]);
      const [country,setCountry]=useState<number>(0)
      const [states,setStates]=useState<Array<statetype>>([]);
      const [currentstate,setCurrentState]=useState<number>(0);
      const [cities,setCities]=useState<Array<citytype>>([]);
      const [city,setCity]=useState<number>(0);
      const [form_progress,setFormProgress]=useState<number>(0);
      const [submission,setSubmission]=useState<boolean>(false);
  
   
  
  const {id}=useParams()
    const userid=id;
  var isloggedIn=localStorage.getItem("isLoggedIn");
  if (isloggedIn !== "true") {
      
    window.location.href='/';
  }
    useEffect(() => {
    
      server.get(`/operationuser/${userid}`)
    .then((response)=>{
      console.log(response)
      if(response.data.code===200){
      
        if(response.data.code===200){
            setfirstName(response.data.user.first_name)
            setlastName(response.data.user.last_name)
            setUserEmail(response.data.user.user_email)
          setPmQStatus(response.data.user.pmq_status);
          setEmployCompletion(response.data.user.emp_completion_status);
          setEmployHospital(response.data.user.emp_hospital_name);
          setEmpCountry(response.data.user.emp_country)
          if(response.data.user.emp_country===""||response.data.user.emp_country===null){
            setEmpCountry(0);  
          }else{
            setState(response.data.user.emp_country);
          }
          setStatesArray(response.data.user.emp_country)
          
          if(response.data.user.emp_state===""||response.data.user.emp_state===null){
            setState(0);  
          }else{
            setState(response.data.user.emp_state);
          }
          setCityArray(response.data.user.emp_state);
          if(response.data.user.emp_city===""||response.data.user.emp_city===null){
            setEmpCity(0);  
          }else{
          setEmpCity(response.data.user.emp_city);
          }
          if(response.data.user.interested_speciality===""||response.data.user.interested_speciality===null){
            setSpecialityId(0)
          }else{
            setSpecialityId(response.data.user.interested_speciality_id)
          }
          // setSpecialityId(response.data.user.interested_speciality);
          
          // setSubspecialities(response.data.user.interested_speciality);
          setspecialities2(response.data.user.interested_speciality_id2);
          setSubSpecialityId(response.data.user.sub_speciality);
          if(response.data.user.form_progress!==null){
            setFormProgress(response.data.user.form_progress)
          }else{
            setFormProgress(0)
          }
          setTimeout(function () { setLoading(false) }, 1000);
        
          }
    console.log(subspecialityid)
  
     
      }
  })
      server.get(`/specialities`)
      .then((response)=>{
          
        setSpecialities(response.data.specialities)
  })
    }, []);
   
  
  const setSubspecialities=(id:number)=>{
      setSpecialityId(id)
      server.get(`/sub_speciality_by_speciality/${id}`)
      .then((response)=>{
          setSubSpeciality(response.data.sub_specialities)
      })
  }
  
  const  updateDetails = (e:React.FormEvent<HTMLFormElement>) =>{
    e.preventDefault();
    
   
    // var userid= localStorage.getItem('ClientID');
     
    
      var form = document.getElementById('employment_and_speciality') as HTMLFormElement
      var formData = new FormData(form);
      
      // var emp_completion_status=document.getElementById('emp_completion_status') as HTMLInputElement
      // var emp_hospital_name=document.getElementById('emp_hospital_name') as HTMLInputElement
      // var emp_city=document.getElementById('emp_city') as HTMLInputElement
      // var emp_state=document.getElementById('emp_state') as HTMLInputElement
      // var speciality_id=document.getElementById('interested_speciality') as HTMLInputElement
      // var subspecialityid=document.getElementById('sub_speciality') as HTMLInputElement
      
  
  if(emp_completion_status===0){
      toast('Choose Employee Status');
  }else if(speciality_id===0){
      toast('Choose Speciality');
  }else if(subspecialityid===0){
      toast('Choose SubSpeciality');
  }else{
    setLoading(true)
    server.post(`/employment_and_speciality/${userid}`,formData)
    .then((response=>{
      if(response.data.code===200){
        setLoading(false)
        toast(response.data.message);
        
      
      }else{
        toast(response.data.message);
      }
    }))
  }
  
  } 

const saveDetails=()=>{
    var userid= localStorage.getItem('ClientID');
     
    
      var form = document.getElementById('employment_and_speciality') as HTMLFormElement
      var formData = new FormData(form);
      
    
  
  if(pmq_status===3 && emp_completion_status===0){
      toast.error('Choose Employee Status');
  }
  else if(emp_completion_status===1 && emp_country===0){
    toast.error('Select Employed Country')
  }else if(emp_completion_status===1 && emp_state===0){
    toast.error('Select Employed State')
  }
  
  else if(speciality_id===0){
      toast('Choose Speciality');
  }else{
    setLoading(true)
    server.post(`/employment_and_speciality/${userid}`,formData)
    .then((response=>{
      if(response.data.code===200){
        setLoading(false)
        Swal.fire({
          title:"Details Saved!",
          text: "Employment Details Saved Successfully",
          icon: "success"
        });
        
          
      }else{
        setLoading(false)
        toast(response.data.message);
      }
    }))
  }
  }

  const setStatesArray=(id:number)=>{
    setEmpCountry(id)
    
    server.get(`/get_states/${id}`)
    .then((response)=>{
       setStates(response.data.states)
    })
  }
  const setCityArray=(id:number)=>{
    setState(id)
    server.get(`/get_cities/${id}`)
    .then((response)=>{
       setCities(response.data.cities)
    })
  }
  if (loading) {
    return <Loader />;
  }
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}


    <h4 className='text-danger text-center'>Employment And Speciality Details</h4>
    <hr className='bg-danger px-1' />
    <div className='user_form_dashboard userpath_right_pad mx-3 pt-2'>
        {/* {pmq_status===3? <h5 className='text-danger text-center my-4'>Employment Details</h5>:""} */}
       {/* {pmq_status===3 ?<hr/>:""} */}
       <h3 className='blue-color fw-bold my-4'>Employment Details</h3>
          <form className='w-100' id="employment_and_speciality" onSubmit={updateDetails}>
          <input type='hidden' name="form_progress" id="form_progress" value={6}/>

          {pmq_status===3?<div className='row'>
                <div className='col-md-3'>
                    <label className="blue-color fw-bold mb-3">Are you employed?<b className='text-danger'>*</b></label>
                    <select className='form-control rounded user_input_dashboard' id="emp_completion_status" name="emp_completion_status" value={emp_completion_status} onChange={(e)=>{setEmployCompletion(parseInt(e.target.value))}}>
                        <option value={0}>Choose Status</option>
                        <option value={1}>Yes</option>
                        <option value={2}>No</option>
                    </select>
                </div>
                <div className='col-md-3'>
                   {emp_completion_status===1? <label className="blue-color fw-bold">Name of Hospital/Employer<b className='text-danger'>*</b></label>:""}
                    {emp_completion_status===1?<input type="text"className='form-control rounded user_input_dashboard' name="emp_hospital_name" id="emp_hospital_name" value={emp_hospital_name} onChange={(e)=>setEmployHospital(e.target.value)} required />:""}
                </div>
             
             
            </div>:""}

            {emp_completion_status===1 && pmq_status===3 ? <div className='row mt-5'>
         
            
            <div className='col-md-3'>
              <label className="blue-color fw-bold">Country</label>
              {/* <input className="form-control rounded"  type="text" name="country" id="country" value={country} onChange={(e)=>setCountry(e.target.value)}  required /> */}
              <select className='form-control rounded user_input_dashboard'  name="emp_country" id="emp_country" value={emp_country} onChange={(e)=>setStatesArray(parseInt(e.target.value))}  required>
                        <option value={0}>Select Country</option>
                        {countries.map((country,index)=>(
                          <option value={country.id}>{country.name}</option>
                        ))}
              </select>
            </div>
            <div className='col-md-3'>
            <label className="blue-color fw-bold">State</label>
              {/* <input className="form-control rounded" type="text" name="state" id="state" value={currentstate} onChange={(e)=>setCurrentState(e.target.value)}   required/> */}
              <select className='form-control rounded user_input_dashboard' name="emp_state" id="emp_state" value={emp_state} onChange={(e)=>setCityArray(parseInt(e.target.value))}   required>
              <option value={0}>Select State</option>
                        {states.map((state,index)=>(
                          <option value={state.id}>{state.name}</option>
                        ))}

              </select>
            </div>
            <div className='col-md-3'>
            <label className="blue-color fw-bold">City</label>
              {/* <input className="form-control rounded" type="city" name="city" id="city" value={city} onChange={(e)=>setCity(e.target.value)}  required /> */}
              <select className="form-control rounded user_input_dashboard" name="emp_city" id="emp_city" value={emp_city} onChange={(e)=>setEmpCity(parseInt(e.target.value))}  >
              <option value={0}>Select City</option>
                        {cities.map((city,index)=>(
                          <option value={city.id}>{city.name}</option>
                        ))}
              </select>
            </div>
           
          </div>:""}
          <hr className='my-5'/>
            <h3 className='blue-color my-4 fw-bold'>Speciality Interest</h3>
            
                <div className='row mt-3'>

                    <div className='col-md-6'>
                    <label className="blue-color fw-bold">Primary Speciality Interest<b className='text-danger'>*</b></label>
                        <select name="interested_speciality" id="interested_speciality" className='form-control rounded user_input_dashboard' value={speciality_id} onChange={(e)=>setSpecialityId(parseInt(e.target.value))} >
                          <option value={0}>Select Speciality </option>
                              {specialities.map((speciality)=>(
                               <option value={speciality.id}>{speciality.speciality}</option>
                                  ))}
              </select>
                    </div>
                    <div className='col-md-6'>
                    <label className="blue-color fw-bold">Secondary Speciality Interest</label>
                        <select name="interested_speciality2" id="interested_speciality2" className='form-control rounded user_input_dashboard' value={speciality_id2} onChange={(e)=>setspecialities2(parseInt(e.target.value))} >
                          <option value={0}>Select Speciality </option>
                              {specialities.map((speciality)=>(
                               <option value={speciality.id}>{speciality.speciality}</option>
                                  ))}
              </select>
                    </div>
                   
                </div>
            <ToastContainer/>
            <div className='my-5 text-center'><button type="button" onClick={saveDetails} className='w-25 btn text-white bg-blue'>Update</button></div>
            <br/>
            <br/>
         </form>

        </div>    {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
</div>
  )
}

export default UserEmloymentAndSpecialityEdiT