import React,{useState,useEffect} from 'react'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link} from 'react-router-dom';
import axios from 'axios';
import UserSideBar from '../../pages/UserSideBar/UserSideBar';
import UserHeader from '../../pages/UserHeader/UserHeader';
import {UserData} from '../../@types/usertype';
import { CollegeData } from '../../@types/collegedata';
import validator from 'validator';
import server from '../Utils';
import Loader from './Loader';
import UserProgressBar from '../UserProgressBar';
import Swal from 'sweetalert2';


const GMC_And_Intenship = () => {
  var loggedIn=localStorage.getItem('loggedIn');

  const [pmq_status,setPmQStatus]=useState<number>(0);
  const [gmc_reference_number_exist,setGmcReferenceNumberExist]=useState<number>(0)
  const [gmc_reference_number,setGmcReferenceNumber]=useState<string>('')
  const [loading, setLoading] = useState(false);
  const [first_name,setfirstName]=useState<string>("");
  const [last_name,setlastName]=useState<string>('');
  const [user_email,setUserEmail]=useState<string>('')
  const [form_progress,setFormProgress]=useState<number>(0)
  const [submission,setSubmission]=useState<boolean>(false);

  if(loggedIn!=="true")
  {
    window.location.href="/userlogin";
  }
  useEffect(() => {
    let userid= localStorage.getItem('ClientID');
    setLoading(true)
    server.get(`/operationuser/${userid}`)
  .then((response)=>{
    
    if(response.data.code===200){
      setfirstName(response.data.user.first_name)
      setlastName(response.data.user.last_name)
      setUserEmail(response.data.user.user_email)
    if(response.data.user.pmq_status===null){
      setPmQStatus(0);
    }else{
      setPmQStatus(response.data.user.pmq_status);
    }
    if(response.data.user.gmc_refrence_exists===null){
      setGmcReferenceNumberExist(0);
    }else{
      setGmcReferenceNumberExist(response.data.user.gmc_refrence_exists);
    }if(response.data.user.gmc_reference_number===null){
      setGmcReferenceNumber('')
     }else{
      setGmcReferenceNumber(response.data.user.gmc_reference_number)
     }
     if(response.data.user.form_progress!==null){
      setFormProgress(response.data.user.form_progress)
    }else{
      setFormProgress(0)
    }
     setLoading(false)
    
    }
})
    
  }, []);
 


  if (loading) {
    return <Loader />;
  }

const  updateDetails = (e:React.FormEvent<HTMLFormElement>) =>{
  e.preventDefault();
  
  
  var userid= localStorage.getItem('ClientID');
   
    
  var len=gmc_reference_number.toString().length;
  
  if(gmc_reference_number_exist===0)
  {
    toast('Choose whether GMC reference number exists?')
  } else if(gmc_reference_number_exist===null)
  {
    toast('Choose whether GMC reference number exists?')
  }
  else if(gmc_reference_number_exist===1 &&  gmc_reference_number===null){
    toast('enter gmc reference Number');
  }else if(gmc_reference_number_exist===1 &&  gmc_reference_number===""){
    toast('enter gmc reference Number');
  }else if(gmc_reference_number_exist===1 &&  gmc_reference_number===null){
    toast('enter gmc reference Number');
  } else if(gmc_reference_number_exist===1 && len!==7) {
    
     toast('GMC Reference Number has to be 7 digits number')
  }
  
  else{
    var form = document.getElementById('gmc_and_intenship') as HTMLFormElement
    var formData = new FormData(form);
    setLoading(true);
    setSubmission(true)
    server.post(`/gmc_and_intenship/${userid}`,formData)
    .then((response)=>{
        if(response.data.code===200){
          setLoading(false)
            toast(response.data.message) 
            if(loading===false){
            setTimeout(function(){ window.location.href="/employment_and_speciality"; }, 100);
            }
        }else{
          setLoading(false)
            toast(response.data.message) 
        }
    })
  }


} 

const saveDetails=()=>{
  
  var userid= localStorage.getItem('ClientID');
   
    
  var len=gmc_reference_number.toString().length;
  
  if(gmc_reference_number_exist===0)
  {
    toast('Choose whether GMC reference number exists?')
  } else if(gmc_reference_number_exist===null)
  {
    toast('Choose whether GMC reference number exists?')
  }
  else if(gmc_reference_number_exist===1 &&  gmc_reference_number===null){
    toast('enter gmc reference Number');
  }else if(gmc_reference_number_exist===1 &&  gmc_reference_number===""){
    toast('enter gmc reference Number');
  }else if(gmc_reference_number_exist===1 &&  gmc_reference_number===null){
    toast('enter gmc reference Number');
  } else if(gmc_reference_number_exist===1 && len!==7) {
    
     toast('GMC Reference Number has to be 7 digits number')
  }
  
  else{
    var form = document.getElementById('gmc_and_intenship') as HTMLFormElement
    var formData = new FormData(form);
    setLoading(true);
    setSubmission(true);
    server.post(`/gmc_and_intenship/${userid}`,formData)
    .then((response)=>{
        if(response.data.code===200){
          setLoading(false)
          Swal.fire({
            title:"Details Saved!",
            text: "GMC Details Saved Successfully",
            icon: "success"
          });
        }else{
          setLoading(false)
            toast(response.data.message) 
        }
    })
  }
}


  return (
 
<div className='container-fluid desktop_container' >
    <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name}/>
<div className='horizontal-menu' style={{background:"transparent"}}>

<div className='container-fluid mt-3'>
<div className='row my-5'>
          <div className='col-md-3 text-center px-4'><h2 className='blue-color'><b>GMC Details</b></h2></div>
          <div className='col-md-9 mt-2 px-5'><UserProgressBar form_progress={form_progress}/></div>
      </div>
 
  
  </div>
  <div className='user_foarm_board userpath_right_pad mx-3 pt-2'>
      <h3 className='blue-color fw-bold my-4'>GMC Details</h3>
          <form className='w-100' id="gmc_and_intenship" onSubmit={updateDetails}>
          <input type='hidden' name="form_progress" id="form_progress" value={5}/>
            <div className='row'>
                <div className='col-md-4'>
                    <label className="blue-color fw-bold">Do you have a GMC Reference Number?<b className='text-danger'>*</b></label>
                    <select className='form-control rounded  user_input_dashboard' id="gmc_reference_number_exist" name="gmc_refrence_exists" value={gmc_reference_number_exist} onChange={(e)=>{setGmcReferenceNumberExist(parseInt(e.target.value))}}>
                        <option value={0}>Choose</option>
                        <option value={1}>Yes</option>
                        <option value={2}>No</option>
                    </select>
                </div>
                {gmc_reference_number_exist===1?<div className='col-md-4'>
                    <label className="blue-color fw-bold">Enter GMC Reference Number<b className='text-danger'>*</b></label>
                    <input className='form-control rounded  user_input_dashboard' type="number" id="gmc_reference_number" name="gmc_reference_number" value={gmc_reference_number} onChange={(e)=>setGmcReferenceNumber(e.target.value)} required  />
                </div>:<div className='col-md-6'></div>}
            </div>
           
            
            <ToastContainer/>
            <div className='my-5 user_align_mobile'><Link to="/language_test" className='btn bg-blue text-white'>Previous</Link>&nbsp;&nbsp;&nbsp;<button type="button" onClick={saveDetails} className='btn bg-blue text-white'>Save</button>&nbsp;&nbsp;&nbsp;<button type="submit" disabled={submission === true} className='btn bg-blue text-white'>Save & Next</button></div>
            <br/>
            <br/>
         </form>

        </div>
        </div>

</div>


    
  )
}

export default GMC_And_Intenship