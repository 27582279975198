import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { message } from 'antd';
import {useParams} from 'react-router-dom';
import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import $ from 'jquery'; 
import './onboarding.css';
import validator from 'validator';
import server from '../Utils';
import UserInfo from '../UserInfo';
import Loader from './Loader';
 
const EditOnBoardDetails = () => {
    const {id}= useParams();
    const onboarding_id=id;
    
    const [project_id,setProjectId]=useState<number>(0);
    const [product_id,setProductId]=useState<number>(0);
    const [admins,setAdmins]=useState<Array<Admintype>>([]);
    const [adminid,setAdminId]=useState<number>(0);
    const [welcome_call_by,setWelcomeBy]=useState<number>(0);
    const [welcome_kits_online,setWelcomeKitOnline]=useState<Array<welcomekittype>>([]);
    const [welcome_kits_offline,setWelcomeKitOffline]=useState<Array<welcomekittype>>([]);
    const [welcome_kit_types,setWelcomeKitTypes]=useState<Array<welcomekittype>>([]);
    const[welcome_call_completed,setWelcomeCallCompleted]=useState<number>(0);
    const [welcome_call_date,setWelcomeCallDate]=useState<string>('');
    const [update_onboarding_form,setUpdateOnboarding]=useState<number>(0);
    const [sent_for_delivery,setSentForDelivery]=useState<number>(0);
    const [sent_for_delivery_date,setSentForDeliverDate]=useState<string>('');
    const [delivered,setDelivered]=useState<number>(0);
    const [delivery_date,setDeliveryDate]=useState<string>("");
    const [clear,setClear]=useState<number>(1);
    const [onboardid,setOnboardId]=useState<number>(0);
    const [start_training,setStartTraining]=useState<number>(0);
    const [start_training_date,setStartTrainingDate]=useState<string>('');
    const [pass,setPass]=useState<number>(1);
    const[online,setOnline]=useState<Array<number>>([]);
    const[offline,setOffline]=useState<Array<number>>([]);
    const [online_date,setOnlineDate]=useState<Array<string>>([]);
    const [dispatch_status,setDispatchStatus]=useState<number>(0);
    const [delivery_status,setDeliveryStatus]=useState<number>(0);
    const [reason,setReason]=useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [user_id,setUserId]=useState<number>(0);
    let  admin_id:string=JSON.parse(localStorage.getItem("admin_id")||'{}');
    useEffect(() => {
       
        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let id = +projectid;
        let productid:string= JSON.parse(localStorage.getItem("product_id") || "{}");
        setProjectId(id) ;
        setProductId(+productid)
        
        
        server.get(`/get_onboarding_details/${onboarding_id}`)
        .then((response)=>{
            
           setWelcomeBy(response.data.onboarding.welcome_call_by)
      
           setUserId(response.data.onboarding.user_id)
           setWelcomeCallCompleted(response.data.onboarding.welcome_call_completed)
           setWelcomeCallDate(response.data.onboarding.welcome_call_date)
           setOnline(response.data.onboarding.online)
           setOffline(response.data.onboarding.offline)
           setOnlineDate(response.data.onboarding.online_date)
           setUpdateOnboarding(response.data.onboarding.form_updated)
           setDispatchStatus(response.data.onboarding.dispatch_status)
           setSentForDeliverDate(response.data.onboarding.sent_for_delivery_date)
           setDeliveryStatus(response.data.onboarding.delivery_status)
           setDeliveryDate(response.data.onboarding.delivery_date)
           setReason(response.data.onboarding.reason)
           
        })

        server.get(`/get_operations`)
        .then((response)=>{
            setAdmins(response.data.admins)
        })
        server.get(`/get_welcomekit_products_online`)
        .then((response)=>{
          
            
            setWelcomeKitOnline(response.data.welcomekitproducts)
    
        })
        server.get(`/get_welcomekit_products_offline`)
        .then((response)=>{
          
            setWelcomeKitOffline(response.data.welcomekitproducts)
    
        })
        server.get(`/get_welcomekit_types`)
        .then((response)=>{
          
            setWelcomeKitTypes(response.data.welcome_kit_type)
    
        })
       $('.hide').hide();
      }, []);

      const getCurrentDate = () => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const year = today.getFullYear();
    
        const formattedMonth = month < 10 ? `0${month}` : `${month}`;
        const formattedDay = day < 10 ? `0${day}` : `${day}`;
    
        return `${year}-${formattedMonth}-${formattedDay}`;
      };
      for(let i=0;i<=online.length;i++){

      
    //   var element =document.getElementById('online'+i) as HTMLInputElement;
      
    //   if($('#online'+i).is(':checked')){
    //     console.log("true",i)
    //     $('#online_date'+i).removeClass('hide')
    //     $('#online_date'+i).addClass('show')
    //   }
    }
    
 if (loading) {
    return <Loader />;
  }
    const  showDate =(type:number,index:number)=>{
        
        if(type===2){
    var element =document.getElementById('onlinedate'+index) as HTMLInputElement;
     element.value="";
     element.toggleAttribute('checked')
     element.classList.toggle('hide');
     element.classList.toggle('show');
    }
     }


    const  AddClientOnBoardDetails=(e:React.FormEvent<HTMLFormElement>)=>{
        e.preventDefault();
        

        if(welcome_call_by===0){
            toast('please Select Executive');
        }else if(welcome_call_date===""||welcome_call_date===null){
            toast("Please Enter Welcome Call Date");
        }
        else  if(welcome_kits_online.length>0)
        {
            for(var i=1;i<=welcome_kits_online.length;i++) {
                var element=document.getElementById('online'+i) as HTMLInputElement;
                if(element.value!==""||element.value!==null){
                   if(element.checked===true){
                    var date=document.getElementById('onlinedate'+i) as HTMLInputElement;
                    if(date.value==="" ||date.value===null){
                        toast('please select the date online product'+i);
                        setClear(0)
                    }else{
                        setClear(1)
                    }
                   }
                }                
            } 
        }
       
        if(clear===1){   
            // setLoading(true) 
        let form = document.getElementById('addclientonboarddetails') as HTMLFormElement;
        let formdata=new FormData(form);
        server.post(`/update_onboarding/${onboarding_id}`,formdata)
        .then((response)=>{
                if(response.data.code===200){
                    // setLoading(false)
                    toast(response.data.message)
                    setUpdateOnboarding(1);
                    
                    setOnboardId(response.data.id);
                }
        })
    }else{
        setLoading(false)
        toast('could not be added')
    }
    
    }

    const addOtherDetails=(e:React.FormEvent<HTMLFormElement>)=>{
        e.preventDefault();
      
       if(dispatch_status===2){
            if(sent_for_delivery_date===""||sent_for_delivery_date===null){
                toast("Enter the Date for Delivery sent.")
                setPass(0)
                
            }
        }
         if(delivery_status===2){
            
            if(delivery_date ===""||delivery_date ===null){
                toast("Enter the date of delivery.")
                setPass(0)
               
            }
        } else {
            setPass(1);
            
        }
        
    
       
        if(pass===1){
        
            var form= document.getElementById('update_onboarding') as HTMLFormElement
            var formdata= new FormData(form);
            server.post(`/add_onboarding_update`,formdata)
            .then((response=>{
                if(response.data.code===200){
                    toast(response.data.message)
                    setTimeout(function () { window.history.back(); }, 1000);
                   
                }else{
                    toast(response.data.message)
                }
            }))
        }
    }
     let offline_array = new Array();
     let count=0;
     
     welcome_kits_offline.map((item, index) => {
        const isChecked = offline.includes(item.id); // Check if item.id exists in offline array
        
        offline_array.push(
          <div className='row' key={index}> {/* Ensure each row has a unique key */}
            <div className='col-md-4'>
              <label>{item.welcome_kit_name}</label>
            </div>
            <div className='col-md-4'>
              <input
                type="checkbox"
                name={"offline" + (index + 1)}
                value={item.id}
                id={"offline" + (index + 1)}
                checked={isChecked} 
                onChange={() => handleCheckboxChange(item.id)}
              />
            </div>
          </div>
        );
      });

      const handleCheckboxChange = (itemId:number) => {
        const updatedOffline = [...offline]; // Create a copy of the offline array
        const index = updatedOffline.indexOf(itemId); // Check if itemId exists in updatedOffline array
      
        if (index !== -1) {
          // If itemId exists, remove it from the array to uncheck the checkbox
          updatedOffline.splice(index, 1);
        } else {
          // If itemId does not exist, add it to the array to check the checkbox
          updatedOffline.push(itemId);
        }
      
        // Update the state of the offline array
        setOffline(updatedOffline);
      };

    let online_array=new Array();
    welcome_kits_online.map((item,index)=>{
         let online_count=0;
        if(online.includes(item.id)){
            online_count=online_count+1;
            online_array.push(<div className='row'>
                <div className='col-md-4'><label>{item.welcome_kit_name}</label></div>
                <div className='col-md-4'><input  type="checkbox" name={"online"+(index+1)} id={"online"+(index+1)}   value={item.id} onLoad={()=>showDate(2,(index+1))} onChange={()=>showDate(2,(index+1))} defaultChecked /></div>
               
                <div className='col-md-4'><input  className='form-control show' max={getCurrentDate()} type="date" value={online_date[online_count-1]} name={"onlinedate"+(index+1)} id={"onlinedate"+(index+1)}/> </div>
            </div>
            
            )
            
        }else if(!online.includes(item.id)){
            online_array.push(<div className='row'>
            <div className='col-md-4'><label>{item.welcome_kit_name}</label></div>
            <div className='col-md-4'><input  type="checkbox" name={"online"+(index+1)} id={"online"+(index+1)}  value={item.id} onChange={()=>showDate(2,(index+1))}  /></div>
           
        </div>
            )
            
        }
    })

    const handleOnlineCheckboxChange = (id: number) => {
        if (online.includes(id)) {
            setOnline(online.filter(itemId => itemId !== id));
        } else {
            setOnline([...online, id]);
        }
    };

    const handleOnlineDateChange = (value: string, index: number) => {
        const updatedOnlineDate = [...online_date];
        updatedOnlineDate[index] = value;
        setOnlineDate(updatedOnlineDate);
    };
    
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}

<UserInfo/>
    <h4 className='text-danger text-center'>Edit Client Onboarding Details</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5 jumbotron mx-2'>
  
   
    <div className='col-md-12 px-5'>
        <form id="addclientonboarddetails" onSubmit={AddClientOnBoardDetails}  encType='multipart/form-data' className='w-100'>
         <div className='container-fluid'>
            <input type="hidden" name="user_id" id="user_id" value={user_id} />
            <input type="hidden" name="project_id" id="project_id" value={project_id} />
            <input type="hidden" name="product_id" id="product_id" value={product_id} />
            <input type="hidden" name="onboard_id" id="onboard_id" value={onboarding_id} />
            <input type="hidden" name="admin_id" id="admin_id" value={admin_id}/>
            <div className='row'>
       
                <div className='col-md-3'>
                    <label>Welcome Call By</label>
                    <select className='form-control' id="welcome_call_by" name="welcome_call_by" value={welcome_call_by} onChange={(e)=>setWelcomeBy(parseInt(e.target.value))}>
                       <option value="0">Select Executives</option>
                        {admins.map((admin)=>(
                            <option value={admin.id}>{admin.first_name}</option>
                        ))}
                    </select>
                </div>
                <div className='col-md-3'>
                    <label>Welcome Call Completed</label><br/>
                    {welcome_call_completed===1?<input type="checkbox" name="welcome_call_completed" id="welcome_call_completed" value={welcome_call_completed} onChange={(e)=>setWelcomeCallCompleted(0)} checked />:<input type="checkbox" name="welcome_call_completed" id="welcome_call_completed" value={welcome_call_completed} onChange={(e)=>setWelcomeCallCompleted(1)} />}
                    
                </div>
                {welcome_call_completed===1? <div className='col-md-3'>
                <label>Welcome Call Completed Date</label><br/>  
                <input type='date' className='form-control' max={getCurrentDate()} name="welcome_call_date" id="welcome_call_date" value={welcome_call_date} onChange={(e)=>setWelcomeCallDate(e.target.value)} />
                </div>:""}
                <div className='col-md-12 mt-3'>
           
                <input type="hidden" name="online_length" value={welcome_kits_online.length} />
                <input type="hidden" name="offline_length" value={welcome_kits_offline.length} />

                {welcome_call_completed===1?<div><h3 className='my-3'>To include in courier</h3></div>:""}
                {offline_array}
                
               
                {welcome_call_completed===1?<div>{online_array.length>0?<h3 className='my-3'>Online resourses given at the time of Onboarding</h3>:""}</div>:""}
                 {online_array}           
               
                {/* {welcome_call_completed===1  && welcome_kits_online.map((item,index)=>(
                    <div className='row my-2'>
                       
                        <div className='col-md-2'>
                            <h6>{item.welcome_kit_name}</h6></div>
                            
                   <div className='col-md-3 text-center'>
                        
                       {online.includes(item.id)? <input  type="checkbox" name={"online"+(index+1)} id={"online"+(index+1)}   value={item.id} onLoad={()=>showDate(2,(index+1))} onChange={()=>showDate(2,(index+1))} defaultChecked />: <input  type="checkbox" name={"online"+(index+1)} id={"online"+(index+1)}  value={item.id} onChange={()=>showDate(2,(index+1))}  />}</div>
                       {online.includes(item.id)?<div className='col-md-4'><input  className='form-control show' type="date" value={online_date[index-1]} name={"onlinedate"+(index+1)} id={"onlinedate"+(index+1)}/> </div>:<div className='col-md-4'><input  className='form-control hide' type="date" value={online_date[index]} name={"onlinedate"+(index+1)} id={"onlinedate"+(index+1)}/> </div>}
                    </div>
                ))} */}

                
               
                </div>
                
                
            </div>

         </div>

            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-info text-light mt-2'>Update Details</button></div>
        </form>
       {update_onboarding_form===1?<form id="update_onboarding" onSubmit={addOtherDetails} className='w-100'>
       <input type="hidden" name="user_id" id="user_id" value={id} />
            <div className='row my-5'>
                <div className='col-md-2'>
                    <label>Courier Status</label>
                    <select className='form-control' name="dispatch_status" id="dispatch_status" value={dispatch_status} onChange={(e)=>setDispatchStatus(parseInt(e.target.value))}>
                        <option value={1}>Not Sent</option>
                        <option value={2}>Sent For Delivery</option>
                       
                        
                    </select>
                </div>
                {dispatch_status===2?  <div className='col-md-2'>
                    <input type="hidden" name='onboard_id' id="onboard_id" value={onboardid}  />
                    <label>Sent For Delivery</label>&nbsp;&nbsp;&nbsp;&nbsp;
                     {/* <input type="checkbox" value={sent_for_delivery}  name={"sent_for_delivery"} id={"sent_for_delivery"} onChange={(e)=>sent_for_delivery===0?setSentForDelivery(1):setSentForDelivery(0)} />   */}
                    <input type="date"  className='form-control'  name={"sent_for_delivery_date"}  value={sent_for_delivery_date} onChange={(e)=>setSentForDeliverDate(e.target.value)} />
                  
                </div>:""}
                {dispatch_status===2?<div className='col-md-2'>
                    <label>Delivery Status</label>
                    <select className='form-control' id="delivery_status" name={"delivery_status"} value={delivery_status} onChange={(e)=>setDeliveryStatus(parseInt(e.target.value))}>
                        <option value={1}>In Transit</option>
                        <option value={2}>Delivered</option>
                        <option value={3}>Not Delivered</option>
                        
                    </select></div>:""}
                {delivery_status===2?<div className='col-md-2'>
                    <label> Delivered</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                     {/* <input type="checkbox" value={delivered}  id="delivery" name="delivery" onChange={(e)=>delivered===0?setDelivered(1):setDelivered(0)} />   */}
                    <input type="date"  className='form-control' name={"delivery_date"} id="delivery_date" value={delivery_date} onChange={(e)=>setDeliveryDate(e.target.value)} />
                </div>:""}
                {delivery_status===3?<div className='col-md-3'>
                    <label>Reason for failure of delivery</label>
                    <select className='form-control' name={"reason"} id="reson" value={reason} onChange={(e)=>setReason(parseInt(e.target.value))}>
                    <option value="1">Returned Due to Wrong Details</option>
                    <option value="2">Package Lost</option>
                    </select>
                </div>:""}

                {/* <div className='col-md-2'>
                    <label> Start Training</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                     <input type="checkbox" value={start_training}  id="start_training" name="start_training" onChange={(e)=>start_training===0?setStartTraining(1):setStartTraining(0)} />  
                    {start_training===1? <input type="date"  className='form-control' name={"start_training_date"} id="start_training_date" value={start_training_date} onChange={(e)=>setStartTrainingDate(e.target.value)} />:""}
                </div> */}
            </div>
            <div className='text-center'><button  type ="submit" className='btn btn-info text-center text-white mb-5'>Update Other Details</button></div>
        </form>:""} 
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default EditOnBoardDetails