import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { ProductData } from '../../@types/producttype';
  import {ProjectData} from '../../@types/projecttype';
  import {Link,useParams} from 'react-router-dom';
import server from '../Utils';
  import Loader from './Loader';

const EditStage = () => {
    
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [projects,setProjects]=useState<Array<ProjectData>>([])
    const [project_id,setProjectId]=useState<number>(0);
    const [loading, setLoading] = useState(false);

    const [product_id,setProductId]=useState<number>(0);
    const [stageid,setStageId]=useState<number>(0);
    const [stage,setStage]=useState<string>();
    const [error,setError]=useState<string>();
    const {id}= useParams();
    
    useEffect(() => {
        server.get(`/get_products`)
        .then((response)=>{
            setProducts(response.data.products)
    })
    // server.get(`/get_projects`)
    // .then((response)=>{
    //   setProjects(response.data.projects)

    // })
      }, []);

      let stage_id=id;
      server.get(`/stage/${stage_id}`)
      .then((response)=>{
          setProjectId(response.data.stage.project_id)
          setProductId(response.data.stage.product_id);
          setStage(response.data.stage.stage_name);
          setStageId(response.data.stage.stage_id);
           
})
    

const updateStage=(e:React.FormEvent<HTMLFormElement>)=>{
    e.preventDefault();
    var regEx = /^[A-Za-z]+$/;
    var name:any = stage;
    if(project_id===0)
    {
    setError("Please Select the project"); 
    toast(error);
    }
   else if(product_id===0)
   {
    setError("Please Select the product"); 
    toast(error);
   }else
    {
        setError('');
        var token=localStorage.getItem('auth_token');
        var form=document.getElementById('update_stage_form') as HTMLFormElement;
            var formdata=new FormData(form);
        server.post(`/stage/${stage_id}`,formdata,{ headers: { 'Authorization': 'Bearer '+token } })
        .then((response)=>{
            if(response.data.code===200)
            {
                toast(response.data.message)
                setTimeout(function () { window.history.back(); }, 1000);
            }else
            {
                toast(response.data.message)
            }
        })
    }
}
if (loading) {
    return <Loader />;
  }   
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}

    <h4 className='text-danger text-center'>Edit Stage</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>
    <div className='col-md-1'></div>
    <div className='col-md-10'>
        <form id="update_stage_form" onSubmit={updateStage} className='w-100'>
            <div className='row'>
            {/* <div className='col-md-4'>
                <label>Projects</label>
            <select className='form-control' name='project_id' id="project_id" defaultValue={project_id} onChange={(e)=>setProjectId(parseInt(e.target.value))} required> 
                        <option value={0}> Select Project</option>
                        {projects.map((project)=>(
                            <option value={project.id}>{project.project_name}</option>))}
            </select>
            </div> */}
              <input type="hidden" name="project_id"  id="project_id" value={project_id} />
                <div className='col-md-4'>
                    <label >Products</label>
                    <select className='form-control' value={product_id} onChange={(e)=>setProductId(parseInt(e.target.value))} name='product_id' id="product_id" required>
                        <option value={0}>Select the Products</option>
                        
                        {products.map((pack,index)=>(
                            <option value={pack.id}>{pack.product_name}</option>))}
                    </select>
                </div>
                <div className='col-md-4'>
                    <label >Stage</label>
                    <input className='form-control' type="text" placeholder='Enter Stage' defaultValue={stage}  name="stage_name" id="stage_name" required />
                </div>
               
            </div>
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Submit</button></div>
        </form>
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default EditStage