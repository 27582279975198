
import React,{useEffect, useState} from 'react';

import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link} from 'react-router-dom';
import { ProjectData } from '../../@types/projecttype';
import server from '../Utils';
import './SuperAdminDashboard.css';

const SuperAdminInnerDashboard = () => {
  const [projects,setProjects]=useState<Array<ProjectData>>([])
  var isloggedIn=localStorage.getItem("isLoggedIn");
  var role=localStorage.getItem('role');
  
   
  useEffect(() => {
    if (isloggedIn !== "true") {
      
      window.location.href='/';
    }
    server.get(`/get_projects`)
    .then((response)=>{
      setProjects(response.data.projects)

    })
  }, []);
  
  const redirectLink=(id:any)=>{
   
    localStorage.setItem('project_id',id)
   window.location.href="/superadminsecondary"
  }

  return (
    <div className='card-columns px-2'>

      {projects.map((project,index)=>(
         (index % 3)===0?<div className="card py-5 hover" style={{backgroundColor:"#146b71"}} onClick={(e)=>{redirectLink(project.id)}} ><div className="card-body text-center text-light">{project.project_name}</div></div>:(index % 3)===1?<div className="card py-5 hover" style={{backgroundColor:"#32c3cd"}} onClick={(e)=>redirectLink(project.id)} ><div className="card-body text-center text-light">{project.project_name}</div></div>:<div className="card bg-danger py-5 hover" ><div className="card-body text-center  text-light" onClick={(e)=>redirectLink(project.id)}>{project.project_name}</div></div>
      ))}
    </div>
  )
}

export default SuperAdminInnerDashboard