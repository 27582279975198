import React,{useState,useEffect} from 'react'
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link,useParams} from 'react-router-dom';
import axios from 'axios';
import { StageData } from '../../@types/stagetype';
import {ProductData} from '../../@types/producttype';
import { SubStageData } from '../../@types/substagetype';
import { ProjectData } from '../../@types/projecttype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import server from '../Utils';
import { service_categorytypedata } from '../../@types/service_categorytypedata';
import Loader from './Loader';
const EditService = () => {
   
    const [stages,setStages]=useState<Array<StageData>>([])
    const[stage_id,setStageId]=useState<number>(0);
    const [status,setStatus]=useState<number>(1)  
    const [substages,setSubStages]=useState<Array<SubStageData>>([])
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [projects,setProjects]=useState<Array<ProjectData>>([])
    const [project_id,setProjectId]=useState<number>(0);
    const [product_id,setProductId]=useState<number>(0)
    const [package_id,setPackageId]=useState<number>(0);
    const [substageid,setSubStageId]=useState<number>(0)
    const [error,setError]=useState<string>('')
    const [service,setService]=useState<string>('')
    const[service_price,setServicePrice]=useState<string>('')
    const[service_counts,setServiceCount]=useState<number>()
    const [servicecategories,setServiceCategories]=useState<Array<ServiceCategoryType>>([])
    const [service_category_type,setServiceCategorytypeId]=useState<number>(0)

    const [service_category,setServiceCategory]=useState<number>(0)
    const[service_type,setServiceType]=useState<number>(0);
    const [service_display,setServiceDisplay]=useState<number>(0)
    const [service_category_types,setServiceCategorytypes]=useState<Array<service_categorytypedata>>([])
    const [loading, setLoading] = useState(false);


   
    const {id}=useParams();
    let service_id=id;
    let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
    useEffect(() => {
        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let project_id= +projectid
        
        server.get(`/get_sub_stages/${project_id}`)
        .then((response)=>{
            
            setSubStages(response.data.substages)
        })
        server.get(`/get_service_categories/${projectid}`)
        .then((response)=>{
            console.log(response)
            if(response.data.service_categories.length>0){
                setServiceCategories(response.data.service_categories)
            }else{
                setServiceCategories([]);
            }
        })
        server.get(`/get_projects`)
        .then((response)=>{
          setProjects(response.data.projects)
  
        })
       
        server.get(`/service/${service_id}`)
        .then((response)=>{
          setProductId(parseInt(response.data.service.product_id))
          
          setPackageId(parseInt(response.data.service.product_id))
          server.get(`/get_stages/${projectid}/${response.data.service.product_id}`)
        .then((response)=>{
            setStages(response.data.stages)
  
        })
        setStageId(parseInt(response.data.service.stage_id))
          setSubStageId(parseInt(response.data.service.sub_stage_id))
          setService(response.data.service.service_name)
          setServicePrice(response.data.service.service_price)
          setServiceCount(parseInt(response.data.service.service_counts))
          setServiceType(parseInt(response.data.service.service_type))
          setServiceCategory(parseInt(response.data.service.service_category))
          setServiceCategorytype(response.data.service.service_category)
          setServiceDisplay(parseInt(response.data.service.service_display))
          setServiceCategorytypeId(response.data.service.service_category_type)

  
        })
       
       
        let  service_categoryid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
    let id= +service_categoryid
        setProjectId(id)
        server.get(`/get_product/${id}`)
        .then((response)=>{
            
            setProducts(response.data.product)
            
        })
      
    
        server.get(`/get_projects`)
        .then((response)=>{
          setProjects(response.data.projects)
  
        })
      }, []);

      


      
      const setStage=(id:number)=>
      {
        console.log(id)
        
        setProductId(id);
        server.get(`/get_service_categories_all/${projectid}/${id}`)
        .then((response)=>{
            console.log(response)
            if(response.data.service_categories.length>0){
                setServiceCategories(response.data.service_categories)
            }else{
                setServiceCategories([]);
            }
        })
          server.get(`/get_stage/${id}`)
          .then((response)=>{
              
            setStages(response.data.stage);
              
          })
      }
      
      const setSubStage=(id:number)=>
      {
        console.log(id)
        setStageId(id);
        server.get(`/get_sub_stage/${id}`)
        .then((response)=>{
              
            setSubStages(response.data.sub_stage);
              
          })
      }
    
  
    const updateService=(e:React.FormEvent<HTMLFormElement>)=>
      {
        e.preventDefault();
        var regEx = /^[A-Za-z]+$/;
        var name:any = service;
        if(project_id===0)
        {
            setError("Select Project"); 
         toast(error);
        }
        else if(product_id===0)
        {
         setError("Select Package"); 
         toast(error);
        }
        else if(stage_id===0)
       {
        setError("Select Stage"); 
        toast(error);
       }else if(substageid===0)
       {
        setError("Select the SubStage"); 
        toast(error);
       }else if(service_counts===0 || service_counts===null){
        toast('please select the number of counts')
       }else{
        var form=document.getElementById('update_service_form') as HTMLFormElement ;
        var formData= new FormData(form);
        server.post(`/service/${service_id}`,formData)
        .then((response)=>{
            console.log(response.data)
            if(response.data.code===200)
            {
                toast(response.data.message)
                setTimeout(function () { window.location.href = "/services"; }, 1000);
                
            }else
            {
                toast(response.data.message)
            }
        })
       }

      }


      const setServiceCategorytype=(id:number)=>{
        setServiceCategory(id)
        server.get(`/service_category_type_on_category/${id}`)
        .then((response)=>{
            console.log(response)
            if(response.data.service_category_types.length>0){
                setServiceCategorytypes(response.data.service_category_types)
            }else{
                setServiceCategorytypes([]);
            }
        })
    }


    
    if (loading) {
        return <Loader />;
      }
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
            <div className='headerbar'>
        <Header/>
        {/* Content Row */}

    <h4 className='text-danger text-center'>Edit Service</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>
   
    <div className='col-md-12 ml-4'>
      
        <form id="update_service_form" onSubmit={updateService} className='w-100'><div className='row'>
           <input type="hidden" name="project_id" id="project_id" value={project_id} />
            {project_id!==0?<div className='col-md-3'>
            <label >Products</label>
                     <select className='form-control' name='product_id' id="product_id" value={product_id} onChange={(e)=>setStage(parseInt(e.target.value))} required>
                        <option value={0}>Select Product</option>
                        {products.map((product)=>(
                            <option value={product.id}>{product.product_name}</option>))}
            </select>
            </div>:""}
            {product_id!==0? <div className='col-md-3'>
                    <label >Stages</label>
                    <select className='form-control' name='stage_id' id="stage_id" value={stage_id} onChange={(e)=>setSubStage(parseInt(e.target.value))} required>
                        <option value={0}>Select Stage</option>
                        {stages.map((stage,index)=>(
                            <option value={stage.id}>{stage.stage_name}</option>))}
                    </select>
                </div>:""}

                {stage_id!==0? <div className='col-md-3'>
                    <label>SubStage</label>
                    <select className='form-control' name="sub_stage_id" id="sub_stage_id" value={substageid} onChange={(e)=>setSubStageId(parseInt(e.target.value))}>
                   <option value={0}>Select SubStage</option>
                    {substages.map((stage,index)=>(
                            <option value={stage.id}>{stage.sub_stage_name}</option>))}
                </select></div>:""}
                {substageid!==0?<div className='col-md-3'>
                <label>Service Category</label>
                <select className='form-control' value={service_category} onChange={(e)=>setServiceCategorytype(parseInt(e.target.value))} name="service_category">
                <option value={0}>Select Service Category</option>
                {servicecategories.map((servicecategory,index)=>(
                    
                    <option value={servicecategory.id}>{servicecategory.service_category_name}</option>
                ))}
                 </select>
               </div>:""}
                
            </div>
            {substageid!==0? <div className='row mt-4'>
            {service_category!==0? <div className='col-md-3'>
                    <label>Service Category Types</label>
                    <select className='form-control' name="service_category_type" id="service_category_type" value={service_category_type} onChange={(e)=>setServiceCategorytypeId(parseInt(e.target.value))}>
                   <option value={0}>Select Category Types</option>
                    {service_category_types.map((service_category_type,index)=>(
                            <option value={service_category_type.id}>{service_category_type.service_category_type}</option>))}
                </select></div>:""}
                <div className='col-md-2'><label>Service Name</label><input className='form-control' type="text" name="service_name" id="service_name" value={service} onChange={(e)=>setService(e.target.value)} /></div>
                <div className='col-md-2'><label>Service Price</label><input className='form-control' type="number" name="service_price" id="service_price" value={service_price} onChange={(e)=>setServicePrice(e.target.value)} /></div>
                
                {status===1?<div className='col-md-2'><button className='btn btn-primary' onClick={(e)=>setStatus(0)}>Undefined</button><label>Number of Counts</label><input className='form-control' type="number" name="service_counts" id="service_counts" value={service_counts} onChange={(e)=>setServiceCount(parseInt(e.target.value))} /></div>:<div className='col-md-2'><label className='mt-4'>Undefined<input type="hidden" name="service_counts" value="20000000"/></label></div>}
                    <input type="hidden" name="count_status" id="count_status" value={status}/>
                    {/* <div className='col-md-3'><label>Service Display</label>
                     <select name='service_display' value={service_display} onChange={(e)=>setServiceDisplay(parseInt(e.target.value))} className='form-control'>
                       <option value={0}>Choose whether to display</option>
                       <option value={1}>Yes</option>
                       <option value={2}>No</option>
                       
                     </select>
                </div>  */}
                {/* {service_display===1? <div className='col-md-2'><label>Service Type</label>
                     <select name='service_type' value={service_type} onChange={(e)=>{setServiceType(parseInt(e.target.value))}} className='form-control'>
                       <option value={0}>Select service type</option>
                       <option value={1}>Fixed</option>
                       <option value={2}>UnFixed</option>
                     </select>
                </div>:"" } */}
                   
            </div>:""}
           <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Submit</button></div>
        </form>
     
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default EditService