
import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';

import {Link,NavLink,useParams} from 'react-router-dom';

import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { message } from 'antd';

import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import $ from 'jquery'; 

import validator from 'validator';
import { off } from 'process';
import { type } from 'os';
import { vendortype } from '../../@types/vendortype';
import TestBookings from '../TestBooking/TestBookings';

import { testbookingtype } from '../../@types/testbookingtype';
import Registrations from '../Registrations/Registrations';

import { ServiceCategoryType } from '../../@types/service_category_type';
import { ServiceData } from '../../@types/servicetype';
import { registrationtype } from '../../@types/registrationtype';
import { trainingsubcriptiontype } from '../../@types/training_and_subscription';
import moment from 'moment';
import server from '../Utils';
import Loader from'./Loader';
const TrainingAndSubscriptionDetails = () => {
    let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
    let pro_id= +projectid;

    const {id}= useParams();
    const [project_id,setProjectId]=useState<number>(0);
    const [user_exist,setUserExists]=useState<boolean>(false);
    const [training_exists,setTrainingExists]=useState<boolean>(false);
    const [test_booking_exists,setTestBookingExist]=useState<boolean>(false);
    const [test_bookings,setTestBooking]=useState<Array<testbookingtype>>([]);
    const [registration_exist,setRegistrationExist]=useState<boolean>(false);
    const [training_and_subscription_exist,setTrainingAndSubscriptionExist]=useState<boolean>(false);
    const [training_subscriptions,setTrainingSubscriptions]=useState<Array<trainingsubcriptiontype>>([])
    const [loading, setLoading] = useState(false);
    const [user_agreed,setUserAgreed]=useState<number>(0);


    useEffect(() => {
        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let pro_id= +projectid;
        server.get(`/operationuser/${id}`)
        .then((response)=>{
         
           if(response.data.user.user_agreement_flag===1){
             setUserAgreed(1)
           }else{
             setUserAgreed(0)
           }
        })
      server.get(`/test_bookings/${id}`)
      .then((response)=>{
        setTestBooking(response.data.test_bookings)
      })
         server.get(`/onboarding_exists/${id}`)
         .then((response)=>{
          
          if(response.data.code===200){
            setUserExists(true)
          }else{
            setUserExists(false)
          }
         })
  
         server.get(`/training_exists/${id}`)
         .then((response)=>{
          
          if(response.data.code===200){
            setTrainingExists(true)
          }else{
            setTrainingExists(false)
          }
         })
         server.get(`/test_booking_exists/${id}`)
         .then((response)=>{
          
          if(response.data.code===200){
            setTestBookingExist(true)
          }else{
            setTestBookingExist(false)
          }
         })
         server.get(`/registration_exists/${id}`)
         .then((response)=>{
          
          if(response.data.code===200){
            setRegistrationExist(true)
          }else{
            setRegistrationExist(false)
          }
         })
         server.get(`/training_and_subscriptions_details_exist/${id}`)
         .then((response)=>{
          
          if(response.data.code===200){
            setTrainingAndSubscriptionExist(true)
          }else{
            setTrainingAndSubscriptionExist(false)
          }
         })

         server.get(`/get_training_and_subscriptions_details/${id}`)
         .then((response)=>{
          setTrainingSubscriptions(response.data.training_and_subscriptions)
         })
      
        }, []);

        if (loading) {
          return <Loader />;
        }

  return (
    <div>
    {/* {user_exist===false?<Link to={`/add_onboarding_details/${id}`}  className='btn btn-info text-light text-capitalize'>Add Onboarding details before proceeding to Training</Link>:""}
    {user_exist===true &&  training_exists===false?<Link to={`/add_training_details/${id}`} className='btn btn-info text-white' >Add Training </Link>:""}
    {training_exists===true && test_booking_exists===false ?<Link to={`/add_test_booking_detail/${id}`} className='btn btn-info text-white' >Add Test Booking </Link>:""}
    {test_booking_exists===true && registration_exist===false ?<Link to={`/add_registration_detail/${id}`} className='btn btn-info text-white' >Add Registration </Link>:""} */}
  {/* <Link  className='btn btn-info text-light float-right' to={`/add_training_and_subscription/${id}`} >Add Training And Subscription</Link> */}
  {user_agreed === 1 ? 
  
  <Link to={`/add_training_and_subscription/${id}`} className='btn btn-info text-light float-right'>+</Link> :
<p className='text-danger'>Kindly ask the doctor to fill their details in their portal</p>
}
  <br/>
  <br/>

      {training_subscriptions.map((training_and_subscription,index)=>(
        <div className='py-4 jumbotron'>
          {training_and_subscription_exist===true?<Link to={`/edit_training_and_subscription/${training_and_subscription.id}`}><i className='fa fa-edit text-info'></i></Link>:""}
     
        <div className='row'>
         <div className='col-md-6'><h5>Training & Subscription for</h5></div>
         <div className='col-md-6'><i>{training_and_subscription.service_name}</i></div>
        </div>
        <div className='row'>
         <div className='col-md-6'><h5> Start Date</h5></div>
         <div className='col-md-6'><i>{moment(training_and_subscription.subscription_start_date).format('Do MMMM YYYY')}</i></div>
        </div>
        <div className='row'>
         <div className='col-md-6'><h5> Duration</h5></div>
         <div className='col-md-6'><i>{training_and_subscription.subscription_duration} {training_and_subscription.subscription_period===1?"Days":training_and_subscription.subscription_period===2?"Weeks":training_and_subscription.subscription_period===3?"Months":training_and_subscription.subscription_period===4?"Year":""}</i></div>
        </div>
        
        <div className='row'>
         <div className='col-md-6'><h5> User Name</h5></div>
         <div className='col-md-6'><i>{training_and_subscription.username}</i></div>
        </div>
        <div className='row'>
         <div className='col-md-6'><h5> Password</h5></div>
         <div className='col-md-6'><i>{training_and_subscription.password}</i></div>
        </div>
        
        </div>
      ))}

    </div>
  )
}

export default TrainingAndSubscriptionDetails;