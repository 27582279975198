import React, { useState, useEffect } from 'react';
import { useParams, Link, redirect } from 'react-router-dom';
import moment from 'moment';
import server from '../Utils';
import Loader from './Loader';
import { training_details } from '../../@types/training_details';
import UserSideBar from '../../pages/UserSideBar/UserSideBar';
import { FaRegQuestionCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { testbookingtype } from '../../@types/testbookingtype';
import { IoCheckmarkCircle } from "react-icons/io5";
import { FaCircleXmark } from "react-icons/fa6";
import { registrationtype } from '../../@types/registrationtype';
import { ServiceData } from '../../@types/servicetype';

import { ServiceCategoryType } from '../../@types/service_category_type';

const UserRegistrationDetails = () => {
    const [project_id,setProjectId]=useState<number>(0);
    const [user_exist,setUserExists]=useState<boolean>(false);
    const [training_exists,setTrainingExists]=useState<boolean>(false);
    const [test_booking_exists,setTestBookingExist]=useState<boolean>(false);
    const [test_bookings,setTestBooking]=useState<Array<testbookingtype>>([]);
    const [registration_exist,setRegistrationExist]=useState<boolean>(false);
    const[registrations,setRegistrations]=useState<Array<registrationtype>>([]);
    
    const [service_category_id,setServiceCategoryId]=useState<number>(0);
    const [service_categories,setServiceCategories]=useState<Array<ServiceCategoryType>>([])
    const [services,setServices]=useState<Array<ServiceData>>([])
    const [service_id,setServiceId]=useState<number>(0)
   
    //GMC
   const [gmc_reference_number,setGmcReferenceNumber]=useState<number>(0);
   const [gmc_login_password,setGmcLoginPassword]=useState<string>('');
   const [gmc_secret_question,setGmcSecretQuestion]=useState<string>('');
   const [gmc_secret_answer,setGmcSecretAnswer]=useState<string>('');
   const [gmc_exist,setGmcExist]=useState<number>(1);

   //IELTS
   const [ielts_login_id,setIeltsLoginId]=useState<string>('');
   const [ielts_login_password,setIeltsLoginPassword]=useState<string>('');
   const [ielts_secret_question,setIeltsSecretQuestion]=useState<string>('');
   const [ielts_secret_answer,setIeltsSecretAnswer]=useState<string>('');
   const [ielts_exist,setIeltsExist]=useState<number>(1);

   //OET

const [oet_login_id,setOetLoginId]=useState<string>('');
const [oet_login_password,setOetLoginPassword]=useState<string>('');
const [oet_secret_question,setOetSecretQuestion]=useState<string>('');
const [oet_secret_answer,setOetSecretAnswer]=useState<string>('');
const [oet_exist,setOetExist]=useState<number>(1);

//EPIC
const [epic_account_setup_date,setEpicAccountSetupDate]=useState<string>('');
const [epic_username,setEpicUsername]=useState<string>('');
const [epic_password,setEpicPassword]=useState<string>('');
const [epic_id,setEpicId]=useState<string>('');
const [epic_secret_question1,setEpicSecretQuestion1]=useState<string>('')
const [epic_secret_question2,setEpicSecretQuestion2]=useState<string>('')
const [epic_secret_question3,setEpicSecretQuestion3]=useState<string>('')
const [epic_secret_question4,setEpicSecretQuestion4]=useState<string>('')

const [epic_secret_answer1,setEpicSecretAnswer1]=useState<string>('')
const [epic_secret_answer2,setEpicSecretAnswer2]=useState<string>('')
const [epic_secret_answer3,setEpicSecretAnswer3]=useState<string>('')
const [epic_secret_answer4,setEpicSecretAnswer4]=useState<string>('')
const [epic_exist,setEpicExist]=useState<number>(1);

const[notarycam_username,setNotarycamUsername]=useState<string>('')
const[notarycam_password,setNotarycamPassword]=useState<string>('')
const [loading, setLoading] = useState(false);
const [user_agreed,setUserAgreed]=useState<number>(0);
    
    const [first_name,setfirstName]=useState<string>("");
    const [last_name,setlastName]=useState<string>('');
    const [user_email,setUserEmail]=useState<string>('')
   let id= localStorage.getItem('ClientID');
   var loggedIn=localStorage.getItem('loggedIn');
   useEffect(() => {
       if(loggedIn!=="true")
 {
   window.location.href="/userlogin";
 }
 setLoading(true)
        server.get(`/operationuser/${id}`)
        .then((response)=>{
            setfirstName(response.data.user.first_name)
            setlastName(response.data.user.last_name)
            setUserEmail(response.data.user.user_email)
           if(response.data.user.user_agreement_flag===1){
             setUserAgreed(1)
           }else{
             setUserAgreed(2)
           }
        })
      server.get(`/test_bookings/${id}`)
      .then((response)=>{
        setTestBooking(response.data.test_bookings)
      })
         server.get(`/onboarding_exists/${id}`)
         .then((response)=>{
          
          if(response.data.code===200){
            setUserExists(true)
          }else{
            setUserExists(false)
          }
         })
  
         server.get(`/training_exists/${id}`)
         .then((response)=>{
          
          if(response.data.code===200){
            setTrainingExists(true)
          }else{
            setTrainingExists(false)
          }
         })
         server.get(`/test_booking_exists/${id}`)
         .then((response)=>{
          
          if(response.data.code===200){
            setTestBookingExist(true)
          }else{
            setTestBookingExist(false)
          }
         })

         server.get(`/registrations/${id}`)
       .then((response)=>{
         setServiceCategoryId(response.data.registrations.service_category_id)
        
        setRegistrations(response.data.registrations)
        setServiceId(response.data.registrations.service_id)
        setGmcReferenceNumber(response.data.registrations.gmc_reference_number)
        setGmcLoginPassword(response.data.registrations.gmc_login_password)
        setGmcSecretQuestion(response.data.registrations.gmc_secret_question)
        setGmcSecretAnswer(response.data.registrations.gmc_secret_answer)
        setGmcExist(response.data.registrations.gmc_exist)
        setIeltsLoginId(response.data.registrations.ielts_login_id)
        setIeltsLoginPassword(response.data.registrations.ielts_login_password)
        setIeltsSecretQuestion(response.data.registrations.ielts_secret_question)
        setIeltsSecretAnswer(response.data.registrations.ielts_secret_answer)
        setIeltsExist(response.data.registrations.ielts_exist)
        setOetLoginId(response.data.registrations.oet_login_id)
        setOetLoginPassword(response.data.registrations.oet_login_password)
        setOetSecretQuestion(response.data.registrations.oet_secret_question)
        setOetSecretAnswer(response.data.registrations.oet_secret_answer)
        setOetExist(response.data.registrations.oet_exist)
        setEpicAccountSetupDate(response.data.registrations.epic_account_setup_date)
        setEpicUsername(response.data.registrations.epic_username)
        setEpicPassword(response.data.registrations.epic_password)
        setEpicId(response.data.registrations.epic_id)
        setEpicSecretQuestion1(response.data.registrations.epic_secret_question1)
        setEpicSecretQuestion2(response.data.registrations.epic_secret_question2)
        setEpicSecretQuestion3(response.data.registrations.epic_secret_question3)
        setEpicSecretQuestion4(response.data.registrations.epic_secret_question4)
        setEpicSecretAnswer1(response.data.registrations.epic_secret_answer1)
        setEpicSecretAnswer2(response.data.registrations.epic_secret_answer2)
        setEpicSecretAnswer3(response.data.registrations.epic_secret_answer3)
        setEpicSecretAnswer4(response.data.registrations.epic_secret_answer4)
        setEpicExist(response.data.registrations.epic_exist)
        setNotarycamUsername(response.data.registrations.notarycam_username)
        setNotarycamPassword(response.data.registrations.notarycam_username)

       })
       setLoading(false)
    }, [id]);

    if(loading===true){
      return <Loader/>
  }
    return (

        <div className='container-fluid desktop_container' >
    <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name}/>
    <div className='horizontal-menu' style={{background:"transparent"}}>

      <div className='container-fluid mt-4 userpath_right_pad user_foarm_board'>
      <div className='d-flex mb-2 mt-3 prog-info'>
        <div className='w-75 text-left'>
            <h1><b className='blue-color fw-bold'>Registrations </b></h1>
        </div>
        <div className='w-25 text-right blue-color' style={{ fontSize: "29px" }}>
            <FaRegQuestionCircle className='mb-1' />
            <span className='support_hide'> Support</span>
        </div>
    </div>
    <div className='mt-4'>
    {user_agreed===2?<p className="text-danger">
                            <Link to="/user_profile">Please Complete Profile here</Link>
                        </p>:""}
        { user_agreed === 0 ? (
                        <p className="text-danger">
                            {/* <Link to="/user_profile">Please Complete Profile here</Link> */}
                        </p>
                    ) : user_agreed === 1 && test_bookings.length > 0 ? (
                        <>
                         
                             
 { registrations && registrations.map((registration,index)=>(<div className='my-4'>
    
    {registration.service_id===15||registration.service_id===105||registration.service_id===191?<div className='row my-3 box-shadow p-3' style={{borderRadius:"20px"}}>
      <div className='col-md-12 py-2'><div className='row'><div className='col-md-12'><h3 className="blue-color fw-bold">OET Account</h3> </div><div className='col-md-5'></div></div>
      <hr/>
      </div>
    
     
      <div className='col-md-4 my-2'>
        <label className="blue-color">OET Login Id</label>
      </div>
      <div className='col-md-4 my-2'>
        {registration.oet_login_id}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className='col-md-4 my-2'>
        <label className="blue-color">OET Login Password</label>
      </div>
      <div className='col-md-4 my-2'>
        {registration.oet_login_password}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className="col-md-4 my-2">
      <label className="blue-color">OET Secret Question</label>
      </div>
      <div className="col-md-4 my-2">
        {registration.oet_secret_question}
      </div>
      <div className="col-md-4 my-2"></div>


      <div className="col-md-4 my-2">
      <label className="blue-color">OET Secret Answer</label>
      </div>
      <div className="col-md-4 my-2">
        {registration.oet_secret_answer}
      </div>
      <div className="col-md-4 my-2"></div>

    </div>:registration.service_id===90||registration.service_id===176||registration.service_id===262?<div className='row my-3 box-shadow p-3' style={{borderRadius:"20px"}}>
    <div className='col-md-12 py-2'><div className='row'><div className='col-md-12'><h3 className="blue-color fw-bold">IELTS Account</h3> </div></div>
    <hr/>
    </div>      
     
      
      <div className='col-md-4 my-2'>
        <label className="blue-color">IELTS Login Id</label>
      </div>
      <div className='col-md-4 my-2'>
        {registration.ielts_login_id}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className='col-md-4 my-2'>
        <label className="blue-color">IELTS Login Password</label>
      </div>
      <div className='col-md-4 my-2'>
        {registration.ielts_login_password}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className="col-md-4 my-2">
      <label className="blue-color">IELTS Secret Question</label>
      </div>
      <div className="col-md-4 my-2">
        {registration.ielts_secret_question}
      </div>
      <div className="col-md-4 my-2"></div>


      <div className="col-md-4 my-2">
      <label className="blue-color">IELTS Secret Answer</label>
      </div>
      <div className="col-md-4 my-2">
        {registration.ielts_secret_answer}
      </div>
      <div className="col-md-4 my-2"></div>

    </div>:registration.service_id===14||registration.service_id===104||registration.service_id===190?<div className='row my-3 box-shadow p-3' style={{borderRadius:"20px"}}>
    <div className='col-md-12 py-2'><div className='row'><div className='col-md-12'><h3 className="blue-color fw-bold">GMC Account</h3> </div></div>
    <hr/>
    </div>   
    
      
     
      
      <div className='col-md-4 my-2'>
        <label className="blue-color">GMC Reference Number</label>
      </div>
      <div className='col-md-4 my-2'>
        {registration.gmc_reference_number}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className='col-md-4 my-2'>
        <label className="blue-color">GMC Login Password</label>
      </div>
      <div className='col-md-4 my-2'>
        {registration.gmc_login_password}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className="col-md-4 my-2">
      <label className="blue-color">GMC Secret Question</label>
      </div>
      <div className="col-md-4 my-2">
        {registration.gmc_secret_question}
      </div>
      <div className="col-md-4 my-2"></div>


      <div className="col-md-4 my-2">
      <label className="blue-color">GMC Secret Answer</label>
      </div>
      <div className="col-md-4 my-2">
        {registration.gmc_secret_answer}
      </div>
      <div className="col-md-4 my-2"></div>

    </div>:registration.service_id===33||registration.service_id===123||registration.service_id===209?<div className='row my-3 box-shadow p-3' style={{borderRadius:"20px"}}>
    <div className='col-md-12 py-2'><div className='row'><div className='col-md-12'><h3 className="blue-color fw-bold">EPIC Account</h3> </div></div>
    <hr/>
    </div>      
      
      
      
      <div className='col-md-4 my-2'>
        <label className="blue-color">EPIC Account Setup Date</label>
      </div>
      <div className='col-md-4 my-2'>
        {moment(registration.epic_account_setup_date).format('Do MMMM YYYY')}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className='col-md-4 my-2'>
        <label className="blue-color">EPIC Username</label>
      </div>
      <div className='col-md-4 my-2'>
        {registration.epic_username}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className="col-md-4 my-2">
      <label className="blue-color">EPIC  Password</label>
      </div>
      <div className="col-md-4 my-2">
        {registration.epic_password}
      </div>
      <div className="col-md-4 my-2"></div>


      <div className="col-md-4 my-2">
      <label className="blue-color">EPIC Secret Question1</label>
      </div>
      <div className="col-md-4 my-2">
        {registration.epic_secret_question1}
      </div>
      <div className="col-md-4 my-2"></div>

      <div className="col-md-4 my-2">
      <label className="blue-color">EPIC Secret Answer1</label>
      </div>
      <div className="col-md-4 my-2">
        {registration.epic_secret_answer1}
      </div>
      <div className="col-md-4 my-2"></div>


      <div className="col-md-4 my-2">
      <label className="blue-color">EPIC Secret Question2</label>
      </div>
      <div className="col-md-4 my-2">
        {registration.epic_secret_question2}
      </div>
      <div className="col-md-4 my-2"></div>

      <div className="col-md-4 my-2">
      <label className="blue-color">EPIC Secret Answer2</label>
      </div>
      <div className="col-md-4 my-2">
        {registration.epic_secret_answer2}
      </div>
      <div className="col-md-4 my-2"></div>


      <div className="col-md-4 my-2">
      <label className="blue-color">EPIC Secret Question3</label>
      </div>
      <div className="col-md-4 my-2">
        {registration.epic_secret_question3}
      </div>
      <div className="col-md-4 my-2"></div>

      <div className="col-md-4 my-2">
      <label className="blue-color">EPIC Secret Answer3</label>
      </div>
      <div className="col-md-4 my-2">
        {registration.epic_secret_answer3}
      </div>
      <div className="col-md-4 my-2"></div>

      <div className="col-md-4 my-2">
      <label className="blue-color">EPIC Secret Question4</label>
      </div>
      <div className="col-md-4 my-2">
        {registration.epic_secret_question4}
      </div>
      <div className="col-md-4 my-2"></div>

      <div className="col-md-4 my-2">
      <label className="blue-color">EPIC Secret Answer4</label>
      </div>
      <div className="col-md-4 my-2">
        {registration.epic_secret_answer4}
      </div>
      <div className="col-md-4 my-2"></div>

    </div>:registration.service_id===35||registration.service_id===125||registration.service_id===211?<div className='row mb-5 box-shadow p-3' style={{borderRadius:"20px",marginBottom:'2%'}}>
    <div className='col-md-12 py-2'><div className='row '><div className='col-md-12'><h3 className="blue-color fw-bold">Notarycam Account</h3> </div></div>
    <hr/>
    </div>      
      
      
      
      <div className='col-md-4 my-2'>
        <label className="blue-color">NotaryCam UserName </label>
      </div>
      <div className='col-md-4 my-2'>
        {registration.notarycam_username}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className='col-md-4 my-2'>
        <label className="blue-color">NotaryCam  Password</label>
      </div>
      <div className='col-md-4 my-2'>
        {registration.notarycam_password}
      </div>
      <div className='col-md-4 my-2'></div>

      

    </div>:""}


 </div>))}
                        </>
                    ) : user_agreed === 1 && test_bookings.length === 0 ? (
                        <div className="text-danger bold pl-3 pt-2" style={{ position: 'absolute', top: "12%" }}>
                            Hi there! You do not have any Registrations allocated at the moment, kindly contact <a style={{ display: 'content' }} className='text-left' href="tel:+919019925836">9019925836</a>
                        </div>
                    ) : null}
        </div>
        
        </div>
       
   

    </div>
  
</div>


        
    );
};

export default UserRegistrationDetails;
