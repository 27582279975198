import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { LoginContext } from '../../context/actions/LoginAction';
import ViewUser from './ViewUser';
import {useParams} from 'react-router-dom';
import Onboarding from '../Onboarding/Onboarding';
import TrainingDetails from '../TrainingDetails/TrainingDetails';
import Documents from '../Documents';
import TestBookingDetails from '../TestBookingDetails/TestBookingDetails';
import RegisterationDetails from '../RegistrationsDetails/RegistrationDetails';
import TrainingAndSubscriptions from '../TrainingAndSubcriptionsDetails';
import CoursesAllocated from '../CoursesAllocated/CoursesAllocated';
import CoursesAllocatedDetails from '../CoursesAllocatedDetails/CoursesAllocatedDetails';
import Portfolio from '../Portfolio';
import ClientDashboard from '../Clients';
import ClientDetails from '../ClientDetails';
import Tier4Visa from '../Tier4Visa/Tier4Visa';
import TravelAndAccomdation from '../TravelAndAccomdation';
import Cab from '../Cab/Cab';
import GMCAndRegistration from '../GMCAndRegistration';
import server from '../Utils';
import UserInfo from '../UserInfo';
import Loader from './Loader';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import { ServiceData } from '../../@types/servicetype';
import { StageData } from '../../@types/stagetype';
import UserDetails from './UserDetails';
import { CollegeData } from '../../@types/collegedata';
import { SpecialityData } from '../../@types/specialitytype';
import { SubSpecialityData } from '../../@types/subspeciality';
import { toast } from 'react-toastify';
import { callnotestype } from '../../@types/callnotestype';
import UserDashboardInfo from '../UserDashboardInfo';

const SuperAdminDashboard = () => {
  let role =localStorage.getItem('role');
const  admin_id=localStorage.getItem('admin_id');
  const { id } = useParams();
  const userid = id !== undefined ? String(id) : '';
  const[first_name,setfirstName]=useState<string>("");
  const[last_name,setlastName]=useState<string>("");
  const [loading, setLoading] = useState(false);
  const [products,setProducts]=useState<Array<ProductData>>([])
  const [projects,setProjects]=useState<Array<ProjectData>>([])
  const [discounted_amount,setDiscountedAmount]=useState<number>(0)
  const [project_id,setProjectId]=useState<number>(0);
  const [product_id,setProductId]=useState<number>(0);
  const[product_price,setProductPrice]=useState<number>(0);
  const [stages,setStages]=useState<Array<StageData>>([])
   const[stage_id,setStageId]=useState<number>(0);
   const [services,setServices]=useState<Array<ServiceData>>([])
   const [subscribed_services,setSubscribedServices]=useState<string>('')
  
   const[primary_country_code,setPrimaryCountryCode]=useState<number>(0);
   const[primary_contact,setPrimaryContact]=useState<number>(0);
   const[alernate_country_code,setAlternateCountryCode]=useState<number>(0);
   const[alternatecontact,setAlternateContact]=useState<number>(0);
   const[user_email,setEmail]=useState<string>("");
   const [project_name,setProjectName]=useState<string>(''); 
   const[product_name,setProductName]=useState<string>('');  
   const [stage_name,setStageName]=useState<string>('');
   const[installment_type_name,setInstallmentTypeName]=useState('');
   const[date_of_registration,setDateOfRegistration]=useState<string>("");
 
   const [passport_first_name,setPassportFirstName]=useState<string>("")
   const [passport_last_name,setPassportlastName]=useState<string>("")
   const [certificate_first_name,setCertificateFirstName]=useState<string>('')
   const [certificate_last_name,setCertificateLastName]=useState<string>('')
   
  
   const [alternate_phone_number,setAlternatePhoneNumber]=useState<string>('')
  
   const [password,setPassword]=useState<string>('');
   const [last_password,setLastPassword]=useState<string>('');
   const [dob,setDob]=useState<string>('');
   const [fathers_full_name,setFathersFullName]=useState<string>('')
   const [fathers_email,setFathersEmail]=useState<string>('')
   const [fathers_contact_country_code,setFathersContactCountryCode]=useState('')
   const [fathers_contact,setFathersContact]=useState<number|undefined>()
   const [mothers_full_name,setMothersFullName]=useState<string>('')
   const [mothers_email,setMothersEmail]=useState<string>('');
   const [mothers_country_code,setMothersCountryCode]=useState<string>('');
   const [mothers_contact,setMothersContact]=useState<number|undefined>();
   const [passport_exist,setPassportExist]=useState<number>(0);
   const [passport_number,setPassportNumber]=useState<string>('');
   const [passport_issue_date,setPassportIssueDate]=useState<string>('');
   const [passport_expiry_date,setPassportExpiryDate]=useState<string>(''); 
   const[product_amount,setProductAmount]=useState<number>();
   const[contract_file,setContractFile]=useState<string>('');
   const[counsellor_note,setCounsellorNote]=useState<string>('');   
  const[file,setFile]=useState<File>();
  const[service_names,setServiceNames]=useState<Array<ServiceData>>([]);
  
   const [installtypes,setInstalltypes]=useState<Array<InstallmentTypeData>>([])
   const[installtypesid,setInstallTypeId]=useState<number>(0);
  
  
   const [noofintallments,setNoOfInstallments]=useState<number>(0);
   
   const [installment1,setInstallment1]=useState<number>(0);
   const [installment2,setInstallment2]=useState<number>(0);
   const [installment3,setInstallment3]=useState<number>(0);
   const [installment4,setInstallment4]=useState<number>(0);
   const [installment5,setInstallment5]=useState<number>(0);
   const [installment6,setInstallment6]=useState<number>(0);
  
   const[installmentdiscount1,setInstallmentDiscount1]=useState<number>(0);
   const[installmentdiscount2,setInstallmentDiscount2]=useState<number>(0);
   const[installmentdiscount3,setInstallmentDiscount3]=useState<number>(0);
   const[installmentdiscount4,setInstallmentDiscount4]=useState<number>(0);
   const[installmentdiscount5,setInstallmentDiscount5]=useState<number>(0);
   const[installmentdiscount6,setInstallmentDiscount6]=useState<number>(0);

   const[institutionaldiscount1,setInstitutionalDiscount1]=useState<number>(0);  
   const[institutionaldiscount2,setInstitutionalDiscount2]=useState<number>(0);  
   const[institutionaldiscount3,setInstitutionalDiscount3]=useState<number>(0);  
   const[institutionaldiscount4,setInstitutionalDiscount4]=useState<number>(0);  
   const[institutionaldiscount5,setInstitutionalDiscount5]=useState<number>(0);  
   const[institutionaldiscount6,setInstitutionalDiscount6]=useState<number>(0);  

   const [payments,setPayments]=useState<number>(1);
   const [payment1,setPayment1]=useState<number>(0);
   const [payment2,setPayment2]=useState<number>(0);
   const [payment3,setPayment3]=useState<number>(0);
   const [payment4,setPayment4]=useState<number>(0);
   const [payment5,setPayment5]=useState<number>(0);
   const [payment6,setPayment6]=useState<number>(0);

   const [payment_no_of_days1,setPaymentNoOfDays1]=useState<number>(0);
   const [payment_no_of_days2,setPaymentNoOfDays2]=useState<number>(0);
   const [payment_no_of_days3,setPaymentNoOfDays3]=useState<number>(0);
   const [payment_no_of_days4,setPaymentNoOfDays4]=useState<number>(0);
   const [payment_no_of_days5,setPaymentNoOfDays5]=useState<number>(0);
   const [payment_no_of_days6,setPaymentNoOfDays6]=useState<number>(0);

   const [paymenttrigger1,setPaymentTrigger1]=useState<number>(0);
   const [paymenttrigger2,setPaymentTrigger2]=useState<number>(0);
   const [paymenttrigger3,setPaymentTrigger3]=useState<number>(0);
   const [paymenttrigger4,setPaymentTrigger4]=useState<number>(0);
   const [paymenttrigger5,setPaymentTrigger5]=useState<number>(0);
   const [paymenttrigger6,setPaymentTrigger6]=useState<number>(0);

   const [payment_date1,setPaymentDate1]=useState<string>('');
   const [payment_date2,setPaymentDate2]=useState<string>('');
   const [payment_date3,setPaymentDate3]=useState<string>('');
   const [payment_date4,setPaymentDate4]=useState<string>('');
   const [payment_date5,setPaymentDate5]=useState<string>('');
   const [payment_date6,setPaymentDate6]=useState<string>('');

   const [installment_date1,setInstallmentDate1]=useState<string>('');
   const [installment_date2,setInstallmentDate2]=useState<string>('');
   const [installment_date3,setInstallmentDate3]=useState<string>('');
   const [installment_date4,setInstallmentDate4]=useState<string>('');
   const [installment_date5,setInstallmentDate5]=useState<string>('');


   const [installment_no_of_days1,setInstallmentNoOfDays1]=useState<number>(0)
   const [installment_no_of_days2,setInstallmentNoOfDays2]=useState<number>(0)
   const [installment_no_of_days3,setInstallmentNoOfDays3]=useState<number>(0)
   const [installment_no_of_days4,setInstallmentNoOfDays4]=useState<number>(0)
   const [installment_no_of_days5,setInstallmentNoOfDays5]=useState<number>(0)
   const [installment_no_of_days6,setInstallmentNoOfDays6]=useState<number>(0)
  
   const [final_trigger1,setFinalTrigger1]=useState<number>(0);
   const [final_trigger2,setFinalTrigger2]=useState<number>(0);
   const [final_trigger3,setFinalTrigger3]=useState<number>(0);
   const [final_trigger4,setFinalTrigger4]=useState<number>(0);
   const [final_trigger5,setFinalTrigger5]=useState<number>(0);
   const [final_trigger6,setFinalTrigger6]=useState<number>(0);
   
   
   const [final_installment1,setFinalInstallment1]=useState<number>(0);
   const [final_installment2,setFinalInstallment2]=useState<number>(0);
   const [final_installment3,setFinalInstallment3]=useState<number>(0);
   const [final_installment4,setFinalInstallment4]=useState<number>(0);
   const [final_installment5,setFinalInstallment5]=useState<number>(0);
   const [final_installment6,setFinalInstallment6]=useState<number>(0);

  const [timefunction1,setFunctionalTime1]=useState<number>(0);
  const [timefunction2,setFunctionalTime2]=useState<number>(0);
  const [timefunction3,setFunctionalTime3]=useState<number>(0);
  const [timefunction4,setFunctionalTime4]=useState<number>(0);
  const [timefunction5,setFunctionalTime5]=useState<number>(0);

  const [personaldetails,setPersonalDetails]=useState(false);
  const [productdetails,setProductDetails]=useState(false);
  const [paymentDetails,setPaymentDetails]=useState(false);
    const [city,setCity]=useState<string>('');
     const [state,setState]=useState<string>('');
     const [country,setCountry]=useState<string>('');
  const [current_stage_name,setCurrentStageName]=useState('');
  const [current_door_no,setCurrentDoorNo]=useState<string>('');
 const [current_stage,setCurrentStage]=useState<string>('');
const [current_street,setCurrentStreet]=useState<string>('');
    const [current_locality,setCurrentLocality]=useState<string>('');
    const [current_city,setCurrentCity]=useState<string>('');
    const [current_state,setCurrentState]=useState<string>('');
    const [current_country,setCurrentCountry]=useState<string>('');
    const [current_postal_code,setCurrentPostalCode]=useState<string>('');

    const [permanent_door_no,setPermanentDoorNo]=useState<string>('');
    const [permanent_street,setPermanentStreet]=useState<string>('');
    const [permanent_locality,setPermanentLocality]=useState<string>('');
    const [permanent_city,setPermanentCity]=useState<string>('');
    const [permanent_state,setPermanentState]=useState<string>('');
    const [permanent_country,setPermanentCountry]=useState<string>('');
    const [permanent_postal_code,setPermanentPostalCode]=useState<string>('');
    const [address_same_flag,setAddressSameFlag]=useState<number>(0);


    const [colleges,setColleges]=useState<Array<CollegeData>>([])
    const [collegeid,setCollegeId]=useState<string>('');
    const [pmq_status,setPmQStatus]=useState<number>(0);
    const [pmq_start_date,setPmqStartDate]=useState<string>('');
    const [pmq_end_date,setPmqEndDate]=useState<string>('');
    const [pmq_finish_date,setPmqFinishDate]=useState<string>('');

  const [created_by, setCreatedBy] = useState("");
  const [updated_by,setUpdatedBy]=useState('');


  

  const [collegename,setCollegename]=useState('')
  //  language test details
  const [language_test_taken,setLanguageTestTaken]=useState<number>(0);
  const [language_test_type,setLanguageTestType]=useState<number>(0);
  const [language_test_date,setLanguageTestDate]=useState<string>("");
  const [ielts_trf_number,setIELTStrfNo]=useState<string>("");
  const [ielts_listening_score,setIELTSListeningScore]=useState<string>('');
  const [ielts_reading_score,setIELTSReadingScore]=useState<string>('');
  const [ielts_writing_score,setIELTSWritingScore]=useState<string>('');
  const [ielts_speaking_score,setIELTSSpeakingScore]=useState<string>('');
  const [ielts_overall_score,setIELTSOverallScore]=useState<string>('');
  const [oet_candidate_number,setOetCandidateNumber]=useState<string>('');
  const [oet_listening_score,setOetListeningScore]=useState<string>('');
  const [oet_reading_score,setOetReadingScore]=useState<string>('');
  const [oet_writing_score,setOetWritingScore]=useState<string>('');
  const [oet_speaking_score,setOetSpeakingScore]=useState<string>('');
  const [oet_overall_score,setOetOverallScore]=useState<string>('');

  const [gmc_reference_number_exist,setGmcReferenceNumberExist]=useState<number>(0)
  const [gmc_reference_number,setGmcReferenceNumber]=useState<string>('')
  const [internship_start_date,setInternshipStartDate]=useState<string>("")
  const [internship_end_date,setInternshipEndDate]=useState<string>("0")
  const [call_notes,setCallNotes]=useState<string>('')
  const[callrecords,setCallRecords]=useState<Array<callnotestype>>([])

  const [emp_completion_status,setEmployCompletion]=useState<number>(0);
  const [emp_hospital_name,setEmployHospital]=useState<string>("");
  const [emp_city,setEmpCity] =useState<string>("");
  const [emp_state,setEmpState]=useState<string>('');

  const [specialities,setSpecialities]=useState<Array<SpecialityData>>([]);
  const [speciality_id,setSpecialityId]=useState<number>(0);
  const [sub_specialities,setSubSpeciality]=useState<Array<SubSpecialityData>>([]);
  const [subspecialityid,setSubSpecialityId]=useState<number>(0);

  const [personal_note,setPersnolNote]=useState<string>("");
  const [welcome_call_date,setWelcomeCallDate]=useState<string>('');
  const [welcome_call_time,setWelcomeCallTime]=useState<number>(0);
  const [user_agreed,setUserAgreed]=useState<number>(0);
  const [gc_registration,setGCRegistration]=useState<string>('')
 
  const [client_photo,setClientPhoto]=useState<string>('');
  const [current_sub_stage,setCurrentSubStage]=useState<string>('')
  const [percentage,setPercentage]=useState<number>(0);
  const [gender,setGender]=useState<number>(0)
     useEffect(() => {
      setLoading(true)
      server.get(`/user_info/${userid}`)
      .then((response)=>{
          setfirstName(response.data.user.first_name);
          setlastName(response.data.user.last_name);
           
          setPrimaryCountryCode(response.data.user.primary_contact_country_code);
          setPrimaryContact(response.data.user.primary_contact);
          setAlternateCountryCode(response.data.user.alernate_country_code);
          setAlternateContact(response.data.user.alternate_phone_number);
          setGender(response.data.user.gender);
          setEmail(response.data.user.user_email);
          setProjectId(response.data.user.project_id);
          setProductId(response.data.user.product_id);
          localStorage.setItem("product_id",response.data.user.product_id);
          setDateOfRegistration(response.data.user.date_of_registration);
          setStage(response.data.user.stage_id);
          setDiscountedAmount(response.data.user.discounted_amount);
          setClientPhoto(response.data.user.client_photo);
         
          setProductAmount(response.data.user.product_amount);
          setInstallTypeId(response.data.user.installment_type);
          setNoOfInstallments(response.data.user.noofintallments);
         
          setCounsellorNote(response.data.user.counsellor_note);
          setProductPrice(response.data.user.product_price);
          setProjectName(response.data.user.project_name);
          setProductName(response.data.user.product_name);
       setCity(response.data.user.current_city_name);
                  setState(response.data.user.current_state_name);
                  setCountry(response.data.user.current_country_name);
          setStageName(response.data.user.stage_name);
          setCurrentSubStage(response.data.user.current_sub_stage_name);
          setInstallmentTypeName(response.data.user.installment_type_name);
          setPassword(response.data.user.last_password);
          setSubscribedServices(response.data.user.services_offered);
          setContractFile(response.data.user.contract_file);
          setCreatedBy(response.data.user.created_by);
          setUpdatedBy(response.data.user.updated_by);


          setPassportFirstName(response.data.user.passport_first_name)
    setPassportlastName(response.data.user.passport_last_name)
    setCertificateFirstName(response.data.user.certificate_first_name)
    setCertificateLastName(response.data.user.certificate_last_name)
    setPrimaryCountryCode(response.data.user.primary_contact_country_code)
    setPrimaryContact(response.data.user.primary_contact)
    
    setAlternateCountryCode(response.data.user.alernate_country_code)
    setAlternatePhoneNumber(response.data.user.alternate_phone_number)
    
    setPassword(response.data.user.last_password)
    setLastPassword(response.data.user.last_password)
    setGCRegistration(response.data.user.goocampus_registration_number)
    setDob(response.data.user.dob)
    setFathersFullName(response.data.user.fathers_full_name)
    setFathersEmail(response.data.user.fathers_email)
    setFathersContactCountryCode(response.data.user.fathers_contact_country_code)
    setFathersContact(response.data.user.fathers_contact)
    setMothersFullName(response.data.user.mothers_full_name)
    setMothersEmail(response.data.user.mothers_email)
    setMothersCountryCode(response.data.user.mothers_country_code)
    
    setMothersContact(response.data.user.mothers_contact)
    setPassportExist(response.data.user.passport_exist)
    setPassportNumber(response.data.user.passport_number)
    setPassportIssueDate(response.data.user.passport_issue_date)
    setPassportExpiryDate(response.data.user.passport_expiry_date)
// userProfile Ends

// user Address Starts
setCurrentDoorNo(response.data.user.current_door_no)
    setCurrentStreet(response.data.user.current_street)
    setCurrentLocality(response.data.user.current_locality)
    setCurrentCity(response.data.user.current_city_name)
    setCurrentState(response.data.user.current_state_name)
    setCurrentCountry(response.data.user.current_country_name)
    setCurrentPostalCode(response.data.user.current_postal_code)

    setPermanentDoorNo(response.data.user.permanent_door_no)
    setPermanentStreet(response.data.user.permanent_street)
    setPermanentLocality(response.data.user.permanent_locality)
    setPermanentCity(response.data.user.permanent_city_name)
    setPermanentState(response.data.user.permanent_state_name)
    setPermanentCountry(response.data.user.permanent_country_name)
    setPermanentPostalCode(response.data.user.permanent_postal_code)

    // address details ends

    // pmq details starts
    setCollegeId(response.data.user.pmq_college);
    setPmQStatus(response.data.user.pmq_status);
    setPmqStartDate(response.data.user.pmq_start_date);
    setPmqEndDate(response.data.user.pmq_end_date);
    setPmqFinishDate(response.data.user.pmq_finish_date);
    setCollegename(response.data.user.pmq_college);
    // pmq details Ends
// language test details
    setLanguageTestTaken(parseInt(response.data.user.language_test_taken))
      setLanguageTestType(parseInt(response.data.user.language_test_type))
      setLanguageTestDate(response.data.user.date_of_test)
      setIELTStrfNo(response.data.user.ielts_trf_number)
      if(response.data.user.ielts_trf_number===null||response.data.user.ielts_trf_number===""){
        setIELTStrfNo("")
      }
      setIELTSListeningScore(response.data.user.ielts_listening_score)
      setIELTSReadingScore(response.data.user.ielts_reading_score)
      setIELTSWritingScore(response.data.user.ielts_writing_score)
      setIELTSSpeakingScore(response.data.user.ielts_speaking_score)
      setIELTSOverallScore(response.data.user.ielts_overall_score)
      
      if(response.data.user.oet_candidate_number===null||response.data.user.oet_candidate_number===""){
        setOetCandidateNumber('')
      }else{
        setOetCandidateNumber(response.data.user.oet_candidate_number)
      }
      setOetListeningScore(response.data.user.oet_listening_score)
      setOetReadingScore(response.data.user.oet_reading_score)
      setOetWritingScore(response.data.user.oet_writing_score)
      setOetSpeakingScore(response.data.user.oet_speaking_score)
      setOetOverallScore(response.data.user.oet_overall_score)
      //language test details


      if(response.data.user.pmq_status===null){
        setPmQStatus(0);
      }else{
        setPmQStatus(response.data.user.pmq_status);
      }
      if(response.data.user.gmc_refrence_exists===null){
        setGmcReferenceNumberExist(0);
      }else{
        setGmcReferenceNumberExist(response.data.user.gmc_refrence_exists);
      }if(response.data.user.gmc_reference_number===null){
        setGmcReferenceNumber('')
       }else{
        setGmcReferenceNumber(response.data.user.gmc_reference_number)
       }
       if(response.data.user.internship_end_date===null){
        setInternshipEndDate('')
       }else{
        setInternshipEndDate(response.data.user.internship_end_date)
       }
       if(response.data.user.internship_end_date===null){
        setInternshipStartDate('')
       }else{
        setInternshipStartDate(response.data.user.internship_start_date)
       }
       setGCRegistration(response.data.user.goocampus_registration_number)
       setPmQStatus(response.data.user.pmq_status);
       setEmployCompletion(response.data.user.emp_completion_status);
       setEmployHospital(response.data.user.emp_hospital_name);
       setEmpCity(response.data.user.emp_city_name);
       setEmpState(response.data.user.emp_state_name);
       setSpecialityId(response.data.user.interested_speciality);
       
       setSubSpecialityId(response.data.user.sub_speciality);
       setPersnolNote(response.data.user.personal_note);
       setWelcomeCallDate(response.data.user.welcome_call_date);
       setWelcomeCallTime(response.data.user.welcome_call_time);
          setCurrentStageName(response.data.user.current_stage_name)
            setPmQStatus(response.data.user.pmq_status);
         setPersnolNote(response.data.user.personal_note);
         setUserAgreed(response.data.user.user_agreement_flag);
         setPercentage(response.data.percentage);
          setLoading(false)  
          
    })
setLoading(true)
    server.get(`/get_installments/${userid}`)
.then((response)=>{
    
    setInstallment1(response.data.user.installment1)
   
    setInstallment2(response.data.user.installment2)
    setInstallment3(response.data.user.installment3)
    setInstallment4(response.data.user.installment4)
    setInstallmentDiscount1(response.data.user.installmentdiscount1)
    
    setInstallmentDiscount2(response.data.user.installmentdiscount2)
    setInstallmentDiscount3(response.data.user.installmentdiscount3)
    setInstallmentDiscount4(response.data.user.installmentdiscount4)
    setInstallmentDiscount5(response.data.user.installmentdiscount5)
    setInstitutionalDiscount1(response.data.user.idiscount1)
    setInstitutionalDiscount2(response.data.user.idiscount2)
    setInstitutionalDiscount3(response.data.user.idiscount3)
    setInstitutionalDiscount4(response.data.user.idiscount4)
    setPayment1(response.data.user.payment1)
    setPayment2(response.data.user.payment2)
    setPayment3(response.data.user.payment3)
    setPaymentDate1(response.data.user.payment_date1)
    setPaymentDate2(response.data.user.payment_date2)
    setPaymentDate3(response.data.user.payment_date3)
    setPaymentDate4(response.data.user.payment_date4)
    setPaymentNoOfDays1(response.data.user.no_of_days1)
    setPaymentNoOfDays2(response.data.user.no_of_days2)
    setPaymentNoOfDays3(response.data.user.no_of_days3)
    setPaymentNoOfDays4(response.data.user.no_of_days4)
    setFinalTrigger1(response.data.user.final_trigger1)
    setFinalTrigger2(response.data.user.final_trigger2)
    setFinalTrigger3(response.data.user.final_trigger3)
    setFinalTrigger4(response.data.user.final_trigger4)
    setFinalTrigger5(response.data.user.final_trigger5)
    setFinalInstallment1(response.data.user.finalinstallment1)
    setFinalInstallment2(response.data.user.finalinstallment2)
    setFinalInstallment3(response.data.user.finalinstallment3)
    setFinalInstallment4(response.data.user.finalinstallment4)
    setFinalInstallment5(response.data.user.finalinstallment5)
    setInstallmentDate1(response.data.user.installment_date1)
    setInstallmentDate2(response.data.user.installment_date2)
    setInstallmentDate3(response.data.user.installment_date3)
    setInstallmentDate4(response.data.user.installment_date4)
    setInstallmentDate5(response.data.user.installment_date5)
    setFunctionalTime1(response.data.user.timefunction1)
    setFunctionalTime2(response.data.user.timefunction2)
    setFunctionalTime3(response.data.user.timefunction3)
    setFunctionalTime4(response.data.user.timefunction4)
  
    setInstallmentNoOfDays2(response.data.user.no_of_days2)
    setInstallmentNoOfDays3(response.data.user.no_of_days3)
    setInstallmentNoOfDays4(response.data.user.no_of_days4)
    

 setLoading(false)
    
})




     },[])


     const addCallNotes = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!call_notes || call_notes.trim() === "") {
          toast('Enter call Notes');
          return;
      }
  
     
          const formData = new FormData(e.currentTarget);
  
          const response = await server.post('/add_call_notes', formData);
  
          if (response.data.code === 200) {
              server.get(`/get_call_notes/${id}/${admin_id}`)
              .then((response)=>{
                 setCallRecords(response.data.call_notes)
              }) 
              toast(response.data.message);
          } else {
              toast(response.data.message);
          }
     
  };
     const setStage=(id:number)=>
      {
        
        
        setProductId(id);
        // // setLoading(true)
        //   server.get(`/get_stage/${id}`)
        //   .then((response)=>{
              
        //     setStages(response.data.stage);
        //       // setLoading(false)
        //   })
        }
        
     if (loading) {
      return <Loader />;
    } 
   
  return (
    <div className='continer-fluid'>
        <div className='d-flex'>
            <SideBar/>
            <div className='headerbar'>
            <Header/>
            {/* Content Row */}

          <div className="d-sm-flex align-items-center justify-content-between mb-4">
  
         </div>

<div className='px-2'>
  <UserDashboardInfo  client_photo={client_photo} gc_registration={gc_registration} gender={gender} first_name={first_name} last_name={last_name}  primary_country_code={primary_country_code} primary_contact={primary_contact} user_email={user_email} city={city} state={state} country ={country} pmq_status={pmq_status} pmq_college={collegeid} stage_name={stage_name} current_stage={current_stage_name} current_sub_stage={current_sub_stage} percentage={percentage} />
</div>
{/* content should be written here */}

<div className='row px-5 mt-4'>
    <div className='col-md-12'>
    <ul className="nav nav-tabs" role="tablist">
   {role==="1"||role==="2"||role==="3"||role==="5"||role==="6"||role==="9" ?<li className="nav-item">
      <a className="nav-link active" data-bs-toggle="tab" href="#userdetails_by_counsellor">Registration Details</a>
    </li>:""}
    {role==="1"||role==="3"||role==="5"||role==="6" ? <li className="nav-item">
      <a className="nav-link" data-bs-toggle="tab" href="#userdetails_by_user">Client Details</a>
    </li>:""}
    {role==="1"||role==="2"||role==="3"||role==="5"||role==="6" ?<li className="nav-item">
      <a className="nav-link" data-bs-toggle="tab" href="#onboarding">Onboarding</a>
    </li>:""}
    {role==="1"||role==="3"||role==="5"||role==="6" ?<li className="nav-item">
      <a className="nav-link" data-bs-toggle="tab" href="#training_details">Training</a>
    </li>:""}
    {role==="1"||role==="3"||role==="5"||role==="6" ?<li className="nav-item">
      <a className="nav-link" data-bs-toggle="tab" href="#documents">Documents</a>
    </li>:""}
    {role==="1"||role==="3"||role==="5"||role==="6" ?<li className="nav-item">
      <a className="nav-link" data-bs-toggle="tab" href="#test_booking">Test Booking</a>
    </li>:""}
    {role==="1"||role==="3"||role==="5"||role==="6" ?<li className="nav-item">
      <a className="nav-link" data-bs-toggle="tab" href="#registration">Registrations</a>
    </li>:""}
    {role==="1"||role==="3"||role==="5"||role==="6" ?<li className="nav-item">
      <a className="nav-link" data-bs-toggle="tab" href="#training_subscription">Training & Subscription</a>
    </li>:""}
    {role==="1"||role==="3"||role==="5"||role==="6" ? <li className="nav-item">
      <a className="nav-link" data-bs-toggle="tab" href="#courses_allocated_details">Courses Allocated</a>
    </li>:""}
    {role==="1"||role==="3"||role==="5"||role==="6" ?<li className="nav-item">
      <a className="nav-link" data-bs-toggle="tab" href="#portfolio">Portfolio</a>
    </li>:""}
    {role==="1"||role==="3"||role==="5"||role==="6" ?<li className="nav-item">
      <a className="nav-link" data-bs-toggle="tab" href="#visa4tier">Tier 4 Visa</a>
    </li>:""}
    {role==="1"||role==="3"||role==="5"||role==="6" ?<li className="nav-item">
      <a className="nav-link" data-bs-toggle="tab" href="#travelandaccomodation">Travel & Accomadation</a>
    </li>:""}
    {role==="1"||role==="3"||role==="5"||role==="6" ? <li className="nav-item">
      <a className="nav-link" data-bs-toggle="tab" href="#cab">Flight & Cab</a>
    </li>:""}
    {role==="1"||role==="3"||role==="5"||role==="6" ? <li className="nav-item">
      <a className="nav-link" data-bs-toggle="tab" href="#gmcandregistration">GMC License & Job</a>
    </li>:""}
    
  </ul>
  <div className="tab-content">
    <div id="userdetails_by_counsellor" className="container tab-pane active"><br/>
      <ViewUser first_name={first_name}
last_name={last_name}
primary_country_code={primary_country_code}
alernate_country_code={alernate_country_code}
primary_contact={primary_contact}
alternatecontact={alternatecontact}
password={password}
user_email={user_email}
created_by={created_by}
updated_by={updated_by}
date_of_registration={date_of_registration}
noofintallments={noofintallments}
installment1={installment1}
installment2={installment2}
installment3={installment3}
installment4={installment4}
installment5={installment5}
installment6={installment6}
contract_file={contract_file}
counsellor_note={counsellor_note}
stage_name={stage_name}
product_name={product_name}
project_name={project_name}
product_price={product_price}
current_stage_name={current_stage_name}
installmentdiscount1={installmentdiscount1}
installmentdiscount2={installmentdiscount2}
installmentdiscount3={installmentdiscount3}  
installmentdiscount4={installmentdiscount4}
institutionaldiscount1={institutionaldiscount1}
institutionaldiscount2={institutionaldiscount2}
institutionaldiscount3={institutionaldiscount3}
institutionaldiscount4={institutionaldiscount4}
finalinstallment1={final_installment1}
finalinstallment2={final_installment2}
finalinstallment3={final_installment3}
finalinstallment4={final_installment4}
installment_no_of_days1={installment_no_of_days1}
installment_no_of_days2={installment_no_of_days2}
installment_no_of_days3={installment_no_of_days3}
installment_no_of_days4={installment_no_of_days4}
timefunction2={timefunction2}
timefunction3={timefunction3}
timefunction4={timefunction4}
final_trigger2={final_trigger2}
final_trigger3={final_trigger3}
final_trigger4={final_trigger4}
installment_date2={installment_date2}
installment_date3={installment_date3}
installment_date4={installment_date4}
payment1={payment1}
payment2={payment2}
payment_date1={payment_date1}
payment_date2={payment_date2}
/>
    </div>
    <div id="userdetails_by_user" className="container tab-pane fade"><br />
      <ClientDetails  
      passport_first_name={passport_first_name}
      passport_last_name={passport_last_name}
      certificate_first_name={certificate_first_name}
      certificate_last_name={certificate_last_name}
      primary_country_code={primary_country_code}
      primary_contact={primary_contact}
      alernate_country_code={alernate_country_code}
      alternate_phone_number={alternatecontact}
      user_email={user_email}
      password={password}
      last_password={password}
      dob={dob}
      fathers_full_name={fathers_full_name}
      fathers_email={fathers_email}
      fathers_contact_country_code={fathers_contact_country_code}
      fathers_contact={fathers_contact}
      mothers_full_name={mothers_full_name}
      mothers_email={mothers_email}
      mothers_country_code={mothers_country_code}
      mothers_contact={mothers_contact}
      passport_exist={passport_exist}
      passport_number={passport_number}
      passport_issue_date={passport_issue_date}
      passport_expiry_date={passport_expiry_date}
      collegename={collegename}
      address_same_flag={address_same_flag}
      current_door_no={current_door_no}
      current_street={current_street}
      current_locality={current_locality}
      current_city={current_city}
      current_state={current_state}
      current_country={current_country}
      current_postal_code={current_postal_code}
      permanent_door_no={permanent_door_no}
      permanent_street={permanent_street}
      permanent_locality={permanent_locality}
      permanent_city={permanent_city}
      permanent_state={permanent_state}
      permanent_country={permanent_country}
      permanent_postal_code={permanent_postal_code}
      pmq_college={collegeid}
      pmq_status={pmq_status}
      pmq_start_date={pmq_start_date}
      pmq_end_date={pmq_end_date}
      pmq_finish_date={pmq_finish_date}
      language_test_taken={language_test_taken}
      language_test_type={language_test_type}
      language_test_date={language_test_date}
      ielts_trf_number={ielts_trf_number}
      ielts_listening_score={ielts_listening_score}
      ielts_reading_score={ielts_reading_score}
      ielts_writing_score={ielts_writing_score}
      ielts_speaking_score={ielts_speaking_score}
      ielts_overall_score={ielts_overall_score}
      oet_candidate_number={oet_candidate_number}
      oet_listening_score={oet_listening_score}
      oet_reading_score={oet_reading_score}
      oet_writing_score={oet_writing_score}
      oet_speaking_score={oet_speaking_score}
      oet_overall_score={oet_overall_score}
      gmc_reference_number_exist={gmc_reference_number_exist}
      gmc_reference_number={gmc_reference_number}
      internship_start_date={internship_start_date}
      internship_end_date={internship_end_date}
      emp_completion_status={emp_completion_status}
      emp_hospital_name={emp_hospital_name}
      emp_city={emp_city}
      emp_state={emp_state}
      personal_note={personal_note}
      welcome_call_date={welcome_call_date}
      welcome_call_time={welcome_call_time}
      />
    </div>
    <div id="onboarding" className="container tab-pane fade"><br />
    <Onboarding user_agreed={user_agreed}/>
    </div>
    <div id="training_details" className='container tab-pane fade'>
        <br />
      <TrainingDetails user_agreed={user_agreed} />  
    </div>
    <div id="documents" className='container tab-pane fade'>
        <br />
      <Documents user_agreed={user_agreed} personal_note={personal_note} pmq_status={pmq_status} />  
    </div>
    <div id="test_booking" className='container tab-pane fade'>
        <br />
      <TestBookingDetails user_agreed={user_agreed} />  
    </div>
    <div id="registration" className='container tab-pane fade'>
        <br />
      <RegisterationDetails user_agreed={user_agreed} />  
    </div>
    <div id="training_subscription" className='container tab-pane fade'>
        <br />
      <TrainingAndSubscriptions />  
    </div>
    <div id="courses_allocated_details" className='container tab-pane fade'>
        <br />
      <CoursesAllocatedDetails />
    </div>
    <div id="portfolio" className='container tab-pane fade'>
        <br />
      <Portfolio />
    </div>
    <div id="visa4tier" className='container tab-pane fade'>
        <br />
      <Tier4Visa />
    </div>
    <div id="travelandaccomodation" className='container tab-pane fade'>
      <br/>
      <TravelAndAccomdation/>
    </div>
    <div id="cab" className='container tab-pane fade'>
      <br/>
      <Cab/>
    </div>
    <div id="gmcandregistration" className='container tab-pane fade'>
      <br/>
      <GMCAndRegistration/>
    </div>
    
    
  </div>
</div>
    </div>
</div>
            </div>
            {/* header bar ends here */}
           
      

        </div>
 
  )
}

export default SuperAdminDashboard