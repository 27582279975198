import React, { useEffect, useState } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GridApi, ColumnApi, CsvExportParams } from 'ag-grid-community';
import * as XLSX from 'xlsx';  // Importing the xlsx package
import jsPDF from 'jspdf';  // Importing jsPDF
import 'jspdf-autotable';  // Importing the jspdf-autotable plugin
import pencil from '../../assets/images/pencil.png';

import server from '../Utils';
import Loader from './Loader';
import { UserData } from '../../@types/usertype';

// Mock data for demonstration
const mockColumns: { headerName: string; field: string }[] = [
  { headerName: 'First Name', field: 'first_name' },
  { headerName: 'Last Name', field: 'last_name' },
  { headerName: 'Phone', field: 'primary_contact' },
  { headerName: 'Email', field: 'user_email' },
  { headerName: 'Date of Registration', field: 'date_of_registration' },
  { headerName: 'Current Stage', field: 'current_stage_name' },
  { headerName: 'Joined Stage', field: 'stage_name' },
];

const AllUsers: React.FC = () => {
  const role = localStorage.getItem('role');
  const [users, setUsers] = useState<UserData[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserData[]>([]);
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const [searchText, setSearchText] = useState<string>('');

  let projectid: string = JSON.parse(localStorage.getItem('project_id') || '{}');

  useEffect(() => {
    setLoading(true);
    server
      .get(`/get_operation_users/${projectid}`)
      .then((response) => {
        setUsers(response.data.users);
        setFilteredUsers(response.data.users); // Initialize filtered data
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [projectid]);

  const onGridReady = (params: { api: GridApi, columnApi: ColumnApi }) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const deleteUser = (deleteid: number) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          server.delete(`/operationuser/${deleteid}`).then((response) => {
            setUsers(response.data.users);
            setFilteredUsers(response.data.users); // Update filtered users as well
            swalWithBootstrapButtons.fire({
              title: 'Deleted!',
              text: 'User has been deleted.',
              icon: 'success',
            });
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: 'Cancelled',
            text: 'Your user data is safe :)',
            icon: 'error',
          });
        }
      });
  };

  const redirectUser = (id: string) => {
    localStorage.setItem('user_id', id);
    window.location.href = 'user_edit/' + id;
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = users.filter((user) => {
      for (const key in user) {
        if (Object.prototype.hasOwnProperty.call(user, key)) {
          const val = user[key as keyof UserData];

          if (typeof val === 'string') {
            if (val.toLowerCase().includes(value)) {
              return true;
            }
          } else if (typeof val === 'number') {
            if (val.toString().includes(value)) {
              return true;
            }
          }
        }
      }
      return false;
    });

    setFilteredUsers(filteredData);
    gridApi?.setRowData(filteredData);
  };

  const resetFilters = () => {
    setFilteredUsers(users); // Reset filtered data to original dataset
    gridApi?.setFilterModel(null); // Clear all applied filters
  };

  const exportCSV = () => {
    openColumnModal('csv');
  };

  const exportPDF = () => {
    openColumnModal('pdf');
  };

  const exportXLSX = () => {
    openColumnModal('xlsx');
  };

  const openColumnModal = (format: 'csv' | 'pdf' | 'xlsx') => {
    // Open modal logic here
    setShowColumnModal(true);
    setExportFormat(format);
  };

  const closeColumnModal = () => {
    setShowColumnModal(false);
  };

  const handleExport = () => {
    if (!gridApi || !gridColumnApi || !selectedColumns.length || !exportFormat) {
      console.error('Export error: Grid API, Column API, selected columns, or export format not set.');
      return;
    }
  
    const displayedColumns = gridColumnApi.getAllDisplayedColumns();
    const columnIdsInDisplayOrder = displayedColumns.map((col) => col.getColId()).filter(colId => colId !== 'slNo' && colId !== 'actions');
    const exportParams: CsvExportParams = {
      columnKeys: selectedColumns.filter(colId => colId !== 'slNo' && colId !== 'actions'),
      fileName: 'exported_data',
    };
  
    if (exportFormat === 'csv') {
      exportParams.fileName += '.csv';
      gridApi.exportDataAsCsv(exportParams);
    } else if (exportFormat === 'xlsx') {
      const rowData: any[] = [];
      gridApi.forEachNode((node) => rowData.push(node.data));
      const filteredData = rowData.map((row) => {
        const newRow: { [key: string]: any } = {};
        columnIdsInDisplayOrder.forEach((colId) => {
          if (selectedColumns.includes(colId)) {
            newRow[colId] = row[colId];
          }
        });
        return newRow;
      });
  
      const worksheet = XLSX.utils.json_to_sheet(filteredData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, 'exported_data.xlsx');
    } else if (exportFormat === 'pdf') {
      const rowData: any[] = [];
      gridApi.forEachNode((node) => rowData.push(node.data));
      const filteredData = rowData.map((row) => {
        const newRow: { [key: string]: any } = {};
        columnIdsInDisplayOrder.forEach((colId) => {
          if (selectedColumns.includes(colId)) {
            newRow[colId] = row[colId];
          }
        });
        return newRow;
      });
  
      const doc = new jsPDF('l', 'mm', 'a4'); // 'l' for landscape mode
      const tableColumn = selectedColumns.filter(colId => colId !== 'slNo' && colId !== 'actions').map((colId) => {
        const col = displayedColumns.find((c) => c.getColId() === colId);
        return col ? col.getColDef().headerName : colId;
      });
      const tableRows = filteredData.map((row) => selectedColumns.filter(colId => colId !== 'slNo' && colId !== 'actions').map((col) => row[col]));
  
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        margin: { top: 10 },
        styles: { cellPadding: 3, fontSize: 8 },
        pageBreak: 'auto',
      });
  
      doc.save('exported_data.pdf');
    }
  
    // Close modal after export logic
    closeColumnModal();
  };
  
  const [showColumnModal, setShowColumnModal] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [exportFormat, setExportFormat] = useState<'csv' | 'pdf' | 'xlsx' | null>(null);

  const toggleColumn = (column: string) => {
    if (selectedColumns.includes(column)) {
      setSelectedColumns(selectedColumns.filter((col) => col !== column));
    } else {
      setSelectedColumns([...selectedColumns, column]);
    }
  };

  const selectAllColumns = () => {
    const allColumnIds = gridColumnApi?.getColumns()?.map((col) => col.getColId()) || [];
    setSelectedColumns(allColumnIds);
  };

  const clearAllColumns = () => {
    setSelectedColumns([]);
  };

  const isColumnSelected = (column: string) => selectedColumns.includes(column);

  const getColumnDefs = () => [
    {
      headerName: 'SL No',
      valueGetter: 'node.rowIndex + 1',  // Generate serial number based on row index
      sortable: true,
      filter: false,
      width: 100,  // Adjust width as needed
      hide: false, // Ensure this column is not hidden
    },
    {
      headerName: 'First Name',
      field: 'first_name',
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: 'Last Name',
      field: 'last_name',
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: 'Phone',
      field: 'primary_contact',
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: 'Email',
      field: 'user_email',
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: 'Date of Registration',
      field: 'date_of_registration',
      sortable: true,
      filter: true,
      floatingFilter: true,
      valueFormatter: (params: any) => moment(params.value).format('DD/MM/YYYY'),
    },
    {
      headerName: 'Current Stage',
      field: 'current_stage_name',
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: 'Joined Stage',
      field: 'stage_name',
      sortable: true,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: 'Account Status',
      field:'account_status',
      sortable: true,
      filter:true,
      floatingFilter:true,
    },
    {
      headerName: 'Actions',
      filter: false,
      cellRenderer: (params: { data: { id: number; user_id: number; product_id: number } }) => (
        <div className='row'>
          <div className='col-md-4'>
            {role === '1' || role === '2' || role === '3' || role === '5' || role === '6'  ? (
              <Link
                className='text-dark'
                to={`/user_view/${params.data.id}`}
                onClick={(e) => {
                  localStorage.setItem('user_id', params.data.id.toString());
                  localStorage.setItem('product_id', params.data.product_id.toString());
                }}
              >
                <i className='fa fa-eye'></i>
              </Link>
            ) : (
              ''
            )}
          </div>
          <div className='col-md-4'>
            {role === '1' || role === '3' || role === '5' || role === '6' || role==='8' || role === '9' ? (
              <img
                width='20px'
                height='20px'
                onClick={() => {
                  localStorage.setItem('refresh_count', '0');
                  redirectUser(params.data.id.toString());
                }}
                src={pencil}
                alt='pencil'
              />
            ) : (
              ''
            )}
          </div>
          <div className='col-md-4'>
            {role === '1' ? (
              <i onClick={() => deleteUser(params.data.id)} className='fa fa-trash'></i>
            ) : (
              ''
            )}
          </div>
        </div>
      ),
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
  };

  return (
    <div className='container-fluid'>
      <div className='d-flex'>
        <SideBar />
        <div className='headerbar'>
          <Header />
          <h4 className='text-center text-uppercase px-3 text-danger'>All Users</h4>
          <hr className='bg-danger px-1' />
          <div className='row mt-5 px-2'>
            <div className='col-md-4'>
              {/* <input
                type='text'
                className='form-control'
                placeholder='Search...'
                value={searchText}
                onChange={handleSearchInputChange}
              /> */}
            </div>
            <div className='col-md-8 text-right'>
              <button className='btn btn-success float-right mr-2 mb-3' onClick={exportCSV}>
                Export CSV
              </button>
              <button className='btn btn-danger float-right mr-2 mb-3' onClick={exportPDF}>
                Export PDF
              </button>
              <button className='btn btn-primary float-right mr-2 mb-3' onClick={exportXLSX}>
                Export XLSX
              </button>
              <button className='btn btn-secondary float-right mr-2 mb-3' onClick={resetFilters}>
                Reset Filters
              </button>
              <Link
                to='/add_user_by_counsellor'
                className='btn btn-info text-white float-right mr-2 mb-3'
                role='button'
              >
                Add User
              </Link>

            </div>
            <div className='col-md-12 ag-theme-alpine' style={{ height: '600px' }}>
              <AgGridReact
                onGridReady={onGridReady}
                columnDefs={getColumnDefs()}
                rowData={filteredUsers}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={200}
              />
            </div>
          </div>
        </div>
      </div>
      {showColumnModal && (
        <div className='modal' tabIndex={-1} role='dialog' style={{ display: 'block' }}>
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Select Columns to Export</h5>
                <button type='button' className='close' onClick={closeColumnModal}>
                  <span>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <div>
                  <button className='btn btn-primary mr-2' onClick={selectAllColumns}>
                    Select All
                  </button>
                  <button className='btn btn-danger mr-2' onClick={clearAllColumns}>
                    Clear All
                  </button>
                </div>
                <ul className='list-group mt-3'>
                  {mockColumns.map((col) => (
                    <li key={col.field} className='list-group-item'>
                      <label>
                        <input
                          type='checkbox'
                          checked={isColumnSelected(col.field)}
                          onChange={() => toggleColumn(col.field)}
                        />{' '}
                        {col.headerName}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-secondary' onClick={closeColumnModal}>
                  Close
                </button>
                <button type='button' className='btn btn-primary' onClick={handleExport}>
                  Export
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllUsers;
