import React, { useEffect, useState } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import moment from 'moment';
import { Link } from 'react-router-dom';
import server from '../Utils';
import Loader from './Loader';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GridApi, ColumnApi, ColDef } from 'ag-grid-community';
import { travelandaccomdationtype } from '../../@types/travelandaccomdationtype';

const TravelAndAccomdationList: React.FC = () => {
  const [travelAndAccomdations, setTravelAndAccomdations] = useState<Array<travelandaccomdationtype>>([]);
  const [filteredTravelAndAccomdations, setFilteredTravelAndAccomdations] = useState<Array<travelandaccomdationtype>>([]);
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const [searchText, setSearchText] = useState<string>('');

  let projectid: string = JSON.parse(localStorage.getItem('project_id') || '{}');
  let id = +projectid;

  useEffect(() => {
    setLoading(true);
    server
      .get(`/get_travelaccomdations/${id}`)
      .then((response) => {
        setTravelAndAccomdations(response.data.travelandaccomdation);
        setFilteredTravelAndAccomdations(response.data.travelandaccomdation); // Initialize filtered data
      })
      .catch((error) => {
        console.error('Error fetching travel and accommodation list:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const onGridReady = (params: { api: GridApi, columnApi: ColumnApi }) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = travelAndAccomdations.filter(item => {
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          const val = item[key as keyof travelandaccomdationtype]; // Use keyof to access typed properties

          if (typeof val === 'string' && val.toLowerCase().includes(value)) {
            return true;
          } else if (typeof val === 'number' && val.toString().includes(value)) {
            return true;
          }
        }
      }
      return false;
    });

    setFilteredTravelAndAccomdations(filteredData);
    gridApi?.setRowData(filteredData);
  };

  const resetFilters = () => {
    setFilteredTravelAndAccomdations(travelAndAccomdations); // Reset filtered data to original dataset
    gridApi?.setFilterModel(null); // Clear all applied filters
  };

  const getColumnDefs = (): ColDef[] => [
    {
      headerName: 'SL No',
      valueGetter: "node.rowIndex + 1",
      sortable: true,
      filter: false,
    },
    {
      headerName: 'First Name',
      field: 'first_name',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Last Name',
      field: 'last_name',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Vendor Name',
      field: 'vendor_name',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Accommodation Start Date',
      field: 'accommodationStartDate',
      sortable: true,
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => moment(params.value).format('Do MMMM YYYY'),
    },
    {
      headerName: 'Accommodation End Date',
      field: 'accommodationEndDate',
      sortable: true,
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => moment(params.value).format('Do MMMM YYYY'),
    },
    {
      headerName: 'Room Type',
      field: 'room_type',
      sortable: true,
      filter: true,
      valueFormatter: (params) => {
        switch (params.value) {
          case 1:
            return 'Single Ensuite room with sea view (Double bed)';
          case 2:
            return 'Single Ensuite room with sea view (Single Bed)';
          case 3:
            return 'Triple Room (Double Bed)';
          case 4:
            return 'Triple Room (Single Bed)';
          case 5:
            return 'Twin Sharing Ensuite (Single Bed)';
          case 6:
            return 'Twin Sharing Ensuite (Double Bed)';
          case 7:
            return 'Single Ensuite room (Double Bed)';
          case 8:
            return 'Single Ensuite room (Single Bed)';
          default:
            return 'Not booked';
        }
      },
    },
    {
      headerName: 'Actions',
      filter:false,
      cellRenderer: (params: { data: { user_id: number; id: number } }) => (
        <Link
          className='text-dark text-center'
          to={`/edit_travel_and_accomdation/${params.data.id}`}
        >
          <i className='fa fa-edit text-success'></i>
        </Link>
      ),
      sortable: false,
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='container-fluid'>
      <div className='d-flex'>
        <SideBar />
        <div className='headerbar'>
          <Header />
          <h4 className='text-danger text-center'>Travel And Accommodation Lists</h4>
          <hr className='bg-danger px-1' />
          <div className='row mt-5 px-2'>
            <div className='col-md-4'>
              {/* <input
                type='text'
                className='form-control'
                placeholder='Search...'
                value={searchText}
                onChange={handleSearchInputChange}
              /> */}
            </div>
            <div className='col-md-8 text-right'>
              <Link
                to={"/add_multilpe_travel_and_accomdation"}
                className='btn btn-info float-right mr-2 text-white mb-3'
                role="button"
              >
                +
              </Link>
              <button
                className='btn btn-secondary float-right mr-2 text-white mb-3'
                onClick={resetFilters}
              >
                Reset Filters
              </button>
            </div>
            <div className='col-md-12 ag-theme-alpine' style={{ height: '500px' }}>
              <AgGridReact
                onGridReady={onGridReady}
                columnDefs={getColumnDefs()}
                rowData={filteredTravelAndAccomdations}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={50}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelAndAccomdationList;
