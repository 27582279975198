import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import AddClientOnBoardDetails from './addClientOnboardingDetails';
import {Link,useParams} from 'react-router-dom';

import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { message } from 'antd';

import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import $ from 'jquery'; 
import './onboarding.css';
import validator from 'validator';
import { off } from 'process';
import { type } from 'os';
import server from '../Utils';
import Loader from './Loader';

interface props{
  user_agreed:number
}
const Onboarding:React.FC<props> = ({user_agreed}) => {
        
   
   
    const [project_id,setProjectId]=useState<number>(0);
    const [user_exist,setUserExists]=useState<boolean>(false);
    const {id}= useParams();
    const [online_dates,setOnlineDates]=useState<Array<string>>([]);
    const [online,setOnline]=useState<Array<number>>([]);
    const [offline,setOffline]=useState<Array<number>>([]);
    const [admins,setAdmins]=useState<Array<Admintype>>([]);
    const [adminid,setAdminId]=useState<number>(0);

    const [welcome_call_by_name,setWelcomeCallByName]=useState('');
    const [project_name,setProjectName]=useState('');

    const [welcome_call_by,setWelcomeBy]=useState<number>(0);
    const [welcome_kits_online,setWelcomeKitOnline]=useState<Array<welcomekittype>>([]);
    const [welcome_kits_offline,setWelcomeKitOffline]=useState<Array<welcomekittype>>([]);
    const [welcome_kit_types,setWelcomeKitTypes]=useState<Array<welcomekittype>>([]);
    const [welcome_call_completed,setWelcomeCallCompleted]=useState<number>(0);
    const [welcome_call_date,setWelcomeCallDate]=useState<string>('');
    const [update_onboarding_form,setUpdateOnboarding]=useState<number>(0);
    const [sent_for_delivery,setSentForDelivery]=useState<number>(0);
    const [sent_for_delivery_date,setSentForDeliverDate]=useState<string>('');
    const [delivered,setDelivered]=useState<number>(0);
    const [delivery_date,setDeliveryDate]=useState<string>("");
    const [clear,setClear]=useState<number>(1);
    const [onboardid,setOnboardId]=useState<number>(0);
    const [start_training,setStartTraining]=useState<number>(0);
    const [start_training_date,setStartTrainingDate]=useState<string>('');
    const [pass,setPass]=useState<number>(1);
    const [loading, setLoading] = useState(false);
    
    const [created_by,setCreatedBy]=useState<string>(""); 
    const [updated_by,setUpdatedBy]=useState<string>(""); 
    // const [user_agreed,setUserAgreed]=useState<number>(0);
    // const [name,setName]=useState<string>('');
    // const [phone,setPhone]=useState<string>('');

    useEffect(() => {
        // setLoading(true)
       server.get(`/onboarding_exists/${id}`)
       .then((response)=>{
        
        if(response.data.code===200){
          setUserExists(true)
        }else{
          setUserExists(false)
        }
       })
      //  server.get(`/operationuser/${id}`)
      //  .then((response)=>{
        
      //     if(response.data.user.user_agreement_flag===1){
      //       setUserAgreed(1)
      //     }else{
      //       setUserAgreed(0)
      //     }
      //  })
      
      //  server.get(`/get_welcomekit_products_online`)
      //  .then((response)=>{
         
          
      //      setWelcomeKitOnline(response.data.welcomekitproducts)
   
      //  })
       server.get(`/get_welcomekit_products_offline`)
       .then((response)=>{
         
           setWelcomeKitOffline(response.data.welcomekitproducts)
   
       })
      //  server.get(`/get_welcomekit_types`)
      //  .then((response)=>{
         
      //      setWelcomeKitTypes(response.data.welcome_kit_type)
   
      //  })


       server.get(`/get_onboarding_details/${id}`)
       .then((response)=>{
          // setLoading(false)
        setWelcomeBy(response.data.onboarding.welcome_call_by)
        var online=response.data.onboarding.online;
        setOnline(online)
        var online_dates=response.data.onboarding.online_date;
        setOnlineDates(online_dates);
        var offline=response.data.onboarding.offline;
        setOffline(offline);
        setWelcomeCallByName(response.data.onboarding.first_name+" "+response.data.onboarding.last_name)
       
        setProjectName(response.data.onboarding.project_name)
        setCreatedBy(response.data.onboarding.created_by) 
        setUpdatedBy(response.data.onboarding.updated_by)
        })
       
      }, []);

      
    var online_array =[];
    var offline_array=[];
    for(let i=0;i<online.length;i++){
       
      // for(let j=0;j<online.length;j++){
        for(let j=0;j<welcome_kits_online.length;j++){
       
        if(online[i]===welcome_kits_online[j].id){
        
          online_array.push(<div className='row my-3'>
            <div className='col-md-6'><i>{welcome_kits_online[i].welcome_kit_name}</i></div>
            <div className='col-md-6'><i>{online_dates[i]}</i></div>
          </div>)
        // }
      }}
      
    }
    for(let i=0;i<offline.length;i++){
       
      // for(let j=0;j<online.length;j++){
        for(let j=0;j<welcome_kits_offline.length;j++){
          
        if(offline[i]===welcome_kits_offline[j].id){
        
          offline_array.push(
            <li className='my-2'>
              <i>{welcome_kits_offline[i].welcome_kit_name}</i></li>
          
          )
        // }
      }}
      
    }

    // if (loading) {
    //   return <Loader />;
    // }
    
  return (
    <div className='container-fluid jumbotron pl-5'>
  
  {user_agreed === 1 ? 
  (user_exist === false ? 
    <Link to={`/add_onboarding_details/${id}`} className='btn btn-info text-light'>Add Onboarding Details</Link> :
    null
  ) :
  <p className='text-danger'>Kindly ask the doctor to fill their details in their portal</p>
}
    {user_exist===true? <div>
     <Link to={`/onboarding_edit/${id}`} className='text-info text-right mb-3 pr-5' ><i className='fa fa-edit'></i></Link>
      <div className='row'>
      <div className='col-md-6'><h5>Project Name</h5></div>
      <div className='col-md-6'>{project_name}</div>
      </div>
      <div className='row my-3'>      
        <div className='col-md-6'><h5>Welcome Call By</h5></div>
        <div className='col-md-6'>{welcome_call_by_name}</div>
      </div>
      <div className='row my-3'>      
        <div className='col-md-6'><h5>Added By</h5></div>
        <div className='col-md-6'>{created_by}</div>
      </div>
      <div className='row my-3'>      
        <div className='col-md-6'><h5>Updated By</h5></div>
        <div className='col-md-6'>{updated_by}</div>
      </div>
      
      {/* <h5 className='my-3'>Online Resources Provided</h5>
      {online_array} */}
      <h5 className='my-3'>Included in Courier</h5>
      <div className='row pl-3'>
      <ul style={{listStyleType:"number"}}>{offline_array}</ul>
      </div>
          </div>:""}
   
    </div>
  )
}

export default Onboarding;