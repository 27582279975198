import React from 'react';
// If the GIF is in the public folder, you can reference it directly by its path.
// If it's imported directly, webpack will handle it (e.g., when using create-react-app)
import loaderGif from '../../assets/images/loader.gif'; // adjust the path accordingly
// import './index.css';
const Loader: React.FC = () => {
  return (
    <div className="loader">
      {/* Set the alt text to something descriptive */}
      <img src={loaderGif} alt="Loading..." />
    </div>
  );
};

export default Loader;