import axios from "axios";

const server = axios.create({
     
  //baseURL: "http://localhost:8000/api/admin",
   // baseURL:"http://192.168.1.12:8000/api/admin",
 baseURL:"https://api.prod.goocampus.in/api/admin",
   
    headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded"
    }
});

export default server;
