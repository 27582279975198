import React,{useState,useEffect} from 'react'
import { Link,useParams } from 'react-router-dom'
import { vendortype } from '../../@types/vendortype';
import server from '../Utils';

 import {tier4visatype} from '../../@types/tier4visatype';
import moment from 'moment';
import Loader from './Loader';
const Tier4Visa = () => {

   
  const [project_id,setProjectId] = useState<number>(0);
  const [product_id,setProductId] = useState<number>(0);

  const [user_id,setUserid]=useState<number>(0);
  const [tier4visas,setTier4Visas]=useState<Array<tier4visatype>>([])
  const {id}= useParams();
  const [loading, setLoading] = useState(false);
  const [user_agreed,setUserAgreed]=useState<number>(0);

  useEffect(() => {
    let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
    let project_id = +projectid;
    setProjectId(project_id)
    let productid:string=JSON.parse(localStorage.getItem("product_id")||'{}');
    let product_id = +productid;
    setProductId(product_id);
    server.get(`/operationuser/${id}`)
        .then((response)=>{
         
           if(response.data.user.user_agreement_flag===1){
             setUserAgreed(1)
           }else{
             setUserAgreed(0)
           }
        })
   server.get(`/tier4visas/${id}`)
   .then((response)=>{
 
    setTier4Visas(response.data.tier4visa)

   })
 },[id]);
 
 console.log(tier4visas)
 if (loading) {
  return <Loader />;
}

  return (
    <div className='' >
      
      {/* <Link to={'/add_tier4_visa'} className='btn btn-info'>Add Tier4 Visa</Link> */}
      {user_agreed === 1 ?<Link to={`/add_tier4_visa/${id}`} className='btn btn-info text-white float-right  text-center' >Add Tier 4-Visa </Link>:<p className='text-danger'>Kindly ask the doctor to fill their details in their portal And Please Book the Plab2 Test for this client after client completes the details in thier portal</p>}
     
      <br/>
      {tier4visas.map((tier4visa, index) => (
        <div key={index} className='mt-4 jumbotron'>
          <Link className='text-danger' to={`/edit_tier4_visa/${tier4visa.id}`}><i className='fa fa-edit text-info'></i></Link>
          <br/>
          <div className='row'>
            <div className='col-md-6'>
            {tier4visa.sendEmail !== 0? <div className='row mt-2'>
            <div className='col-md-6 text-left'><h5 className='text-left'>Email Sent</h5></div>
            <div className='col-md-6 text-left'><i>{tier4visa.sendEmail === 0 ? "Not Sent" : "Sent"}</i></div>
          </div>:""}
          {tier4visa.applicationStatus !== null? <div className='row mt-2'>
            <div className='col-md-6 text-left'><h5 className='text-left'>Application Status</h5></div>
            <div className='col-md-6 text-left'><i>{tier4visa.applicationStatus === 1 ? "Yet to Start" : tier4visa.applicationStatus === 2?"In Progress":tier4visa.applicationStatus === 3?"Submitted":""}</i></div>
          </div>:""}
          {tier4visa.applicationStatus===3 && tier4visa.appointmentDate !== null?  <div className='row mt-2'>
            <div className='col-md-6 text-left'><h5 className='text-left'>Visa Appointment Date</h5></div>
            <div className='col-md-6 text-left'><i>{moment(tier4visa.appointmentDate).format('Do MMMM YYYY')}</i></div>
          </div>:""}
          { tier4visa.applicationStatus===3 && tier4visa.applicationStatus !== null? <div className='row mt-2'>
            <div className='col-md-6 text-left'><h5 className='text-left'>Visa Status</h5></div>
            <div className='col-md-6 text-left'><i>{tier4visa.visaStatus === 1 ? "processing" : tier4visa.visaStatus === 2?"Approved":tier4visa.visaStatus === 3?"Rejected":""}</i></div>
          </div>:""}
          </div>
          <div className='col-md-6'>
            
          {tier4visa.applicationStatus===3 && tier4visa.visaPeriod !== null && tier4visa.applicationStatus!==null?<div className='row mt-2'>
            <div className='col-md-6 text-left'><h5 className='text-left'>Visa Duration </h5></div>
            <div className='col-md-6 text-left'><i>{tier4visa.visaPeriod} {tier4visa.visaDuration === 1 ? "Days" : tier4visa.visaDuration === 2?"Weeks":tier4visa.visaDuration === 3?"Months":tier4visa.visaDuration===4?"years":""}</i></div>
          </div>:""}
          { tier4visa.vendor_name!==null? <div className='row mt-2'>
            <div className='col-md-6 text-left'><h5 className='text-left'>Visa Vendor </h5></div>
            <div className='col-md-6 text-left'><i>{tier4visa.vendor_name}</i> </div>
          </div>:""}
          {tier4visa.visaStatus===2 && (tier4visa.visa_file!==null   || tier4visa.visa_file!=="")?  <div className='row mt-2'>
            <div className='col-md-6 text-left'><h5 className='text-left'> Visa File</h5></div>
            <div className='col-md-6 text-left'>{tier4visa.visa_file.toLowerCase().endsWith('.pdf') ? (
            // If it's a PDF, show a link
            <a href={tier4visa.visa_file} target="_blank" rel="noopener noreferrer">
              View PDF
            </a>
          ) : (
            // Otherwise, show the image
            <img src={tier4visa.visa_file} width="200" height="200"  alt="visa file" />
          )} </div>
          </div>:""}
          </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Tier4Visa