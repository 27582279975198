import React, { useState } from 'react'
import { Fragment } from "react";
import Logo from './logo.png';
import {Link} from 'react-router-dom';
import userlogo from '../../assets/images/user_logo.png';
import user_logo_mobile from '../../assets/images/user_logo_mobile.png';
import clientavatar from '../../assets/images/user-doctor.jpeg'; 
import ticketicon from '../../assets/images/ticket_icon.png';
import server from '../../components/Utils';
import './UserSideBar.css';
import { RiLogoutCircleLine } from "react-icons/ri";

interface Props{user_email:string,first_name:string,last_name:string}

const UserSideBar:React.FC<Props> =({user_email,first_name,last_name}) => {
  const [client_photo,setClientPhoto]=useState<string>('');
    const logout=()=>
  {       
       localStorage.removeItem("loggedIn");
       setTimeout(function() { window.location.href="/userlogin" }, 1000);
      
  }
  let userid= localStorage.getItem('ClientID');
   
  server.get(`/get_documents/${userid}`)
  .then((response) => {
    if (response.data.code === 200) {
      setClientPhoto(response.data.documents.client_photo);
     
    }
  });
    return (
       
        <div className='vertical-menu text-center'>
          
     
          <Link to="/userpath">  <img src={userlogo}   id="userlogo" alt="userlogo"/> </Link>
      
        <div className='mobile-header'>
          <div className='d-flex w-100'>
            <div  style={{width:"20%",marginTop:"6%"}}>
             <button className="navbar-toggler" style={{lineHeight:"4.5"}} type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
              <i className="fa fa-bars"  aria-hidden="true"></i>
             </button> 
            </div>
            <div  style={{width:"80%"}}>
              <div className='d-flex'>
            {/* <span style={{width:"10%"}}><img src ={clientavatar} className='rounded-circle mt-4'  width="30" height="30" alt="ticket_icon"/></span> */}
            {/* <span style={{width:"40%"}}><h4 className='mt-4'> Hi {first_name}</h4></span> */}
            {/* <span style={{width:"25%"}}><img src ={client_photo===""||client_photo===null?clientavatar:client_photo} className='mt-3 rounded-circle' width="40" height="40" alt="ticket_icon"/></span> */}
            <span className='' style={{width:"100%"}}><Link className='text-left' to="/userpath"> <img className='mt-1' src={userlogo} width="90%"  height="100%"  id="user_logo_mobile" alt="user_logo_mobile"/></Link> </span>
             </div>
            </div>
        </div>
      </div>
        <div>
       
       <div className='user_name_details_desktop'>
       <img src={client_photo===""||client_photo===null?clientavatar:client_photo} className='rounded-circle' width="100" height="100"  alt="clientimage" style={{objectFit: "cover"}} /><br/>
          <h5 className='mt-3 blue-color fw-bold'>{first_name} {last_name}</h5>
          <p className='blue-color fw-bold' style={{fontSize:"10px"}}>{user_email}</p>
          </div>
        <nav className="navbar navbar-expand-md navbar-dark">
  
  
  <div className="collapse navbar-collapse" id="collapsibleNavbar">
    <div className='user_name_details_mobile'>
    <img className='rounded-circle' src={client_photo===""||client_photo===null?clientavatar:client_photo} alt={first_name+" "+last_name+"photo"} style={{objectFit: "cover"}}  width="100"  height="100"/><br/>
          <h5 className='mt-2'>{first_name}{last_name}</h5>
          <p>{user_email}</p>
    </div>
    <ul style={{paddingLeft:"10%",width:"100%"}} >
    <li className="nav-item  nav_pad user_side_bar_link">
        <Link to="/userpath" className="nav-link text-left fw-bold" style={{color: "#233974"}}>Home</Link>
      </li>
      <li className="nav-item  nav_pad user_side_bar_link">
        <Link to="/user_training" className="nav-link text-left fw-bold" style={{color: "#233974"}}>Training</Link>
      </li>
      <li className="nav-item nav_pad user_side_bar_link">
        <Link to="/user_test_bookings" className="nav-link  text-left fw-bold"  style={{color: "#233974"}} >Test Bookings</Link>
      </li>
      <li className="nav-item nav_pad user_side_bar_link">
        <Link to="/user_registrations" className="nav-link text-left fw-bold" style={{color: "#233974"}} >Registrations</Link >
      </li>
      
      <li className="nav-item nav_pad user_side_bar_link">
        <Link to="/user_training_and_subscriptions" className="nav-link text-left fw-bold" style={{color: "#233974"}} >Training & Subscriptions</Link>
      </li>
      <li className="nav-item nav_pad user_side_bar_link">
        <Link to="/users_courses_allocated" className="nav-link text-left fw-bold" style={{color: "#233974"}} >Courses Allocated</Link>
      </li>  
      <li className="nav-item nav_pad user_side_bar_link">
        <Link to="/user_portfolios" className="nav-link text-left fw-bold" style={{color: "#233974"}} >Portfolio</Link>
      </li> 
      <li className="nav-item nav_pad user_side_bar_link">
        <Link to="/user_additional_documents" className="nav-link text-left fw-bold" style={{color: "#233974"}} >Documents</Link>
      </li> 
      <li className="nav-item nav_pad user_side_bar_link">
      <Link to="/user_travel_and_accomdations"  className="nav-link text-left fw-bold" style={{color: "#233974"}} >Travel & Accommadation</Link>
      </li> 
      <li className="nav-item nav_pad user_side_bar_link">
        <Link to="/user_tier4visa" className="nav-link text-left fw-bold" style={{color: "#233974"}} >Tier 4-visa</Link>
      </li>
      <li className="nav-item nav_pad user_side_bar_link">
        <Link to={"/usercab_details"} className="nav-link text-left fw-bold" style={{color: "#233974"}} >Flight & Cabs</Link>
      </li>  
      <li className="nav-item nav_pad user_side_bar_link">
        <Link to="/user_gmc_registrations" className="nav-link text-left fw-bold" style={{color: "#233974"}} >GMC License & Job</Link>
      </li> 
      <br/>
     
       <div className='text-center pr-5'>
      <button  onClick={()=>logout()} className='btn fw-bold logout-btn' ><RiLogoutCircleLine className='mb-1' />  Logout</button>
      </div>
    </ul>
   
    
  </div>
  </nav>
  

    </div>
        </div>
     
      )
}

export default UserSideBar;