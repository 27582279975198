import React from 'react'
import { Fragment } from "react";
import Logo from './logo.png';
import {Link} from 'react-router-dom';
import './MainSideBar.css';

const MainSideBar = () => {
     let role =localStorage.getItem('role');
    return (
        <Fragment>
             <div className='sidebar' >
              {/* Topbar */}
                    {/* Sidebar */}
                <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                {/* Sidebar - Brand */}
            <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/superadmindashboard">
                    <div className="sidebar-brand-icon  text-right">
                    <img src={Logo} width="100%" height="100%" alt="goocampuslogo"/>
                    </div>
                    {/* <div className="sidebar-brand-text mx-3 text-start">Goocampus</div> */}
                </Link>
                {/* Divider */}
                <hr className="sidebar-divider my-0" />
                {/* Nav Item - Dashboard */}
                <li className="nav-item active">
                    <Link className="nav-link" to="/">
                    <i className="fas fa-fw fa-tachometer-alt" />
                    <span>Dashboard</span></Link>
                </li>
                {/* Divider */}
                <hr className="sidebar-divider" />
                {/* Heading */}
                <div className="sidebar-heading">
                    Interface
                </div>
                {/* Nav Item - Pages Collapse Menu */}
             
               {role==="1" || role==="3" || role==="5"? <li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                    <i className="fas fa-fw fa-cog" />
                    <span>Projects</span>
                    </Link>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        
                        <Link className="collapse-item text-left" to="/add_project">Add Projects</Link>
                        <Link className="collapse-item text-left" to="/projects">Projects</Link>
                    </div>
                    </div>
                </li>:""}
                
               </ul>
    
                </div>
        </Fragment>
      )
}

export default MainSideBar;