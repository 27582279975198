import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import { ServiceData } from '../../@types/servicetype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import { service_categorytypedata } from '../../@types/service_categorytypedata';
import { StageData } from '../../@types/stagetype';
import { useParams } from 'react-router-dom';
import server from '../Utils';
import Loader from './Loader';
const AddServiceCategoriser = () => {
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [stages,setStages]=useState<Array<StageData>>([])
    const [project_id,setProjectId]=useState<number>(0);
    const [product_id,setProductId]=useState<number>(0);
    const [service_category_id,setServiceCategoryId]=useState<number>(0);
    const [service_categories,setServiceCategories]=useState<Array<ServiceCategoryType>>([])
    const [services,setServices]=useState<Array<ServiceData>>([])
    const [service_id,setServiceId]=useState<number>(0)
    const [service_categoriser_name,setServiceCategoriserName]=useState<string>("")
    const [service_category_type,setCategoryTypeId]=useState<number>(0)
    const[service_type,setServiceType]=useState<number>(0);
    const [service_display,setServiceDisplay]=useState<number>(0)
    const[stage_id,setStageId]=useState<number>(0);
    const [loading, setLoading] = useState(false);

    const [service_category_types,setServiceCategorytypes]=useState<Array<service_categorytypedata>>([])
    
    useEffect(() => {
        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let id= +projectid
        setProjectId(id)
        // server.get(`/service_category_types/${id}`)
        // .then((response)=>{
        //     console.log(response)
        //     if(response.data.service_category_types.length>0){
        //         setServiceCategorytypes(response.data.service_category_types)
        //     }else{
        //         setServiceCategorytypes([]);
        //     }
        // })
        server.get(`/get_product/${id}`)
        .then((response)=>{
            
            setProducts(response.data.product)
            
        })
        server.get(`/get_stage/${product_id}`)
        .then((response)=>{
            
          setStages(response.data.stage);
            
        })
        server.get(`/get_service_categories`)
        .then((response)=>{
           
            if(response.data.service_categories.length>0){
              setServiceCategories(response.data.service_categories)
            }else{
              setServiceCategories([]);
            }
        })
    
    
      }, []);
   
       const getServices =(id:number)=>{
        setServiceCategoryId(id)
        server.get(`/service_category_type_on_category/${id}`)
        .then((response)=>{
            console.log(response)
            if(response.data.service_category_types.length>0){
                setServiceCategorytypes(response.data.service_category_types)
            }else{
                setServiceCategorytypes([]);
            }
        })
        server.get(`/service_on_category/${id}`)
        .then((response)=>{
            setServices(response.data.services)
        })
       }
       const setStage=(id:number)=>
       {
    
         
         setProductId(id);
         
           server.get(`/get_stage/${id}`)
           .then((response)=>{
               
             setStages(response.data.stage);
               
           })
       }
    const addTrainingCrud=(e:React.FormEvent<HTMLFormElement>)=>{
        e.preventDefault();
        setLoading(true)
        if(product_id===0){
            toast('select the product');
        }else if(service_category_id===0){
            toast('Select Service Category')
        }else if(service_category_type===0){
            toast('select Category type')
        }
        
        else{
            var form =document.getElementById('add_training_crud') as HTMLFormElement
            var formdata= new FormData(form);
            server.post(`/add_service_categoriser`,formdata)
            .then((response)=>{
               if(response.data.code===200){
                setLoading(false)
                toast(response.data.message);
                setTimeout(function () { window.history.back(); }, 1000);
               }else{
                setLoading(false)
                toast(response.data.message);
               }
            })
        }
    }
    if (loading) {
        return <Loader />;
      }

    return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}

<div className="row px-2">
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-primary shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
        Earnings (Monthly)</div>
        <div className="h5 mb-0 font-weight-bold text-gray-800">$40,000</div>
    </div>
    <div className="col-auto">
        <i className="fas fa-calendar fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-success shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
        Earnings (Annual)</div>
        <div className="h5 mb-0 font-weight-bold text-gray-800">$215,000</div>
    </div>
    <div className="col-auto">
        <i className="fas fa-dollar-sign fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-info shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3  ">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Tasks
        </div>
        <div className="row no-gutters align-items-center">
        <div className="col-auto">
            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">50%</div>
        </div>
        <div className="col">
            <div className="progress progress-sm mr-2">
            <div className="progress-bar bg-info" role="progressbar" style={{width: '50%'}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
            </div>
        </div>
        </div>
    </div>
    <div className="col-auto">
        <i className="fas fa-clipboard-list fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
{/* Pending Requests Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-warning shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
        Pending Requests</div>
        <div className="h5 mb-0 font-weight-bold text-gray-800">18</div>
    </div>
    <div className="col-auto">
        <i className="fas fa-comments fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
</div>
    <h4 className='text-danger text-center'>Add Stage</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5 px-3'>
    
    <div className='col-md-12'>
      
        <form id="add_training_crud" onSubmit={addTrainingCrud} className='w-100' >
          <div className='row'>
            <input type="hidden" name="project_id" value={project_id} id="project_id" />
            
            {project_id!==0?<div className='col-md-3'>
                <label>Product </label>
            <select className='form-control' name='product_id' id="product_id" onChange={(e)=>setStage(parseInt(e.target.value))} required>
                        <option value={0}>Select Product</option>
                        {products.map((product)=>(
                            <option value={product.id}>{product.product_name}</option>))}
            </select>
            </div>:""}
            
                          
            {product_id!==0? <div className='col-md-3'>
            <label htmlFor="">Service Category:</label>
               <select className='form-control' id="service_category_id" name="service_cat_id" value={service_category_id} onChange={(e)=>getServices(parseInt(e.target.value))}> 
              
                    <option value="0">Select Service Category</option>        
                         {service_categories.map((service_category,index)=>(
                            // service_category.id===2||service_category.id===13?<option value={service_category.id}>{service_category.service_category_name}</option>:""
                            <option value={service_category.id}>{service_category.service_category_name}</option>
                       ))}   
                </select>             
                            
                </div>:""}
            {service_category_id!==0?<div className='col-md-3'>
              <label>Service Category Type</label>
              <select id="service_category_type" className='form-control' name="service_category_type" value={service_category_type} onChange={(e)=>setCategoryTypeId(parseInt(e.target.value))}>
                <option value="0">Select Category Type</option>
                {service_category_types.map((service_category_type,index)=>(
                  <option value={service_category_type.id}>{service_category_type.service_category_type}</option>
                ))}
              </select>
            </div>:""}
                {service_category_id!==0 && service_category_type!==0?<div className='col-md-3'>
              <label htmlFor="">Services :</label>
               <select className='form-control' id="service_id" name="service_id" value={service_id} onChange={(e)=>setServiceId(parseInt(e.target.value))}>

                    <option value="0">Select Service </option>
                         {services.map((service,index)=>(
                            <option value={service.id}>{service.service_name}</option>
                         ))}
                </select>

                </div>:""}
                
               

          </div>
          {/* <div className='row my-3'>
            {service_category_id!==0?<div className='col-md-3'>
             <label>Service Display</label>
                     <select name='service_display' value={service_display} onChange={(e)=>setServiceDisplay(parseInt(e.target.value))} className='form-control'>
                         <option value={0}>Choose whether to display</option>
                         <option value={1}>Yes</option>
                         <option value={2}>No</option>
                     </select>
                        
                </div>:""}
                
                {service_display===1? <div className='col-md-2'><label>Service Type</label>
                     <select name='service_type' value={service_type} onChange={(e)=>{setServiceType(parseInt(e.target.value))}} className='form-control'>
                       <option value={0}>Select service type</option>
                       <option value={1}>Fixed</option>
                       <option value={2}>UnFixed</option>
                     </select>
                </div>:"" }
                {service_display===1 ? <div className='col-md-3'>
                    <label >Stages</label>
                    <select className='form-control' name='stage_id' id="stage_id" value={stage_id} onChange={(e)=>setStageId(parseInt(e.target.value))} required>
                        <option value={0}>Select Stage</option>
                        {stages.map((stage,index)=>(
                            <option value={stage.id}>{stage.stage_name}</option>))}
                    </select>
                </div>:""}
          </div> */}
          
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Submit</button></div>
        </form>
     
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default AddServiceCategoriser