import { useEffect,useState } from "react";
import React from 'react';
import validator from 'validator';
import server from '../Utils';
import { CollegeData } from "../../@types/collegedata";
import moment from "moment";
import { SpecialityData } from "../../@types/specialitytype";
import { SubSpecialityData } from "../../@types/subspeciality";
import { Link, useParams } from "react-router-dom";
import './ClientDetails.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import pencil from '../../assets/images/pencil.png';
import Loader from "./Loader";


const UserDeclare = () => {
  const [first_name,setfirstName]=useState<string>("");
  const [last_name,setlastName]=useState<string>('');
  const [passport_first_name,setPassportFirstName]=useState<string>("")
  const [passport_last_name,setPassportlastName]=useState<string>("")
  const [certificate_first_name,setCertificateFirstName]=useState<string>('')
  const [certificate_last_name,setCertificateLastName]=useState<string>('')
  const [primary_contact_country_code,setPrimaryCountryCode]=useState<string>('')
  const [primary_contact,setPrimaryContact]=useState<string>('')
  const [alernate_country_code,setAlternateCountryCode]=useState<string>('')
  const [alternate_phone_number,setAlternatePhoneNumber]=useState<string>('')
  const [user_email,setUserEmail]=useState<string>('')
  const [password,setPassword]=useState<string>('');
  const [last_password,setLastPassword]=useState<string>('');
  const [dob,setDob]=useState<string>('');
  const [fathers_full_name,setFathersFullName]=useState<string>('')
  const [fathers_email,setFathersEmail]=useState<string>('')
  const [fathers_contact_country_code,setFathersContactCountryCode]=useState('')
  const [fathers_contact,setFathersContact]=useState<number|undefined>()
  const [mothers_full_name,setMothersFullName]=useState<string>()
  const [mothers_email,setMothersEmail]=useState<string>('');
  const [mothers_country_code,setMothersCountryCode]=useState<string>();
  const [mothers_contact,setMothersContact]=useState<number|undefined>();
  const [passport_exist,setPassportExist]=useState<number>(0);
  const [passport_number,setPassportNumber]=useState<string>('');
  const [passport_issue_date,setPassportIssueDate]=useState<string>('');
  const [passport_expiry_date,setPassportExpiryDate]=useState<string>('');  
  const [loading, setLoading] = useState(false);

  const [collegename,setCollegename]=useState('')
  // address  details
  const [current_door_no,setCurrentDoorNo]=useState<string>('');
    const [current_street,setCurrentStreet]=useState<string>('');
    const [current_locality,setCurrentLocality]=useState<string>('');
    const [current_city,setCurrentCity]=useState<string>('');
    const [current_state,setCurrentState]=useState<string>('');
    const [current_country,setCurrentCountry]=useState<string>('');
    const [current_postal_code,setCurrentPostalCode]=useState<string>('');

    const [permanent_door_no,setPermanentDoorNo]=useState<string>('');
    const [permanent_street,setPermanentStreet]=useState<string>('');
    const [permanent_locality,setPermanentLocality]=useState<string>('');
    const [permanent_city,setPermanentCity]=useState<string>('');
    const [permanent_state,setPermanentState]=useState<string>('');
    const [permanent_country,setPermanentCountry]=useState<string>('');
    const [permanent_postal_code,setPermanentPostalCode]=useState<string>('');
    const [address_same_flag,setAddressSameFlag]=useState<number>(0);

    // college data
    const [colleges,setColleges]=useState<Array<CollegeData>>([])
    const [collegeid,setCollegeId]=useState<number>(0);
    const [pmq_status,setPmQStatus]=useState<number>(0);
    const [pmq_start_date,setPmqStartDate]=useState<string>('');
    const [pmq_end_date,setPmqEndDate]=useState<string>('');
    const [pmq_finish_date,setPmqFinishDate]=useState<string>('');
   
    //language test details
  const [language_test_taken,setLanguageTestTaken]=useState<number>(0);
  const [language_test_type,setLanguageTestType]=useState<number>(0);
  const [language_test_date,setLanguageTestDate]=useState<string>("");
  const [ielts_trf_number,setIELTStrfNo]=useState<string>("");
  const [ielts_listening_score,setIELTSListeningScore]=useState<string>('');
  const [ielts_reading_score,setIELTSReadingScore]=useState<string>('');
  const [ielts_writing_score,setIELTSWritingScore]=useState<string>('');
  const [ielts_speaking_score,setIELTSSpeakingScore]=useState<string>('');
  const [ielts_overall_score,setIELTSOverallScore]=useState<string>('');
  const [oet_candidate_number,setOetCandidateNumber]=useState<string>('');
  const [oet_listening_score,setOetListeningScore]=useState<string>('');
  const [oet_reading_score,setOetReadingScore]=useState<string>('');
  const [oet_writing_score,setOetWritingScore]=useState<string>('');
  const [oet_speaking_score,setOetSpeakingScore]=useState<string>('');
  const [oet_overall_score,setOetOverallScore]=useState<string>('');

  const [gmc_reference_number_exist,setGmcReferenceNumberExist]=useState<number>(0)
  const [gmc_reference_number,setGmcReferenceNumber]=useState<string>('')
  const [internship_start_date,setInternshipStartDate]=useState<string>("")
  const [internship_end_date,setInternshipEndDate]=useState<string>("0")


  const [emp_completion_status,setEmployCompletion]=useState<number>(0);
  const [emp_hospital_name,setEmployHospital]=useState<string>("");
  const [emp_city,setEmpCity] =useState<string>("");
  const [emp_state,setState]=useState<string>('');
  const [specialities,setSpecialities]=useState<Array<SpecialityData>>([]);
  const [speciality_id,setSpecialityId]=useState<number>(0);
  const [sub_specialities,setSubSpeciality]=useState<Array<SubSpecialityData>>([]);
  const [subspecialityid,setSubSpecialityId]=useState<number>(0);

  const [personal_note,setPersnolNote]=useState<string>("");
  const [welcome_call_date,setWelcomeCallDate]=useState<string>('');
  const [welcome_call_time,setWelcomeCallTime]=useState<number>(0);

  //accordian states
  const [isPersonalDetailsOpen, setIsPersonalDetailsOpen] = useState(false);
  const [isAddressDetailsOpen, setIsAddressDetailsOpen] = useState(false);
  const [isPMQDetailsOpen, setIsPMQDetailsOpen] = useState(false);
  const [isLanguageTestDetailsOpen, setIsLanguageTestDetailsOpen] = useState(false);
  const [isGMCInternshipOpen, setIsGMCInternshipOpen] = useState(false);
  const [isEmploymentAndSpecialityOpen, setIsEmploymentAndSpecialityOpen] = useState(false);
  const [isPersonalNoteOthersOpen, setIsPersonalNoteOthersOpen] = useState(false);

  const [passport_photo,setPassportPhoto]=useState<string>('');
  const [passport_photo_back,setPassportPhotoBack]=useState<string>('')
  const [client_photo,setClientPhoto]=useState<string>('');
  const [provisional_degree_certificate,setProvisionalDegreeCertificate]=useState<string>('');
  const [final_degree_certificate,setFinalDegreeCertificate]=useState<string>('');
  const [provisional_registration_certificate,setProvisionalRegistrationCertificate]=useState<string>('');
  const [final_registration_certificate,setFinalRegistrationCertificate]=useState<string>('');
  const [internship_completion_certificate,setInternshipCompletionCertificate]=useState<string>('');
  const {id}=useParams()

  const [certificate_names,setcertificateNames]=useState<string>('');
  const [certificate_desc,setCertificateDesc]=useState<string>('');
  const [certificate_files,setCertificateFiles]=useState<string>('');
  const [file_count,setFileCount]=useState<number>(0);

  const[contract_file,setContractFile]=useState<string>('')
  let userid= localStorage.getItem('ClientID');
  var loggedIn=localStorage.getItem('loggedIn');
    useEffect(() => {
        if(loggedIn!=="true")
  {
    window.location.href="/userlogin";
  }
    
   
    server.get(`/operationuser/${userid}`)
    .then((response)=>{
setLoading(true)
      // userProfileStarts
    setfirstName(response.data.user.first_name)
    setlastName(response.data.user.last_name)
    setPassportFirstName(response.data.user.passport_first_name)
    setPassportlastName(response.data.user.passport_last_name)
    setCertificateFirstName(response.data.user.certificate_first_name)
    setCertificateLastName(response.data.user.certificate_last_name)
    setPrimaryCountryCode(response.data.user.primary_contact_country_code)
    setPrimaryContact(response.data.user.primary_contact)
    
    setAlternateCountryCode(response.data.user.alernate_country_code)
    setAlternatePhoneNumber(response.data.user.alternate_phone_number)
    setUserEmail(response.data.user.user_email)
    setPassword(response.data.user.last_password)
    setLastPassword(response.data.user.last_password)
    setDob(response.data.user.dob)
    setFathersFullName(response.data.user.fathers_full_name)
    setFathersEmail(response.data.user.fathers_email)
    setFathersContactCountryCode(response.data.user.fathers_contact_country_code)
    setFathersContact(response.data.user.fathers_contact)
    setMothersFullName(response.data.user.mothers_full_name)
    setMothersEmail(response.data.user.mothers_email)
    setMothersCountryCode(response.data.user.mothers_country_code)
    
    setMothersContact(response.data.user.mothers_contact)
    if(response.data.user.passport_exist===1 ){
      setPassportExist(1)
    }else if(response.data.user.passport_exist===2){
      setPassportExist(2)
    }else{
      setPassportExist(0)
    }
    setPassportNumber(response.data.user.passport_number)
    setPassportIssueDate(response.data.user.passport_issue_date)
    setPassportExpiryDate(response.data.user.passport_expiry_date)
//userProfile Ends

//user Address Starts
setCurrentDoorNo(response.data.user.current_door_no)
    setCurrentStreet(response.data.user.current_street)
    setCurrentLocality(response.data.user.current_locality)
    setCurrentCity(response.data.user.current_city_name)
    setCurrentState(response.data.user.current_state_name)
    setCurrentCountry(response.data.user.current_country_name)
    setCurrentPostalCode(response.data.user.current_postal_code)

    setPermanentDoorNo(response.data.user.permanent_door_no)
    setPermanentStreet(response.data.user.permanent_street)
    setPermanentLocality(response.data.user.permanent_locality)
    setPermanentCity(response.data.user.permanent_city_name)
    setPermanentState(response.data.user.permanent_state_name)
    setPermanentCountry(response.data.user.permanent_country_name)
    setPermanentPostalCode(response.data.user.permanent_postal_code)

    //address details ends

    //pmq details starts
    setCollegeId(response.data.user.pmq_college);
    setPmQStatus(response.data.user.pmq_status);
    setPmqStartDate(response.data.user.pmq_start_date);
    setPmqEndDate(response.data.user.pmq_end_date);
    setPmqFinishDate(response.data.user.pmq_finish_date);
    setCollegename(response.data.user.pmq_college);
    //pmq details Ends
//language test details
    setLanguageTestTaken(parseInt(response.data.user.language_test_taken))
      setLanguageTestType(parseInt(response.data.user.language_test_type))
      setLanguageTestDate(response.data.user.date_of_test)
      setIELTStrfNo(response.data.user.ielts_trf_number)
      if(response.data.user.ielts_trf_number===null||response.data.user.ielts_trf_number===""){
        setIELTStrfNo("")
      }
      setIELTSListeningScore(response.data.user.ielts_listening_score)
      setIELTSReadingScore(response.data.user.ielts_reading_score)
      setIELTSWritingScore(response.data.user.ielts_writing_score)
      setIELTSSpeakingScore(response.data.user.ielts_speaking_score)
      setIELTSOverallScore(response.data.user.ielts_overall_score)
      
      if(response.data.user.oet_candidate_number===null||response.data.user.oet_candidate_number===""){
        setOetCandidateNumber('')
      }else{
        setOetCandidateNumber(response.data.user.oet_candidate_number)
      }
      setOetListeningScore(response.data.user.oet_listening_score)
      setOetReadingScore(response.data.user.oet_reading_score)
      setOetWritingScore(response.data.user.oet_writing_score)
      setOetSpeakingScore(response.data.user.oet_speaking_score)
      setOetOverallScore(response.data.user.oet_overall_score)
      //language test details


      if(response.data.user.pmq_status===null){
        setPmQStatus(0);
      }else{
        setPmQStatus(response.data.user.pmq_status);
      }
      if(response.data.user.gmc_refrence_exists===null){
        setGmcReferenceNumberExist(0);
      }else{
        setGmcReferenceNumberExist(response.data.user.gmc_refrence_exists);
      }if(response.data.user.gmc_reference_number===null){
        setGmcReferenceNumber('')
       }else{
        setGmcReferenceNumber(response.data.user.gmc_reference_number)
       }
       if(response.data.user.internship_end_date===null){
        setInternshipEndDate('')
       }else{
        setInternshipEndDate(response.data.user.internship_end_date)
       }
       if(response.data.user.internship_end_date===null){
        setInternshipStartDate('')
       }else{
        setInternshipStartDate(response.data.user.internship_start_date)
       }

       setPmQStatus(response.data.user.pmq_status);
       setEmployCompletion(response.data.user.emp_completion_status);
       
       setEmployHospital(response.data.user.emp_hospital_name);
       setEmpCity(response.data.user.emp_city_name);
       setState(response.data.user.emp_state_name);
       setSpecialityId(response.data.user.interested_speciality);
       
       setSubSpecialityId(response.data.user.sub_speciality);
       setPersnolNote(response.data.user.personal_note);
       setWelcomeCallDate(response.data.user.welcome_call_date);
       setWelcomeCallTime(response.data.user.welcome_call_time);
       setContractFile(response.data.user.contract_file)
       setLoading(false)
    })

    server.get(`/operation_user_view/${userid}`)
    .then((response)=>{
         setPmQStatus(response.data.user.pmq_status);
         setPersnolNote(response.data.user.personal_note);
         

    })

    server.get(`/get_documents/${userid}`)
  .then((response)=>{
    setLoading(true)
    if(response.data.code===200){
        console.log(response.data)
    // setPmQStatus(response.data.documents.pmq_status);
    setClientPhoto(response.data.documents.client_photo);
    setPassportPhoto(response.data.documents.passport_photo);
    setPassportPhotoBack(response.data.documents.passport_photo_back);
    setProvisionalDegreeCertificate(response.data.documents.provisional_degree_certificate);
    setFinalDegreeCertificate(response.data.documents.final_degree_certificate);
    setProvisionalRegistrationCertificate(response.data.documents.provisional_registration_certificate);
    setFinalRegistrationCertificate(response.data.documents.final_registration_certificate);
    setInternshipCompletionCertificate(response.data.documents.internship_completion_certificate);
    setcertificateNames(response.data.documents.certificate_names);
    setCertificateDesc(response.data.documents.certificate_description);
    setCertificateFiles(response.data.documents.certificate_file);
    setFileCount(response.data.documents.file_count);
   
    setTimeout(function () { setLoading(false) }, 1000);
 }
})


  },[]);



  if (loading) {
    return <Loader />;
  }
  return (
    <div className="px-5">
      <div id="accordion">
        {/* Personal And Passport Details */}
       
         
          <div id="personal_details" >
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
              <h3 className="blue-color fw-bold">Personal Details</h3>
              </div>
              
            </div>
             
            <div className='row mt-4'>


             <div className='col-md-6'>
              <label className="blue-color fw-bold">First Name(According Aadhaar / Passport)</label>

             </div>
             <div className='col-md-6'>
             <p>{passport_first_name}</p>
             </div>
             <div className='col-md-6'>
              <label className="blue-color fw-bold">Last Name(According To Aadhaar / Passport)</label>

             </div>
             <div className='col-md-6'>
             <p>{passport_last_name}</p>
             </div>
             <div className='col-md-6'>
              <label className="blue-color fw-bold">First Name(According To Degree Certificate)</label>

             </div>
             <div className='col-md-6'>
             <p>{certificate_first_name} </p>
             </div>
             <div className='col-md-6'>
              <label className="blue-color fw-bold">Last Name(According To Degree Certificate)</label>

             </div>
             <div className='col-md-6'>
             <p>{certificate_last_name}</p>
             </div>

            </div>

            <div className='row'>
             <div className='col-md-6'>
               <label className="blue-color fw-bold">Email </label>

             </div>
             <div className='col-md-6'><p>{user_email}</p></div>
             <div className='col-md-6'>
                <label className="blue-color fw-bold">Date Of Birth</label>

             </div>
             <div className="col-md-6">
             <p>{moment(dob).format('Do MMMM YYYY') }</p>
             </div>
             <div className='col-md-6'>
                <label className="blue-color fw-bold">Primary Contact</label>
                {/* <input type="text" className='form-control'  name="primary_contact_country_code" id="primary_contact_country_code"  value={primary_contact_country_code} placeholder='Enter Date Of Birth' onChange={(e)=>setPrimaryCountryCode(e.target.value)} required/> */}

             </div>
             <div className="col-md-6">
             <p>{primary_contact_country_code}-{primary_contact}</p>
             </div>
             <div className='col-md-6'>
                <label className="blue-color fw-bold">Alternate Contact</label>
                {/* <input type="text" className='form-control'  name="primary_contact_country_code" id="primary_contact_country_code"  value={primary_contact_country_code} placeholder='Enter Date Of Birth' onChange={(e)=>setPrimaryCountryCode(e.target.value)} required/> */}

             </div>
             <div className="col-md-6">
             <p>{alernate_country_code}-{alternate_phone_number}</p>
             </div>
            </div>
            <hr />
            <h3 className='blue-color fw-bold my-4 text-left'>Parents Details</h3>
            <div className='row'>
            <div className='col-md-3'>
              <label className="blue-color fw-bold">Father's Full Name</label>
             <p>{fathers_full_name}</p>
             </div>
             <div className='col-md-5'>
             <label className="blue-color fw-bold">Father's Email</label>
              <p>{fathers_email}</p>

             </div>
             <div className='col-md-3'>
             <label className="blue-color fw-bold">Father's Contact</label>
              <p>{fathers_contact_country_code}-{fathers_contact}</p>
            </div>
            </div>
            <div className='row mt-4'>
            <div className='col-md-3'>
              <label className="blue-color fw-bold">Mother's Full Name</label>
              <p>{mothers_full_name}</p>
             </div>
             <div className='col-md-5'>
             <label className="blue-color fw-bold">Mother's Email</label>
              <p>{mothers_email}</p>

             </div>

             <div className='col-md-3'>
             <label className="blue-color fw-bold">Mother's Contact</label>
              <p>{mothers_country_code}-{mothers_contact}</p>
            </div>
             {/* Address Details */}
        <hr/>
       
          <div id="user_address_details" >
          <div className="card-body">
          <h3 className='blue-color fw-bold my-4 text-left'>Address Details</h3>
             <div className="row mt-4">
                <div className="col-md-6">
                  <label className="blue-color fw-bold">Current Address</label>
                  <p>{current_door_no},{current_street},{current_locality},{current_city},{current_state},{current_country}-{current_postal_code}</p>
                </div>
                {address_same_flag!==null?<div className="col-md-6">
                <label className="blue-color fw-bold">Permanant Address</label>
                  <p>{permanent_door_no},{permanent_street},{permanent_locality},{permanent_city},{permanent_state},{permanent_country}-{permanent_postal_code}</p>
                </div>:""}
              </div>
      </div>
          </div>
            <hr/>
            
            {passport_exist===1?<h3 className='blue-color fw-bold my-4 text-left'>Passport Details</h3>:""}
           
             <div className='row mb-3'>

              {passport_exist===1?<div className='col-md-3'>
              <label className="blue-color fw-bold">Passport Number</label>
                <p className="mt-2">{passport_number}</p>
              </div>:""}
              {passport_exist===1? <div className='col-md-3'>
              <label className="blue-color fw-bold">Passport Issue Date</label>
              <p className="mt-2">{moment(passport_issue_date).format('Do MMMM YYYY') }</p>
              </div>:""}
              {passport_exist===1?<div className='col-md-3'>
              <label className="blue-color fw-bold">Passport Expiry Date</label>
              <p className="mt-2">{moment(passport_expiry_date).format('Do MMMM YYYY') }</p>
              </div>:"" }
              </div>
              {passport_exist===1? <hr/>:null}
      </div>
    </div>
          </div>
        

       
        

        {/* Primary Medical Qualification Details */}
        {/* <div className="card"> */}
          
          <div id="pmq_details" >
          <div className="card-body">
          <div className="row">
              <div className="col-md-6">
              <h3 className='blue-color fw-bold my-4 text-left'>Primary Medical Qualification Details</h3>
              </div>
              <div className="col-md-6">
                {/* <Link to={`/user_pmq_details_edit/${id}/`}><img className="rounded"  style={{border:"2px solid #233974"}} width="20px" height="20px"  src={pencil} alt="pencil"/></Link> */}
              </div>
            </div>
            
              <div className="row">
                <div className="col-md-6"><label className="blue-color fw-bold">College Name</label></div>
                <div className="col-md-6">{collegename}</div>
                <div className="col-md-6 mt-3"><label className="blue-color fw-bold">Primary Medical Qualification Status</label></div>
                <div className="col-md-6 mt-3">{pmq_status===1?"Ongoing":pmq_status===2?"Internship":pmq_status===3?"Completed":""}</div>
              </div>

              <div className='row mt-3'>
         { pmq_status!==0? <div className='col-md-4'>
          <label className="blue-color fw-bold">PMQ Start Date</label>
         <p>{moment(pmq_start_date).format('Do MMMM YYYY') }</p>
          </div>:""}
          { pmq_status!==0 && pmq_status!==1 ? <div className='col-md-4'>
          <label className="blue-color fw-bold">PMQ End Date</label>
          <p>{moment(pmq_end_date).format('Do MMMM YYYY') }</p>
          </div>:""}
          { pmq_status===3? <div className='col-md-4'>
          <label className="blue-color fw-bold">PMQ Finish Date</label>
          <p>{moment(pmq_finish_date).format('Do MMMM YYYY') }</p>
          </div>:""}
         </div>
      </div>
          </div>
        {/* </div> */}
        <hr/>
        {pmq_status===2||pmq_status===3 ?<div className='row'>
            <h4 className='blue-color fw-bold text-left my-4'>Intenship Details</h4>
                <div className='col-md-6'>
                    <label className="blue-color fw-bold">Internship Start Date</label>
                  <p>{moment(internship_start_date).format('Do MMMM YYYY')}</p>

                </div>
                <div className='col-md-6'>
                    <label className="blue-color fw-bold">Internship End Date</label>
                    <p>{moment(internship_end_date).format('Do MMMM YYYY')}</p>
                </div>
            </div>:""}
        {/* Language Test Details */}
        {/* <div className="card"> */}
          
          <div id="language_test_details">
          <div className="card-body">
      
        <div className="row">
              <div className="col-md-6">
             <h3 className="blue-color fw-bold my-3">GMC & Language Test Details</h3>
              </div>
              <div className="col-md-6">
                {/* <Link to={`/user_language_test_edit/${id}/`}><img className="rounded"  style={{border:"2px solid #233974"}} width="20px" height="20px"  src={pencil} alt="pencil"/></Link> */}
              </div>
            </div>
         <div className='row'>
                <div className='col-md-4'>
                   <label className="blue-color fw-bold">Passed IELTS/OET Exam?</label>
                   <p className="mt-2">{language_test_taken===1?"Yes":language_test_taken===2?"No":""}</p>
                </div>
                {language_test_taken===1?<div className='col-md-4'>
                <label className="blue-color fw-bold">Language test type</label>
                  <p>{language_test_type===1?"IELTS":language_test_type===2?"OET":""}</p>
                </div>:""}
                {language_test_taken===1?<div className='col-md-4'>
                <label className="blue-color fw-bold">Test Date</label> <br/>
                 {moment(language_test_date).format('Do MMMM YYYY')}
                </div>:""}
                {language_test_taken===1 && language_test_type===1?<div className='row  mt-2'>
                <div className='col-md-2'>
                   <label className="blue-color fw-bold">TRF Number</label>
                   <p>{ielts_trf_number}</p>
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold"> Listening score</label>
                <p>{ielts_listening_score}</p>
                </div>
              <div className='col-md-2'>
                <label className="blue-color fw-bold"> Reading Score</label>
               <p>{ielts_reading_score}</p>
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold"> Writing Score</label>
                <p>{ielts_writing_score}</p>
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold"> Speaking Score</label>
                  <p>{ielts_speaking_score}</p>
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold"> Overall Number</label>
                  <p>{ielts_overall_score}</p>
                </div>


            </div>:language_test_taken===1 && language_test_type===2?<div className='row mt-5'>
                <div className='col-md-2'>
                   <label className="blue-color fw-bold">Candidate Number</label>
                   <p>{oet_candidate_number}</p>
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold">Listening score</label>
                  <p>{oet_listening_score}</p>
                </div>
              <div className='col-md-2'>
                <label className="blue-color fw-bold">Reading Score</label>
                <p>{oet_reading_score}</p>
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold">Writing Score</label>
                <p>{oet_writing_score}</p>
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold">Speaking Score</label>
                <p>{oet_speaking_score}</p>
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold">Overall Score</label>
                <p>{oet_overall_score}</p>
                </div>



            </div>:""}

            </div>
      </div>
          </div>
        {/* </div> */}

        {/* GMC Account & Internship Details */}
        {/* <div className="card"> */}
         
          <div id="gmcinternship" 
          // className={`collapse ${isGMCInternshipOpen ? 'show' : ''}` }
        //   className="collapse"
        //    aria-labelledby="headingFive" data-parent="#accordion"
           >
          <div className="card-body">

 
          <div className="row">
              <div className="col-md-6">
             {/* <h3 className="blue-color fw-bold my-3">GMC And Internship Details</h3> */}
              </div>
              <div className="col-md-6">
                {/* <Link to={`/user_gmc_and_internship_edit/${id}/`}><img className="rounded"  style={{border:"2px solid #233974"}} width="20px" height="20px"  src={pencil} alt="pencil"/></Link> */}
              </div>
            </div>

      <div className='row'>

                {gmc_reference_number_exist===1?<div className='col-md-12'>
                    <label className="blue-color fw-bold m-0">GMC Reference Number</label>
                    <p className="mt-2">{gmc_reference_number}</p>
                </div>:<div className='col-md-6'></div>}
            </div>

         

      </div>
          </div>

          <hr/>
        {/* </div> */}

        {/* Employment And Speciality */}
        {/* <div className="card"> */}
          
          <div id="employment_and_speciality">
          <div className="card-body">
          <div className="row">
              <div className="col-md-6">
              {emp_completion_status===1? <h4 className='blue-color fw-bold text-left my-4'>Employment Details</h4>:""}
              </div>
              <div className="col-md-6">
                {/* <Link to={`/user_employment_and_speciality_edit/${id}/`}><img className="rounded"  style={{border:"2px solid #233974"}} width="20px" height="20px"  src={pencil} alt="pencil"/></Link> */}
              </div>
            </div>
      
            {pmq_status===3 ?<div className='row'>

                <div className='col-md-3'>
                    <label className="blue-color fw-bold" >Employed</label>
                   
                    <p className="mt-2">{emp_completion_status===1?"Yes":emp_completion_status===2?"No":""}</p>
                </div>
                <div className='col-md-3'>
                   {emp_completion_status===1? <label className="blue-color fw-bold">Name Of Hospital</label>:""}
                    {emp_completion_status===1?<p className="mt-2">{emp_hospital_name}</p>:""}
                </div>
                <div className='col-md-3'>
                {emp_completion_status===1?<label className="blue-color fw-bold">City</label>:""}
                {emp_completion_status===1?<p className="mt-2">{emp_city.toString()==="0"?"Not Mentioned":emp_city}</p>:""}
                </div>
                <div className='col-md-3'>
                   {emp_completion_status===1? <label className="blue-color fw-bold">State</label>:""}
                   {emp_completion_status===1? <p className="mt-2">{emp_state}</p>:""}
                </div>
<hr/>
            </div>:""}
      </div>
          </div>
        {/* </div> */}

        {/* Personal Notes */}
     
        
          <div id="personal_note_others" >
          <div className="card-body">
          <div className="row">
              <div className="col-md-6">
              {/* <h4 className='blue-color fw-bold text-left my-4'>Personal Notes And Welcome Call Details</h4> */}
              </div>
              <div className="col-md-6">
                {/* <Link to={`/personal_notes/${id}/`}><img className="rounded"  style={{border:"2px solid #233974"}} width="20px" height="20px"  src={pencil} alt="pencil"/></Link> */}
              </div>
            </div>
            {passport_exist===1?<div className='row'>
                <div className='col-md-12'>
                {/* <Link to={`/document_add/${id}`} className='btn btn-outline-primary my-5 float-right'>Add Additional Documents</Link> */}
                </div>
                <h4 className='blue-color fw-bold text-left my-4'>Documents</h4>
                <div className='col-md-6'>
                    <label className="blue-color fw-bold">Passport Front</label>
                    {/* {passport_photo_input===true?<input type="file" name="passport_photo" id="passport_photo"  required />:<label className='bordered p-3 w-100' onClick={(e)=>setpassphotInput(true)}>Click here to upload passport document</label>}  */}
                </div>
                <div className='col-md-6 text-left'>
                {passport_photo && typeof passport_photo === 'string' && passport_photo.endsWith('.pdf') ? 
  <a className='text-left' href={passport_photo} target='_blank'>View Photo</a> : 
  <img className="rounded"  style={{border:"2px solid #233974"}} src={passport_photo || 'defaultImagePath'} alt="Passport" width={200} height={200} />
}
                {/* {passport_photo.endsWith('.pdf')?<a className='text-left' href={passport_photo} target='_blank'>View Passport Front</a>:<img className="rounded"  style={{border:"2px solid #233974"}} src={passport_photo} alt="Passport" width={200} height={200} />} */}
                </div>
            </div>:""}
            {passport_exist===1?<div className='row mt-3'>
                <div className='col-md-6'>
                    <label className="blue-color fw-bold">Passport Back</label>
                    {/* {client_photo_input===true?<input type="file" name="client_photo"  id="client_photo" required />:<label className='bordered p-3 w-100' onClick={(e)=>setClientPhotoInput(true)}>Click here to upload Your Photo</label>}  */}
                </div>
                <div className='col-md-6 text-left'>
                {passport_photo_back && typeof passport_photo_back === 'string' && passport_photo_back.endsWith('.pdf') ? 
              <a className='text-left' href={passport_photo_back} target='_blank'>View Photo</a> : 
         <img className="rounded"  style={{border:"2px solid #233974"}} src={passport_photo_back || 'defaultImagePath'} alt="Passport" width={200} height={200} />
   }
                {/* {passport_photo_back.endsWith('.pdf')?<a className='text-left' href={passport_photo_back} target='_blank'>View Passport Back</a>: <img className="rounded"  style={{border:"2px solid #233974"}} src={passport_photo_back} alt="Passport" width={200} height={200} />}<br /><br /> */}
                </div>
            </div>:""}
            <div className='row mt-3'>
                <div className='col-md-6'>
                    <label className="blue-color fw-bold">Client Photograph</label>
                    {/* {client_photo_input===true?<input type="file" name="client_photo"  id="client_photo" required />:<label className='bordered p-3 w-100' onClick={(e)=>setClientPhotoInput(true)}>Click here to upload Your Photo</label>}  */}
                </div>
                <div className='col-md-6 text-left'>
                {client_photo.endsWith('.pdf')?<><iframe src={client_photo} width={"200px"} height={"200px"}  className='rounded' style={{border:"2px solid #233974"}}/><br/><a className='text-left' href={client_photo} target='_blank'>View Photo</a></>: <img className="rounded"  style={{border:"2px solid #233974"}} src={client_photo} alt="Passport" width={200} height={200} />}<br /><br />
                {/* <img className="rounded"  style={{border:"2px solid #233974"}} src={client_photo} alt="" width={200} height={200}/> */}
                </div>
            </div>
            <div className='row mt-3'>
               {pmq_status===2||pmq_status===1 ?<div className='col-md-6'>
                    <label className="blue-color fw-bold"> Provisional Degree Certificate </label><br/><br/>
                    {/* {provisional_degree_certificate_input===true?<input type="file"  name="provisional_degree_certificate"  id="provisional_degree_certificate" required />:<label className='bordered p-3 w-100' onClick={(e)=>setProvisionalDegreeCertificateInput(true)}>Click here to upload provisonal degree certificate</label>}  */}
                </div>:pmq_status===3?<div className='col-md-6'>
                    <label className="blue-color fw-bold"> Final Degree Certificate</label><br/><br/>
                    {/* {final_degree_certificate_input===true?<input type="file" name="final_degree_certificate" id="final_degree_certificate"   required />:<label className='bordered p-3 w-100' onClick={(e)=>setFinalDegreeCertificateInput(true)}>Click here to upload final degree certificate</label>}  */}
                </div>:""}
                <div className='col-md-6 text-left'>
                {pmq_status === 2 || pmq_status === 1 ? (
    provisional_degree_certificate.endsWith('.pdf') ? (
      <><iframe src={provisional_degree_certificate} width={"200px"} height={"200px"}  className='rounded' style={{border:"2px solid #233974"}}/><br/>  <a  className="text-left"  href={provisional_degree_certificate} target="_blank">View Provisional Degree Certificate</a></>
    ) : (
        <img className="rounded"  style={{border:"2px solid #233974"}} src={provisional_degree_certificate} alt="" width={200} height={200}/>
    )
) : pmq_status === 3 ? (
    final_degree_certificate.endsWith('.pdf') ? (
        <><iframe src={final_degree_certificate} width={"200px"} height={"200px"}  className='rounded' style={{border:"2px solid #233974"}}/><br/><a className="text-left" href={final_degree_certificate} target="_blank">View Final Degree Certificate</a></>
    ) : (
        <img className="rounded"  style={{border:"2px solid #233974"}} src={final_degree_certificate} alt="" width={200} height={200}/>
    )
) : null}

                </div>
            </div>
            <div className='row mt-3'>
               {pmq_status===2||pmq_status===1 ?<div className='col-md-6'>
                    <label className="blue-color fw-bold"> Provisional Registration Certificate </label><br/><br/>
                    {/* {provisional_registration_certificate_input===true?<input type="file" name="provisional_degree_certificate"   id="provisional_degree_certificate" required />:<label className='bordered p-3 w-100' onClick={(e)=>setProvisionalRegistrationCertificateInput(true)}>Click here to upload provisonal registration certificate</label>}  */}
                </div>:pmq_status===3?<div className='col-md-6'>
                    <label className="blue-color fw-bold"> Final Registration Certificate</label><br/><br/>
                    {/* {final_registration_certificate_input===true?<input type="file" name="final_registration_certificate"  id="final_registration_certificate" required />:<label className='bordered p-3 w-100' onClick={(e)=>setFinalRegistrationCertificateInput(true)}>Click here to upload final registration certificate</label>}  */}
                </div>:""}
                <div className='col-md-6'>
                {pmq_status === 2 || pmq_status === 1 ? (
    provisional_registration_certificate ? (
        provisional_registration_certificate.endsWith('.pdf') ? (
            <><iframe src={provisional_registration_certificate} width={"200px"} height={"200px"}  className='rounded' style={{border:"2px solid #233974"}}/><br/><a  href={provisional_registration_certificate} className="text-left" target="_blank">View Provisional Registration Certificate</a></>
        ) : (
            <img className="rounded"  style={{border:"2px solid #233974"}} src={provisional_registration_certificate} alt="" width={200} height={200}/>
        )
    ) : (
        <p>No provisional registration certificate available</p>
    )
) : pmq_status === 3 ? (
    final_registration_certificate ? (
        final_registration_certificate.endsWith('.pdf') ? (
           <> <iframe src={final_registration_certificate} width={"200px"} height={"200px"}  className='rounded' style={{border:"2px solid #233974"}}/><br/><a href={final_registration_certificate} className="text-left" target="_blank">View Final Registration Certificate</a></>
        ) : (
            <img className="rounded"  style={{border:"2px solid #233974"}} src={final_registration_certificate} alt="" width={200} height={200}/>
        )
    ) : (
        <p>No final registration certificate available</p>
    )
) : null}

                </div>
            </div>
            
            {pmq_status===3?<div className='row my-3'>
                <div className='col-md-6'>
                    <label className="blue-color fw-bold">Internship Completion Certificate</label><br/><br/>
                    {/* {internship_completion_certificate_input===true?<input type="file" name="internship_completion_certificate"  id="internship_completion_certificate" required />:<label className='bordered p-3 w-100' onClick={(e)=>setInternshipCompletionCertificateInput(true)}>Click here to upload internship completion certificate</label>}  */}
                </div>
                <div className='col-md-6'>
               {internship_completion_certificate.endsWith('.pdf')?<><iframe src={internship_completion_certificate} width={"200px"} height={"200px"}  className='rounded' style={{border:"2px solid #233974"}}/><br/><a href={internship_completion_certificate} className="text-left" target="_blank">View Internship Certificate</a></>:<img className="rounded"  style={{border:"2px solid #233974"}} src={internship_completion_certificate} alt="" width={200} height={200}/>}
                </div>
            </div>:""}
            <hr className="mt-5"/>
          <div className='row mt-4'>
               <div className='col-md-6'>
                <label className="blue-color fw-bold">Personal Note</label>
               <p className="border rounded p-2"  style={{minHeight:"100px"}}>{personal_note}</p>
               </div>
            </div>
            
            <div className='row my-5'>
               <div className='col-md-4'>
                <label className="blue-color fw-bold">Welcome Call Date</label>
               <p>{moment(welcome_call_date).format('Do MMMM YYYY')}</p>
               </div>
               <div className='col-md-4'>
               <label className="blue-color fw-bold">Welcome Call Time</label>
               <p>{welcome_call_time===1?"10am to 11am":welcome_call_time===2?"11am to 12am":welcome_call_time===3?"12pm to 1pm":welcome_call_time===4?"3pm to 4pm":welcome_call_time===5?"4pm to 5pm":""}</p>

               </div>
               </div>
               <hr/>
      </div>
          </div>
        </div>
      </div>
    
  );

}

export default UserDeclare