import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'antd/dist/reset.css';
import { vendortype } from '../../@types/vendortype';
import server from '../Utils';
import Loader from './Loader';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GridApi, ColumnApi, ColDef } from 'ag-grid-community';

const Vendors = () => {
  const [vendors, setVendors] = useState<Array<vendortype>>([]);
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);

  let projectid: string = JSON.parse(localStorage.getItem("project_id") || '{}');
  let id = +projectid;

  useEffect(() => {
    setLoading(true);
    server.get(`/vendors/${id}`)
      .then((response) => {
        setVendors(response.data.vendors);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [id]);

  const deleteVendor = (id: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        server.delete(`/vendor_delete/${id}`)
          .then((response) => {
            setVendors(response.data.vendors);
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          });
      }
    });
  };

  const onGridReady = (params: { api: GridApi, columnApi: ColumnApi }) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const resetFilters = () => {
    if (gridApi) {
      gridApi.setFilterModel(null);
    }
  };

  const columns: ColDef[] = [
    {
      headerName: 'SL No',
      valueGetter: "node.rowIndex + 1",
      sortable: true,
      // filter: 'agNumberColumnFilter',
      // floatingFilter: true,
      flex: 1,
      filter:false,
    },
    {
      headerName: 'Vendor Name',
      field: 'vendor_name',
      cellRenderer: (params: { data: vendortype }) => (
        <Link className='text-left' to={`/vendor_edit/${params.data.id}`}>{params.data.vendor_name}</Link>
      ),
      sortable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      flex: 1,
    },
    {
      headerName: 'Product Name',
      field: 'product_name',
      sortable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      flex: 1,
    },
    {
      headerName: 'Vendor Email',
      field: 'vendor_email',
      sortable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      flex: 1,
    },
    {
      headerName: 'Vendor Phone',
      field: 'contact_number',
      sortable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      flex: 1,
    },
    {
      headerName: 'Actions',
      cellRenderer: (params: { data: vendortype }) => (
        <div>
          <i onClick={() => deleteVendor(params.data.id)} className="fa fa-trash text-danger" style={{ cursor: 'pointer' }}></i>
        </div>
      ),
      flex: 1,
      sortable: false,
      filter: false,
    }
  ];

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <div className='row'>
        <div className="col-md-6"></div>
        <div className="col-md-6">
          <Link to="/add_vendor" className='btn btn-outline-primary float-right my-3'>Add Vendor</Link>
          <button onClick={resetFilters} className='btn btn-outline-secondary float-right my-3 mr-2'>Reset Filters</button>
        </div>
      </div>
      <div className="row px-2">
        <div className='ag-theme-alpine' style={{ height: 600, width: '100%' }}>
          <AgGridReact
            onGridReady={onGridReady}
            columnDefs={columns}
            rowData={vendors}
            defaultColDef={{
              sortable: true,
              filter: true,
              floatingFilter: true,
              flex: 1,
            }}
            pagination={true}
            paginationPageSize={10}
          />
        </div>
      </div>
    </div>
  );
}

export default Vendors;
