import React, { useEffect, useState } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import moment from 'moment';
import { Link } from 'react-router-dom';
import server from '../Utils';
import Loader from './Loader';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GridApi, ColumnApi, ColDef } from 'ag-grid-community';
import { tier4visatype } from '../../@types/tier4visatype';

const Tier4VisaList: React.FC = () => {
  const [tier4visa, setTier4Visa] = useState<Array<tier4visatype>>([]);
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);

  let projectid: string = JSON.parse(localStorage.getItem('project_id') || '{}');
  let id = +projectid;

  useEffect(() => {
    setLoading(true);
    server
      .get(`/get_tier4visa/${id}`)
      .then((response) => {
        setTier4Visa(response.data.tier4visa);
      })
      .catch((error) => {
        console.error('Error fetching tier 4 visa list:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const onGridReady = (params: { api: GridApi, columnApi: ColumnApi }) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const getColumnDefs = (): ColDef[] => [
    {
      headerName: 'SL No',
      valueGetter: "node.rowIndex + 1",
      sortable: false,
      filter: false,
    },
    {
      headerName: 'First Name',
      // valueGetter: (params) => params.data.first_name + ' ' + params.data.last_name,
      field:"first_name",
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Last Name',
      // valueGetter: (params) => params.data.first_name + ' ' + params.data.last_name,
      field:"last_name",
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Application Status',
      valueGetter: (params) =>
        params.data.applicationStatus === 1 ? 'Yet To Start' :
        params.data.applicationStatus === 2 ? 'In Progress' :
        params.data.applicationStatus === 3 ? 'Submitted' : '',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Visa Appointment Date',
      field: 'appointmentDate',
      sortable: true,
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => moment(params.value).format('Do MMMM YYYY'),
    },
    {
      headerName: 'Visa End Date',
      field: 'visa_end_date',
      sortable: true,
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => moment(params.value).format('Do MMMM YYYY'),
    },
    {
      headerName: 'Visa Vendor Name',
      field: 'vendor_name',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Actions',
      filter:false,
      cellRenderer: (params: { data: { user_id: number; id: number } }) => (
        <Link
          onClick={() => localStorage.setItem('user_id', params.data.user_id.toString())}
          className='text-dark text-center'
          to={`/edit_tier4_visa/${params.data.id}`}
        >
          <i className='fa fa-edit text-success'></i>
        </Link>
      ),
      sortable: true,
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='container-fluid'>
      <div className='d-flex'>
        <SideBar />
        <div className='headerbar'>
          <Header />
          <h4 className='text-danger text-center'>Tier 4 Visa List</h4>
          <hr className='bg-danger px-1' />
          <div className='row mt-5 px-2'>
            <div className='col-md-12'>
              <Link
                to={"/add_multiple_tier4_visa"}
                className='btn btn-info float-right mr-2 text-white mb-3'
                role="button"
              >
                +
              </Link>
            </div>
            <div className='col-md-12'>
              <div className='ag-theme-alpine' style={{ height: '500px' }}>
                <AgGridReact
                  onGridReady={onGridReady}
                  columnDefs={getColumnDefs()}
                  rowData={tier4visa}
                  defaultColDef={defaultColDef}
                  pagination={true}
                  paginationPageSize={50}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tier4VisaList;
