import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';

import {Link,useParams} from 'react-router-dom';

import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { message } from 'antd';

import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import $ from 'jquery'; 

import validator from 'validator';
import { off } from 'process';
import { type } from 'os';
import { vendortype } from '../../@types/vendortype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import { ServiceData } from '../../@types/servicetype';
import { count } from 'console';
import {service_categorytypedata} from '../../@types/service_categorytypedata';
import server from '../Utils';
import { countrytype } from '../../@types/contrytype';
import { statetype } from '../../@types/statetype';
import { citytype } from '../../@types/citytype';
import UserInfo from '../UserInfo';
import Loader from './Loader';
import { multipleusertype } from '../../@types/multipleusertype';
import Multiselect from 'multiselect-react-dropdown';

const AddMultipleTestBookingDetails = () => {
        
   
   
    const [project_id,setProjectId] = useState<number>(0);
    
    const [assesment_date,setAssesmentDate] =useState<string>('');
    const [service_category_id,setServiceCategoryId]=useState<number>(0);
    const [service_categories,setServiceCategories]=useState<Array<ServiceCategoryType>>([])
    const [services,setServices]=useState<Array<ServiceData>>([])
    const [service_id,setServiceId]=useState<number>(0)
   
    const [test_date,setTestDate]=useState<string>('');
    const [booking_date,setBookingDate]=useState('');
    const [venue_name,setVenueName]=useState<string>('');
    const [test_status,setTestStatus]=useState<number>(1);
    const [note,setNote]=useState<string>('')
    const [service_category_type,setCategoryTypeId]=useState<number>(0)
    const [result_date,setResultDate]=useState<string>('');
    const [result_status,setResultTestStatus]=useState<number>(0);
    const[language_result_date,setLanguageResultDate]=useState<string>('')
    const [plab1_result_date,setPlab1ResultDate]=useState<string>('');
    const [plab2_result_date,setPlab2ResultDate]=useState<string>('');
    const [countries,setCountries]=useState<Array<countrytype>>([]);
    const [country,setCountry]=useState<number>(0)
    const [states,setStates]=useState<Array<statetype>>([]);
    const [currentstate,setCurrentState]=useState<number>(0);
    const [cities,setCities]=useState<Array<citytype>>([]);
    const [city,setCity]=useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [users,setUsers]=useState<Array<multipleusertype>>([]);
    const [user_ids,setUserIds]=useState<Array<number>>([])
    // var options: SelectProps['options'] = [];
    const [selectedValue,setSelectedValue]=useState<Array<multipleusertype>>([])
    const [error,setError]=useState(false)
    const [user_id,setUserId]=useState<number>()
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [product_id,setProductId]=useState<number>(0);
    const [count,setCount]=useState<number>(0);
    // const {id}= useParams();
    // const userid=id;
    const [service_category_types,setServiceCategorytypes]=useState<Array<service_categorytypedata>>([])
    useEffect(() => {
      let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
      let id = +projectid;
      server.get(`/get_product/${id}`)
      .then((response)=>{
          
          setProducts(response.data.product)
          
      })
 
      server.get('get_operationuser')
      .then((response)=>{
        setUsers(response.data.users)
         
      })

      setProjectId(id);

      server.get(`/service_types_basedon_categories/3`)
      .then((response)=>{
         
          if(response.data.service_category_types.length>0){
              setServiceCategorytypes(response.data.service_category_types)
          }else{
              setServiceCategorytypes([]);
          }
      })
      
      
      // server.get(`/service_categories_for_test_booking`)
      // .then((response)=>{
      //   setServiceCategories(response.data.services_categories)
  
      // })
      server.get(`/get_countries`)
      .then((response)=>{
        setCountries(response.data.countries)
      })
     
    }, []);
    const getCurrentDate = () => {
      const today = new Date();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const year = today.getFullYear();
  
      const formattedMonth = month < 10 ? `0${month}` : `${month}`;
      const formattedDay = day < 10 ? `0${day}` : `${day}`;
  
      return `${year}-${formattedMonth}-${formattedDay}`;
    };
    const getServices =(id:number)=>{
      
        setCategoryTypeId(id)
       
        server.get(`/service_on_category_type/${id}`)
        .then((response)=>{
          console.log(response.data.services)
            setServices(response.data.services)
        })
       }
    
       const addTraining = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
      
        let date1 = new Date(test_date).getTime();
        let date2 = new Date(booking_date).getTime();
        let result_date = new Date(language_result_date).getTime();
        let plab1resultdate = new Date(plab1_result_date).getTime();
        let plab2resultdate = new Date(plab2_result_date).getTime();
      
        if (product_id === 0) {
          toast('Select the product');
        } else if (selectedValue.length === 0) {
          toast('Select at least one doctor');
        } else if (service_category_type === 0) {
          toast('Select category type');
        } else if (service_id === 0) {
          toast('Select Service');
        } else if (test_status === 0) {
          toast('Select Test Status');
        } else if (service_category_type === 1 && test_status === 2 && result_status === 2 && (language_result_date === "" || language_result_date === null)) {
          toast('Enter Language Result Date');
        } else if (service_category_type === 1 && test_status === 2 && result_status === 2 && result_date < new Date(test_date).getTime()) {
          toast('Enter result date greater than test date');
        } else if (service_category_type === 2 && test_status === 2 && result_status === 2 && (plab1_result_date === null)) {
          toast('Enter Plab1 Result Date');
        } else if (service_category_type === 2 && test_status === 2 && result_status === 2 && plab1resultdate < new Date(test_date).getTime()) {
          toast('Enter Plab1 result date greater than test date');
        } else if (service_category_type === 3 && test_status === 2 && result_status === 2 && validator.isEmpty(plab2_result_date)) {
          toast('Enter Plab2 Result Date');
        } else if (service_category_type === 3 && test_status === 2 && result_status === 2 && plab2resultdate < new Date(test_date).getTime()) {
          toast('Enter Plab2 result date greater than test date');
        } else if (date2 > date1) {
          toast('Booking date should be before test date');
        } else {
          var form = document.getElementById('addtrainingdetails') as HTMLFormElement;
          setError(false);
      
          let successCount = 0;
          let hasError = false;
      
          const promises = selectedValue.map((element) => {
            var formData = new FormData(form);
            return server.post(`/add_test_booking/${element.id}`, formData)
              .then((response) => {
                if (response.data.code === 200) {
                  successCount += 1;
                  // toast("Data added Successfully for " + element.user);
                } else {
                  toast("Couldn't be added for" + element.user + "because "+response.data.message);
                  hasError = true;
                }
              });
          });
      
          Promise.all(promises).then(() => {
            if (!hasError && successCount === selectedValue.length) {
              toast('Test booking added for all doctors');
              setTimeout(function () { window.location.href = "/bookinglist"; }, 2000);
            } else {
              toast('Some bookings could not be added. Please try again.');
            }
          });
        }
      };
      
const setStatesArray=(id:number)=>{
  setCountry(id)
  
  server.get(`/get_states/${id}`)
  .then((response)=>{
     setStates(response.data.states)
  })
}
const setCityArray=(id:number)=>{
  setCurrentState(id)
  server.get(`/get_cities/${id}`)
  .then((response)=>{
     setCities(response.data.cities)
  })
}

const setallUsers=(id:number)=>{
  setProductId(id)
  setUserId(0)
  setError(true);
  setCount(count+1)
  setSelectedValue([])
server.get(`get_operationuser/${id}`)
      .then((response)=>{
        setUsers(response.data.users)
        
      })

}
const onRemove = (selectedList: multipleusertype[], removedItem: multipleusertype) => {
  setSelectedValue(selectedList);
};

if (loading) {
  return <Loader />;
}
const onSelect=(selectedList:[], selectedItem:any) => {
    setSelectedValue(selectedList)
    
}

  return (
    <div className='container-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}

{/* <UserInfo/> */}
    <h4 className='text-danger text-center'>Add Multiple Test Booking</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5 jumbotron mx-3'>
   
  {project_id!==0?<div className='col-md-6'>
            <label >Products</label>
                     <select className='form-control' name='product_id' id="product_id" value={product_id} onChange={(e)=>setallUsers(parseInt(e.target.value))} required>
                        <option value={0}>Select Product</option>
                        {products.map((product)=>(
                            <option value={product.id}>{product.product_name}</option>))}
            </select>
            </div>:""}
      <div className='col-md-12'>
      {product_id !== 0 && (
        users.length !== 0 ? (
          <div>
          <label>Users</label>
          <Multiselect
            options={users}
            placeholder="Select doctors here"
            selectedValues={selectedValue}
            onSelect={onSelect}
            onRemove={onRemove}
            displayValue="user"
          />
          </div>
        ) : (
          <p className="text-danger">
            Users List can't be displayed because no user filled their details yet
          </p>
        )
      )}    </div>    
    <div className='col-md-12 px-5'>
    {/* {users.length!==0?<Multiselect options={users} placeholder='Select doctors here' selectedValues={selectedValue} onSelect={onSelect} displayValue='user'/>:<p className='text-danger'>Users List cant be displayed because No user filled their details yet</p>} */}
    
    <br/>
        {product_id!==0?<form id="addtrainingdetails" onSubmit={addTraining}  encType='multipart/form-data' className='w-100'>
          <div className='row'>
          <input type="hidden" name={"service_category_id"} id={"service_category_id"} value="3"/>
          <input type={'hidden'} name={'project_id'} value={project_id}/>
            <input type={'hidden'} name={'product_id'} value={product_id}/>
          <div className='col-md-3'>
              <label>Test Category</label>
              <select id="service_category_type" className='form-control' name="service_category_type" value={service_category_type} onChange={(e)=>getServices(parseInt(e.target.value))}>
                <option value="0">Select Test Category</option>
                {service_category_types.map((service_category_type,index)=>(
                  <option value={service_category_type.id}>{service_category_type.service_category_type}</option>
                ))}
              </select>
            </div>
            {service_category_type!==0?<div className='col-md-3'>
              <label htmlFor="">Test</label>
               <select className='form-control' id="service_id" name="service_id" value={service_id} onChange={(e)=>setServiceId(parseInt(e.target.value))}> 
             
                    <option value="0">Select Test </option>        
                         {services.map((service,index)=>(
                            <option value={service.id}>{service.service_name}</option>
                         ))}   
                </select>             
                            
                </div>
                
                :""}  
                {service_category_type!==0? <div className='col-md-3'>
                <label htmlFor="">Booking Date</label>
                <input className="form-control" type="date"
                //  max={getCurrentDate()}
                  name={"booking_date"}  id={'booking_date'} value={booking_date} onChange={(e)=>setBookingDate(e.target.value)}   required/>
              </div> :""}
                 {service_category_type!==0?<div className='col-md-3'>
                <label htmlFor="">Test Date</label>
                <input className="form-control" type="date" name={"test_date"}  id={'test_date'} value={test_date} onChange={(e)=>setTestDate(e.target.value)}   required/>
              </div>:""}  
             

          </div>
         {service_category_type!==0?<div className='row mt-5'>
         
            
            <div className='col-md-3'>
              <label>Country</label>
              {/* <input className="form-control"  type="text" name="country" id="country" value={country} onChange={(e)=>setCountry(e.target.value)}  required /> */}
              <select className='form-control'  name="country" id="country" value={country} onChange={(e)=>setStatesArray(parseInt(e.target.value))}  required>
                        <option value={0}>Select Country</option>
                        {countries.map((country,index)=>(
                          <option value={country.id}>{country.name}</option>
                        ))}
              </select>
            </div>
            <div className='col-md-3'>
            <label>State</label>
              {/* <input className="form-control" type="text" name="state" id="state" value={currentstate} onChange={(e)=>setCurrentState(e.target.value)}   required/> */}
              <select className='form-control' name="state" id="state" value={currentstate} onChange={(e)=>setCityArray(parseInt(e.target.value))}   required>
              <option value={0}>Select State</option>
                        {states.map((state,index)=>(
                          <option value={state.id}>{state.name}</option>
                        ))}

              </select>
            </div>
            <div className='col-md-3'>
            <label>City</label>
              {/* <input className="form-control" type="city" name="city" id="city" value={city} onChange={(e)=>setCity(e.target.value)}  required /> */}
              <select className="form-control" name="city" id="city" value={city} onChange={(e)=>setCity(parseInt(e.target.value))}  >
              <option value={0}>Select City</option>
                        {cities.map((city,index)=>(
                          <option value={city.id}>{city.name}</option>
                        ))}
              </select>
            </div>
            <div className='col-md-3'>
                <label>Name of the Venue</label>
                <input type="text" className='form-control' name="venue_name" id="venue_name" onChange={(e)=>setVenueName(e.target.value)} placeholder="" />
            </div>
          </div>
          
          :""}

          {service_category_type!==0?<div className='row mt-5'>
           
          <div className='col-md-3'>
              <label>Test Status</label>
              <select className='form-control' name="test_status" id="test_status" value={test_status} onChange={(e)=>setTestStatus(parseInt(e.target.value))} >
               
              
                <option value={1}>Booked</option>
                <option value={2}>Attended</option>
                <option value={3}>Missed</option>
                <option value={4}>Cancelled by Authority</option>
                <option value={5}>Cancelled by Client</option>
                <option value={6}>Rescheduled</option>
              </select>
            </div>
           
            {test_status===2? <div className='col-md-3'>
              <label>Result Status</label>
               <select className='form-control' name="result_status" id="result_status" value={result_status} onChange={(e)=>setResultTestStatus(parseInt(e.target.value))}>
                          <option value={1} >Waiting For Results</option>
                          <option value={2} >Pass</option>
                          <option value={3} >Fail</option>
               </select>
             </div>:""}   
             {test_status===2 && service_category_type===1 && result_status===2?<div className='col-md-3'>
              <label>Language Result Date</label>
               <input className='form-control' type="date" name="language_result_date" id="language_result_date" value={language_result_date} onChange={(e)=>setLanguageResultDate(e.target.value)}    />       
             </div>:""}
             {test_status===2 && service_category_type===2 && result_status===2?<div className='col-md-3'>
              <label>PlAB1 Result Date</label>
               <input className='form-control' type="date" name="plab1_result_date" id="plab1_result_date" value={plab1_result_date} onChange={(e)=>setPlab1ResultDate(e.target.value)}    />       
             </div>:""}
             {test_status===2 && service_category_type===3 && result_status===2?<div className='col-md-3'>
              <label>PlAB2 Result Date</label>
               <input className='form-control' type="date" name="plab2_result_date" id="result_date" value={plab2_result_date} onChange={(e)=>setPlab2ResultDate(e.target.value)}    />       
             </div>:""}          
            <div className='col-md-12 my-3'>
                <label>Note</label>
                <textarea className='form-control' name="note" id="note" value={note} onChange={(e)=>setNote(e.target.value)}></textarea>
            </div>
          </div>:""}
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-info text-white mt-2'>Add Details</button></div>
        </form>:""}
   
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default AddMultipleTestBookingDetails;