import React,{useState,useEffect} from 'react'

import './index.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link,useParams} from 'react-router-dom';
import axios from 'axios';
import UserSideBar from '../../pages/UserSideBar/UserSideBar';
import UserHeader from '../../pages/UserHeader/UserHeader';
import validator from 'validator';
import Swal from 'sweetalert2';
import AddDocuments from './AddDocuments';
import server from '../Utils';
import { additionaldocumentstype } from '../../@types/additionaldocumenttype';
import Loader from './Loader';

interface props{
  user_agreed:number,
  pmq_status:number,
  personal_note: string,
}

const Documents:React.FC<props> = ({user_agreed,pmq_status,personal_note,}) => {
  var loggedIn=localStorage.getItem('loggedIn');
 
  const [passport_photo_input,setpassphotInput]=useState<boolean>(false);
  const [client_photo_input,setClientPhotoInput]=useState<boolean>(false);
  const [provisional_degree_certificate_input,setProvisionalDegreeCertificateInput]=useState<boolean>(false);
  const [provisional_registration_certificate_input,setProvisionalRegistrationCertificateInput]=useState<boolean>(false);
  const [final_degree_certificate_input,setFinalDegreeCertificateInput]=useState<boolean>(false);
  const [final_registration_certificate_input,setFinalRegistrationCertificateInput]=useState<boolean>(false);
  const [internship_completion_certificate_input,setInternshipCompletionCertificateInput]=useState<boolean>(false);
  const [passport_photo,setPassportPhoto]=useState<string>('');
  const [client_photo,setClientPhoto]=useState<string>('');
  const [provisional_degree_certificate,setProvisionalDegreeCertificate]=useState<string>('');
  const [final_degree_certificate,setFinalDegreeCertificate]=useState<string>('');
  const [provisional_registration_certificate,setProvisionalRegistrationCertificate]=useState<string>('');
  const [final_registration_certificate,setFinalRegistrationCertificate]=useState<string>('');
  const [internship_completion_certificate,setInternshipCompletionCertificate]=useState<string>('');
 
  const [welcome_call_date,setWelcomeCallDate]=useState<string>('');
  const [welcome_call_time,setWelcomeCallTime]=useState<number>(0);
  const [certificate_names,setcertificateNames]=useState<string>('');
  const [certificate_desc,setCertificateDesc]=useState<string>('');
  const [certificate_files,setCertificateFiles]=useState<string>('');
  const [file_count,setFileCount]=useState<number>(0);
  const [additional_documents,setAdditonalDocuments]=useState<Array<additionaldocumentstype>>([]);
  const[languagetestcertificate,setLanguageTestCertificate]=useState<string>(''); 
  const[plab1certificate,setPlab1Certificate]=useState<string>(''); 
  const[plab2certificate,setPlab2Certificate]=useState<string>(''); 
  const[good_standing_certificate,setGoodStandingCertificate]=useState<string>(''); 
  const {id}=useParams();
  const [loading, setLoading] = useState(false);
  // const [user_agreed,setUserAgreed]=useState<number>(0);

  useEffect(() => {
    // server.get(`/operationuser/${id}`)
    // .then((response)=>{
     
    //    if(response.data.user.user_agreement_flag===1){
    //      setUserAgreed(1)
    //    }else{
    //      setUserAgreed(0)
    //    }
    // })
    
    // server.get(`/operation_user_view/${id}`)
    // .then((response)=>{
    //      setPmQStatus(response.data.user.pmq_status);
    //      setPersnolNote(response.data.user.personal_note);
         

    // })

    server.get(`/get_documents/${id}`)
  .then((response)=>{
    
    if(response.data.code===200){
    
    
    setAdditonalDocuments(response.data.additional_documents)

   
    setLoading(false)
 }else{
    setLoading(false)
 }
})
    
  }, []);
  

  let certificate_files_array = certificate_files && certificate_files !== "" ? certificate_files.split(',') : [];
  let certificate_desc_array = certificate_desc && certificate_desc !== "" ? certificate_desc.split(',') : [];
  let certificate_names_array = certificate_names && certificate_names !== "" ? certificate_names.split(',') : [];
  
// var certificates=[];

// for(let i=0;i<=additional_documents.length;i++){
//     certificates.push(
//         <div className='row px-2 mt-3' id={"sub"+i}>
            
//             <div className='col-md-2'>
//             <h6>  <b> {certificate_names_array[i]==="1"?"CPD webinar":certificate_names_array[i]==="2"?"Course":certificate_names_array[i]==="3"?"Publication":certificate_names_array[i]==="4"?"leadership":certificate_names_array[i]==="5"?"Teaching":certificate_names_array[i]==="6"?"Conference":certificate_names_array[i]==="7"?"Presentation":certificate_names_array[i]==="8"?"Others":""}</b></h6>
//              </div>
//              <div className='col-md-4'>
               
           
//             </div>
//             <div className='col-md-3'>
               
//              <img src={certificate_files_array[i]!==null?certificate_files_array[i]:""} width={200} height={200} alt=""/>
//             </div>
//             <div className='col-md-3'>
             
//                 {certificate_desc_array[i]!==null?certificate_desc_array[i]:""}
//             </div>
           
            
//         </div>
//     )
// }
// console.log(certificate_files_array[1])


const deleteDocument=(documentid:number)=>{


  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger"
    },
    buttonsStyling: false
  });
  swalWithBootstrapButtons.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, cancel!",
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed) {
      server.delete(`/delete_document/${documentid}`)
      .then((response)=>{
          if(response.data.code===200){
             setAdditonalDocuments(response.data.additional_documents)
             
                
                // setPmQStatus(response.data.documents.pmq_status);
                setClientPhoto(response.data.documents.client_photo);
                setPassportPhoto(response.data.documents.passport_photo);
                setProvisionalDegreeCertificate(response.data.documents.provisional_degree_certificate);
                setFinalDegreeCertificate(response.data.documents.final_degree_certificate);
                setProvisionalRegistrationCertificate(response.data.documents.provisional_registration_certificate);
                setFinalRegistrationCertificate(response.data.documents.final_registration_certificate);
                setInternshipCompletionCertificate(response.data.documents.internship_completion_certificate);
                setcertificateNames(response.data.documents.certificate_names);
                setCertificateDesc(response.data.documents.certificate_description);
                setCertificateFiles(response.data.documents.certificate_file);
                setFileCount(response.data.documents.file_count);
                setAdditonalDocuments(response.data.additional_documents)
                setLanguageTestCertificate(response.data.documents.language_test_result)
                setPlab1Certificate(response.data.documents.plab1_result)
                setPlab2Certificate(response.data.documents.plab2_result)
                setGoodStandingCertificate(response.data.documents.good_standing_certificate)
             }
            
          
      })
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      swalWithBootstrapButtons.fire({
        title: "Cancelled",
        text: "Your file is safe :)",
        icon: "error"
      });
    }
  });

    
}

if (loading) {
    return <Loader />;
  }
  return (
 
      <div className='pb-5'>
         {user_agreed === 1 ? 
  
  <Link to={`/document_add/${id}`} className='btn btn-info text-light float-right'>+</Link> :
<p className='text-danger'>Kindly ask the doctor to fill their details in their portal</p>
}
         {/* <Link to={`/document_add/${id}`} className='btn btn-info text-white mb-3 float-right'>Add Additional Documents</Link> */}
        
         
         <br/>
         <br/>
         
    
        
          
            
            <ToastContainer />
           
            
        {/* </div> */}
        { additional_documents.length>0 &&  <div className='jumbotron'>
        <h3 className='text-center'>Additional Documents</h3><hr/>
        
      { additional_documents.map((document) =>(
         <div className='row px-2 mt-3' id={"sub"}>
            
         <div className='col-md-2'>
         <h6>  <b> {document.certificate_type===1?"CPD webinar":document.certificate_type===2?"Course":document.certificate_type===3?"Publication":document.certificate_type===4?"leadership":document.certificate_type===5?"Teaching":document.certificate_type===6?"Conference":document.certificate_type===7?"Presentation":document.certificate_type===8?"Language Test Result":document.certificate_type===9?"PLAB-1 Test Result":document.certificate_type===10?"PLAB-2 Test Result":document.certificate_type===11?"Good Standing Certificate":document.certificate_type===12?"Others":""}</b></h6>
          </div>
          <div className='col-md-3'>
            
        
         </div>
         <div className='col-md-3'>
            
          <img src={document.certificate_file!==null?document.certificate_file:""} width={200} height={200} alt=""/>
         </div>
         <div className='col-md-3'>
          
           <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>  {document.certificate_name!==null?document.certificate_name:""}</div>
         </div>
         <div className='col-md-1'>
            <i className='fa fa-trash text-danger' onClick={(e)=>deleteDocument(document.id)}></i>
         </div>
        
         
     </div>
    )) }
        </div>}
         </div>
    
  )
}

export default Documents