import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import { useParams } from 'react-router-dom';
import { service_categorytypedata } from '../../@types/service_categorytypedata';
import { StageData } from '../../@types/stagetype';
import server from '../Utils';
import { ServiceCategoryType } from '../../@types/service_category_type';
import Loader from './Loader';
const EditServiceCategoryType = () => {
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [projects,setProjects]=useState<Array<ProjectData>>([])
    const [project_id,setProjectId]=useState<number>(0);
    const [product_id,setProductId]=useState<number>(0);
    const [service_categories,setServiceCategories]=useState<Array<ServiceCategoryType>>([]);
    const [service_category,setServiceCategory]=useState<number>(0);
    const [service_category_type,setServiceCategoryType]=useState<string>();

    const[stage_id,setStageId]=useState<number>(0);
    const [stages,setStages]=useState<Array<StageData>>([])
    const [display,setDisplay]=useState<number>(0);
    const [fixed,setFixed]=useState<number>(0);
    const [error,setError]=useState<string>();
    const [loading, setLoading] = useState(false);

    const {id}=useParams();
    const cat_type_id=id;
    let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
    useEffect(() => {
        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let id= +projectid
        setProjectId(id)
    //    server.get(`/edit_service_category_type/${id}`)
    //    .then((response)=>{
    //     setStages(response.data.stages)
    //    })
    server.get(`/get_service_categories/${id}`)
    .then((response)=>{
        console.log(response)
        if(response.data.service_categories.length>0){
            setServiceCategories(response.data.service_categories)
        }else{
            setServiceCategories([]);
        }
    })
        server.get(`/edit_service_category_type/${cat_type_id}`)
        .then((response)=>{
            if(response.data.code===200){
                setProjectId(response.data.service_category_types.project_id)
                setProductId(response.data.service_category_types.product_id)
                setStage(response.data.service_category_types.product_id);
                setServiceCategory(response.data.service_category_types.service_category)
                setStageId(response.data.service_category_types.stage_id)
                
                setServiceCategoryType(response.data.service_category_types.service_category_type)
                setDisplay(response.data.service_category_types.display)
                setFixed(response.data.service_category_types.fixed)
            }
        })
        
        server.get(`/get_product/${id}`)
        .then((response)=>{
            
            setProducts(response.data.product)
            
        })
       
      }, []);
   console.log(service_category)
    const addservice_category_type=(e:React.FormEvent<HTMLFormElement>)=>{
        e.preventDefault();
        
        // var regEx = /^[A-Za-z]+$/;
        var name:any = service_category_type;
        if(project_id===0)
        {
            setError("Please Select the project"); 
            toast(error);
        }
       else if(product_id===0)
       {
        setError("Please Select the product"); 
        toast(error);
       }
       
       else
        {
            setError('');
            var token=localStorage.getItem('auth_token');
            var form=document.getElementById('add_service_category_type_form') as HTMLFormElement;
            var formdata=new FormData(form);
                

            server.post(`/update_service_category_type/${cat_type_id}`,formdata,{ headers: { 'Authorization': 'Bearer '+token } })
            .then((response)=>{
                if(response.data.code===200)
                {
                    toast(response.data.message)
                     setTimeout(function () { window.location.href = "/service_category_types"; }, 1000);
                }else
                {
                    toast(response.data.message)
                }
            })
        }
    }

    const setStage=(id:number)=>
    {
      setProductId(id);
      
      server.get(`/get_service_categories_all/${projectid}/${id}`)
      .then((response)=>{
          console.log(response)
          if(response.data.service_categories.length>0){
              setServiceCategories(response.data.service_categories)
          }else{
              setServiceCategories([]);
          }
      })
      server.get(`/get_stages/${projectid}/${id}`)
      .then((response)=>{
          
        setStages(response.data.stages);
          
      })
    }

    // const setProject=(id:number)=>
    // {
    //     setProjectId(id);
    //     server.get(`/get_product/${id}`)
    //     .then((response)=>{
            
    //         setProducts(response.data.product)
            
    //     })
    // }
    
    if (loading) {
        return <Loader />;
      }

  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}

<div className="row px-2">
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-primary shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
        Earnings (Monthly)</div>
        <div className="h5 mb-0 font-weight-bold text-gray-800">$40,000</div>
    </div>
    <div className="col-auto">
        <i className="fas fa-calendar fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-success shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
        Earnings (Annual)</div>
        <div className="h5 mb-0 font-weight-bold text-gray-800">$215,000</div>
    </div>
    <div className="col-auto">
        <i className="fas fa-dollar-sign fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-info shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3  ">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Tasks
        </div>
        <div className="row no-gutters align-items-center">
        <div className="col-auto">
            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">50%</div>
        </div>
        <div className="col">
            <div className="progress progress-sm mr-2">
            <div className="progress-bar bg-info" role="progressbar" style={{width: '50%'}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
            </div>
        </div>
        </div>
    </div>
    <div className="col-auto">
        <i className="fas fa-clipboard-list fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
{/* Pending Requests Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-warning shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
        Pending Requests</div>
        <div className="h5 mb-0 font-weight-bold text-gray-800">18</div>
    </div>
    <div className="col-auto">
        <i className="fas fa-comments fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
</div>
    <h4 className='text-danger text-center'>Add Service Category Type</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>
   
    <div className='col-md-12 px-4'>
      
        <form id="add_service_category_type_form" className='w-100' onSubmit={addservice_category_type}>
          <div className='row'>
            <input type="hidden" name="project_id" value={project_id} id="project_id" />
           
            {project_id!==0?<div className='col-md-2'>
            <label >Products</label>
                     <select className='form-control' name='product_id' id="product_id" value={product_id} onChange={(e)=>setStage(parseInt(e.target.value))} required>
                        <option value={0}>Select Product</option>
                        {products.map((product)=>(
                            <option value={product.id}>{product.product_name}</option>))}
            </select>
            </div>:""}
            {product_id!==0? <div className='col-md-2'>
                    <label >Stages</label>
                    <select className='form-control' name='stage_id' id="stage_id" value={stage_id} onChange={(e)=>setStageId(parseInt(e.target.value))} required>
                        <option value={0}>Select the stage</option>
                        {stages.map((stage,index)=>(
                            <option value={stage.id}>{stage.stage_name}</option>))}
                    </select>
                </div>:""}  
                      
                {stage_id!==0?<div className='col-md-2'>
                     <label>Service Category</label>
                     <select className='form-control' name='service_category' id="service_category" value={service_category} onChange={(e)=>setServiceCategory(parseInt(e.target.value))} required>
                        <option value={0}>Select the Service Category</option>
                        {service_categories.map((service_category,index)=>(
                            <option value={service_category.id}>{service_category.service_category_name}</option>))}
                    </select>     
               </div>:""}             
                     
             {service_category!==0? <div className='col-md-2'>
                    <label>Service Category Type</label>
                    <input className='form-control' type="text" placeholder='Enter Category Type' value={service_category_type} onChange={(e)=>setServiceCategoryType(e.target.value)} name="service_category_type" id="service_category_type" required />
                </div>:""}
                {service_category!==0?<div className='col-md-2'>
                <label >Display</label>
                <select className='form-control' name={"display"} value={display} onChange={(e)=>setDisplay(parseInt(e.target.value))}>
                    <option value={0}>---Choose Display---</option>
                    <option value={1}>Yes</option>
                    <option value={2}>No</option>

                </select>
             </div>:""}   
             {display!==0?<div className='col-md-2'>
                <label>Fixed</label>
                <select className='form-control' name={"fixed"} value={fixed} onChange={(e)=>setFixed(parseInt(e.target.value))} >
                    <option value={0}>Choose Option</option>
                    <option value={1}>Fixed</option>
                    <option value={2}>UnFixed</option>
                </select>
             </div>:""}
          </div>
          
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Submit</button></div>
        </form>
     
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default EditServiceCategoryType