import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { SelectProps, message } from 'antd';
import {useParams} from 'react-router-dom';
import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import {multipleusertype} from '../../@types/multipleusertype';
import Multiselect from 'multiselect-react-dropdown';
import $ from 'jquery'; 
// import './onboarding.css';
import validator from 'validator';
import server from '../Utils';
import UserInfo from '../UserInfo';
import Loader from './Loader';
import  {  Select, Space  }  from 'antd';
const AddOnboardingClientMultiple = () => {
    const {id}= useParams();
    
    const [project_id,setProjectId]=useState<number>(0);
    const [admins,setAdmins]=useState<Array<Admintype>>([]);
    const [adminid,setAdminId]=useState<number>(0);
    const [welcome_call_by,setWelcomeBy]=useState<number>(0);
    const [welcome_kits_online,setWelcomeKitOnline]=useState<Array<welcomekittype>>([]);
    const [welcome_kits_offline,setWelcomeKitOffline]=useState<Array<welcomekittype>>([]);
    const [welcome_kit_types,setWelcomeKitTypes]=useState<Array<welcomekittype>>([]);
    const [welcome_call_completed,setWelcomeCallCompleted]=useState<number>(0);
    const [welcome_call_date,setWelcomeCallDate]=useState<string>('');
    const [update_onboarding_form,setUpdateOnboarding]=useState<number>(0);
    const [sent_for_delivery,setSentForDelivery]=useState<number>(0);
    const [sent_for_delivery_date,setSentForDeliverDate]=useState<string>('');
    const [delivered,setDelivered]=useState<number>(0);
    const [delivery_date,setDeliveryDate]=useState<string>("");
    const [clear,setClear]=useState<number>(1);
    const [onboardid,setOnboardId]=useState<number>(0);
    const [start_training,setStartTraining]=useState<number>(0);
    const [start_training_date,setStartTrainingDate]=useState<string>('');
    const [pass,setPass]=useState<number>(1);
    const [loading, setLoading] = useState(false);
    
     const [users,setUsers]=useState<Array<multipleusertype>>([]);
    const [user_id,setUserId]=useState<number>(0);
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [product_id,setProductId]=useState<number>(0);
    // var options: SelectProps['options'] = [];
    const [selectedValue,setSelectedValue]=useState<Array<multipleusertype>>([])
    const [error,setError]=useState<boolean>(false);
    const [count,setCount]=useState<number>(0);
    const [userError,setUserError]=useState<string>('')
    let  admin_id:string=JSON.parse(localStorage.getItem("admin_id")||'{}');
    useEffect(() => {
        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let id = +projectid;
        setProjectId(id) ;
        

        server.get(`/get_product/${id}`)
        .then((response)=>{
            
            setProducts(response.data.product)
            
        })
        
        
        server.get(`/get_operations`)
        .then((response)=>{
            setAdmins(response.data.admins)
        })
        server.get(`/get_welcomekit_products_online`)
        .then((response)=>{
          
            
            setWelcomeKitOnline(response.data.welcomekitproducts)
    
        })
        server.get(`/get_welcomekit_products_offline`)
        .then((response)=>{
          
            setWelcomeKitOffline(response.data.welcomekitproducts)
    
        })
        server.get(`/get_welcomekit_types`)
        .then((response)=>{
          
            setWelcomeKitTypes(response.data.welcome_kit_type)
    
        })
       $('.hide').hide();
      }, []);

      
    //   const [options]=useState<Array<multipleusertype>>(users)
      const getCurrentDate = () => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const year = today.getFullYear();
    
        const formattedMonth = month < 10 ? `0${month}` : `${month}`;
        const formattedDay = day < 10 ? `0${day}` : `${day}`;
    
        return `${year}-${formattedMonth}-${formattedDay}`;
      };
      
      
     const  showDate =(type:number,index:number)=>{
        if(type===2){
    var element =document.getElementById('onlinedate'+index) as HTMLInputElement;
    element.classList.toggle('hide');
    element.classList.toggle('show');
    }
     }

     if (loading) {
        return <Loader />;
      }

     
const handleChange=(value:string)=>{
    
}

    const  AddClientOnBoardDetails=(e:React.FormEvent<HTMLFormElement>)=>{
        let offlineCheckboxSelected = false;
        for (let i = 1; i <= welcome_kits_offline.length; i++) {
            const offlineElement = document.getElementById('offline' + i) as HTMLInputElement;
            if (offlineElement.checked) {
                offlineCheckboxSelected = true;
                break;
            }
        }
        
        e.preventDefault();
        if(product_id===0){
            toast('select the product')
        }else if(error===true && count>1 && user_id===0){
            toast('select the user again')
        }
       else if(user_id===0){
            toast('select the user')
        }
        else if(welcome_call_by===0){
            toast('please Select Executive');
        }else if(welcome_call_completed===0){
            toast('please check whether welcome Call is completed');
        }else if((welcome_call_completed===1 && welcome_call_date==="")|| (welcome_call_completed===1 && welcome_call_date===null)){
            toast("Please Enter Welcome Call Date");
        } else if (!offlineCheckboxSelected) {
            toast('Please select at least one offline product');
            return;
        }
    
        else  if(welcome_kits_online.length>0)
        {
            for(var i=1;i<=welcome_kits_online.length;i++) {
                var element=document.getElementById('online'+i) as HTMLInputElement;
                if(element.value!==""||element.value!==null){
                   if(element.checked===true){
                    var date=document.getElementById('onlinedate'+i) as HTMLInputElement;
                    if(date.value==="" ||date.value===null){
                        toast('please select the date online product'+i);
                        setClear(0)
                    }else{
                        setClear(1)
                    }
                   }
                }                
            } 
        }
       
        else if(clear===1){  
            setLoading(false)  
        let form = document.getElementById('addclientonboarddetails') as HTMLFormElement;
        let formdata=new FormData(form);
        server.post(`/add_onboarding`,formdata)
        .then((response)=>{
                if(response.data.code===200){
                    setLoading(false)
                    toast(response.data.message)
                    setUpdateOnboarding(1);
                    setOnboardId(response.data.id);

                    setTimeout(function () { window.history.back(); }, 1000);
                }else{
                    setLoading(false)
                    toast(response.data.message)
                }
        })
    }else{
        toast('could not be added')
    }
    
    }
    const onSelect=(selectedList:[], selectedItem:any) => {
        setSelectedValue(selectedList)
        
    }

    
    const addOtherDetails=(e:React.FormEvent<HTMLFormElement>)=>{
        e.preventDefault();
      
        if(sent_for_delivery!==0){
            if(sent_for_delivery_date===""||sent_for_delivery_date===null){
                toast("Enter the Date for Delivery sent.")
                setPass(0)
                
            }
        }
         if(delivered!==0){
            if(validator.isEmpty(delivery_date)){
                toast("Enter the date of delivery.")
                setPass(0)
               
            }
        } 
        if(start_training!==0){
            if(start_training_date===""||start_training_date===null){
                toast("Select Start Training Date");
                setPass(0)
                console.log("pass",pass)
            }else{
               
                setPass(1)
            }
        }
       
        if(pass===1){
            console.log("pass",pass)
            var form= document.getElementById('update_onboarding') as HTMLFormElement
            var formdata= new FormData(form);
            server.post(`/add_onboarding_update`,formdata)
            .then((response=>{
                
                if(response.data.code===200){
                    toast(response.data.message)
                   
                }else{
                    toast(response.data.message)
                }
            }))
        }
    }
    const onChange = (value:number) => {
       setUserId(value)
      };
    
      let options = [];

for (let i = 0; i < users.length; i++) {
    options.push({
        value: users[i].id,
        label: users[i].user
    });
}

const setallUsers=(id:number)=>{
    setUserError('loading the users..')
    setProductId(id)
    setUserId(0)
    setError(true);
    setCount(count+1)
server.get(`get_operationuser/${id}`)
        .then((response)=>{
          setUsers(response.data.users)
         if(response.data.users.length===0){
            setUserError('There are no users for this project');
         }else{
            setUserError('')
         }
        })

}


      // Filter `option.label` match the user type `input`
      const filterOption = (input:any, option:any) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    // console.log(options)
  return (
    <div className='container-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}

{/* <UserInfo/> */}
    <h4 className='text-danger text-center'>Add Client OnBoarding Details</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5 jumbotron mx-3'>
  
   
    <div className='col-md-12 px-5'>
        <div className="row">
        <div className="col-md-6">
     
        {project_id!==0?<div className='col-md-12'>
            <label >Products</label>
                     <select className='form-control' name='product_id' id="product_id" value={product_id} onChange={(e)=>setallUsers(parseInt(e.target.value))} required>
                        <option value={0}>Select Product</option>
                        {products.map((product)=>(
                            <option value={product.id}>{product.product_name}</option>))}
            </select>
            </div>:""}
      
        </div>
        <div className="col-md-6">

       

        {product_id !== 0 && (
                users.length !== 0 ? (
                  <div>
                    <label>Users</label>
                    <Select
showSearch
style={{ width: '100%' }}
placeholder="Select a person"
optionFilterProp="children"
onChange={onChange}
// onSearch={onSearch}
filterOption={filterOption}
options={options}
/>
                  </div>
                ) : (
                  <p className="text-danger">
                    {userError}
                  </p>
                )
              )}

        </div>
        </div>
    
    
     
    {/* <Select
   mode="multiple"
  style={{ width: '100%' }}
  placeholder="Tags Mode"
  onChange={handleChange}
  
  options={options.map(option => ({ label: option.label, value: option.value }))}
/> */}

{/* <Multiselect options={users} selectedValues={selectedValue} onSelect={onSelect} displayValue='user'/> */}
        {product_id!==0?<form id="addclientonboarddetails" onSubmit={AddClientOnBoardDetails}  encType='multipart/form-data' className='w-100'>
         <div className='container-fluid'>
            <input type="hidden" name="user_id" id="user_id" value={user_id} />
            <input type="hidden" name="project_id" id="project_id" value={project_id} />
            <input type="hidden" name="admin_id" id="admin_id" value={admin_id}/>
            <div className='row'>
  
    <h4 className='text-danger my-3'>Welcome Call Details</h4>
<hr/>
                <div className='col-md-3'>
                    <label>Welcome call by<b className='text-danger'>*</b></label>
                    <select className='form-control' id="welcome_call_by" name="welcome_call_by" value={welcome_call_by} onChange={(e)=>setWelcomeBy(parseInt(e.target.value))}>
                       <option value="0">Select Executives</option>
                        {admins.map((admin)=>(
                            admin.role===5 ||admin.role===6?<option value={admin.id}>{admin.first_name}</option>:""
                        ))}
                    </select>
                </div>
                <div className='col-md-3'>
                    <label>Is Welcome call completed?<b className='text-danger'>*</b></label><br/>
                    <input type="checkbox" name="welcome_call_completed" id="welcome_call_completed" value={welcome_call_completed} onChange={(e)=>welcome_call_completed===0?setWelcomeCallCompleted(1):setWelcomeCallCompleted(0)}/>
                    
                </div>
                {welcome_call_completed===1? <div className='col-md-3'>
                <label>Call Completed Date<b className='text-danger'>*</b></label><br/>  
                <input type='date' className='form-control' max={getCurrentDate()} name="welcome_call_date" id="welcome_call_date" value={welcome_call_date} onChange={(e)=>setWelcomeCallDate(e.target.value)} />
                </div>:""}
                <div className='col-md-12 mt-3'>
                
               
                <input type="hidden" name="online_length" value={welcome_kits_online.length} />
                <input type="hidden" name="offline_length" value={welcome_kits_offline.length} />
                {welcome_call_completed===1? <hr/>:""}
                {welcome_call_completed===1?<div><h4 className='text-danger my-3'>To include in courier</h4></div>:""}
                {welcome_call_completed===1? <hr/>:""}
                {welcome_call_completed===1  && welcome_kits_offline.map((item,index)=>(
                    <div className='row my-2'>
                        

                        <div className='col-md-2'>
                            <h6>{item.welcome_kit_name}</h6></div>
                   <div className='col-md-3 text-center'>
                        <input  type="checkbox" name={"offline"+(index+1)} id={"offline"+(index+1)}  value={item.id} onChange={()=>showDate(1,(index+1))}  /></div>
                        
                    </div>
                ))}
                
               <br/>
              {welcome_call_completed===1 && welcome_kits_online.length>0? <hr/>:""}
              {welcome_call_completed===1 && welcome_kits_online.length>0?<div>{welcome_kits_online.length>0?<h4 className='text-danger my-3'> Online resourses given at the time of Onboarding</h4>:""}</div>:""}
                {welcome_call_completed===1 && welcome_kits_online.length>0? <hr/>:""}
                {welcome_call_completed===1  && welcome_kits_online.map((item,index)=>(
                    <div className='row my-2'>
                       
                        <div className='col-md-2'>
                            <h6>{item.welcome_kit_name}</h6></div>
                   <div className='col-md-3 text-center'>
                        <input  type="checkbox" name={"online"+(index+1)} id={"online"+(index+1)}  value={item.id} onChange={()=>showDate(2,(index+1))}  /></div>
                        <div className='col-md-4'> <input  className='form-control hide' type="date" name={"onlinedate"+(index+1)} id={"onlinedate"+(index+1)}/>
                    </div>
                    </div>
                ))}

                
               
                </div>
                
                
            </div>

         </div>

            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-info text-light mt-2'>Add Details</button></div>
        </form>:""}
   
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default AddOnboardingClientMultiple