import React, { useState, useEffect } from 'react';
import './index.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import server from '../Utils';
import Loader from './Loader';
import UserProgressBar from '../UserProgressBar';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import UserSideBar from '../../pages/UserSideBar/Index';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const UserDocuments: React.FC = () => {
  const loggedIn = localStorage.getItem('loggedIn');

  const [pmq_status, setPmQStatus] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [first_name, setFirstName] = useState<string>('');
  const [last_name, setLastName] = useState<string>('');
  const [user_email, setUserEmail] = useState<string>('');
  const [passport_photo, setPassportPhoto] = useState<any[]>([]);
  const [passport_photo_back, setPassportPhotoBack] = useState<any[]>([]);
  const [client_photo, setClientPhoto] = useState<any[]>([]);
  const [provisional_degree_certificate, setProvisionalDegreeCertificate] = useState<any[]>([]);
  const [final_degree_certificate, setFinalDegreeCertificate] = useState<any[]>([]);
  const [provisional_registration_certificate, setProvisionalRegistrationCertificate] = useState<any[]>([]);
  const [final_registration_certificate, setFinalRegistrationCertificate] = useState<any[]>([]);
  const [internship_completion_certificate, setInternshipCompletionCertificate] = useState<any[]>([]);
  const [personal_note, setPersonalNote] = useState<string>('');
  const [welcome_call_date, setWelcomeCallDate] = useState<string>('');
  const [welcome_call_time, setWelcomeCallTime] = useState<number>(0);
  const [passport_exist, setPassportExist] = useState<number>(0);
  const [form_progress, setFormProgress] = useState<number>(0);
  const [submission,setSubmission]=useState<boolean>(false);
  const userid = localStorage.getItem('ClientID');

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const response = await server.get(`/operation_user_view/${userid}`);
        const userData = response.data.user;
        setFirstName(userData.first_name);
        setLastName(userData.last_name);
        setUserEmail(userData.user_email);
        setPmQStatus(userData.pmq_status);
        setPersonalNote(userData.personal_note);
        setWelcomeCallDate(userData.welcome_call_date);
        if(userData.welcome_call_time===null){
        setWelcomeCallTime(0)
        }else{
        setWelcomeCallTime(userData.welcome_call_time);
        }
        setPassportExist(userData.passport_exist);
        setFormProgress(userData.form_progress || 0);
      } finally {
        // setLoading(false);
      }
    };

    const fetchDocuments = async () => {
      setLoading(true);
      try {
        const response = await server.get(`/get_documents/${userid}`);
        const documents = response.data.documents;
        setClientPhoto(documents.client_photo ? [{ source: documents.client_photo }] : []);
        setPassportPhoto(documents.passport_photo ? [{ source: documents.passport_photo }] : []);
        setPassportPhotoBack(documents.passport_photo_back ? [{ source: documents.passport_photo_back }] : []);
        setProvisionalDegreeCertificate(documents.provisional_degree_certificate ? [{ source: documents.provisional_degree_certificate }] : []);
        setFinalDegreeCertificate(documents.final_degree_certificate ? [{ source: documents.final_degree_certificate }] : []);
        setProvisionalRegistrationCertificate(documents.provisional_registration_certificate ? [{ source: documents.provisional_registration_certificate }] : []);
        setFinalRegistrationCertificate(documents.final_registration_certificate ? [{ source: documents.final_registration_certificate }] : []);
        setInternshipCompletionCertificate(documents.internship_completion_certificate ? [{ source: documents.internship_completion_certificate }] : []);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
    fetchDocuments();
   
  }, [userid]);

  if (loggedIn !== "true") {
    window.location.href = "/userlogin";
  }
  const validateForm = () => {
    
    if (!welcome_call_date) {
      toast('Enter welcome call date');
      return false;
    }
    if (welcome_call_time === 0 || welcome_call_time===null) {
      toast('Select welcome call time');
      return false;
    }
    if (personal_note.trim() === '') {
      toast('Enter personal notes');
      return false;
    }
    if (passport_exist === 1 && (passport_photo.length === 0)) {
      toast('Upload front page of passport');
      return false;
    }
    if(passport_exist===1 && (passport_photo_back.length===0)){
      toast('Upload  back page of passport');
    }
    if (client_photo.length === 0) {
      toast('Upload client photograph');
      return false;
    }
    if ((pmq_status === 1 || pmq_status === 2) && provisional_degree_certificate.length === 0) {
      toast('Upload provisional degree certificate');
      return false;
    }
    if ((pmq_status === 1 || pmq_status === 2) && provisional_registration_certificate.length === 0) {
      toast('Upload provisional registration certificate');
      return false;
    }
    if (pmq_status === 3 && internship_completion_certificate.length === 0) {
      toast('Upload internship completion certificate');
      return false;
    }
    if (pmq_status === 3 && final_degree_certificate.length === 0) {
      toast('Upload final degree certificate');
      return false;
    }
    if (pmq_status === 3 && final_registration_certificate.length === 0) {
      toast('Upload final registration certificate');
      return false;
    }
    return true;
  };
  
  const documentUploadSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) return;
  
    const userid = localStorage.getItem('ClientID');
    const formData = new FormData();
  
    const appendFile = (key: string, file: any[]) => {
      if (file.length > 0 && file[0].file) {
        formData.append(key, file[0].file, file[0].file.name);
      }
    };
  
    appendFile('passport_photo', passport_photo);
    appendFile('passport_photo_back', passport_photo_back);
    appendFile('client_photo', client_photo);
    appendFile('provisional_degree_certificate', provisional_degree_certificate);
    appendFile('final_degree_certificate', final_degree_certificate);
    appendFile('provisional_registration_certificate', provisional_registration_certificate);
    appendFile('final_registration_certificate', final_registration_certificate);
    appendFile('internship_completion_certificate', internship_completion_certificate);
  
    formData.append('personal_note', personal_note);
    formData.append('welcome_call_date', welcome_call_date);
    formData.append('welcome_call_time', welcome_call_time.toString());
    formData.append('form_progress', '7');
  
    setLoading(true);
    setSubmission(false);
  
    try {
      const response = await server.post(`/user_documents/${userid}`, formData);
      if (response.data.code === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Documents Uploaded Successfully",
          showConfirmButton: false,
        });
        setTimeout(() => {
          window.location.href = "/user_declaration";
        }, 100);
      } else {
        toast(response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };
  
  const saveDetails = async () => {
    if (!validateForm()) return;
  
    const userid = localStorage.getItem('ClientID');
    const formData = new FormData();
  
    const appendFile = (key: string, file: any[]) => {
      if (file.length > 0 && file[0].file) {
        formData.append(key, file[0].file, file[0].file.name);
      }
    };
  
    appendFile('passport_photo', passport_photo);
    appendFile('passport_photo_back', passport_photo_back);
    appendFile('client_photo', client_photo);
    appendFile('provisional_degree_certificate', provisional_degree_certificate);
    appendFile('final_degree_certificate', final_degree_certificate);
    appendFile('provisional_registration_certificate', provisional_registration_certificate);
    appendFile('final_registration_certificate', final_registration_certificate);
    appendFile('internship_completion_certificate', internship_completion_certificate);
  
    formData.append('personal_note', personal_note);
    formData.append('welcome_call_date', welcome_call_date);
    formData.append('welcome_call_time', welcome_call_time.toString());
    formData.append('form_progress', '7');
  
    setLoading(true);
  
    try {
      const response = await server.post(`/user_documents/${userid}`, formData);
      if (response.data.code === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Documents Uploaded Successfully",
          showConfirmButton: false,
        });
      } else {
        toast(response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };
  
  if (loading) {
    return <Loader />;
  }

  return (
    <div className='container-fluid desktop_container'>
      <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name} />
      <div className='horizontal-menu' style={{ background: "transparent" }}>
        <div className='container-fluid mt-3'>
          <div className='row my-5'>
            <div className='col-md-3 text-center'><h2 className='blue-color'><b>Documents</b></h2></div>
            <div className='col-md-9 mt-2 px-5'><UserProgressBar form_progress={form_progress} /></div>
          </div>
        </div>
        <div className='user_foarm_board userpath_right_pad'>
          <h3 className='blue-color fw-bold my-4'> Documents</h3>
          <form className="w-100" id="document_upload_form" encType='multipart/form-data' onSubmit={documentUploadSubmit}>
            <input type='hidden' name="form_progress" id="form_progress" value={7} />
            <div className='row mt-3'>
              {passport_exist === 1 && (
                <>
                  <div className='col-md-4'>
                    <label className="blue-color fw-bold">Upload Passport Front Page<b className='text-danger'>*</b></label>
                  <br /><br />
                    <FilePond
                      files={passport_photo}
                      onupdatefiles={setPassportPhoto}
                      allowMultiple={false}
                      name="passport_photo"
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                  </div>
                  <div className='col-md-4'>
                    <label className="blue-color fw-bold">Upload Passport Back Page<b className='text-danger'>*</b></label><br /><br />
                    <FilePond
                      files={passport_photo_back}
                      onupdatefiles={setPassportPhotoBack}
                      allowMultiple={false}
                      name="passport_photo_back"
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                  </div>
                </>
              )}
              <div className='col-md-4'>
                <label className="blue-color fw-bold">Upload Photograph<b className='text-danger'>*</b></label><br /><br />
                <FilePond
                  files={client_photo}
                  onupdatefiles={setClientPhoto}
                  allowMultiple={false}
                  name="client_photo"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
              </div>
            </div>
            <div className='row documents_second_row'>
              {pmq_status === 3 && (
                <div className='col-md-4'>
                  <label className="blue-color fw-bold">Upload Internship Completion Certificate<b className='text-danger'>*</b></label><br /><br />
                  <FilePond
                    files={internship_completion_certificate}
                    onupdatefiles={setInternshipCompletionCertificate}
                    allowMultiple={false}
                    name="internship_completion_certificate"
                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  />
                </div>
              )}
              {(pmq_status === 1 || pmq_status === 2) && (
                <>
                  <div className='col-md-4'>
                    <label className="blue-color fw-bold">Upload Provisional Degree Certificate<b className='text-danger'>*</b></label><br /><br />
                    <FilePond
                      files={provisional_degree_certificate}
                      onupdatefiles={setProvisionalDegreeCertificate}
                      allowMultiple={false}
                      name="provisional_degree_certificate"
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                  </div>
                  <div className='col-md-4'>
                    <label className="blue-color fw-bold">Upload Provisional Registration Certificate<b className='text-danger'>*</b></label><br /><br />
                    <FilePond
                      files={provisional_registration_certificate}
                      onupdatefiles={setProvisionalRegistrationCertificate}
                      allowMultiple={false}
                      name="provisional_registration_certificate"
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                  </div>
                </>
              )}
              {pmq_status === 3 && (
                <>
                  <div className='col-md-4'>
                    <label className="blue-color fw-bold">Upload Registration Certificate<b className='text-danger'>*</b></label><br /><br />
                    <FilePond
                      files={final_registration_certificate}
                      onupdatefiles={setFinalRegistrationCertificate}
                      allowMultiple={false}
                      name="final_registration_certificate"
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                  </div>
                  <div className='col-md-4'>
                    <label className="blue-color fw-bold">Upload Final Degree Certificate<b className='text-danger'>*</b></label><br /><br />
                    <FilePond
                      files={final_degree_certificate}
                      onupdatefiles={setFinalDegreeCertificate}
                      allowMultiple={false}
                      name="final_degree_certificate"
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                  </div>
                </>
              )}
            </div>
            <hr className='my-5' />
            <h3 className='blue-color fw-bold mt-4'>Personal Notes</h3>
            <div className='row mt-5'>
              <div className='col-md-12'>
                <label className="blue-color fw-bold">Please mention important details which you wish to mention<b className='text-danger'>*</b></label>
                <textarea name="personal_note" id="personal_note" style={{ height: "100px" }} className='w-100 form-control rounded user_input_dashboard' onChange={(e) => setPersonalNote(e.target.value)} value={personal_note} required></textarea>
              </div>
            </div>
            <hr className='my-5' />
            <h4 className='blue-color fw-bold mt-4'>Schedule Your Welcome Call</h4>
            <div className='row my-5'>
              <div className='col-md-4'>
                <label className="blue-color fw-bold">Welcome Call Date<b className='text-danger'>*</b></label>
                <input type="date" name="welcome_call_date" id="welcome_call_date" className='w-100 form-control rounded user_input_dashboard' onChange={(e) => setWelcomeCallDate(e.target.value)} value={welcome_call_date} required />
              </div>
              <div className='col-md-4'>
                <label className="blue-color fw-bold">Welcome Call Time<b className='text-danger'>*</b></label>
                <select name="welcome_call_time" id="welcome_call_time" value={welcome_call_time} className='form-control rounded user_input_dashboard' onChange={(e) => setWelcomeCallTime(parseInt(e.target.value))}>
                  <option value="0">Select Welcome Call Time</option>
                  <option value="1">10:00 to 11:00</option>
                  <option value="2">11:00 to 12:00</option>
                  <option value="3">12:00 to 13:00</option>
                  <option value="4">15:00 to 16:00</option>
                  <option value="5">16:00 to 17:00</option>
                </select>
              </div>
            </div>
            <ToastContainer />
            <div className='my-5 user_align_mobile'>
              <Link to="/employment_and_speciality" className='btn bg-blue text-white'>Previous</Link>&nbsp;&nbsp;&nbsp;
              <button type="button" onClick={saveDetails} className='btn bg-blue text-white'>Save</button>&nbsp;&nbsp;&nbsp;
              <button type="submit" disabled={submission === true} className='btn bg-blue text-white'>Save & Next</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UserDocuments;