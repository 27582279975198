import React,{useEffect, useState} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link} from 'react-router-dom';
import { ProjectData } from '../../@types/projecttype';
import MainSideBar from '../../pages/MainSideBar';
import MainHeader from '../../pages/MainHeader';
import Vendors from '../../components/Vendors';



  const Vendor = () => {
  
  return (
    <div className='conatiner-fluid' style={{width:'100%'}}>
    <div className='d-flex'>
             <SideBar  />
                <div className='headerbar'>
                    <Header />
        {/* Content Row */}

    <h4 className='text-danger text-center'>Vendors</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5 px-3'>
    
    <div className='col-md-12'>
   
      <Vendors />
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default Vendor