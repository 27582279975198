
import { trainingtype } from '../../@types/trainingtype';
import React,{useEffect, useState} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link} from 'react-router-dom';
import server from '../Utils';
import Loader from './Loader';
const TrainingAndSubscriptions = () => {
   const [trainings,setTraining]=useState<Array<trainingtype>>([])
   const [loading, setLoading] = useState(false);

   let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
   let id= +projectid
   
   
   useEffect(() => {
    server.get(`/training_and_subscriptions_crud/${id}`)
    .then((response)=>{
        
      setTraining(response.data.training_and_subscription)
})
  }, []);
  const deleteProgram=(deleteid:number)=>{
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        server.delete(`/delete_training_and_subscriptions_crud/${deleteid}`)
    .then((response)=>{
      setTraining(response.data.training_and_subscription)
      swalWithBootstrapButtons.fire({
        title: "Deleted!",
        text: "Your file has been deleted.",
        icon: "success"
      });
    })
        
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your imaginary file is safe :)",
          icon: "error"
        });
      }
    });
    }

    if (loading) {
      return <Loader />;
    }
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}

    <h4 className='text-danger text-center'>Training And Subscriptions</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>
    
    <div className='col-md-11 ml-3'>
        <Link to="/add_training_subscriptions" className='btn btn-outline-primary float-right my-3'>Add TrainingAndSubscriptions </Link>
      <table className='table table-bordered table-striped table-hover'>
        <tr className='bg-warning'><th>Sl no</th><th>Product Name</th><th>Service Category</th><th>Service Name</th> <th className='text-center' colSpan={2}>Actions</th></tr>
      
      {trainings.map((training)=>(
        <tr><td>{training.id}</td><td><Link className='text-left' to={`/training_subscriptions_edit/${training.id}`}>{training.product_name}</Link></td><td>{training.service_category_name}</td><td>{training.service_name}</td><td>edit</td><td><i onClick={()=>deleteProgram(training.id)} className='fa fa-trash'></i></td></tr>
      ))}
       </table>
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
  
}

export default TrainingAndSubscriptions