
import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { ProjectData } from '../../@types/projecttype';
  import {Link,useParams} from 'react-router-dom';
import server from '../Utils';
const EditDiscountType = () => {
    const [projects,setProjects]=useState<Array<ProjectData>>([])
    const [project_id,setProjectId]=useState<number>(0);
    const [loading, setLoading] = useState(false);

    const [discounttype,setDiscountType]=useState<string>('');
    const [error,setError]=useState<string>();
    const {id}=useParams();
    const discountid=id;
    
    const addPackage=(e:React.FormEvent<HTMLFormElement>)=>
    {

        e.preventDefault();
        // var regEx = /^[A-Za-z]+$/;
        var regEx=/^\d+$/gs;
        var name:any = discounttype;
       if(project_id===0)
       {
        setError("Please Select the program"); 
        toast(error);
       }else if(regEx.test(name))
        {
           setError("Discount Type should be alphabets and special characters only"); 
           toast(error);
        }
        
        else{
            setError('');
            var token=localStorage.getItem('auth_token');
            console.log(token)
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            var form=document.getElementById('add_package_form') as HTMLFormElement;
                var formdata=new FormData(form);
           server.post(`/discount_type`,formdata)
            .then((response)=>{
                if(response.data.code===200)
                {
                    toast(response.data.message)
                    setTimeout(function () { window.location.href = "/discounttypes"; }, 1000);
                }else
                {
                    toast(response.data.message)
                }
            })

        }
    }
    useEffect(() => {
      server.get(`/get_projects`)
      .then((response)=>{
        setProjects(response.data.projects)

      })
      server.get(`/edit_discount_type/${discountid}`)
      .then((response)=>{

        setProjectId(response.data.discount_type.project_id)
        setDiscountType(response.data.discount_type.discount_type_name)
      })
    }, []);
    
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}

<div className="row px-2">
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-primary shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
        Earnings (Monthly)</div>
        <div className="h5 mb-0 font-weight-bold text-gray-800">$40,000</div>
    </div>
    <div className="col-auto">
        <i className="fas fa-calendar fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-success shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
        Earnings (Annual)</div>
        <div className="h5 mb-0 font-weight-bold text-gray-800">$215,000</div>
    </div>
    <div className="col-auto">
        <i className="fas fa-dollar-sign fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-info shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3  ">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Tasks
        </div>
        <div className="row no-gutters align-items-center">
        <div className="col-auto">
            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">50%</div>
        </div>
        <div className="col">
            <div className="progress progress-sm mr-2">
            <div className="progress-bar bg-info" role="progressbar" style={{width: '50%'}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
            </div>
        </div>
        </div>
    </div>
    <div className="col-auto">
        <i className="fas fa-clipboard-list fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
{/* Pending Requests Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-warning shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
        Pending Requests</div>
        <div className="h5 mb-0 font-weight-bold text-gray-800">18</div>
    </div>
    <div className="col-auto">
        <i className="fas fa-comments fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
</div>
    <h4 className='text-danger text-left'>Add Product</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>
    <div className='col-md-1'></div>
    <div className='col-md-10'>
        <form id="add_package_form" className='w-100' onSubmit={addPackage}>
            <div className='row'>
                {/* <div className='col-md-4'>
                    <label >Project</label>
                    <select className='form-control' name='project_id' id="project_id" value={project_id} onChange={(e)=>setProjectId(parseInt(e.target.value))}>
                        <option value={0}>Please select the program</option>
                        {projects.map((project,index)=>(
                            <option value={project.id}>{project.project_name}</option>))}
                    </select>
                </div> */}
                 <input type="hidden" name="project_id"  id="project_id" value={project_id} />
                <div className='col-md-4'>
                    <label >Discount Type</label>
                    <input className='form-control' type="text" placeholder='Enter Discount Type' value={discounttype} onChange={(e)=>setDiscountType(e.target.value)} name="discount_type_name" id="discount_type_name" required />
                </div>
                
            </div>
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Submit</button></div>
        </form>
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default EditDiscountType