
import React,{useState,useEffect} from 'react'
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link,useParams} from 'react-router-dom';
import axios from 'axios';
import { StageData } from '../../@types/stagetype';
import {ProductData} from '../../@types/producttype';
import {ProjectData} from'../../@types/projecttype';
import server from '../Utils';
import Loader from './Loader';
const EditSubStages = () => {
    const {id}=useParams();
    const [stages,setStages]=useState<Array<StageData>>([])
    const[stage_id,setStageId]=useState<number>();
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [projects,setProjects]=useState<Array<ProjectData>>([])
    const [project_id,setProjectId]=useState<number>(0);
    const [product_id,setProductId]=useState<number>(0);
    const [substage,setSubStage]=useState<string>('')
    const [error,setError]=useState<string>('')
    const [loading, setLoading] = useState(false);

    const substageid=id;
    let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
    useEffect(() => {
        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let id= +projectid
        setProjectId(id)
            server.get(`/get_product/${id}`)
            .then((response)=>{
                
                setProducts(response.data.product)
                
            })

       
            server.get(`/substage/${substageid}`)
            .then((response)=>
            {
              setProjectId(response.data.sub_stage.project_id)
              setProductId(response.data.sub_stage.product_id)
              server.get(`/get_stages/${projectid}/${response.data.sub_stage.product_id}`)
              .then((response)=>{
                  
                setStages(response.data.stages);
                  
              })
              setStageId(response.data.sub_stage.stage_id)
              setSubStage(response.data.sub_stage.sub_stage_name)
            })
        server.get(`/get_projects`)
        .then((response)=>{
          setProjects(response.data.projects)
  
        })
       
            
      }, []);

   
      
      const setStage=(id:number)=>
      {
        setProductId(id);
        
          server.get(`/get_stages/${projectid}/${id}`)
          .then((response)=>{
              
            setStages(response.data.stages);
              
          })
      }
      
      

     
const updateSubStage=(e:React.FormEvent<HTMLFormElement>)=>
{
    e.preventDefault();
    var form=document.getElementById('update_sub_stage_form') as HTMLFormElement ;
    var formData= new FormData(form);
    var regEx = /^[A-Za-z]+$/;
    var name:any = substage;
    if(product_id===0)
    {
     setError("Please Select the package"); 
     toast(error);
    }
    else if(stage_id===0)
   {
    setError("Please Select the Stage"); 
    toast(error);
   }
    server.post(`/substage/${substageid}`,formData)
    .then((response)=>
    {
        if(response.data.code===200)
        {
            toast(response.data.message)
            setTimeout(function () { window.history.back(); }, 1000);
        }else
        {
            toast(response.data.message)
        }
    })
}
if (loading) {
    return <Loader />;
  }
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
            <div className='headerbar'>
        <Header/>
        {/* Content Row */}

    <h4 className='text-danger text-center'>Edit Stage</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>
    <div className='col-md-1'></div>
    <div className='col-md-10'>
      
        <form id="update_sub_stage_form" onSubmit={updateSubStage} className='w-100' >
            <div className='row'>

            {/* <div className='col-md-3'>
            <label >Project</label>
            <select className='form-control' name='project_id' id="project_id" defaultValue={project_id} onChange={(e)=>setProject(parseInt(e.target.value))} required> 
                        <option value={0}>Select Project</option>
                        {projects.map((project)=>(
                            <option value={project.id}>{project.project_name}</option>))}
            </select>
            </div>     */}
            <input type="hidden" name="project_id"  id="project_id" value={project_id} />
            <div className='col-md-3'>

           
            <label >Products</label>
                     <select className='form-control' name='product_id' id="product_id" value={product_id} onChange={(e)=>setStage(parseInt(e.target.value))} required>
                        <option value={0}>Select Product</option>
                        {products.map((product)=>(
                            <option value={product.id}>{product.product_name}</option>))}
            </select>
            </div>
                <div className='col-md-3'>
                <label >Stage</label>
                  
                    <select className='form-control' name='stage_id' id="stage_id" value={stage_id}  onChange={(e)=>setStageId(parseInt(e.target.value))} required>
                        <option value={0}>Select the stage</option>
                        {stages.map((stage,index)=>(
                            <option value={stage.id}>{stage.stage_name}</option>))}
                    </select>
                </div>
                <div className='col-md-3'>
                <label >SubStage</label> 
                    <input className='form-control' type="text" placeholder='Enter SubStage' value={substage}  onChange={(e)=>setSubStage(e.target.value)} name="sub_stage_name" id="sub_stage_name" required />
                </div>
                
            </div>
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Submit</button></div>
        </form>
     
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default EditSubStages