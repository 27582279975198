import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import {Link, useParams} from 'react-router-dom';
import { UserData } from '../../@types/usertype';
import { ServiceData } from '../../@types/servicetype';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import './viewuser.css';
import server from '../Utils';
import moment from 'moment';
import Loader from './Loader';

type userdata={
first_name:string,
last_name:string,
primary_country_code:number,
primary_contact:number,
alernate_country_code:number,
alternatecontact:number,
password:string
user_email:string,
created_by:string,
updated_by:string,
date_of_registration:string,
noofintallments:number,
installment1:number,
installment2:number,
installment3:number,
installment4:number,
installment5:number,
installment6:number,
contract_file:string,
counsellor_note:string,
stage_name:string,
product_name:string,
project_name:string,
product_price:number,
current_stage_name:string,
installmentdiscount1:number,
installmentdiscount2:number,
installmentdiscount3:number,  
installmentdiscount4:number,
institutionaldiscount1:number,
institutionaldiscount2:number,
institutionaldiscount3:number,
institutionaldiscount4:number,
finalinstallment1:number,
finalinstallment2:number,
finalinstallment3:number,
finalinstallment4:number,
installment_no_of_days1:number,
installment_no_of_days2:number,
installment_no_of_days3:number,
installment_no_of_days4:number,
timefunction2:number,
timefunction3:number,
timefunction4:number,
final_trigger2:number,
final_trigger3:number,
final_trigger4:number,
installment_date2:string,
installment_date3:string,
installment_date4:string,
payment1:number,
payment2:number,
payment_date1:string,
payment_date2:string,
}
const ViewUser:React.FC<userdata> = ({
  first_name,
  last_name,
  primary_country_code,
  primary_contact,
  alernate_country_code,
  alternatecontact,
  password,
  user_email,
  created_by,
  updated_by,
  date_of_registration,
  noofintallments,
  installment1,
  installment2,
  installment3,
  installment4,
  installment5,
  installment6,
  contract_file,
  counsellor_note,
  stage_name,
  product_name,
  project_name,
  product_price,
  current_stage_name,
  installmentdiscount1,
  installmentdiscount2,
  installmentdiscount3 ,
  installmentdiscount4,
  institutionaldiscount1,
  institutionaldiscount2,
  institutionaldiscount3,
  institutionaldiscount4,
  finalinstallment1,
  finalinstallment2,
  finalinstallment3,
  finalinstallment4,
  installment_no_of_days1,
  installment_no_of_days2,
  installment_no_of_days3,
  installment_no_of_days4,
  timefunction2,
  timefunction3,
  timefunction4,
  final_trigger2,
  final_trigger3,
  final_trigger4,
  installment_date2,
  installment_date3,
  installment_date4,
  payment1,
  payment2,
  payment_date1,
  payment_date2,
  }) => {
  let role =localStorage.getItem('role');
    const {id}= useParams();
    const userid=id;
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [projects,setProjects]=useState<Array<ProjectData>>([])
    const [discounted_amount,setDiscountedAmount]=useState<number>(0)
    const [project_id,setProjectId]=useState<number>(0);
    const [product_id,setProductId]=useState<number>(0);
    // const[product_price,setProductPrice]=useState<number>(0);
    const [stages,setStages]=useState<Array<StageData>>([])
     const[stage_id,setStageId]=useState<number>(0);
     const [services,setServices]=useState<Array<ServiceData>>([])
     const [subscribed_services,setSubscribedServices]=useState<string>('')
    
     const[installment_type_name,setInstallmentTypeName]=useState('');
    //  const[date_of_registration,setDateOfRegistration]=useState<string>("");
     const [loading, setLoading] = useState(false);

     const[product_amount,setProductAmount]=useState<number>();
    //  const[contract_file,setContractFile]=useState<string>('');
    //  const[counsellor_note,setCounsellorNote]=useState<string>();   
    const[file,setFile]=useState<File>();
    const[service_names,setServiceNames]=useState<Array<ServiceData>>([]);
    
     const [installtypes,setInstalltypes]=useState<Array<InstallmentTypeData>>([])
     const[installtypesid,setInstallTypeId]=useState<number>(0);
      // const [password,setPassword]=useState<string>("");
    
    //  const [noofintallments,setNoOfInstallments]=useState<number>(0);
     
    //  const [installment1,setInstallment1]=useState<number>(0);
    //  const [installment2,setInstallment2]=useState<number>(0);
    //  const [installment3,setInstallment3]=useState<number>(0);
    //  const [installment4,setInstallment4]=useState<number>(0);
    //  const [installment5,setInstallment5]=useState<number>(0);
     const [installmen6,setInstallment6]=useState<number>(0);
    
    //  const[installmentdiscount1,setInstallmentDiscount1]=useState<number>(0);
    //  const[installmentdiscount2,setInstallmentDiscount2]=useState<number>(0);
    //  const[installmentdiscount3,setInstallmentDiscount3]=useState<number>(0);
    //  const[installmentdiscount4,setInstallmentDiscount4]=useState<number>(0);
    //  const[installmentdiscount5,setInstallmentDiscount5]=useState<number>(0);
    //  const[installmentdiscount6,setInstallmentDiscount6]=useState<number>(0);

    //  const[institutionaldiscount1,setInstitutionalDiscount1]=useState<number>(0);  
    //  const[institutionaldiscount2,setInstitutionalDiscount2]=useState<number>(0);  
    //  const[institutionaldiscount3,setInstitutionalDiscount3]=useState<number>(0);  
    //  const[institutionaldiscount4,setInstitutionalDiscount4]=useState<number>(0);  
    //  const[institutionaldiscount5,setInstitutionalDiscount5]=useState<number>(0);  
    //  const[institutionaldiscount6,setInstitutionalDiscount6]=useState<number>(0);  

    //  const [payments,setPayments]=useState<number>(1);
    //  const [payment1,setPayment1]=useState<number>(0);
    //  const [payment2,setPayment2]=useState<number>(0);
    //  const [payment3,setPayment3]=useState<number>(0);
    //  const [payment4,setPayment4]=useState<number>(0);
    //  const [payment5,setPayment5]=useState<number>(0);
    //  const [payment6,setPayment6]=useState<number>(0);

    //  const [payment_no_of_days1,setPaymentNoOfDays1]=useState<number>(0);
    //  const [payment_no_of_days2,setPaymentNoOfDays2]=useState<number>(0);
    //  const [payment_no_of_days3,setPaymentNoOfDays3]=useState<number>(0);
    //  const [payment_no_of_days4,setPaymentNoOfDays4]=useState<number>(0);
    //  const [payment_no_of_days5,setPaymentNoOfDays5]=useState<number>(0);
    //  const [payment_no_of_days6,setPaymentNoOfDays6]=useState<number>(0);

    //  const [paymenttrigger1,setPaymentTrigger1]=useState<number>(0);
    //  const [paymenttrigger2,setPaymentTrigger2]=useState<number>(0);
    //  const [paymenttrigger3,setPaymentTrigger3]=useState<number>(0);
    //  const [paymenttrigger4,setPaymentTrigger4]=useState<number>(0);
    //  const [paymenttrigger5,setPaymentTrigger5]=useState<number>(0);
    //  const [paymenttrigger6,setPaymentTrigger6]=useState<number>(0);

    //  const [payment_date1,setPaymentDate1]=useState<string>('');
    //  const [payment_date2,setPaymentDate2]=useState<string>('');
    //  const [payment_date3,setPaymentDate3]=useState<string>('');
    //  const [payment_date4,setPaymentDate4]=useState<string>('');
    //  const [payment_date5,setPaymentDate5]=useState<string>('');
    //  const [payment_date6,setPaymentDate6]=useState<string>('');

    //  const [installment_date1,setInstallmentDate1]=useState<string>('');
    //  const [installment_date2,setInstallmentDate2]=useState<string>('');
    //  const [installment_date3,setInstallmentDate3]=useState<string>('');
    //  const [installment_date4,setInstallmentDate4]=useState<string>('');
    //  const [installment_date5,setInstallmentDate5]=useState<string>('');


    //  const [installment_no_of_days1,setInstallmentNoOfDays1]=useState<number>(0)
    //  const [installment_no_of_days2,setInstallmentNoOfDays2]=useState<number>(0)
    //  const [installment_no_of_days3,setInstallmentNoOfDays3]=useState<number>(0)
    //  const [installment_no_of_days4,setInstallmentNoOfDays4]=useState<number>(0)
    //  const [installment_no_of_days5,setInstallmentNoOfDays5]=useState<number>(0)
    //  const [installment_no_of_days6,setInstallmentNoOfDays6]=useState<number>(0)
    
    //  const [final_trigger1,setFinalTrigger1]=useState<number>(0);
    //  const [final_trigger2,setFinalTrigger2]=useState<number>(0);
    //  const [final_trigger3,setFinalTrigger3]=useState<number>(0);
    //  const [final_trigger4,setFinalTrigger4]=useState<number>(0);
    //  const [final_trigger5,setFinalTrigger5]=useState<number>(0);
    //  const [final_trigger6,setFinalTrigger6]=useState<number>(0);
     
     
    //  const [final_installment1,setFinalInstallment1]=useState<number>(0);
    //  const [final_installment2,setFinalInstallment2]=useState<number>(0);
    //  const [final_installment3,setFinalInstallment3]=useState<number>(0);
    //  const [final_installment4,setFinalInstallment4]=useState<number>(0);
    //  const [final_installment5,setFinalInstallment5]=useState<number>(0);
    //  const [final_installment6,setFinalInstallment6]=useState<number>(0);

    // const [timefunction1,setFunctionalTime1]=useState<number>(0);
    // const [timefunction2,setFunctionalTime2]=useState<number>(0);
    // const [timefunction3,setFunctionalTime3]=useState<number>(0);
    // const [timefunction4,setFunctionalTime4]=useState<number>(0);
    // const [timefunction5,setFunctionalTime5]=useState<number>(0);

     const [personaldetails,setPersonalDetails]=useState(false);
     const [productdetails,setProductDetails]=useState(false);
     const [paymentDetails,setPaymentDetails]=useState(false);
    // const [created_by, setCreatedBy] = useState("");
    // const [updated_by,setUpdatedBy]=useState('');
    useEffect(() => {
// setLoading(true)
// server.get(`/get_installments/${userid}`)
// .then((response)=>{
    
//     setInstallment1(response.data.user.installment1)
   
//     setInstallment2(response.data.user.installment2)
//     setInstallment3(response.data.user.installment3)
//     setInstallment4(response.data.user.installment4)
//     setInstallmentDiscount1(response.data.user.installmentdiscount1)
    
//     setInstallmentDiscount2(response.data.user.installmentdiscount2)
//     setInstallmentDiscount3(response.data.user.installmentdiscount3)
//     setInstallmentDiscount4(response.data.user.installmentdiscount4)
//     setInstallmentDiscount5(response.data.user.installmentdiscount5)
//     setInstitutionalDiscount1(response.data.user.idiscount1)
//     setInstitutionalDiscount2(response.data.user.idiscount2)
//     setInstitutionalDiscount3(response.data.user.idiscount3)
//     setInstitutionalDiscount4(response.data.user.idiscount4)
//     setPayment1(response.data.user.payment1)
//     setPayment2(response.data.user.payment2)
//     setPayment3(response.data.user.payment3)
//     setPaymentDate1(response.data.user.payment_date1)
//     setPaymentDate2(response.data.user.payment_date2)
//     setPaymentDate3(response.data.user.payment_date3)
//     setPaymentDate4(response.data.user.payment_date4)
//     setPaymentNoOfDays1(response.data.user.no_of_days1)
//     setPaymentNoOfDays2(response.data.user.no_of_days2)
//     setPaymentNoOfDays3(response.data.user.no_of_days3)
//     setPaymentNoOfDays4(response.data.user.no_of_days4)
//     setFinalTrigger1(response.data.user.final_trigger1)
//     setFinalTrigger2(response.data.user.final_trigger2)
//     setFinalTrigger3(response.data.user.final_trigger3)
//     setFinalTrigger4(response.data.user.final_trigger4)
//     setFinalTrigger5(response.data.user.final_trigger5)
//     setFinalInstallment1(response.data.user.finalinstallment1)
//     setFinalInstallment2(response.data.user.finalinstallment2)
//     setFinalInstallment3(response.data.user.finalinstallment3)
//     setFinalInstallment4(response.data.user.finalinstallment4)
//     setFinalInstallment5(response.data.user.finalinstallment5)
//     setInstallmentDate1(response.data.user.installment_date1)
//     setInstallmentDate2(response.data.user.installment_date2)
//     setInstallmentDate3(response.data.user.installment_date3)
//     setInstallmentDate4(response.data.user.installment_date4)
//     setInstallmentDate5(response.data.user.installment_date5)
//     setFunctionalTime1(response.data.user.timefunction1)
//     setFunctionalTime2(response.data.user.timefunction2)
//     setFunctionalTime3(response.data.user.timefunction3)
//     setFunctionalTime4(response.data.user.timefunction4)
  
//     setInstallmentNoOfDays2(response.data.user.no_of_days2)
//     setInstallmentNoOfDays3(response.data.user.no_of_days3)
//     setInstallmentNoOfDays4(response.data.user.no_of_days4)
    
    

// // setLoading(false)
    
// })
//         // setLoading(true)
        // server.get(`/get_projects`)
        // .then((response)=>{
        //   setProjects(response.data.projects)
        //   // setLoading(false)
        // })
    //     server.get(`/get_installment_types`)
    // .then((response)=>{
    //   // setLoading(true)
    //     setInstalltypes(response.data.installment_types)
    //     // setLoading(false)

    // })
    // setLoading(true)
//     server.get(`/get_services`)
//     .then((response)=>{
        
//         setServices(response.data.services)
//         // setLoading(false)
// })

// setLoading(true)
    // server.get(`/operation_user_view/${userid}`)
    //   .then((response)=>{
    //       // setfirstName(response.data.user.first_name);
    //       // setlastName(response.data.user.last_name);
    //       // setPrimaryCountryCode(response.data.user.primary_contact_country_code);
    //       // setPrimaryContact(response.data.user.primary_contact);
    //       // setAlternateCountryCode(response.data.user.alernate_country_code);
    //       // setAlternateContact(response.data.user.alternate_phone_number);
    //       // setEmail(response.data.user.user_email);
    //       // setProjectId(response.data.user.project_id);
    //       // setProductId(response.data.user.product_id);
    //       // localStorage.setItem("product_id",response.data.user.product_id);
    //       // setDateOfRegistration(response.data.user.date_of_registration);
    //       // setStage(response.data.user.stage_id);
    //       // setDiscountedAmount(response.data.user.discounted_amount);
          
    //       // setProductAmount(response.data.user.product_amount);
    //       // setInstallTypeId(response.data.user.installment_type);
    //       //  setNoOfInstallments(response.data.user.noofintallments);
         
    //       // setCounsellorNote(response.data.user.counsellor_note);
    //       // setProductPrice(response.data.user.product_price);
    //       // setProjectName(response.data.user.project_name);
    //       // setProductName(response.data.user.product_name);
    //       // setStageName(response.data.user.stage_name);
    //       // setInstallmentTypeName(response.data.user.installment_type_name);
    //       // setPassword(response.data.user.last_password);
    //       // setSubscribedServices(response.data.user.services_offered);
    //       // setContractFile(response.data.user.contract_file);
    //       // setCreatedBy(response.data.user.created_by);
    //       // setUpdatedBy(response.data.user.updated_by);

    //     //  setLoading(false)
         
          
    // })
       
      }, []);

      
      // const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
      //   const fileList = e.target.files;
    
      //   if (!fileList) return;
        
      //   setFile(fileList[0]);
      // };
      // const setProject=(id:number)=>
      // {
      //     setProjectId(id);
      //     setLoading(true)
      //     server.get(`/get_product/${id}`)
      //     .then((response)=>{
              
      //         setProducts(response.data.product)
      //         setLoading(false)
              
              
      //     })
      // }
      // const setStage=(id:number)=>
      // {
        
        
      //   setProductId(id);
      //   // setLoading(true)
      //     server.get(`/get_stage/${id}`)
      //     .then((response)=>{
              
      //       setStages(response.data.stage);
      //         // setLoading(false)
      //     })
      //     // setLoading(true)
      //     // server.get(`/product/${id}`)
      //     // .then((response)=>{
              
      //     //   // setProductPrice(response.data.product.product_price)
      //     //   // setLoading(false)
              
      //     // })
      // }

      // const calNoofInstallments=(id:number)=>
      // {
      //   setLoading(true)
      //   server.get(`/installment_type/${id}`)
      //   .then((response)=>{
      //       // setNoOfInstallments(response.data.installments.no_of_installments)
      //       setLoading(false)
      //   })
      // }
    const installment_types=[];
      for(let i=0;i<noofintallments;i++)
      {
        
        installment_types.push(<div className='col-md-3'>
            <label>Installment{i+1}</label>
                        
            <input className='form-control' type="number" id={"installment"+(i+1)} defaultValue={i+1==1?installment1:i+1==2?installment2:i+1?installment3:i+1?installment4:i+1==5?installment5:i+6?installmen6:""} name={"installment"+(i+1)} />
        </div>)
      }
      
     const addUserData=async (e: React.FormEvent<HTMLFormElement>)=>
     {
        e.preventDefault();
        let form= document.getElementById('adduserdataform') as HTMLFormElement;
        const formData = new FormData(form);
        
        
    
        // setLoading(true)
        server.post(`/operationuser/${userid}`,formData,{
            headers: {
              'Content-Type': 'multipart/form-data',
            }})
            .then((response)=>{
                if(response.data.code===200)
                {
                  // setLoading(false)
                    toast(response.data.message);
                   
                }else if(response.data.code===204){
                  // setLoading(false) 
                  toast(response.data.message);
                   
                }
            })
     } 



    //  var service_array=[]; 
    //  for (var i=0;i<services.length;i++){
    //     if(subscribed_services[i+1].id===services[i+1].id){
    //         service_array.push(<div>{subscribed_services[i+1].service_name}</div>)
    //     }
    //  }

 var services_list=subscribed_services.split("||");
    //  console.log(services_list);
 var service_array=[];  
//  for(var i=0;i<stages.length;i++){
//     let services_all=services_list[i].split(',')
        
//     service_array[i]=<div className='col-md-3'>
//        <h3> {stages[i].stage_name}</h3>
//        {services_all.map((service,index)=>(
//         <div>{service}</div>   
//        ))}
//     </div>
//  }

 for (var i=0;i<stages.length;i++){
    let services_list_array= services_list[i].split(',')
    // console.log(services_list_array)
    // for(var j=0;j<services_list_array.length;j++){
    // service_array.push(
    
    //     <div className='col-md-4'>{services_list_array[j]}</div>
    // )}
 }
     
 if (loading) {
  return <Loader />;
} 

  return (
   
    <div className='container-fluid jumbotron lightgray'>
    
    <div className='px-5 py-3 mt-3 m-auto'>
 <div id="accordion">
  <div className="card">
    <div className="card-header" id="headingOne">
      <h5 className="mb-0">
        <button className="btn nav-link text-left text-white w-100" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Personal Details
      </button>
      </h5>
    </div>
    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
      <div className="card-body">
    
    <div className='row my-3'>
        <div className='col-md-6 text-left'><label>First Name</label></div>
        <div className='col-md-6 text-left'>{first_name}</div>
    </div>
    <div className='row my-3'>
        <div className='col-md-6 text-left'><label>Last Name</label></div>
        <div className='col-md-6 text-left'>{last_name}</div>
    </div>
    <div className='row my-3'>
        <div className='col-md-6 text-left'><label>Primary Contact Number</label></div>
        <div className='col-md-6 text-left'>+{primary_country_code}-{primary_contact}</div>
    </div>
    <div className='row my-3'>
  {alernate_country_code && alternatecontact ? (
    <>
      <div className='col-md-6 text-left'><label>Alternate Contact Number</label></div>
      <div className='col-md-6 text-left'>+{alernate_country_code}-{alternatecontact}</div>
    </>
  ) : null}
</div>
    {/* <div className='row my-3'>
        <div className='col-md-6 text-left'><label>Alternate Contact Number</label></div>
        <div className='col-md-6 text-left'>+{alernate_country_code}-{alternatecontact}</div>
    </div> */}
    
    <div className='row my-3'>
        <div className='col-md-6 text-left'><label>Email</label></div>
        <div className='col-md-6 text-left'>{user_email}</div>
    </div>
    <div className='row my-3'>
        <div className='col-md-6 text-left'><label>Password</label></div>
        <div className='col-md-6 text-left'>{password}</div>
    </div> 
    {role==="1"?<div className='row my-3'>
        <div className='col-md-6 text-left'><label>Added By</label></div>
        <div className='col-md-6 text-left'>{ created_by}</div>
    </div>:""}
    {role==="1"?<div className='row my-3'>
        <div className='col-md-6 text-left'><label>Updated By</label></div>
        <div className='col-md-6 text-left'>{updated_by}</div>
    </div>:""}
    
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingTwo">
      <h5 className="mb-0">
        <button className="btn nav-link text-left collapsed w-100 text-white" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
         Product Details 
        </button>
      </h5>
    </div>
    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div className="card-body">
     
    <div className='row my-3'>
        <div className='col-md-6 text-left'><label>Project</label></div>
        <div className='col-md-6 text-left'>{project_name}</div>
    </div> <div className='row my-3'>
        <div className='col-md-6 text-left'><label>Product</label></div>
        <div className='col-md-6 text-left'>{product_name}</div>
    </div>
    <div className='row my-3'>
        <div className='col-md-6 text-left'><label>Stage</label></div>
        <div className='col-md-6 text-left'>{stage_name}</div>
    </div>
    <div className='row my-3'>
        <div className='col-md-6 text-left'><label>Date of Registration</label></div>
        <div className='col-md-6 text-left'>{moment(date_of_registration).format('Do MMMM YYYY') }</div>
    </div>
    
      </div>
    </div>
  </div>
  <div className="card">
    <div className="card-header" id="headingThree">
      <h5 className="mb-0">
        <button className="btn nav-link text-left collapsed w-100 text-white" data-toggle="collapse" data-target="#collapseThree" aria-expanded={paymentDetails} aria-controls="collapseThree" onClick={()=>setPaymentDetails(!personaldetails)}>
          Payment Details
          
        </button>
      </h5>
    </div>
    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div className="card-body">
     
     
     
    

    {installment1!==null ||installment1!==undefined ?<div className='row my-3'>
    
        <div className='col-md-12 text-left'><h3 className='text-danger'>Installment 1</h3></div>
        <div className='col-md-6 text-left'>Amount for Installment 1</div>
        <div className='col-md-6 text-left'>{installment1}</div>

        <div className='col-md-6 text-left'>Discount on Installment 1</div>
        <div className='col-md-6 text-left'>{installmentdiscount1}</div>

        <div className='col-md-6 text-left'>Institutional Discount</div>
        <div className='col-md-6 text-left'>{institutionaldiscount1}</div>

        <div className='col-md-6 text-left'>Final Price For Installment 1</div>
        <div className='col-md-6 text-left'>{finalinstallment1}</div>

        <div className='col-md-6 text-left'>Recieved Installment 1</div>
        <div className='col-md-6 text-left'>{payment1}</div>

        <div className='col-md-6 text-left'> Received Payment Date</div>
        <div className='col-md-6 text-left'>{moment(payment_date1).format('Do MMMM YYYY') }</div>
       
        {payment2!==null?<div className='col-md-6 text-left'> Balance Payable </div>:""}
        <div className='col-md-6 text-left'>{payment2!==null?payment2:""}</div>
        {payment_date2!==null?<div className='col-md-6 text-left'> Balance Payable Date</div>:""}
        <div className='col-md-6 text-left'> {payment_date2!==null?moment(payment_date2).format('Do MMMM YYYY') :""}</div>
        </div> :""}
        {noofintallments>=2 ?<div className='row my-3'>
        <div className='col-md-12 text-left'><h3 className='text-danger'>Installment 2</h3></div>
        <div className='col-md-6'>Amount for Installment2</div>
        <div className='col-md-6 text-left'>{installment2}</div>
        <div className='col-md-6 text-left'>Applied Discount</div>
        <div className='col-md-6 text-left'>{installmentdiscount2}</div>
        <div className='col-md-6 text-left'>Institutional Discount</div>
        <div className='col-md-6 text-left'>{institutionaldiscount2}</div>  
        <div className='col-md-6 text-left'>Final Amount</div>
        <div className='col-md-6 text-left'>{finalinstallment2}</div> 
        <div className='col-md-6 text-left'>Number of Days</div>
        <div className='col-md-6 text-left'>{installment_no_of_days2}</div>  
        {timefunction2!==null ||timefunction2!==0?<div className='col-md-6 text-left'>Time Function</div>:""}
        {timefunction2!==null ||timefunction2!==0? <div className='col-md-6 text-left'>{timefunction2===1?"Before":timefunction2===2?"After":""}</div>  :""}
        
        {final_trigger2!==null || final_trigger2!==0?<div className='col-md-6 text-left'>Final Trigger</div>:""}
        {final_trigger2!==null || final_trigger2!==0?<div className='col-md-6 text-left'>{final_trigger2===27?"Language Result Date":final_trigger2===28?"Plab1 Result Date":final_trigger2===29?"Plab2 Result Date":""}</div>  :""}
        {installment_date2===null || installment_date2===""?"":<div className='col-md-6 text-left'>Installment Date 2</div>}
        {installment_date2===null || installment_date2===""?"":<div className='col-md-6 text-left'>{moment(installment_date2).format('Do MMMM YYYY') }</div>  }
          
        </div> :""}

        {noofintallments>=3 ?<div className='row my-3'>
        <div className='col-md-12 text-left'><h3 className='text-danger'>Installment 3</h3></div>
        <div className='col-md-6'>Amount for Installment3</div>
        <div className='col-md-6 text-left'>{installment3}</div>
        <div className='col-md-6 text-left'>Applied Discount</div>
        <div className='col-md-6 text-left'>{installmentdiscount3}</div>
        <div className='col-md-6 text-left'>Institutional Discount</div>
        <div className='col-md-6 text-left'>{institutionaldiscount3}</div>  
        <div className='col-md-6 text-left'>Final Amount</div>
        <div className='col-md-6 text-left'>{finalinstallment3}</div> 
        <div className='col-md-6 text-left'>Number of Days</div>
        <div className='col-md-6 text-left'>{installment_no_of_days3}</div>  
        {timefunction3!==null ||timefunction3!==0?<div className='col-md-6 text-left'>Time Function</div>:""}
        {timefunction3!==null ||timefunction3!==0? <div className='col-md-6 text-left'>{timefunction3===1?"Before":timefunction3===2?"After":""}</div>  :""}
        
        {final_trigger3!==null || final_trigger3!==0?<div className='col-md-6 text-left'>Final Trigger</div>:""}
        {final_trigger3!==null || final_trigger3!==0?<div className='col-md-6 text-left'>{final_trigger3===27?"Language Result Date":final_trigger3===28?"Plab1 Result Date":final_trigger3===29?"Plab2 Result Date":""}</div>  :""}
        {installment_date3===null || installment_date3===""?"":<div className='col-md-6 text-left'>Installment Date 3</div>}
        {installment_date3===null || installment_date3===""?"":<div className='col-md-6 text-left'>{moment(installment_date3).format('Do MMMM YYYY')}</div>  }
          
        </div> :""}
        {noofintallments>=4 ?<div className='row my-3'>
        <div className='col-md-12 text-left'><h3 className='text-danger'>Installment 4</h3></div>
        <div className='col-md-6'>Amount for Installment4</div>
        <div className='col-md-6 text-left'>{installment4}</div>
        <div className='col-md-6 text-left'>Applied Discount</div>
        <div className='col-md-6 text-left'>{installmentdiscount4}</div>
        <div className='col-md-6 text-left'>Institutional Discount</div>
        <div className='col-md-6 text-left'>{institutionaldiscount4}</div>  
        <div className='col-md-6 text-left'>Final Amount</div>
        <div className='col-md-6 text-left'>{finalinstallment4}</div> 
        <div className='col-md-6 text-left'>Number of Days</div>
        <div className='col-md-6 text-left'>{installment_no_of_days4}</div>  
        {timefunction4!==null ||timefunction4!==0?<div className='col-md-6 text-left'>Time Function</div>:""}
        {timefunction4!==null ||timefunction4!==0? <div className='col-md-6 text-left'>{timefunction4===1?"Before":timefunction4===2?"After":""}</div>  :""}
        
        {final_trigger4!==null || final_trigger4!==0?<div className='col-md-6 text-left'>Final Trigger</div>:""}
        {final_trigger4!==null || final_trigger4!==0?<div className='col-md-6 text-left'>{final_trigger4===27?"Language Result Date":final_trigger4===28?"Plab1 Result Date":final_trigger4===29?"Plab2 Result Date":""}</div>  :""}
        {installment_date4===null || installment_date4===""?"":<div className='col-md-6 text-left'>Installment Date 4</div>}
        {installment_date4===null || installment_date4===""?"":<div className='col-md-6 text-left'>{moment(installment_date4).format('Do MMMM YYYY')}</div>  }
        
        </div> :""}
    <div className='row'>
    <div className='col-md-6'>Final Price</div>  
    <div className='col-md-6'>{finalinstallment1+finalinstallment2+finalinstallment3+finalinstallment4}</div>  
    </div>
        {contract_file!==null?<div className='row my-3'>
        <div className='col-md-6 text-left'><label>Contract </label></div>
        <div className='col-md-6 text-left'>
          {contract_file.endsWith('.pdf')?<Link className='text-left' target='_blank' to={contract_file}>View</Link>:<img src={contract_file} alt={'contract_file'} width="200px" height="200px"/>}
          </div>
    </div>:""}
    <div className='row my-3'>
        <div className='col-md-6 text-left'><label>Counsellor Note</label></div>
        <div className='col-md-6 text-left'>{counsellor_note}</div>
    </div>
      </div>
    </div>
  </div>
</div>

    
   
    
    {/* <h6 className='my-2 text-danger'>Services Subscribed</h6> */}
    {/* <hr /> */}
    <div className='row mt-3'>  
               {/* <h5 className='text-danger text-center my-5'>Services Offered</h5>   */}
                  
                       
                   {/* <div className='row'>   */}
                   
                   {/* {stages.map((stage,stageindex)=>(
                         
                         <div className='col-md-2'>
                           <h5 className='text-danger'>{stage.stage_name}</h5>
                                        {subscribed_services.map((service,index)=>(
                service.stage_id===stage.id?<div className='col-md-1'>
                 <span>{service.service_name}</span>
                
                </div>:""
      ))}
                    
                    </div>
                           ))}        */}
                 {/* {stages.map((stage,stageindex)=>(
                         
                         <div className='col-md-2'>
                           <h5 className='text-danger'>{stage.stage_name}</h5>
                           {services.map((service,index)=>(
                            <div>
                                        {subscribed_services.map((subservice,index)=>(
                service.id===subservice.id?<div className='col-md-1'>
                 <span>{subservice.service_name}</span>
                
                </div>:""
      ))}
         </div> 
      ))}
                    </div>
                           ))} */}
                   {/* </div>  */}
                   
                          

              {/* {services.map((service,index)=>(
                <div className='col-md-1'>
                 <span>{service.service_name}   </span><input  className='check' type="checkbox" name={"service"+(index+1)} id={"service"+(index+1)} value={service.id}  defaultChecked />
                 
                </div>
      ))} */}
      </div>
      <div className='row'>
    {/* {service_array} */}
    </div>
    {/* {service_array} */}
         
    </div>
        
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    
   
</div>
  )
}

export default ViewUser