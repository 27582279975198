import React from 'react'
import { Link } from 'react-router-dom'
interface Props{form_progress:number}
const UserProgressBar:React.FC<Props> = ({form_progress}) => {
  return (
    <div className='user_form_progress_status'>
    <div className='row rounded' style={{border:"1px solid black",padding:"3px"}}>
    <div className='col-md-2 py-2' style={form_progress >= 1 ? { backgroundColor: "#233974" } : {backgroundColor:"lightGray"}}></div>  
    <div className='col-md-2 py-2'style={form_progress >= 2 ? { backgroundColor: "#233974" } : {backgroundColor:"lightGray"}} ></div>
    <div className='col-md-1 py-2' style={form_progress >= 3 ? { backgroundColor: "#233974" } : {backgroundColor:"lightGray"}}></div>
    <div className='col-md-2 py-2' style={form_progress >= 4 ? { backgroundColor: "#233974" } : {backgroundColor:"lightGray"}}></div>
    <div className='col-md-1 py-2' style={form_progress >= 5 ? { backgroundColor: "#233974" } : {backgroundColor:"lightGray"}}></div>
    <div className='col-md-2 py-2' style={form_progress >= 6 ? { backgroundColor: "#233974" } : {backgroundColor:"lightGray"}}></div>
    <div className='col-md-2 py-2' style={form_progress === 7 ? { backgroundColor: "#233974" } : {backgroundColor:"lightGray"}}></div>
    </div>    
   <div className='row rounded'>
    <div className='col-md-2 text-center' style={{fontSize:"11px"}}>{form_progress >= 1 ?<Link className='text-center blue-color nav-link' to="/user_profile"  style={{fontSize:"11px"}}>Personal Info</Link>:"Personal Info"}</div>  
    <div className='col-md-2 text-center' style={{fontSize:"11px"}} >{form_progress >= 2 ?<Link className='text-center blue-color nav-link' to="/user_address" style={{fontSize:"11px"}}>Address Info</Link>:"Address Info"}</div>
    <div className='col-md-1 text-center' style={{fontSize:"11px"}} >{form_progress >= 3 ?<Link className='text-center blue-color nav-link' to="/pmq_details" style={{fontSize:"11px"}}>PMQ Details</Link>:"PMQ Details"}</div>
    <div className='col-md-2 text-center' style={{fontSize:"11px"}} >{form_progress >= 4 ?<Link className='text-center blue-color nav-link' to="/language_test" style={{fontSize:"11px"}}>Language</Link>:"Language"}</div>
    <div className='col-md-1 text-center' style={{fontSize:"11px"}} >{form_progress >= 5 ?<Link className='text-center blue-color nav-link' to="/gmc_and_intenship" style={{fontSize:"11px"}}>GMC</Link>:"GMC"}</div>
    <div className='col-md-2 text-center' style={{fontSize:"11px"}} >{form_progress >= 6 ?<Link className='text-center blue-color nav-link' to="/employment_and_speciality" style={{fontSize:"11px"}}>Employment</Link>:"Employment"}</div>
    <div className='col-md-2 text-center' style={{fontSize:"11px"}} >{form_progress >= 7 ?<Link className='text-center blue-color nav-link' to="/documents" style={{fontSize:"11px"}}>Documents</Link>:"Documents"}</div>
   </div>
   </div>
  )
}

export default UserProgressBar