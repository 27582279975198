import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { welcomekittype } from '../../@types/welcomekitdata';
import validator from 'validator';

import { message } from 'antd';
import server from '../Utils';
import Loader from './Loader';
const AddWelcomeKitType = () => {
  const [welcome_kit_type,setWelcomeKitType]=useState<number>(0);
  const [welcome_kit_name,setWelcomeKitName]=useState<string>('');
  const [welcome_kit_price,setWelcomeKitPrice]=useState<number>();
  const [projects,setProjects]=useState<Array<ProjectData>>([]);
  const [project_id,setProjectId]=useState<number>(0);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
    let id= +projectid
    setProjectId(id)
  }, []);

const addWelcomeKitType=(e:React.FormEvent<HTMLFormElement>)=>{
e.preventDefault();

    if(welcome_kit_type===0){
        toast('Select welcome kit type');
    }else if(welcome_kit_name===""){
        toast('Enter welcome kit Name');
    }else if(welcome_kit_price===0||welcome_kit_price===undefined || welcome_kit_price===null){
        toast('Enter welcome kit price');
    }else {
        var form = document.getElementById('addwelcomekitform') as HTMLFormElement;
        var formData = new FormData(form);
        server.post(`/add_welcomekit_product`,formData)
        .then((response)=>{
            if(response.data.code===200){
                toast(response.data.message)
                setTimeout(function () { window.history.back(); }, 1000);
            }else if(response.data.code===204){
                toast(response.data.message)
            }else{
                toast(response.data.message)
            }
        })
    }
}

  
if (loading) {
    return <Loader />;
  } 
    
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}


    <h4 className='text-danger text-center'>Add Welcome Kits</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>
   <h5 className='text-danger text-center'>Welcome Kits</h5>
   
    <div className='col-md-12 px-5'>
        <form id="addwelcomekitform" onSubmit={addWelcomeKitType}  encType='multipart/form-data'  className='w-100'>
           <div className='row'>
           {/* <div className='col-md-3'>
                    <label >Project</label>
                    <select className='form-control' name='project_id' id="project_id" onChange={(e)=>setProjectId(parseInt(e.target.value))}>
                        <option value={0}>Please select the program</option>
                        {projects.map((project,index)=>(
                            <option value={project.id}>{project.project_name}</option>))}
                    </select>
                </div> */}
                 <input type="hidden" name="project_id"  id="project_id" value={project_id} />
               <div className='col-md-3'>
                <label htmlFor='name'>Welcome Kit Type:</label><br/>
                <select className='form-control' id="welcome_kit_type" name="welcome_kit_type" value={welcome_kit_type} onChange={(e)=>setWelcomeKitType(parseInt(e.target.value))}>
                    <option value="0">Select  Welcome kit type</option> 
                    <option value="1">Offline</option>
                    <option value="2">Online</option>
                </select>
               </div>
               <div className='col-md-3'>
               <label htmlFor='name'>Welcome kit Name:</label><br/>
               <input className='form-control' type="text" name="welcome_kit_name" id="welcome_kit_name"  value={welcome_kit_name}  onChange={(e)=>setWelcomeKitName(e.target.value)} placeholder='Welcome Kit Name'  />
               </div> 
               <div className='col-md-3'>
               <label htmlFor='name'>Welcome kit price:</label><br/>
               <input className='form-control' type="number" name="welcome_kit_price" id="welcome_kit_price"  value={welcome_kit_price} onChange={(e)=>setWelcomeKitPrice(parseInt(e.target.value))} placeholder='Welcome Kit Price' />
               </div> 
           </div>
          

            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Add Welcome Kit </button></div>
        </form>
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default AddWelcomeKitType