import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { travelandaccomdationtype } from '../../@types/travelandaccomdationtype';
import server from '../Utils';
import { cabType } from '../../@types/cabtype';
import Loader from './Loader'; // import the Loader component

const Cab = () => {
  const {id}= useParams();
  const [records,setRecords]=useState<Array<cabType>>([])
  const [loading,setLoading]=useState<boolean>(false)
  const [user_agreed,setUserAgreed]=useState<number>(0);

  useEffect(() => {
    let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
    let project_id = +projectid;
    
    let productid:string=JSON.parse(localStorage.getItem("product_id")||'{}');
    let product_id = +productid;
    server.get(`/operationuser/${id}`)
    .then((response)=>{
     
       if(response.data.user.user_agreement_flag===1){
         setUserAgreed(1)
       }else{
         setUserAgreed(0)
       }
    })
   server.get(`/cabs/${id}`)
   .then((response)=>{
 
    setRecords(response.data.cabs)

   })
 },[id]);

 if (loading) {
  return <Loader />;
}
 return (
  <div>
    {user_agreed === 1 ? <Link to={`/add_cab/${id}`} className='btn btn-info text-white float-right mr-5 pr-5' >Add Cab </Link>:<p className='text-danger'>Kindly ask the doctor to fill their details in their portal And Please Book the Plab2 Test for this client after client completes the details in thier portal</p>}
    {/* <Link to={`/add_cab/${id}`} className='btn btn-info text-white float-right mr-5 pr-5' >Add Cab </Link> */}
    <br/>
  <div className="mt-4">
  

    {records.map((record, index) => (
    <div className='jumbotron'>  
      <div key={record.id} className={`row ${index % 3 === 0 ? 'mb-4' : ''}` }>
        <Link to={`/edit_cab/${record.id}`} className='text-danger mr-5 pr-5' >Edit</Link> 
        <div className='col-md-6'>
          <h2 className='mb-4'>Flight Information {index+1}</h2>
          <div className="row">
            <div className="col-md-6">
              <label>Flight Number:</label>{' '}
              <span className='label label-default'>{record.flightNumber}</span>
            </div>
            <div className="col-md-6">
              <label>Arrival Date:</label>{' '}
              <span className='label label-default'>
                {moment(record.arrivalDate).format('YYYY-MM-DD')}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label>Arrival Time:</label>{' '}
              <span className='label label-default'>
               
                {moment(record.arrivalTime, 'HH:mm:ss').format('hh:mm A')}
              </span>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <h2 className='mb-4'>Vendor Information </h2>
          <div className="row">
            <div className="col-md-6">
              <label>Vendor ID:</label>{' '}
              <span className='label label-default'>{record.vendor_name}</span>
            </div>
            <div className="col-md-6">
              <label>Send Pickup Email:</label>{' '}
              <span className='label label-default'>
                {record.sendPickupEmail === 1 ? 'Yes' : 'No'}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Pickup Location:</label>{' '}
              <span className='label label-default'>{record.pickupLocation}</span>
            </div>
            <div className="col-md-6">
              <label>Pickup Pin Code:</label>{' '}
              <span className='label label-default'>{record.pickupPinCode}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Drop Location:</label>{' '}
              <span className='label label-default'>{record.dropLocation}</span>
            </div>
            <div className="col-md-6">
              <label>Drop Pin Code:</label>{' '}
              <span className='label label-default'>{record.dropPinCode}</span>
            </div>
          </div>
        </div>
      </div>
      </div>
    ))}
  </div>
  </div>
);


}

export default Cab