import React, { useEffect, useState } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import moment from 'moment';
import { Link } from 'react-router-dom';
import server from '../Utils';
import Loader from './Loader';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GridApi, ColumnApi } from 'ag-grid-community';

import { trainingtype } from '../../@types/trainingtype';

const OnboardingList: React.FC = () => {
  const [onboardings, setOnboardings] = useState<Array<trainingtype>>([]);
  const [filteredOnboardings, setFilteredOnboardings] = useState<Array<trainingtype>>([]);
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const [searchText, setSearchText] = useState<string>('');

  let projectid: string = JSON.parse(localStorage.getItem('project_id') || '{}');
  let id = +projectid;

  useEffect(() => {
    setLoading(true);
    server
      .get(`/onboarding_list/${id}`)
      .then((response) => {
        setOnboardings(response.data.onboarding_list);
        setFilteredOnboardings(response.data.onboarding_list); // Initialize filtered data
      })
      .catch((error) => {
        console.error('Error fetching onboarding list:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const onGridReady = (params: { api: GridApi, columnApi: ColumnApi }) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const parseDate = (dateStr: string): string | null => {
    const dateFormats = [
      'YYYY/MM/DD',
      'YYYY-MM-DD',
      'DD/MM/YYYY',
      'MM/DD/YYYY',
      'Do MMMM YYYY', // For example: 1st January 2024
      'D MMMM YYYY',  // For example: 1 January 2024
      'YYYY-MM-DDTHH:mm:ss', // ISO 8601 format with time
      'YYYY-MM-DDTHH:mm:ss.SSS', // ISO 8601 format with milliseconds
      'DD-MM-YYYY', // For example: 14-02-2014
      'D-M-YYYY', // For example: 4-2-2024
      'D/M/YYYY', // For example: 4/2/2024
    ];
  
    for (const format of dateFormats) {
      const parsedDate = moment(dateStr, format, true);
      if (parsedDate.isValid()) {
        return parsedDate.toISOString().split('T')[0]; // Convert to yyyy-mm-dd
      }
    }
  
    return null;
  };

  const isValidDate = (dateInput: string): boolean => {
    const dateFormats = [
      'YYYY/MM/DD',
      'YYYY-MM-DD',
      'DD/MM/YYYY',
      'MM/DD/YYYY',
      'Do MMMM YYYY', // For example: 1st January 2024
      'D MMMM YYYY',  // For example: 1 January 2024
      'YYYY-MM-DDTHH:mm:ss', // ISO 8601 format with time
      'YYYY-MM-DDTHH:mm:ss.SSS', // ISO 8601 format with milliseconds
      'DD-MM-YYYY', // For example: 14-02-2014
      'D-M-YYYY', // For example: 4-2-2024
      'D/M/YYYY', // For example: 4/2/2024
    ];
  
    for (const format of dateFormats) {
      if (moment(dateInput, format, true).isValid()) {
        return true;
      }
    }
  
    return false; // No valid format matched
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
  
    const filteredData = onboardings.filter(onboarding => {
      for (const key in onboarding) {
        if (Object.prototype.hasOwnProperty.call(onboarding, key)) {
          const val = onboarding[key as keyof trainingtype]; // Use keyof to access typed properties
  
          if (typeof val === 'string' && isValidDate(val)) {
            const parsedDate = parseDate(val);
            if (parsedDate !== null && parsedDate.includes(value)) {
              return true;
            }
          } else if (typeof val === 'string') {
            if (val.toLowerCase().includes(value)) {
              return true;
            }
          } else if (typeof val === 'number') {
            if (val.toString().includes(value)) {
              return true;
            }
          }
        }
      }
      return false;
    });
  
    setFilteredOnboardings(filteredData);
    gridApi?.setRowData(filteredData);
  };

  const resetFilters = () => {
    setFilteredOnboardings(onboardings); // Reset filtered data to original dataset
    gridApi?.setFilterModel(null); // Clear all applied filters
  };

  const dateComparator = (filterLocalDateAtMidnight: Date, cellValue: any) => {
    if (!cellValue) return 0;

    const cellDate = moment(cellValue).startOf('day').toDate();

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }

    return 1;
  };

  const getColumnDefs = () => [
    {
      headerName: 'SL No',
      valueGetter: "node.rowIndex + 1",
      sortable: false,
      filter: false,
    },
    {
      headerName: 'First Name',
      field: 'username',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Last Name',
      field: 'userlastname',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Welcome Call By',
      field: 'first_name',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Dispatch Status',
      field: 'dispatch_status',
      sortable: true,
      filter: true,
      valueFormatter: (params: { value: number | null }) =>
        params.value === null ? 'Not Initiated' : params.value === 1 ? 'Not Sent' : 'Sent for delivery',
    },
    {
      headerName: 'Actions',
      filter:false,
      cellRenderer: (params: { data: { user_id: number; id: number } }) => (
        <Link
          className='text-dark text-center'
          onClick={() => localStorage.setItem('user_id', params.data.user_id.toString())}
          to={`/onboarding_edit/${params.data.user_id}`}
        >
          <i className='fa fa-edit text-success'></i>
        </Link>
      ),
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='container-fluid'>
      <div className='d-flex'>
        <SideBar />
        <div className='headerbar'>
          <Header />
          <h4 className='text-danger text-center'>Onboarding Lists</h4>
          <hr className='bg-danger px-1' />
          <div className='row mt-5 px-2'>
            <div className='col-md-4'>
              {/* <input
                type='text'
                className='form-control'
                placeholder='Search...'
                value={searchText}
                onChange={handleSearchInputChange}
              /> */}
            </div>
            <div className='col-md-8 text-right'>
              <Link
                to={"/onboarding_multiple"}
                className='btn btn-info float-right mr-2 text-white mb-3'
                role="button"
              >
                +
              </Link>
              <button
                className='btn btn-secondary float-right mr-2 text-white mb-3'
                onClick={resetFilters}
              >
                Reset Filters
              </button>
            </div>
            <div className='col-md-12 ag-theme-alpine' style={{ height: '500px' }}>
              <AgGridReact
                onGridReady={onGridReady}
                columnDefs={getColumnDefs()}
                rowData={filteredOnboardings}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={50}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingList;
