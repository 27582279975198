import React, { useState, useEffect } from 'react';
import { useParams, Link, redirect } from 'react-router-dom';
import moment from 'moment';
import server from '../Utils';
import Loader from './Loader';
import { training_details } from '../../@types/training_details';
import UserSideBar from '../../pages/UserSideBar/UserSideBar';
import { FaRegQuestionCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { coursedatatype } from '../../@types/coursesdatatype';
import { testbookingtype } from '../../@types/testbookingtype';

const UserCoursesAllocatedDetails = () => {
    const [user_agreed, setUserAgreed] = useState<number>(0);
    const [project_id,setProjectId]=useState<number>(0);
    const [user_exist,setUserExists]=useState<boolean>(false);
    const [training_exists,setTrainingExists]=useState<boolean>(false);
    const [test_booking_exists,setTestBookingExist]=useState<boolean>(false);
    const [test_bookings,setTestBooking]=useState<Array<testbookingtype>>([]);
    const [registration_exist,setRegistrationExist]=useState<boolean>(false);
    const [training_and_subscription_exist,setTrainingAndSubscriptionExist]=useState<boolean>(false);
    const [courses_allocated_details,setCoursesAllocatedDetails]=useState<Array<coursedatatype>>([])
    const [loading, setLoading] = useState(false);
    // const { id } = useParams();
    const [first_name,setfirstName]=useState<string>("");
    const [last_name,setlastName]=useState<string>('');
    const [user_email,setUserEmail]=useState<string>('')
   let id= localStorage.getItem('ClientID');
   var loggedIn=localStorage.getItem('loggedIn');
    useEffect(() => {
        if(loggedIn!=="true")
  {
    window.location.href="/userlogin";
  }
        server.get(`/operationuser/${id}`).then((response) => {
            setfirstName(response.data.user.first_name)
            setlastName(response.data.user.last_name)
            setUserEmail(response.data.user.user_email)
            
            if (response.data.user.user_agreement_flag === 1) {
                setUserAgreed(1);

            } else {
                setUserAgreed(2);
            }
            if(response.data.user.user_agreement_flag===0 || response.data.user.user_agreement_flag===null){
                Swal.fire({
                  title: "Profile Incomplete",
                  text: "You won't be able to start services until profile is complete!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#233974",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Complete Profile"
                  
                }).then((result) => {
                 
                  if (result.isConfirmed) {
                    
                    
                    redirect("/user_profile");
                    window.location.href="/user_profile"
                  }
                });
              }
        });
        server.get(`/courses_allocated_details/${id}`)
        .then((response)=>{
          setCoursesAllocatedDetails(response.data.courses_allocated)
        })
        
    }, [id]);

if(loading===true){
    return <Loader/>
}
    return (

        <div className='container-fluid desktop_container' >
    <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name}/>
    <div className='horizontal-menu' style={{background:"transparent"}}>

      <div className='container-fluid mt-4 userpath_right_pad user_foarm_board'>
      <div className='d-flex mb-2 mt-3 prog-info'>
        <div className='w-75 text-left'>
            <h1><b className='blue-color'>Courses Allocated</b></h1>
        </div>
        <div className='w-25 text-right blue-color fw-bold' style={{ fontSize: "29px" }}>
            <FaRegQuestionCircle className='mb-1' />
            <span className='support_hide'> Support</span>
        </div>
    </div>
    <div className='mt-4'>
    {user_agreed===2?<p className="text-danger">
                            <Link to="/user_profile">Please Complete Profile here</Link>
                        </p>:""}
        { user_agreed === 0 ? (
                        <p className="text-danger">
                            {/* <Link to="/user_profile">Please Complete Profile here</Link> */}
                        </p>
                    ) : user_agreed === 1 && courses_allocated_details.length > 0 ? (
                        <>
                         
                         {courses_allocated_details.map((courses_allocated_detail,index)=>(
        <div className='my-4 box-shadow p-4'style={{borderRadius:"20px"}} >
        <div className='row'><div className='col-md-12'><h4 className="blue-color fw-bold">{courses_allocated_detail.service_name}</h4><hr/></div>
        
        </div>
        
         {/* <div className='row my-2'>
         <div className="col-md-4"><label className="blue-color">Course Name</label></div>
         <div className="col-md-4"></div>
         <div className="col-md-4"></div>
         </div> */}
         <div className='row my-2'>
         <div className="col-md-4"><label className="blue-color">Mode of Delivery</label></div>
         <div className="col-md-4">{courses_allocated_detail.mode_of_delivery===1?"Online":courses_allocated_detail.mode_of_delivery===2?"Offline":""}</div>
         <div className="col-md-4"></div>
         </div>
        {courses_allocated_detail.course_venue!==null?<div className='row my-2'>
         <div className="col-md-4"><label className="blue-color">Course Venue</label></div>
         <div className="col-md-4">{courses_allocated_detail.course_venue}</div>
         <div className="col-md-4"></div>
         </div>:""}
         <div className='row my-2'>
         <div className="col-md-4"><label className="blue-color">Course Allocated Date</label></div>
         <div className="col-md-4">{moment(courses_allocated_detail.course_allocated_date).format('Do MMMM YYYY')}</div>
         <div className="col-md-4"></div>
         </div>

        {courses_allocated_detail.mode_of_delivery===1?<div className='row my-2'>
         <div className="col-md-4"><label className="blue-color">Course Duration</label></div>
         <div className='col-md-4'>{courses_allocated_detail.course_duration} {courses_allocated_detail.course_period===1?"Days":courses_allocated_detail.course_period===2?"Weeks":courses_allocated_detail.course_period===3?"Months":courses_allocated_detail.course_period===4?"Year":""}</div>         <div className="col-md-4"></div>
         </div>:""}
         <div className='row my-2'>
         <div className="col-md-4"><label className="blue-color">Course Start Date</label></div>
         <div className="col-md-4">{moment(courses_allocated_detail.course_start_date).format('Do MMMM YYYY')}</div> 
         <div className="col-md-4"></div>
         </div>
         { courses_allocated_detail.course_allocated_end_date!==""?<div className='row my-2'>
         <div className="col-md-4"><label className="blue-color">Course End Date</label></div>
         <div className="col-md-4">{moment(courses_allocated_detail.course_allocated_end_date).format('Do MMMM YYYY')}</div>
         <div className="col-md-4"></div>
         </div>:""}
         </div>

      ))}
                        </>
                    ) : user_agreed === 1 && courses_allocated_details.length === 0 ? (
                        <div className="text-danger bold pl-3 pt-2" style={{ position: 'absolute', top: "12%" }}>
                            Hi there! You do not have any courses allocated at the moment, kindly contact <a style={{ display: 'content' }} className='text-left' href="tel:+919019925836">9019925836</a>
                        </div>
                    ) : null}
        </div>
        
        </div>
       
   

    </div>
  
</div>


        
    );
};

export default UserCoursesAllocatedDetails;
