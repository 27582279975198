import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import server from '../Utils';
import Loader from './Loader';

const Addinstalltypes = () => {

  const [products,setProducts]=useState<Array<ProductData>>([])
  const [projects,setProjects]=useState<Array<ProjectData>>([])
  const [project_id,setProjectId]=useState<number>(0);
  const [product_id,setProductId]=useState<number>(0);
  const [product_price,setProductPrice]=useState<number>(0);
  const [installmenttype,setInstallmentType]=useState<string>();
  const [no_of_installments,setNoOfInstallments]=useState<number>(0);
  const[installment1,setInstallment1]=useState<number>();
  const[installment2,setInstallment2]=useState<number>();
  const[installment3,setInstallment3]=useState<number>();
  const[installment4,setInstallment4]=useState<number>();
  const [error,setError]=useState<string>();
  const [installtypes,setInstalltypes]=useState<Array<InstallmentTypeData>>([])
 
  const [loading, setLoading] = useState(false);

  
  useEffect(() => {
    let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
    let id= +projectid
    setProjectId(id)
      server.get(`/get_projects`)
      .then((response)=>{
        setProjects(response.data.projects)

      })
      server.get(`/get_installment_types`)
    .then((response)=>{
       
        setInstalltypes(response.data.installment_types)

    })
    server.get(`/get_product/${id}`)
    .then((response)=>{
        
        setProducts(response.data.product)
     
        
    })
    }, []);
  
     
       
    
   const  addInstallType=(e:React.FormEvent<HTMLFormElement>)=>
    {
        e.preventDefault();
        
        // var regEx = /^[A-Za-z]+$/;
        var name:any = installmenttype;
        if(project_id===0)
        {
            setError("Please Select the project"); 
            toast(error);
        }
       else if(product_id===0)
       {
        setError("Please Select the product"); 
        toast(error);
       }else if(no_of_installments>4 || no_of_installments<1)
       {
        setError("Installments can't be more than 4 and less than 1"); 
        toast(error);
       }
       else
        {
            setError('');
            var token=localStorage.getItem('auth_token');
            var form=document.getElementById('add_installments_form') as HTMLFormElement;
            var formdata=new FormData(form);
                

            server.post(`add_installment_type`,formdata,{ headers: { 'Authorization': 'Bearer '+token } })
            .then((response)=>{
                if(response.data.code===200)
                {
                    toast(response.data.message)
                    setTimeout(function () { window.location.href = "/installments"; }, 1000);
                }else
                {
                    toast(response.data.message)
                }
            })
        }
    }

    const setTotalNoOf=(id:number)=>
    {
        if(id<=4||id===1){
        setNoOfInstallments(id);
        }else{
            toast('no of installments cant be more than 4 or 0');
        }
    }
    const getProductDetails=(id:number)=>
    {
        setProductId(id);
        server.get(`/product/${id}`)
        .then((response)=>{
            setProductPrice(response.data.product.product_price)
        })
    }
    const installment_types=[];
    for(let i=1;i<=no_of_installments;i++)
    {
      installment_types.push(<div className='col-md-3'>
          <label>Installment{i}</label>
          <input className='form-control' type="number" id={"installment"+(i)} name={"installment"+(i)} />
      </div>)
    }

   
 if (loading) {
    return <Loader />;
  }
    return (
      <div className='conatiner-fluid'>
      <div className='d-flex'>
          <SideBar/>
          <div className='headerbar'>
          <Header/>
          {/* Content Row */}
  {/* 
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
  
       </div> */}
  
      <h4 className='text-danger text-center'>Add Installment Types</h4>
      <hr className='bg-danger px-1' />
    <div className='row mt-5'>
     
      <div className='col-md-12 px-5'>
        
          <form id="add_installments_form" className='w-100' onSubmit={addInstallType}>
            <div className='row'>
              
              {/* <div className='col-md-3'>
              <select className='form-control' name='project_id' id="project_id" onChange={(e)=>setProject(parseInt(e.target.value))} required> 
                          <option value={0}>Select Project</option>
                          {projects.map((project)=>(
                              <option value={project.id}>{project.project_name}</option>))}
              </select>
              </div> */}
               <input type="hidden" name="project_id"  id="project_id" value={project_id} />
              {project_id!==0?<div className='col-md-3'>
              <select className='form-control' name='product_id' id="product_id" onChange={(e)=>getProductDetails(parseInt(e.target.value))} required>
                          <option value={0}>Select Product</option>
                          {products.map((product)=>(
                              <option value={product.id}>{product.product_name}</option>))}
              </select>
              </div>:""}
              {product_id!==0?<div className='col-md-3'>
                  <input type="text" className='form-control' placeholder='Enter Install Type' name="installment_type_name" id="installment_type_name" value={installmenttype} onChange={(e)=>setInstallmentType(e.target.value)}   required/>
              </div>:""}
              {product_id!==0?<div className='col-md-3'>
                  <input type="number" className='form-control' placeholder='Enter Number of Installments' name="no_of_installments" id="no_of_installments"  onChange={(e)=>setTotalNoOf(parseInt(e.target.value))}  required/>
              </div>:""}
            </div>
            <div className='row mt-4'>
               {installment_types}
            </div>
              <ToastContainer />
             <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Submit</button></div>
          </form>
       
      </div>
    </div>
          </div>
          {/* header bar ends here */}
         
          {/* Content Row */}
            
      </div>
     
  </div>
    )
}

export default Addinstalltypes