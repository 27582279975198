
import { trainingtype } from '../../@types/trainingtype';
import React,{useEffect, useState} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link} from 'react-router-dom';
import DataTable from 'react-data-table-component';
import server from '../Utils';

import { taskschedulingtypes } from '../../@types/taskschedulingtypes';
import Loader from './Loader';


const TaskSchedulers = () => {
   const [task_schedulers,setTaskSchedulers]=useState<Array<taskschedulingtypes>>([])
   const [loading, setLoading] = useState(false);

   let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
   let id= +projectid
   
   
   useEffect(() => {
    server.get(`/get_task_schedules`)
    .then((response)=>{
      setTaskSchedulers(response.data.task_schedules)
      
    })
  }, []);
  const deleteProgram=(deleteid:number)=>{
  //   const swalWithBootstrapButtons = Swal.mixin({
  //     customClass: {
  //       confirmButton: "btn btn-success",
  //       cancelButton: "btn btn-danger"
  //     },
  //     buttonsStyling: false
  //   });
  //   swalWithBootstrapButtons.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes, delete it!",
  //     cancelButtonText: "No, cancel!",
  //     reverseButtons: true
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       server.delete(`/service_categorisers/${deleteid}`)
  //       .then((response)=>{
  //         setTraining(response.data.service_categorisers)
  //         addSerialNumbers(response.data.service_categorisers)
  //         swalWithBootstrapButtons.fire({
  //           title: "Deleted!",
  //           text: "Your file has been deleted.",
  //           icon: "success"
  //         });
  //       })
        
  //     } else if (
  //       /* Read more about handling dismissals below */
  //       result.dismiss === Swal.DismissReason.cancel
  //     ) {
  //       swalWithBootstrapButtons.fire({
  //         title: "Cancelled",
  //         text: "Your imaginary file is safe :)",
  //         icon: "error"
  //       });
  //     }
  //   })
  //  }

    // const addSerialNumbers = (data:[]) => {
    //    data.map((item:{}, index) => ({
    //     ...item,
    //     slNo: index + 1,
    //   }));
    //   setTraining(data)
    
    };
    const tableCustomStyles = {
      headRow: {
        style: {
          color:'#fff',
          backgroundColor: '#476cd9'
        },
      },
      striped: {
          default: 'red'
      },
      

    }
 
    const columns=[
      {
        name:'SL No',
        selector:(row:any)=>row.id,
        sortable:true,
      },
      {
        name:'Service Name',
        selector:(row:any)=>row.service_name,
        sortable:true,
      },{
        name:'Primary Incharge',
        selector:(row:any)=>row.primary_incharge,
        sortable:true,
      },
      {
        name:'Secondary Incharge',
        selector:(row:any)=>row.secondary_incharge,
        sortable:true,
      },
      {
        name:'Start time',
        selector:(row:any)=>row.start_time,
        sortable:true,
      },
      {
        name:"Time Duration",
        selector:(row:any)=>row.start_time_duration===1?"Days":row.star_time_duration===2?"Weeks":row.star_time_duration===3?"Months":row.star_time_duration===4?"Years":"",
      },{
        name:'Before/After',
        selector:(row:any)=>row.before_or_after_start_time===1?"Before":row.before_or_after_start_time===2?"After":"",
        sortable:true,
      },
      {name:'Actions',
      selector:(row:any)=><div className='row'><div className='col-md-6'><Link className='text-left' to={`/edit_task_schedule/${row.id}`}><i className='fa fa-edit text-success'></i></Link></div><div className='col-md-6'><i onClick={()=>deleteProgram(row.id)} className='fa fa-trash text-danger'></i></div></div>,
       sortable:true,
      },

    ];
   
    if (loading) {
      return <Loader />;
    }
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}

    <h4 className='text-danger text-center'>Task Schedules</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5 px-2'>
    
    <div className='col-md-12'>
        <Link to="/add_task_schedule" className='btn btn-outline-primary float-right my-3'>Add Task Scheduler</Link>
      {/* <table className='table table-bordered table-striped table-hover'>
        <tr className='bg-warning'><th>Sl no</th><th>Product Name</th><th>Service Category Type</th><th>Service Category</th><th>Service Name</th> <th className='text-center' colSpan={2}>Actions</th></tr>
       */}
      {/* {trainings.map((training)=>(
        <tr><td>{training.id}</td><td>{training.product_name}</td><td>{training.service_category_type}</td><td>{training.service_category_name}</td><td>{training.service_name}</td><td><Link className='text-left' to={`/edit_training/${training.id}`}><i className='fa fa-edit'></i></Link></td><td><i onClick={()=>deleteProgram(training.id)} className='fa fa-trash'></i></td></tr>
      ))} */}
       {/* </table> */}
       <DataTable columns={columns} data={task_schedulers} customStyles={tableCustomStyles} paginationPerPage={50} paginationRowsPerPageOptions={[50,10,25,100]} pagination/>
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
  
}

export default TaskSchedulers