import React, { useState, useEffect } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { Link, useParams } from 'react-router-dom';
import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { message } from 'antd';
import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import $ from 'jquery';
import validator from 'validator';
import { off } from 'process';
import { type } from 'os';
import { vendortype } from '../../@types/vendortype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import { ServiceData } from '../../@types/servicetype';
import { service_categorytypedata } from '../../@types/service_categorytypedata';
import server from '../Utils';
import UserInfo from '../UserInfo';
import Loader from './Loader';
import { multipleusertype } from '../../@types/multipleusertype';
import Multiselect from 'multiselect-react-dropdown';

const AddMultipleCourseAllocatedDetails = () => {
  const [project_id, setProjectId] = useState<number>(0);
  const [assesment_date, setAssesmentDate] = useState<string>('');
  const [service_category_id, setServiceCategoryId] = useState<number>(0);
  const [service_categories, setServiceCategories] = useState<Array<ServiceCategoryType>>([]);
  const [services, setServices] = useState<Array<ServiceData>>([]);
  const [service_id, setServiceId] = useState<number>(0);
  const [vendors, setVendors] = useState<Array<vendortype>>([]);
  const [vendor_id, setVendorId] = useState<number>(0);
  const [mode_of_training, setModeOfTraining] = useState(0);
  const [subscription_start_date, setSubscriptionStartDate] = useState<string>('');
  const [course_period, setCoursePeriod] = useState<number>(0);
  const [delivery_method, setDeliveryMethod] = useState<number>(0);
  const [service_category_type, setCategoryTypeId] = useState<number>(0);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [course_venue, setCoureVenue] = useState<string>('');
  const [mode_of_delivery, setModeOfDelivery] = useState<number>(0);
  const [course_allocated_date, setCourseAllocatedDate] = useState<string>('');
  const [course_start_date, setCourseStartDate] = useState<string>('');
  const [course_end_date, setCourseEndDate] = useState<string>('');
  const [course_duration, setCourseDuration] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  // const { id } = useParams();
  // const userid = id;
  const [service_category_types, setServiceCategorytypes] = useState<Array<service_categorytypedata>>([]);
  const [users, setUsers] = useState<Array<multipleusertype>>([]);
  const [user_ids, setUserIds] = useState<Array<number>>([]);
  const [selectedValue, setSelectedValue] = useState<Array<multipleusertype>>([]);
  const [error, setError] = useState(false);
  const [user_id, setUserId] = useState<number>();
  const [products, setProducts] = useState<Array<ProductData>>([]);
  const [product_id, setProductId] = useState<number>(0);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    let projectid: string = JSON.parse(localStorage.getItem("project_id") || '{}');
    let id = +projectid;
    setProjectId(id);
    server.get(`/get_product/${id}`)
      .then((response) => {
        setProducts(response.data.product);
      });

    server.get('get_operationuser')
      .then((response) => {
        setUsers(response.data.users);
      });

    server.get(`/service_types_basedon_categories/12`)
      .then((response) => {
        if (response.data.service_category_types.length > 0) {
          setServiceCategorytypes(response.data.service_category_types);
        } else {
          setServiceCategorytypes([]);
        }
      });

    server.get(`/vendors/${id}`)
      .then((response) => {
        setVendors(response.data.vendors);
      });
  }, []);

  const getServices = (id: number) => {
    setCategoryTypeId(id);
    server.get(`/service_on_category_type/${id}`)
      .then((response) => {
        setServices(response.data.services);
      });
  };

  const onSelect = (selectedList: [], selectedItem: any) => {
    setSelectedValue(selectedList);
  };
  
  const addCourseAllocatedDetails = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    if (product_id === 0) {
      toast('Select the product');
    } else if (error && count > 1 && selectedValue.length === 0) {
      toast('Select the user again');
    } else if (service_category_type === 0) {
      toast('Select Service Category Type');
    } else if (service_id === 0) {
      toast('Select Course');
    } else if (mode_of_delivery === 0) {
      toast('Select mode of delivery');
    } else if (mode_of_delivery === 2 && validator.isEmpty(course_venue)) {
      toast('Enter Course Venue');
    } else if (!course_allocated_date) {
      toast('Enter course allocation date');
    } else if (!course_start_date) {
      toast('Enter course start date');
    } else if (new Date(course_start_date) <= new Date(course_allocated_date)) {
      toast('Course start date must be greater than course allocated date');
    } else if (mode_of_delivery === 1 && course_duration === 0) {
      toast('Enter Course Duration');
    } else if (mode_of_delivery === 1 && course_period === 0) {
      toast('Select Course Duration');
    } else {
      setLoading(true);
      setError(false);
  
      let successCount = 0;
  
      try {
        await Promise.all(selectedValue.map(async (element) => {
          const formData = new FormData(document.getElementById('addcourse_allocated_details') as HTMLFormElement);
          const response = await server.post(`/add_courses_allocated/${element.id}`, formData);
          if (response.data.code === 200) {
            successCount++;
            // toast(`Data added successfully for ${element.user}`);
          } else {
            setError(true);
            toast(`Couldn't be added for ${element.user}, try again`);
          }
        }));
  
        if (successCount === selectedValue.length) {
          toast('Course allocated for all users successfully');
          setTimeout(() => {
            window.location.href = "/courses_allocated_list";
          }, 2000);
        } else {
          toast(`Course allocation completed with ${successCount} successes and ${selectedValue.length - successCount} failures.`);
        }
      } catch (err) {
        setError(true);
        toast('An error occurred, please try again');
      } finally {
        setLoading(false);
      }
    }
  };
  

  const getCurrentDate = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const year = today.getFullYear();

    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedDay = day < 10 ? `0${day}` : `${day}`;

    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const setallUsers = (id: number) => {
    setProductId(id);
    setUserId(0);
    setError(true);
    setCount(count + 1);
    setSelectedValue([]);
    server.get(`get_operationuser/${id}`)
      .then((response) => {
        setUsers(response.data.users);
      });
  };
const onRemove = (selectedList: multipleusertype[], removedItem: multipleusertype) => {
    setSelectedValue(selectedList);
  };
  console.log(selectedValue);
  if (loading) {
    return <Loader />;
  }
  return (
    <div className='container-fluid'>
      <div className='d-flex'>
        <SideBar />
        <div className='headerbar'>
          <Header />
          {/* <UserInfo /> */}
          <h3 className='text-danger text-center'>Add Multiple Courses Allocated</h3>
          <hr className='bg-danger px-1' />
          <div className='row mt-5 jumbotron mx-3'>
            {project_id !== 0 && <div className='col-md-6'>
              <label>Products</label>
              <select className='form-control' name='product_id' id="product_id" value={product_id} onChange={(e) => setallUsers(parseInt(e.target.value))} required>
                <option value={0}>Select Product</option>
                {products.map((product) => (
                  <option key={product.id} value={product.id}>{product.product_name}</option>
                ))}
              </select>
            </div>}
            <div className='col-md-12'>
              {product_id !== 0 && (
                users.length !== 0 ? (
                  <div>
                    <label>Users</label>
                    <Multiselect
                      options={users}
                      placeholder="Select doctors here"
                      selectedValues={selectedValue}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="user"
                    />
                  </div>
                ) : (
                  <p className="text-danger">
                    Users List can't be displayed because no user filled their details yet
                  </p>
                )
              )}
            </div>
            <div className='col-md-12 px-5'>
              <br />
              {product_id!==0?<form id="addcourse_allocated_details" onSubmit={addCourseAllocatedDetails} encType='multipart/form-data' className='w-100'>
                <div className='row'>
                  <input type="hidden" name={"service_category_id"} id={"service_category_id"} value="10" />
                  <input type={'hidden'} name={'project_id'} value={project_id} />
                  <input type={'hidden'} name={'product_id'} value={product_id} />
                  <div className='col-md-3'>
                    <label>Subscription Category</label>
                    <select id="service_category_type" className='form-control' name="service_category_type" value={service_category_type} onChange={(e) => getServices(parseInt(e.target.value))}>
                      <option value="0">Select Subscription Category</option>
                      {service_category_types.map((service_category_type) => (
                        <option key={service_category_type.id} value={service_category_type.id}>{service_category_type.service_category_type}</option>
                      ))}
                    </select>
                  </div>
                  {service_category_type !== 0 && <div className='col-md-3'>
                    <label htmlFor="">Courses :</label>
                    <select className='form-control' id="service_id" name="service_id" value={service_id} onChange={(e) => setServiceId(parseInt(e.target.value))}>
                      <option value="0">Select Courses</option>
                      {services.map((service) => (
                        <option key={service.id} value={service.id}>{service.service_name}</option>
                      ))}
                    </select>
                  </div>}
                  {service_category_type !== 0 && <div className='col-md-3'>
                    <label>Mode Of Delivery</label>
                    <select className='form-control' id="mode_of_delivery" name="mode_of_delivery" value={mode_of_delivery} onChange={(e) => setModeOfDelivery(parseInt(e.target.value))}>
                      <option value={0}>Select mode of Delivery</option>
                      <option value={1}>Online</option>
                      <option value={2}>Offline</option>
                    </select>
                  </div>}
                  {service_category_type !== 0 && mode_of_delivery === 2 && <div className='col-md-3'>
                    <label>Course Venue</label>
                    <input type="text" className='form-control' id="course_venue" name="course_venue" value={course_venue} onChange={(e) => setCoureVenue(e.target.value)} />
                  </div>}
                </div>
                <div className='row my-3'>
                  {service_category_type !== 0 && <div className='col-md-3'>
                    <label>Course Allocated Date</label>
                    <input className='form-control' type="date" name="course_allocated_date" id="course_allocated_date" value={course_allocated_date} onChange={(e) => { setCourseAllocatedDate(e.target.value) }} />
                  </div>}
                  {service_category_type !== 0 && <div className='col-md-3'>
                    <label>Course Start Date</label>
                    <input className='form-control' type="date" name="course_start_date" id="course_start_date" value={course_start_date} onChange={(e) => { setCourseStartDate(e.target.value) }} />
                  </div>}
                  {mode_of_delivery === 1 && <div className='col-md-6'>
                    <label>Subscription Duration</label>
                    <div className='row'>
                      <div className='col-md-2'><input className="form-control" type="number" name="course_duration" id="course_duration" value={course_duration} onChange={(e) => setCourseDuration(parseInt(e.target.value))} /></div>
                      <div className='col-md-10 text-left'>
                        <select className='form-control w-75' id="course_period" name="course_period" value={course_period} onChange={(e) => setCoursePeriod(parseInt(e.target.value))}>
                          <option value={0}>Choose Periods</option>
                          <option value={1}>Days</option>
                          <option value={2}>Weeks</option>
                          <option value={3}>Months</option>
                          <option value={4}>Years</option>
                        </select>
                      </div>
                    </div>
                  </div>}
                </div>
                <ToastContainer />
                <div className='text-center mt-4'><button type="submit" className='btn btn-info text-white mt-2'>Add Details</button></div>
              </form>:""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMultipleCourseAllocatedDetails;
