
import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';

import {Link,NavLink,useParams} from 'react-router-dom';

import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { message } from 'antd';

import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import $ from 'jquery'; 

import validator from 'validator';
import { off } from 'process';
import { type } from 'os';
import { vendortype } from '../../@types/vendortype';
import TestBookings from '../TestBooking/TestBookings';

import { testbookingtype } from '../../@types/testbookingtype';
import Registrations from '../Registrations/Registrations';

import { ServiceCategoryType } from '../../@types/service_category_type';
import { ServiceData } from '../../@types/servicetype';
import { registrationtype } from '../../@types/registrationtype';
import { trainingsubcriptiontype } from '../../@types/training_and_subscription';
import moment from 'moment';
import AddCourseAllocatedDetails from './AddCourseAllocatedDetails';
import { coursedatatype } from '../../@types/coursesdatatype';
import server from '../Utils';
import Loader from './Loader';

const CourseAllocatedDetails = () => {
    let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
    let pro_id= +projectid;

    const {id}= useParams();
    const [project_id,setProjectId]=useState<number>(0);
    const [user_exist,setUserExists]=useState<boolean>(false);
    const [training_exists,setTrainingExists]=useState<boolean>(false);
    const [test_booking_exists,setTestBookingExist]=useState<boolean>(false);
    const [test_bookings,setTestBooking]=useState<Array<testbookingtype>>([]);
    const [registration_exist,setRegistrationExist]=useState<boolean>(false);
    const [training_and_subscription_exist,setTrainingAndSubscriptionExist]=useState<boolean>(false);
    const [courses_allocated_details,setCoursesAllocatedDetails]=useState<Array<coursedatatype>>([])
    const [loading, setLoading] = useState(false);
    const [user_agreed,setUserAgreed]=useState<number>(0);

    useEffect(() => {
        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let pro_id= +projectid;
        server.get(`/operationuser/${id}`)
        .then((response)=>{
         
           if(response.data.user.user_agreement_flag===1){
             setUserAgreed(1)
           }else{
             setUserAgreed(0)
           }
        })
      server.get(`/courses_allocated_details/${id}`)
      .then((response)=>{
        setCoursesAllocatedDetails(response.data.courses_allocated)
      })
         
  
         
        
        

        //  server.get(`/get_training_and_subscriptions_details/${id}`)
        //  .then((response)=>{
        //   setCoursesAllocatedDetails(response.data.courses_allocated)
        //  })
      
        }, []);


 if (loading) {
  return <Loader />;
}
  return (
    <div>
    
    {user_agreed === 1 ? <Link className='btn btn-info text-info float-right text-light' to={`/add_course_allocated_details/${id}`}>Add Allocated Course</Link>:<p className='text-danger'>Kindly ask the doctor to fill their details in their portal</p>}
    <br/>
      {courses_allocated_details && courses_allocated_details.map((courses_allocated_detail,index)=>(
        <div className='my-4 jumbotron'>
        <div className='row'><div className='col-md-4'><h4 >Course Allocated - {index+1} </h4></div><div className='col-md-6'></div><div className='col-md-2'><Link to={`/edit_course_allocated_details/${courses_allocated_detail.id}`}><i className='fa fa-edit text-info'></i></Link></div></div>
        
         <div className='row'>
         <div className="col-md-4">Course Name</div>
         <div className="col-md-4"><i>{courses_allocated_detail.service_name}</i></div>
         <div className="col-md-4"></div>
         </div>
         <div className='row'>
         <div className="col-md-4">Course Name</div>
         <div className="col-md-4"><i>{courses_allocated_detail.mode_of_delivery===1?"Online":courses_allocated_detail.mode_of_delivery===2?"Offline":""}</i></div>
         <div className="col-md-4"></div>
         </div>
        {courses_allocated_detail.course_venue!==null?<div className='row'>
         <div className="col-md-4">Course Venue</div>
         <div className="col-md-4"><i>{courses_allocated_detail.course_venue}</i></div>
         <div className="col-md-4"></div>
         </div>:""}
         <div className='row'>
         <div className="col-md-4">Course Allocated Date</div>
         <div className="col-md-4"><i>{moment(courses_allocated_detail.course_allocated_date).format('Do MMMM YYYY')}</i></div>
         <div className="col-md-4"></div>
         </div>

        {courses_allocated_detail.mode_of_delivery===1?<div className='row'>
         <div className="col-md-4">Course Duration</div>
         <div className='col-md-4'><i>{courses_allocated_detail.course_duration} {courses_allocated_detail.course_period===1?"Days":courses_allocated_detail.course_period===2?"Weeks":courses_allocated_detail.course_period===3?"Months":courses_allocated_detail.course_period===4?"Year":""}</i></div>         <div className="col-md-4"></div>
         </div>:""}
         <div className='row'>
         <div className="col-md-4">Course Start Date</div>
         <div className="col-md-4"><i>{moment(courses_allocated_detail.course_start_date).format('Do MMMM YYYY')}</i></div> 
         <div className="col-md-4"></div>
         </div>
         <div className='row'>
         <div className="col-md-4">Course End Date</div>
         <div className="col-md-4"><i>{moment(courses_allocated_detail.course_allocated_end_date).format('Do MMMM YYYY')}</i></div>
         <div className="col-md-4"></div>
         </div>
         </div>

      ))}

    </div>
  )
}

export default CourseAllocatedDetails;