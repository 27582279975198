import React,{useEffect,useState} from 'react';
import { useParams,Link } from 'react-router-dom';
import server from '../Utils';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import { ServiceData } from '../../@types/servicetype';
import { StageData } from '../../@types/stagetype';
import maleImage from '../../assets/images/male_avatar.svg';
import femaleImage from '../../assets/images/female_avatar.svg';
import profileimage from '../../assets/images/profileimage.png';
import { CollegeData } from '../../@types/collegedata';
import TextArea from 'antd/es/input/TextArea';
import validator from 'validator';
import { ToastContainer, toast } from 'react-toastify';
import { callnotestype } from '../../@types/callnotestype';
import './UserInfo.css';
import { GMCRegistrationType } from '../../@types/gmc_registration';
import { testbookingtype } from '../../@types/testbookingtype';
import { training_details } from '../../@types/training_details';
import moment from 'moment';
import ProgressBar from '../ProgressBar';
import Loader from './Loader'



const UserInfo=() => {
    let  projectid:string=JSON.parse(localStorage.getItem("user_id")||'{}');
      let id = +projectid;
    
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [projects,setProjects]=useState<Array<ProjectData>>([])
    const [discounted_amount,setDiscountedAmount]=useState<number>(0)
    const [project_id,setProjectId]=useState<number>(0);
    const [product_id,setProductId]=useState<number>(0);
    const[product_price,setProductPrice]=useState<number>(0);
    const [loading, setLoading] = useState(false);

    const [call_notes,setCallNotes]=useState<string>('')
     const[callrecords,setCallRecords]=useState<Array<callnotestype>>([])
     const [stages,setStages]=useState<Array<StageData>>([])
     const[stage_id,setStageId]=useState<number>(0);
     const [services,setServices]=useState<Array<ServiceData>>([])
     const [subscribed_services,setSubscribedServices]=useState<string>('')
     const [passport_photo,setPassportPhoto]=useState<string>('');
    const[first_name,setfirstName]=useState<string>("");
     const[last_name,setlastName]=useState<string>("");
     const [gender,setGender]=useState<number>(0)
     const[primary_country_code,setPrimaryCountryCode]=useState<number>();
     const[primary_contact,setPrimaryContact]=useState<number>();
     const[alernate_country_code,setAlternateCountryCode]=useState<number>();
     const[alternatecontact,setAlternateContact]=useState<number>();
     const[user_email,setEmail]=useState<string>("");
     const [project_name,setProjectName]=useState<string>(''); 
     const[product_name,setProductName]=useState<string>('');  
     const [stage_name,setStageName]=useState<string>('');
     const[installment_type_name,setInstallmentTypeName]=useState('');
     const[date_of_registration,setDateOfRegistration]=useState<string>("");
     const [city,setCity]=useState<string>('');
     const [state,setState]=useState<string>('');
     const [country,setCountry]=useState<string>('');
     
     
    
     const[product_amount,setProductAmount]=useState<number>();
     const[contract_file,setContractFile]=useState<number>();
     const[counsellor_note,setCounsellorNote]=useState<string>();   
     const[file,setFile]=useState<File>();
     const[service_names,setServiceNames]=useState<Array<ServiceData>>([]);
    
     const [installtypes,setInstalltypes]=useState<Array<InstallmentTypeData>>([])
     const[installtypesid,setInstallTypeId]=useState<number>(0);
     const [password,setPassword]=useState<string>("");
    
     const [noofintallments,setNoOfInstallments]=useState<number>(0);
     
    
    
    const [client_photo,setClientPhoto]=useState<string>('');
   


  const [colleges,setColleges]=useState<Array<CollegeData>>([])
  const [collegeid,setCollegeId]=useState<number>(0);
  const [pmq_status,setPmQStatus]=useState<number>(0);
  const [pmq_start_date,setPmqStartDate]=useState<string>('');
  const [pmq_end_date,setPmqEndDate]=useState<string>('');
  const [pmq_finish_date,setPmqFinishDate]=useState<string>('');

  
  const [percentage,setPercentage]=useState<number>(0);
  const  admin_id=localStorage.getItem('admin_id');
  
  
  const [current_stage,setCurrentStage]=useState<string>('');
  const [current_sub_stage,setCurrentSubStage]=useState<string>('');

  const [gc_registration,setGCRegistration]=useState<string>('')
  const [documents,setDocuments]=useState<string>('')

    useEffect(() => {
        
         server.get(`/get_call_notes/${id}/${admin_id}`)
         .then((response)=>{
            setCallRecords(response.data.call_notes)
         })   
        
      
            server.get(`/user_info/${id}`)
              .then((response)=>{
                console.log('user_info',response.data)
                  setfirstName(response.data.user.first_name);
                  setlastName(response.data.user.last_name);
                  setGender(response.data.user.gender);
                  setPrimaryCountryCode(response.data.user.primary_contact_country_code);
                  setPrimaryContact(response.data.user.primary_contact);
                  setAlternateCountryCode(response.data.user.alernate_country_code);
                  setAlternateContact(response.data.user.alternate_phone_number);
                  setEmail(response.data.user.user_email);
                  setProjectId(response.data.user.project_id);
                  setProductId(response.data.user.product_id);
                  localStorage.setItem("product_id",response.data.user.product_id);
                  setDateOfRegistration(response.data.user.date_of_registration);
                  setStageId(response.data.user.stage_id);
                  setDiscountedAmount(response.data.user.discounted_amount);
                  
                  setProductAmount(response.data.user.product_amount);
                  setInstallTypeId(response.data.user.installment_type);
                  setNoOfInstallments(response.data.user.noofintallments);
                 
                  setCounsellorNote(response.data.user.counsellor_note);
                  setProductPrice(response.data.user.product_price);
                  setProjectName(response.data.user.project_name);
                  setProductName(response.data.user.product_name);
                  setStageName(response.data.user.stage_name);
                  setInstallmentTypeName(response.data.user.installment_type_name);
                  setPassword(response.data.user.last_password);
                  setSubscribedServices(response.data.user.services_offered);
                  setCity(response.data.user.current_city_name);
                  setState(response.data.user.current_state_name);
                  setCountry(response.data.user.current_country_name);
                  setCurrentStage(response.data.user.current_stage_name);
                  setCurrentSubStage(response.data.user.current_sub_stage_name);
                  setGCRegistration(response.data.user.goocampus_registration_number)
                  setCollegeId(response.data.user.pmq_college);
                  setPmQStatus(response.data.user.pmq_status);
                  setPmqStartDate(response.data.user.pmq_start_date);
                  setPmqEndDate(response.data.user.pmq_end_date);
                  setPmqFinishDate(response.data.user.pmq_finish_date);
                  setClientPhoto(response.data.user.client_photo);
                  setPercentage(response.data.percentage)
                 
                  
            })
            
//             server.get(`/operationuser/${id}`)
//   .then((response)=>{
   
//     if(response.data.code===200){
   
//     }
// })
    // loadColleges();
//             server.get(`/get_documents/${id}`)
//   .then((response)=>{
    
//     if(response.data.code===200){
//       setDocuments(response.data.documents)
//     // setPmQStatus(response.data.documents.pmq_status);
//     setClientPhoto(response.data.documents.client_photo);
//     // setPassportPhoto(response.data.documents.passport_photo);
//     // setProvisionalDegreeCertificate(response.data.documents.provisional_degree_certificate);
//     // setFinalDegreeCertificate(response.data.documents.final_degree_certificate);
//     // setProvisionalRegistrationCertificate(response.data.documents.provisional_registration_certificate);
//     // setFinalRegistrationCertificate(response.data.documents.final_registration_certificate);
//     // setInternshipCompletionCertificate(response.data.documents.internship_completion_certificate);
//     // setcertificateNames(response.data.documents.certificate_names);
//     // setCertificateDesc(response.data.documents.certificate_description);
//     // setCertificateFiles(response.data.documents.certificate_file);
    
    
   
    
//  }
// })


               
              }, []);


  
              // if (loading) {
              //   return <Loader />;
              // }

              const addCallNotes = async (e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                if (!call_notes || call_notes.trim() === "") {
                    toast('Enter call Notes');
                    return;
                }
            
               
                    const formData = new FormData(e.currentTarget);
            
                    const response = await server.post('/add_call_notes', formData);
            
                    if (response.data.code === 200) {
                        server.get(`/get_call_notes/${id}/${admin_id}`)
                        .then((response)=>{
                           setCallRecords(response.data.call_notes)
                        }) 
                        toast(response.data.message);
                    } else {
                        toast(response.data.message);
                    }
               
            };
            
  return (
    <div className='row w-100 border py-3 px-1 rounded jumbotron' style={{background:"#c2c3c5",marginLeft:"0.02%"}} >
        <div className='col-md-2 text-left border-right' style={{paddingTop:"4%"}}>
            { client_photo!==null?<img src={client_photo} className='rounded-circle mr-5' width="150px" height="150px" alt=""/>:gender===1?<img className='rounded-circle' src={maleImage} width="100" height="100" alt=""/>:<img className='rounded-circle' src={profileimage} width="100" height="100" alt=""/>}
            </div>
            
        <div className='col-md-4 border-right'>
            <div className='row'>
               
            <div className='col-md-9 px-4 text-left'>
                <h3>Dr {first_name} {last_name}</h3>
                <p><strong>Registration Number: </strong>{gc_registration}</p>
                <p>+{primary_country_code}-{primary_contact}</p>
                <p>{user_email}</p>
                <p>{city!=="" && parseInt(city)!==0?city+",":""}{state!=="" && parseInt(state)!==0?state+",":""}{country!=="" && parseInt(country)!==0?country+".":""}</p>
               
                    {collegeid}
            </div>
            </div>
        </div>
        <div className='col-md-3 border-right'>
           {pmq_status===1?<p><strong>PMQ Status: </strong> Ongoing</p>:pmq_status===2?<p><strong>PMQ Status:</strong>Internship</p>:pmq_status===3?<p><strong>PMQ Status:</strong>Completed</p>:""}
           <p><strong>Joined Stage: </strong>{stage_name}</p>
           <p><strong>Current Stage: </strong>{current_stage} </p>
          
        </div>
        <div className='col-md-3'>
           
            <div className='bg-secondary' style={{height:"250px",overflowY:"scroll",borderRadius:"25px"}}>
             {callrecords.map((callrecord)=>(
                <div>
                <div className='bg-white px-1 my-3  mx-2 w-75 rounded' style={{height:"auto"}}>
                    <h6 className='py-1 px-1' style={{wordWrap:"break-word",justifyContent:"center"}}>{callrecord.call_notes}</h6>
                </div>
                <div className='w-75 bg-warning float-right mr-4 mb-2 px-1 rounded'>{callrecord.first_name} <b className='float-right mt-3' style={{fontSize:"10px",fontStyle:"italic"}}>{moment(callrecord.created_at).format('DD-MM-YYYY, HH:mm')}</b></div>
                <br/>
                </div>
                
             ))}   
            </div>
            <div>
            <ToastContainer />
  <button type="button" className="btn btn-outline-primary float-right mt-3" data-toggle="modal" data-target="#myModal">
    Add Call Note
  </button>
  {/* The Modal */}
  <div className="modal fade" id="myModal">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        {/* Modal Header */}
        <form  className='w-100' id="add_call_notes"onSubmit={addCallNotes}>
        <div className="modal-header">
          <h4 className="modal-title">Add Call Notes</h4>
          <button type="button" className="close" data-dismiss="modal">×</button>
        </div>
        {/* Modal body */}
        <div className="modal-body">
            <input type="hidden" name={"admin_id"} value={admin_id?.toString()} />
            <input type="hidden" name={"user_id"} value={id?.toString()} />
          <TextArea className='form-control' name="call_notes" value={call_notes} onChange={(e)=>setCallNotes(e.target.value)} rows={10}></TextArea>
        </div>
        {/* Modal footer */}
        <div className="modal-footer">
         <button type="submit" className="btn btn-secondary" >Add</button>
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
        </form>
      </div>
    </div>
  </div>
</div>

            
            
        </div>
       <ProgressBar current_sub_stage={current_sub_stage} percentage={percentage} />
    </div>
  )
}

export default UserInfo
