import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import { ServiceData } from '../../@types/servicetype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import {Link,useParams} from 'react-router-dom';
import server from '../Utils';
import validator from 'validator';
import Loader from './Loader';


const UserGMCAndInternshipEdiT = () => {
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [loading, setLoading] = useState(false);

    const [project_id,setProjectId]=useState<number>(0);
    const [product_id,setProductId]=useState<number>(0);
    const [service_category_id,setServiceCategoryId]=useState<number>(0);
    const [service_categories,setServiceCategories]=useState<Array<ServiceCategoryType>>([])
    const [services,setServices]=useState<Array<ServiceData>>([])
    const [service_id,setServiceId]=useState<number>(0)
    const [pmq_status,setPmQStatus]=useState<number>(0);
    const [gmc_reference_number_exist,setGmcReferenceNumberExist]=useState<number>(0)
  const [gmc_reference_number,setGmcReferenceNumber]=useState<string>('')
  const [internship_start_date,setInternshipStartDate]=useState<string>("")
  const [internship_end_date,setInternshipEndDate]=useState<string>("0")

  const [submission,setSubmission]=useState<boolean>(false);


  const { id } = useParams();
    const userid=id;
  var isloggedIn=localStorage.getItem("isLoggedIn");
  if (isloggedIn !== "true") {
      
    window.location.href='/';
  }
  useEffect(() => {
   
    server.get(`/operationuser/${userid}`)
  .then((response)=>{
    
    if(response.data.code===200){
    if(response.data.user.pmq_status===null){
      setPmQStatus(0);
    }else{
      setPmQStatus(response.data.user.pmq_status);
    }
    if(response.data.user.gmc_refrence_exists===null){
      setGmcReferenceNumberExist(0);
    }else{
      setGmcReferenceNumberExist(response.data.user.gmc_refrence_exists);
    }if(response.data.user.gmc_reference_number===null){
      setGmcReferenceNumber('')
     }else{
      setGmcReferenceNumber(response.data.user.gmc_reference_number)
     }
     if(response.data.user.internship_end_date===null){
      setInternshipEndDate('')
     }else{
      setInternshipEndDate(response.data.user.internship_end_date)
     }
     if(response.data.user.internship_end_date===null){
      setInternshipStartDate('')
     }else{
      setInternshipStartDate(response.data.user.internship_start_date)
     }
    
    }
})
    
  }, []);
 



const  updateDetails = (e:React.FormEvent<HTMLFormElement>) =>{
  e.preventDefault();
  
  
  // var userid= localStorage.getItem('ClientID');
    var start_date=new Date(internship_start_date).getTime();

    var end_date=new Date(internship_end_date).getTime();
    
    
  var len=gmc_reference_number.toString().length;
  
  if(gmc_reference_number_exist===0)
  {
    toast('Choose whether GMC reference number exists?')
  } else if(gmc_reference_number_exist===null)
  {
    toast('Choose whether GMC reference number exists?')
  }
  else if(gmc_reference_number_exist===1 &&  gmc_reference_number===null){
    toast('enter gmc reference Number');
  }else if(gmc_reference_number_exist===1 &&  gmc_reference_number===""){
    toast('enter gmc reference Number');
  }else if(gmc_reference_number_exist===1 &&  gmc_reference_number===null){
    toast('enter gmc reference Number');
  } else if(gmc_reference_number_exist===1 && len!==7) {
    
     toast('GMC Reference Number has to be 7 digits number')
  }
  else if((pmq_status===2|| pmq_status===3) && validator.isEmpty(internship_start_date)){
    toast('Enter the Internship Start Date')
  }
  else if((pmq_status===2 || pmq_status===3)  && validator.isEmpty(internship_end_date)){
    toast('Enter the Internship End Date')
  }else if(start_date===end_date){
    toast('Start date cant be equal to End Date');
  }
  else if(start_date>end_date){
    toast('Start date cant be Bigger Than End Date');
  }
  else{
    var form = document.getElementById('gmc_and_intenship') as HTMLFormElement
    var formData = new FormData(form);
    server.post(`/gmc_and_intenship/${userid}`,formData)
    .then((response)=>{
        if(response.data.code===200){
            toast(response.data.message) 
           
        }else{
            toast(response.data.message) 
        }
    })
  }


} 



const saveDetails=()=>{
  
  // var userid= localStorage.getItem('ClientID');
   
    
  var len=gmc_reference_number.toString().length;
  
  if(gmc_reference_number_exist===0)
  {
    toast('Choose whether GMC reference number exists?')
  } else if(gmc_reference_number_exist===null)
  {
    toast('Choose whether GMC reference number exists?')
  }
  else if(gmc_reference_number_exist===1 &&  gmc_reference_number===null){
    toast('enter gmc reference Number');
  }else if(gmc_reference_number_exist===1 &&  gmc_reference_number===""){
    toast('enter gmc reference Number');
  }else if(gmc_reference_number_exist===1 &&  gmc_reference_number===null){
    toast('enter gmc reference Number');
  } else if(gmc_reference_number_exist===1 && len!==7) {
    
     toast('GMC Reference Number has to be 7 digits number')
  }
  
  else{
    var form = document.getElementById('gmc_and_intenship') as HTMLFormElement
    var formData = new FormData(form);
    setLoading(true);
    setSubmission(true);
    server.post(`/gmc_and_intenship/${userid}`,formData)
    .then((response)=>{
        if(response.data.code===200){
          setLoading(false)
          Swal.fire({
            title:"Details Saved!",
            text: "GMC Details Saved Successfully",
            icon: "success"
          });
        }else{
          setLoading(false)
            toast(response.data.message) 
        }
    })
  }
}

  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}

<div className="row px-2">
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-primary shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
        Earnings (Monthly)</div>
        <div className="h5 mb-0 font-weight-bold text-gray-800">$40,000</div>
    </div>
    <div className="col-auto">
        <i className="fas fa-calendar fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-success shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
        Earnings (Annual)</div>
        <div className="h5 mb-0 font-weight-bold text-gray-800">$215,000</div>
    </div>
    <div className="col-auto">
        <i className="fas fa-dollar-sign fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-info shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3  ">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Tasks
        </div>
        <div className="row no-gutters align-items-center">
        <div className="col-auto">
            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">50%</div>
        </div>
        <div className="col">
            <div className="progress progress-sm mr-2">
            <div className="progress-bar bg-info" role="progressbar" style={{width: '50%'}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
            </div>
        </div>
        </div>
    </div>
    <div className="col-auto">
        <i className="fas fa-clipboard-list fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
{/* Pending Requests Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-warning shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
        Pending Requests</div>
        <div className="h5 mb-0 font-weight-bold text-gray-800">18</div>
    </div>
    <div className="col-auto">
        <i className="fas fa-comments fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
</div>
    <h4 className='text-danger text-center'>Edit GMC And Internship Details</h4>
    <hr className='bg-danger px-1' />
    <div className='user_foarm_board userpath_right_pad mx-3 pt-2'>
      <h3 className='blue-color fw-bold my-4'>GMC Details</h3>
          <form className='w-100' id="gmc_and_intenship" onSubmit={updateDetails}>
          <input type='hidden' name="form_progress" id="form_progress" value={5}/>
            <div className='row'>
                <div className='col-md-4'>
                    <label className="blue-color fw-bold">Do you have a GMC Reference Number?<b className='text-danger'>*</b></label>
                    <select className='form-control rounded  user_input_dashboard' id="gmc_reference_number_exist" name="gmc_refrence_exists" value={gmc_reference_number_exist} onChange={(e)=>{setGmcReferenceNumberExist(parseInt(e.target.value))}}>
                        <option value={0}>Choose</option>
                        <option value={1}>Yes</option>
                        <option value={2}>No</option>
                    </select>
                </div>
                {gmc_reference_number_exist===1?<div className='col-md-4'>
                    <label className="blue-color fw-bold">Enter GMC Reference Number<b className='text-danger'>*</b></label>
                    <input className='form-control rounded  user_input_dashboard' type="number" id="gmc_reference_number" name="gmc_reference_number" value={gmc_reference_number} onChange={(e)=>setGmcReferenceNumber(e.target.value)} required  />
                </div>:<div className='col-md-6'></div>}
            </div>
           
            
            <ToastContainer/>
            <div className='my-5 text-center'><button type="button" onClick={saveDetails} className='w-25 btn bg-blue text-white'>Save</button></div>
            <br/>
            <br/>
         </form>

        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
</div>
  )
}

export default UserGMCAndInternshipEdiT