import React, { useState,useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import SideBar from '../../pages/SideBar';
import Header from '../../pages/Header';
import {Link,useParams} from 'react-router-dom'
import { vendortype } from '../../@types/vendortype';
import server from '../Utils';
import axios from 'axios';
import UserInfo from '../UserInfo';
import Swal from 'sweetalert2';
import Loader from './Loader';


const AddTravelAndAccomodation: React.FC = () => {
  const [project_id,setProjectId] = useState<number>(0);
  const [product_id,setProductId] = useState<number>(0);
  const [vendors,setVendors]=useState<Array<vendortype>>([]);
  const [vendor_id,setVendorId]=useState<number>(0)
  const [loading, setLoading] = useState(false);

  const {id}= useParams();
  const user_id=id;
  const [accommodationStartDate, setAccommodationStartDate] = useState<string>('');
  const [accommodationEndDate, setAccommodationEndDate] = useState<string>('');
  const [numberOfDays, setNumberOfDays] = useState<number>(0);
  const [daysIncluded, setDaysIncluded] = useState<number>(35);
  const [accommodationVendor, setAccommodationVendor] = useState<string>('');
  const [sendAccommodationEmail, setSendAccommodationEmail] = useState<boolean>(false);
  const [sendTravelReminderEmail, setSendTravelReminderEmail] = useState<boolean>(false);
  const [returnDate, setReturnDate] = useState<string>('');
  const [line1,setLine1]=useState<string>('')
  const [line2,setLine2]=useState<string>('')
  const [city,setCity]=useState<string>('')
  const [state,setState]=useState<string>('')
  const [country,setCountry]=useState<string>('')
  const [pin,setPin]=useState<string>('')
  const [notes, setNotes] = useState<string>('');
  const [days_included_package,setDaysIncludedPackage]=useState<number>(0);
  const [room_type,setRoomType]=useState<number>(0);
  
  useEffect(() => {
    let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
    let id = +projectid;
    setProjectId(id)
    let productid:string=JSON.parse(localStorage.getItem("product_id")||'{}');
    let product_id = +productid;
    setProductId(product_id);
    
  // server.get(`/vendor_for_travel/${projectid}/${product_id}`)
  // .then((response)=>{
  //   setVendors(response.data.vendors)
  // })

  if(product_id===1){
    server.get(`/vendor_on_service_types/22`)
    .then((response)=>{
      // console.log(response.data.vendors)
      setVendors(response.data.vendors)
        
    })
  }else if(product_id===2){
    server.get(`/vendor_on_service_types/62`)
    .then((response)=>{
      // console.log(response.data.vendors)
      setVendors(response.data.vendors)
        
    })
  }else if(product_id===3){
    server.get(`/vendor_on_service_types/94`)
    .then((response)=>{
      // console.log(response.data.vendors)
      setVendors(response.data.vendors)
        
    })
  }
  
    
  //  server(`get_vendor/${vendor_id}`)
  //  .then(re)
  }, []);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const start = new Date(accommodationStartDate);
    const end = new Date(accommodationEndDate);
    const calculatedDays = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
    setNumberOfDays(calculatedDays);

    
  };

  const setVendorSelection=(id:number)=>{
    setVendorId(id)
    if(id===0){
      setLine1("")
      setLine2('')
      setCity('')
      setState('')
      setCountry('')
      setPin('')
    }
     server(`get_vendor/${id}`)
   .then((response)=>{
      setLine1(response.data.vendor.line1)
      setLine2(response.data.vendor.line2)
      setCity(response.data.vendor.city)
      setState(response.data.vendor.state)
      setCountry(response.data.vendor.country)
      setPin(response.data.vendor.pin)

   })
  }
  
  const setNumberOfDaysFunction1 = (val: string) => {
    
    // Use the updated state values
    const start = new Date(val);
    const end = new Date(accommodationEndDate);
    setAccommodationStartDate(val);
    if(end<start){
      toast.error('kindly select end date bigger than start date')
    }
    const calculatedDays = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
    setNumberOfDays(calculatedDays);
  };
  
  const setNumberOfDaysFunction2 = (val: string) => {
    setAccommodationEndDate(val);
  
    // Use the updated state values
    const start = new Date(accommodationStartDate);
    const end = new Date(val);
    if(end<start){
      toast.error('kindly select end date bigger than start date')
    }
    const calculatedDays = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
    setNumberOfDays(calculatedDays);
  };
  
  const AddTravelAndAccomodation = async (e: React.FormEvent) => {
    e.preventDefault();


const start = new Date(accommodationStartDate);
const end = new Date(accommodationEndDate);
const returnD = new Date(returnDate);


    // Validate fields
    if (!accommodationStartDate) {
      toast.error('Accommodation Start Date is required.');
      return;
    }

    if (!accommodationEndDate) {
      toast.error('Accommodation End Date is required.');
      return;
    }

    if (!numberOfDays) {
      toast.error('Number of Days for Accommodation is required.');
      return;
    }

    if (days_included_package === 0) {
      toast.error('Select the Days Included In Package.');
      return;
    }

    if (!daysIncluded) {
      toast.error('Number of Days Excluded is required.');
      return;
    }

    if (vendor_id === 0) {
      toast.error('Select the Accommodation Vendor.');
      return;
    }

    if (room_type === 0) {
      toast.error('Select the Room Type.');
      return;
    }

    if (!returnDate) {
      toast.error('Return Date is required.');
      return;
    }

    // Validate address fields if vendor_id is not 0
    if (vendor_id !== 0) {
      if (!line1.trim() || !line2.trim() || !city.trim() || !pin.trim() || !state.trim() || !country.trim()) {
        toast.error('All address fields are required.');
        return;
      }
    }

    // Additional date validations
    if (start > end) {
      toast.error('Kindly select an end date greater than the start date');
      return;
    }

    if (returnD <= end) {
      toast.error('Kindly select a return date greater than the end date');
      return;
    }

    // Date validation (existing code)

    // Calculate number of days (existing code)

    // Create FormData object
    // setLoading(true)
    var form= document.getElementById('add_travel_and_accomdation') as HTMLFormElement;
      var formData=new FormData(form)

    try {
      // Your axios post request with FormData
    
      await server.post('/add_travel_and_accomdation', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response)=>{
        if(response.data.code===200){
          // setLoading(false)
          toast(response.data.message)
          // Swal.fire({
          //   position: "center",
          //   icon: "success",
          //   title: response.data.message,
          //   showConfirmButton: false,
          //   timer: 1500
          // });
         
          setTimeout(function () { window.history.back(); }, 1000);
        }else{
          // setLoading(true)
          toast.error(response.data.message);
        }
      })

      // Handle success (existing code)

    } catch (error) {
      // Handle error (existing code)
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const year = today.getFullYear();

    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedDay = day < 10 ? `0${day}` : `${day}`;

    return `${year}-${formattedMonth}-${formattedDay}`;
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <div className='container-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}

<UserInfo />
    <h4 className='text-danger text-center'>Add Travel And Accomdation</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5 jumbotron mx-3'>
   
   
    <div className='col-md-12 px-5'>
    <form id="add_travel_and_accomdation" onSubmit={AddTravelAndAccomodation} className="needs-validation w-100">
    <input type="hidden" value={project_id} name="project_id"/>
          <input type="hidden" value={product_id} name="product_id"/>
          <input type="hidden" value={user_id} name="user_id"/>
    <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Accommodation Start Date:</label>
            <input
              type="date"
              className="form-control"
              name="accommodationStartDate"
              value={accommodationStartDate}
              onChange={(e) => {setNumberOfDaysFunction1(e.target.value)}}
              // min={getCurrentDate()}
              required
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Accommodation End Date:</label>
            <input
              type="date"
              className="form-control"
              name="accommodationEndDate"
              value={accommodationEndDate}
              // min={getCurrentDate()}
              onChange={(e) => {setNumberOfDaysFunction2(e.target.value)}}
              required
            />
          </div>
        </div>

        <p className="mb-3">Number of Days for Accommodation: {numberOfDays}</p>
        <label>Days Included In Package</label><br/>
        <select  className="form-control w-25 mb-3" name="days_included_package" id="days_included_package" value={days_included_package} onChange={(e)=>setDaysIncludedPackage(parseInt(e.target.value))} >
          <option value={0}>select the number of days</option>
          <option value={35}>35 days</option>
          <option value={28}>28 days</option>
        </select>
         <input type="hidden" value={numberOfDays} name='numberOfDays' />
        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Number of Days Excluded (days calculated):</label>
            <input
              type="number"
              className="form-control"
              name="daysIncluded"
              value={numberOfDays-days_included_package}
              onChange={(e) => setDaysIncluded(Number(e.target.value))}
              required
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Select Accommodation Vendor:</label>
            <select
              className="form-control"
              value={vendor_id}
              name="vendor_id"
              onChange={(e) => setVendorSelection(parseInt(e.target.value))}
            >
             <option value={0}>Select Vendor</option>
                {vendors.map((vendor)=>(
                  <option value={vendor.id}>{vendor.vendor_name}</option>
                ))}
            </select>
          </div>
        </div>
        <label>Room Type</label><br/>
        <select  className="form-control w-25 mb-3" name="room_type" id="room_type" value={room_type} onChange={(e)=>setRoomType(parseInt(e.target.value))} >
          <option value={0}>Select Room Type</option>
          <option value={1}>Single Ensuite room with sea view (Double bed)</option>
          <option value={2}>Single Ensuite room with sea view (Single Bed)</option>
          <option value={3}>Triple Room (Double Bed)</option>
          <option value={4}>Triple Room (Single Bed)</option>
          <option value={5}>Twin Sharing Ensuite (Single Bed)</option>
          <option value={6}>Twin Sharing Ensuite (Double Bed)</option>
          <option value={7}>Single Ensuite room (Double Bed)</option>
          <option value={8}>Single Ensuite room (Single Bed)</option>
        </select>
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            checked={sendAccommodationEmail}
            name="sendAccommodationEmail"
            onChange={() => setSendAccommodationEmail(!sendAccommodationEmail)}
          />
          <label className="form-check-label">Send Accommodation Email</label>
        </div>
{/* 
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            name="sendTravelReminderEmail"
            checked={sendTravelReminderEmail}
            onChange={() => setSendTravelReminderEmail(!sendTravelReminderEmail)}
          />
          <label className="form-check-label">Send Travel Reminder Email</label>
        </div> */}

        <div className="row mb-3">
          <div className="col-md-6">
            <label className="form-label">Return Date:</label>
            <input
              type="date"
              className="form-control"
              value={returnDate}
              name="returnDate"
              onChange={(e) => setReturnDate(e.target.value)}
              required
            />
          </div>
          {/* Other address fields go here */}
        </div>
       {vendor_id!==0?<div className="row mb-3">
  <div className="col-md-6">
    <label className="form-label">Line 1:</label>
    <input
      type="text"
      className="form-control"
      value={line1}
      name="line1"
      onChange={(e) => setLine1(e.target.value)}
      required
    />
  </div>
  <div className="col-md-6">
    <label className="form-label">Line 2:</label>
    <input
      type="text"
      className="form-control"
      value={line2}
      name="line2"
      onChange={(e) => setLine2(e.target.value)}
      required
    />
  </div>
</div>:""}

{vendor_id!==0?<div className="row mb-3">
  <div className="col-md-6">
    <label className="form-label">City:</label>
    <input
      type="text"
      className="form-control"
      value={city}
      name="city"
      onChange={(e) => setCity(e.target.value)}
      required
    />
  </div>
  <div className="col-md-6">
    <label className="form-label">State:</label>
    <input
      type="text"
      className="form-control"
      value={state}
      name="state"
      onChange={(e) => setState(e.target.value)}
      required
    />
  </div>
</div>:""}

{vendor_id!==0?<div className="row mb-3">
  <div className="col-md-6">
    <label className="form-label">Country:</label>
    <input
      type="text"
      className="form-control"
      name="country"
      value={country}
      onChange={(e) => setCountry(e.target.value)}
      required
    />
  </div>
  <div className="col-md-6">
    <label className="form-label">Pincode:</label>
    <input
      type="text"
      className="form-control"
      name="pin"
      value={pin}
      onChange={(e) => setPin(e.target.value)}
      required
    />
  </div>
</div>:""}


        <div className="mb-3">
          <label className="form-label">Notes:</label>
          <textarea
            className="form-control"
            value={notes}
            name="notes"
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-info text-white mt-2'>Add Tier4Visa</button></div>
        </form>
   
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default AddTravelAndAccomodation;