import React, { useEffect, useState } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import { Link } from 'react-router-dom';
import server from '../Utils';
import Loader from './Loader';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GridApi, ColumnApi } from 'ag-grid-community';

import { registrationtype } from '../../@types/registrationtype';

const RegistrationList: React.FC = () => {
  const [registrations, setRegistrations] = useState<Array<registrationtype>>([]);
  const [filteredRegistrations, setFilteredRegistrations] = useState<Array<registrationtype>>([]);
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const [searchText, setSearchText] = useState<string>('');

  let projectid: string = JSON.parse(localStorage.getItem('project_id') || '{}');
  let id = +projectid;

  useEffect(() => {
    setLoading(true);
    server
      .get(`/registration_list/${id}`)
      .then((response) => {
        setRegistrations(response.data.registrations);
        setFilteredRegistrations(response.data.registrations); // Initialize filtered data
      })
      .catch((error) => {
        console.error('Error fetching registration list:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const onGridReady = (params: { api: GridApi, columnApi: ColumnApi }) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = registrations.filter(registration => {
      for (const key in registration) {
        if (Object.prototype.hasOwnProperty.call(registration, key)) {
          const val = registration[key as keyof registrationtype]; // Use keyof to access typed properties

          if (typeof val === 'string' && val.toLowerCase().includes(value)) {
            return true;
          } else if (typeof val === 'number' && val.toString().includes(value)) {
            return true;
          }
        }
      }
      return false;
    });

    setFilteredRegistrations(filteredData);
    gridApi?.setRowData(filteredData);
  };

  const resetFilters = () => {
    setFilteredRegistrations(registrations); // Reset filtered data to original dataset
    gridApi?.setFilterModel(null); // Clear all applied filters
  };

  const getColumnDefs = () => [
    {
      headerName: 'SL No',
      valueGetter: "node.rowIndex + 1",
      sortable: false,
      filter: false,
    },
    {
      headerName: 'First Name',
      field: 'first_name',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Last Name',
      field: 'last_name',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Service Name',
      field: 'service_name',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Actions',
      filter:false,
      cellRenderer: (params: { data: { user_id: number; id: number } }) => (
        <Link
          className='text-dark text-center'
          onClick={() => localStorage.setItem('user_id', params.data.user_id.toString())}
          to={`/edit_registration_detail/${params.data.id}`}
        >
          <i className='fa fa-edit text-success'></i>
        </Link>
      ),
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='container-fluid'>
      <div className='d-flex'>
        <SideBar />
        <div className='headerbar'>
          <Header />
          <h4 className='text-danger text-center'>Registration List</h4>
          <hr className='bg-danger px-1' />
          <div className='row mt-5 px-2'>
            <div className='col-md-4'>
              {/* <input
                type='text'
                className='form-control'
                placeholder='Search...'
                value={searchText}
                onChange={handleSearchInputChange}
              /> */}
            </div>
            <div className='col-md-8 text-right'>
              <Link
                to={"/add_multiple_registrations"}
                className='btn btn-info float-right mr-2 text-white mb-3'
                role="button"
              >
                +
              </Link>
              <button
                className='btn btn-secondary float-right mr-2 mb-3'
                onClick={resetFilters}
              >
                Reset Filters
              </button>
            </div>
            <div className='col-md-12 ag-theme-alpine' style={{ height: '500px' }}>
              <AgGridReact
                onGridReady={onGridReady}
                columnDefs={getColumnDefs()}
                rowData={filteredRegistrations}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={50}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationList;
