
import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { ProjectData } from '../../@types/projecttype';
import server from '../Utils';
import Loader from './Loader';
const AddProduct = () => {
    const [projects,setProjects]=useState<Array<ProjectData>>([])
    const [project_id,setProjectId]=useState<number>(0);
    const [product_name,setProductName]=useState<string>("");
    const [product_price,setProductPrice]=useState<number>();
   
    const [error,setError]=useState<string>();
    const [loading, setLoading] = useState(false);

    const addPackage=(e:React.FormEvent<HTMLFormElement>)=>
    {

        e.preventDefault();
        // var regEx = /^[A-Za-z]+$/;
        var regEx=/^\d+$/gs;
        var name:any = product_name;
        if(regEx.test(name))
        {
           setError("Product Name should be alphabets and special characters only"); 
           toast(error);
        }
        
        else{
            setError('');
            var token=localStorage.getItem('auth_token');
            console.log(token)
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            var form=document.getElementById('add_package_form') as HTMLFormElement;
                var formdata=new FormData(form);
            server.post(`/add_product`,formdata,{ headers: { 'Authorization': 'Bearer '+token } })
            .then((response)=>{
                if(response.data.code===200)
                {
                    toast(response.data.message)
                    setTimeout(function () { window.location.href = "/products"; }, 1000);
                }else
                {
                    toast(response.data.message)
                }
            })

        }
    }
    useEffect(() => {

        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let id= +projectid
        setProjectId(id)
    //   server.get(`/get_projects`)
    //   .then((response)=>{
    //     setProjects(response.data.projects)

    //   })
    }, []);
    
 if (loading) {
    return <Loader />;
  }
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}

    <h4 className='text-danger text-left margin-left'>Add Product</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>
    <div className='col-md-1'></div>
    <div className='col-md-10'>
        <form id="add_package_form" className='w-100' onSubmit={addPackage}>
            <div className='row'>
                {/* <div className='col-md-4'>
                    <label >Project</label>
                    <select className='form-control' name='project_id' id="project_id" onChange={(e)=>setProjectId(parseInt(e.target.value))}>
                        <option value={0}>Please select the program</option>
                        {projects.map((project,index)=>(
                            <option value={project.id}>{project.project_name}</option>))}
                    </select>
                </div> */}
                <input type="hidden" name="project_id"  id="project_id" value={project_id} />
                <div className='col-md-4'>
                    <label >Product Name</label>
                    <input className='form-control' type="text" placeholder='Enter Package' value={product_name} onChange={(e)=>setProductName(e.target.value)} name="product_name" id="product_name" required />
                </div>
                <div className='col-md-4'>
                <label >Product Price</label>
                    <input className='form-control' type="number" value={(product_price)} onChange={(e)=>setProductPrice(parseInt(e.target.value))} placeholder='Enter Price' name="product_price" id="product_price" required />
                </div>
            </div>
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Submit</button></div>
        </form>
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default AddProduct