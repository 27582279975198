import React,{useState,useEffect} from 'react'
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { StageData } from '../../@types/stagetype';
import {ProductData} from '../../@types/producttype';
import { SubStageData } from '../../@types/substagetype';
import { ProjectData } from '../../@types/projecttype';
import { useParams } from 'react-router-dom';
import server from '../Utils';
import Loader from './Loader';

const EditServiceCategory = () => {
    
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [loading, setLoading] = useState(false);

    const [project_id,setProjectId]=useState<number>(0);
    const [product_id,setProductId]=useState<number>(0)
   
    const [error,setError]=useState<string>('')
    // const [service,setService]=useState<string>('')
  
    const[service_category_name,setServiceCategory]=useState<string>('');
    const {id}=useParams();
    const servicetypeid=id;
    useEffect(() => {
      

        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let id= +projectid
        setProjectId(id)
        server.get(`/get_product/${id}`)
        .then((response)=>{
            
            setProducts(response.data.product)
            
        })
        server.get(`/get_service_category/${servicetypeid}`)
        .then((response)=>{
            console.log(response.data)
            setProductId(response.data.service_category.product_id)
            setServiceCategory(response.data.service_category.service_category_name)
        })
  
      }, []);

      
         


      
    
      
      
      const updateServiceCategory=(e:React.FormEvent<HTMLFormElement>)=>{
        e.preventDefault();
        var regEx = /^[A-Za-z]+$/;
        // var name:any = service;
        if(product_id===0)
        {
         setError("Select Package"); 
         toast(error);
        }else if(service_category_name===""||service_category_name===null){
          toast('Enter Service Category')
         
        }
      
       else{
        var form=document.getElementById('update_service_category_form') as HTMLFormElement ;
        var formData= new FormData(form);
        server.post(`/update_service_category/${servicetypeid}`,formData)
        .then((response)=>{
            console.log(response.data)
            if(response.data.code===200)
            {
                toast(response.data.message)
                form.reset();
                setTimeout(function () { window.location.href = "/service_categories"; }, 1000);
            }else
            {
                toast(response.data.message)
            }
        })
    }

}
if (loading) {
    return <Loader />;
  } 

      
      return (
        <div className='conatiner-fluid'>
        <div className='d-flex'>
            <SideBar/>
            <div className='headerbar'>
            <Header/>
            {/* Content Row */}

        <h4 className='text-danger text-center'>Edit Service Category</h4>
        <hr className='bg-danger px-1' />
      <div className='row mt-5'>
        <div className='col-md-1'></div>
        <div className='col-md-10'>
            <form id="update_service_category_form" onSubmit={updateServiceCategory} className='w-100' >
                <div className='row'>
                  <input type="hidden" value={project_id} name="project_id" id="project_id" />
                {project_id!==0?<div className='col-md-3'>
                <label >Products</label>
                     <select className='form-control' name='product_id' id="product_id" value={product_id} onChange={(e)=>setProductId(parseInt(e.target.value))} required>
                        <option value={0}>Select Product</option>
                        {products.map((product)=>(
                            <option value={product.id}>{product.product_name}</option>))}
            </select>
            </div>:""}
            {product_id!==0?<div className='col-md-3'>
                <label >Service Category</label>
                    <input type="text" className='form-control' placeholder="Enter Service Category" value={service_category_name} name="service_category_name" onChange={(e)=>setServiceCategory(e.target.value)} />
            </div>:""}
                </div>
                <ToastContainer />
               <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Submit</button></div>
            </form>
        </div>
      </div>
            </div>
            {/* header bar ends here */}
           
            {/* Content Row */}
              
        </div>
       
    </div>
      )

}
export default EditServiceCategory