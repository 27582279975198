import React, { useState,useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import SideBar from '../../pages/SideBar';
import Header from '../../pages/Header';
import {Link,useParams} from 'react-router-dom'
import { vendortype } from '../../@types/vendortype';
import server from '../Utils';
import axios from 'axios';
import UserInfo from '../UserInfo';
import Loader from './Loader'; // import the Loader component


const AddCab: React.FC = () => {
  const [project_id,setProjectId] = useState<number>(0);
  const [product_id,setProductId] = useState<number>(0);
  const [vendors,setVendors]=useState<Array<vendortype>>([]);
  const [vendor_id,setVendorId]=useState<number>(0)
  const {id}= useParams();
  const user_id=id;
  const [flightNumber, setFlightNumber] = useState<string>('');
  const [arrivalDate, setArrivalDate] = useState<string>('');
  const [arrivalTime, setArrivalTime] = useState<string>('');
  const [selectedVendor, setSelectedVendor] = useState<string>('');
  const [sendPickupEmail, setSendPickupEmail] = useState<boolean>(false);
  const [pickupLocation, setPickupLocation] = useState<string>('');
  const [pickupPinCode, setPickupPinCode] = useState<string>('');
  const [dropLocation, setDropLocation] = useState<string>('');
  const [dropPinCode, setDropPinCode] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
    let id = +projectid;
    setProjectId(id)
    let productid:string=JSON.parse(localStorage.getItem("product_id")||'{}');
    let product_id = +productid;
    setProductId(product_id);
    
  // server.get(`/vendor_for_travel/${projectid}/${product_id}`)
  // .then((response)=>{
  //   setVendors(response.data.vendors)
  // })
  if(product_id===1){
    server.get(`/vendor_on_service_types/24`)
    .then((response)=>{
      // console.log(response.data.vendors)
      setVendors(response.data.vendors)
        
    })
  }else if(product_id===2){
    server.get(`/vendor_on_service_types/64`)
    .then((response)=>{
      // console.log(response.data.vendors)
      setVendors(response.data.vendors)
        
    })
  }else if(product_id===3){
    server.get(`/vendor_on_service_types/96`)
    .then((response)=>{
      // console.log(response.data.vendors)
      setVendors(response.data.vendors)
        
    })
  }
  // server.get(`/vendor_on_service_types/24`)
  // .then((response)=>{
    
  //     setVendors(response.data.vendors)
  // })
 
  }, []);
  const handleValidation = () => {
    let isValid = true;
  
    if (!flightNumber) {
      toast.error('Flight Number is required');
      isValid = false;
    }
  
    if (!arrivalDate) {
      toast.error('Arrival Date is required');
      isValid = false;
    }
  
    if (!arrivalTime) {
      toast.error('Arrival Time is required');
      isValid = false;
    }
  
    if (!vendor_id) {
      toast.error('Select Vendor is required');
      isValid = false;
    }
  
    if (!pickupLocation) {
      toast.error('Pick-up Location is required');
      isValid = false;
    }
  
    if (!pickupPinCode) {
      toast.error('Pick-up Pin Code is required');
      isValid = false;
    }
  
    if (!dropLocation) {
      toast.error('Drop Location is required');
      isValid = false;
    }
  
    if (!dropPinCode) {
      toast.error('Drop Pin Code is required');
      isValid = false;
    }
  
    return isValid;
  };
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  
    if (handleValidation()) {
      // setLoading(true)
        try {
            var form= document.getElementById('addcab') as HTMLFormElement;
            var formData=new FormData(form)           
            server.post('/add_cab', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response)=>{
              if(response.data.status===200){
                // setLoading(false)
                toast(response.data.message);
                setTimeout(function () { window.history.back(); }, 1000);
              }else{
                // setLoading(false)
                toast.error(response.data.message);
               
              }
            })
      
            // Handle success (existing code)
      
          } catch (error) {
            // Handle error (existing code)
          }  
          }
  };
  
  if (loading) {
    return <Loader />;
  }

  return (
    <div className='container-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}

<UserInfo />
    <h4 className='text-danger text-center'>Add Cab  </h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5 jumbotron mx-3'>
   
   
    <div className='col-md-12 px-5'>
    <form id="addcab" onSubmit={handleSubmit} className='w-100'>
    <input type="hidden" value={project_id} name="project_id"/>
          <input type="hidden" value={product_id} name="product_id"/>
          <input type="hidden" value={user_id} name="user_id"/>
  <div className='container-fluid'>
    <div className='row'>
      {/* First Column */}
      <div className='col-md-4'>
        <div className='row mb-3'>
          <div className='col-md-12'>
            <label className='form-label'>Flight Number:</label>
            <input
              type='text'
              className='form-control'
              name='flightNumber'
              value={flightNumber}
              onChange={(e) => setFlightNumber(e.target.value)}
              required
            />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-md-6'>
            <label className='form-label'>Arrival Date:</label>
            <input
              type='date'
              className='form-control'
              name='arrivalDate'
              value={arrivalDate}
              onChange={(e) => setArrivalDate(e.target.value)}
              required
            />
          </div>
          <div className='col-md-6'>
            <label className='form-label'>Arrival Time:</label>
            <input
              type='time'
              className='form-control'
              name='arrivalTime'
              value={arrivalTime}
              onChange={(e) => setArrivalTime(e.target.value)}
              required
            />
          </div>
        </div>
      </div>

      {/* Second Column */}
      <div className='col-md-4'>
        <div className='row mb-3'>
          <div className='col-md-12'>
            <label className='form-label'>Select Vendor:</label>
            <select
              className='form-control'
              name='vendor_id'
              value={vendor_id}
              onChange={(e) => setVendorId(parseInt(e.target.value))}
              required
            >
              {/* Populate vendors from API or state */}
              <option value={0}>Select Vendor</option>
                {vendors.map((vendor)=>(
                  <option value={vendor.id}>{vendor.vendor_name}</option>
                ))}
            </select>
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-md-12'>
            <div className='form-check'>
              <input
                type='checkbox'
                className='form-check-input'
                name='sendPickupEmail'
                checked={sendPickupEmail}
                onChange={() => setSendPickupEmail(!sendPickupEmail)}
              />
              <label className='form-check-label'>Send Pick-up Email</label>
            </div>
          </div>
        </div>
      </div>

      {/* Third Column */}
      <div className='col-md-4'>
        <div className='row mb-3'>
          <div className='col-md-12'>
            <label className='form-label'>Pick-up Location:</label>
            <input
              type='text'
              className='form-control'
              name='pickupLocation'
              value={pickupLocation}
              onChange={(e) => setPickupLocation(e.target.value)}
              required
            />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-md-6'>
            <label className='form-label'>Pin Code:</label>
            <input
              type='text'
              className='form-control'
              name='pickupPinCode'
              value={pickupPinCode}
              onChange={(e) => setPickupPinCode(e.target.value)}
              required
            />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-md-12'>
            <label className='form-label'>Drop Location:</label>
            <input
              type='text'
              className='form-control'
              name='dropLocation'
              value={dropLocation}
              onChange={(e) => setDropLocation(e.target.value)}
              required
            />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-md-6'>
            <label className='form-label'>Pin Code:</label>
            <input
              type='text'
              className='form-control'
              name='dropPinCode'
              value={dropPinCode}
              onChange={(e) => setDropPinCode(e.target.value)}
              required
            />
          </div>
        </div>
       
      </div>
      <div className='row mb-3'>
          <div className='col-md-12'>
            <label className='form-label'>Notes</label><br/>
            <textarea value={notes} name={"notes"} onChange={(e)=>setNotes(e.target.value)} className='form-control'></textarea>
          </div>
        </div>
    </div>
    <ToastContainer />
    {/* ... (rest of your form) */}

    <div className='text-center mt-4'>
      <button type='submit' className='btn btn-info text-white mt-2'>
        Add Cab
      </button>
    </div>
  </div>
</form>

   
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default AddCab;