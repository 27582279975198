import { LoginAction,LoginState } from "../../@types/login";

export const LoginReducer =(state:LoginState,action:LoginAction):LoginState=>
{
    switch(action.type)
    {
        case "REQUEST_LOGIN":
            return{
                ...state,
                loading:false,
                isloggedIn:action.payload,
            }
        case "LOGIN_SUCCESS":
            return{
                ...state,
                userDetails:action.payload
            }
        case "SET_TOKEN":
            return {
                ...state,
                token:action.payload
            }    
        case "LOGOUT":
            return{
                ...state,
                isloggedIn:false,
                loading:true,
            } 
        case "LOGIN_ERROR":
            return{
                ...state,
                isloggedIn:false,
                loading:true,
                error:action.payload,
            }           
    }
}