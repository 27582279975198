import React,{useState,useEffect, FormEvent} from 'react'
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { SpecialityData } from '../../@types/specialitytype';
import pencil from '../../assets/images/pencil.png';
import Swal from 'sweetalert2';
import server from '../Utils';
import Loader from './Loader';
import DataTable from 'react-data-table-component';
const Specialities = () => {
  const [specialities,setSpecialities]=useState<Array<SpecialityData>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    server.get(`/specialities`)
    .then((response)=>{
         if(response.data.specialities.length>0){ 
      setSpecialities(response.data.specialities)
         }else{
            setSpecialities([])
         }
})
  }, []);

  const tableCustomStyles = {
    headRow: {
      style: {
        color:'#fff',
        backgroundColor: '#476cd9'
      },
    },
    striped: {
        default: 'red'
    },
  }
  const columns = [
    {
      name: 'SL No',
      selector: (row: any) => row.id,
      sortable: true,
      maxWidth: "50px",
    },
    {
      name: 'Training Program',
      selector: (row: any) => row.type_of_training_program===1?"GP":row.type_of_training_program===2?"Run-through":row.type_of_training_program===3?"Uncoupled":"",
      sortable: true,
      maxWidth: "130px",
    },
    {
      name: 'Speciality',
      selector: (row: any) => row.speciality,
      sortable: true,
      
    },
    {
      name: 'Actions',
      selector: (row: any) => (
        <div className='row'>
          <div className='col-md-6'>
            <Link className='text-right' to={`/speciality/${row.id}`}><img width="20px" height="20px" src={pencil} alt="pencil"/></Link>
          </div>
          <div className='col-md-6'>
            <i style={{ marginTop: "4%" }} onClick={() => deleteSpeciality(row.id)} className='fa fa-trash'></i>
          </div>
        </div>
      ),
      sortable: true,
    },
  ];
  
  
  const deleteSpeciality=(id:number)=>{
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
            server.delete(`/speciality/${id}`)
            .then((response)=>{
                if(response.data.specialities.length>0){ 
                    setSpecialities(response.data.specialities)
                    swalWithBootstrapButtons.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                      });
                       }else{
                          setSpecialities([])
                       }
         })
         
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Your imaginary file is safe :)",
            icon: "error"
          });
        }
      });
   

  }

  if (loading) {
    return <Loader />;
  }
  return (
    <div className='container-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}

    <h4 className='text-danger text-center'>Specialities</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>
    
    <div className='col-md-11 ml-3'>
    <Link to="/add_speciality" className='btn btn-outline-primary float-right my-3'>Add SubSpeciality</Link>  
      {/* <table className='table table-bordered table-striped table-hover'>
        <tr className='bg-warning'><th>Sl no</th><th>Training Program</th><th>Speciality</th><th className='text-center' colSpan={2}>Actions</th></tr>
      
      {specialities.map((speciality,index)=>(
        <tr><td>{index+1}</td><td>{speciality.type_of_training_program===1?"GP":speciality.type_of_training_program===2?"Run-through":speciality.type_of_training_program===3?"Uncoupled":""}</td><td>{speciality.speciality}</td><td><Link className='text-right' to={`/speciality/${speciality.id}`}><img width="20px" height="20px" src={pencil} alt="pencil"/></Link></td><td><i style={{marginTop:"4%"}} onClick={()=>deleteSpeciality(speciality.id)} className='fa fa-trash'></i></td></tr>
      ))}
       </table> */}
         <DataTable  data={specialities} columns={columns} customStyles={tableCustomStyles} paginationPerPage={50} paginationRowsPerPageOptions={[50,10,25,100]} pagination/>
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default Specialities