import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';

import {Link,useParams} from 'react-router-dom';

import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { message } from 'antd';

import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import $ from 'jquery'; 

import validator from 'validator';
import { off } from 'process';
import { type } from 'os';
import { vendortype } from '../../@types/vendortype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import { ServiceData } from '../../@types/servicetype';
import {service_categorytypedata} from '../../@types/service_categorytypedata';
import server from '../Utils';
import UserInfo from '../UserInfo';
import Loader from './Loader';
import { multipleusertype } from '../../@types/multipleusertype';
import Multiselect from 'multiselect-react-dropdown';

const AddMultiplePortFolio = () => {
    const [project_id,setProjectId] = useState<number>(0);
    
    const [assesment_date,setAssesmentDate] =useState<string>('');
    const [service_category_id,setServiceCategoryId]=useState<number>(0);
    const [service_categories,setServiceCategories]=useState<Array<ServiceCategoryType>>([])
    const [services,setServices]=useState<Array<ServiceData>>([])
    const [service_id,setServiceId]=useState<number>(0)
    const [vendors,setVendors]=useState<Array<vendortype>>([]);
    const [vendor_id,setVendorId]=useState<number>(0);
    const [mode_of_training,setModeOfTraining]=useState(0);
    const[subscription_start_date,setSubscriptionStartDate]=useState<string>('')
    
    const[course_period,setCoursePeriod]=useState<number>(0);
    const[delivery_method,setDeliveryMethod]=useState<number>(0);
    const [service_category_type,setCategoryTypeId]=useState<number>(0)
    const [username,setUsername]=useState<string>('')
    const [password,setPassword]=useState<string>('')
    const [course_venue,setCoureVenue]=useState<string>('');
    const [mode_of_delivery,setModeOfDelivery]=useState<number>(0);
    const [course_allocated_date,setCourseAllocatedDate]=useState<string>('');
    const [course_start_date,setCourseStartDate]=useState<string>('')
    const [course_duration,setCourseDuration]=useState<number>(0);
  //research And Publication states
  
    const [research_batch_name,setresearchBatchName]=useState<string>('');
    const [research_start_date,setresearchStartDate]=useState<string>('');
    const [research_topic,setresearchTopic]=useState('');
    const [research_end_date,setresearchEndDate]=useState('');
    const [research_bieng_published,setresearchBiengPublished]=useState<number>(0);
    const [publication_name,setPublicationName]=useState<string>('');
  
    // NGO states
    const [ngo_batch_name,setNgoBatchName]=useState('');
    const [ngo_description,setNgoDescription]=useState('');
    const [ngo_start_date,setNgoStartDate]=useState('');
    const [ngo_end_date,setNgoEndDate]=useState('');
    
    // teaching states
    const [teaching_date,setTeachingDate]=useState<string>('');
    const [teaching_topic,setTeachingTopic]=useState<string>('');
    const [feedback_form_link,setFeedBackFormLink]=useState<string>('');
    
    
    const [a_qip_batch_name,setAQIPBatchName]=useState<string>('');
    const [a_qip_topic_name,setAQIPTopicName]=useState<string>('');
    const [a_qip_start_date,setAQIPStartDate]=useState<string>('');
    const [a_qip_end_date,setAQIPEndDate]=useState<string>('');
    const [qip_name,setQipName]=useState<string>('');



    const [participation_type,setParticipationType]=useState<number>(0);
    const [conference_mode,setConferenceMode]=useState<number>(0);
    const [conference_venue,setConferenceVenue]=useState<string>('');
    const [conference_date,setConferenceDate]=useState<string>('');
    const [loading, setLoading] = useState(false);

    const [users,setUsers]=useState<Array<multipleusertype>>([]);
    const [user_ids,setUserIds]=useState<Array<number>>([])
    // var options: SelectProps['options'] = [];
    const [selectedValue,setSelectedValue]=useState<Array<multipleusertype>>([])
    const [error,setError]=useState(false)


    // const {id}= useParams();
    // const userid=id;
    const [products, setProducts] = useState<Array<ProductData>>([]);
    const [product_id, setProductId] = useState<number>(0);
    const [count, setCount] = useState<number>(0);
    const [service_category_types,setServiceCategorytypes]=useState<Array<service_categorytypedata>>([])
    const getCurrentDate = () => {
      const today = new Date();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const year = today.getFullYear();
  
      const formattedMonth = month < 10 ? `0${month}` : `${month}`;
      const formattedDay = day < 10 ? `0${day}` : `${day}`;
  
      return `${year}-${formattedMonth}-${formattedDay}`;
    };
    useEffect(() => {
      let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
      let id = +projectid;
      setProjectId(id);
      server.get(`/get_product/${id}`)
      .then((response) => {
        setProducts(response.data.product);
      });
    
      server.get(`/service_types_basedon_categories/5`)
      .then((response)=>{
         
          if(response.data.service_category_types.length>0){
              setServiceCategorytypes(response.data.service_category_types)
          }else{
              setServiceCategorytypes([]);
          }
      })
      // server.get(`/get_service_categories`)
      // .then((response)=>{
      //     console.log(response)
      //     if(response.data.service_categories.length>0){
      //       setServiceCategories(response.data.service_categories)
      //     }else{
      //       setServiceCategories([]);
      //     }
      // })

      // server.get(`/vendors/${id}`)
      // .then((response)=>{
      //   setVendors(response.data.vendors)
  
      // })
    }, []);
    const getServices =(id:number)=>{
      setVendorId(0); 
        setCategoryTypeId(id)
       
        server.get(`/service_on_category_type/${id}`)
        .then((response)=>{
          console.log(response.data.services)
            setServices(response.data.services)
        })
        server.get(`/vendor_on_service_types/${id}`)
        .then((response)=>{
          
            setVendors(response.data.vendors)
        })
       }
       const addPortfolioForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    
        let research_date1 = new Date(research_start_date).getTime();
        let research_date2 = new Date(research_end_date).getTime();
        let ngo_start_day = new Date(ngo_start_date).getTime();
        let ngo_end_day = new Date(ngo_end_date).getTime();
        let aqip_start_date = new Date(a_qip_start_date).getTime();
        let aqip_end_date = new Date(a_qip_end_date).getTime();
        let form = e.currentTarget;
        let errorFlag = false;
    
        if (service_category_type === 0) {
            toast('Select portfolio Activity Type');
            errorFlag = true;
        } else if ((service_category_type===21 || service_category_type===61||service_category_type===93 )&& vendor_id === 0) {
            toast('Select Portfolio');
            errorFlag = true;
        } else if ((service_category_type===21 || service_category_type===61||service_category_type===93 ) && research_bieng_published === 0) {
            toast('Check Research Being Published');
            errorFlag = true;
        } else if ((service_category_type===21 || service_category_type===61||service_category_type===93 ) && validator.isEmpty(research_batch_name)) {
            toast('Enter Research Batch Name');
            errorFlag = true;
        } else if ((service_category_type===21 || service_category_type===61||service_category_type===93 ) && validator.isEmpty(research_topic)) {
            toast('Enter Research Topic');
            errorFlag = true;
        } else if ((service_category_type===21 || service_category_type===61||service_category_type===93 ) && validator.isEmpty(research_start_date)) {
            toast('Enter Research Start Date');
            errorFlag = true;
        } else if ((service_category_type===21 || service_category_type===61||service_category_type===93 ) && validator.isEmpty(research_end_date)) {
            toast('Enter Research End Date');
            errorFlag = true;
        } else if (research_date1 > research_date2) {
            toast(`Research End Date should be greater than Research Start Date`);
            errorFlag = true;
        } else if (research_date2 === research_date1) {
            toast(`Research End Date & Research Start Date can't be equal`);
            errorFlag = true;
        } else if ((service_category_type===17 || service_category_type===57||service_category_type===89 ) && vendor_id === 0) {
            toast('Select NGO Vendor');
            errorFlag = true;
        } else if ((service_category_type===17 || service_category_type===57||service_category_type===89 ) && validator.isEmpty(ngo_batch_name)) {
            toast('Enter NGO Batch Name');
            errorFlag = true;
        } else if ((service_category_type===17 || service_category_type===57||service_category_type===89 ) && validator.isEmpty(ngo_description)) {
            toast('Enter NGO Description');
            errorFlag = true;
        } else if ((service_category_type===17 || service_category_type===57||service_category_type===89 ) && validator.isEmpty(ngo_start_date)) {
            toast('Enter NGO Start Date');
            errorFlag = true;
        } else if ((service_category_type===17 || service_category_type===57||service_category_type===89 ) && validator.isEmpty(ngo_end_date)) {
            toast('Enter NGO End Date');
            errorFlag = true;
        } else if (ngo_start_day > ngo_end_day) {
            toast(`NGO End Date should be greater than NGO Start Date`);
            errorFlag = true;
        } else if (ngo_start_day === ngo_end_day) {
            toast(`NGO End Date & NGO Start Date can't be equal`);
            errorFlag = true;
        } else if ((service_category_type===20 || service_category_type===60||service_category_type===92 )&& validator.isEmpty(teaching_date)) {
            toast('Enter Teaching Date');
            errorFlag = true;
        } else if ((service_category_type===20 || service_category_type===60||service_category_type===92 )&& validator.isEmpty(teaching_topic)) {
            toast('Enter Teaching Topic');
            errorFlag = true;
        } else if ((service_category_type===20 || service_category_type===60||service_category_type===92 )&& validator.isEmpty(feedback_form_link)) {
            toast('Enter Feedback Form Link');
            errorFlag = true;
        } else if ((service_category_type===20 || service_category_type===60||service_category_type===92 ) && vendor_id === 0) {
            toast('Select Audit And QIP Vendor');
            errorFlag = true;
        } else if ((service_category_type===20 || service_category_type===60||service_category_type===92 ) && validator.isEmpty(a_qip_batch_name)) {
            toast('Enter Audit And QIP Batch Name');
            errorFlag = true;
        } else if ((service_category_type===20 || service_category_type===60||service_category_type===92 ) && validator.isEmpty(a_qip_topic_name)) {
            toast('Enter Audit And QIP Topic Name');
            errorFlag = true;
        } else if ((service_category_type===20 || service_category_type===60||service_category_type===92 ) && validator.isEmpty(a_qip_start_date)) {
            toast('Select Start Date');
            errorFlag = true;
        } else if ((service_category_type===20 || service_category_type===60||service_category_type===92 ) && validator.isEmpty(a_qip_end_date)) {
            toast('Select End Date');
            errorFlag = true;
        } else if (aqip_start_date > aqip_end_date) {
            toast(`Audit and QIP End Date should be greater than Audit and QIP Start Date`);
            errorFlag = true;
        } else if (aqip_start_date === aqip_end_date) {
            toast(`Audit and QIP End Date & Audit and QIP Start Date can't be equal`);
            errorFlag = true;
        } else if ((service_category_type===20 || service_category_type===60||service_category_type===92 ) && validator.isEmpty(qip_name)) {
            toast('Enter QIP name');
            errorFlag = true;
        } else if ((service_category_type===18 || service_category_type===58||service_category_type===90 )&& vendor_id === 0) {
            toast('Select Conference Vendor');
            errorFlag = true;
        } else if ((service_category_type===18 || service_category_type===58||service_category_type===90) && participation_type === 0) {
            toast('Select Participation Type');
            errorFlag = true;
        } else if ((service_category_type===18 || service_category_type===58||service_category_type===90) && conference_mode === 0) {
            toast('Select Mode of Conducting the Conference');
            errorFlag = true;
        } else if ((service_category_type===18 || service_category_type===58||service_category_type===90) && validator.isEmpty(conference_venue)) {
            toast('Enter Venue');
            errorFlag = true;
        } else if ((service_category_type===18 || service_category_type===58||service_category_type===90) && validator.isEmpty(conference_date)) {
            toast('Enter Date');
            errorFlag = true;
        }
    
        if (!errorFlag) {
            setError(false);
            let formData = new FormData(form);
    
            try {
                let successCount = 0;
                await Promise.all(selectedValue.map(async (element) => {
                    const response = await server.post(`/add_portfolio/${element.id}`, formData);
                    if (response.data.code === 200) {
                        successCount++;
                        // toast("Data added Successfully for " + element.user);
                    } else {
                        toast(response.data.message);
                    }
                }));
    
                if (successCount > 0 && successCount === selectedValue.length) {
                    toast("Data added Successfully for all the users");
                    setTimeout(function () { window.location.href = "/portfolio_list"; }, 1000);
                }
            } catch (error) {
                console.error('Error occurred during form submission:', error);
                toast('An error occurred during form submission. Please try again.');
            }
        }
    };
    
    

   
    const setallUsers = (id: number) => {
      setProductId(id);
      // setUserId(0);
      setError(true);
      setCount(count + 1);
      setSelectedValue([]);
      server.get(`get_operationuser/${id}`)
        .then((response) => {
          setUsers(response.data.users);
        });
    };
    if (loading) {
      return <Loader />;
    }
    const onSelect=(selectedList:[], selectedItem:any) => {
      setSelectedValue(selectedList)
      }
      const onRemove = (selectedList: multipleusertype[], removedItem: multipleusertype) => {
        setSelectedValue(selectedList);
      };
  return (
    <div className='container-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}
{/* <UserInfo/> */}
    <h4 className='text-danger text-center'>Add Multiple Portfolio Activity </h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5 jumbotron mx-3'>
  
   
    <div className='col-md-12 px-5'>
    {project_id !== 0 && <div className='col-md-6'>
              <label>Products</label>
              <select className='form-control' name='product_id' id="product_id" value={product_id} onChange={(e) => setallUsers(parseInt(e.target.value))} required>
                <option value={0}>Select Product</option>
                {products.map((product) => (
                  <option key={product.id} value={product.id}>{product.product_name}</option>
                ))}
              </select>
            </div>}
            <div className='col-md-12'>
              {product_id !== 0 && (
                users.length !== 0 ? (
                  <div>
                    <label>Users</label>
                    <Multiselect
                      options={users}
                      placeholder="Select doctors here"
                      selectedValues={selectedValue}
                      onSelect={onSelect}
                      displayValue="user"
                      onRemove={onRemove}
                    />
                  </div>
                ) : (
                  <p className="text-danger">
                    Users List can't be displayed because no user filled their details yet
                  </p>
                )
              )}
            </div>
        <form id="add_portfolio_form" onSubmit={addPortfolioForm}  encType='multipart/form-data' className='w-100'>
        
       {/* {users.length!==0?<Multiselect options={users} placeholder='Select doctors here' selectedValues={selectedValue} onSelect={onSelect} displayValue='user'/>:<p className='text-danger'>Users List cant be displayed because No user filled their details yet</p>} */}
        <br/>
          <div className='row'>
          <input type="hidden" name={"service_category_id"} id={"service_category_id"} value="5"/>
          <input type={'hidden'} name={'project_id'} value={project_id} />
                  <input type={'hidden'} name={'product_id'} value={product_id} />
            <div className='col-md-3'>
              <label>Portfolio Activity</label>
              <select id="service_category_type" className='form-control' name="service_category_type" value={service_category_type} onChange={(e)=>getServices(parseInt(e.target.value))}>
                <option value="0">Select Portfolio Activity</option>
                {service_category_types.map((service_category_type,index)=>(
                  <option value={service_category_type.id}>{service_category_type.service_category_type}</option>
                ))}
              </select>
            </div>
            {service_category_type===21 || service_category_type===61||service_category_type===93?<div className='col-md-3'>
              <label htmlFor="">Vendor</label>
               <select className='form-control' id="vendor_id" name="vendor_id" value={vendor_id} onChange={(e)=>setVendorId(parseInt(e.target.value))} > 
                  
                    <option value="0">Select Vendor </option>        
                    {vendors && vendors.map((vendor,index)=>(
                    <option value={vendor.id}>{vendor.vendor_name}</option>
                ))}   
                </select>             
                            
                </div>:""} 
               
                {service_category_type===21 || service_category_type===61||service_category_type===93?<div className='col-md-3'>
                <label htmlFor="">Research Batch Name</label>
                <input className='form-control'  type='text' name="research_batch_name" id="research_batch_name" value={research_batch_name} onChange={(e)=>setresearchBatchName(e.target.value)} required />         
                            
                </div>:""}  
                {service_category_type===21 || service_category_type===61||service_category_type===93?<div className='col-md-3'>
                <label htmlFor="">Research Topic :</label>
                <input className='form-control'  type='text' name="research_topic" id="research_topic" value={research_topic} onChange={(e)=>setresearchTopic(e.target.value)} required />         
                            
                </div>:""} 
                

             
                   </div>
                
               
                {service_category_type===21 || service_category_type===61||service_category_type===93?<div className='row my-4'>
                <div className='col-md-2'>
                  <label htmlFor="">Start Date :</label>
                  <input className='form-control'  type='date' name="research_start_date" id="research_start_date" value={research_start_date} onChange={(e)=>setresearchStartDate(e.target.value)}  required/>         
                </div>
                <div className='col-md-2'>
                  <label htmlFor="">End Date :</label>
                  <input className='form-control'  type='date' name="research_end_date" id="research_end_date" value={research_end_date} onChange={(e)=>setresearchEndDate(e.target.value)}  required />         
                </div>
                <div className='col-md-3'>
                  <label htmlFor="">Research Being Published:</label>
                  <select className='form-control'  name="research_bieng_published" id="research_bieng_published" value={research_bieng_published} onChange={(e)=>setresearchBiengPublished(parseInt(e.target.value))} >
                      <option value={0}> Select Research Being Published</option>
                      <option value={1}>Yes</option>
                      <option value={2}>No</option>
                  </select>         
                </div>
                <div className='col-md-2'>
                  <label htmlFor="">Publication Name:</label>
                  <input type='text' className='form-control'  name="publication_name" id="publication_name" value={publication_name} onChange={(e)=>setPublicationName(e.target.value)}  required/>
                             
                </div>
                <div className='col-md-3'>
                  <label htmlFor="">Publication Upload:</label>
                  <input type='file' className='form-control'  name="publication_upload" id="publication_upload"  />
                             
                </div>
                
                
                </div>:""}  


            {/* NGO  */}

                    {service_category_type===17 || service_category_type===57||service_category_type===89?<div className='row my-3'>
                    <div className='col-md-3'>
                      <label htmlFor="">Vendor</label>
                      <select className='form-control' id="vendor_id" name="vendor_id" value={vendor_id} onChange={(e)=>setVendorId(parseInt(e.target.value))} > 
             
                      <option value="0">Select Vendor </option>      
                    {vendors && vendors.map((vendor,index)=>(
                    <option value={vendor.id}>{vendor.vendor_name}</option>
                            ))}   
                      </select>             
              
                    </div> 
                    <div className='col-md-3'>
                      <label htmlFor="" >NGO Batch Name</label><br/>
                      <input className='form-control' type="text" name='ngo_batch_name' id='ngo_batch_name' value={ngo_batch_name} onChange={(e)=>setNgoBatchName(e.target.value)}  required/>
                    </div>
                    <div className='col-md-3'>
                      <label htmlFor="" >NGO Description</label><br/>
                      <input className='form-control' type="text" name='ngo_description' id='ngo_description' value={ngo_description} onChange={(e)=>setNgoDescription(e.target.value)}  required/>
                    </div>
                   
                    
                    
                    </div>:""}  

                    {service_category_type===17 || service_category_type===57||service_category_type===89?<div className='row my-3'>
                      <div className='col-md-3'>
                        <label htmlFor="" >Start Date</label><br/>
                        <input className='form-control' type="date" name='ngo_start_date' id='ngo_start_date' value={ngo_start_date} onChange={(e)=>setNgoStartDate(e.target.value)} required />
                      </div>
                      <div className='col-md-3'>
                        <label htmlFor="" >End Date</label><br/>
                        <input className='form-control' type="date" name='ngo_end_date' id='ngo_end_date' value={ngo_end_date} onChange={(e)=>setNgoEndDate(e.target.value)} required />
                      </div>
                      <div className='col-md-3'>
                        <label htmlFor="" >Upload Certificate </label><br/>
                        <input className='form-control' type="file" name='ngo_file' id='ngo_file' />
                      </div>
                      
                    </div>:""}
                    {service_category_type===19 || service_category_type===59||service_category_type===91?<div className='row my-3'>
                      <div className='col-md-3'>
                        <label htmlFor="" >Teaching Session 1</label><br/>
                        <input className='form-control' type="date" name='teaching_date' id='teaching_date' value={teaching_date} onChange={(e)=>setTeachingDate(e.target.value)} required />
                      </div>
                      <div className='col-md-3'>
                        <label htmlFor="" >Teaching Topic</label><br/>
                        <input className='form-control' type="text" name='teaching_topic' id='teaching_topic' value={teaching_topic} onChange={(e)=>setTeachingTopic(e.target.value)} required />
                      </div>
                      <div className='col-md-3'>
                        <label htmlFor="" >FeedBack Form Link</label><br/>
                        <input className='form-control' type="text" name='feedback_form_link' id='feedback_form_link'  value={feedback_form_link} onChange={(e)=>setFeedBackFormLink(e.target.value)} required />
                      </div>
                      
                    </div>:""}

                    {service_category_type===20 || service_category_type===60||service_category_type===92?<div className='row my-3'>
                    <div className='col-md-3'>
                      <label htmlFor="">Vendor</label>
                      <select className='form-control' id="vendor_id" name="vendor_id" value={vendor_id} onChange={(e)=>setVendorId(parseInt(e.target.value))} > 
             
                         <option value="0"> Select Audit And QIP Vendor</option>        
                         {vendors && vendors.map((vendor,index)=>(
                          <option value={vendor.id}>{vendor.vendor_name}</option>
                       ))} 
                      </select>             
              
                    </div>
                    <div className='col-md-3'>
                      <label htmlFor="">Batch Name :</label>
                      <input className='form-control' type="text" name="a_qip_batch_name" id="a_qip_batch_name" value={a_qip_batch_name} onChange={(e)=>setAQIPBatchName(e.target.value)} required />            
              
                    </div> 
                    <div className='col-md-3'>
                      <label htmlFor="">Topic :</label>
                      <input className='form-control' type="text" name="a_qip_topic_name" id="a_qip_topic_name" value={a_qip_topic_name} onChange={(e)=>setAQIPTopicName(e.target.value)} required />            
                    </div>  
                    <div className='col-md-3'>
                      <label htmlFor="">Start Date :</label>
                      <input className='form-control' type="date" name="a_qip_start_date" id="a_qip_start_date" value={a_qip_start_date} onChange={(e)=>setAQIPStartDate(e.target.value)} required />            
                    </div> 
                    
                    </div>:""}
                    {service_category_type===20 || service_category_type===60||service_category_type===92?<div className='row my-3'>
                    <div className='col-md-3'>
                      <label htmlFor="">End Date :</label>
                      <input className='form-control' type="date" name="a_qip_end_date" id="a_qip_end_date" value={a_qip_end_date} onChange={(e)=>setAQIPEndDate(e.target.value)} required />            
                    </div>  
                    <div className='col-md-3'>
                      <label htmlFor="">QIP Name:</label>
                      <input className='form-control' type="text" name="qip_name" id="qip_name" value={qip_name} onChange={(e)=>setQipName(e.target.value)} required />            
                    </div> 
                    <div className='col-md-3'>
                      <label htmlFor="">Upload Certificate:</label>
                      <input className='form-control' type="file" name="upload_certificate" id="upload_certificate"  />            
                    </div> 
                    <div className='col-md-3'>
                      <label htmlFor="">Upload QIP:</label>
                      <input className='form-control' type="file" name="upload_qip" id="upload_qip"   />            
                    </div>   
                    </div>:""}

                    {service_category_type===18 || service_category_type===58||service_category_type===90?<div className='row my-3'>
                    <div className='col-md-3'>
                      <label htmlFor="">Conference Vendor :</label>
                      <select className='form-control' id="vendor_id" name="vendor_id" value={vendor_id} onChange={(e)=>setVendorId(parseInt(e.target.value))} > 
             
                         <option value="0"> Select Conference Vendor</option>        
                            {vendors && vendors.map((vendor,index)=>(
                             <option value={vendor.id}>{vendor.vendor_name}</option>
                            ))}   
                      </select>             
              
                    </div>
                    <div className='col-md-2'>
                      <label htmlFor="">Participation Type:</label>
                      <select className='form-control' id="participation_type" name="participation_type" value={participation_type} onChange={(e)=>setParticipationType(parseInt(e.target.value))} > 
                         <option value="0"> Select Participation Type</option>  
                         <option value="1">Presenter</option>      
                         <option value="2">Attendee</option>      
                      </select>             
              
                    </div>
                    <div className='col-md-3'>
                      <label htmlFor="">Conference Mode:</label>
                      <select className='form-control' id="conference_mode" name="conference_mode" value={conference_mode} onChange={(e)=>setConferenceMode(parseInt(e.target.value))} > 
                         <option value="0"> Select Conference Mode</option>   
                         <option value="1">Online</option>      
                         <option value="2">Offline</option>      
                      </select>             
              
                    </div>
                    <div className='col-md-2'>
                      <label htmlFor="">Conference Venue:</label>
                     <input className='form-control'  type="text" name="conference_venue" id="conference_venue" value={conference_venue} onChange={(e)=>setConferenceVenue(e.target.value)} required />            
              
                    </div>
                    <div className='col-md-2'>
                      <label htmlFor="">Conference Date:</label>
                     <input  className='form-control' type="date" name="conference_date" id="conference_date" value={conference_date} onChange={(e)=>setConferenceDate(e.target.value)}  required/>            
              
                    </div>
                    </div>:""}
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-info text-white mt-2'>Add Details</button></div>
        </form>
        </div>
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   

  )
}

export default AddMultiplePortFolio;