import React,{useState,useEffect} from 'react'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link} from 'react-router-dom';
import axios from 'axios';
import UserSideBar from '../../pages/UserSideBar/UserSideBar';
import UserHeader from '../../pages/UserHeader/UserHeader';
import {UserData} from '../../@types/usertype';
import validator from 'validator';
import server from '../Utils';
import { Select } from 'antd';
import Loader from './Loader';
import UserProgressBar from '../UserProgressBar';
const { Option } = Select;

const UserProfile = () => {
  var loggedIn=localStorage.getItem('loggedIn');
  let userid= localStorage.getItem('ClientID');
  const [first_name,setfirstName]=useState<string>("");
  const [last_name,setlastName]=useState<string>('');
  const [passport_first_name,setPassportFirstName]=useState<string>("")
  const [passport_last_name,setPassportlastName]=useState<string>("")
  const [certificate_first_name,setCertificateFirstName]=useState<string>('')
  const [certificate_last_name,setCertificateLastName]=useState<string>('')
  const [primary_contact_country_code,setPrimaryCountryCode]=useState<string>('+91')
  const [primary_contact,setPrimaryContact]=useState<string>('')
  const [alernate_country_code,setAlternateCountryCode]=useState<string>('+91')
  const [alternate_phone_number,setAlternatePhoneNumber]=useState<string>('')
  const [user_email,setUserEmail]=useState<string>('')
  const [password,setPassword]=useState<string>('');
  const [last_password,setLastPassword]=useState<string>('');
  const [dob,setDob]=useState<string>('');
  const [fathers_full_name,setFathersFullName]=useState<string>('')
  const [fathers_email,setFathersEmail]=useState<string>('')
  const [fathers_contact_country_code,setFathersContactCountryCode]=useState<string>('+91')
  const [fathers_contact,setFathersContact]=useState<number|undefined>()
  const [mothers_full_name,setMothersFullName]=useState<string>()
  const [mothers_email,setMothersEmail]=useState<string>('');
  const [mothers_country_code,setMothersCountryCode]=useState<string>('+91');
  const [mothers_contact,setMothersContact]=useState<number|undefined>();
  const [passport_exist,setPassportExist]=useState<number>(0);
  const [passport_number,setPassportNumber]=useState<string>('');
  const [passport_issue_date,setPassportIssueDate]=useState<string>('');
  const [passport_expiry_date,setPassportExpiryDate]=useState<string>(''); 
  const [gender,setGender]=useState<number>(0); 
  const [loading, setLoading] = useState(false);
  const [form_progress,setFormProgress]=useState<number>(0);





  if(loggedIn!=="true")
  {
    window.location.href="/userlogin";
  }
  useEffect(() => {

    let userid= localStorage.getItem('ClientID');
    setLoading(true);
    
  server.get(`/operationuser/${userid}`)
  .then((response)=>{
    setfirstName(response.data.user.first_name)
    setlastName(response.data.user.last_name)
    
    setPassportFirstName(response.data.user.passport_first_name)
    setPassportlastName(response.data.user.passport_last_name)
    setCertificateFirstName(response.data.user.certificate_first_name)
    setCertificateLastName(response.data.user.certificate_last_name)
    setPrimaryCountryCode(response.data.user.primary_contact_country_code)
    setPrimaryContact(response.data.user.primary_contact)
    setGender(response.data.user.gender)
    setAlternateCountryCode(response.data.user.alernate_country_code)
    setAlternatePhoneNumber(response.data.user.alternate_phone_number)
    setUserEmail(response.data.user.user_email)
    setPassword(response.data.user.last_password)
    setLastPassword(response.data.user.last_password)
    setDob(response.data.user.dob)
    setFathersFullName(response.data.user.fathers_full_name)
    setFathersEmail(response.data.user.fathers_email)
    if(response.data.user.fathers_contact_country_code===null||response.data.user.fathers_contact_country_code===""){
      setFathersContactCountryCode('+91')
    }else{
    setFathersContactCountryCode(response.data.user.fathers_contact_country_code)
  }
    setFathersContact(response.data.user.fathers_contact)
    setMothersFullName(response.data.user.mothers_full_name)
    setMothersEmail(response.data.user.mothers_email)
    if(response.data.user.mothers_country_code===null|| response.data.user.mothers_country_code===""){
      setMothersCountryCode("+91")
    }else{
      setMothersCountryCode(response.data.user.mothers_country_code)
    }
  
    setMothersContact(response.data.user.mothers_contact)
    if(response.data.user.passport_exist===1 ){
      setPassportExist(1)
    }else if(response.data.user.passport_exist===2){
      setPassportExist(2)
    }else{
      setPassportExist(0)
    }
    setPassportNumber(response.data.user.passport_number)
    setPassportIssueDate(response.data.user.passport_issue_date)
    setPassportExpiryDate(response.data.user.passport_expiry_date)
    if(response.data.user.form_progress!==null){
      setFormProgress(response.data.user.form_progress)
    }else{
      setFormProgress(0)
    }
    setLoading(false)
  })
   
  }, []);
  console.log(passport_exist)


const countryOptions: { value: string; label: string }[] = [

  { value: '213',label:'+213'},
  { value: '213',label:'+213'},
  { value: '244',label:'+244'},
  { value: '1264',label:'+1264'},
  { value: '1268',label:'+1268'},
  { value: '54',label:'+54'},
  { value: '374',label:'+374'},
  { value: '297',label:'+297'},
  { value: '61',label:'+61'},
  { value: '43',label:'+43'},
  { value: '994',label:'+994'},
  { value: '1242',label:'+1242'},
  { value: '973',label:'+973'},
  { value: '880',label:'+880'},
  { value: '1246',label:'+1246'},
  { value: '375',label:'+375'},
  { value: '32',label:'+32'},
  { value: '501',label:'+501'},
  { value: '229',label:'+229'},
  { value: '1441',label:'+1441'},
  { value: '975',label:'+975'},
  { value: '591',label:'+591'},
  { value: '387',label:'+387'},
  { value: '267',label:'+267'},
  { value: '55',label:'+55'},
  { value: '673',label:'+673'},
  { value: '359',label:'+359'},
  { value: '226',label:'+226'},
  { value: '257',label:'+257'},
  { value: '855',label:'+855'},
  { value: '237',label:'+237'},
  { value: '1',label:'+1'},
  { value: '238',label:'+238'},
  { value: '1345',label:'+1345'},
  { value: '236',label:'+236'},
  { value: '56',label:'+56'},
  { value: '86',label:'+86'},
  { value: '57',label:'+57'},
  { value: '269',label:'+269'},
  { value: '242',label:'+242'},
  { value: '682',label:'+682'},
  { value: '506',label:'+506'},
  { value: '385',label:'+385'},
  { value: '53',label:'+53'},
  { value: '90392',label:'+90392'},
  { value: '357',label:'+357'},
  { value: '42',label:'+42'},
  { value: '45',label:'+45'},
  { value: '253',label:'+253'},
  { value: '1809',label:'+1809'},
  { value: '1809',label:'+1809'},
  { value: '593',label:'+593'},
  { value: '20',label:'+20'},
  { value: '503',label:'+503'},
  { value: '240',label:'+240'},
  { value: '291',label:'+291'},
  { value: '372',label:'+372'},
  { value: '251',label:'+251'},
  { value: '500',label:'+500'},
  { value: '298',label:'+298'},
  { value: '679',label:'+679'},
  { value: '358',label:'+358'},
  { value: '33',label:'+33'},
  { value: '594',label:'+594'},
  { value: '689',label:'+689'},
  { value: '241',label:'+241'},
  { value: '220',label:'+220'},
  { value: '7880',label:'+7880'},
  { value: '49',label:'+49'},
  { value: '233',label:'+233'},
  { value: '350',label:'+350'},
  { value: '30',label:'+30'},
  { value: '299',label:'+299'},
  { value: '1473',label:'+1473'},
  { value: '590',label:'+590'},
  { value: '671',label:'+671'},
  { value: '502',label:'+502'},
  { value: '224',label:'+224'},
  { value: '245',label:'+245'},
  { value: '592',label:'+592'},
  { value: '509',label:'+509'},
  { value: '504',label:'+504'},
  { value: '852',label:'+852'},
  { value: '36',label:'+36'},
  { value: '354',label:'+354'},
  { value: '91',label:'+91'},
  { value: '62',label:'+62'},
  { value: '98',label:'+98'},
  { value: '964',label:'+964'},
  { value: '353',label:'+353'},
  { value: '972',label:'+972'},
  { value: '39',label:'+39'},
  { value: '1876',label:'+1876'},
  { value: '81',label:'+81'},
  { value: '962',label:'+962'},
  { value: '7',label:'+7'},
  { value: '254',label:'+254'},
  { value: '686',label:'+686'},
  { value: '850',label:'+850'},
  { value: '82',label:'+82'},
  { value: '965',label:'+965'},
  { value: '996',label:'+996'},
  { value: '856',label:'+856'},
  { value: '371',label:'+371'},
  { value: '961',label:'+961'},
  { value: '266',label:'+266'},
  { value: '231',label:'+231'},
  { value: '218',label:'+218'},
  { value: '417',label:'+417'},
  { value: '370',label:'+370'},
  { value: '352',label:'+352'},
  { value: '853',label:'+853'},
  { value: '389',label:'+389'},
  { value: '261',label:'+261'},
  { value: '265',label:'+265'},
  { value: '60',label:'+60'},
  { value: '960',label:'+960'},
  { value: '223',label:'+223'},
  { value: '356',label:'+356'},
  { value: '692',label:'+692'},
  { value: '596',label:'+596'},
  { value: '222',label:'+222'},
  { value: '269',label:'+269'},
  { value: '52',label:'+52'},
  { value: '691',label:'+691'},
  { value: '373',label:'+373'},
  { value: '377',label:'+377'},
  { value: '976',label:'+976'},
  { value: '1664',label:'+1664'},
  { value: '212',label:'+212'},
  { value: '258',label:'+258'},
  { value: '95',label:'+95'},
  { value: '264',label:'+264'},
  { value: '674',label:'+674'},
  { value: '977',label:'+977'},
  { value: '31',label:'+31'},
  { value: '687',label:'+687'},
  { value: '64',label:'+64'},
  { value: '505',label:'+505'},
  { value: '227',label:'+227'},
  { value: '234',label:'+234'},
  { value: '683',label:'+683'},
  { value: '672',label:'+672'},
  { value: '670',label:'+670'},
  { value: '47',label:'+47'},
  { value: '968',label:'+968'},
  { value: '680',label:'+680'},
  { value: '507',label:'+507'},
  { value: '675',label:'+675'},
  { value: '595',label:'+595'},
  { value: '51',label:'+51'},
  { value: '63',label:'+63'},
  { value: '48',label:'+48'},
  { value: '351',label:'+351'},
  { value: '1787',label:'+1787'},
  { value: '974',label:'+974'},
  { value: '262',label:'+262'},
  { value: '40',label:'+40'},
  { value: '7',label:'+7'},
  { value: '250',label:'+250'},
  { value: '378',label:'+378'},
  { value: '239',label:'+239'},
  { value: '966',label:'+966'},
  { value: '221',label:'+221'},
  { value: '381',label:'+381'},
  { value: '248',label:'+248'},
  { value: '232',label:'+232'},
  { value: '65',label:'+65'},
  { value: '421',label:'+421'},
  { value: '386',label:'+386'},
  { value: '677',label:'+677'},
  { value: '252',label:'+252'},
  { value: '27',label:'+27'},
  { value: '34',label:'+34'},
  { value: '94',label:'+94'},
  { value: '290',label:'+290'},
  { value: '1869',label:'+1869'},
  { value: '1758',label:'+1758'},
  { value: '249',label:'+249'},
  { value: '597',label:'+597'},
  { value: '268',label:'+268'},
  { value: '46',label:'+46'},
  { value: '41',label:'+41'},
  { value: '963',label:'+963'},
  { value: '886',label:'+886'},
  { value: '7',label:'+7'},
  { value: '66',label:'+66'},
  { value: '228',label:'+228'},
  { value: '676',label:'+676'},
  { value: '1868',label:'+1868'},
  { value: '216',label:'+216'},
  { value: '90',label:'+90'},
  { value: '7',label:'+7'},
  { value: '993',label:'+993'},
  { value: '1649',label:'+1649'},
  { value: '688',label:'+688'},
  { value: '256',label:'+256'},
  { value: '44',label:'+44'},
  { value: '380',label:'+380'},
  { value: '971',label:'+971'},
  { value: '598',label:'+598'},
  { value: '1',label:'+1'},
  { value: '7',label:'+7'},
  { value: '678',label:'+678'},
  { value: '379',label:'+379'},
  { value: '58',label:'+58'},
  { value: '84',label:'+84'},
  { value: '84',label:'+1284'},
  { value: '84',label:'+1340'},
  { value: '681',label:'+681'},
  { value: '969',label:'+969'},
  { value: '967',label:'+967'},
  { value: '260',label:'+260'},
  { value: '263',label:'+263'},
 ]

 const submitUserPersonalDetails=(e:React.FormEvent<HTMLFormElement>)=>
{

  
  e.preventDefault();
  const re = /^[A-Za-z\s]*$/;
  const ne =/^[0-9]+$/;

let date1 = new Date(passport_issue_date).getTime();
let date2 = new Date(passport_expiry_date).getTime();
 
  
 console.log(fathers_contact_country_code)
   if(gender===0 || gender===null){
    toast('Select Gender');
   }
   else if(!validator.isEmail(user_email)){

    toast('Enter proper email');
   
  }else if(primary_contact_country_code.length>2 && primary_contact_country_code.length<1){
    toast("Primary contact code should be between 2 to 3 characters");
  }
  else if(primary_contact.length>10 && primary_contact.length<1 ){
      toast("Primary contact number Should be 10 digits");
    }else if(alernate_country_code.length>2 && alernate_country_code.length<1)
    {
     toast("Alternate contact code should be between 2 to 3 characters");
    }else if(alternate_phone_number.length>10 && alternate_phone_number.length<1){
      toast("Alternate contact number Should be 10 digits");
    }
    else if(fathers_email && !validator.isEmail(fathers_email))
    {
      toast("Invalid Fathers Email");
    }else if(mothers_email  && !validator.isEmail(mothers_email))
    {
      toast("Invalid Fathers Email");
    }else if(fathers_contact_country_code===null ||  fathers_contact_country_code===""){
      toast("Father's Country Code is required");
    }else if(mothers_country_code===null ||  mothers_country_code===""){
      toast("Mother's Country Code is required");
    }
    else if (passport_exist === null) {
      toast("Please select passport status");
  }
  //  else if (passport_exist === 1 && passport_number.length !== 8) {
  //     toast('Passport number should be one alphabet and 7 numbers');
  // }
   else if (passport_exist === 1 && date1 >= date2) {
      toast('Passport expiry date should be greater than issue date');
  } else{
    
      setLoading(true)
      var form = document.getElementById('user_presonal_details_form') as HTMLFormElement;
      var formdata= new FormData(form);
      server.post(`/add_personal_details/${userid}`,formdata)
      .then((response)=>{
       if(response.data.code===200){
        setLoading(false)
        toast(response.data.message);
        
        if(loading===false){
             setTimeout(function() { window.location.href="/user_address"}, 100);
        }
       }else{
        setLoading(false)
        toast(response.data.message[0]);
        
       }
      })}
}

 if (loading) {
  return <Loader />;
}
  return (
    <div className='container-fluid desktop_container' >
    <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name}/>
    <div className='horizontal-menu' style={{background:"transparent"}}>

      <div className='container-fluid mt-3'>
        {/* <div className='row mt-3'>
          <div className='col text-left px-4'><h3><b>Hi Dr {first_name} {last_name}</b></h3></div>
          <div className='col text-right pr-5'><h3><i className="fa fa-question-circle" aria-hidden="true"></i>Support</h3></div>
        </div> */}
       
        <br/>
        </div>
       
       
        <div className='container-fluid mt-3'>
        <div className='row mt-3'>
          <div className='col-md-3 text-center px-4'><h2 className='blue-color'><b>Profile Details</b></h2></div>
          <div className='col-md-9 mt-2 px-5'><UserProgressBar form_progress={form_progress}/></div>
        </div>
        </div>
         <div className='user_foarm_board userpath_right_pad pt-2 mx-3 mt-5'>
         <h3 className='text-left my-4 blue-color fw-bold'>Personal Details</h3>
           <form className='w-100' id="user_presonal_details_form" onSubmit={submitUserPersonalDetails}>
            <input type='hidden' name="form_progress" id="form_progress" value={1}/>
             <div className='row mt-3'>
            
           
              <div className='col-md-3'>
               <label className="blue-color">First Name<br/> <span  style={{fontSize:"10px"}} >(According to Aadhaar / Passport)</span> <b className="text-danger">*</b></label>
               <input type="text" className='form-control rounded user_input_dashboard' name="passport_first_name" id="passport_first_name" value={passport_first_name} onChange={(e)=>setPassportFirstName(e.target.value)}  placeholder='Passport First Name' required />
              </div>
              <div className='col-md-3'>
               <label className="blue-color">Last Name<br/> <span style={{fontSize:"10px"}} >(According to Aadhaar / Passport)</span><b className="text-danger">*</b></label>
               <input type="text"className='form-control rounded user_input_dashboard'  name="passport_last_name" id="passport_last_name"  value={passport_last_name} onChange={(e)=>setPassportlastName(e.target.value)} placeholder='Passport Last Name' required/>
              </div>
              <div className='col-md-3'>
               <label className="blue-color">First Name <br/> <span style={{fontSize:"10px"}} >(According to Degree Certificate)</span><b className="text-danger">*</b></label>
               <input type="text"className='form-control rounded user_input_dashboard'  name="certificate_first_name" id="certificate_first_name" value={certificate_first_name}  onChange={(e)=>setCertificateFirstName(e.target.value)}  placeholder='Certificate First Name' required/>
              </div>
              <div className='col-md-3'>
               <label className="blue-color">Last Name <br/> <span style={{fontSize:"10px"}} >(According to Degree Certificate)</span><b className="text-danger">*</b></label>
               <input type="text"className='form-control rounded user_input_dashboard'  name="certificate_last_name" id="certificate_last_name" value={certificate_last_name} onChange={(e)=>setCertificateLastName(e.target.value)}  placeholder='Certificate Last Name' required/>
              </div>
             
             </div>
            <div className='row my-4'>
            <div className="col-md-2">
               <label className="blue-color">Gender<b className="text-danger">*</b></label>
               <select className='form-control rounded user_input_dashboard mt-1' id="gender" name="gender" value={gender} onChange={(e)=>setGender(parseInt(e.target.value))}>
                 <option value={0}>Select Gender</option>
                 <option value={1}>Male</option>
                 <option value={2}>Female</option>
                 <option value={3}>Others</option>
               </select>
             </div>
             <div className='col-md-3'>
                <label className="blue-color">Email<b className="text-danger">*</b></label>
                 <input type="email" className='form-control rounded user_input_dashboard' name="user_email" id="user_email"  value={user_email} placeholder='Enter Email' onChange={(e)=>setUserEmail(e.target.value)} required/>
              </div>
              <div className='col-md-2'>
                 <label className="blue-color mt-2">Date of Birth<b className="text-danger">*</b></label>
                 <input type="date" className='form-control rounded user_input_dashboard'  name="dob" id="dob"  value={dob} placeholder='Enter Date Of Birth' onChange={(e)=>setDob(e.target.value)} required/>
              </div>
            </div>
             
             <div className='row mt-4'>
              
             

              <div className="col-md-4">
              <label className="blue-color">Primary Contact Number<b className="text-danger">*</b></label>
                <div className='d-flex mt-1'>
                
                
                <div className="user_profile_contact_code">
                 
                 {/* <input type="text" className='form-control rounded user_input_dashboard'  name="primary_contact_country_code" id="primary_contact_country_code"  value={primary_contact_country_code} placeholder='Enter Date Of Birth' onChange={(e)=>setPrimaryCountryCode(e.target.value)} required/> */}
                 {/* <select  className='form-control rounded user_input_dashboard' name="primary_contact_country_code" id="primary_contact_country_code" value={primary_contact_country_code!==""?primary_contact_country_code:"91"} onChange={(e)=>setPrimaryCountryCode(e.target.value)} required > */}
                 <Select
               id='primary_contact_country_code'
               data-name='primary_contact_country_code'
               // name={'fathers_contact_country_code'}
               className='w-100 user_input_dashboard'
               value={primary_contact_country_code}
               onChange={(value) => setPrimaryCountryCode(value)}
               showSearch
               placeholder='Select Country Code'
            optionFilterProp="children"
 >
                  
                   <option data-countryCode="DZ" value="213">+213</option>
                   <option data-countryCode="AD" value="376">+376</option>
                   <option data-countryCode="AO" value="244">+244</option>
                   <option data-countryCode="AI" value="1264">+1264</option>
                   <option data-countryCode="AG" value="1268">+1268</option>
                   <option data-countryCode="AR" value="54">+54</option>
                   <option data-countryCode="AM" value="374">+374</option>
                   <option data-countryCode="AW" value="297">+297</option>
                   <option data-countryCode="AU" value="61">+61</option>
                   <option data-countryCode="AT" value="43">+43</option>
                   <option data-countryCode="AZ" value="994">+994</option>
                   <option data-countryCode="BS" value="1242">+1242</option>
                   <option data-countryCode="BH" value="973">+973</option>
                   <option data-countryCode="BD" value="880">+880</option>
                   <option data-countryCode="BB" value="1246">+1246</option>
                   <option data-countryCode="BY" value="375">+375</option>
                   <option data-countryCode="BE" value="32">+32</option>
                   <option data-countryCode="BZ" value="501">+501</option>
                   <option data-countryCode="BJ" value="229">+229</option>
                   <option data-countryCode="BM" value="1441">+1441</option>
                   <option data-countryCode="BT" value="975">+975</option>
                   <option data-countryCode="BO" value="591">+591</option>
                   <option data-countryCode="BA" value="387">+387</option>
                   <option data-countryCode="BW" value="267">+267</option>
                   <option data-countryCode="BR" value="55">+55</option>
                   <option data-countryCode="BN" value="673">+673</option>
                   <option data-countryCode="BG" value="359">+359</option>
                   <option data-countryCode="BF" value="226">+226</option>
                   <option data-countryCode="BI" value="257">+257</option>
                   <option data-countryCode="KH" value="855">+855</option>
                   <option data-countryCode="CM" value="237">+237</option>
                   <option data-countryCode="CA" value="1">+1</option>
                   <option data-countryCode="CV" value="238">+238</option>
                   <option data-countryCode="KY" value="1345">+1345</option>
                   <option data-countryCode="CF" value="236">+236</option>
                   <option data-countryCode="CL" value="56">+56</option>
                   <option data-countryCode="CN" value="86">+86</option>
                   <option data-countryCode="CO" value="57">+57</option>
                   <option data-countryCode="KM" value="269">+269</option>
                   <option data-countryCode="CG" value="242">+242</option>
                   <option data-countryCode="CK" value="682">+682</option>
                   <option data-countryCode="CR" value="506">+506</option>
                   <option data-countryCode="HR" value="385">+385</option>
                   <option data-countryCode="CU" value="53">+53</option>
                   <option data-countryCode="CY" value="90392">+90392</option>
                   <option data-countryCode="CY" value="357">+357</option>
                   <option data-countryCode="CZ" value="42">+42</option>
                   <option data-countryCode="DK" value="45">+45</option>
                   <option data-countryCode="DJ" value="253">+253</option>
                   <option data-countryCode="DM" value="1809">+1809</option>
                   <option data-countryCode="DO" value="1809">+1809</option>
                   <option data-countryCode="EC" value="593">+593</option>
                   <option data-countryCode="EG" value="20">+20</option>
                   <option data-countryCode="SV" value="503">+503</option>
                   <option data-countryCode="GQ" value="240">+240</option>
                   <option data-countryCode="ER" value="291">+291</option>
                   <option data-countryCode="EE" value="372">+372</option>
                   <option data-countryCode="ET" value="251">+251</option>
                   <option data-countryCode="FK" value="500">+500</option>
                   <option data-countryCode="FO" value="298">+298</option>
                   <option data-countryCode="FJ" value="679">+679</option>
                   <option data-countryCode="FI" value="358">+358</option>
                   <option data-countryCode="FR" value="33">+33</option>
                   <option data-countryCode="GF" value="594">+594</option>
                   <option data-countryCode="PF" value="689">+689</option>
                   <option data-countryCode="GA" value="241">+241</option>
                   <option data-countryCode="GM" value="220">+220</option>
                   <option data-countryCode="GE" value="7880">+7880</option>
                   <option data-countryCode="DE" value="49">+49</option>
                   <option data-countryCode="GH" value="233">+233</option>
                   <option data-countryCode="GI" value="350">+350</option>
                   <option data-countryCode="GR" value="30">+30</option>
                   <option data-countryCode="GL" value="299">+299</option>
                   <option data-countryCode="GD" value="1473">+1473</option>
                   <option data-countryCode="GP" value="590">+590</option>
                   <option data-countryCode="GU" value="671">+671</option>
                   <option data-countryCode="GT" value="502">+502</option>
                   <option data-countryCode="GN" value="224">+224</option>
                   <option data-countryCode="GW" value="245">+245</option>
                   <option data-countryCode="GY" value="592">+592</option>
                   <option data-countryCode="HT" value="509">+509</option>
                   <option data-countryCode="HN" value="504">+504</option>
                   <option data-countryCode="HK" value="852">+852</option>
                   <option data-countryCode="HU" value="36">+36</option>
                   <option data-countryCode="IS" value="354">+354</option>
                   <option data-countryCode="IN" value="91">+91</option>
                   <option data-countryCode="ID" value="62">+62</option>
                   <option data-countryCode="IR" value="98">+98</option>
                   <option data-countryCode="IQ" value="964">+964</option>
                   <option data-countryCode="IE" value="353">+353</option>
                   <option data-countryCode="IL" value="972">+972</option>
                   <option data-countryCode="IT" value="39">+39</option>
                   <option data-countryCode="JM" value="1876">+1876</option>
                   <option data-countryCode="JP" value="81">+81</option>
                   <option data-countryCode="JO" value="962">+962</option>
                   <option data-countryCode="KZ" value="7">+7</option>
                   <option data-countryCode="KE" value="254">+254</option>
                   <option data-countryCode="KI" value="686">+686</option>
                   <option data-countryCode="KP" value="850">+850</option>
                   <option data-countryCode="KR" value="82">+82</option>
                   <option data-countryCode="KW" value="965">+965</option>
                   <option data-countryCode="KG" value="996">+996</option>
                   <option data-countryCode="LA" value="856">+856</option>
                   <option data-countryCode="LV" value="371">+371</option>
                   <option data-countryCode="LB" value="961">+961</option>
                   <option data-countryCode="LS" value="266">+266</option>
                   <option data-countryCode="LR" value="231">+231</option>
                   <option data-countryCode="LY" value="218">+218</option>
                   <option data-countryCode="LI" value="417">+417</option>
                   <option data-countryCode="LT" value="370">+370</option>
                   <option data-countryCode="LU" value="352">+352</option>
                   <option data-countryCode="MO" value="853">+853</option>
                   <option data-countryCode="MK" value="389">+389</option>
                   <option data-countryCode="MG" value="261">+261</option>
                   <option data-countryCode="MW" value="265">+265</option>
                   <option data-countryCode="MY" value="60">+60</option>
                   <option data-countryCode="MV" value="960">+960</option>
                   <option data-countryCode="ML" value="223">+223</option>
                   <option data-countryCode="MT" value="356">+356</option>
                   <option data-countryCode="MH" value="692">+692</option>
                   <option data-countryCode="MQ" value="596">+596</option>
                   <option data-countryCode="MR" value="222">+222</option>
                   <option data-countryCode="YT" value="269">+269</option>
                   <option data-countryCode="MX" value="52">+52</option>
                   <option data-countryCode="FM" value="691">+691</option>
                   <option data-countryCode="MD" value="373">+373</option>
                   <option data-countryCode="MC" value="377">+377</option>
                   <option data-countryCode="MN" value="976">+976</option>
                   <option data-countryCode="MS" value="1664">+1664</option>
                   <option data-countryCode="MA" value="212">+212</option>
                   <option data-countryCode="MZ" value="258">+258</option>
                   <option data-countryCode="MN" value="95">+95</option>
                   <option data-countryCode="NA" value="264">+264</option>
                   <option data-countryCode="NR" value="674">+674</option>
                   <option data-countryCode="NP" value="977">+977</option>
                   <option data-countryCode="NL" value="31">+31</option>
                   <option data-countryCode="NC" value="687">+687</option>
                   <option data-countryCode="NZ" value="64">+64</option>
                   <option data-countryCode="NI" value="505">+505</option>
                   <option data-countryCode="NE" value="227">+227</option>
                   <option data-countryCode="NG" value="234">+234</option>
                   <option data-countryCode="NU" value="683">+683</option>
                   <option data-countryCode="NF" value="672">+672</option>
                   <option data-countryCode="NP" value="670">+670</option>
                   <option data-countryCode="NO" value="47">+47</option>
                   <option data-countryCode="OM" value="968">+968</option>
                   <option data-countryCode="PW" value="680">+680</option>
                   <option data-countryCode="PA" value="507">+507</option>
                   <option data-countryCode="PG" value="675">+675</option>
                   <option data-countryCode="PY" value="595">+595</option>
                   <option data-countryCode="PE" value="51">+51</option>
                   <option data-countryCode="PH" value="63">+63</option>
                   <option data-countryCode="PL" value="48">+48</option>
                   <option data-countryCode="PT" value="351">+351</option>
                   <option data-countryCode="PR" value="1787">+1787</option>
                   <option data-countryCode="QA" value="974">+974</option>
                   <option data-countryCode="RE" value="262">+262</option>
                   <option data-countryCode="RO" value="40">+40</option>
                   <option data-countryCode="RU" value="7">+7</option>
                   <option data-countryCode="RW" value="250">+250</option>
                   <option data-countryCode="SM" value="378">+378</option>
                   <option data-countryCode="ST" value="239">+239</option>
                   <option data-countryCode="SA" value="966">+966</option>
                   <option data-countryCode="SN" value="221">+221</option>
                   <option data-countryCode="CS" value="381">+381</option>
                   <option data-countryCode="SC" value="248">+248</option>
                   <option data-countryCode="SL" value="232">+232</option>
                   <option data-countryCode="SG" value="65">+65</option>
                   <option data-countryCode="SK" value="421">+421</option>
                   <option data-countryCode="SI" value="386">+386</option>
                   <option data-countryCode="SB" value="677">+677</option>
                   <option data-countryCode="SO" value="252">+252</option>
                   <option data-countryCode="ZA" value="27">+27</option>
                   <option data-countryCode="ES" value="34">+34</option>
                   <option data-countryCode="LK" value="94">+94</option>
                   <option data-countryCode="SH" value="290">+290</option>
                   <option data-countryCode="KN" value="1869">+1869</option>
                   <option data-countryCode="SC" value="1758">+1758</option>
                   <option data-countryCode="SD" value="249">+249</option>
                   <option data-countryCode="SR" value="597">+597</option>
                   <option data-countryCode="SZ" value="268">+268</option>
                   <option data-countryCode="SE" value="46">+46</option>
                   <option data-countryCode="CH" value="41">+41</option>
                   <option data-countryCode="SI" value="963">+963</option>
                   <option data-countryCode="TW" value="886">+886</option>
                   <option data-countryCode="TJ" value="7">+7</option>
                   <option data-countryCode="TH" value="66">+66</option>
                   <option data-countryCode="TG" value="228">+228</option>
                   <option data-countryCode="TO" value="676">+676</option>
                   <option data-countryCode="TT" value="1868">+1868</option>
                   <option data-countryCode="TN" value="216">+216</option>
                   <option data-countryCode="TR" value="90">+90</option>
                   <option data-countryCode="TM" value="7">+7</option>
                   <option data-countryCode="TM" value="993">+993</option>
                   <option data-countryCode="TC" value="1649">+1649</option>
                   <option data-countryCode="TV" value="688">+688</option>
                   <option data-countryCode="UG" value="256">+256</option>
                   <option data-countryCode="GB" value="44">+44</option>
                   <option data-countryCode="UA" value="380">+380</option>
                   <option data-countryCode="AE" value="971">+971</option>
                   <option data-countryCode="UY" value="598">+598</option>
                   <option data-countryCode="US" value="1">+1</option>
                   <option data-countryCode="UZ" value="7">+7</option>
                   <option data-countryCode="VU" value="678">+678</option>
                   <option data-countryCode="VA" value="379">+379</option>
                   <option data-countryCode="VE" value="58">+58</option>
                   <option data-countryCode="VN" value="84">+84</option>
                   <option data-countryCode="VG" value="84">+1284</option>
                   <option data-countryCode="VI" value="84">+1340</option>
                   <option data-countryCode="WF" value="681">+681</option>
                   <option data-countryCode="YE" value="969">+969</option>
                   <option data-countryCode="YE" value="967">+967</option>
                   <option data-countryCode="ZM" value="260">+260</option>
                   <option data-countryCode="ZW" value="263">+263</option>    
             </Select>
             <input type="hidden" name={'primary_contact_country_code'} value={primary_contact_country_code}/>
              </div>
              <div className='w-75 px-1'>
                 
                 <input type="number" className='form-control rounded user_input_dashboard'  name="primary_contact" id="primary_contact"  value={primary_contact} placeholder='Enter phone number' onChange={(e)=>setPrimaryContact(e.target.value)} required/>
              </div>
                </div>
              </div>

              <div className='col-md-4 '>
              <label className="blue-color">Alternate Contact Number<b className="text-danger">*</b></label>
                <div className='d-flex mt-1'>
                     <div className="user_profile_contact_code">
               
                 {/* <select  className='form-control rounded user_input_dashboard' name="alernate_country_code" id="alernate_country_code" value={alernate_country_code!==""?alernate_country_code:"91"} onChange={(e)=>setAlternateCountryCode(e.target.value)} required > */}
                 <Select
               id='alernate_country_code'
               data-name='alernate_country_code'
               // name={'fathers_contact_country_code'}
               className='w-100 user_input_dashboard'
               value={alernate_country_code}
               onChange={(value) => setAlternateCountryCode(value)}
               showSearch
               placeholder='Select Country Code'
              optionFilterProp="children"
 >
                   <option data-countryCode="DZ" value="213">+213</option>
                   <option data-countryCode="AD" value="376">+376</option>
                   <option data-countryCode="AO" value="244">+244</option>
                   <option data-countryCode="AI" value="1264">+1264</option>
                   <option data-countryCode="AG" value="1268">+1268</option>
                   <option data-countryCode="AR" value="54">+54</option>
                   <option data-countryCode="AM" value="374">+374</option>
                   <option data-countryCode="AW" value="297">+297</option>
                   <option data-countryCode="AU" value="61">+61</option>
                   <option data-countryCode="AT" value="43">+43</option>
                   <option data-countryCode="AZ" value="994">+994</option>
                   <option data-countryCode="BS" value="1242">+1242</option>
                   <option data-countryCode="BH" value="973">+973</option>
                   <option data-countryCode="BD" value="880">+880</option>
                   <option data-countryCode="BB" value="1246">+1246</option>
                   <option data-countryCode="BY" value="375">+375</option>
                   <option data-countryCode="BE" value="32">+32</option>
                   <option data-countryCode="BZ" value="501">+501</option>
                   <option data-countryCode="BJ" value="229">+229</option>
                   <option data-countryCode="BM" value="1441">+1441</option>
                   <option data-countryCode="BT" value="975">+975</option>
                   <option data-countryCode="BO" value="591">+591</option>
                   <option data-countryCode="BA" value="387">+387</option>
                   <option data-countryCode="BW" value="267">+267</option>
                   <option data-countryCode="BR" value="55">+55</option>
                   <option data-countryCode="BN" value="673">+673</option>
                   <option data-countryCode="BG" value="359">+359</option>
                   <option data-countryCode="BF" value="226">+226</option>
                   <option data-countryCode="BI" value="257">+257</option>
                   <option data-countryCode="KH" value="855">+855</option>
                   <option data-countryCode="CM" value="237">+237</option>
                   <option data-countryCode="CA" value="1">+1</option>
                   <option data-countryCode="CV" value="238">+238</option>
                   <option data-countryCode="KY" value="1345">+1345</option>
                   <option data-countryCode="CF" value="236">+236</option>
                   <option data-countryCode="CL" value="56">+56</option>
                   <option data-countryCode="CN" value="86">+86</option>
                   <option data-countryCode="CO" value="57">+57</option>
                   <option data-countryCode="KM" value="269">+269</option>
                   <option data-countryCode="CG" value="242">+242</option>
                   <option data-countryCode="CK" value="682">+682</option>
                   <option data-countryCode="CR" value="506">+506</option>
                   <option data-countryCode="HR" value="385">+385</option>
                   <option data-countryCode="CU" value="53">+53</option>
                   <option data-countryCode="CY" value="90392">+90392</option>
                   <option data-countryCode="CY" value="357">+357</option>
                   <option data-countryCode="CZ" value="42">+42</option>
                   <option data-countryCode="DK" value="45">+45</option>
                   <option data-countryCode="DJ" value="253">+253</option>
                   <option data-countryCode="DM" value="1809">+1809</option>
                   <option data-countryCode="DO" value="1809">+1809</option>
                   <option data-countryCode="EC" value="593">+593</option>
                   <option data-countryCode="EG" value="20">+20</option>
                   <option data-countryCode="SV" value="503">+503</option>
                   <option data-countryCode="GQ" value="240">+240</option>
                   <option data-countryCode="ER" value="291">+291</option>
                   <option data-countryCode="EE" value="372">+372</option>
                   <option data-countryCode="ET" value="251">+251</option>
                   <option data-countryCode="FK" value="500">+500</option>
                   <option data-countryCode="FO" value="298">+298</option>
                   <option data-countryCode="FJ" value="679">+679</option>
                   <option data-countryCode="FI" value="358">+358</option>
                   <option data-countryCode="FR" value="33">+33</option>
                   <option data-countryCode="GF" value="594">+594</option>
                   <option data-countryCode="PF" value="689">+689</option>
                   <option data-countryCode="GA" value="241">+241</option>
                   <option data-countryCode="GM" value="220">+220</option>
                   <option data-countryCode="GE" value="7880">+7880</option>
                   <option data-countryCode="DE" value="49">+49</option>
                   <option data-countryCode="GH" value="233">+233</option>
                   <option data-countryCode="GI" value="350">+350</option>
                   <option data-countryCode="GR" value="30">+30</option>
                   <option data-countryCode="GL" value="299">+299</option>
                   <option data-countryCode="GD" value="1473">+1473</option>
                   <option data-countryCode="GP" value="590">+590</option>
                   <option data-countryCode="GU" value="671">+671</option>
                   <option data-countryCode="GT" value="502">+502</option>
                   <option data-countryCode="GN" value="224">+224</option>
                   <option data-countryCode="GW" value="245">+245</option>
                   <option data-countryCode="GY" value="592">+592</option>
                   <option data-countryCode="HT" value="509">+509</option>
                   <option data-countryCode="HN" value="504">+504</option>
                   <option data-countryCode="HK" value="852">+852</option>
                   <option data-countryCode="HU" value="36">+36</option>
                   <option data-countryCode="IS" value="354">+354</option>
                   <option data-countryCode="IN" value="91">+91</option>
                   <option data-countryCode="ID" value="62">+62</option>
                   <option data-countryCode="IR" value="98">+98</option>
                   <option data-countryCode="IQ" value="964">+964</option>
                   <option data-countryCode="IE" value="353">+353</option>
                   <option data-countryCode="IL" value="972">+972</option>
                   <option data-countryCode="IT" value="39">+39</option>
                   <option data-countryCode="JM" value="1876">+1876</option>
                   <option data-countryCode="JP" value="81">+81</option>
                   <option data-countryCode="JO" value="962">+962</option>
                   <option data-countryCode="KZ" value="7">+7</option>
                   <option data-countryCode="KE" value="254">+254</option>
                   <option data-countryCode="KI" value="686">+686</option>
                   <option data-countryCode="KP" value="850">+850</option>
                   <option data-countryCode="KR" value="82">+82</option>
                   <option data-countryCode="KW" value="965">+965</option>
                   <option data-countryCode="KG" value="996">+996</option>
                   <option data-countryCode="LA" value="856">+856</option>
                   <option data-countryCode="LV" value="371">+371</option>
                   <option data-countryCode="LB" value="961">+961</option>
                   <option data-countryCode="LS" value="266">+266</option>
                   <option data-countryCode="LR" value="231">+231</option>
                   <option data-countryCode="LY" value="218">+218</option>
                   <option data-countryCode="LI" value="417">+417</option>
                   <option data-countryCode="LT" value="370">+370</option>
                   <option data-countryCode="LU" value="352">+352</option>
                   <option data-countryCode="MO" value="853">+853</option>
                   <option data-countryCode="MK" value="389">+389</option>
                   <option data-countryCode="MG" value="261">+261</option>
                   <option data-countryCode="MW" value="265">+265</option>
                   <option data-countryCode="MY" value="60">+60</option>
                   <option data-countryCode="MV" value="960">+960</option>
                   <option data-countryCode="ML" value="223">+223</option>
                   <option data-countryCode="MT" value="356">+356</option>
                   <option data-countryCode="MH" value="692">+692</option>
                   <option data-countryCode="MQ" value="596">+596</option>
                   <option data-countryCode="MR" value="222">+222</option>
                   <option data-countryCode="YT" value="269">+269</option>
                   <option data-countryCode="MX" value="52">+52</option>
                   <option data-countryCode="FM" value="691">+691</option>
                   <option data-countryCode="MD" value="373">+373</option>
                   <option data-countryCode="MC" value="377">+377</option>
                   <option data-countryCode="MN" value="976">+976</option>
                   <option data-countryCode="MS" value="1664">+1664</option>
                   <option data-countryCode="MA" value="212">+212</option>
                   <option data-countryCode="MZ" value="258">+258</option>
                   <option data-countryCode="MN" value="95">+95</option>
                   <option data-countryCode="NA" value="264">+264</option>
                   <option data-countryCode="NR" value="674">+674</option>
                   <option data-countryCode="NP" value="977">+977</option>
                   <option data-countryCode="NL" value="31">+31</option>
                   <option data-countryCode="NC" value="687">+687</option>
                   <option data-countryCode="NZ" value="64">+64</option>
                   <option data-countryCode="NI" value="505">+505</option>
                   <option data-countryCode="NE" value="227">+227</option>
                   <option data-countryCode="NG" value="234">+234</option>
                   <option data-countryCode="NU" value="683">+683</option>
                   <option data-countryCode="NF" value="672">+672</option>
                   <option data-countryCode="NP" value="670">+670</option>
                   <option data-countryCode="NO" value="47">+47</option>
                   <option data-countryCode="OM" value="968">+968</option>
                   <option data-countryCode="PW" value="680">+680</option>
                   <option data-countryCode="PA" value="507">+507</option>
                   <option data-countryCode="PG" value="675">+675</option>
                   <option data-countryCode="PY" value="595">+595</option>
                   <option data-countryCode="PE" value="51">+51</option>
                   <option data-countryCode="PH" value="63">+63</option>
                   <option data-countryCode="PL" value="48">+48</option>
                   <option data-countryCode="PT" value="351">+351</option>
                   <option data-countryCode="PR" value="1787">+1787</option>
                   <option data-countryCode="QA" value="974">+974</option>
                   <option data-countryCode="RE" value="262">+262</option>
                   <option data-countryCode="RO" value="40">+40</option>
                   <option data-countryCode="RU" value="7">+7</option>
                   <option data-countryCode="RW" value="250">+250</option>
                   <option data-countryCode="SM" value="378">+378</option>
                   <option data-countryCode="ST" value="239">+239</option>
                   <option data-countryCode="SA" value="966">+966</option>
                   <option data-countryCode="SN" value="221">+221</option>
                   <option data-countryCode="CS" value="381">+381</option>
                   <option data-countryCode="SC" value="248">+248</option>
                   <option data-countryCode="SL" value="232">+232</option>
                   <option data-countryCode="SG" value="65">+65</option>
                   <option data-countryCode="SK" value="421">+421</option>
                   <option data-countryCode="SI" value="386">+386</option>
                   <option data-countryCode="SB" value="677">+677</option>
                   <option data-countryCode="SO" value="252">+252</option>
                   <option data-countryCode="ZA" value="27">+27</option>
                   <option data-countryCode="ES" value="34">+34</option>
                   <option data-countryCode="LK" value="94">+94</option>
                   <option data-countryCode="SH" value="290">+290</option>
                   <option data-countryCode="KN" value="1869">+1869</option>
                   <option data-countryCode="SC" value="1758">+1758</option>
                   <option data-countryCode="SD" value="249">+249</option>
                   <option data-countryCode="SR" value="597">+597</option>
                   <option data-countryCode="SZ" value="268">+268</option>
                   <option data-countryCode="SE" value="46">+46</option>
                   <option data-countryCode="CH" value="41">+41</option>
                   <option data-countryCode="SI" value="963">+963</option>
                   <option data-countryCode="TW" value="886">+886</option>
                   <option data-countryCode="TJ" value="7">+7</option>
                   <option data-countryCode="TH" value="66">+66</option>
                   <option data-countryCode="TG" value="228">+228</option>
                   <option data-countryCode="TO" value="676">+676</option>
                   <option data-countryCode="TT" value="1868">+1868</option>
                   <option data-countryCode="TN" value="216">+216</option>
                   <option data-countryCode="TR" value="90">+90</option>
                   <option data-countryCode="TM" value="7">+7</option>
                   <option data-countryCode="TM" value="993">+993</option>
                   <option data-countryCode="TC" value="1649">+1649</option>
                   <option data-countryCode="TV" value="688">+688</option>
                   <option data-countryCode="UG" value="256">+256</option>
                   <option data-countryCode="GB" value="44">+44</option>
                   <option data-countryCode="UA" value="380">+380</option>
                   <option data-countryCode="AE" value="971">+971</option>
                   <option data-countryCode="UY" value="598">+598</option>
                   <option data-countryCode="US" value="1">+1</option>
                   <option data-countryCode="UZ" value="7">+7</option>
                   <option data-countryCode="VU" value="678">+678</option>
                   <option data-countryCode="VA" value="379">+379</option>
                   <option data-countryCode="VE" value="58">+58</option>
                   <option data-countryCode="VN" value="84">+84</option>
                   <option data-countryCode="VG" value="84">+1284</option>
                   <option data-countryCode="VI" value="84">+1340</option>
                   <option data-countryCode="WF" value="681">+681</option>
                   <option data-countryCode="YE" value="969">+969</option>
                   <option data-countryCode="YE" value="967">+967</option>
                   <option data-countryCode="ZM" value="260">+260</option>
                   <option data-countryCode="ZW" value="263">+263</option>    
             </Select>
             <input type="hidden" name="alernate_country_code" value={alernate_country_code} />
              </div>
              <div className='w-75 px-1'>
                 
                 <input type="number" className='form-control rounded user_input_dashboard'  name="alternate_phone_number" id="alternate_phone_number"  value={alternate_phone_number} placeholder='Enter alternate phone number' onChange={(e)=>setAlternatePhoneNumber(e.target.value)} required/>
              </div>
                </div>
              </div>
              
           
              
             <hr className="mt-5"/>
             </div>
 
 
             <h3 className='my-4 text-left blue-color fw-bold'>Parents Details</h3>
             <div className='row'>
             <div className='col-md-3'>
               <label className="blue-color">Father's Full Name <b className="text-danger">*</b></label>
               <input type="text"className='form-control rounded user_input_dashboard'  name="fathers_full_name" value={fathers_full_name} id="fathers_full_name" onChange={(e)=>setFathersFullName(e.target.value)}  placeholder='Enter Fathers Full Name' required/>
              </div>
              <div className='col-md-3'>
              <label className="blue-color">Father's Email</label>
               <input type="email" className='form-control rounded user_input_dashboard'  name="fathers_email" id="fathers_email"  value={fathers_email} onChange={(e)=>setFathersEmail(e.target.value)}  placeholder='Enter fathers Email' />
 
              </div>
              <div className='col-md-4'>
              <label className="blue-color">Father's Contact Number<b className="text-danger">*</b></label>
                <div className='d-flex'>
                <div className="user_profile_contact_code">
              
             
               <Select
               id='fathers_contact_country_code'
               data-name='fathers_contact_country_code'
               // name={'fathers_contact_country_code'}
               className='w-100 user_input_dashboard'
             
               value={fathers_contact_country_code===""||fathers_contact_country_code===null?"91":fathers_contact_country_code}
               onChange={(value) => setFathersContactCountryCode(value)}
               showSearch
               placeholder='Select Country Code'
            optionFilterProp="children"
            defaultValue="+91"
 >
           
           <option data-countryCode="DZ" value="213">+213</option>
                   <option data-countryCode="AD" value="376">+376</option>
                   <option data-countryCode="AO" value="244">+244</option>
                   <option data-countryCode="AI" value="1264">+1264</option>
                   <option data-countryCode="AG" value="1268">+1268</option>
                   <option data-countryCode="AR" value="54">+54</option>
                   <option data-countryCode="AM" value="374">+374</option>
                   <option data-countryCode="AW" value="297">+297</option>
                   <option data-countryCode="AU" value="61">+61</option>
                   <option data-countryCode="AT" value="43">+43</option>
                   <option data-countryCode="AZ" value="994">+994</option>
                   <option data-countryCode="BS" value="1242">+1242</option>
                   <option data-countryCode="BH" value="973">+973</option>
                   <option data-countryCode="BD" value="880">+880</option>
                   <option data-countryCode="BB" value="1246">+1246</option>
                   <option data-countryCode="BY" value="375">+375</option>
                   <option data-countryCode="BE" value="32">+32</option>
                   <option data-countryCode="BZ" value="501">+501</option>
                   <option data-countryCode="BJ" value="229">+229</option>
                   <option data-countryCode="BM" value="1441">+1441</option>
                   <option data-countryCode="BT" value="975">+975</option>
                   <option data-countryCode="BO" value="591">+591</option>
                   <option data-countryCode="BA" value="387">+387</option>
                   <option data-countryCode="BW" value="267">+267</option>
                   <option data-countryCode="BR" value="55">+55</option>
                   <option data-countryCode="BN" value="673">+673</option>
                   <option data-countryCode="BG" value="359">+359</option>
                   <option data-countryCode="BF" value="226">+226</option>
                   <option data-countryCode="BI" value="257">+257</option>
                   <option data-countryCode="KH" value="855">+855</option>
                   <option data-countryCode="CM" value="237">+237</option>
                   <option data-countryCode="CA" value="1">+1</option>
                   <option data-countryCode="CV" value="238">+238</option>
                   <option data-countryCode="KY" value="1345">+1345</option>
                   <option data-countryCode="CF" value="236">+236</option>
                   <option data-countryCode="CL" value="56">+56</option>
                   <option data-countryCode="CN" value="86">+86</option>
                   <option data-countryCode="CO" value="57">+57</option>
                   <option data-countryCode="KM" value="269">+269</option>
                   <option data-countryCode="CG" value="242">+242</option>
                   <option data-countryCode="CK" value="682">+682</option>
                   <option data-countryCode="CR" value="506">+506</option>
                   <option data-countryCode="HR" value="385">+385</option>
                   <option data-countryCode="CU" value="53">+53</option>
                   <option data-countryCode="CY" value="90392">+90392</option>
                   <option data-countryCode="CY" value="357">+357</option>
                   <option data-countryCode="CZ" value="42">+42</option>
                   <option data-countryCode="DK" value="45">+45</option>
                   <option data-countryCode="DJ" value="253">+253</option>
                   <option data-countryCode="DM" value="1809">+1809</option>
                   <option data-countryCode="DO" value="1809">+1809</option>
                   <option data-countryCode="EC" value="593">+593</option>
                   <option data-countryCode="EG" value="20">+20</option>
                   <option data-countryCode="SV" value="503">+503</option>
                   <option data-countryCode="GQ" value="240">+240</option>
                   <option data-countryCode="ER" value="291">+291</option>
                   <option data-countryCode="EE" value="372">+372</option>
                   <option data-countryCode="ET" value="251">+251</option>
                   <option data-countryCode="FK" value="500">+500</option>
                   <option data-countryCode="FO" value="298">+298</option>
                   <option data-countryCode="FJ" value="679">+679</option>
                   <option data-countryCode="FI" value="358">+358</option>
                   <option data-countryCode="FR" value="33">+33</option>
                   <option data-countryCode="GF" value="594">+594</option>
                   <option data-countryCode="PF" value="689">+689</option>
                   <option data-countryCode="GA" value="241">+241</option>
                   <option data-countryCode="GM" value="220">+220</option>
                   <option data-countryCode="GE" value="7880">+7880</option>
                   <option data-countryCode="DE" value="49">+49</option>
                   <option data-countryCode="GH" value="233">+233</option>
                   <option data-countryCode="GI" value="350">+350</option>
                   <option data-countryCode="GR" value="30">+30</option>
                   <option data-countryCode="GL" value="299">+299</option>
                   <option data-countryCode="GD" value="1473">+1473</option>
                   <option data-countryCode="GP" value="590">+590</option>
                   <option data-countryCode="GU" value="671">+671</option>
                   <option data-countryCode="GT" value="502">+502</option>
                   <option data-countryCode="GN" value="224">+224</option>
                   <option data-countryCode="GW" value="245">+245</option>
                   <option data-countryCode="GY" value="592">+592</option>
                   <option data-countryCode="HT" value="509">+509</option>
                   <option data-countryCode="HN" value="504">+504</option>
                   <option data-countryCode="HK" value="852">+852</option>
                   <option data-countryCode="HU" value="36">+36</option>
                   <option data-countryCode="IS" value="354">+354</option>
                   <option data-countryCode="IN" value="91">+91</option>
                   <option data-countryCode="ID" value="62">+62</option>
                   <option data-countryCode="IR" value="98">+98</option>
                   <option data-countryCode="IQ" value="964">+964</option>
                   <option data-countryCode="IE" value="353">+353</option>
                   <option data-countryCode="IL" value="972">+972</option>
                   <option data-countryCode="IT" value="39">+39</option>
                   <option data-countryCode="JM" value="1876">+1876</option>
                   <option data-countryCode="JP" value="81">+81</option>
                   <option data-countryCode="JO" value="962">+962</option>
                   <option data-countryCode="KZ" value="7">+7</option>
                   <option data-countryCode="KE" value="254">+254</option>
                   <option data-countryCode="KI" value="686">+686</option>
                   <option data-countryCode="KP" value="850">+850</option>
                   <option data-countryCode="KR" value="82">+82</option>
                   <option data-countryCode="KW" value="965">+965</option>
                   <option data-countryCode="KG" value="996">+996</option>
                   <option data-countryCode="LA" value="856">+856</option>
                   <option data-countryCode="LV" value="371">+371</option>
                   <option data-countryCode="LB" value="961">+961</option>
                   <option data-countryCode="LS" value="266">+266</option>
                   <option data-countryCode="LR" value="231">+231</option>
                   <option data-countryCode="LY" value="218">+218</option>
                   <option data-countryCode="LI" value="417">+417</option>
                   <option data-countryCode="LT" value="370">+370</option>
                   <option data-countryCode="LU" value="352">+352</option>
                   <option data-countryCode="MO" value="853">+853</option>
                   <option data-countryCode="MK" value="389">+389</option>
                   <option data-countryCode="MG" value="261">+261</option>
                   <option data-countryCode="MW" value="265">+265</option>
                   <option data-countryCode="MY" value="60">+60</option>
                   <option data-countryCode="MV" value="960">+960</option>
                   <option data-countryCode="ML" value="223">+223</option>
                   <option data-countryCode="MT" value="356">+356</option>
                   <option data-countryCode="MH" value="692">+692</option>
                   <option data-countryCode="MQ" value="596">+596</option>
                   <option data-countryCode="MR" value="222">+222</option>
                   <option data-countryCode="YT" value="269">+269</option>
                   <option data-countryCode="MX" value="52">+52</option>
                   <option data-countryCode="FM" value="691">+691</option>
                   <option data-countryCode="MD" value="373">+373</option>
                   <option data-countryCode="MC" value="377">+377</option>
                   <option data-countryCode="MN" value="976">+976</option>
                   <option data-countryCode="MS" value="1664">+1664</option>
                   <option data-countryCode="MA" value="212">+212</option>
                   <option data-countryCode="MZ" value="258">+258</option>
                   <option data-countryCode="MN" value="95">+95</option>
                   <option data-countryCode="NA" value="264">+264</option>
                   <option data-countryCode="NR" value="674">+674</option>
                   <option data-countryCode="NP" value="977">+977</option>
                   <option data-countryCode="NL" value="31">+31</option>
                   <option data-countryCode="NC" value="687">+687</option>
                   <option data-countryCode="NZ" value="64">+64</option>
                   <option data-countryCode="NI" value="505">+505</option>
                   <option data-countryCode="NE" value="227">+227</option>
                   <option data-countryCode="NG" value="234">+234</option>
                   <option data-countryCode="NU" value="683">+683</option>
                   <option data-countryCode="NF" value="672">+672</option>
                   <option data-countryCode="NP" value="670">+670</option>
                   <option data-countryCode="NO" value="47">+47</option>
                   <option data-countryCode="OM" value="968">+968</option>
                   <option data-countryCode="PW" value="680">+680</option>
                   <option data-countryCode="PA" value="507">+507</option>
                   <option data-countryCode="PG" value="675">+675</option>
                   <option data-countryCode="PY" value="595">+595</option>
                   <option data-countryCode="PE" value="51">+51</option>
                   <option data-countryCode="PH" value="63">+63</option>
                   <option data-countryCode="PL" value="48">+48</option>
                   <option data-countryCode="PT" value="351">+351</option>
                   <option data-countryCode="PR" value="1787">+1787</option>
                   <option data-countryCode="QA" value="974">+974</option>
                   <option data-countryCode="RE" value="262">+262</option>
                   <option data-countryCode="RO" value="40">+40</option>
                   <option data-countryCode="RU" value="7">+7</option>
                   <option data-countryCode="RW" value="250">+250</option>
                   <option data-countryCode="SM" value="378">+378</option>
                   <option data-countryCode="ST" value="239">+239</option>
                   <option data-countryCode="SA" value="966">+966</option>
                   <option data-countryCode="SN" value="221">+221</option>
                   <option data-countryCode="CS" value="381">+381</option>
                   <option data-countryCode="SC" value="248">+248</option>
                   <option data-countryCode="SL" value="232">+232</option>
                   <option data-countryCode="SG" value="65">+65</option>
                   <option data-countryCode="SK" value="421">+421</option>
                   <option data-countryCode="SI" value="386">+386</option>
                   <option data-countryCode="SB" value="677">+677</option>
                   <option data-countryCode="SO" value="252">+252</option>
                   <option data-countryCode="ZA" value="27">+27</option>
                   <option data-countryCode="ES" value="34">+34</option>
                   <option data-countryCode="LK" value="94">+94</option>
                   <option data-countryCode="SH" value="290">+290</option>
                   <option data-countryCode="KN" value="1869">+1869</option>
                   <option data-countryCode="SC" value="1758">+1758</option>
                   <option data-countryCode="SD" value="249">+249</option>
                   <option data-countryCode="SR" value="597">+597</option>
                   <option data-countryCode="SZ" value="268">+268</option>
                   <option data-countryCode="SE" value="46">+46</option>
                   <option data-countryCode="CH" value="41">+41</option>
                   <option data-countryCode="SI" value="963">+963</option>
                   <option data-countryCode="TW" value="886">+886</option>
                   <option data-countryCode="TJ" value="7">+7</option>
                   <option data-countryCode="TH" value="66">+66</option>
                   <option data-countryCode="TG" value="228">+228</option>
                   <option data-countryCode="TO" value="676">+676</option>
                   <option data-countryCode="TT" value="1868">+1868</option>
                   <option data-countryCode="TN" value="216">+216</option>
                   <option data-countryCode="TR" value="90">+90</option>
                   <option data-countryCode="TM" value="7">+7</option>
                   <option data-countryCode="TM" value="993">+993</option>
                   <option data-countryCode="TC" value="1649">+1649</option>
                   <option data-countryCode="TV" value="688">+688</option>
                   <option data-countryCode="UG" value="256">+256</option>
                   <option data-countryCode="GB" value="44">+44</option>
                   <option data-countryCode="UA" value="380">+380</option>
                   <option data-countryCode="AE" value="971">+971</option>
                   <option data-countryCode="UY" value="598">+598</option>
                   <option data-countryCode="US" value="1">+1</option>
                   <option data-countryCode="UZ" value="7">+7</option>
                   <option data-countryCode="VU" value="678">+678</option>
                   <option data-countryCode="VA" value="379">+379</option>
                   <option data-countryCode="VE" value="58">+58</option>
                   <option data-countryCode="VN" value="84">+84</option>
                   <option data-countryCode="VG" value="84">+1284</option>
                   <option data-countryCode="VI" value="84">+1340</option>
                   <option data-countryCode="WF" value="681">+681</option>
                   <option data-countryCode="YE" value="969">+969</option>
                   <option data-countryCode="YE" value="967">+967</option>
                   <option data-countryCode="ZM" value="260">+260</option>
                   <option data-countryCode="ZW" value="263">+263</option>    
           </Select>
           <input type='hidden' name="fathers_contact_country_code" value={fathers_contact_country_code}/>
              </div>
              <div className='w-75 px-1'>
              
               <input type="number" className='form-control rounded user_input_dashboard'  name="fathers_contact" id="fathers_contact"  value={fathers_contact} onChange={(e)=>setFathersContact(parseInt(e.target.value))}  placeholder='Enter fathers contact' required />
               
              </div>
                </div>
              </div>
             
             
             </div>
             <div className='row mt-4'>
             <div className='col-md-3'>
               <label className="blue-color">Mother's Full Name <b className="text-danger">*</b></label>
               <input type="text"className='form-control rounded user_input_dashboard'  name="mothers_full_name" id="mothers_full_name" value={mothers_full_name} onChange={(e)=>setMothersFullName(e.target.value)}  placeholder='Enter mothers Full Name' required/>
              </div>
              <div className='col-md-3'>
              <label className="blue-color">Mother's Email</label>
               <input type="email" className='form-control rounded user_input_dashboard'  name="mothers_email" id="mothers_email" value={mothers_email} onChange={(e)=>setMothersEmail(e.target.value)} placeholder='Enter mothers Email' />
 
              </div>
              <div className='col-md-4'>
              <label className="blue-color">Mother's Contact Number<b className="text-danger">*</b></label>
                <div className='d-flex'>
                <div className="user_profile_contact_code">
              <label className="blue-color"></label>
               {/* <input type="text" className='form-control rounded user_input_dashboard'  name="mothers_country_code" id="mothers_country_code" value={mothers_country_code} onChange={(e)=>setMothersCountryCode(e.target.value)}  placeholder='Enter mothers country code ' /> */}
               {/* <select  className='form-control rounded user_input_dashboard' name="mothers_country_code" id="mothers_country_code" value={mothers_country_code!==""?mothers_country_code:"91"} onChange={(e)=>setMothersCountryCode(e.target.value)} required > */}
               <Select
               id='mothers_country_code'
               data-name='mothers_country_code'
               // name={'fathers_contact_country_code'}
               className='w-100 user_input_dashboard'
               
               value={mothers_country_code===null|| mothers_country_code===""?"+91":mothers_country_code}
               onChange={(value) => setMothersCountryCode(value)}
               showSearch
               placeholder='Select Country Code'
             optionFilterProp="children"
             defaultValue="+91"
 >
 
                   <option data-countryCode="DZ" value="213">+213</option>
                   <option data-countryCode="AD" value="376">+376</option>
                   <option data-countryCode="AO" value="244">+244</option>
                   <option data-countryCode="AI" value="1264">+1264</option>
                   <option data-countryCode="AG" value="1268">+1268</option>
                   <option data-countryCode="AR" value="54">+54</option>
                   <option data-countryCode="AM" value="374">+374</option>
                   <option data-countryCode="AW" value="297">+297</option>
                   <option data-countryCode="AU" value="61">+61</option>
                   <option data-countryCode="AT" value="43">+43</option>
                   <option data-countryCode="AZ" value="994">+994</option>
                   <option data-countryCode="BS" value="1242">+1242</option>
                   <option data-countryCode="BH" value="973">+973</option>
                   <option data-countryCode="BD" value="880">+880</option>
                   <option data-countryCode="BB" value="1246">+1246</option>
                   <option data-countryCode="BY" value="375">+375</option>
                   <option data-countryCode="BE" value="32">+32</option>
                   <option data-countryCode="BZ" value="501">+501</option>
                   <option data-countryCode="BJ" value="229">+229</option>
                   <option data-countryCode="BM" value="1441">+1441</option>
                   <option data-countryCode="BT" value="975">+975</option>
                   <option data-countryCode="BO" value="591">+591</option>
                   <option data-countryCode="BA" value="387">+387</option>
                   <option data-countryCode="BW" value="267">+267</option>
                   <option data-countryCode="BR" value="55">+55</option>
                   <option data-countryCode="BN" value="673">+673</option>
                   <option data-countryCode="BG" value="359">+359</option>
                   <option data-countryCode="BF" value="226">+226</option>
                   <option data-countryCode="BI" value="257">+257</option>
                   <option data-countryCode="KH" value="855">+855</option>
                   <option data-countryCode="CM" value="237">+237</option>
                   <option data-countryCode="CA" value="1">+1</option>
                   <option data-countryCode="CV" value="238">+238</option>
                   <option data-countryCode="KY" value="1345">+1345</option>
                   <option data-countryCode="CF" value="236">+236</option>
                   <option data-countryCode="CL" value="56">+56</option>
                   <option data-countryCode="CN" value="86">+86</option>
                   <option data-countryCode="CO" value="57">+57</option>
                   <option data-countryCode="KM" value="269">+269</option>
                   <option data-countryCode="CG" value="242">+242</option>
                   <option data-countryCode="CK" value="682">+682</option>
                   <option data-countryCode="CR" value="506">+506</option>
                   <option data-countryCode="HR" value="385">+385</option>
                   <option data-countryCode="CU" value="53">+53</option>
                   <option data-countryCode="CY" value="90392">+90392</option>
                   <option data-countryCode="CY" value="357">+357</option>
                   <option data-countryCode="CZ" value="42">+42</option>
                   <option data-countryCode="DK" value="45">+45</option>
                   <option data-countryCode="DJ" value="253">+253</option>
                   <option data-countryCode="DM" value="1809">+1809</option>
                   <option data-countryCode="DO" value="1809">+1809</option>
                   <option data-countryCode="EC" value="593">+593</option>
                   <option data-countryCode="EG" value="20">+20</option>
                   <option data-countryCode="SV" value="503">+503</option>
                   <option data-countryCode="GQ" value="240">+240</option>
                   <option data-countryCode="ER" value="291">+291</option>
                   <option data-countryCode="EE" value="372">+372</option>
                   <option data-countryCode="ET" value="251">+251</option>
                   <option data-countryCode="FK" value="500">+500</option>
                   <option data-countryCode="FO" value="298">+298</option>
                   <option data-countryCode="FJ" value="679">+679</option>
                   <option data-countryCode="FI" value="358">+358</option>
                   <option data-countryCode="FR" value="33">+33</option>
                   <option data-countryCode="GF" value="594">+594</option>
                   <option data-countryCode="PF" value="689">+689</option>
                   <option data-countryCode="GA" value="241">+241</option>
                   <option data-countryCode="GM" value="220">+220</option>
                   <option data-countryCode="GE" value="7880">+7880</option>
                   <option data-countryCode="DE" value="49">+49</option>
                   <option data-countryCode="GH" value="233">+233</option>
                   <option data-countryCode="GI" value="350">+350</option>
                   <option data-countryCode="GR" value="30">+30</option>
                   <option data-countryCode="GL" value="299">+299</option>
                   <option data-countryCode="GD" value="1473">+1473</option>
                   <option data-countryCode="GP" value="590">+590</option>
                   <option data-countryCode="GU" value="671">+671</option>
                   <option data-countryCode="GT" value="502">+502</option>
                   <option data-countryCode="GN" value="224">+224</option>
                   <option data-countryCode="GW" value="245">+245</option>
                   <option data-countryCode="GY" value="592">+592</option>
                   <option data-countryCode="HT" value="509">+509</option>
                   <option data-countryCode="HN" value="504">+504</option>
                   <option data-countryCode="HK" value="852">+852</option>
                   <option data-countryCode="HU" value="36">+36</option>
                   <option data-countryCode="IS" value="354">+354</option>
                   <option data-countryCode="IN" value="91">+91</option>
                   <option data-countryCode="ID" value="62">+62</option>
                   <option data-countryCode="IR" value="98">+98</option>
                   <option data-countryCode="IQ" value="964">+964</option>
                   <option data-countryCode="IE" value="353">+353</option>
                   <option data-countryCode="IL" value="972">+972</option>
                   <option data-countryCode="IT" value="39">+39</option>
                   <option data-countryCode="JM" value="1876">+1876</option>
                   <option data-countryCode="JP" value="81">+81</option>
                   <option data-countryCode="JO" value="962">+962</option>
                   <option data-countryCode="KZ" value="7">+7</option>
                   <option data-countryCode="KE" value="254">+254</option>
                   <option data-countryCode="KI" value="686">+686</option>
                   <option data-countryCode="KP" value="850">+850</option>
                   <option data-countryCode="KR" value="82">+82</option>
                   <option data-countryCode="KW" value="965">+965</option>
                   <option data-countryCode="KG" value="996">+996</option>
                   <option data-countryCode="LA" value="856">+856</option>
                   <option data-countryCode="LV" value="371">+371</option>
                   <option data-countryCode="LB" value="961">+961</option>
                   <option data-countryCode="LS" value="266">+266</option>
                   <option data-countryCode="LR" value="231">+231</option>
                   <option data-countryCode="LY" value="218">+218</option>
                   <option data-countryCode="LI" value="417">+417</option>
                   <option data-countryCode="LT" value="370">+370</option>
                   <option data-countryCode="LU" value="352">+352</option>
                   <option data-countryCode="MO" value="853">+853</option>
                   <option data-countryCode="MK" value="389">+389</option>
                   <option data-countryCode="MG" value="261">+261</option>
                   <option data-countryCode="MW" value="265">+265</option>
                   <option data-countryCode="MY" value="60">+60</option>
                   <option data-countryCode="MV" value="960">+960</option>
                   <option data-countryCode="ML" value="223">+223</option>
                   <option data-countryCode="MT" value="356">+356</option>
                   <option data-countryCode="MH" value="692">+692</option>
                   <option data-countryCode="MQ" value="596">+596</option>
                   <option data-countryCode="MR" value="222">+222</option>
                   <option data-countryCode="YT" value="269">+269</option>
                   <option data-countryCode="MX" value="52">+52</option>
                   <option data-countryCode="FM" value="691">+691</option>
                   <option data-countryCode="MD" value="373">+373</option>
                   <option data-countryCode="MC" value="377">+377</option>
                   <option data-countryCode="MN" value="976">+976</option>
                   <option data-countryCode="MS" value="1664">+1664</option>
                   <option data-countryCode="MA" value="212">+212</option>
                   <option data-countryCode="MZ" value="258">+258</option>
                   <option data-countryCode="MN" value="95">+95</option>
                   <option data-countryCode="NA" value="264">+264</option>
                   <option data-countryCode="NR" value="674">+674</option>
                   <option data-countryCode="NP" value="977">+977</option>
                   <option data-countryCode="NL" value="31">+31</option>
                   <option data-countryCode="NC" value="687">+687</option>
                   <option data-countryCode="NZ" value="64">+64</option>
                   <option data-countryCode="NI" value="505">+505</option>
                   <option data-countryCode="NE" value="227">+227</option>
                   <option data-countryCode="NG" value="234">+234</option>
                   <option data-countryCode="NU" value="683">+683</option>
                   <option data-countryCode="NF" value="672">+672</option>
                   <option data-countryCode="NP" value="670">+670</option>
                   <option data-countryCode="NO" value="47">+47</option>
                   <option data-countryCode="OM" value="968">+968</option>
                   <option data-countryCode="PW" value="680">+680</option>
                   <option data-countryCode="PA" value="507">+507</option>
                   <option data-countryCode="PG" value="675">+675</option>
                   <option data-countryCode="PY" value="595">+595</option>
                   <option data-countryCode="PE" value="51">+51</option>
                   <option data-countryCode="PH" value="63">+63</option>
                   <option data-countryCode="PL" value="48">+48</option>
                   <option data-countryCode="PT" value="351">+351</option>
                   <option data-countryCode="PR" value="1787">+1787</option>
                   <option data-countryCode="QA" value="974">+974</option>
                   <option data-countryCode="RE" value="262">+262</option>
                   <option data-countryCode="RO" value="40">+40</option>
                   <option data-countryCode="RU" value="7">+7</option>
                   <option data-countryCode="RW" value="250">+250</option>
                   <option data-countryCode="SM" value="378">+378</option>
                   <option data-countryCode="ST" value="239">+239</option>
                   <option data-countryCode="SA" value="966">+966</option>
                   <option data-countryCode="SN" value="221">+221</option>
                   <option data-countryCode="CS" value="381">+381</option>
                   <option data-countryCode="SC" value="248">+248</option>
                   <option data-countryCode="SL" value="232">+232</option>
                   <option data-countryCode="SG" value="65">+65</option>
                   <option data-countryCode="SK" value="421">+421</option>
                   <option data-countryCode="SI" value="386">+386</option>
                   <option data-countryCode="SB" value="677">+677</option>
                   <option data-countryCode="SO" value="252">+252</option>
                   <option data-countryCode="ZA" value="27">+27</option>
                   <option data-countryCode="ES" value="34">+34</option>
                   <option data-countryCode="LK" value="94">+94</option>
                   <option data-countryCode="SH" value="290">+290</option>
                   <option data-countryCode="KN" value="1869">+1869</option>
                   <option data-countryCode="SC" value="1758">+1758</option>
                   <option data-countryCode="SD" value="249">+249</option>
                   <option data-countryCode="SR" value="597">+597</option>
                   <option data-countryCode="SZ" value="268">+268</option>
                   <option data-countryCode="SE" value="46">+46</option>
                   <option data-countryCode="CH" value="41">+41</option>
                   <option data-countryCode="SI" value="963">+963</option>
                   <option data-countryCode="TW" value="886">+886</option>
                   <option data-countryCode="TJ" value="7">+7</option>
                   <option data-countryCode="TH" value="66">+66</option>
                   <option data-countryCode="TG" value="228">+228</option>
                   <option data-countryCode="TO" value="676">+676</option>
                   <option data-countryCode="TT" value="1868">+1868</option>
                   <option data-countryCode="TN" value="216">+216</option>
                   <option data-countryCode="TR" value="90">+90</option>
                   <option data-countryCode="TM" value="7">+7</option>
                   <option data-countryCode="TM" value="993">+993</option>
                   <option data-countryCode="TC" value="1649">+1649</option>
                   <option data-countryCode="TV" value="688">+688</option>
                   <option data-countryCode="UG" value="256">+256</option>
                   <option data-countryCode="GB" value="44">+44</option>
                   <option data-countryCode="UA" value="380">+380</option>
                   <option data-countryCode="AE" value="971">+971</option>
                   <option data-countryCode="UY" value="598">+598</option>
                   <option data-countryCode="US" value="1">+1</option>
                   <option data-countryCode="UZ" value="7">+7</option>
                   <option data-countryCode="VU" value="678">+678</option>
                   <option data-countryCode="VA" value="379">+379</option>
                   <option data-countryCode="VE" value="58">+58</option>
                   <option data-countryCode="VN" value="84">+84</option>
                   <option data-countryCode="VG" value="84">+1284</option>
                   <option data-countryCode="VI" value="84">+1340</option>
                   <option data-countryCode="WF" value="681">+681</option>
                   <option data-countryCode="YE" value="969">+969</option>
                   <option data-countryCode="YE" value="967">+967</option>
                   <option data-countryCode="ZM" value="260">+260</option>
                   <option data-countryCode="ZW" value="263">+263</option>    
             </Select>
             <input type="hidden" name="mothers_country_code" value={mothers_country_code}/>
              </div>
              <div className='w-75 px-1'>
              
               <input type="number" className='form-control rounded user_input_dashboard'  name="mothers_contact" id="mothers_contact" value={mothers_contact} onChange={(e)=>setMothersContact(parseInt(e.target.value))}  placeholder='Enter mothers contact' required/>
               
              </div>
                </div>
              </div>
        
             
              <hr className="mt-5"/>
              <h3 className='my-4 text-left blue-color fw-bold'>Passport Details</h3>
              <div className='row mb-3'>
               <div className='col-md-3'>
               <label className="blue-color">Do you have a passport ?<b className="text-danger">*</b></label>
                 <select className='form-control rounded user_input_dashboard' name='passport_exist' value={passport_exist} onChange={(e)=>{setPassportExist(parseInt(e.target.value))}}>
                   <option value="0">Choose</option>
                   <option value="1">Yes</option>
                   <option value="2">No</option>
                 </select>
               </div>
               {passport_exist===1?<div className='col-md-3'>
               <label className="blue-color">Passport Number<b className="text-danger">*</b></label>
                 <input type="text" className='form-control rounded user_input_dashboard' name="passport_number" value={passport_number} onChange={(e)=>setPassportNumber(e.target.value)} id="passport_number" placeholder='Enter passport number' required />
               </div>:""}
               {passport_exist===1? <div className='col-md-3'>
               <label className="blue-color">Passport Issue Date<b className="text-danger">*</b></label>
               <input type="date" className='form-control rounded user_input_dashboard' name ="passport_issue_date" value={passport_issue_date} onChange={(e)=>setPassportIssueDate(e.target.value)} id="passport_issue_date" placeholder='Enter passport issue date' required />
               </div>:""}
               {passport_exist===1?<div className='col-md-3'>
               <label className="blue-color">Passport Expiry Date<b className="text-danger">*</b></label>
               <input type="date" className='form-control rounded user_input_dashboard' name="passport_expiry_date"  value={passport_expiry_date}  onChange={(e)=>setPassportExpiryDate(e.target.value)} id="passport_expiry_date" placeholder='Enter passport expiry date' required />
               </div>:"" } 
               </div>
             </div>
             <ToastContainer />
               <div className='py-5 text-right pr-5'><button type="submit" className='btn bg-blue text-white btn-lg'> Save & Next</button></div>
             </form>
 
         </div>
    

     </div>
     </div>
    
  )
  }

export default UserProfile