import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import { ServiceData } from '../../@types/servicetype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import {Link,useParams} from 'react-router-dom';
import server from '../Utils';
import validator from 'validator';
import { CollegeData } from '../../@types/collegedata';
import { Select } from 'antd';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import UserProgressBar from '../UserProgressBar';



const UserPMQEdiT = () => {
  const [first_name,setfirstName]=useState<string>("");
  const [last_name,setlastName]=useState<string>('');
  const [user_email,setUserEmail]=useState<string>('')
  const [colleges,setColleges]=useState<Array<CollegeData>>([])
  const [collegeid,setCollegeId]=useState<number>(0);
  const [pmq_status,setPmQStatus]=useState<number>(0);
  const [pmq_start_date,setPmqStartDate]=useState<string>('');
  const [pmq_end_date,setPmqEndDate]=useState<string>('');
  const [pmq_finish_date,setPmqFinishDate]=useState<string>('');
  const [collegeslist,collegeList]=useState<Array<{value:number,label:string}>>([])
  const [country_id,setCountryId]=useState<number>(0) 
  const [world_countries,setWorldCountries]=useState<Array<{id:number,country:string,country_id:number,college:string}>>([]);   
  const [internship_start_date,setInternshipStartDate]=useState<string>("")
  const [internship_end_date,setInternshipEndDate]=useState<string>("0")
  const [loading, setLoading] = useState(false);
  const [form_progress,setFormProgress]=useState<number>(0)
  const [submission,setSubmission]=useState<boolean>(false);
  const {id}=useParams();
  let userid= id;
  useEffect(() => {
    
    server.get(`/get_world_countries`)
    .then((response)=>{
      setWorldCountries(response.data.countries)
    })
setLoading(true)
  server.get(`/operationuser/${userid}`)
  .then((response)=>{
    
    if(response.data.code===200){
   setfirstName(response.data.user.first_name)
   setlastName(response.data.user.last_name)
   setUserEmail(response.data.user.user_email)
    setCountryId(response.data.user.pmq_country)
    if(response.data.user.pmq_country!==null || response.data.user.pmq_country!==""){
    loadColleges(response.data.user.pmq_country)
    }
    setCollegeId(response.data.user.pmq_college);
    if(response.data.user.pmq_status!==null){
    setPmQStatus(response.data.user.pmq_status);
    }else{
      setPmQStatus(0);  
    }
    setPmqStartDate(response.data.user.pmq_start_date);
    setPmqEndDate(response.data.user.pmq_end_date);
    setPmqFinishDate(response.data.user.pmq_finish_date);
    if(response.data.user.internship_end_date===null){
      setInternshipEndDate('')
     }else{
      setInternshipEndDate(response.data.user.internship_end_date)
     }
     if(response.data.user.internship_end_date===null){
      setInternshipStartDate('')
     }else{
      setInternshipStartDate(response.data.user.internship_start_date)
     }


     if(response.data.user.form_progress!==null){
      setFormProgress(response.data.user.form_progress)
    }else{
      setFormProgress(0)
    }
     setLoading(false)
    }
})
    
  }, []);
  
  
 
  const loadColleges = async (id:number)=> 
{
  setCountryId(id)
 
  let token = "Bearer "+ localStorage.getItem('auth_token');

  await server.get(`/get_world_colleges/${id}`)
  .then(response=>{
    
    setColleges(response.data.colleges)
  })

}


const  userPmqDetails = (e:React.FormEvent<HTMLFormElement>) =>{
  e.preventDefault();
    
 
  var form=document.getElementById('user_address_details_form') as HTMLFormElement;
  var formData= new FormData(form);
  
  
  let date1 = new Date(pmq_start_date).getTime();
  let date2 = new Date(pmq_end_date).getTime();
  let date3 = new Date(pmq_finish_date).getTime();
  
  var start_date=new Date(internship_start_date).getTime();

  var end_date=new Date(internship_end_date).getTime();
 
if(collegeid===0 ){
  toast('Select  College')
}else if(pmq_status===0){
  toast('Select PMQ status')
}else if(pmq_start_date==="" || pmq_start_date===null)
{
  toast('Select PMQ start date')
}
else if(pmq_status===2   && pmq_end_date==="" )
{
  toast('Select PMQ End date')
}
else if(pmq_status===2   && pmq_end_date===null )
{
  toast('Select PMQ End date')
}
else if(pmq_status===3   && pmq_end_date==="" )
{
  toast('Select PMQ End date')
}
else if(pmq_status===3   && pmq_end_date===null )
{
  toast('Select PMQ End date')
}
else if(pmq_status===3 && pmq_finish_date==="")
{
  toast('Select PMQ Finish date')
}else if(pmq_status===3 && pmq_finish_date===null)
{
  toast('Select PMQ Finish date')
}
  if (pmq_status !== 0 && date1 === date2) {
    toast('PMQ Start Date cant be equal to PMQ end Date');
  } else if (pmq_status === 2 && date1 > date2) {
    toast('PMQ Start Date cant be greater than PMQ end Date');
  } else if (pmq_status === 3 && date2 > date3) {
    toast('PMQ End Date cant be greater than PMQ Finish Date');
  } else if (pmq_status === 3 && date1 > date3) {
    toast('PMQ Start Date cant be bigger than PMQ Finish Date');
  } else if (pmq_status === 3 && date3 < date2) {
    toast('PMQ Finish Date cant be greater than PMQ End Date');
  } else if (pmq_status === 3 && date1 > date2) {
    toast('PMQ Start Date cant be bigger than PMQ End Date');
  } else if (pmq_status === 3 && (date1 >= date2 || date2 >= date3)) {
    toast('Invalid date range. Please ensure PMQ Start Date is before PMQ End Date, and PMQ End Date is before PMQ Finish Date.');
  }else if((pmq_status===2|| pmq_status===3) && validator.isEmpty(internship_start_date)){
    toast('Enter the Internship Start Date')
  }
  else if((pmq_status===2 || pmq_status===3)  && validator.isEmpty(internship_end_date)){
    toast('Enter the Internship End Date')
  }else if(start_date===end_date){
    toast('Internship Start date cant be equal to Internship End Date');
  }
  else if(start_date>end_date){
    toast('Internship Start date cant be Greater than Internship End Date');
  }

else{
  setLoading(true)
  setSubmission(true)
  server.post(`/add_pmqdetails/${userid}`,formData)
  .then((response)=>{
   if(response.data.code===200)
   {
    toast(response.data.message)
    setLoading(false)
    if(loading===false){
    window.location.href="/language_test";
    }

   }else
   {
    setLoading(false)
    toast(response.data.message)
   }
  })
  }

} 

const saveDetails=()=>{
  
  var form=document.getElementById('user_address_details_form') as HTMLFormElement;
  var formData= new FormData(form);
  
  
  let date1 = new Date(pmq_start_date).getTime();
  let date2 = new Date(pmq_end_date).getTime();
  let date3 = new Date(pmq_finish_date).getTime();
  
  var start_date=new Date(internship_start_date).getTime();

  var end_date=new Date(internship_end_date).getTime();
  console.log(pmq_start_date)
if(collegeid===0 ){
  toast('Select  College')
}else if(pmq_status===0){
  toast('Select PMQ status')
}else if(pmq_start_date==="" || pmq_start_date===null)
{
  toast('Select PMQ start date')
}
else if(pmq_status===2   && pmq_end_date==="" )
{
  toast('Select PMQ End date')
}
else if(pmq_status===2   && pmq_end_date===null )
{
  toast('Select PMQ End date')
}
else if(pmq_status===3   && pmq_end_date==="" )
{
  toast('Select PMQ End date')
}
else if(pmq_status===3   && pmq_end_date===null )
{
  toast('Select PMQ End date')
}
else if(pmq_status===3 && pmq_finish_date==="")
{
  toast('Select PMQ Finish date')
}else if(pmq_status===3 && pmq_finish_date===null)
{
  toast('Select PMQ Finish date')
}
  if (pmq_status !== 0 && date1 === date2) {
    toast('PMQ Start Date cant be equal to PMQ end Date');
  } else if (pmq_status === 2 && date1 > date2) {
    toast('PMQ Start Date cant be greater than PMQ end Date');
  } else if (pmq_status === 3 && date2 > date3) {
    toast('PMQ End Date cant be greater than PMQ Finish Date');
  } else if (pmq_status === 3 && date1 > date3) {
    toast('PMQ Start Date cant be bigger than PMQ Finish Date');
  } else if (pmq_status === 3 && date3 < date2) {
    toast('PMQ Finish Date cant be greater than PMQ End Date');
  } else if (pmq_status === 3 && date1 > date2) {
    toast('PMQ Start Date cant be bigger than PMQ End Date');
  } else if (pmq_status === 3 && (date1 >= date2 || date2 >= date3)) {
    toast('Invalid date range. Please ensure PMQ Start Date is before PMQ End Date, and PMQ End Date is before PMQ Finish Date.');
  }else if((pmq_status===2|| pmq_status===3) && validator.isEmpty(internship_start_date)){
    toast('Enter the Internship Start Date')
  }
  else if((pmq_status===2 || pmq_status===3)  && validator.isEmpty(internship_end_date)){
    toast('Enter the Internship End Date')
  }else if(start_date===end_date){
    toast('Internship Start date cant be equal to Internship End Date');
  }
  else if(start_date>end_date){
    toast('Internship Start date cant be Greater than Internship End Date');
  }

else{
  setLoading(true)
  setSubmission(true)
  server.post(`/add_pmqdetails/${userid}`,formData)
  .then((response)=>{
   if(response.data.code===200)
   {
    
    setLoading(false)
    Swal.fire({
      title:"Details Saved!",
      text: "PMQ Details Saved Successfully",
      icon: "success"
    });
   }else
   {
    setLoading(false)
    toast(response.data.message)
   }
  })
  }
}


interface CollegeOption {
  value: string;
  label: string;
}

// ...

let collegearray: CollegeOption[] = [];

colleges && colleges.forEach(function (college) {
  collegearray.push({ value: college.college, label: college.college });
});
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}

 
<div className='container-fluid mt-3'>
      {/* <div className='row my-5'>
          <div className='col-md-3 text-center px-4'><h2 className='blue-color'><b>PMQ Details</b></h2></div>
          <div className='col-md-9 mt-2 px-5'><UserProgressBar form_progress={form_progress}/></div>
      </div> */}
        </div>
        <h3 className='pl-5 blue-color fw-bold'>Primary Medical Qualification Details</h3>
        <div className='user_foarm_board userpath_right_pad pt-2 mt-5'>
          <form className='w-100' id="user_address_details_form" onSubmit={userPmqDetails}>
          <input type='hidden' name="form_progress" id="form_progress" value={3}/>
             <div className='row my-2'>
              <div className='col-md-4'>
              <label className="blue-color">Select PMQ Country<b className="text-danger">*</b></label>
            
              <Select
  id='pmq_country'
  data-name='pmq_country'
  className='w-100 user_input_dashboard rounded'
  value={country_id===0?null:country_id}
  onChange={(value) => {
    loadColleges(value);
    setCountryId(value);
  }}
  showSearch
  placeholder='Select Country Code'
  optionFilterProp="children"
>
  {world_countries.map((country) => (
    <Select.Option key={country.country} value={country.country}>{country.country}</Select.Option>
  ))}
</Select>

                      
                          <input type="hidden" name={"pmq_country"} value={country_id}/>
             </div>
             </div>
             <div className='row'>
              
                <div className='col-md-6'>
                    <label className="blue-color">PMQ College<b className="text-danger">*</b></label>
                      
                      <Select
  id='pmq_college'
  data-name='pmq_college'
  className='w-100 user_input_dashboard rounded'
  value={collegeid===0?null:collegeid}
  onChange={(value) => setCollegeId(value)}
  showSearch
  placeholder='Select College'
  optionFilterProp="children"
>
  {collegearray.map((college) => (
    <Select.Option key={college.value} value={college.value}>
      {college.label}
    </Select.Option>
  ))}
</Select>

            <input type="hidden" name="pmq_college" value={collegeid}/>
                </div>
                {collegeid!==0  ||collegeid!==null? <div className='col-md-6'>
                <label className="blue-color">Primary Medical Qualification Status<b className="text-danger">*</b></label>
                <select  className='form-control user_input_dashboard rounded' id="pmq_status"  name="pmq_status" value={pmq_status} onChange={(e)=>setPmQStatus(parseInt(e.target.value))}>
                    <option value="0">Select PMQ Status</option>
                    <option value="1">Ongoing</option>
                    <option value="2">Internship</option>
                    <option value="3">Completed</option>
                  </select>
                </div>:""}

         </div>

         <div className='row mt-3'>
         { pmq_status!==0? <div className='col-md-4'>
          <label className="blue-color">PMQ Start Date <b className="text-danger">*</b><span data-toggle="tooltip" title="Primary medical qualification start date (College Start Date)"><i className="text-danger" >  <span className="blue-color"><BsFillInfoCircleFill /></span></i></span></label>
          <input type="date" className='form-control user_input_dashboard rounded'  name="pmq_start_date" id="pmq_start_date" value={pmq_start_date}  onChange={(e)=>setPmqStartDate(e.target.value)} />
          </div>:""}
          { pmq_status!==0 && pmq_status!==1 ? <div className='col-md-4'>
          <label className="blue-color">PMQ End Date<b className="text-danger">*</b><span data-toggle="tooltip" title="Primary medical qualification end date (Last working day of the college / final exam end date)"><i className="text-danger" >  <span className="blue-color"><BsFillInfoCircleFill /></span></i></span></label>
          <input type="date" className='form-control user_input_dashboard rounded'  name="pmq_end_date" id="pmq_end_date" value={pmq_end_date}  onChange={(e)=>setPmqEndDate(e.target.value)} />
          </div>:""}
          { pmq_status===3? <div className='col-md-4'>
          <label className="blue-color">PMQ Finish Date<b className="text-danger">*</b><span data-toggle="tooltip" title="Primary medical qualification finish date (Final degree certificate awarded date)"><i className="text-danger" >  <span className="blue-color"><BsFillInfoCircleFill /></span></i></span></label>
          <input type="date"className='form-control user_input_dashboard rounded'  name="pmq_finish_date" id="pmq_finish_date" value={pmq_finish_date}  onChange={(e)=>setPmqFinishDate(e.target.value)}/>
          </div>:""}
          {pmq_status===2||pmq_status===3 ?<div className='row'>
          <hr className=' mt-5'/>
          <h3 className='blue-color fw-bold my-4'>Internship Details</h3>
            
                <div className='col-md-6'>
                    <label className="blue-color">Internship Start Date<b className="text-danger">*</b></label>
                    <input  className='form-control user_input_dashboard rounded' type="date" id="internship_start_date" name="internship_start_date" value={internship_start_date} onChange={(e)=>setInternshipStartDate(e.target.value)} required />
                    
                </div>
                <div className='col-md-6'>
                    <label className="blue-color">Internship End Date<b className="text-danger">*</b><span data-toggle="tooltip" title="If you do not have internship completion certificate please mention tentative completion date"><i className="text-danger" >  <span className="blue-color"><BsFillInfoCircleFill /></span></i></span></label>
                    <input className='form-control user_input_dashboard rounded' type="date" id="internship_end_date" name="internship_end_date" value={internship_end_date} onChange={(e)=>setInternshipEndDate(e.target.value)} required />
                </div>
            </div>:""}
         </div>
         <div className='mt-5 text-lg-center'><button type="button" onClick={saveDetails} disabled={submission === true} className='w-25 btn bg-blue text-white'>Update</button></div>
            <br/>
            <br/>
            <ToastContainer/>
         </form>

        </div>
   

    </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>

  )
}

export default UserPMQEdiT