import { ToastContainer, toast } from "react-toastify";
import UserHeader from "../../pages/UserHeader";
import UserDeclare from "./UserDeclare";

import React,{useEffect, useState} from 'react'
import server from "../Utils";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Loader from "../Login/Loader";
import UserSideBar from "../../pages/UserSideBar/UserSideBar";
const UserDeclaration = () => {
const[user_agreement_flag,setUserAgreementFlag]=useState<boolean>(false)
const [loading, setLoading] = useState(false);
const [first_name,setfirstName]=useState<string>("");
  const [last_name,setlastName]=useState<string>('');
  const [user_email,setUserEmail]=useState<string>('')
  useEffect(() => {
    let userid= localStorage.getItem('ClientID');
    server.get(`/operationuser/${userid}`)
    .then((response)=>{
      setfirstName(response.data.user.first_name)
      setlastName(response.data.user.last_name)
      setUserEmail(response.data.user.user_email)
    })
  })
const declrationSubmit=(e:React.FormEvent<HTMLFormElement>)=>{
  e.preventDefault()
  
  setUserAgreementFlag(true);
  if(user_agreement_flag===false){
    toast('check the agreement');
  }else{
    var form= document.getElementById('declarationForm') as HTMLFormElement;
    var formdata = new FormData(form)
    let userid= localStorage.getItem('ClientID');
    server.post(`/user_declaration/${userid}`,formdata)
    .then((response)=>{
      if(response.data.code===200){
        Swal.fire({
          title: "Submitted!",
          text: response.data.message,
          icon: "success"
        })
        .then((result)=>{
          if (result.isConfirmed) {
            setTimeout(function () { window.location.href="/userpath"; }, 1000);
          } 
         
        })
        
      }
    })
  }
}
if (loading) {
  return <Loader />;
}
  return (
 
<div className='container-fluid desktop_container' >
    <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name}/>
    <div className='horizontal-menu' style={{background:"transparent"}}>

      <div className='container-fluid userpath_right_pad mt-3 user_foarm_board'>
        <div className='row'>
          <div className='col text-left my-5 blue-color'><h2><b>Declaration</b></h2></div>
          {/* <div className='col text-right pr-5'><h3><i className="fa fa-question-circle" aria-hidden="true"></i>Support</h3></div> */}
        </div>
        <UserDeclare/>
        <form className="text-center w-100 my-5" id={'declarationForm'} onSubmit={declrationSubmit}>
         
         <input type="checkbox" className="mr-3" style={{width:"20px",height:"20px"}} name="user_agreement_flag" id="user_agreement_flag" checked={user_agreement_flag} onChange={(e)=>setUserAgreementFlag(!user_agreement_flag)} />
         <label className="px-1">I agree that the details I have provided above are correct to the best of my knowledge.</label>
         <ToastContainer/>
         <br/>
         <div className="pr-2"><Link className="btn bg-blue text-white" role="button" to="/documents">Previous</Link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button type="submit" className="btn bg-blue text-light">Submit</button></div>
         <br/>
       </form>
        
        </div>
      

    </div>
  
</div>


    // <div className='container-fluid' >
            
    //     <UserHeader />
    //     <h4 className='text-danger text-center my-4'>Declaration</h4>
        
    //     <form className="text-center w-100 my-5" id={'declarationForm'} onSubmit={declrationSubmit}>
         
    //       <input type="checkbox" className="mr-3" style={{width:"20px",height:"20px"}} name="user_agreement_flag" id="user_agreement_flag" checked={user_agreement_flag} onChange={(e)=>setUserAgreementFlag(!user_agreement_flag)} />
    //       <label>I agree that the details I have provided above are correct to the best of my knowledge.</label>
    //       <ToastContainer/>
    //       <br/>
    //       <div className="mx-5 px-5"><Link className="float-left btn btn-success" role="button" to="/documents">Previous</Link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<button type="submit" className="float-right btn btn-info text-light">Submit</button></div>
    //     </form>
        
      
          
  
)

}

export default UserDeclaration