import React, { useEffect, useState } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import pencil from '../../assets/images/pencil.png';
import { UserData } from '../../@types/usertype';
import server from '../Utils';
import { Table, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons'; // Import SearchOutlined
import moment from 'moment';
import ReactToPdf from 'react-to-pdf';
import * as XLSX from 'xlsx';
import { inchargetype } from '../../@types/inchargetype';
// import * as jsonexport from 'jsonexport';


const { Search } = Input;

const AllUsers: React.FC = () => {
  const role = localStorage.getItem('role');
  const [incharges, setIncharges] = useState<inchargetype[]>([]);
  const [loading, setLoading] = useState(false);
  let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
  useEffect(() => {
    server.get(`/incharges/${projectid}`)
      .then((response) => {
        
        setIncharges(response.data.incharges);
      });
  }, []);

  const deleteProgram = (deleteid: number) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        server.delete(`/incharge/${deleteid}`)
          .then((response) => {
            setIncharges(response.data.incharges);
            swalWithBootstrapButtons.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success"
            });
          });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your imaginary file is safe :)",
          icon: "error"
        });
      }
    });
  };

  const redirectUser = (id: string) => {
    
    window.location.href = "edit_incharge/" + id;
  };




  const columns: any[] =[
    {
      title: 'SL No',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: UserData, b: UserData) => a.id - b.id,
    },
    {
      title: 'Project',
      dataIndex: 'project_name',
      key: 'project_name',
      sorter: (a: inchargetype, b: inchargetype) => a.project_name.localeCompare(b.project_name),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Project`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, 'project_name')}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <button
            type="button"
            onClick={() => handleReset(clearFilters)}
            style={{ width: 90, marginRight: 8 }}
          >
            Reset
          </button>
          <button
            type="button"
            onClick={() => handleSearch(selectedKeys, confirm, 'project_name')}
            style={{ width: 90 }}
          >
            Search
          </button>
        </div>
      ),
      filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}  />,
      onFilter: (value: any, record: any) => record.project_name.toLowerCase().includes(value.toLowerCase()),
      render: (text: any) => (
        <span>{text}</span>
      ),
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
      sorter: (a: inchargetype, b: inchargetype) => a.product_name.localeCompare(b.product_name),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Product Name`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, 'product_name')}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <button
            type="button"
            onClick={() => handleReset(clearFilters)}
            style={{ width: 90, marginRight: 8 }}
          >
            Reset
          </button>
          <button
            type="button"
            onClick={() => handleSearch(selectedKeys, confirm, 'product_name')}
            style={{ width: 90 }}
          >
            Search
          </button>
        </div>
      ),
      filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}  />,
      onFilter: (value: any, record: any) => record.product_name.toLowerCase().includes(value.toLowerCase()),
      render: (text: any) => (
        <span>{text}</span>
      ),
    },
    {
        title: 'Stage Name',
        dataIndex: 'stage_name',
        key: 'stage_name',
        sorter: (a: inchargetype, b: inchargetype) => a.stage_name.toString().localeCompare(b.stage_name.toString()),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`Search Stage Name`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, 'stage_name')}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <button
              type="button"
              onClick={() => handleReset(clearFilters)}
              style={{ width: 90, marginRight: 8 }}
            >
              Reset
            </button>
            <button
              type="button"
              onClick={() => handleSearch(selectedKeys, confirm, 'stage_name')}
              style={{ width: 90 }}
            >
              Search
            </button>
          </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}  />,
        onFilter: (value: any, record: any) => record.stage_name.toString().toLowerCase().includes(value.toString().toLowerCase()),
        render: (text: any) => (
          <span>{text}</span>
        ),
      },
    {
      title: 'Primary Incharge',
      dataIndex: 'primary_incharge_firstname',
      key: 'primary_incharge_firstname',
      sorter: (a: inchargetype, b: inchargetype) => a.primary_incharge_firstname.toString().localeCompare(b.primary_incharge_firstname.toString()),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Primary Incharge`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, 'primary_incharge_firstname')}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <button
            type="button"
            onClick={() => handleReset(clearFilters)}
            style={{ width: 90, marginRight: 8 }}
          >
            Reset
          </button>
          <button
            type="button"
            onClick={() => handleSearch(selectedKeys, confirm, 'primary_incharge_firstname')}
            style={{ width: 90 }}
          >
            Search
          </button>
        </div>
      ),
      filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}  />,
      onFilter: (value: any, record: any) => record.primary_incharge_firstname.toString().toLowerCase().includes(value.toString().toLowerCase()),
      render: (text: any) => (
        <span>{text}</span>
      ),
    },
    {
      title: 'Secondary Incharge',
      dataIndex: 'secondary_incharge_firstname',
      key: 'user_email',
      sorter: (a: inchargetype, b: inchargetype) => a.secondary_incharge_firstname.localeCompare(b.secondary_incharge_firstname),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Secondary Incharge`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, 'secondary_incharge_firstname')}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <button
            type="button"
            onClick={() => handleReset(clearFilters)}
            style={{ width: 90, marginRight: 8 }}
          >
            Reset
          </button>
          <button
            type="button"
            onClick={() => handleSearch(selectedKeys, confirm, 'secondary_incharge_firstname')}
            style={{ width: 90 }}
          >
            Search
          </button>
        </div>
      ),
      filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}  />,
      onFilter: (value: any, record: any) => record.secondary_incharge_firstname.toLowerCase().includes(value.toLowerCase()),
      render: (text: any) => (
        <span>{text}</span>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: any, record: any) => (
        <div className='row'>
          
          <div className='col-md-4'>
            {role === "1" || role === "3" || role === "5" || role === "6" || role === "9" ? <img width="20px" height="20px" onClick={() => redirectUser(record.id.toString())} src={pencil} alt="pencil" /> : ""}
          </div>
          <div className='col-md-4'>
            {role === "1" ? <i onClick={() => deleteProgram(record.id)} className='fa fa-trash'></i> : ""}
          </div>
        </div>
      ),
    },
  ];
  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };

  return (
    <div className='conatiner-fluid'>
      <div className='d-flex'>
        <SideBar />
        <div className='headerbar'>
          <Header />
          <h4 className='text-center text-uppercase px-3 text-danger'>All Incharges</h4>
          <hr className='bg-danger px-1' />
          <div className='row' style={{ marginTop: "-1.2%" }}>
            <div className='col-md-1'></div>
            <div className='col-md-12 px-3'>
              <Link to="/add_incharge" className='btn btn-info text-white float-right mr-3 my-3'>Add Incharge</Link>
              <Table
                dataSource={incharges}
                columns={columns}
                pagination={{
                  //  pageSize: 50,
                   pageSizeOptions: ['50', '10', '25', '100'],
                   showSizeChanger: true,
                   showQuickJumper: true, // This allows users to jump to a specific page number
                   total: incharges.length, // Make sure to set the total number of records
                   responsive:true
                  }}
              rowKey="id" // Assuming 'id' is a unique key for each user
/>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllUsers;
