
import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProjectData } from '../../@types/projecttype';
import { Certificate } from 'crypto';
import {Link,useParams} from 'react-router-dom';
import { Toast } from 'react-toastify/dist/components';
import server from '../Utils';
import Loader from './Loader';



const EditDocuments = () => {
    const [projects,setProjects]=useState<Array<ProjectData>>([])
    const [project_id,setProjectId]=useState<number>(0);
    const [error,setError]=useState<string>();
    const {id} =useParams()
    const document_id=id;
    const [file_count,setFileCount]=useState<number>(1);
    const [loading, setLoading] = useState(false);

    
    useEffect(() => {

        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let id= +projectid
        setProjectId(id)
    
    }, []);
const submitEvent=(id:number)=>{

    var row= document.getElementById('sub'+id) as HTMLDivElement

    if(id===1){
    toast('Cant delete the first Row')
    }else{
    row.remove();
    }
}
var certificates=[];

for(let i=1;i<=file_count;i++){
    certificates.push(
        <div className='row px-2 mt-3' id={"sub"+i}>
            
            <div className='col-md-2'>
                <label>Certificate</label>
                <select className='form-control certificate_name' id={"certificate_name"+i} name={"certificate"+i}  >
                    <option value="0">Select Certificate</option>
                    <option value="1">CPD webinar</option>
                    <option value="2">Course</option>
                    <option value="3">Publication</option>
                    <option value="4">leadership</option>
                    <option value="5">Teaching</option>
                    <option value="6">Conference</option>
                    <option value="7">Presentation</option>
                    <option value="8">Others</option>
                    
                </select>
            </div>
            <div className='col-md-3'>
            <label>Certificate File</label>
            <input className='form-control certificate_file' type="file" name={"certificate_file"+i} id={"certificate_file"+i} required />
            </div>
            <div className='col-md-5'>
             <label>Certificate Description</label>
                <textarea className='w-100 form-control certificate_desc' id={"certificate_desc"+i} name={"certificate_desc"+i} required></textarea>
            </div>
           
            <div className="col-md-1">
               {i!==1?<button type="button" onClick={(e)=>submitEvent(i)} className='btn btn-danger mt-4'>-</button>:""}
            </div>
        </div>
    )
}
const addRow=()=>{
    const certificate_name = document.getElementsByClassName('certificate_name') as HTMLCollectionOf<HTMLInputElement>;
    if(certificate_name[certificate_name.length-1].value==="0"){
      toast('Select the Certificate Name at'+(certificate_name.length-1))
    }
    else{

    setFileCount(file_count+1)
    }

}
const addAdditionalDetails=(e:React.FormEvent<HTMLFormElement>)=>{
e.preventDefault();
setLoading(true)
var form=document.getElementById('add_additional_file_form') as HTMLFormElement
var formData =new FormData(form)
server.post(`/add_additional_documents/${document_id}`,formData)
.then((response)=>{
   if(response.data.code===200){ 
   
    Swal.fire(
        'Good job!',
        response.data.message,
        'success'
      )
    }
    setLoading(false)
    setTimeout(function () { window.history.back(); }, 1000);
})


}

if (loading) {
    return <Loader />;
  }
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}


    <h4 className='text-danger text-left'>Add Additional Documents</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>
    
    <div className='col-md-12'>
        <form id="add_additional_file_form" className='w-100' encType='multipart/form-data' onSubmit={addAdditionalDetails}>
            <input type="hidden" name={"file_count"} id={"file_count"} value={file_count} />
            <div className='text-right mr-5'><button type="button" onClick={(e)=>addRow()} className='btn btn-outline-danger my-1'>+</button></div>
            {certificates}   
            <div className='py-3 mx-3 row my-5'>
                <div className='col-md-3'>
                    <label>Language Test Certificate</label>
                   
                    <input  className="form-control"  type="file" name={"language_test_result"} id={"language_test_result"} />
                </div>
                <div className='col-md-3'>
                    <label>PLAB-1 Certificate</label>
                    
                    <input  className="form-control" type="file" name={"plab1_result"} id={"plab1_certificate"} />
                </div>
                <div className='col-md-3'>
                <label>PLAB-2 Certificate</label>
                   
                    <input className="form-control"  type="file" name={"plab2_result"} id={"plab2_certificate"} />
                </div>
                <div className='col-md-3'>
                    <label>Good Standing Certificate</label>
                   
                    <input className="form-control"  type="file" name={"good_standing_certificate"} id={"good_standing_certificate"} />
                </div>
            </div>       
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Submit</button></div>
        </form>
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default EditDocuments