import React, { useState, useEffect } from 'react';
import { useParams, Link, redirect } from 'react-router-dom';
import moment from 'moment';
import server from '../Utils';
import Loader from './Loader';
import UserSideBar from '../../pages/UserSideBar/UserSideBar';
import { FaRegQuestionCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { travelandaccomdationtype } from '../../@types/travelandaccomdationtype';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { FaCircleXmark } from 'react-icons/fa6';

const UserTravelAndAccomdation = () => {
    const [user_agreed, setUserAgreed] = useState<number>(0);
    const [first_name, setFirstName] = useState<string>("");
    const [last_name, setLastName] = useState<string>('');
    const [user_email, setUserEmail] = useState<string>('');
    const [records, setRecords] = useState<Array<travelandaccomdationtype>>([]);
    const [loading, setLoading] = useState(false);
    let id = localStorage.getItem('ClientID');

    var loggedIn=localStorage.getItem('loggedIn');
    useEffect(() => {
        if(loggedIn!=="true")
  {
    window.location.href="/userlogin";
  }
        setLoading(true);
        server.get(`/operationuser/${id}`).then((response) => {
            setFirstName(response.data.user.first_name);
            setLastName(response.data.user.last_name);
            setUserEmail(response.data.user.user_email);

            if (response.data.user.user_agreement_flag === 1) {
                setUserAgreed(1);
            } else {
                setUserAgreed(2);
            }

            if (response.data.user.user_agreement_flag === 0 || response.data.user.user_agreement_flag === null) {
                Swal.fire({
                    title: "Profile Incomplete",
                    text: "You won't be able to start services until profile is complete!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#233974",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Complete Profile"
                }).then((result) => {
                    if (result.isConfirmed) {
                        redirect("/user_profile");
                        window.location.href = "/user_profile";
                    }
                });
            }
        });

        server.get(`/travelandaccomdations/${id}`).then((response) => {
            setRecords(response.data.travelandaccomdation);
            setLoading(false);
        });
    }, [id]);

    return (
        <div className='container-fluid desktop_container'>
            <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name} />
            <div className='horizontal-menu' style={{ background: "transparent" }}>
                <div className='container-fluid mt-4 userpath_right_pad user_foarm_board' style={{height:"100vh"}} >
                    <div className='d-flex mb-2 mt-3 prog-info'>
                        <div className='w-75 text-left'>
                            <h1><b className='blue-color'>Travel & Accomdation</b></h1>
                        </div>
                        <div className='w-25 text-right blue-color fw-bold' style={{ fontSize: "29px" }}>
                            <FaRegQuestionCircle className='mb-1' />
                            <span className='support_hide'> Support</span>
                        </div>
                    </div>
                    <div className='mt-4'>
                        {loading ? <Loader /> : null}
                        {user_agreed===2?<p className="text-danger">
                            <Link to="/user_profile">Please Complete Profile here</Link>
                        </p>:""}
                        {user_agreed === 1 && records.length > 0 ? (
                            records.map((record, index) => (
                                <div className='w-100 box-shadow p-4 mb-5' key={index}>
                                        <h3 className='my-3 blue-color fw-bold'>Accomdation Details</h3>
                                            {/* <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='row'>
                                                <div className='col-md-5 text-left'><label className="blue-color">Send Accommodation Email</label></div>
                                              
                                                <div className='col-md-4 text-left'>{record.sendAccommodationEmail === 1 ? <span><IoCheckmarkCircle className='mb-1' style={{color:"#1cc12a",fontSize:"23px"}}/> Sent</span>: <span><FaCircleXmark  className='mb-1' style={{color:"#FF4141",fontSize:"23px"}}/> Not Sent</span>}</div>
                                                <div className='col-md-3'></div>
                                                </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='row'>
                                                <div className='col-md-5 text-left'><label className="blue-color">Send Travel Reminder Email</label></div>
                                                
                                                <div className='col-md-4 text-left'>{record.sendTravelReminderEmail === 1 ? <span><IoCheckmarkCircle className='mb-1' style={{color:"#1cc12a",fontSize:"23px"}}/> Sent</span> : <span><FaCircleXmark className='mb-1'  style={{color:"#FF4141",fontSize:"23px"}}/> Not Sent</span>}</div>
                                                <div className='col-md-3'></div>
                                                </div>
                                                </div>
                                            </div> */}
                                            <hr/>
                                            <div className='row'>
                                                <div className='col-md-4 text-left'><label className="blue-color">Accommodation Start Date:</label></div>
                                                <div className='col-md-4 text-left'>{moment(record.accommodationStartDate).format('Do MMMM YYYY')}</div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4 text-left'><label className="blue-color">Accommodation End Date:</label></div>
                                                <div className='col-md-4 text-left'>{moment(record.accommodationEndDate).format('Do MMMM YYYY')}</div>
                                            </div>
                                        
                                       
                                            <div className='row'>
                                                <div className='col-md-4 text-left'><label className="blue-color">Number of Days:</label></div>
                                                <div className='col-md-4 text-left'>{record.numberOfDays}</div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4 text-left'><label className="blue-color">Days Excluded:</label></div>
                                                <div className='col-md-4 text-left'>{record.daysIncluded} Days</div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4 text-left'><label className="blue-color">Days Included Package:</label></div>
                                                <div className='col-md-4 text-left'>{record.days_included_package} days</div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4 text-left'><label className="blue-color">Room Type:</label></div>
                                                <div className='col-md-4 text-left'>
                                                    {record.room_type === 1 ? "Single Ensuite room with sea view (Double bed)" :
                                                     record.room_type === 2 ? "Single Ensuite room with sea view (Single Bed)" :
                                                     record.room_type === 3 ? "Triple Room (Double Bed)" :
                                                     record.room_type === 4 ? "Triple Room (Single Bed)" :
                                                     record.room_type === 5 ? "Twin Sharing Ensuite (Single Bed)" :
                                                     record.room_type === 6 ? "Twin Sharing Ensuite (Double Bed)" :
                                                     record.room_type === 7 ? "Single Ensuite room (Double Bed)" :
                                                     record.room_type === 8 ? "Single Ensuite room (Single Bed)" : ""}
                                                </div>
                                            </div>
                                            
                                            <div className='row'>
                                                <div className='col-md-4 text-left'><label className="blue-color">Return Date:</label></div>
                                                <div className='col-md-4 text-left'>{moment(record.returnDate).format('Do MMMM YYYY')}</div>
                                            </div>

                                        <h3 className='blue-color fw-bold mt-4'>Property Details</h3>
                                        <hr/>
                                        <div className='row'>
                                                <div className='col-md-4 text-left'><label className="blue-color">Property Name:</label></div>
                                                <div className='col-md-4 text-left'>{record.vendor_name}</div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4 text-left'><label className="blue-color">Address Line 1:</label></div>
                                                <div className='col-md-4 text-left'>{record.line1}</div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4 text-left'><label className="blue-color">Address Line 2:</label></div>
                                                <div className='col-md-4 text-left'>{record.line2}</div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4 text-left'><label className="blue-color">City:</label></div>
                                                <div className='col-md-4 text-left'>{record.city}</div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4 text-left'><label className="blue-color">State:</label></div>
                                                <div className='col-md-4 text-left'>{record.state}</div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4 text-left'><label className="blue-color">Country:</label></div>
                                                <div className='col-md-4 text-left'>{record.country}</div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4 text-left'><label className="blue-color">Pin:</label></div>
                                                <div className='col-md-4 text-left'>{record.pin}</div>
                                            </div>
                                        
                                    
                                    {/* {record.notes !== null ? (
                                        <div>
                                            <label className='float-left blue-color'>Notes:</label><br />
                                            <div className='text-left w-100' style={{marginLeft:"20px"}}>{record.notes}</div>
                                        </div>
                                    ) : null} */}
                                </div>
                            ))
                        ) : user_agreed === 1 && records.length === 0 ? (
                            <div className="text-danger bold pl-3 pt-2" style={{ position: 'absolute', top: "12%" }}>
                                Hi there! You do not have any training allocated at the moment, kindly contact <a style={{ display: 'content' }} className='text-left' href="tel:+919019925836">9019925836</a>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserTravelAndAccomdation;
