import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';

import {Link,useParams} from 'react-router-dom';

import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { message } from 'antd';

import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import $ from 'jquery'; 

import validator from 'validator';
import { off } from 'process';
import { type } from 'os';
import { vendortype } from '../../@types/vendortype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import { ServiceData } from '../../@types/servicetype';
import {service_categorytypedata} from '../../@types/service_categorytypedata';
import server from '../Utils';
import UserInfo from '../UserInfo';
import Loader from './Loader';
const AddTrainingAndSubscriptionDetails = () => {
        
   
   
    const [project_id,setProjectId] = useState<number>(0);
    
    const [assesment_date,setAssesmentDate] =useState<string>('');
    const [service_category_id,setServiceCategoryId]=useState<number>(0);
    const [service_categories,setServiceCategories]=useState<Array<ServiceCategoryType>>([])
    const [services,setServices]=useState<Array<ServiceData>>([])
    const [service_id,setServiceId]=useState<number>(0)
    const[vendors,setVendors]=useState<Array<vendortype>>([]);
    const [vendor_id,setVendorId]=useState<number>(0);
    const [mode_of_training,setModeOfTraining]=useState(0);
    const[subscription_start_date,setSubscriptionStartDate]=useState<string>('')
    const[subscription_duration,setSubscriptionDuration]=useState<number>(0)
    const[subscription_period,setSubscriptionPeriod]=useState<number>(0);
    const[delivery_method,setDeliveryMethod]=useState<number>(0);
    const [service_category_type,setCategoryTypeId]=useState<number>(0)
    const [username,setUsername]=useState<string>('')
    const [password,setPassword]=useState<string>('')
    const {id}= useParams();
    const userid=id;
    const [loading, setLoading] = useState(false);

    const [service_category_types,setServiceCategorytypes]=useState<Array<service_categorytypedata>>([])
    let  productid:string=JSON.parse(localStorage.getItem("product_id")||'{}');
    useEffect(() => {
      let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
      let id = +projectid;
      setProjectId(id);
      if(parseInt(productid)===1){
      server.get(`/service_types_basedon_categories/10`)
      .then((response)=>{
         
          if(response.data.service_category_types.length>0){
              setServiceCategorytypes(response.data.service_category_types)
          }else{
              setServiceCategorytypes([]);
          }
      })
    }else if(parseInt(productid)===2){
      server.get(`/service_types_basedon_categories/22`)
      .then((response)=>{
         
          if(response.data.service_category_types.length>0){
              setServiceCategorytypes(response.data.service_category_types)
          }else{
              setServiceCategorytypes([]);
          }
      })
    }
    else if(parseInt(productid)===3){
      server.get(`/service_types_basedon_categories/33`)
      .then((response)=>{
         
          if(response.data.service_category_types.length>0){
              setServiceCategorytypes(response.data.service_category_types)
          }else{
              setServiceCategorytypes([]);
          }
      })
    }
      // server.get(`/get_service_categories`)
      // .then((response)=>{
      //     console.log(response)
      //     if(response.data.service_categories.length>0){
      //       setServiceCategories(response.data.service_categories)
      //     }else{
      //       setServiceCategories([]);
      //     }
      // })

      server.get(`/vendors/${id}`)
      .then((response)=>{
        setVendors(response.data.vendors)
  
      })
    }, []);
    const getServices =(id:number)=>{
      console.log(id)
        setCategoryTypeId(id)
       
        server.get(`/service_on_category_type/${id}`)
        .then((response)=>{
          console.log(response.data.services)
            setServices(response.data.services)
        })
       }
    const addTrainingAndSubscription=(e:React.FormEvent<HTMLFormElement>)=>{
      e.preventDefault();
      
      
    if(service_category_type===0){
      toast('Select Service Category Type');
    }else   if(service_id===0){
        toast('Select Service');
      }
    
    else  if(subscription_start_date===null||subscription_start_date===""){
      toast('Subscription Start  Date is required');
    }
    else if(subscription_duration===0){
      toast(' Enter Subscription Duration');
    }else if(subscription_period===0){
      toast('Select period');
    }
    else {
      // setLoading(true)

      var form= document.getElementById('addtrainingdetails') as HTMLFormElement;
      var formData=new FormData(form)
      server.post(`/add_training_and_subscriptions/${userid}`,formData)
      .then((response)=>{
        if(response.data.code===200){
          // setLoading(false)
          toast(response.data.message)
          setTimeout(function () { window.history.back(); }, 1000);
        }else{
          // setLoading(false)
          toast(response.data.message)
        }
      })
    }
    }
    const getCurrentDate = () => {
      const today = new Date();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const year = today.getFullYear();
  
      const formattedMonth = month < 10 ? `0${month}` : `${month}`;
      const formattedDay = day < 10 ? `0${day}` : `${day}`;
  
      return `${year}-${formattedMonth}-${formattedDay}`;
    };

    if (loading) {
      return <Loader />;
    }
  return (
    <div className='container-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}

<UserInfo />
    <h4 className='text-danger text-center'>Add Training & Subscription</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5 jumbotron mx-3'>
   
   
    <div className='col-md-12 px-5'>
        <form id="addtrainingdetails" onSubmit={addTrainingAndSubscription}  encType='multipart/form-data' className='w-100'>
          <div className='row'>
          <input type="hidden" name={"service_category_id"} id={"service_category_id"} value="10"/>
          <input type="hidden" name="project_id" id="project_id" value={project_id} />
          <input type="hidden" name="product_id" id="product_id" value={productid} />
            <div className='col-md-3'>
              <label>Subscription Category</label>
              <select id="service_category_type" className='form-control' name="service_category_type" value={service_category_type} onChange={(e)=>getServices(parseInt(e.target.value))}>
                <option value="0">Select Subscription Category</option>
                {service_category_types.map((service_category_type,index)=>(
                  <option value={service_category_type.id}>{service_category_type.service_category_type}</option>
                ))}
              </select>
            </div>
            {service_category_type!==0?<div className='col-md-3'>
              <label htmlFor="">Services :</label>
               <select className='form-control' id="service_id" name="service_id" value={service_id} onChange={(e)=>setServiceId(parseInt(e.target.value))}> 
             
                    <option value="0">Select Service </option>        
                         {services.map((service,index)=>(
                            <option value={service.id}>{service.service_name}</option>
                         ))}   
                </select>             
                            
                </div>:""}  


          </div>
         {service_category_type!==0?<div className='row mt-5'>
            
            
            <div className='col-md-3'>
              <label>Subscription Start Date</label>
              <input className="form-control" max={getCurrentDate()}  type="date" name="subscription_start_date" id="subscription_start_date" value={subscription_start_date} onChange={(e)=>setSubscriptionStartDate(e.target.value)} />
            </div>
            <div className='col-md-6'>
            <label>Subscription Duration </label>
              <div className='row'>
                    <div className='col-md-2'><input className="form-control" type="number" name="subscription_duration" id="subscription_duration" value={subscription_duration} onChange={(e)=>setSubscriptionDuration(parseInt(e.target.value))} /></div>      
                    <div className='col-md-10 text-left'>
                      <select className='form-control w-75' id="subscription_period" name="subscription_period" value={subscription_period} onChange={(e)=>setSubscriptionPeriod(parseInt(e.target.value))}>
                        <option value={0}>Choose Periods</option>
                        <option value={1}>Days</option>
                        <option value={2}>Weeks</option>
                        <option value={3}>Months</option>
                        <option value={4}>Years</option>
                      </select>
                    </div>      
              </div>            


            
            </div>
           
          </div>:""}
          {service_category_type!==0?<div className='row my-3'>
                 <div className='col-md-4'>
                  <label>User Name</label>
                  <input type="text" className='form-control' name={"username"} id={'username'} value={username} onChange={(e)=>setUsername(e.target.value)} required />
                  </div>         
                 <div className='col-md-4'>
                  <label>Password</label>
                  <input type="text" className='form-control' name={"password"} id={'password'} value={password} onChange={(e)=>setPassword(e.target.value)} required />
                  </div>         
          </div>:""}
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-info text-white mt-2'>Add Details</button></div>
        </form>
   
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default AddTrainingAndSubscriptionDetails;