import React, { useState, useEffect } from 'react';
import { useParams, Link, redirect } from 'react-router-dom';
import moment from 'moment';
import server from '../Utils';
import Loader from './Loader';
import { training_details } from '../../@types/training_details';
import UserSideBar from '../../pages/UserSideBar/UserSideBar';
import { FaRegQuestionCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { PortfolioType } from '../../@types/portfoliotypes';

const UserPortFolio = () => {
    const [user_agreed, setUserAgreed] = useState<number>(0);
    const [project_id,setProjectId]=useState<number>(0);
    const [user_exist,setUserExists]=useState<boolean>(false);
    const [portfolios,setPortfolio] =useState<Array<PortfolioType>>();
    // const { id } = useParams();
    const [first_name,setfirstName]=useState<string>("");
    const [last_name,setlastName]=useState<string>('');
    const [user_email,setUserEmail]=useState<string>('')
    const [loading,setLoading]=useState<boolean>(false);
    let id= localStorage.getItem('ClientID');
    var loggedIn=localStorage.getItem('loggedIn');
   useEffect(() => {
if(loggedIn!=="true")
 {
   window.location.href="/userlogin";
 }
        setLoading(true)
        server.get(`/operationuser/${id}`).then((response) => {
            setfirstName(response.data.user.first_name)
            setlastName(response.data.user.last_name)
            setUserEmail(response.data.user.user_email)
            
            if (response.data.user.user_agreement_flag === 1) {
                setUserAgreed(1);

            } else {
                setUserAgreed(2);
            }
            if(response.data.user.user_agreement_flag===0 || response.data.user.user_agreement_flag===null){
                Swal.fire({
                  title: "Profile Incomplete",
                  text: "You won't be able to start services until profile is complete!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#233974",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Complete Profile"
                  
                }).then((result) => {
                 
                  if (result.isConfirmed) {
                    
                    
                    redirect("/user_profile");
                    window.location.href="/user_profile"
                  }
                });
              }
        });
        server.get(`/get_portfolios/${id}`)
        .then((response)=>{
            
          setPortfolio(response.data.portfolio)
        })
      setLoading(false)
    }, [id]);
    
    if(loading===true){
      return <Loader/>
    }
   
    return (

        <div className='container-fluid desktop_container' >
    <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name}/>
    <div className='horizontal-menu' style={{background:"transparent"}}>

      <div className='container-fluid mt-4 userpath_right_pad user_foarm_board'>
      <div className='d-flex mb-2 mt-3 prog-info'>
        <div className='w-75 text-left'>
            <h1><b className='blue-color'>Portfolio</b></h1>
        </div>
        <div className='w-25 text-right blue-color fw-bold' style={{ fontSize: "29px" }}>
            <FaRegQuestionCircle className='mb-1' />
            <span className='support_hide'> Support</span>
        </div>
    </div>
    <div className='mt-4'>
    {user_agreed===2?<p className="text-danger">
                            <Link to="/user_profile">Please Complete Profile here</Link>
                        </p>:""}
        { user_agreed === 0 ? (
                        <p className="text-danger">
                            {/* <Link to="/user_profile">Please Complete Profile here</Link> */}
                        </p>
                    ) : user_agreed === 1 ? (
                        <>
                         
                         { portfolios && portfolios.map((portfolio,index)=>(<div className=''>
    
    {portfolio.service_category_type===21?<div className='row my-2 box-shadow p-4' style={{borderRadius:"20px"}}>
      <div className='col-md-12'><div className='row'><div className='col-md-12'><h3 className="blue-color fw-bold mb-3">Research And Publication Details</h3> </div></div><hr/></div>
      
      <div className="col-md-4 my-2"><h6 className="blue-color">Vendor Name</h6></div>
      <div className="col-md-4 my-2">{portfolio.vendor_name}</div>
      <div className='col-md-4 my-2'></div>
      <div className='col-md-4 my-2'>
        <h6 className="blue-color">Research Batch Name</h6>
      </div>
      <div className='col-md-4 my-2'>
       {portfolio.research_batch_name}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className='col-md-4 my-2'>
        <h6 className="blue-color">OET Login Password</h6>
      </div>
      <div className='col-md-4 my-2'>
       {portfolio.research_topic}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className="col-md-4 my-2">
      <h6 className="blue-color">Research Start Date</h6>
      </div>
      <div className="col-md-4 my-2">
      {moment(portfolio.research_start_date).format('Do MMMM YYYY')}
      </div>
      <div className="col-md-4 my-2"></div>


      <div className="col-md-4 my-2">
      <h6 className="blue-color">Research End Date</h6>
      </div>
      <div className="col-md-4 my-2">
       {moment(portfolio.research_end_date).format('Do MMMM YYYY')}
      </div>
      <div className="col-md-4 my-2"></div>
      
      
      <div className="col-md-4 my-2">
      <h6 className="blue-color">Research Bieng Published</h6>
      </div>
      <div className="col-md-4 my-2">
        {portfolio.research_bieng_published===1?"Yes":portfolio.research_bieng_published===2?"No":"Not Specified"}
      </div>
      <div className="col-md-4 my-2"></div>
      
      
      <div className="col-md-4 my-2">
        <h6 className="blue-color">Publication Name</h6>
      </div>
      <div className="col-md-4 my-2">
       {portfolio.publication_name}
      </div>
      <div className="col-md-4 my-2"></div>

      <div className="col-md-4 my-2">
        <h6 className="blue-color">Publication Uploaded</h6>
      </div>
      <div className="col-md-4 my-2">
      <a target="_blank" className='text-left' href={portfolio.publication_upload} >Publication Uploaded</a>
      </div>
      <div className="col-md-4 my-2"></div>

    </div>:portfolio.service_category_type===17?<div className='row my-3 box-shadow p-4' style={{borderRadius:"20px"}}>
    <div className='col-md-12'><div className='row'><div className='col-md-12'><h3 className="blue-color fw-bold mb-3"> Ngo Research Details</h3> </div></div><hr/></div>      
      <div className="col-md-4 my-2"><h6 className="blue-color">Vendor Name</h6></div>
      <div className="col-md-4 my-2">{portfolio.vendor_name}</div>
      <div className='col-md-4 my-2'></div>
      
      <div className='col-md-4 my-2'>
        <h6 className="blue-color">Ngo Batch Name</h6>
      </div>
      <div className='col-md-4 my-2'>
      {portfolio.ngo_batch_name}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className='col-md-4 my-2'>
        <h6 className="blue-color">NGO Description </h6>
      </div>
      <div className='col-md-4 my-2'>
      {portfolio.ngo_description}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className="col-md-4 my-2">
      <h6 className="blue-color">NGO Start Date</h6>
      </div>
      <div className="col-md-4 my-2">
      {moment(portfolio.ngo_start_date).format('Do MMMM YYYY')}
      </div>
      <div className="col-md-4 my-2"></div>


      <div className="col-md-4 my-2">
      <h6 className="blue-color">NGO End Date</h6>
      </div>
      <div className="col-md-4 my-2">
      {moment(portfolio.ngo_end_date).format('Do MMMM YYYY')}
      </div>
      <div className="col-md-4 my-2"></div>


      <div className="col-md-4 my-2">
      <h6 className="blue-color">NGO File </h6>
      </div>
      {portfolio.ngo_file!==null?<div className="col-md-4 my-2">
      <a target="_blank" className='text-left' href={portfolio.ngo_file}>NGO File</a>
      </div>:""}
      <div className="col-md-4 my-2"></div>

    </div>:portfolio.service_category_type===19?<div className='row my-3 box-shadow p-4' style={{borderRadius:"20px"}}>
    <div className='col-md-12'><div className='row'>
      <div className='col-md-12'><h3 className="blue-color fw-bold mb-3">Teaching Details</h3> </div></div><hr/></div>   
    
        
      {portfolio.vendor_name && <div><div className="col-md-4 my-2"><h6 className="blue-color">Vendor Name</h6></div>
      <div className="col-md-4 my-2">{portfolio.vendor_name}</div>
      <div className='col-md-4 my-2'></div></div>}
      
      <div className='col-md-4 my-2'>
        <h6 className="blue-color">Teaching Date</h6>
      </div>
      <div className='col-md-4 my-2'>
      {moment(portfolio.teaching_date).format('Do MMMM YYYY')}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className='col-md-4 my-2'>
        <h6 className="blue-color">Teaching Topic</h6>
      </div>
      <div className='col-md-4 my-2'>
       {portfolio.teaching_topic}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className="col-md-4 my-2">
      <h6 className="blue-color">Feed Back Form Link</h6>
      </div>
      <div className="col-md-4 my-2">
       <a target="_blank" className='text-left' href={portfolio.feedback_form_link}>Feedback form Link</a>
      </div>
      <div className="col-md-4 my-2"></div>


      

    </div>:portfolio.service_category_type===20?<div className='row my-3 box-shadow p-4' style={{borderRadius:"20px"}}>
    <div className='col-md-12'>
      <div className='row'><div className='col-md-12'><h3 className="blue-color fw-bold mb-3">Audit And QIP Details</h3> </div></div><hr/></div>      
      
      <div className="col-md-4 my-2"><h6 className="blue-color">Vendor Name</h6></div>
      <div className="col-md-4 my-2">{portfolio.vendor_name}</div>
      <div className='col-md-4 my-2'></div>
      
      <div className='col-md-4 my-2'>
        <h6 className="blue-color">Audit And QIP Batch Name</h6>
      </div>
      <div className='col-md-4 my-2'>
        {/* {moment(portfolio.a_qip_batch_name).format('Do MMMM YYYY')} */}
         {portfolio.a_qip_batch_name}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className='col-md-4 my-2'>
        <h6 className="blue-color">Audit And QIP Topic Name</h6>
      </div>
      <div className='col-md-4 my-2'>
      {portfolio.a_qip_topic_name}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className="col-md-4 my-2">
      <h6 className="blue-color"> Audit And QIP Start Date</h6>
      </div>
      <div className="col-md-4 my-2">
      {moment(portfolio.a_qip_start_date).format('Do MMMM YYYY')}
      </div>
      <div className="col-md-4 my-2"></div>


      <div className="col-md-4 my-2">
      <h6 className="blue-color">Audit And QIP End Date</h6>
      </div>
      <div className="col-md-4 my-2">
      {moment(portfolio.a_qip_end_date).format('Do MMMM YYYY')}
      </div>
      <div className="col-md-4 my-2"></div>

      <div className="col-md-4 my-2">
      <h6 className="blue-color">QIP Name</h6>
      </div>
      <div className="col-md-4 my-2">
      {portfolio.qip_name}
      </div>
      <div className="col-md-4 my-2"></div>


      <div className="col-md-4 my-2">
      <h6 className="blue-color">Certificate</h6>
      </div>
      <div className="col-md-4 my-2">
       <a target="_blank" className='text-left' href={portfolio.upload_certificate}>Certificate Uploaded</a>
      </div>
      <div className="col-md-4 my-2"></div>

      <div className="col-md-4 my-2">
      <h6 className="blue-color">QIP Uploaded</h6>
      </div>
      <div className="col-md-4 my-2">
        <a target="_blank" className='text-left' href={portfolio.upload_qip}>QIP Uploaded</a>
      </div>
      <div className="col-md-4 my-2"></div>


     

    </div>:portfolio.service_category_type===18?<div className='row my-3 box-shadow p-4' style={{borderRadius:"20px"}}>
    <div className='col-md-12'><div className='row'><div className='col-md-5'><h3 className="blue-color fw-bold mb-3">Confernce Details</h3> </div></div><hr/></div>      
      
     { portfolio.vendor_name &&<> 
     <div className="col-md-4 my-2">
      <h6 className="blue-color">Vendor Name</h6>
      </div>
      <div className="col-md-4 my-2">{portfolio.vendor_name}</div>
      <div className='col-md-4 my-2'></div>
      </>}
      
      <div className='col-md-4 my-2'>
        <h6 className="blue-color">Participation Type</h6>
      </div>
      <div className='col-md-4 my-2'>
      {portfolio.participation_type===1?"Presenter":portfolio.participation_type===2?"Attendee":""}
      </div>
      <div className='col-md-4 my-2'></div>

      <div className='col-md-4 my-2'>
        <h6 className="blue-color">Conference Mode</h6>
      </div>
      <div className='col-md-4 my-2'>
       {portfolio.conference_mode===1?"Online":portfolio.conference_mode===2?"Offline":""}
      </div>
      <div className='col-md-4 my-2'></div>
     
      <div className='col-md-4 my-2'>
        <h6 className="blue-color">Conference Date</h6>
      </div>
      <div className='col-md-4 my-2'>
      {moment(portfolio.conference_date).format('Do MMMM YYYY')}
      </div>
      <div className='col-md-4 my-2'></div>

      

    </div>:""}


 </div>))}
                        </>
                    ) : user_agreed === 1 && !portfolios ? (
                        <div className="text-danger bold pl-3 pt-2" style={{ position: 'absolute', top: "12%" }}>
                            Hi there! You do not have any training allocated at the moment, kindly contact <a target="_blank" style={{ display: 'content' }} className='text-left' href="tel:+919019925836">9019925836</a>
                        </div>
                    ) : null}
        </div>
        
        </div>
       
   

    </div>
  
</div>


        
    );
};

export default UserPortFolio;
