import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';

import {Link,NavLink,useParams} from 'react-router-dom';

import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { message } from 'antd';

import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import $ from 'jquery'; 

import validator from 'validator';
import { off } from 'process';
import { type } from 'os';
import { vendortype } from '../../@types/vendortype';
import TestBookings from '../TestBooking/TestBookings';
import AddTestBookingDetails from './AddTestbookingDetails';
import { testbookingtype } from '../../@types/testbookingtype';
import server from '../Utils';
import moment from 'moment';
import Loader from './Loader';
interface props{
  user_agreed:number,
}

const TestBookingDetails:React.FC<props> = ({user_agreed}) => {
        
   
   
    const [project_id,setProjectId]=useState<number>(0);
    const [user_exist,setUserExists]=useState<boolean>(false);
    const [training_exists,setTrainingExists]=useState<boolean>(false);
    const [test_booking_exists,setTestBookingExist]=useState<boolean>(false);
    const [test_bookings,setTestBooking]=useState<Array<testbookingtype>>([]);
    const {id}= useParams();
    const [assesment_date,setAssesmentDate] =useState<string>('');
    const[service_category_name,setServiceCategoryName]=useState<string>('')
    const [service_name,setServiceName]=useState<string>('')
    const [vendor_name,setVendorName]=useState<string>('');
    const [vendor_id,setVendorId]=useState<number>(0);
    const [mode_of_training,setModeOfTraining]=useState(0);
    const [start_date,setStartDate]=useState<string>('')
    const [end_date,setEndDate]=useState<string>('');
    const [training_id,setTrainingId]=useState<number>(0);
    const [loading, setLoading] = useState(false);
    // const [user_agreed,setUserAgreed]=useState<number>(0);

    useEffect(() => {
      let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
      let pro_id= +projectid;
      
    server.get(`/test_bookings/${id}`)
    .then((response)=>{
      setTestBooking(response.data.test_bookings)
    })
      }, []);

      if (loading) {
        return <Loader />;
      }  

  return ( 
  <div>
    {user_agreed === 1 ? 
  
  <Link to={`/add_test_booking_detail/${id}`} className='btn btn-info text-light float-right'>+</Link> :
<p className='text-danger'>Kindly ask the doctor to fill their details in their portal</p>
}
    {/* <Link to={`/add_test_booking_detail/${id}`} className='btn btn-info float-right text-white' >Add Test Booking </Link> */}
    <br/>
  <div className='my-3'>
    {/* {user_exist===false?<Link to={`/add_onboarding_details/${id}`}  className='btn btn-info text-light text-capitalize'>Add Onboarding details before proceeding to Training</Link>:""} */}
    {/* {user_exist===true &&  training_exists===false?<Link to={`/add_training_details/${id}`} className='btn btn-info text-white' >Add Training </Link>:""} */}
    
    {test_bookings.map((test_booking,index)=>(
      <div className='my-4 jumbotron'>
        <div className='row'><div className='col-md-3'><h2>Test Booking-{index+1}</h2></div><div className='col-md-2'></div><div className='col-md-3'></div><div className='col-md-4 text-right'><Link className='text-dark' to={`/edit_test_booking_detail/${test_booking.id}`}><i style={{fontSize:"20px"}} className='fa fa-edit text-info'></i></Link></div></div>
      <div className='row my-3'>
        <div className='col-md-4'><b>Test Date</b></div>
        <div className='col-md-6'><i>{moment(test_booking.test_date).format('Do MMMM YYYY')}</i></div>
      </div>
      <div className='row my-2'>
       <div className='col-md-4'><b>Booking Date</b></div>
       <div className='col-md-6'><i>{moment(test_booking.booking_date).format('Do MMMM YYYY')}</i></div>
      </div>
      <div className='row my-2'>
        <div className='col-md-4'><b>Test Type </b></div>
        <div className='col-md-6'><i>{test_booking.service_category_name}</i></div>
      </div>
      <div className='row my-2'>
       <div className='col-md-4'><b>Test</b></div>
       <div className='col-md-6'><i>{test_booking.service_name}</i></div>
      </div>
      <div className='row my-2'>
        <div className='col-md-4'><b>Country</b></div>
        <div className='col-md-6'><i>{test_booking.countryname}</i></div>
      </div>
      <div className='row my-2'>
       <div className='col-md-4'><b>State</b></div>
       <div className='col-md-6'><i>{test_booking.statename}</i></div>
      </div>
      <div className='row my-2'>
        <div className='col-md-4'><b>City</b></div>
        <div className='col-md-6'><i>{test_booking.cityname}</i></div>
      </div>
      <div className='row my-2'>
       <div className='col-md-4'><b>Venue</b></div>
       <div className='col-md-6'><i>{test_booking.venue_name}</i></div>
      </div>
      <div className='row my-2'>
        <div className='col-md-4'><b>Test Status</b></div>
        <div className='col-md-6'><i>{test_booking.test_status===1?"Booked":test_booking.test_status===2?"Attended":test_booking.test_status===3?"Missed":test_booking.test_status===4?"Cancelled by Authority":test_booking.test_status===5?"Cancelled by Client":test_booking.test_status===6?"Rescheduled":""}</i></div>
      </div>
      {test_booking.result_status!==null?<div className='row'>
     <div className='col-md-4'><b>Test Result</b></div>
        <div className='col-md-6'><i>{test_booking.result_status===1?"Waiting For Result":test_booking.result_status===2?"Pass":test_booking.result_status===3?"Fail":""}</i></div>
      </div>:""}
      <div className='row my-2'>
       <div className='col-md-4'><b>Note</b></div>
       <div className='col-md-8'><i>{test_booking.note}</i></div>
      </div>
      {test_booking.created_by && test_booking.created_by!=="" ?<div className='row my-2'>
        <div className="col-md-4"><b>Created By</b></div>
        <div className='col-md-8'>{test_booking.created_by}</div>
      </div>:""}
      {test_booking.updated_by && test_booking.updated_by!=="" ?<div className='row my-2'>
        <div className="col-md-4"><b>Updated By</b></div>
        <div className='col-md-8'>{test_booking.updated_by}</div>
      </div>:""}
  

</div>

    ))}
    </div>
    </div>)
}

export default TestBookingDetails;