import React,{createContext,useReducer} from 'react';
import { LoginReducer } from '../reducers/loginReducer';
import {LoginState ,loginContextType} from "../../@types/login";


const initialState :LoginState={
    userDetails:{email:"",
        first_name:"",
        last_name:"",
        phone:0,
        role:0,
        id:0,},
    token:"",
    loading:true,
    error:"",
    isloggedIn:false

}
 
interface Props {
    children: React.ReactNode;
  }
export const LoginContext = createContext({} as loginContextType);
export const LoginProvider:React.FC<Props>=({children})=>{
    const [state,logindispatch] =useReducer(LoginReducer,initialState);

    const login =(login:boolean)=>{
        logindispatch({type:"REQUEST_LOGIN",payload:login});
    }
    const loginSuccess=(data:any)=>{
        
        logindispatch({type:"LOGIN_SUCCESS",payload:data});
    }
    const setToken=(token:string)=>
    {
        logindispatch({type:"SET_TOKEN",payload:token})
    }
    const logOut=()=>{
        logindispatch({type:"LOGOUT"});
    }
    const loginError=(message:string)=>
    {
        logindispatch({type:"LOGIN_ERROR",payload:message});
    }

return(
<LoginContext.Provider value={{loginDetails:state.userDetails,loginstate:state,login,loginSuccess,setToken,logOut,loginError}}>{children}</LoginContext.Provider>
)
}