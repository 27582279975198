import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';

import {Link,useParams} from 'react-router-dom';

import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { message } from 'antd';

import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import $ from 'jquery'; 

import validator from 'validator';
import { off } from 'process';
import { type } from 'os';
import { vendortype } from '../../@types/vendortype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import { ServiceData } from '../../@types/servicetype';
import {service_categorytypedata} from '../../@types/service_categorytypedata';
import server from '../Utils';
import UserInfo from '../UserInfo';
import Loader from './Loader';
import { multipleusertype } from '../../@types/multipleusertype';
import Multiselect from 'multiselect-react-dropdown';
const AddMultipleTrainingAndSubscriptionDetails = () => {
        
   
   
    const [project_id,setProjectId] = useState<number>(0);
    
    const [assesment_date,setAssesmentDate] =useState<string>('');
    const [service_category_id,setServiceCategoryId]=useState<number>(0);
    const [service_categories,setServiceCategories]=useState<Array<ServiceCategoryType>>([])
    const [services,setServices]=useState<Array<ServiceData>>([])
    const [service_id,setServiceId]=useState<number>(0)
    const[vendors,setVendors]=useState<Array<vendortype>>([]);
    const [vendor_id,setVendorId]=useState<number>(0);
    const [mode_of_training,setModeOfTraining]=useState(0);
    const[subscription_start_date,setSubscriptionStartDate]=useState<string>('')
    const[subscription_duration,setSubscriptionDuration]=useState<number>(0)
    const[subscription_period,setSubscriptionPeriod]=useState<number>(0);
    const[delivery_method,setDeliveryMethod]=useState<number>(0);
    const [service_category_type,setCategoryTypeId]=useState<number>(0)
    const [username,setUsername]=useState<string>('')
    const [password,setPassword]=useState<string>('')
    const {id}= useParams();
    const userid=id;
    const [loading, setLoading] = useState(false);

    const [service_category_types,setServiceCategorytypes]=useState<Array<service_categorytypedata>>([])
    const [users,setUsers]=useState<Array<multipleusertype>>([]);
    const [user_ids,setUserIds]=useState<Array<number>>([])
    // var options: SelectProps['options'] = [];
    const [selectedValue,setSelectedValue]=useState<Array<multipleusertype>>([])
    const [error,setError]=useState(false)
    const [products, setProducts] = useState<Array<ProductData>>([]);
    const [product_id, setProductId] = useState<number>(0);
    const [count, setCount] = useState<number>(0);
    useEffect(() => {
      let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
      let id = +projectid;
      setProjectId(id);

      server.get(`/get_product/${id}`)
      .then((response) => {
        setProducts(response.data.product);
      });
      server.get('get_operationuser')
      .then((response)=>{
        setUsers(response.data.users)
      })

      server.get(`/service_types_basedon_categories/10`)
      .then((response)=>{
         
          if(response.data.service_category_types.length>0){
              setServiceCategorytypes(response.data.service_category_types)
          }else{
              setServiceCategorytypes([]);
          }
      })
      // server.get(`/get_service_categories`)
      // .then((response)=>{
      //     console.log(response)
      //     if(response.data.service_categories.length>0){
      //       setServiceCategories(response.data.service_categories)
      //     }else{
      //       setServiceCategories([]);
      //     }
      // })

      server.get(`/vendors/${id}`)
      .then((response)=>{
        setVendors(response.data.vendors)
  
      })
    }, []);
    const getServices =(id:number)=>{
      console.log(id)
        setCategoryTypeId(id)
       
        server.get(`/service_on_category_type/${id}`)
        .then((response)=>{
          console.log(response.data.services)
            setServices(response.data.services)
        })
       }
       const addTrainingAndSubscription = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        if (product_id === 0) {
            toast('Select the product');
        } else if (selectedValue.length === 0) {
            toast('Select at least one doctor');
        } else if (service_category_type === 0) {
            toast('Select Service Category Type');
        } else if (service_id === 0) {
            toast('Select Service');
        } else if (subscription_start_date === null || subscription_start_date === "") {
            toast('Subscription Start Date is required');
        } else if (subscription_duration === 0) {
            toast('Enter Subscription Duration');
        } else if (subscription_period === 0) {
            toast('Select period');
        } else {
            setLoading(true);
            setError(false);
    
            try {
                let successCount = 0;
                await Promise.all(selectedValue.map(async (element) => {
                    const form = document.getElementById('addtrainingdetails') as HTMLFormElement;
                    const formData = new FormData(form);
                    const response = await server.post(`/add_training_and_subscriptions/${element.id}`, formData);
                    if (response.data.code === 200) {
                        successCount++;
                    } else {
                        toast(`Couldn't be added for ${element.user}, try again`);
                    }
                }));
    
                if (successCount > 0 && successCount === selectedValue.length) {
                    toast('Training and subscription added successfully for all selected doctors');
                    setTimeout(() => {
                        window.location.href = "/training_and_subscription_list";
                    }, 2000);
                }
            } catch (err) {
                setError(true);
                toast('An error occurred, please try again');
            } finally {
                setLoading(false);
            }
        }
    };
    
    const getCurrentDate = () => {
      const today = new Date();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const year = today.getFullYear();
  
      const formattedMonth = month < 10 ? `0${month}` : `${month}`;
      const formattedDay = day < 10 ? `0${day}` : `${day}`;
  
      return `${year}-${formattedMonth}-${formattedDay}`;
    };

    if (loading) {
      return <Loader />;
    }

    const onSelect=(selectedList:[], selectedItem:any) => {
        setSelectedValue(selectedList)
     
      }
     
    const onRemove = (selectedList: multipleusertype[], removedItem: multipleusertype) => {
        setSelectedValue(selectedList);
      };
      const setallUsers = (id: number) => {
        setProductId(id);
        // setUserId(0);
        setError(true);
        setCount(count + 1);
        setSelectedValue([]);
        server.get(`get_operationuser/${id}`)
          .then((response) => {
            setUsers(response.data.users);
          });
      };
  return (
    <div className='container-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}

{/* <UserInfo/> */}
    <h4 className='text-danger text-center'>Add Training & Subscription</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5 jumbotron mx-3'>
   
  {project_id !== 0 && <div className='col-md-6'>
              <label>Products</label>
              <select className='form-control' name='product_id' id="product_id" value={product_id} onChange={(e) => setallUsers(parseInt(e.target.value))} required>
                <option value={0}>Select Product</option>
                {products.map((product) => (
                  <option key={product.id} value={product.id}>{product.product_name}</option>
                ))}
              </select>
            </div>}
            <div className='col-md-12'>
              {product_id !== 0 && (
                users.length !== 0 ? (
                  <div>
                    <label>Users</label>
                    <Multiselect
                      options={users}
                      placeholder="Select doctors here"
                      selectedValues={selectedValue}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="user"
                    />
                  </div>
                ) : (
                  <p className="text-danger">
                    Users List can't be displayed because no user filled their details yet
                  </p>
                )
              )}
            </div>
    <div className='col-md-12 px-5'>
    {/* <Multiselect options={users} placeholder='Select doctors here' selectedValues={selectedValue} onSelect={onSelect} displayValue='user'/> */}
    <br/>
       {product_id!==0? <form id="addtrainingdetails" onSubmit={addTrainingAndSubscription}  encType='multipart/form-data' className='w-100'>
          <div className='row'>
          <input type="hidden" name={"service_category_id"} id={"service_category_id"} value="10"/>
          <input type={'hidden'} name={'project_id'} value={project_id} />
                  <input type={'hidden'} name={'product_id'} value={product_id} />
           
            {/* <div className='col-md-3'>
              <label>Training And Subscription</label>
              <select className='form-control' id="service_category_id" name="service_category_id"   onChange={(e)=>getServices(parseInt(e.target.value))} >
                <option value={0}>Select Category</option>
               {service_categories.map((service_category,index)=>(
                 service_category.id===10?<option value={service_category.id}>{service_category.service_category_name}</option>:""
               ))}
              </select>
            </div> */}
            <div className='col-md-3'>
              <label>Subscription Category</label>
              <select id="service_category_type" className='form-control' name="service_category_type" value={service_category_type} onChange={(e)=>getServices(parseInt(e.target.value))}>
                <option value="0">Select Subscription Category</option>
                {service_category_types.map((service_category_type,index)=>(
                  <option value={service_category_type.id}>{service_category_type.service_category_type}</option>
                ))}
              </select>
            </div>
            {service_category_type!==0?<div className='col-md-3'>
              <label htmlFor="">Services :</label>
               <select className='form-control' id="service_id" name="service_id" value={service_id} onChange={(e)=>setServiceId(parseInt(e.target.value))}> 
             
                    <option value="0">Select Service </option>        
                         {services.map((service,index)=>(
                            <option value={service.id}>{service.service_name}</option>
                         ))}   
                </select>             
                            
                </div>:""}  


          </div>
         {service_category_type!==0?<div className='row mt-5'>
            
            
            <div className='col-md-3'>
              <label>Subscription Start Date</label>
              <input className="form-control" max={getCurrentDate()}  type="date" name="subscription_start_date" id="subscription_start_date" value={subscription_start_date} onChange={(e)=>setSubscriptionStartDate(e.target.value)} />
            </div>
            <div className='col-md-6'>
            <label>Subscription Duration </label>
              <div className='row'>
                    <div className='col-md-2'><input className="form-control" type="number" name="subscription_duration" id="subscription_duration" value={subscription_duration} onChange={(e)=>setSubscriptionDuration(parseInt(e.target.value))} /></div>      
                    <div className='col-md-10 text-left'>
                      <select className='form-control w-75' id="subscription_period" name="subscription_period" value={subscription_period} onChange={(e)=>setSubscriptionPeriod(parseInt(e.target.value))}>
                        <option value={0}>Choose Periods</option>
                        <option value={1}>Days</option>
                        <option value={2}>Weeks</option>
                        <option value={3}>Months</option>
                        <option value={4}>Years</option>
                      </select>
                    </div>      
              </div>            


            
            </div>
           
          </div>:""}
          {service_category_type!==0?<div className='row my-3'>
                 <div className='col-md-4'>
                  <label>User Name</label>
                  <input type="text" className='form-control' name={"username"} id={'username'} value={username} onChange={(e)=>setUsername(e.target.value)} required />
                  </div>         
                 <div className='col-md-4'>
                  <label>Password</label>
                  <input type="text" className='form-control' name={"password"} id={'password'} value={password} onChange={(e)=>setPassword(e.target.value)} required />
                  </div>         
          </div>:""}
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-info text-white mt-2'>Add Details</button></div>
        </form>:""}
   
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default AddMultipleTrainingAndSubscriptionDetails;