import React, { useEffect, useState } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import server from '../Utils';
import { ProductData } from '../../@types/producttype';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GridApi, ColumnApi, ColDef } from 'ag-grid-community';
import { Select, Button } from 'antd';

const { Option } = Select;

const Installtypes = () => {
  const [installtypes, setInstalltypes] = useState<Array<InstallmentTypeData>>([]);
  const [products, setProducts] = useState<Array<ProductData>>([]);
  const [product_id, setProductId] = useState<number>(0);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);

  let service_categoryid: string = JSON.parse(localStorage.getItem("project_id") || '{}');
  let id = +service_categoryid;

  useEffect(() => {
    Promise.all([
      server.get(`/get_product/${id}`),
      server.get(`/get_installment_types/${id}`)
    ]).then(([productResponse, installtypesResponse]) => {
      setProducts(productResponse.data.product || []);
      setInstalltypes(installtypesResponse.data.installment_types || []);
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  }, [id]);

  const deleteProgram = (deleteid: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        server.delete(`/installment/${deleteid}`)
          .then((response) => {
            setInstalltypes(response.data.installment_types || []);
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          });
      }
    });
  };

  const displayStages = (productid: number) => {
    setProductId(productid);
    server.get(`/getallinstallments/${productid}`)
      .then((response) => {
        setInstalltypes(response.data.installment_types || []);
      });
  };

  const onGridReady = (params: { api: GridApi, columnApi: ColumnApi }) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const columns: ColDef[] = [
    {
      headerName: 'SL No',
      valueGetter: "node.rowIndex + 1",
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    },
    {
      headerName: 'Installment Type Name',
      field: 'installment_type_name',
      sortable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      flex: 1,
    },
    {
      headerName: 'No of Installments',
      field: 'no_of_installments',
      sortable: true,
      filter: 'agNumberColumnFilter',
      floatingFilter: true,
      flex: 1,
    },
    {
      headerName: 'Package Name',
      field: 'product_name',
      sortable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      flex: 1,
    },
    {
      headerName: 'Project Name',
      field: 'project_name',
      sortable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      flex: 1,
    },
    {
      headerName: 'Actions',
      flex: 1,
      cellRenderer: (params: { data: InstallmentTypeData }) => (
        <div className="row">
          <div className="col-md-2">
            <Link to={`/installment_type_edit/${params.data.id}`}>
              <i className="fa fa-edit text-success"></i>
            </Link>
          </div>
          <div className="col-md-2">
            <i onClick={() => deleteProgram(params.data.id)} className="fa fa-trash text-danger"></i>
          </div>
        </div>
      ),
      sortable: false,
      filter: false,
    }
  ];

  return (
    <div className='container-fluid'>
      <div className='d-flex'>
        <SideBar />
        <div className='headerbar'>
          <Header />
          <h4 className='text-danger text-center'>Installment Types</h4>
          <hr className='bg-danger px-1' />
          <div className='row px-2'>
            <div className='col-md-6'>
              <Select className='w-50 my-4' value={product_id} onChange={(value: number) => displayStages(value)}>
                <Option value={0}>Select Product</Option>
                {products.map((product) => (
                  <Option key={product.id} value={product.id}>{product.product_name}</Option>
                ))}
              </Select>
            </div>
            <div className='col-md-6'>
              <div className='d-flex justify-content-end my-4'>
                <Link to="/add_installment_type" className='btn btn-outline-primary mr-2'>Add Installment Type</Link>
                <Button onClick={() => gridApi?.setFilterModel(null)} className='btn btn-secondary'>Reset Filters</Button>
              </div>
            </div>
          </div>
          <div className='row mt-5 px-2'>
            <div className='col-md-12'>
              <div className='ag-theme-alpine' style={{ flex: 1, width: '100%' }}>
                <AgGridReact
                  onGridReady={onGridReady}
                  columnDefs={columns}
                  rowData={installtypes}
                  defaultColDef={{
                    sortable: true,
                    filter: true,
                    floatingFilter: true,
                    flex: 1,
                  }}
                  pagination={true}
                  paginationPageSize={10}
                  domLayout='autoHeight'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Installtypes;
