import React, { useEffect, useState } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import moment from 'moment';
import { Link } from 'react-router-dom';
import server from '../Utils';
import Loader from './Loader';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GridApi, ColumnApi, ColDef } from 'ag-grid-community';
import { GMCRegistrationType } from '../../@types/gmc_registration';

const GMCAndRegistrationList: React.FC = () => {
  const [gmcregistrations, setGMCRegistrations] = useState<Array<GMCRegistrationType>>([]);
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);

  let projectid: string = JSON.parse(localStorage.getItem('project_id') || '{}');
  let id = +projectid;

  useEffect(() => {
    setLoading(true);
    server
      .get(`/get_gmc_registration/${id}`)
      .then((response) => {
        setGMCRegistrations(response.data.gmc_registration);
      })
      .catch((error) => {
        console.error('Error fetching GMC registration list:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const onGridReady = (params: { api: GridApi, columnApi: ColumnApi }) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const getColumnDefs = (): ColDef[] => [
    {
      headerName: 'SL No',
      valueGetter: "node.rowIndex + 1",
      sortable: true,
      filter: false,
      // maxWidth: 50,
    },
    {
      headerName: 'First Name',
      field:'first_name',
      // valueGetter: (params) => params.data.first_name + ' ' + params.data.last_name,
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Last Name',
      field:'last_name',
      // valueGetter: (params) => params.data.first_name + ' ' + params.data.last_name,
      sortable: true,
      filter: true,
    },
    
    {
      headerName: 'GMC Status',
      field: 'gmc_license_status',
      sortable: true,
      filter: true,
      valueFormatter: (params) => {
        switch (params.value) {
          case 1:
            return 'Yet To Start';
          case 2:
            return 'In Progress';
          case 3:
            return 'Submitted';
          case 4:
            return 'Received by user';
          default:
            return '';
        }
      },
    },
    {
      headerName: 'License Date',
      field: 'gmc_license_date',
      sortable: true,
      filter: 'agDateColumnFilter',
      valueFormatter: (params) =>
        params.value ? moment(params.value).format('Do MMMM YYYY') : 'N/A',
    },
    {
      headerName: 'Vendor Name',
      field: 'vendor_name',
      sortable: true,
      filter: true,
      valueFormatter: (params) => params.value ? params.value : 'N/A',
    },
    {
      headerName: 'Employer Name',
      field: 'employers_name',
      sortable: true,
      filter: true,
      valueFormatter: (params) => params.value ? params.value : 'N/A',
    },
    {
      headerName: 'Visa Application Status',
      field: 'application_status',
      sortable: true,
      filter: true,
      valueFormatter: (params) => {
        switch (params.value) {
          case 1:
            return 'Yet To Start';
          case 2:
            return 'In Progress';
          case 3:
            return 'Submitted';
          default:
            return 'N/A';
        }
      },
    },
    {
      headerName: 'Job Date',
      field: 'job_offer_date',
      sortable: true,
      filter: 'agDateColumnFilter',
      valueFormatter: (params) =>
        params.value ? moment(params.value).format('Do MMMM YYYY') : 'N/A',
    },
    {
      headerName: 'Actions',
      cellRenderer: (params: { data: { user_id: number; id: number } }) => (
        <Link
          className='text-dark text-center'
          to={`/edit_gmc_registration_details/${params.data.id}`}
        >
          <i className='fa fa-edit text-success'></i>
        </Link>
      ),
      sortable: false,
      filter: false, // Set filter to false for Actions column
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='container-fluid'>
      <div className='d-flex'>
        <SideBar />
        <div className='headerbar'>
          <Header />
          <h4 className='text-danger text-center'>GMC Registration Lists</h4>
          <hr className='bg-danger px-1' />
          <div className='row mt-5 px-2'>
            <div className='col-md-12'>
              <Link
                to={"/add_multiple_gmc_registration_details"}
                className='btn btn-info float-right mr-2 text-white mb-3'
                role="button"
              >
                +
              </Link>
            </div>
            <div className='col-md-12 ag-theme-alpine' style={{ height: '500px' }}>
              <AgGridReact
                onGridReady={onGridReady}
                columnDefs={getColumnDefs()}
                rowData={gmcregistrations}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={50}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GMCAndRegistrationList;
