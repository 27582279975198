import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import UserSideBar from '../../pages/UserSideBar/UserSideBar';
import UserHeader from '../../pages/UserHeader/UserHeader';
import { UserData } from '../../@types/usertype';
import { CollegeData } from '../../@types/collegedata';
import validator from 'validator';
import server from '../Utils';
import Loader from './Loader';
import UserProgressBar from '../UserProgressBar';
import { ServiceCategoryType } from '../../@types/service_category_type';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

const Support = () => {
  var loggedIn = localStorage.getItem('loggedIn');

  const [pmq_status, setPmQStatus] = useState<number>(0);
  const [gmc_reference_number_exist, setGmcReferenceNumberExist] = useState<number>(0)
  const [gmc_reference_number, setGmcReferenceNumber] = useState<string>('')
  const [loading, setLoading] = useState(false);
  const [first_name, setfirstName] = useState<string>("");
  const [last_name, setlastName] = useState<string>('');
  const [user_email, setUserEmail] = useState<string>('')
  const [form_progress, setFormProgress] = useState<number>(0)
  const [product_id, setProductId] = useState<number>(0)
  const [service_categories, setServiceCategories] = useState<Array<ServiceCategoryType>>([])
  const [service_category, setServiceCategorytype] = useState<number>(0);
  const [support_document, setSupportDocument] = useState<any[]>([]);

  if (loggedIn !== "true") {
    window.location.href = "/userlogin";
  }

  useEffect(() => {
    let userid = localStorage.getItem('ClientID');
    setLoading(true)
    server.get(`/operationuser/${userid}`)
      .then((response) => {

        if (response.data.code === 200) {
          setfirstName(response.data.user.first_name)
          setlastName(response.data.user.last_name)
          setUserEmail(response.data.user.user_email)
          setProductId(response.data.user.product_id)

          server.get(`/get_service_categories_all/${response.data.user.project_id}/${response.data.user.product_id}`)
            .then((response) => {

              setServiceCategories(response.data.service_categories)

            })
          if (response.data.user.pmq_status === null) {
            setPmQStatus(0);
          } else {
            setPmQStatus(response.data.user.pmq_status);
          }
          if (response.data.user.gmc_refrence_exists === null) {
            setGmcReferenceNumberExist(0);
          } else {
            setGmcReferenceNumberExist(response.data.user.gmc_refrence_exists);
          } if (response.data.user.gmc_reference_number === null) {
            setGmcReferenceNumber('')
          } else {
            setGmcReferenceNumber(response.data.user.gmc_reference_number)
          }
          if (response.data.user.form_progress !== null) {
            setFormProgress(response.data.user.form_progress)
          } else {
            setFormProgress(0)
          }


          setLoading(false)

        }
      })

  }, []);

  if (loading) {
    return <Loader />;
  }

  const updateDetails = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    var userid = localStorage.getItem('ClientID');

    var len = gmc_reference_number.toString().length;

    if (gmc_reference_number_exist === 0) {
      toast('Choose whether GMC reference number exists?')
    } else if (gmc_reference_number_exist === null) {
      toast('Choose whether GMC reference number exists?')
    }
    else if (gmc_reference_number_exist === 1 && gmc_reference_number === null) {
      toast('enter gmc reference Number');
    } else if (gmc_reference_number_exist === 1 && gmc_reference_number === "") {
      toast('enter gmc reference Number');
    } else if (gmc_reference_number_exist === 1 && gmc_reference_number === null) {
      toast('enter gmc reference Number');
    } else if (gmc_reference_number_exist === 1 && len !== 7) {

      toast('GMC Reference Number has to be 7 digits number')
    }

    else {
      var form = document.getElementById('gmc_and_intenship') as HTMLFormElement
      var formData = new FormData(form);
      setLoading(true);
      server.post(`/gmc_and_intenship/${userid}`, formData)
        .then((response) => {
          if (response.data.code === 200) {
            setLoading(false)
            toast(response.data.message)
            if (loading === false) {
              setTimeout(function () { window.location.href = "/employment_and_speciality"; }, 1000);
            }
          } else {
            setLoading(false)
            toast(response.data.message)
          }
        })
    }
  }

  return (
    <div className='container-fluid desktop_container'>
      <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name} />
      <div className='horizontal-menu' style={{ background: "transparent" }}>
        <div className='container-fluid mt-3'>
          <div className='user_foarm_board userpath_right_pad pt-2'>
            <h2 className='blue-color fw-bold my-4'>Support</h2>

            <h3 className="text-left mt-5 pl-3 blue-color fw-bold">Hello, Dr.{first_name}, what do you need help with?</h3>
            <form className='w-100' id="gmc_and_intenship" onSubmit={updateDetails}>
              <div className='row'>
                <div className='pl-3 col-md-4'>
                  <label className="blue-color fw-bold mt-5 mb-3" >Select appropriate area of enquiry</label>
                  <select className='form-control rounded user_input_dashboard' value={service_category} onChange={(e) => setServiceCategorytype(parseInt(e.target.value))} name="service_category">
                    <option value={0}>Select Enquiry Area</option>
                    {service_categories && service_categories.map((servicecategory, index) => (
                      <option value={servicecategory.id}>{servicecategory.service_category_name}</option>
                    ))}
                    <option value={service_categories.length}>Others</option>
                  </select>
                </div>
              </div>

              <div className='row my-5'>
                <div className='col-md-8 pl-3'>
                  <label className='my-3 blue-color fw-bold'>Explain The Problem</label>
                  <textarea className='form-control rounded user_input_dashboard' rows={4}></textarea>
                </div>
              </div>
              <div className='row my-5'>
                <div className='col-md-4 pl-3'>
                  <label className='my-3 blue-color fw-bold'>Supporting Documents</label>

                  <FilePond
                    files={support_document}
                    onupdatefiles={setSupportDocument}
                    allowMultiple={false}
                    name="support_document"
                    className={"rounded custom_file_pond"}
                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  />
                </div>
              </div>
              {/* Make sure the button is within the form */}
              <div className='text-right'><button type="submit" className='btn bg-blue text-white'>Submit</button></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Support;
