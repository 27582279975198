import React, { useEffect, useState } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import { Link } from 'react-router-dom';
import server from '../Utils';
import moment from 'moment';
import Loader from './Loader';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GridApi, ColumnApi } from 'ag-grid-community';

import { testbookingtype } from '../../@types/testbookingtype';

const TestBookingList: React.FC = () => {
  const [testbookings, setTestBookings] = useState<Array<testbookingtype>>([]);
  const [filteredTestBookings, setFilteredTestBookings] = useState<Array<testbookingtype>>([]);
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const [searchText, setSearchText] = useState<string>('');

  let projectid: string = JSON.parse(localStorage.getItem('project_id') || '{}');
  let id = +projectid;

  useEffect(() => {
    setLoading(true);
    server
      .get(`/testbooking_list/${id}`)
      .then((response) => {
        setTestBookings(response.data.test_booking);
        setFilteredTestBookings(response.data.test_booking); // Initialize filtered data
      })
      .catch((error) => {
        console.error('Error fetching test booking list:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const onGridReady = (params: { api: GridApi, columnApi: ColumnApi }) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const parseDate = (dateStr: string): string | null => {
    const dateFormats = [
      'YYYY/MM/DD',
      'YYYY-MM-DD',
      'DD/MM/YYYY',
      'MM/DD/YYYY',
      'Do MMMM YYYY', // For example: 1st January 2024
      'D MMMM YYYY',  // For example: 1 January 2024
      'YYYY-MM-DDTHH:mm:ss', // ISO 8601 format with time
      'YYYY-MM-DDTHH:mm:ss.SSS', // ISO 8601 format with milliseconds
      'DD-MM-YYYY', // For example: 14-02-2014
      'D-M-YYYY', // For example: 4-2-2024
      'D/M/YYYY', // For example: 4/2/2024
    ];

    for (const format of dateFormats) {
      const parsedDate = moment(dateStr, format, true);
      if (parsedDate.isValid()) {
        return parsedDate.toISOString().split('T')[0]; // Convert to yyyy-mm-dd
      }
    }

    return null;
  };

  const isValidDate = (dateInput: string): boolean => {
    const dateFormats = [
      'YYYY/MM/DD',
      'YYYY-MM-DD',
      'DD/MM/YYYY',
      'MM/DD/YYYY',
      'Do MMMM YYYY', // For example: 1st January 2024
      'D MMMM YYYY',  // For example: 1 January 2024
      'YYYY-MM-DDTHH:mm:ss', // ISO 8601 format with time
      'YYYY-MM-DDTHH:mm:ss.SSS', // ISO 8601 format with milliseconds
      'DD-MM-YYYY', // For example: 14-02-2014
      'D-M-YYYY', // For example: 4-2-2024
      'D/M/YYYY', // For example: 4/2/2024
    ];

    for (const format of dateFormats) {
      if (moment(dateInput, format, true).isValid()) {
        return true;
      }
    }

    return false; // No valid format matched
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = testbookings.filter(testbooking => {
      for (const key in testbooking) {
        if (Object.prototype.hasOwnProperty.call(testbooking, key)) {
          const val = testbooking[key as keyof testbookingtype]; // Use keyof to access typed properties

          if (typeof val === 'string' && isValidDate(val)) {
            const parsedDate = parseDate(val);
            if (parsedDate !== null && parsedDate.includes(value)) {
              return true;
            }
          } else if (typeof val === 'string') {
            if (val.toLowerCase().includes(value)) {
              return true;
            }
          } else if (typeof val === 'number') {
            if (val.toString().includes(value)) {
              return true;
            }
          }
        }
      }
      return false;
    });

    setFilteredTestBookings(filteredData);
    gridApi?.setRowData(filteredData);
  };

  const resetFilters = () => {
    setFilteredTestBookings(testbookings); // Reset filtered data to original dataset
    gridApi?.setFilterModel(null); // Clear all applied filters
  };

  const dateComparator = (filterLocalDateAtMidnight: Date, cellValue: any) => {
    if (!cellValue) return 0;

    const cellDate = moment(cellValue).startOf('day').toDate();

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }

    return 1;
  };

  const getColumnDefs = () => [
    {
      headerName: 'SL No',
      valueGetter: "node.rowIndex + 1",
      sortable: true,
      filter: false,
    },
    {
      headerName: 'First Name',
      field: 'first_name',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Last Name',
      field: 'last_name',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Service Category Type',
      field: 'service_category_type',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Service Name',
      field: 'service_name',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Booking Date',
      field: 'booking_date',
      sortable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: dateComparator,
      },
      valueFormatter: (params: { value: string }) => moment(params.value).format('Do MMMM YYYY'),
    },
    {
      headerName: 'Test Date',
      field: 'test_date',
      sortable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: dateComparator,
      },
      valueFormatter: (params: { value: string }) => moment(params.value).format('Do MMMM YYYY'),
    },
    {
      headerName: 'Test Status',
      field: 'test_status',
      sortable: true,
      filter: true,
      valueFormatter: (params: { value: number }) =>
        params.value === 1 ? 'Booked' :
        params.value === 2 ? 'Attended' :
        params.value === 3 ? 'Missed' :
        params.value === 4 ? 'Cancelled by user' :
        params.value === 5 ? 'Cancelled By Client' :
        params.value === 6 ? 'Rescheduled' : 'Not booked',
    },
    {
      headerName: 'Actions',
      filter:false,
      cellRenderer: (params: { data: { user_id: number; id: number } }) => (
        <Link
          className='text-dark text-center'
          onClick={() => localStorage.setItem('user_id', params.data.user_id.toString())}
          to={`/edit_test_booking_detail/${params.data.id}`}
        >
          <i className='fa fa-edit text-success'></i>
        </Link>
      ),
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='container-fluid'>
      <div className='d-flex'>
        <SideBar />
        <div className='headerbar'>
          <Header />
          <h4 className='text-danger text-center'>Test Booking Lists</h4>
          <hr className='bg-danger px-1' />
          <div className='row mt-5 px-2'>
            <div className='col-md-4'>
              {/* <input
                type='text'
                className='form-control'
                placeholder='Search...'
                value={searchText}
                onChange={handleSearchInputChange}
              /> */}
            </div>
            <div className='col-md-8 text-right'>
              <Link
                to={"/add_multiple_test_booking"}
                className='btn btn-info float-right mr-2 text-white mb-3'
                role="button"
              >
                +
              </Link>
              <button
                className='btn btn-secondary float-right mr-2 mb-3'
                onClick={resetFilters}
              >
                Reset Filters
              </button>
            </div>
            <div className='col-md-12 ag-theme-alpine' style={{ height: '500px' }}>
              <AgGridReact
                onGridReady={onGridReady}
                columnDefs={getColumnDefs()}
                rowData={filteredTestBookings}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={50}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestBookingList;
