import React,{useState} from 'react';
import userlogin from '../../assets/images/16.jpg';
import end_of_work from '../../assets/images/end_of_workday.gif';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import server from '../Utils';
import  Loader from './Loader';
import userlogo from '../../assets/images/user_logo.png';
import './userlogin.css';
import { FaEye } from 'react-icons/fa6';
import { FaEyeSlash } from 'react-icons/fa';
const validator = require('validator');


const UserLogin = () => {
  
  const [email,setEmail]=useState<string>("");
  const [password,setPassword]=useState<string>("");
  const [loggedIn,setLoggedIn]=useState<boolean>(false);  
  const [loading, setLoading] = useState(false);
  const [eye,setEye]=useState<boolean>(false);

  var flag=localStorage.getItem('loggedIn')
    if(flag==="true"){
    window.location.href="/userpath";
    }
  


  const userLogin=(e:React.FormEvent<HTMLFormElement>)=>{
    e.preventDefault();
      
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    
    if (validator.isEmail(email)) {
       
        toast('please Enter valid Email');
      }else
      {
        var form=document.getElementById('user_login_form') as HTMLFormElement;
        var formData = new FormData(form);
        server.post(`/clientLogin`,formData)
        .then((response)=>{
         if(response.data.code===200){
        localStorage.setItem('ClientID',response.data.user_details);
        localStorage.setItem('loggedIn',"true");
       var loggedIn= localStorage.getItem('loggedIn');
         if(loggedIn==="true"){
          setLoggedIn(true)          
          window.location.href="/userpath";
          }
         }else
         {
          setLoggedIn(false)
          toast(response.data.message);
         }
        })
      }
  }
  if (loading) {
    return <Loader />;
  } 
  
  return (
    
    <section  className='user_login_background' >
      {/* <img src={userlogin} width={"100%"} style={{height:"100vh"}} alt=" user login background image"/>  */}
    
      <div className='container-fluid' >
      <img src={userlogo}  id="user_logo" alt="user_logo"/>  
      
      </div>
      <div className='user-container text-center m-auto bg-white' >
      <form className='pt-3 px-4' id="user_login_form" onSubmit={userLogin} >
            <h1 className='text-dark display-3 mt-5 mb-3 text-center'><b>Hello</b> <b style={{color:"#F47523"}}>Doctor!</b></h1>
            <h4 className='text-center mb-4'>Welcome to <span style={{color:"#F47523"}} >Goo</span>Campus</h4>
              <div className='form-group text-left my-5'>
                <p  className='text-left login_label' >Email or Username</p>
                <input className='form-control user_input' type="email" name="email" placeholder='Email Address' required/>
              </div>
              <div className='form-group text-left my-5'>
                <p className='text-left login_label'>Password</p>
                
                <input className='form-control user_input' type={eye===false?"password":"text"} name="password" placeholder='Enter Password' required/>
                <span className='float-right mr-5 eye-icon' >{eye===false?<FaEye onClick={()=>setEye(!eye)} style={{width:"30px"}} />:<FaEyeSlash onClick={()=>setEye(!eye)} style={{width:"30px"}} />
}</span>
              </div>
              <ToastContainer />
              
              <button className='btn-lg btn-dark text-white rounded user_login_button' style={{backgroundColor:"#233974",border:"none"}} type="submit"><span>Login</span></button>
              
              <br/>
              <a className='text-center my-3' style={{color:"#233974",fontSize:"18px"}} href="#">Forgot Password</a>
              <br/>
              
            </form>
      </div>
      
      {/* <div className=' p-5 m-5 w-100' style={{position:"absolute",top:"-5%",background:"transparent"}}>
     
        <div className='row  m-5' >
       
          <div className='col-md-6 pt-5'>
            <img className='img-responsive mt-5 pl-2' src={end_of_work} width="100%" height="85%" alt="login image"/>
          </div>
          <div className='col-md-6 pt-5'>
          
            <form className='pt-3' id="user_login_form" onSubmit={userLogin} >
            <h1 className='text-dark mt-5 mb-3'>Login</h1>
              <div className='form-group'>
                <label htmlFor='exampleInputEmail1'>Email</label>
                <input className='form-control' type="email" name="email" placeholder='Email Address' required/>
              </div>
              <div className='form-group'>
                <label htmlFor='exampleInputEmail1'>Password</label>
                <input className='form-control' type="password" name="password" placeholder='Enter Password' required/>
              </div>
              <ToastContainer />
              
              <button className='text-success btn w-100 text-dark' type="submit" style={{background:"#f9a825"}}><b>Login</b></button>
              <br/>
              <br/>
              
            </form>
            
          </div>
        </div>
      </div> */}
      
    </section>
  )
}

export default UserLogin