import { useEffect,useState } from "react";
import React from 'react';
import validator from 'validator';
import server from '../Utils';
import { CollegeData } from "../../@types/collegedata";
import moment from "moment";
import { SpecialityData } from "../../@types/specialitytype";
import { SubSpecialityData } from "../../@types/subspeciality";
import { Link, useParams } from "react-router-dom";
import './ClientDetails.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import pencil from '../../assets/images/pencil.png';
import Loader from "./Loader";


interface userdetails{
  passport_first_name:string,
  passport_last_name:string,
  certificate_first_name:string,
  certificate_last_name:string,
  primary_country_code:number,
  primary_contact:number,
  alernate_country_code:number,
  alternate_phone_number:number,
  user_email:string,
  password:string,
  last_password:string,
  dob:string,
  fathers_full_name:string,
  fathers_email:string,
  fathers_contact_country_code:string,
  fathers_contact:number|undefined,
  mothers_full_name:string,
  mothers_email:string,
  mothers_country_code:string,
  mothers_contact:number|undefined,
  passport_exist:number,
  passport_number:string,
  passport_issue_date:string,
  passport_expiry_date:string,
  collegename:string,
  address_same_flag:number,
  current_door_no:string,
  current_street:string,
  current_locality:string,
  current_city:string,
  current_state:string,
  current_country:string,
  current_postal_code:string,
  permanent_door_no:string,
  permanent_street:string,
  permanent_locality:string,
  permanent_city:string,
  permanent_state:string,
  permanent_country:string,
  permanent_postal_code:string,
  pmq_college:string
  pmq_status:number,
  pmq_start_date:string,
  pmq_end_date:string,
  pmq_finish_date:string,
  language_test_taken:number,
  language_test_type:number,
  language_test_date:string,
  ielts_trf_number:string,
  ielts_listening_score:string,
  ielts_reading_score:string,
  ielts_writing_score:string,
  ielts_speaking_score:string,
  ielts_overall_score:string,
  oet_candidate_number:string,
  oet_listening_score:string,
  oet_reading_score:string,
  oet_writing_score:string,
  oet_speaking_score:string,
  oet_overall_score:string,
  gmc_reference_number_exist:number,
  gmc_reference_number:string,
  internship_start_date:string,
  internship_end_date:string,
  emp_completion_status:number,
  emp_hospital_name:string,
  emp_city:string,
  emp_state:string,
  personal_note:string,
  welcome_call_date:string,
  welcome_call_time:number,

  
}

const ClientDetails:React.FC<userdetails> = ({
  passport_first_name,
  passport_last_name,
  certificate_first_name,
  certificate_last_name,
  primary_country_code,
  primary_contact,
  alernate_country_code,
  alternate_phone_number,
  user_email,
  password,
  last_password,
  pmq_college,
  dob,
  fathers_full_name,
  fathers_email,
  fathers_contact_country_code,
  fathers_contact,
  mothers_full_name,
  mothers_email,
  mothers_country_code,
  mothers_contact,
  passport_exist,
  passport_number,
  passport_issue_date,
  passport_expiry_date,
  collegename,
  address_same_flag,
  current_door_no,
  current_street,
  current_locality,
  current_city,
  current_state,
  current_country,
  current_postal_code,
  permanent_door_no,
  permanent_street,
  permanent_locality,
  permanent_city,
  permanent_state,
  permanent_country,
  permanent_postal_code,
  pmq_status,
  pmq_start_date,
  pmq_end_date,
  pmq_finish_date,
  language_test_taken,
  language_test_type,
  language_test_date,
  ielts_trf_number,
  ielts_listening_score,
  ielts_reading_score,
  ielts_writing_score,
  ielts_speaking_score,
  ielts_overall_score,
  oet_candidate_number,
  oet_listening_score,
  oet_reading_score,
  oet_writing_score,
  oet_speaking_score,
  oet_overall_score,
  gmc_reference_number_exist,
  gmc_reference_number,
  internship_start_date,
  internship_end_date,
  emp_completion_status,
  emp_hospital_name,
  emp_city,
  emp_state,
  personal_note,
  welcome_call_date,
  welcome_call_time,
  
  
}) => {
  
  // const [passport_first_name,setPassportFirstName]=useState<string>("")
  // const [passport_last_name,setPassportlastName]=useState<string>("")
  // const [certificate_first_name,setCertificateFirstName]=useState<string>('')
  // const [certificate_last_name,setCertificateLastName]=useState<string>('')
  // const [primary_contact_country_code,setPrimaryCountryCode]=useState<string>('')
  // const [primary_contact,setPrimaryContact]=useState<string>('')
  // const [alernate_country_code,setAlternateCountryCode]=useState<string>('')
  // const [alternate_phone_number,setAlternatePhoneNumber]=useState<string>('')
  // const [user_email,setUserEmail]=useState<string>('')
  // const [password,setPassword]=useState<string>('');
  // const [last_password,setLastPassword]=useState<string>('');
  // const [dob,setDob]=useState<string>('');
  // const [fathers_full_name,setFathersFullName]=useState<string>('')
  // const [fathers_email,setFathersEmail]=useState<string>('')
  // const [fathers_contact_country_code,setFathersContactCountryCode]=useState('')
  // const [fathers_contact,setFathersContact]=useState<number|undefined>()
  // const [mothers_full_name,setMothersFullName]=useState<string>()
  // const [mothers_email,setMothersEmail]=useState<string>('');
  // const [mothers_country_code,setMothersCountryCode]=useState<string>();
  // const [mothers_contact,setMothersContact]=useState<number|undefined>();
  // const [passport_exist,setPassportExist]=useState<number>(0);
  // const [passport_number,setPassportNumber]=useState<string>('');
  // const [passport_issue_date,setPassportIssueDate]=useState<string>('');
  // const [passport_expiry_date,setPassportExpiryDate]=useState<string>('');  

  // const [collegename,setCollegename]=useState('')
  // address  details
  // const [current_door_no,setCurrentDoorNo]=useState<string>('');
    // const [current_street,setCurrentStreet]=useState<string>('');
    // const [current_locality,setCurrentLocality]=useState<string>('');
    // const [current_city,setCurrentCity]=useState<string>('');
    // const [current_state,setCurrentState]=useState<string>('');
    // const [current_country,setCurrentCountry]=useState<string>('');
    // const [current_postal_code,setCurrentPostalCode]=useState<string>('');

    // const [permanent_door_no,setPermanentDoorNo]=useState<string>('');
    // const [permanent_street,setPermanentStreet]=useState<string>('');
    // const [permanent_locality,setPermanentLocality]=useState<string>('');
    // const [permanent_city,setPermanentCity]=useState<string>('');
    // const [permanent_state,setPermanentState]=useState<string>('');
    // const [permanent_country,setPermanentCountry]=useState<string>('');
    // const [permanent_postal_code,setPermanentPostalCode]=useState<string>('');
    

    // // college data
    // const [colleges,setColleges]=useState<Array<CollegeData>>([])
    // const [collegeid,setCollegeId]=useState<number>(0);
    // const [pmq_status,setPmQStatus]=useState<number>(0);
    // const [pmq_start_date,setPmqStartDate]=useState<string>('');
    // const [pmq_end_date,setPmqEndDate]=useState<string>('');
    // const [pmq_finish_date,setPmqFinishDate]=useState<string>('');
   
    //language test details
  // const [language_test_taken,setLanguageTestTaken]=useState<number>(0);
  // const [language_test_type,setLanguageTestType]=useState<number>(0);
  // const [language_test_date,setLanguageTestDate]=useState<string>("");
  // const [ielts_trf_number,setIELTStrfNo]=useState<string>("");
  // const [ielts_listening_score,setIELTSListeningScore]=useState<string>('');
  // const [ielts_reading_score,setIELTSReadingScore]=useState<string>('');
  // const [ielts_writing_score,setIELTSWritingScore]=useState<string>('');
  // const [ielts_speaking_score,setIELTSSpeakingScore]=useState<string>('');
  // const [ielts_overall_score,setIELTSOverallScore]=useState<string>('');
  // const [oet_candidate_number,setOetCandidateNumber]=useState<string>('');
  // const [oet_listening_score,setOetListeningScore]=useState<string>('');
  // const [oet_reading_score,setOetReadingScore]=useState<string>('');
  // const [oet_writing_score,setOetWritingScore]=useState<string>('');
  // const [oet_speaking_score,setOetSpeakingScore]=useState<string>('');
  // const [oet_overall_score,setOetOverallScore]=useState<string>('');

  // const [gmc_reference_number_exist,setGmcReferenceNumberExist]=useState<number>(0)
  // const [gmc_reference_number,setGmcReferenceNumber]=useState<string>('')
  // const [internship_start_date,setInternshipStartDate]=useState<string>("")
  // const [internship_end_date,setInternshipEndDate]=useState<string>("0")


  // const [emp_completion_status,setEmployCompletion]=useState<number>(0);
  // const [emp_hospital_name,setEmployHospital]=useState<string>("");
  // const [emp_city,setEmpCity] =useState<string>("");
  // const [emp_state,setState]=useState<string>('');
  // const [specialities,setSpecialities]=useState<Array<SpecialityData>>([]);
  // const [speciality_id,setSpecialityId]=useState<number>(0);
  // const [sub_specialities,setSubSpeciality]=useState<Array<SubSpecialityData>>([]);
  // const [subspecialityid,setSubSpecialityId]=useState<number>(0);

  // const [personal_note,setPersnolNote]=useState<string>("");
  // const [welcome_call_date,setWelcomeCallDate]=useState<string>('');
  // const [welcome_call_time,setWelcomeCallTime]=useState<number>(0);

  //accordian states
  const [isPersonalDetailsOpen, setIsPersonalDetailsOpen] = useState(false);
  const [isAddressDetailsOpen, setIsAddressDetailsOpen] = useState(false);
  const [isPMQDetailsOpen, setIsPMQDetailsOpen] = useState(false);
  const [isLanguageTestDetailsOpen, setIsLanguageTestDetailsOpen] = useState(false);
  const [isGMCInternshipOpen, setIsGMCInternshipOpen] = useState(false);
  const [isEmploymentAndSpecialityOpen, setIsEmploymentAndSpecialityOpen] = useState(false);
  const [isPersonalNoteOthersOpen, setIsPersonalNoteOthersOpen] = useState(false);

  const [passport_photo,setPassportPhoto]=useState<string>('');
  const [client_photo,setClientPhoto]=useState<string>('');
  const [provisional_degree_certificate,setProvisionalDegreeCertificate]=useState<string>('');
  const [final_degree_certificate,setFinalDegreeCertificate]=useState<string>('');
  const [provisional_registration_certificate,setProvisionalRegistrationCertificate]=useState<string>('');
  const [final_registration_certificate,setFinalRegistrationCertificate]=useState<string>('');
  const [internship_completion_certificate,setInternshipCompletionCertificate]=useState<string>('');
  const {id}=useParams()

  const [certificate_names,setcertificateNames]=useState<string>('');
  const [certificate_desc,setCertificateDesc]=useState<string>('');
  const [certificate_files,setCertificateFiles]=useState<string>('');
  const [file_count,setFileCount]=useState<number>(0);

  // const[contract_file,setContractFile]=useState<string>('')

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // let userid= localStorage.getItem('ClientID');

    // server.get(`/operationuser/${id}`)
    // .then((response)=>{

      // userProfileStarts
   
    // setPassportFirstName(response.data.user.passport_first_name)
    // setPassportlastName(response.data.user.passport_last_name)
    // setCertificateFirstName(response.data.user.certificate_first_name)
    // setCertificateLastName(response.data.user.certificate_last_name)
    // setPrimaryCountryCode(response.data.user.primary_contact_country_code)
    // setPrimaryContact(response.data.user.primary_contact)
    
    // setAlternateCountryCode(response.data.user.alernate_country_code)
    // setAlternatePhoneNumber(response.data.user.alternate_phone_number)
    // setUserEmail(response.data.user.user_email)
    // setPassword(response.data.user.last_password)
    // setLastPassword(response.data.user.last_password)
    // setDob(response.data.user.dob)
    // setFathersFullName(response.data.user.fathers_full_name)
    // setFathersEmail(response.data.user.fathers_email)
    // setFathersContactCountryCode(response.data.user.fathers_contact_country_code)
    // setFathersContact(response.data.user.fathers_contact)
    // setMothersFullName(response.data.user.mothers_full_name)
    // setMothersEmail(response.data.user.mothers_email)
    // setMothersCountryCode(response.data.user.mothers_country_code)
    
    // setMothersContact(response.data.user.mothers_contact)
   
    // setPassportNumber(response.data.user.passport_number)
    // setPassportIssueDate(response.data.user.passport_issue_date)
    // setPassportExpiryDate(response.data.user.passport_expiry_date)
//userProfile Ends

//user Address Starts
// setCurrentDoorNo(response.data.user.current_door_no)
//     setCurrentStreet(response.data.user.current_street)
//     setCurrentLocality(response.data.user.current_locality)
//     setCurrentCity(response.data.user.current_city_name)
//     setCurrentState(response.data.user.current_state_name)
//     setCurrentCountry(response.data.user.current_country_name)
//     setCurrentPostalCode(response.data.user.current_postal_code)

//     setPermanentDoorNo(response.data.user.permanent_door_no)
//     setPermanentStreet(response.data.user.permanent_street)
//     setPermanentLocality(response.data.user.permanent_locality)
//     setPermanentCity(response.data.user.permanent_city_name)
//     setPermanentState(response.data.user.permanent_state_name)
//     setPermanentCountry(response.data.user.permanent_country_name)
//     setPermanentPostalCode(response.data.user.permanent_postal_code)

    //address details ends

    //pmq details starts
    // setCollegeId(response.data.user.pmq_college);
    // setPmQStatus(response.data.user.pmq_status);
    // setPmqStartDate(response.data.user.pmq_start_date);
    // setPmqEndDate(response.data.user.pmq_end_date);
    // setPmqFinishDate(response.data.user.pmq_finish_date);
    // setCollegename(response.data.user.college_name);
    //pmq details Ends
//language test details
    // setLanguageTestTaken(parseInt(response.data.user.language_test_taken))
    //   setLanguageTestType(parseInt(response.data.user.language_test_type))
    //   setLanguageTestDate(response.data.user.date_of_test)
    //   setIELTStrfNo(response.data.user.ielts_trf_number)
      // if(response.data.user.ielts_trf_number===null||response.data.user.ielts_trf_number===""){
      //   setIELTStrfNo("")
      // }
      // setIELTSListeningScore(response.data.user.ielts_listening_score)
      // setIELTSReadingScore(response.data.user.ielts_reading_score)
      // setIELTSWritingScore(response.data.user.ielts_writing_score)
      // setIELTSSpeakingScore(response.data.user.ielts_speaking_score)
      // setIELTSOverallScore(response.data.user.ielts_overall_score)
      
      // if(response.data.user.oet_candidate_number===null||response.data.user.oet_candidate_number===""){
      //   setOetCandidateNumber('')
      // }else{
      //   setOetCandidateNumber(response.data.user.oet_candidate_number)
      // }
      // setOetListeningScore(response.data.user.oet_listening_score)
      // setOetReadingScore(response.data.user.oet_reading_score)
      // setOetWritingScore(response.data.user.oet_writing_score)
      // setOetSpeakingScore(response.data.user.oet_speaking_score)
      // setOetOverallScore(response.data.user.oet_overall_score)
      // //language test details


      // if(response.data.user.pmq_status===null){
      //   setPmQStatus(0);
      // }else{
      //   setPmQStatus(response.data.user.pmq_status);
      // }
      // if(response.data.user.gmc_refrence_exists===null){
      //   setGmcReferenceNumberExist(0);
      // }else{
      //   setGmcReferenceNumberExist(response.data.user.gmc_refrence_exists);
      // }if(response.data.user.gmc_reference_number===null){
      //   setGmcReferenceNumber('')
      //  }else{
      //   setGmcReferenceNumber(response.data.user.gmc_reference_number)
      //  }
      //  if(response.data.user.internship_end_date===null){
      //   setInternshipEndDate('')
      //  }else{
      //   setInternshipEndDate(response.data.user.internship_end_date)
      //  }
      //  if(response.data.user.internship_end_date===null){
      //   setInternshipStartDate('')
      //  }else{
      //   setInternshipStartDate(response.data.user.internship_start_date)
      //  }

      //  setPmQStatus(response.data.user.pmq_status);
      //  setEmployCompletion(response.data.user.emp_completion_status);
      //  setEmployHospital(response.data.user.emp_hospital_name);
      //  setEmpCity(response.data.user.emp_city);
      //  setState(response.data.user.emp_state);
      //  setSpecialityId(response.data.user.interested_speciality);
       
      //  setSubSpecialityId(response.data.user.sub_speciality);
      //  setPersnolNote(response.data.user.personal_note);
      //  setWelcomeCallDate(response.data.user.welcome_call_date);
      //  setWelcomeCallTime(response.data.user.welcome_call_time);
      //  setContractFile(response.data.user.contract_file)
      //  setPmQStatus(response.data.user.pmq_status);
      //    setPersnolNote(response.data.user.personal_note);
    // })

    // server.get(`/operation_user_view/${id}`)
    // .then((response)=>{
    //     //  setPmQStatus(response.data.user.pmq_status);
    //     //  setPersnolNote(response.data.user.personal_note);
         

    // })

  server.get(`/get_documents/${id}`)
  .then((response)=>{
    
    if(response.data.code===200){
    
    //  setPmQStatus(response.data.documents.pmq_status);
    setClientPhoto(response.data.documents.client_photo);
    setPassportPhoto(response.data.documents.passport_photo);
    setProvisionalDegreeCertificate(response.data.documents.provisional_degree_certificate);
    setFinalDegreeCertificate(response.data.documents.final_degree_certificate);
    setProvisionalRegistrationCertificate(response.data.documents.provisional_registration_certificate);
    setFinalRegistrationCertificate(response.data.documents.final_registration_certificate);
    setInternshipCompletionCertificate(response.data.documents.internship_completion_certificate);
    setcertificateNames(response.data.documents.certificate_names);
    setCertificateDesc(response.data.documents.certificate_description);
    setCertificateFiles(response.data.documents.certificate_file);
    setFileCount(response.data.documents.file_count);
   
    
 }
})

  },[]);
  const isPDF = (url:string) => {
    return url && url.toLowerCase().endsWith('.pdf');
};

//  if (loading) {
//   return <Loader />;
// }
  return (
    <div className="jumbotron p-5">
      <div id="accordion">
        {/* Personal And Passport Details */}
        <div className="card">
          <div className="card-header" id="headingOne">
            <h5 className="mb-0">
              <button 
                className="btn nav-link text-white text-left w-100" 
                data-toggle="collapse" 
                data-target="#personal_details" 
                aria-expanded={isPersonalDetailsOpen} 
                aria-controls="collapseOne"
                onClick={() => setIsPersonalDetailsOpen(!isPersonalDetailsOpen)}
              >
                Personal And Passport Details
                {/* {isPersonalDetailsOpen ? <FaChevronUp /> : <FaChevronDown />} */}
              </button>
            </h5>
          </div>
          <div id="personal_details" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
              <h5 className="text-uppercase blue-color fw-bold">DR {passport_first_name} {passport_last_name} Details</h5>
              </div>
              <div className="col-md-6">
                <Link to={`/user_profile_edit/${id}/`}><img width="20px" height="20px"  src={pencil} alt="pencil"/></Link>
              </div>
            </div>
             
            <div className="row mt-4">
        <div className="col-md-6">
          <label className="blue-color">First Name<span className="user_detail_label_font"> (According To The Passport)</span></label>
        </div>
        <div className="col-md-6">
          <p>{passport_first_name}</p>
        </div>
        <div className="col-md-6">
          <label className="blue-color">Last Name<span className="user_detail_label_font"> (According To The Passport)</span></label>
        </div>
        <div className="col-md-6">
          <p>{passport_last_name}</p>
        </div>
        <div className="col-md-6">
          <label className="blue-color">First Name<span className="user_detail_label_font"> (According To The Certificate)</span></label>
        </div>
        <div className="col-md-6">
          <p>{certificate_first_name}</p>
        </div>
        <div className="col-md-6">
          <label className="blue-color">Last Name<span className="user_detail_label_font"> (According To The Certificate)</span></label>
        </div>
        <div className="col-md-6">
          <p>{certificate_last_name}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <label className="blue-color">Email</label>
        </div>
        <div className="col-md-6">
          <p>{user_email}</p>
        </div>
        <div className="col-md-6">
          <label className="blue-color">Date Of Birth</label>
        </div>
        <div className="col-md-6">
          <p>{moment(dob).format('Do MMMM YYYY')}</p>
        </div>
        <div className="col-md-6">
          <label className="blue-color">Primary Contact</label>
        </div>
        <div className="col-md-6">
          <p>{primary_country_code}-{primary_contact}</p>
        </div>
        <div className="col-md-6">
          <label className="blue-color">Alternate Contact</label>
        </div>
        <div className="col-md-6">
          <p>{alernate_country_code}-{alternate_phone_number}</p>
        </div>
      </div>

      <hr style={{ backgroundColor: "lightgray" }} />
      <h3 className="my-4 text-left blue-color fw-bold">Parents Details</h3>
      <div className="row">
        <div className="col-md-3">
          <label className="blue-color">Father's Name</label>
          <p>{fathers_full_name}</p>
        </div>
        <div className="col-md-3">
          <label className="blue-color">Father's Email</label>
          <p>{fathers_email}</p>
        </div>
        <div className="col-md-4">
          <label className="blue-color">Father's Contact</label>
          <p>{fathers_contact_country_code}-{fathers_contact}</p>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-3">
          <label className="blue-color">Mother's Name</label>
          <p>{mothers_full_name}</p>
        </div>
        <div className="col-md-3">
          <label className="blue-color">Mother's Email</label>
          <p>{mothers_email}</p>
        </div>
        <div className="col-md-4">
          <label className="blue-color">Mother's Contact</label>
          <p>{mothers_country_code}-{mothers_contact}</p>
        </div>
      </div>
    <hr/>
            {passport_exist===1?<h3 className='blue-color fw-bold my-4 text-left'>Passport Details</h3>:""}
             <div className='row mb-3'>

              {passport_exist===1?<div className='col-md-3'>
              <label className="blue-color">Passport Number</label>
                <p>{passport_number}</p>
              </div>:""}
              {passport_exist===1? <div className='col-md-3'>
              <label className="blue-color">Passport Issue Date</label>
              <p>{moment(passport_issue_date).format('Do MMMM YYYY') }</p>
              </div>:""}
              {passport_exist===1?<div className='col-md-3'>
              <label className="blue-color">Passport Expiry Date</label>
              <p>{moment(passport_expiry_date).format('Do MMMM YYYY') }</p>
              </div>:"" }
              </div>
      </div>
    </div>
          </div>
        </div>

        {/* Address Details */}
        <div className="card">
          <div className="card-header" id="headingTwo">
            <h5 className="mb-0">
              <button 
                className="btn nav-link text-white text-left w-100" 
                data-toggle="collapse" 
                data-target="#user_address_details" 
                aria-expanded={isAddressDetailsOpen} 
                aria-controls="collapseTwo"
                onClick={() => setIsAddressDetailsOpen(!isAddressDetailsOpen)}
              >
                Address Details
                {/* {isAddressDetailsOpen ? <FaChevronUp /> : <FaChevronDown />} */}
              </button>
            </h5>
          </div>
          <div id="user_address_details" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
          <div className="card-body">
          <div className="row">
              <div className="col-md-6">
              {/* <h5 className="text-uppercase text-danger">Address Details</h5> */}
              </div>
              <div className="col-md-6">
                <Link to={`/user_address_edit/${id}/`}><img width="20px" height="20px"  src={pencil} alt="pencil"/></Link>
              </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-6">
                  <label className="blue-color">Current Address</label>
                  <p>{current_door_no}, {current_street}, {current_locality}, {current_city}, {current_state}, {current_country}-{current_postal_code}</p>
                </div>
                {address_same_flag!==null?<div className="col-md-6">
                <label className="blue-color">Permanant Address</label>
                  <p>{permanent_door_no}, {permanent_street}, {permanent_locality}, {permanent_city}, {permanent_state}, {permanent_country}-{permanent_postal_code}</p>
                </div>:""}
              </div>
      </div>
          </div>
        </div>

        {/* Primary Medical Qualification Details */}
        <div className="card">
          <div className="card-header" id="headingThree">
            <h5 className="mb-0">
              <button 
                className="btn nav-link text-white text-left w-100" 
                data-toggle="collapse" 
                data-target="#pmq_details" 
                aria-expanded={isPMQDetailsOpen} 
                aria-controls="collapseThree"
                onClick={() => setIsPMQDetailsOpen(!isPMQDetailsOpen)}
              >
                Primary Medical Qualification Details
                {/* {isPMQDetailsOpen ? <FaChevronUp /> : <FaChevronDown />} */}
              </button>
            </h5>
          </div>
          <div id="pmq_details" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
          <div className="card-body">
          <div className="row">
              <div className="col-md-6">
              {/* <h3 className='text-danger my-4 text-left'>Primary Medical Qualification Details</h3> */}
              </div>
              <div className="col-md-6">
                <Link to={`/user_pmq_details_edit/${id}/`}><img width="20px" height="20px"  src={pencil} alt="pencil"/></Link>
              </div>
            </div>
            
            <div className="row mt-4">
                <div className="col-md-4"><label className="blue-color">College Name</label></div>
                <div className="col-md-8"><p>{collegename}</p></div>
                <div className="col-md-4 mt-3"><label className="blue-color">Primary Medical Qualification Status</label></div>
                <div className="col-md-8 mt-3"><p>{pmq_status===1?"Ongoing":pmq_status===2?"Internship":pmq_status===3?"Completed":""}</p></div>
              </div>

              <div className='row mt-3'>
         { pmq_status!==0? <div className='col-md-4'>
          <label className="blue-color">PMQ Start Date</label>
         <p>{moment(pmq_start_date).format('Do MMMM YYYY') }</p>
          </div>:""}
          { pmq_status!==0 && pmq_status!==1 ? <div className='col-md-4'>
          <label className="blue-color">PMQ End Date</label>
          <p>{moment(pmq_end_date).format('Do MMMM YYYY') }</p>
          </div>:""}
          { pmq_status===3? <div className='col-md-4'>
          <label className="blue-color">PMQ Finish Date</label>
          <p>{moment(pmq_finish_date).format('Do MMMM YYYY') }</p>
          </div>:""}
          <hr className="my-3" style={{backgroundColor:" 1px solid lightgray"}}/>
         </div>
         {pmq_status===2||pmq_status===3 ?<div className='row'>
            <h3 className=' text-left my-3 fw-bold blue-color'>Intenship Details</h3>
                <div className='col-md-4'>
                    <label className="blue-color">Internship Start Date</label>
                  <p>{moment(internship_start_date).format('Do MMMM YYYY')}</p>

                </div>
                <div className='col-md-8'>
                    <label className="blue-color my-2">Internship End Date</label>
                    <p>{moment(internship_end_date).format('Do MMMM YYYY')}</p>
                </div>
            </div>:""}
      </div>
          </div>
        </div>

        {/* Language Test Details */}
        <div className="card">
          <div className="card-header" id="headingFour">
            <h5 className="mb-0">
              <button 
                className="btn nav-link text-white text-left w-100" 
                data-toggle="collapse" 
                data-target="#language_test_details" 
                aria-expanded={isLanguageTestDetailsOpen} 
                aria-controls="collapseFour"
                onClick={() => setIsLanguageTestDetailsOpen(!isLanguageTestDetailsOpen)}
              >
                Language Test Details
                {/* {isLanguageTestDetailsOpen ? <FaChevronUp /> : <FaChevronDown />} */}
              </button>
            </h5>
          </div>
          <div id="language_test_details" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
          <div className="card-body">
      
        <div className="row">
              <div className="col-md-6">
             {/* <h3 className="text-danger my-3">Language Test Details</h3> */}
              </div>
              <div className="col-md-6">
                <Link to={`/user_language_test_edit/${id}/`}><img width="20px" height="20px"  src={pencil} alt="pencil"/></Link>
              </div>
            </div>
            <div className='row mt-4'>
                <div className='col-md-4'>
                   <label className="blue-color">Passed IELTS/OET Exam?</label>
                   <p>{language_test_taken===1?"Yes":language_test_taken===2?"No":""}</p>
                </div>
                {language_test_taken===1?<div className='col-md-4'>
                <label className="blue-color">Language test type</label>
                  <p>{language_test_type===1?"IELTS":language_test_type===2?"OET":""}</p>
                </div>:""}
                {language_test_taken===1?<div className='col-md-4'>
                <label className="blue-color">Test Date</label> <br/>
                 {moment(language_test_date).format('Do MMMM YYYY')}
                </div>:""}
                {language_test_taken===1 && language_test_type===1?<div className='row  mt-2'>
                <div className='col-md-2'>
                   <label className="blue-color">TRF Number</label>
                   <p>{ielts_trf_number}</p>
                </div>
                <div className='col-md-2'>
                <label className="blue-color"> Listening score</label>
                <p>{ielts_listening_score}</p>
                </div>
              <div className='col-md-2'>
                <label className="blue-color"> Reading Score</label>
               <p>{ielts_reading_score}</p>
                </div>
                <div className='col-md-2'>
                <label className="blue-color"> Writing Score</label>
                <p>{ielts_writing_score}</p>
                </div>
                <div className='col-md-2'>
                <label className="blue-color"> Speaking Score</label>
                  <p>{ielts_speaking_score}</p>
                </div>
                <div className='col-md-2'>
                <label className="blue-color"> Overall Number</label>
                  <p>{ielts_overall_score}</p>
                </div>


            </div>:language_test_taken===1 && language_test_type===2?<div className='row mt-5'>
                <div className='col-md-2'>
                   <label className="blue-color">Candidate Number</label>
                   <p>{oet_candidate_number}</p>
                </div>
                <div className='col-md-2'>
                <label className="blue-color">Listening score</label>
                  <p>{oet_listening_score}</p>
                </div>
              <div className='col-md-2'>
                <label className="blue-color">Reading Score</label>
                <p>{oet_reading_score}</p>
                </div>
                <div className='col-md-2'>
                <label className="blue-color">Writing Score</label>
                <p>{oet_writing_score}</p>
                </div>
                <div className='col-md-2'>
                <label className="blue-color">Speaking Score</label>
                <p>{oet_speaking_score}</p>
                </div>
                <div className='col-md-2'>
                <label className="blue-color">Overall Score</label>
                
                <p>{oet_overall_score}</p>
                </div>



            </div>:""}

            </div>
      </div>
          </div>
        </div>

        {/* GMC Account & Internship Details */}
        <div className="card">
          <div className="card-header" id="headingFive">
            <h5 className="mb-0">
              <button 
                className="btn nav-link text-white text-left w-100" 
                data-toggle="collapse" 
                data-target="#gmcinternship" 
                aria-expanded={isGMCInternshipOpen} 
                aria-controls="collapseFive"
                onClick={() => setIsGMCInternshipOpen(!isGMCInternshipOpen)}
              >
                GMC Account & Internship Details
                {/* {isGMCInternshipOpen ? <FaChevronUp /> : <FaChevronDown />} */}
              </button>
            </h5>
          </div>
          <div id="gmcinternship" 
          // className={`collapse ${isGMCInternshipOpen ? 'show' : ''}` }
          className="collapse"
           aria-labelledby="headingFive" data-parent="#accordion">
          <div className="card-body">

 
          <div className="row">
              <div className="col-md-6">
             {/* <h3 className="text-danger my-3">GMC And Internship Details</h3> */}
              </div>
              <div className="col-md-6">
                <Link to={`/user_gmc_and_internship_edit/${id}/`}><img width="20px" height="20px"  src={pencil} alt="pencil"/></Link>
              </div>
            </div>

            {gmc_reference_number_exist===1?<div className='col-md-12'>
                    <label className="blue-color my-3">GMC Reference Number</label>
                    <p>{gmc_reference_number}</p>
                </div>:<div className='col-md-12'><div className="col-md-4"><label className="blue-color m-0">GMC Reference Number</label></div>
                <div className="col-md-12 pt-3"><p>&nbsp;Not Available</p></div>
                </div>
                
                }

            

      </div>
          </div>
        </div>

        {/* Employment And Speciality */}
        <div className="card">
          <div className="card-header" id="headingSix">
            <h5 className="mb-0">
              <button 
                className="btn nav-link text-white text-left w-100" 
                data-toggle="collapse" 
                data-target="#employment_and_speciality" 
                aria-expanded={isEmploymentAndSpecialityOpen} 
                aria-controls="collapseSix"
                onClick={() => setIsEmploymentAndSpecialityOpen(!isEmploymentAndSpecialityOpen)}
              >
                Employment And Speciality
                {/* {isEmploymentAndSpecialityOpen ? <FaChevronUp /> : <FaChevronDown />} */}
              </button>
            </h5>
          </div>
          <div id="employment_and_speciality"  className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
          <div className="card-body">
          <div className="row">
              <div className="col-md-6">
              <h4 className='blue-color text-left my-4'>Employment Details</h4>
              </div>
              <div className="col-md-6">
                <Link to={`/user_employment_and_speciality_edit/${id}/`}><img width="20px" height="20px"  src={pencil} alt="pencil"/></Link>
              </div>
            </div>
      
            {pmq_status===3?<div className='row'>

                <div className='col-md-3'>
                    <label className="blue-color">Employed</label>
                    <p>{emp_completion_status===1?"Yes":emp_completion_status===2?"No":""}</p>
                </div>
                <div className='col-md-3'>
                   {emp_completion_status===1? <label className="blue-color">Name Of Hospital</label>:""}
                    {emp_completion_status===1?<p>{emp_hospital_name}</p>:""}
                </div>
                <div className='col-md-3'>
                {emp_completion_status===1?<label className="blue-color">City</label>:""}
                {emp_completion_status===1?<p>{emp_city}</p>:""}
                </div>
                <div className='col-md-3'>
                   {emp_completion_status===1? <label className="blue-color">State</label>:""}
                   {emp_completion_status===1? <p>{emp_state}</p>:""}
                </div>

            </div>:"Employment details not filled yet"}
      </div>
          </div>
        </div>

        {/* Personal Notes */}
        <div className="card">
          <div className="card-header" id="headingSeven">
            <h5 className="mb-0">
              <button 
                className="btn nav-link text-white text-left w-100" 
                data-toggle="collapse" 
                data-target="#personal_note_others" 
                aria-expanded={isPersonalNoteOthersOpen} 
                aria-controls="collapseSeven"
                onClick={() => setIsPersonalNoteOthersOpen(!isPersonalNoteOthersOpen)}
              >
              Documents, Personal Notes And Welcome Call Details
                {/* {isPersonalNoteOthersOpen ? <FaChevronUp /> : <FaChevronDown />} */}
              </button>
            </h5>
          </div>
          <div id="personal_note_others"  className="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
          <div className="card-body">
          <div className="row">
              <div className="col-md-6">
              {/* <h4 className='text-danger text-left my-4'>Personal Notes And Welcome Call Details</h4> */}
              </div>
              <div className="col-md-6">
                <Link to={`/personal_notes/${id}/`}><img width="20px" height="20px"  src={pencil} alt="pencil"/></Link>
              </div>
            </div>
            <div className='row mt-5'>
    <div className='col-md-12'>
        {/* <Link to={`/document_add/${id}`} className='btn btn-outline-primary my-5 float-right'>Add Additional Documents</Link> */}
    </div>
    <div className='col-md-6'>
        <label className="blue-color">Passport Photo</label>
        {/* {passport_photo_input===true?<input type="file" name="passport_photo" id="passport_photo"  required />:<label className='bordered p-3 w-100' onClick={(e)=>setpassphotInput(true)}>Click here to upload passport document</label>}  */}
    </div>
    <div className='col-md-6'>
  

{passport_photo ? (
  isPDF(passport_photo) ? (
    <>
      <iframe
        src={passport_photo}
        width="200px"
        height="200px"
        className="rounded"
        style={{ border: "2px solid #233974" }}
      />
      <br />
      <a
        className="text-left ml-5 pl-3"
        href={passport_photo}
        target="_blank"
        rel="noopener noreferrer"
      >
        View PDF
      </a>
    </>
  ) : (
   <a href={passport_photo} target="_blank" className="text-left"> <img
      className="rounded"
      style={{ objectFit: "fill", border: "2px solid #233974" }}
      src={passport_photo}
      alt="Passport Photo"
      width="200"
      height="200"
    /></a>
  )
) : (
  <p className="text-dark">Passport photo not uploaded yet</p>
)}


    </div>
</div>
<div className='row mt-3'>
    <div className='col-md-6'>
        <label className="blue-color">Client Photo</label>
        {/* {client_photo_input===true?<input type="file" name="client_photo"  id="client_photo" required />:<label className='bordered p-3 w-100' onClick={(e)=>setClientPhotoInput(true)}>Click here to upload Your Photo</label>}  */}
    </div>
    <div className='col-md-6 text-left'>
    {client_photo ? (
  isPDF(client_photo) ? (
    <>
      <iframe
        src={client_photo}
        width="200px"
        height="200px"
        className="rounded"
        style={{ border: "2px solid #233974" }}
      />
      <br />
      <a
        className="text-left ml-5 pl-3"
        href={client_photo}
        target="_blank"
        rel="noopener noreferrer"
      >
        View PDF
      </a>
    </>
  ) : (
    <a href={client_photo} className="text-left" target="_blank"><img
      className="rounded"
      style={{ objectFit: "fill", border: "2px solid #233974" }}
      src={client_photo}
      alt="Client Photograph"
      width="200"
      height="200"
    /></a>
  )
) : (
  <p className="text">Client photo not uploaded yet</p>
)}

    </div>
</div>
<div className='row mt-3'>
    {pmq_status === 2 ? (
        <div className='col-md-6'>
            <label className="blue-color">Provisional Degree Certificate</label><br/><br/>
            {/* {provisional_degree_certificate_input===true?<input type="file"  name="provisional_degree_certificate"  id="provisional_degree_certificate" required />:<label className='bordered p-3 w-100' onClick={(e)=>setProvisionalDegreeCertificateInput(true)}>Click here to upload provisional degree certificate</label>}  */}
        </div>
    ) : pmq_status === 3 ? (
        <div className='col-md-6'>
            <label className="blue-color">Final Degree Certificate</label><br/><br/>
            {/* {final_degree_certificate_input===true?<input type="file" name="final_degree_certificate" id="final_degree_certificate"   required />:<label className='bordered p-3 w-100' onClick={(e)=>setFinalDegreeCertificateInput(true)}>Click here to upload final degree certificate</label>}  */}
        </div>
    ) : ""}
    <div className='col-md-6'>
        {pmq_status === 2 ? (
            provisional_degree_certificate ? (
              isPDF(provisional_degree_certificate) ? (
                <>
                  <iframe
                    src={provisional_degree_certificate}
                    width="200px"
                    height="200px"
                    className="rounded"
                    style={{ border: "2px solid #233974" }}
                  />
                  <br />
                  <a
                    className="text-left ml-5 pl-3"
                    href={provisional_degree_certificate}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View PDF
                  </a>
                </>
              ) : (
                <a href={provisional_degree_certificate} className="text-left" target="_blank"><img
                  style={{ objectFit: "fill", border: "2px solid #233974" }}
                  src={provisional_degree_certificate}
                  alt="Provisional Degree Certificate"
                  width={200}
                  height={200}
                /></a>
              )
            ) : (
              <b className="text-dark">Provisional degree certificate not uploaded yet</b>
            )
            
        ) : pmq_status === 3 ? (
          final_degree_certificate ? (
            isPDF(final_degree_certificate) ? (
              <>
                <iframe
                  src={final_degree_certificate}
                  width="200px"
                  height="200px"
                  className="rounded"
                  style={{ border: "2px solid #233974" }}
                />
                <br />
                <a
                  className="text-left ml-5 pl-3"
                  href={final_degree_certificate}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View PDF
                </a>
              </>
            ) : (
              <img
                className="rounded"
                style={{ objectFit: "fill", border: "2px solid #233974" }}
                src={final_degree_certificate}
                alt="Final Degree Certificate"
                width={200}
                height={200}
              />
            )
          ) : (
            <p  className="text-dark">Final degree certificate not uploaded yet</p>
          )
          
        ) : ""}
    </div>
</div>
<div className='row mt-3'>
    {pmq_status === 2 ? (
        <div className='col-md-6'>
            <label className="blue-color">Provisional Registration Certificate</label><br/><br/>
            {/* {provisional_registration_certificate_input===true?<input type="file" name="provisional_degree_certificate"   id="provisional_degree_certificate" required />:<label className='bordered p-3 w-100' onClick={(e)=>setProvisionalRegistrationCertificateInput(true)}>Click here to upload provisional registration certificate</label>}  */}
        </div>
    ) : pmq_status === 3 ? (
        <div className='col-md-6'>
            <label className="blue-color">Final Registration Certificate</label><br/><br/>
            {/* {final_registration_certificate_input===true?<input type="file" name="final_registration_certificate"  id="final_registration_certificate" required />:<label className='bordered p-3 w-100' onClick={(e)=>setFinalRegistrationCertificateInput(true)}>Click here to upload final registration certificate</label>}  */}
        </div>
    ) : ""}
    <div className='col-md-6'>
        {pmq_status === 2 ? (
           provisional_registration_certificate ? (
            isPDF(provisional_registration_certificate) ? (
              <>
                <iframe
                  src={provisional_registration_certificate}
                  width="200px"
                  height="200px"
                  className="rounded"
                  style={{ border: "2px solid #233974" }}
                />
                <br />
                <a
                  className="text-left ml-5 pl-3"
                  href={provisional_registration_certificate}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View PDF
                </a>
              </>
            ) : (
              <img
                style={{ objectFit: "fill", border: "2px solid #233974" }}
                src={provisional_registration_certificate}
                alt="Provisional Registration Certificate"
                width={200}
                height={200}
              />
            )
          ) : (
            "Provisional registration certificate not uploaded yet"
          )
          
        ) : pmq_status === 3 ? (
          final_registration_certificate ? (
            isPDF(final_registration_certificate) ? (
              <>
                <iframe
                  src={final_registration_certificate}
                  width="200px"
                  height="200px"
                  className="rounded"
                  style={{ border: "2px solid #233974" }}
                />
                <br />
                <a
                  className="text-left ml-5 pl-3"
                  href={final_registration_certificate}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View PDF
                </a>
              </>
            ) : (
              <img
                className="rounded"
                style={{ objectFit: "fill", border: "2px solid #233974" }}
                src={final_registration_certificate}
                alt="Final Registration Certificate"
                width={200}
                height={200}
              />
            )
          ) : (
            <p className="text-dark">Final registration certificate not uploaded yet</p>
          )
          
        ) : ""}
    </div>
</div>

{pmq_status === 3 ? (
    <div className='row my-3'>
        <div className='col-md-6'>
            <label className="blue-color">Internship Completion Certificate</label><br/><br/>
            {/* {internship_completion_certificate_input===true?<input type="file" name="internship_completion_certificate"  id="internship_completion_certificate" required />:<label className='bordered p-3 w-100' onClick={(e)=>setInternshipCompletionCertificateInput(true)}>Click here to upload internship completion certificate</label>}  */}
        </div>
        <div className='col-md-6'>
        {internship_completion_certificate ? (
  isPDF(internship_completion_certificate) ? (
    <>
      <iframe
        src={internship_completion_certificate}
        width="200px"
        height="200px"
        className="rounded"
        style={{ border: "2px solid #233974" }}
      />
      <br />
      <a
        className="text-left ml-5 pl-3"
        href={internship_completion_certificate}
        target="_blank"
        rel="noopener noreferrer"
      >
        View PDF
      </a>
    </>
  ) : (
    <img
      className="rounded"
      style={{ objectFit: "fill", border: "2px solid #233974" }}
      src={internship_completion_certificate}
      alt="Internship Completion Certificate"
      width={200}
      height={200}
    />
  )
) : (
  <p>Internship completion certificate not uploaded yet</p>
)}

        </div>
    </div>
) : ""}
          <div className='row mt-5'>
               <div className='col-md-12'>
                <label className="text-danger">Personal Note</label>
               <p>{(personal_note!==null)?personal_note:"not mentioned"}</p>
               </div>
            </div>
            <div className='row my-5'>
               <div className='col-md-4'>
                <label className="text-danger">Welcome Call Date</label>
               <p>{welcome_call_time!==null? moment(welcome_call_date).format('Do MMMM YYYY'):"Date Not Mentioned"}</p>
               </div>
               <div className='col-md-4'>
               <label className="text-danger">Welcome Call Time</label>
               <p>{welcome_call_time===1?"10am to 11am":welcome_call_time===2?"11am to 12am":welcome_call_time===3?"12pm to 1pm":welcome_call_time===4?"3pm to 4pm":welcome_call_time===5?"4pm to 5pm":"Time Not Mentioned"}</p>

               </div>
               </div>
      </div>
          </div>
        </div>
      </div>
    
  );

}

export default ClientDetails