import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';

import {Link,NavLink,useParams} from 'react-router-dom';

import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { message } from 'antd';

import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import $ from 'jquery'; 

import validator from 'validator';
import { off } from 'process';
import { type } from 'os';
import { vendortype } from '../../@types/vendortype';
import TestBookings from '../TestBooking/TestBookings';

import { testbookingtype } from '../../@types/testbookingtype';
import Registrations from '../Registrations/Registrations';

import { ServiceCategoryType } from '../../@types/service_category_type';
import { ServiceData } from '../../@types/servicetype';
import { registrationtype } from '../../@types/registrationtype';
import moment from 'moment';
import server from '../Utils';
import Loader from './Loader';

interface props{
  user_agreed:number,
}
const RegisterationDetails:React.FC<props> = ({user_agreed}) => {
        
   
   
    const [project_id,setProjectId]=useState<number>(0);
    const [user_exist,setUserExists]=useState<boolean>(false);
    const [training_exists,setTrainingExists]=useState<boolean>(false);
    const [test_booking_exists,setTestBookingExist]=useState<boolean>(false);
    const [test_bookings,setTestBooking]=useState<Array<testbookingtype>>([]);
    const [registration_exist,setRegistrationExist]=useState<boolean>(false);
    const[registrations,setRegistrations]=useState<Array<registrationtype>>([]);
    const {id}= useParams();
    const [service_category_id,setServiceCategoryId]=useState<number>(0);
    const [service_categories,setServiceCategories]=useState<Array<ServiceCategoryType>>([])
    const [services,setServices]=useState<Array<ServiceData>>([])
    const [service_id,setServiceId]=useState<number>(0)
   
    //GMC
   const [gmc_reference_number,setGmcReferenceNumber]=useState<number>(0);
   const [gmc_login_password,setGmcLoginPassword]=useState<string>('');
   const [gmc_secret_question,setGmcSecretQuestion]=useState<string>('');
   const [gmc_secret_answer,setGmcSecretAnswer]=useState<string>('');
   const [gmc_exist,setGmcExist]=useState<number>(1);

   //IELTS
   const [ielts_login_id,setIeltsLoginId]=useState<string>('');
   const [ielts_login_password,setIeltsLoginPassword]=useState<string>('');
   const [ielts_secret_question,setIeltsSecretQuestion]=useState<string>('');
   const [ielts_secret_answer,setIeltsSecretAnswer]=useState<string>('');
   const [ielts_exist,setIeltsExist]=useState<number>(1);

   //OET

const [oet_login_id,setOetLoginId]=useState<string>('');
const [oet_login_password,setOetLoginPassword]=useState<string>('');
const [oet_secret_question,setOetSecretQuestion]=useState<string>('');
const [oet_secret_answer,setOetSecretAnswer]=useState<string>('');
const [oet_exist,setOetExist]=useState<number>(1);

//EPIC
const [epic_account_setup_date,setEpicAccountSetupDate]=useState<string>('');
const [epic_username,setEpicUsername]=useState<string>('');
const [epic_password,setEpicPassword]=useState<string>('');
const [epic_id,setEpicId]=useState<string>('');
const [epic_secret_question1,setEpicSecretQuestion1]=useState<string>('')
const [epic_secret_question2,setEpicSecretQuestion2]=useState<string>('')
const [epic_secret_question3,setEpicSecretQuestion3]=useState<string>('')
const [epic_secret_question4,setEpicSecretQuestion4]=useState<string>('')

const [epic_secret_answer1,setEpicSecretAnswer1]=useState<string>('')
const [epic_secret_answer2,setEpicSecretAnswer2]=useState<string>('')
const [epic_secret_answer3,setEpicSecretAnswer3]=useState<string>('')
const [epic_secret_answer4,setEpicSecretAnswer4]=useState<string>('')
const [epic_exist,setEpicExist]=useState<number>(1);

const[notarycam_username,setNotarycamUsername]=useState<string>('')
const[notarycam_password,setNotarycamPassword]=useState<string>('')
const [loading, setLoading] = useState(false);
// const [user_agreed,setUserAgreed]=useState<number>(0);
 
    useEffect(() => {
      let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
      let pro_id= +projectid;
     
    
   

       
     
       
       server.get(`/registrations/${id}`)
       .then((response)=>{
         setServiceCategoryId(response.data.registrations.service_category_id)
        
        setRegistrations(response.data.registrations)
        setServiceId(response.data.registrations.service_id)
        setGmcReferenceNumber(response.data.registrations.gmc_reference_number)
        setGmcLoginPassword(response.data.registrations.gmc_login_password)
        setGmcSecretQuestion(response.data.registrations.gmc_secret_question)
        setGmcSecretAnswer(response.data.registrations.gmc_secret_answer)
        setGmcExist(response.data.registrations.gmc_exist)
        setIeltsLoginId(response.data.registrations.ielts_login_id)
        setIeltsLoginPassword(response.data.registrations.ielts_login_password)
        setIeltsSecretQuestion(response.data.registrations.ielts_secret_question)
        setIeltsSecretAnswer(response.data.registrations.ielts_secret_answer)
        setIeltsExist(response.data.registrations.ielts_exist)
        setOetLoginId(response.data.registrations.oet_login_id)
        setOetLoginPassword(response.data.registrations.oet_login_password)
        setOetSecretQuestion(response.data.registrations.oet_secret_question)
        setOetSecretAnswer(response.data.registrations.oet_secret_answer)
        setOetExist(response.data.registrations.oet_exist)
        setEpicAccountSetupDate(response.data.registrations.epic_account_setup_date)
        setEpicUsername(response.data.registrations.epic_username)
        setEpicPassword(response.data.registrations.epic_password)
        setEpicId(response.data.registrations.epic_id)
        setEpicSecretQuestion1(response.data.registrations.epic_secret_question1)
        setEpicSecretQuestion2(response.data.registrations.epic_secret_question2)
        setEpicSecretQuestion3(response.data.registrations.epic_secret_question3)
        setEpicSecretQuestion4(response.data.registrations.epic_secret_question4)
        setEpicSecretAnswer1(response.data.registrations.epic_secret_answer1)
        setEpicSecretAnswer2(response.data.registrations.epic_secret_answer2)
        setEpicSecretAnswer3(response.data.registrations.epic_secret_answer3)
        setEpicSecretAnswer4(response.data.registrations.epic_secret_answer4)
        setEpicExist(response.data.registrations.epic_exist)
        setNotarycamUsername(response.data.registrations.notarycam_username)
        setNotarycamPassword(response.data.registrations.notarycam_username)

       })
      }, []);

      
      if (loading) {
        return <Loader />;
      }
  return ( <div className='pb-5'>
    {/* {user_exist===false?<Link to={`/add_onboarding_details/${id}`}  className='btn btn-info text-light text-capitalize'>Add Onboarding details before proceeding to Training</Link>:""}
    {user_exist===true &&  training_exists===false?<Link to={`/add_training_details/${id}`} className='btn btn-info text-white' >Add Training </Link>:""}
    {training_exists===true && test_booking_exists===false ?<Link to={`/add_test_booking_detail/${id}`} className='btn btn-info text-white' >Add Test Booking </Link>:""}
     */}

{user_agreed === 1 ? 
  
  <Link to={`/add_registration_detail/${id}`} className='btn btn-info text-light float-right'>+</Link> :
<p className='text-danger'>Kindly ask the doctor to fill their details in their portal</p>
}
    {/*  <Link to={`/add_registration_detail/${id}`} className='btn btn-info float-right text-white' >Add Registration </Link> */}
    <br/>
    
 { registrations && registrations.map((registration,index)=>(<div className='my-4'>
    
    {registration.service_id===15||registration.service_id===105||registration.service_id===191?<div className='row my-3 jumbotron mx-1'>
      <div className='col-md-12 py-2'><div className='row'><div className='col-md-4'><h3>OET Registrations Details</h3> </div><div className='col-md-5'></div><div className='col-md-3'><Link to={`/edit_registration_detail/${registration.id}`} className='nav-link'><i className='fa fa-edit text-info'></i></Link></div></div></div>
      
     
      <div className='col-md-4'>
        <h6>OET Login Id</h6>
      </div>
      <div className='col-md-4'>
        {registration.oet_login_id}
      </div>
      <div className='col-md-4'></div>

      <div className='col-md-4'>
        <h6>OET Login Password</h6>
      </div>
      <div className='col-md-4'>
        {registration.oet_login_password}
      </div>
      <div className='col-md-4'></div>

      <div className="col-md-4">
      <h6>OET Secret Question</h6>
      </div>
      <div className="col-md-4">
        {registration.oet_secret_question}
      </div>
      <div className="col-md-4"></div>


      <div className="col-md-4">
      <h6>OET Secret Answer</h6>
      </div>
      <div className="col-md-4">
        {registration.oet_secret_answer}
      </div>
      <div className="col-md-4"></div>

    </div>:registration.service_id===90||registration.service_id===176||registration.service_id===262?<div className='row my-3 jumbotron mx-1'>
    <div className='col-md-12 py-2'><div className='row'><div className='col-md-5'><h3>IELTS Registrations Details</h3> </div><div className='col-md-4'></div><div className='col-md-3'><Link to={`/edit_registration_detail/${registration.id}`} className='nav-link'><i className='fa fa-edit text-info'></i></Link></div></div></div>      
     
      
      <div className='col-md-4'>
        <h6>IELTS Login Id</h6>
      </div>
      <div className='col-md-4'>
        {registration.ielts_login_id}
      </div>
      <div className='col-md-4'></div>

      <div className='col-md-4'>
        <h6>IELTS Login Password</h6>
      </div>
      <div className='col-md-4'>
        {registration.ielts_login_password}
      </div>
      <div className='col-md-4'></div>

      <div className="col-md-4">
      <h6>IELTS Secret Question</h6>
      </div>
      <div className="col-md-4">
        {registration.ielts_secret_question}
      </div>
      <div className="col-md-4"></div>


      <div className="col-md-4">
      <h6>IELTS Secret Answer</h6>
      </div>
      <div className="col-md-4">
        {registration.ielts_secret_answer}
      </div>
      <div className="col-md-4"></div>

    </div>:registration.service_id===14||registration.service_id===104||registration.service_id===190?<div className='row my-3 jumbotron mx-1'>
    <div className='col-md-12 py-2'><div className='row'><div className='col-md-5'><h3>GMC Registrations Details</h3> </div><div className='col-md-4'></div><div className='col-md-3'><Link to={`/edit_registration_detail/${registration.id}`} className='nav-link'><i className='fa fa-edit text-info'></i></Link></div></div></div>   
    
      
     
      
      <div className='col-md-4'>
        <h6>GMC Reference Number</h6>
      </div>
      <div className='col-md-4'>
        <i>{registration.gmc_reference_number}</i>
      </div>
      <div className='col-md-4'></div>

      <div className='col-md-4'>
        <h6>GMC Login Password</h6>
      </div>
      <div className='col-md-4'>
       <i> {registration.gmc_login_password}</i>
      </div>
      <div className='col-md-4'></div>

      <div className="col-md-4">
      <h6>GMC Secret Question</h6>
      </div>
      <div className="col-md-4">
        <i>{registration.gmc_secret_question}</i>
      </div>
      <div className="col-md-4"></div>


      <div className="col-md-4">
      <h6>GMC Secret Answer</h6>
      </div>
      <div className="col-md-4">
        <i>{registration.gmc_secret_answer}</i>
      </div>
      <div className="col-md-4"></div>

    </div>:registration.service_id===33||registration.service_id===123||registration.service_id===209?<div className='row my-3 jumbotron mx-1'>
    <div className='col-md-12 py-2'><div className='row'><div className='col-md-4'><h3>EPIC Registrations Details</h3> </div><div className='col-md-5'></div><div className='col-md-3'><Link to={`/edit_registration_detail/${registration.id}`} className='nav-link'><i className='fa fa-edit text-info'></i></Link></div></div></div>      
      
      
      
      <div className='col-md-4'>
        <h6>EPIC Account Setup Date</h6>
      </div>
      <div className='col-md-4'>
        <i>{moment(registration.epic_account_setup_date).format('Do MMMM YYYY')}</i>
      </div>
      <div className='col-md-4'></div>

      <div className='col-md-4'>
        <h6>EPIC Username</h6>
      </div>
      <div className='col-md-4'>
        <i>{registration.epic_username}</i>
      </div>
      <div className='col-md-4'></div>

      <div className="col-md-4">
      <h6>EPIC  Password</h6>
      </div>
      <div className="col-md-4">
        <i>{registration.epic_password}</i>
      </div>
      <div className="col-md-4"></div>


      <div className="col-md-4">
      <h6>EPIC Secret Question1</h6>
      </div>
      <div className="col-md-4">
       <i> {registration.epic_secret_question1}</i>
      </div>
      <div className="col-md-4"></div>

      <div className="col-md-4">
      <h6>EPIC Secret Answer1</h6>
      </div>
      <div className="col-md-4">
        <i>{registration.epic_secret_answer1}</i>
      </div>
      <div className="col-md-4"></div>


      <div className="col-md-4">
      <h6>EPIC Secret Question2</h6>
      </div>
      <div className="col-md-4">
        <i>{registration.epic_secret_question2}</i>
      </div>
      <div className="col-md-4"></div>

      <div className="col-md-4">
      <h6>EPIC Secret Answer2</h6>
      </div>
      <div className="col-md-4">
        <i>{registration.epic_secret_answer2}</i>
      </div>
      <div className="col-md-4"></div>


      <div className="col-md-4">
      <h6>EPIC Secret Question3</h6>
      </div>
      <div className="col-md-4">
       <i> {registration.epic_secret_question3}</i>
      </div>
      <div className="col-md-4"></div>

      <div className="col-md-4">
      <h6>EPIC Secret Answer3</h6>
      </div>
      <div className="col-md-4">
        <i>{registration.epic_secret_answer3}</i>
      </div>
      <div className="col-md-4"></div>

      <div className="col-md-4">
      <h6>EPIC Secret Question4</h6>
      </div>
      <div className="col-md-4">
       <i> {registration.epic_secret_question4}</i>
      </div>
      <div className="col-md-4"></div>

      <div className="col-md-4">
      <h6>EPIC Secret Answer4</h6>
      </div>
      <div className="col-md-4">
        <i>{registration.epic_secret_answer4}</i>
      </div>
      <div className="col-md-4"></div>

      {registration.epic_eif && <div className='col-md-4'>
        <h6>Epic Eif</h6>
      </div>}
      {registration.epic_eif && <div className='col-md-4'>
        {registration.epic_eif.endsWith('.pdf')?<><iframe src={registration.epic_eif} width={"200px"} height={"200px"}  className='rounded' style={{border:"2px solid #233974"}}/><br/><a className='text-left' href={registration.epic_eif} target='_blank'>View Photo</a></>: <img className="rounded"  style={{border:"2px solid #233974"}} src={registration.epic_eif} alt="Passport" width={200} height={200} />}<br /><br />
      </div>}
      {registration.epic_eif && <div className='col-md-4'>
        
      </div>}

    </div>:registration.service_id===35||registration.service_id===125||registration.service_id===211?<div className='row mb-5 jumbotron mx-1' style={{marginBottom:'2%'}}>
    <div className='col-md-12 py-2'><div className='row '><div className='col-md-5'><h3>Notarycam Registrations Details</h3> </div><div className='col-md-4'></div><div className='col-md-3'><Link to={`/edit_registration_detail/${registration.id}`} className='nav-link'><i className='fa fa-edit text-info'></i></Link></div></div></div>      
      
      
      
      <div className='col-md-4'>
        <h6>NotaryCam UserName </h6>
      </div>
      <div className='col-md-4'>
        <i>{registration.notarycam_username}</i>
      </div>
      <div className='col-md-4'></div>

      <div className='col-md-4'>
        <h6>NotaryCam  Password</h6>
      </div>
      <div className='col-md-4'>
        <i>{registration.notarycam_password}</i>
      </div>
      <div className='col-md-4'></div>

      

    </div>:""}


 </div>))}

    
    </div>)
}

export default RegisterationDetails;