import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import { ServiceData } from '../../@types/servicetype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import { service_categorytypedata } from '../../@types/service_categorytypedata';
import { StageData } from '../../@types/stagetype';
import { useParams } from 'react-router-dom';
import server from '../Utils';
import { ColumnTypes } from '../../@types/columntype';
import { admintype as Admintype } from '../../@types/admintype';

import Loader from './Loader';
const AddTaskScheduler = () => {
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [stages,setStages]=useState<Array<StageData>>([])
    const [project_id,setProjectId]=useState<number>(0);
    const [product_id,setProductId]=useState<number>(0);
   
    const [service_cat_id,setServiceCategoryId]=useState<number>(0);
    const [service_categories,setServiceCategories]=useState<Array<ServiceCategoryType>>([])
    const [services,setServices]=useState<Array<ServiceData>>([])
    const [service_id,setServiceId]=useState<number>(0)
    const [service_categoriser_name,setServiceCategoriserName]=useState<string>("")
    const [service_category_type,setCategoryTypeId]=useState<number>(0)
    const [start_time,setStartTime]=useState<number>(0)
    const [start_time_duration,setStartTimeDuration]=useState<number>(0)
    const [before_or_after_start_time,SetBeforeOrAfterStartTime]=useState<number>(0)
    
    const [end_time,setEndTime]=useState<number>(0)
    const [end_time_duration,setEndTimeDuration]=useState<number>(0)
    const [before_or_after_end_time,SetBeforeOrAfterEndTime]=useState<number>(0)
    const [columns,setColumns]=useState<Array<ColumnTypes>>([])
    const [start_time_column,setStartTimeColumn]=useState<number>(0)
    const [end_time_column,setEndTimeColumn]=useState<number>(0)
    const[primary_incharge,setPrimaryIncharge]=useState<number>(0);
    const[secondary_incharge,setSecondaryIncharge]=useState<number>(0);
    const [loading, setLoading] = useState(false);

    
    const [admins,setAdmins]=useState<Array<Admintype>>([]);

    const [service_category_types,setServiceCategorytypes]=useState<Array<service_categorytypedata>>([])
    
    useEffect(() => {
        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let id= +projectid
        setProjectId(id)
        // server.get(`/service_category_types/${id}`)
        // .then((response)=>{
        //     console.log(response)
        //     if(response.data.service_category_types.length>0){
        //         setServiceCategorytypes(response.data.service_category_types)
        //     }else{
        //         setServiceCategorytypes([]);
        //     }
        // })
        server.get(`/get_product/${id}`)
        .then((response)=>{
            
            setProducts(response.data.product)
            
        })
        server.get(`/get_stage/${product_id}`)
        .then((response)=>{
            
          setStages(response.data.stage);
            
        })
       
    server.get(`/get_table_columns`)
    .then((response)=>{
      setColumns(response.data.columns)
    })
    
    server.get(`/get_superadmins`)
    .then((response)=>{
        setAdmins(response.data.admins)
    })

      }, []);
   
      const getCategorytypesArray = (id: number) => {
        setServiceCategoryId(id);
        server.get(`/service_category_type_on_category/${id}`)
            .then((response) => {
                console.log(response);
                if (response.data.service_category_types.length > 0) {
                    setServiceCategorytypes(response.data.service_category_types);
                } else {
                    setServiceCategorytypes([]);
                }
            });
    }

    const getServicesArray = (id: number) => {
        setCategoryTypeId(id);
        if (id !== 0) { // Add this condition to check if id is not equal to 0
            server.get(`/servicesontypes/${id}`)
                .then((response) => {
                    console.log(response.data.services);
                    setServices(response.data.services);
                });
        }
    }
       const setStage=(id:number)=>
       {
    
         
         setProductId(id);
         
           server.get(`/get_stage/${id}`)
           .then((response)=>{
               
             setStages(response.data.stage);
               
           })
           server.get(`/get_service_categories_all/${project_id}/${id}`)
           .then((response)=>{
              
               if(response.data.service_categories.length>0){
                 setServiceCategories(response.data.service_categories)
               }else{
                 setServiceCategories([]);
               }
           })
       }
    const AddTaskScheduler=(e:React.FormEvent<HTMLFormElement>)=>{
        e.preventDefault();
        if(product_id===0){
            toast('select the product');
        }else if(service_cat_id===0){
            toast('Select Service Category')
        }else if(service_category_type===0){
            toast('select Category type')
        }else if(service_id===0){
          toast('select service')
        }else if(start_time===0){
          toast('Enter start time')
        }else if(start_time_duration===0){
          toast('Select time duration')
        }else if(before_or_after_start_time===0){
          toast('Select berfore or after start time')
        }else if(start_time_column===0){
          toast('Select column for start time')
        }else if(end_time===0){
          toast('Enter start time')
        }else if(end_time_duration===0){
          toast('Select time duration')
        }else if(before_or_after_end_time===0){
          toast('Select berfore or after start time')
        }else if(end_time_column===0){
          toast('Select column for start time')
        }else if(primary_incharge===0){
          toast('select primary incharge')
        } else if(secondary_incharge===0){
          toast('select secondary incharge')
        } 

        
        else{
            var form =document.getElementById('add_task_scheduling') as HTMLFormElement
            var formdata= new FormData(form);
            server.post(`/add_task_schedule`,formdata)
            .then((response)=>{
               if(response.data.code===200){
                toast(response.data.message);
                setTimeout(function () { window.history.back(); }, 1000);
               }else{
                toast(response.data.message);
               }
            })
        }
    }
    if (loading) {
      return <Loader />;
    }
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}


    <h4 className='text-danger text-center'>Add Task Scheduler</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5 px-3'>
    
    <div className='col-md-12'>
      
        <form id="add_task_scheduling" onSubmit={AddTaskScheduler} className='w-100' >
          <div className='row'>
            <input type="hidden" name="project_id" value={project_id} id="project_id" />
            
            {project_id!==0?<div className='col-md-3'>
                <label>Product </label>
            <select className='form-control' name='product_id' id="product_id" onChange={(e)=>setStage(parseInt(e.target.value))} required>
                        <option value={0}>Select Product</option>
                        {products.map((product)=>(
                            <option value={product.id}>{product.product_name}</option>))}
            </select>
            </div>:""}
            
                          
            {product_id!==0? <div className='col-md-3'>
            <label htmlFor="">Service Category:</label>
               <select className='form-control' id="service_cat_id" name="service_cat_id" value={service_cat_id} onChange={(e)=>getCategorytypesArray(parseInt(e.target.value))}> 
              
                    <option value="0">Select Service Category</option>        
                         {service_categories.map((service_category,index)=>(
                            
                            <option value={service_category.id}>{service_category.service_category_name}</option>
                       ))}   
                </select>             
                            
                </div>:""}
            { service_cat_id !== 0 ? <div className='col-md-3'>
      <label>Service Category Type</label>
      <select id="service_category_type" className='form-control' name="service_category_type" value={service_category_type} onChange={(e) => { getServicesArray(parseInt(e.target.value));  }}>
        <option value="0">Select Category Type</option>
        {service_category_types.map((service_category_type, index) => (
          <option value={service_category_type.id}>{service_category_type.service_category_type}</option>
        ))}
      </select>
    </div> : ""}

    {service_cat_id !== 0 && service_category_type !== 0 ? <div className='col-md-3'>
      <label htmlFor="">Services :</label>
      <select className='form-control' id="service_id" name="service_id" value={service_id} onChange={(e) => setServiceId(parseInt(e.target.value))}>
        <option value="0">Select Service </option>
        {services.map((service, index) => (
          <option value={service.id}>{service.service_name}</option>
        ))}
      </select>
    </div> : ""}
                
               

          </div>
         {service_id!==0? <div className='row'>
            <h3 className='my-3'>Start Time</h3>
            <div className='col-md-1'>
              <input type="number" className='form-control' name="start_time" value={start_time}  onChange={(e)=>setStartTime(parseInt(e.target.value))} />
            </div>
            <div className='col-md-3'>
              <select  className='form-control' name="start_time_duration" value={start_time_duration} onChange={(e)=>setStartTimeDuration(parseInt(e.target.value))} >
                <option value={0}>Choose The Duration</option>
                <option value={1}>Day(s)</option>
                <option value={2}>Weeks</option>
                <option value={3}>months</option>
                <option value={4}>Years</option>
              </select>
            </div>
            <div className='col-md-3'>
              <select  className='form-control' name="before_or_after_start_time" value={before_or_after_start_time} onChange={(e)=>SetBeforeOrAfterStartTime(parseInt(e.target.value))} >
                <option value={0}>Choose Before/After</option>
                <option value={1}>Before</option>
                <option value={2}>After</option>
              
              </select>
            </div>
           
            <div className='col-md-3'>
              
              <select id="start_time_column" className='form-control' name="start_time_column" value={start_time_column} onChange={(e)=>setStartTimeColumn(parseInt(e.target.value))}>
                <option value="0">Select Notification Date </option>
                {columns.map((column,index)=>(
                  <option value={column.id}>{column.name}</option>
                ))}
              </select>
            </div>
           
          </div>:""}
        {service_id!==0?  <div className='row'>
            <h3 className='my-3'>End Time</h3>
            <div className='col-md-1'>
              <input type="number" className='form-control' name="end_time" value={end_time}  onChange={(e)=>setEndTime(parseInt(e.target.value))} />
            </div>
            <div className='col-md-3'>
              <select  className='form-control' name="end_time_duration" value={end_time_duration} onChange={(e)=>setEndTimeDuration(parseInt(e.target.value))} >
                <option value={0}>Choose The Duration</option>
                <option value={1}>Hours</option>
                <option value={2}>Weeks</option>
                <option value={3}>months</option>
                <option value={4}>Years</option>
              </select>
            </div>
            <div className='col-md-3'>
              <select  className='form-control' name="before_or_after_end_time" value={before_or_after_end_time} onChange={(e)=>SetBeforeOrAfterEndTime(parseInt(e.target.value))} >
              <option value={0}>Choose Before/After</option>
                <option value={1}>Before</option>
                <option value={2}>After</option>
              
              </select>
            </div>
           
            <div className='col-md-3'>
              
              <select id="start_time_column" className='form-control' name="end_time_column" value={end_time_column} onChange={(e)=>setEndTimeColumn(parseInt(e.target.value))}>
                <option value="0">Select Notification Date </option>
                {columns.map((column,index)=>(
                  <option value={column.id}>{column.column_name}</option>
                ))}
              </select>
            </div>
           
          </div>:""}
          
          {service_id!==0?<div className='row my-3'>
          <div className='col-md-3'>
                    <label>Primary Incharge</label>
                    <select className='form-control' id="primary_incharge" name="primary_incharge" value={primary_incharge} onChange={(e)=>setPrimaryIncharge(parseInt(e.target.value))}>
                       <option value="0">Select Executives</option>
                        {admins.map((admin)=>(
                            <option value={admin.id}>{admin.first_name}</option>
                        ))}
                    </select>
                </div>
                <div className='col-md-3'>
                    <label>Secondary Incharge</label>
                    <select className='form-control' id="secondary_incharge" name="secondary_incharge" value={secondary_incharge} onChange={(e)=>setSecondaryIncharge(parseInt(e.target.value))}>
                       <option value="0">Select Executives</option>
                        {admins.map((admin)=>(
                            <option value={admin.id}>{admin.first_name}</option>
                        ))}
                    </select>
                </div>
          </div>:""}
          
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Submit</button></div>
        </form>
     
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default AddTaskScheduler