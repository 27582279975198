/* eslint-disable react/jsx-no-undef */
import React, { useState } from 'react'
import{Routes as AllRoutes,Route} from 'react-router-dom';
import SuperAdminDashboard from '../pages/SuperAdminDashboard';
import Login from '../components/Login';
import { LoginProvider } from '../context/actions/LoginAction';
import AddProject from '../components/Projects/AddProject';
import AllProjects from '../components/Projects/AllProjects';
import EditProject from '../components/Projects/EditProject';
import AddProduct from '../components/Products/AddProduct';
import AllProducts from '../components/Products/AllProducts';
import EditProduct from '../components/Products/EditProduct';
import AddStage from '../components/Stages/AddStage';
import AllStages from '../components/Stages/AllStages';
import EditStage from '../components/Stages/EditStage';
import AddSubStage from '../components/SubStages/AddSubStage';
import SubStages from '../components/SubStages/AllSubStages';
import EditSubStage from '../components/SubStages/EditSubStages';
import AddService from '../components/Services/AddService';
import Services from '../components/Services/Services';
import EditService from '../components/Services/EditService';
import AddUserByCousellor from '../components/Users/AddUserByCousellor';
import Addinstalltypes from '../components/InstallmentTypes/Addinstalltypes';
import Installtypes from '../components/InstallmentTypes/Installtypes';
import Editinstalltypes from '../components/InstallmentTypes/Editinstalltypes';
import Users from '../components/Users/AllUsers';
import EditUser from '../components/Users/EditUser';
import ViewUser from '../components/Users/ViewUser';
import AddSpeciality from '../components/Speciality/AddSpeciality';
import Specialities from '../components/Speciality/Specialities';
import EditSpeciality from '../components/Speciality/EditSpeciality';
import AddSubSpeciality from'../components/SubSpecialities/AddSubSpecialites';
import SubSpeciality from '../components/SubSpecialities/SubSpeciality';
import EditSubSpeciality from '../components/SubSpecialities/EditSubSpeciality';
import UserLogin from '../components/UserLogin';
import ClientDashboard from '../components/Clients';
import UserProfile from '../components/UserProfile/UserProfile';
import UserAddress from '../components/UserAddress';
import PMQDetails from '../components/UserPMQ';
import UserLanguageTest from '../components/UserLanguageTest';
import GMC_And_Intenship from '../components/GMC_And_Intenship';
import EmploymentAndSpecialist from '../components/UserEmploymentAndSpeciality';
import UserDocuments from '../components/UserDocuments/UserDocuments';
import SeeUser from '../components/Users/seeuser';
import AddWelcomeKitType from '../components/Welcomekittype/AddWelcomeKitType';
import Welcomekittype from '../components/Welcomekittype';
import EditWelcomekitType from '../components/Welcomekittype/EditWelcomekitType';
import AddDiscountType from '../components/Discounttypes/AdddiscountTypes';
import DiscountTypes from '../components/Discounttypes';
import EditDiscountType from '../components/Discounttypes/EditDiscountType';
import SuperAdminSecondaryDashboard from '../pages/SuperAdminSecondaryDashboard';
import Addservicecategory from '../components/ServicesCategory/Addservicecategory';
import Servicecategory from '../components/ServicesCategory';
import EditServiceCategory from '../components/ServicesCategory/EditServiceCategory';
import AddClientOnBoardDetails from '../components/Onboarding/addClientOnboardingDetails';
import Onboarding from '../components/Onboarding/Onboarding';
import AddVendor from '../pages/Vendor/AddVendor';
import Vendor from '../pages/Vendor/Vendor';
import EditVendor from '../pages/Vendor/EditVendor';
import EditOnBoardDetails from '../components/Onboarding/EditOnboarding';
import AddTrainingDetails from '../components/TrainingDetails/AddTrainingDetails';
import AddServiceCategoriser from '../components/ServiceCategorisers/AddServiceCategoriser';
import ServiceCategorisers from '../components/ServiceCategorisers';
import EditServiceCategoriser from '../components/ServiceCategorisers/EditServiceCategoriser';
import EditTrainingDetails from '../components/TrainingDetails/EditTrainingDetails';
import AddDocuments from '../components/Documents/AddDocuments';
import EditDocuments from '../components/Documents/EditDocuments';
import EditTestBooking from '../components/TestBooking/EditTestBooking';
import TestBookings from '../components/TestBooking/TestBookings';
import AddTestBooking from '../components/TestBooking/AddTestBooking';
import AddRegsitrations from '../components/Registrations/AddRegistrations';
import Registrations from '../components/Registrations';
import EditRegistrations from '../components/Registrations/EditRegistrations';
import AddTestBookingDetails from '../components/TestBookingDetails/AddTestbookingDetails';
import EditTestBookingDetails from '../components/TestBookingDetails/EditTestBookingDetails';
import AddRegistrationDetails from '../components/RegistrationsDetails/AddRegistrationDetails';
import EditRegistrationDetails from '../components/RegistrationsDetails/EditRegistrationDetails';
import AddTrainingAndSubscriptionDetails from '../components/TrainingAndSubcriptionsDetails/AddTrainingAndSubscriptionsDetails';
 import EditTrainingAndSubscriptionDetails from '../components/TrainingAndSubcriptionsDetails/EditTrainingAndSubscriptionDetails';
import AddTrainingAndSubscriptions from '../components/TrainingAndSubscriptions/AddTrainingAndSubscriptions';
import TrainingAndSubscriptions from '../components/TrainingAndSubscriptions';
import EditTrainingAndSubscriptions from '../components/TrainingAndSubscriptions/EditTrainingAndSubscriptions';
import AddCoursesAllocated from '../components/CoursesAllocated/AddCoursesAllocated';
import CoursesAllocated from '../components/CoursesAllocated/CoursesAllocated';
import EditCoursesAllocated from '../components/CoursesAllocated/EditCoursesAllocated';
import AddServiceCategoryType from '../components/ServiveCategoryType/AddServiceCategoryType';
import ServiceCategoryType from '../components/ServiveCategoryType';
import EditServiceCategoryType from '../components/ServiveCategoryType/EditServiceCategoryType';
import AddCourseAllocatedDetails from '../components/CoursesAllocatedDetails/AddCourseAllocatedDetails';
import EditCourseAllocatedDetails from '../components/CoursesAllocatedDetails/EditCourseAllocatedDetails';
import AddPortfolio from '../components/Portfolio/AddPortfolio';
import EditPortfolio from '../components/Portfolio/EditPortfolio';
import AddTaskScheduler from '../components/TaskScheduler/AddTaskScheduler';
import EditTaskScheduler from '../components/TaskScheduler/EditTaskScheduler';
import TaskSchedulers from '../components/TaskScheduler';
import OnboardingList from '../components/OnboardingList/OnboardingList';
import TrainingList from '../components/TrainingList/TrainingList';
import TestBookingList from '../components/TestBookingList';
import RegistrationList from '../components/RegistrationsList/RegistrationsList';
import CoursesAllocatedList from '../components/CoursesAllocatedList';
import TrainingSubscriptionList from '../components/TrainingSubscriptionList';
import PortFolioList from '../components/PortFolioList';
import AddTier4Visa from '../components/Tier4Visa/AddTier4Visa';
import EditTier4Visa from '../components/Tier4Visa/EditTier4Visa';
import TravelAndAccomdation from '../components/TravelAndAccomdation';
import AddTravelAndAccomdation from '../components/TravelAndAccomdation/AddTravelAndAccomdation';
import EditTravelAndAccomdation from '../components/TravelAndAccomdation/EditTravelAndAccomdation';
import AddCab from '../components/Cab/AddCab';
import EditCab from '../components/Cab/EditCab';
import AddGMCRegistration from '../components/GMCAndRegistration/AddGMCRegistration';
import EditGMCRegistration from '../components/GMCAndRegistration/EditGMCRegistration';
import UserProfileEdit from '../components/UserProfileEdit';
import UserAddressEdit from '../components/UserAddressEdit';
import UserPMQEdiT from '../components/UserPMQEdiT';
import UserLanguageTestEdiT from '../components/UserLanguageTestEdiT';
import UserGMCAndInternshipEdiT from '../components/UserGMCAndInternshipEdiT';
import UserEmploymentAndSpecialityEdiT from '../components/UserEmploymentAndSpecialityEdiT';
import DocumentsEdit from '../components/PersonalNotesEdit';
import PersonalNotesEdit from '../components/PersonalNotesEdit';
import CabList from '../components/CabList';
import TravelAndAccomdationList from '../components/TravelAndAccomdationList';
import GMCAndRegistration from '../components/GMCAndRegistration';
import GMCAndRegistrationList from '../components/GMCAndRegistrationList';
import Tier4Visa from '../components/Tier4Visa/Tier4Visa';
import Tier4VisaList from '../components/Tier4VisaList';
import UserInfo from '../components/UserInfo/UserInfo';
import UserDeclaration from '../components/UserDeclaration';
import AddOnboardingClientMultiple from '../components/MultipleEntry/AddOnboardingClientMultiple';
import AddMultipleTraining from '../components/MultipleEntry/AddMultipleTraining';
import AddMultipleRegistrations from '../components/MultipleEntry/AddMultipleRegistration';
import AddMultipleTier4Visa from '../components/MultipleEntry/AddMultipleTier4Visa';
import AddMultipleTravelAndAccomodation from '../components/MultipleEntry/AddMultipleTravelAndAccomdation';
import AddMultipleCab from '../components/MultipleEntry/AddMultipleCab';
import AddMultipleGMCRegistration from '../components/MultipleEntry/AddMultipleGMCRegistration';
import AddMultipleTestBookingDetails from '../components/MultipleEntry/AddMultipleTestbooking';
import AddMultipleCourseAllocatedDetails from '../components/MultipleEntry/AddMultipleCoursesAllocated';
import AddMultipleTrainingAndSubscriptionDetails from '../components/MultipleEntry/AddMultipleTrainingAndSubscription';
import AddMultiplePortFolio from '../components/MultipleEntry/AddMultiplePortfolio';
import UserDetails from '../components/Users/UserDetails';
import UserTrainingDetails from '../components/TrainingDetails/UserTrainingDetails';
import Support from '../components/Support';
import AddIncharge from '../components/Incharge/AddIncharge';
import Incharge from '../components/Incharge';
import EditIncharge from '../components/Incharge/EditIncharge';
import UserTestBookingDetails from '../components/TestBookingDetails/UserTestBookingDetails';
import UserRegistrationDetails from '../components/RegistrationsDetails/UserRegistrationDetails';
import UserTrainingAndSubscriptionDetails from '../components/TrainingAndSubcriptionsDetails/UserTrainingAnd SubscriptionDetails';
import UserPortFolio from '../components/Portfolio/UserPortFolio';
import UserCoursesAllocatedDetails from '../components/CoursesAllocatedDetails/UserCoursesAllocatedDetails';
import UserTravelAndAccomdation from '../components/TravelAndAccomdation/UserTravelAndAccomdation';
import UserTier4Visa from '../components/Tier4Visa/UserTier4Visa';
import UserCab from '../components/Cab/UserCab';
import UserGMCAndRegistrationDetails from '../components/GMCAndRegistration/UserGMCAndRegistration';
import UserExtraDocuments from '../components/Documents/UserDocuments';
import AddUserDocuments from '../components/Documents/AddUserDocuments';



const Routes = () => {
  const [userAgreed, setUserAgreed] = useState(false);
  return (
    <LoginProvider>
        <AllRoutes>
          <Route path="/" element={<Login/>}/>
          <Route path="/superadmindashboard" element={<SuperAdminDashboard/>}/>
          <Route path="/superadminsecondary" element={<SuperAdminSecondaryDashboard/>} />
          
          {/* Package Routes */}
          <Route path='/add_project' element={<AddProject />} />
          <Route path='/projects' element={<AllProjects/>} />
          <Route path="/program_edit/:id" element={<EditProject/>}/>
          {/* Package Routes */}
          <Route path="/add_product" element={<AddProduct/>} />
          <Route path="/products" element={<AllProducts/>} />
          <Route path="/product_edit/:id" element={<EditProduct/>} />
          {/* Stage Routes */}
          <Route path="/add_stage" element={<AddStage/>}/>
          <Route path="/stages" element={<AllStages/>} />
          <Route path='/stage_edit/:id'element={< EditStage/>}/>
          {/* Sub Stages */}
          <Route path="/add_sub_stage" element={<AddSubStage/>} />
          <Route path="/sub_stages" element={<SubStages/>} />
          <Route path="/sub_stage_edit/:id" element={<EditSubStage/>} />

          {/* Services */}

            <Route path="/add_service" element={<AddService/>} />
            <Route path="/services" element={<Services />} />
            <Route path="/service_edit/:id" element={<EditService />} />

          {/* Services Type Routes */}
          <Route path="/add_service_category" element={<Addservicecategory/>} />
          <Route path="/service_categories/" element={<Servicecategory/>} />
          <Route path="/service_category_edit/:id" element={<EditServiceCategory />}/>
 
    {/* Installment types routes */}

          <Route path="/add_installment_type" element={<Addinstalltypes/>} /> 
          <Route path="/installments" element={<Installtypes/>} />
          <Route path="/installment_type_edit/:id" element={<Editinstalltypes/>} />
            {/*User Routes  */}
            {/* counsellor forms */}
            <Route path="/add_user_by_counsellor" element={<AddUserByCousellor/>} />
            <Route path="/add_onboarding_details/:id" element={<AddClientOnBoardDetails/>} />
            {/* <Route path="/onboarding" element={<Onboarding user_agreed={0}/>} /> */}
            <Route path="/onboarding_edit/:id" element={<EditOnBoardDetails/>}/>
            <Route path="/onboarding_multiple" element={<AddOnboardingClientMultiple/>} />
            <Route path='/users' element={< Users/>} />
            <Route path='/user_edit/:id' element={<EditUser/>}/>
            <Route path='/user_view/:id' element={<SeeUser/>}/>
          
           {/* </AllRoutes> <Route path="/seemore/:id" element={</>} /> */}


            {/* Specialities Routes */}
            <Route path='/add_speciality' element={<AddSpeciality/>} />
            <Route path="/specialities" element={<Specialities/>} />
            <Route path="/speciality/:id" element={<EditSpeciality/>} />


            {/* SubSpeciality */}
            <Route path='/add_sub_speciality' element={<AddSubSpeciality/>} />
            <Route path="/sub_specialities" element={<SubSpeciality/>} />
            <Route path="/sub_speciality/:id" element={<EditSubSpeciality/>} />   

            {/* Welcome kit  */}
            <Route path ="/add_welcomekit" element={<AddWelcomeKitType/> }/>
            <Route path="/welcomekits" element={<Welcomekittype/>}/>
            <Route path="/welcome_kit_edit/:id" element={<EditWelcomekitType/>} />

            {/* Discount types */}
            <Route path="/add_discount_type" element={<AddDiscountType/>} />
            <Route path="/discount_types" element={<DiscountTypes/>} />
            <Route path="/discount_type/:id" element={<EditDiscountType/>} />
            
            {/* Vendor Details */}
            <Route path="/add_vendor" element={<AddVendor/>} />
            <Route path="/vendors" element={<Vendor/>} />
            <Route path="/vendor_edit/:id" element={<EditVendor/>} />
            
      {/* Training Routes */}
          <Route path="/add_service_categoriser" element={<AddServiceCategoriser/>}/>
          <Route path="/service_categorisers" element={<ServiceCategorisers/>}/>
          <Route path="/edit_service_categoriser/:id" element={<EditServiceCategoriser/>}  />
            {/* Training Details  Routes */}
           <Route path="/add_training_details/:id" element={<AddTrainingDetails/>}/>
           <Route path="/edit_training_details/:id" element={<EditTrainingDetails/>} />
           <Route path="/add_multiple_training" element={<AddMultipleTraining/>} />
            

          {/* Documents Routes */}
          <Route path="/document_add/:id" element={<AddDocuments/>}/> 
          <Route path ="document_edit/:id" element={<EditDocuments/>}/>
          <Route path="/user_additional_documents" element={<UserExtraDocuments/>}/>
          <Route path="/add_user_additional_documents" element={<AddUserDocuments/>}/>

     

          {/* Test booking Crud */}
          <Route path="/add_test_booking" element={<AddTestBooking/>} />
          <Route path="/add_multiple_test_booking" element={<AddMultipleTestBookingDetails/>}/>
          <Route path="/testbookings" element={<TestBookings/>}/ >
          <Route path="/testbookedit/:id" element={<EditTestBooking/>} / >


            {/* Test Booking Details */}
            <Route path="/add_test_booking_detail/:id" element={<AddTestBookingDetails/>}/>
            <Route path="edit_test_booking_detail/:id" element={ <EditTestBookingDetails/>}   />
           
             {/* Registrations Crud */}
          <Route path="/add_registrations" element={<AddRegsitrations/>} />
          <Route path="/add_multiple_registrations" element={<AddMultipleRegistrations/>} />
          <Route path="/registrations" element={<Registrations/>}/ >
          <Route path="/registrationsedit/:id" element={<EditRegistrations/>} / >
          <Route path="/user_registrations" element={<UserRegistrationDetails/>} />

        
         {/* Registrations Details */}
            <Route path="/add_registration_detail/:id" element={<AddRegistrationDetails/>}/>
            <Route path="/edit_registration_detail/:id" element={ <EditRegistrationDetails/>}   />
           
         {/* Training And Subscritpion Crud */}
         <Route path="/add_training_subscriptions" element={<AddTrainingAndSubscriptions/>} />
          <Route path="/training_subscriptions" element={<TrainingAndSubscriptions/>}/ >
          <Route path="/training_subscriptions_edit/:id" element={<EditTrainingAndSubscriptions/>} / >
          <Route path="/user_training_and_subscriptions" element={<UserTrainingAndSubscriptionDetails/> } />

        {/*Training And Subscription   */}
        <Route path="/add_training_and_subscription/:id" element={<AddTrainingAndSubscriptionDetails/>} />
        <Route path='/add_multiple_training_and_subscription/' element={<AddMultipleTrainingAndSubscriptionDetails/>}/>
        <Route path="/edit_training_and_subscription/:id" element={<EditTrainingAndSubscriptionDetails/>} />

          {/* Courses Allocated Crud */}
          <Route path="/add_courses_allocated" element={<AddCoursesAllocated/>} />
          <Route path='/add_multiple_courses_allocated' element={<AddMultipleCourseAllocatedDetails/>}/>
          <Route path="/course_allocated" element={<CoursesAllocated/>}/ >
          <Route path="/course_allocated_edit/:id" element={<EditCoursesAllocated/>} / >
          <Route path="/users_courses_allocated" element={<UserCoursesAllocatedDetails/>} />

          {/* service Category Type Routes */}

          <Route path="/add_service_category_type" element={<AddServiceCategoryType/>} />
          <Route path="/service_category_types" element={<ServiceCategoryType/>} />
          <Route path="/service_category_type_edit/:id" element={<EditServiceCategoryType/>} / >
          
          {/* Course Allocated Details  Routes */}
          <Route path="/add_course_allocated_details/:id" element={<AddCourseAllocatedDetails/>} />
          <Route path="/edit_course_allocated_details/:id" element={<EditCourseAllocatedDetails/>} />

          {/* PortFolio Routes */} 
          <Route path="/add_portfolio_form/:id" element={<AddPortfolio/>} />
          <Route path="/edit_portfolio_form/:id" element={<EditPortfolio/>} />
          <Route path="/add_multiple_portfolio" element={<AddMultiplePortFolio/>}/>
          <Route path="/user_portfolios" element={<UserPortFolio/>}/>
          
           {/*  User Login */}
           <Route path="/userlogin" element={<UserLogin/>}/>
           <Route path="/userpath" element={<ClientDashboard/>}/>
           <Route path="/user_profile_details" element={<UserDetails/>}/>
           <Route path="/user_profile" element={<UserProfile />} />
           <Route path="/user_address" element={<UserAddress/>} />
           <Route path='/pmq_details' element={<PMQDetails/>} />
           <Route path="/language_test" element={<UserLanguageTest/>} />
           <Route path="/gmc_and_intenship" element={<GMC_And_Intenship/>}/>
           <Route path="/employment_and_speciality" element={<EmploymentAndSpecialist />} />
           <Route path="/documents/" element={<UserDocuments/>} />
           <Route path="/user_declaration" element={<UserDeclaration/>} />
           <Route path="/support"  element={<Support/>} />


           {/*  user side bar links */}
            <Route path="/user_training" element={<UserTrainingDetails/>}/>
            <Route path='/user_test_bookings' element={<UserTestBookingDetails/>}/>
            

           {/* userprofileedit by counsellor */}

           <Route path="/user_profile_edit/:id" element={<UserProfileEdit/>}/>
           <Route path="/user_address_edit/:id" element={<UserAddressEdit/>}/>
           <Route path="/user_pmq_details_edit/:id" element={<UserPMQEdiT/>} />
           <Route path="/user_language_test_edit/:id" element={<UserLanguageTestEdiT/>} />
           <Route path="/user_gmc_and_internship_edit/:id" element={<UserGMCAndInternshipEdiT/>} />
           <Route path="/user_employment_and_speciality_edit/:id" element={<UserEmploymentAndSpecialityEdiT/>} />
           <Route path="/personal_notes/:id" element={<PersonalNotesEdit/>} />
           
            {/* incharges */}

           <Route path="/add_incharge" element={<AddIncharge/>}/> 
           <Route path="/incharges" element={<Incharge/>}/> 
           <Route path="/edit_incharge/:id" element={<EditIncharge/>}/>
           {/* Task Scheduling routes */}
           <Route path='/add_task_schedule' element={<AddTaskScheduler/>} />
           <Route path='/edit_task_schedule/:id' element={<EditTaskScheduler/>} />
           <Route path='/task_schedules' element={<TaskSchedulers/>} />

           <Route path='/onboardinglist' element={<OnboardingList/>} />
           <Route path='/traininglist' element={<TrainingList/>}/>
           <Route path='/bookinglist' element={<TestBookingList/>}/>
           <Route path='/registration_list' element={<RegistrationList/>} />
           <Route path='/courses_allocated_list' element={<CoursesAllocatedList/>} />
           <Route path='/training_and_subscription_list' element={<TrainingSubscriptionList/>}/>
           <Route path="/portfolio_list" element={<PortFolioList/>} />

           <Route path="/add_tier4_visa/:id" element={<AddTier4Visa/>} />
           <Route path="/add_multiple_tier4_visa" element={<AddMultipleTier4Visa/>}/>
           <Route path="edit_tier4_visa/:id" element={<EditTier4Visa/>}/>
           <Route path="/tier4visa_list" element={<Tier4VisaList/>} />
           <Route path="/user_tier4visa" element={<UserTier4Visa/>}/>

           <Route path="/add_travel_and_accomdation/:id" element={<AddTravelAndAccomdation/>}/>
           <Route path="/add_multilpe_travel_and_accomdation" element={<AddMultipleTravelAndAccomodation/>}/>
           <Route path="/edit_travel_and_accomdation/:id" element={<EditTravelAndAccomdation/>}/>
           <Route path="/travel_and_accomdation_list" element={<TravelAndAccomdationList/>} />
           <Route path="/user_travel_and_accomdations" element={<UserTravelAndAccomdation/>}/>

           <Route path="/add_cab/:id" element={<AddCab/>} />
           <Route path="/add_multiple_cabs" element={<AddMultipleCab/>}/>
           <Route path='/edit_cab/:id'element={<EditCab/>}/>
           <Route path="/cablist" element={<CabList/>}/> 
           <Route path="/usercab_details" element={<UserCab/>}/>

           <Route path="/add_gmc_registration_details/:id" element={<AddGMCRegistration/>}/>
           <Route path="/add_multiple_gmc_registration_details" element={<AddMultipleGMCRegistration/>}/>
           <Route path="/edit_gmc_registration_details/:id" element={<EditGMCRegistration/>}/>
           <Route path="/gmc_registration_list" element={<GMCAndRegistrationList/>} />
           <Route path="/user_gmc_registrations" element={<UserGMCAndRegistrationDetails/>}/>

            {/* <Route path="/demo/:id" element={<UserInfo/>}/> */}
           
         
        </AllRoutes>
    </LoginProvider>
  )
}
  
export default Routes