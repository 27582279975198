import React,{useState,useEffect} from 'react'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link} from 'react-router-dom';
import axios from 'axios';
import UserSideBar from '../../pages/UserSideBar/UserSideBar';
import UserHeader from '../../pages/UserHeader/UserHeader';
import {UserData} from '../../@types/usertype';
import { CollegeData } from '../../@types/collegedata';
import validator from 'validator';
import server from '../Utils';
import Loader from './Loader';
import UserProgressBar from '../UserProgressBar';
import Swal from 'sweetalert2';

const UserLanguageTest = () => {
  var loggedIn=localStorage.getItem('loggedIn');
  const [first_name,setfirstName]=useState<string>("");
  const [last_name,setlastName]=useState<string>('');
  const [user_email,setUserEmail]=useState<string>('')
  const [language_test_taken,setLanguageTestTaken]=useState<number>(0);
  const [language_test_type,setLanguageTestType]=useState<number>(0);
  const [language_test_date,setLanguageTestDate]=useState<string>("");
  const [ielts_trf_number,setIELTStrfNo]=useState<string>("");
  const [ielts_listening_score,setIELTSListeningScore]=useState<string>('');
  const [ielts_reading_score,setIELTSReadingScore]=useState<string>('');
  const [ielts_writing_score,setIELTSWritingScore]=useState<string>('');
  const [ielts_speaking_score,setIELTSSpeakingScore]=useState<string>('');
  const [ielts_overall_score,setIELTSOverallScore]=useState<string>('');
  const [oet_candidate_number,setOetCandidateNumber]=useState<string>('');
  const [oet_listening_score,setOetListeningScore]=useState<string>('');
  const [oet_reading_score,setOetReadingScore]=useState<string>('');
  const [oet_writing_score,setOetWritingScore]=useState<string>('');
  const [oet_speaking_score,setOetSpeakingScore]=useState<string>('');
  const [oet_overall_score,setOetOverallScore]=useState<string>('');
  const [loading, setLoading] = useState(false);
  const [form_progress,setFormProgress]=useState<number>(0);
  const [submission,setSubmission]=useState<boolean>(false);

  if(loggedIn!=="true")
  {
    window.location.href="/userlogin";
  }
  useEffect(() => {
    let userid= localStorage.getItem('ClientID');
    setLoading(true)
  server.get(`/operationuser/${userid}`)
  .then((response)=>{
    console.log(response)
    if(response.data.code===200){
      setfirstName(response.data.user.first_name)
      setlastName(response.data.user.last_name)
      setUserEmail(response.data.user.user_email)
      setLanguageTestTaken(parseInt(response.data.user.language_test_taken))
      setLanguageTestType(parseInt(response.data.user.language_test_type))
      setLanguageTestDate(response.data.user.date_of_test)
      setIELTStrfNo(response.data.user.ielts_trf_number)
      if(response.data.user.ielts_trf_number===null||response.data.user.ielts_trf_number===""){
        setIELTStrfNo("")
      }
      setIELTSListeningScore(response.data.user.ielts_listening_score)
      setIELTSReadingScore(response.data.user.ielts_reading_score)
      setIELTSWritingScore(response.data.user.ielts_writing_score)
      setIELTSSpeakingScore(response.data.user.ielts_speaking_score)
      setIELTSOverallScore(response.data.user.ielts_overall_score)
      
      if(response.data.user.oet_candidate_number===null||response.data.user.oet_candidate_number===""){
        setOetCandidateNumber('')
      }else{
        setOetCandidateNumber(response.data.user.oet_candidate_number)
      }
      setOetListeningScore(response.data.user.oet_listening_score)
      setOetReadingScore(response.data.user.oet_reading_score)
      setOetWritingScore(response.data.user.oet_writing_score)
      setOetSpeakingScore(response.data.user.oet_speaking_score)
      setOetOverallScore(response.data.user.oet_overall_score)
      if(response.data.user.form_progress!==null){
        setFormProgress(response.data.user.form_progress)
      }else{
        setFormProgress(0)
      }
      setLoading(false)
    }
})
  }, []);
  
  

  const LanguageTestDetails=(e:React.FormEvent<HTMLFormElement>)=>{
    e.preventDefault();
    let userid= localStorage.getItem('ClientID');
   
 

    if((language_test_taken===0))
    {
      toast('Select whether test taken');
    }else if(language_test_taken===1 && language_test_type===0)
    {
      toast('Choose the language test type');
    }else if(language_test_taken===1 &&  language_test_type===1 && language_test_date===null){
      toast('Enter the date of the test');
    }else if(language_test_taken===1 && language_test_type===1 && language_test_date===""){
      toast('Enter the date of the test');
    }
    else if(language_test_taken===1 && language_test_type===1 && ielts_trf_number==="")
    {
      toast('Enter the ielts trf number');
    }else if(language_test_taken===1 && language_test_type===1 && ielts_trf_number.length!==16){
      toast('Enter a valid 16 digit TRF number');
    }
    else if(language_test_taken===1 && language_test_type===1 && ielts_listening_score===""){
      toast('Choose IElTS Listening Score')
    }else if(language_test_taken===1 && language_test_type===1 && ielts_listening_score===null){
      toast('Choose IElTS Listening Score')
    }else if(language_test_taken===1 && language_test_type===1 && parseInt(ielts_listening_score)===0){
      toast('Choose IElTS Listening Score')
    } else if(language_test_taken===1 && language_test_type===1 && ielts_reading_score===""){
      toast('Select IElTS Reading Score')
    } else if(language_test_taken===1 && language_test_type===1 && ielts_reading_score===null){
      toast('Select IElTS Reading Score')
    }else if(language_test_taken===1 && language_test_type===1 && parseInt(ielts_reading_score)===0){
      toast('Select IElTS Reading Score')
    }
    else if(language_test_taken===1 && language_test_type===1 && ielts_writing_score===""){
      toast('Select IElTS Writing Score')
    }else if(language_test_taken===1 && language_test_type===1 && ielts_writing_score===null){
      toast('Select IElTS Writing Score')
    }else if(language_test_taken===1 && language_test_type===1 && parseInt(ielts_writing_score)===0){
      toast('Select IElTS Writing Score')
    }
    else if(language_test_taken===1 && language_test_type===1 && ielts_speaking_score===""){
      toast('Select IElTS Speaking Score')
    }else if(language_test_taken===1 && language_test_type===1 && ielts_speaking_score===null){
      toast('Select IElTS Speaking Score')
    }else if(language_test_taken===1 && language_test_type===1 && parseInt(ielts_speaking_score)===0){
      toast('Select IElTS Speaking Score')
    }
    else if(language_test_taken===1 && language_test_type===1 && ielts_overall_score===""){
      toast('Select IElTS Overall Score')
    }else if(language_test_taken===1 && language_test_type===1 && ielts_overall_score===null){
      toast('Select IElTS Overall Score')
    }else if(language_test_taken===1 && language_test_type===1 && parseInt(ielts_overall_score)===0){
      toast('Select IElTS Overall Score')
    }
    else if(language_test_taken===1 && language_test_type===2 && oet_candidate_number.length!==9){
      toast('Enter a valid 9 digit OET number');
    }
    else if(language_test_taken===1 && language_test_type===2 && oet_candidate_number===""){
     
      toast('Enter the oet candidate number with 9 digits !')
    } else if(language_test_taken===1 && language_test_type===2 && oet_candidate_number===null){
      toast('Enter the oet candidate number with 9 digits !')
    }else if(language_test_taken===1 && language_test_type===2 && oet_listening_score===""){
      toast('Enter Proper OET Listening Score with 3 digits')
    }
    else if(language_test_taken===1 && language_test_type===2 && oet_listening_score===null){
      toast('Enter Proper OET Listening Score with 3 digits')
    }else if(language_test_taken===1 && language_test_type===2 && oet_listening_score.toString().length!==3){
      toast('Enter Proper OET Listening Score with 3 digits')
    }  
     else if(language_test_taken===1 && language_test_type===2 && oet_reading_score===""){
      toast('Enter Proper OET Reading Score with 3 digits')
    }else if(language_test_taken===1 && language_test_type===2 && oet_reading_score===null){
      toast('Enter Proper OET Reading Score with 3 digits')
    }else if(language_test_taken===1 && language_test_type===2 && oet_reading_score.toString().length!==3){
      toast('Enter Proper OET Reading Score with 3 digits')
    }
    else if(language_test_taken===1 && language_test_type===2 && oet_writing_score===""){
      toast('Enter Proper OET Writing Score with 3 digits')
    }else if(language_test_taken===1 && language_test_type===2 && oet_writing_score===null){
      toast('Enter Proper OET Writing Score with 3 digits')
    }else if(language_test_taken===1 && language_test_type===2 && oet_writing_score.toString().length!==3){
      toast('Enter Proper OET Writing Score with 3 digits!')
    } else if(language_test_taken===1 && language_test_type===2 && oet_speaking_score===""){
      toast('Enter Proper OET Speaking Score with 3 digits')
    }
    else if(language_test_taken===1 && language_test_type===2 && oet_speaking_score===null){
      toast('Enter Proper OET Speaking Score with 3 digits')
    }else if(language_test_taken===1 && language_test_type===2 && oet_speaking_score.toString().length!==3){
      toast('Enter Proper OET Speaking Score with 3 digits!')
    } else if(language_test_taken===1 && language_test_type===2 && oet_overall_score===""){
      toast('Enter Proper OET Overall Score with 3 digits')
    }else if(language_test_taken===1 && language_test_type===2 && oet_overall_score===null){
      toast('Enter Proper OET Overall Score with 3 digits')
    }else if(language_test_taken===1 && language_test_type===2 && oet_overall_score.toString().length!==3){
      toast('Enter Proper OET Speaking Score with 3 digits!')
    }

else{

      var form = document.getElementById('language_test_details_form') as HTMLFormElement;
      var formData= new FormData(form)
      setLoading(true)
      setSubmission(true)
      server.post(`/update_language_test_details/${userid}`,formData)
      .then((response)=>{
        if(response.data.code===200){
          setLoading(false)
          toast(response.data.message)
          if(loading===false){
          setTimeout(function(){ window.location.href="/gmc_and_intenship"; }, 100);
          }
          
        }else{
          setLoading(false)
          toast(response.data.message)
        }
      })
    }

  }
  const  saveDetails=()=>{
    let userid= localStorage.getItem('ClientID');
   
 

    if((language_test_taken===0))
    {
      toast('Select whether test taken');
    }else if(language_test_taken===1 && language_test_type===0)
    {
      toast('Choose the language test type');
    }else if(language_test_taken===1 &&  language_test_type===1 && language_test_date===null){
      toast('Enter the date of the test');
    }else if(language_test_taken===1 && language_test_type===1 && language_test_date===""){
      toast('Enter the date of the test');
    }
    else if(language_test_taken===1 && language_test_type===1 && ielts_trf_number==="")
    {
      toast('Enter the ielts trf number');
    }else if(language_test_taken===1 && language_test_type===1 && ielts_trf_number.length!==16){
      toast('Enter a valid 16 digit TRF number');
    }
    else if(language_test_taken===1 && language_test_type===1 && ielts_listening_score===""){
      toast('Choose IElTS Listening Score')
    }else if(language_test_taken===1 && language_test_type===1 && ielts_listening_score===null){
      toast('Choose IElTS Listening Score')
    }else if(language_test_taken===1 && language_test_type===1 && parseInt(ielts_listening_score)===0){
      toast('Choose IElTS Listening Score')
    } else if(language_test_taken===1 && language_test_type===1 && ielts_reading_score===""){
      toast('Select IElTS Reading Score')
    } else if(language_test_taken===1 && language_test_type===1 && ielts_reading_score===null){
      toast('Select IElTS Reading Score')
    }else if(language_test_taken===1 && language_test_type===1 && parseInt(ielts_reading_score)===0){
      toast('Select IElTS Reading Score')
    }
    else if(language_test_taken===1 && language_test_type===1 && ielts_writing_score===""){
      toast('Select IElTS Writing Score')
    }else if(language_test_taken===1 && language_test_type===1 && ielts_writing_score===null){
      toast('Select IElTS Writing Score')
    }else if(language_test_taken===1 && language_test_type===1 && parseInt(ielts_writing_score)===0){
      toast('Select IElTS Writing Score')
    }
    else if(language_test_taken===1 && language_test_type===1 && ielts_speaking_score===""){
      toast('Select IElTS Speaking Score')
    }else if(language_test_taken===1 && language_test_type===1 && ielts_speaking_score===null){
      toast('Select IElTS Speaking Score')
    }else if(language_test_taken===1 && language_test_type===1 && parseInt(ielts_speaking_score)===0){
      toast('Select IElTS Speaking Score')
    }
    else if(language_test_taken===1 && language_test_type===1 && ielts_overall_score===""){
      toast('Select IElTS Overall Score')
    }else if(language_test_taken===1 && language_test_type===1 && ielts_overall_score===null){
      toast('Select IElTS Overall Score')
    }else if(language_test_taken===1 && language_test_type===1 && parseInt(ielts_overall_score)===0){
      toast('Select IElTS Overall Score')
    }
    else if(language_test_taken===1 && language_test_type===2 && oet_candidate_number.length!==9){
      toast('Enter a valid 9 digit OET number');
    }
    else if(language_test_taken===1 && language_test_type===2 && oet_candidate_number===""){
     
      toast('Enter the oet candidate number with 9 digits !')
    } else if(language_test_taken===1 && language_test_type===2 && oet_candidate_number===null){
      toast('Enter the oet candidate number with 9 digits !')
    }else if(language_test_taken===1 && language_test_type===2 && oet_listening_score===""){
      toast('Enter Proper OET Listening Score with 3 digits')
    }
    else if(language_test_taken===1 && language_test_type===2 && oet_listening_score===null){
      toast('Enter Proper OET Listening Score with 3 digits')
    }else if(language_test_taken===1 && language_test_type===2 && oet_listening_score.toString().length!==3){
      toast('Enter Proper OET Listening Score with 3 digits')
    }  
     else if(language_test_taken===1 && language_test_type===2 && oet_reading_score===""){
      toast('Enter Proper OET Reading Score with 3 digits')
    }else if(language_test_taken===1 && language_test_type===2 && oet_reading_score===null){
      toast('Enter Proper OET Reading Score with 3 digits')
    }else if(language_test_taken===1 && language_test_type===2 && oet_reading_score.toString().length!==3){
      toast('Enter Proper OET Reading Score with 3 digits')
    }
    else if(language_test_taken===1 && language_test_type===2 && oet_writing_score===""){
      toast('Enter Proper OET Writing Score with 3 digits')
    }else if(language_test_taken===1 && language_test_type===2 && oet_writing_score===null){
      toast('Enter Proper OET Writing Score with 3 digits')
    }else if(language_test_taken===1 && language_test_type===2 && oet_writing_score.toString().length!==3){
      toast('Enter Proper OET Writing Score with 3 digits!')
    } else if(language_test_taken===1 && language_test_type===2 && oet_speaking_score===""){
      toast('Enter Proper OET Speaking Score with 3 digits')
    }
    else if(language_test_taken===1 && language_test_type===2 && oet_speaking_score===null){
      toast('Enter Proper OET Speaking Score with 3 digits')
    }else if(language_test_taken===1 && language_test_type===2 && oet_speaking_score.toString().length!==3){
      toast('Enter Proper OET Speaking Score with 3 digits!')
    } else if(language_test_taken===1 && language_test_type===2 && oet_overall_score===""){
      toast('Enter Proper OET Overall Score with 3 digits')
    }else if(language_test_taken===1 && language_test_type===2 && oet_overall_score===null){
      toast('Enter Proper OET Overall Score with 3 digits')
    }else if(language_test_taken===1 && language_test_type===2 && oet_overall_score.toString().length!==3){
      toast('Enter Proper OET Speaking Score with 3 digits!')
    }

else{

      var form = document.getElementById('language_test_details_form') as HTMLFormElement;
      var formData= new FormData(form)
      setLoading(true)
      server.post(`/update_language_test_details/${userid}`,formData)
      .then((response)=>{
        if(response.data.code===200){
          setLoading(false)
          Swal.fire({
            title:"Details Saved!",
            text: "Language Details Saved Successfully",
            icon: "success"
          });
          
        }else{
          setLoading(false)
          toast(response.data.message)
        }
      })
    }

  }
  if (loading) {
    return <Loader />;
  }
  return (
 

    <div className='container-fluid desktop_container' >
    <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name}/>
    <div className='horizontal-menu' style={{background:"transparent"}}>

      <div className='container-fluid mt-3'>
      <div className='row my-5'>
          <div className='col-md-3 text-center px-4'><h2 className='blue-color'><b>Language Test</b></h2></div>
          <div className='col-md-9 mt-2 px-5'><UserProgressBar form_progress={form_progress}/></div>
      </div>
      </div>
      
      <div className='user_foarm_board userpath_right_pad pt-2'>
        <h3 className='blue-color fw-bold my-4'> Language Test Details</h3>
          <form className='w-100' id="language_test_details_form" onSubmit={LanguageTestDetails} >
          <input type='hidden' name="form_progress" id="form_progress" value={4}/>
            <div className='row'>
                <div className='col-md-4'>
                   <label className="blue-color fw-bold">Have you passed IELTS/OET exam?<b className="text-danger">*</b></label> 
                   <select className='form-control rounded user_input_dashboard' id="language_test_taken" name="language_test_taken" value={language_test_taken} onChange={(e)=>setLanguageTestTaken(parseFloat(e.target.value))} >
                    <option value="0">Select Choice</option> 
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                   </select>
                </div>
                {language_test_taken===1?<div className='col-md-4'>
                <label className="blue-color fw-bold">Language test type<b className="text-danger">*</b></label> 
                <select className='form-control rounded user_input_dashboard' id="language_test_type" name="language_test_type" value={language_test_type} onChange={(e)=>setLanguageTestType(parseFloat(e.target.value))} >
                    <option value="0">Select Test Type</option> 
                    <option value="1">IELTS</option>
                    <option value="2">OET</option>
                </select>
                </div>:""}
                {language_test_taken===1?<div className='col-md-4'>
                <label className="blue-color fw-bold">Test Date<b className="text-danger">*</b></label> 
                  <input type="date" className='form-control rounded user_input_dashboard' name="language_test_date" id="language_test_date" value={language_test_date} onChange={(e)=>setLanguageTestDate(e.target.value)} />
                </div>:""}
                
                
            </div>
           {language_test_taken===1 && language_test_type===1?<div className='row  mt-5'>
                <div className='col-md-2'>
                   <label className="blue-color fw-bold">TRF Number<b className="text-danger">*</b></label> 
                   <input type="text"  className='form-control rounded user_input_dashboard' name="ielts_trf_number" id="ielts_trf_number" value={ielts_trf_number} onChange={(e)=>setIELTStrfNo(e.target.value)} required />
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold"> Listening Score<b className="text-danger">*</b></label> 
                <select className='form-control rounded user_input_dashboard' name="ielts_listening_score" id="ielts_listening_score" value={ielts_listening_score} onChange={(e)=>setIELTSListeningScore(e.target.value)}>
                  <option value="0">Choose Listening Score</option>
                  <option value="5">5</option>
                  <option value="5.5">5.5</option>
                  <option  value="6">6</option>
                  <option  value="6.5" >6.5</option>
                  <option  value="7" >7</option>
                  <option value="7.5" >7.5</option>
                  <option value="8">8</option>
                  <option value="8.5">8.5</option>
                  <option value="9">9</option>
                  <option value="9.5">9.5</option>

                </select>
                </div>
              <div className='col-md-2'>
                <label className="blue-color fw-bold">Reading Score<b className="text-danger">*</b></label> 
                <select className='form-control rounded user_input_dashboard' name="ielts_reading_score" id="ielts_reading_score" value={ielts_reading_score} onChange={(e)=>setIELTSReadingScore(e.target.value)}>
                <option value="0">Choose Reading Score</option>
                <option value="5">5</option>
                  <option value="5.5">5.5</option>
                  <option  value="6">6</option>
                  <option  value="6.5" >6.5</option>
                  <option  value="7" >7</option>
                  <option value="7.5" >7.5</option>
                  <option value="8">8</option>
                  <option value="8.5">8.5</option>
                  <option value="9">9</option>
                  <option value="9.5">9.5</option>
                </select>
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold">Writing Score<b className="text-danger">*</b></label> 
                <select className='form-control rounded user_input_dashboard' name="ielts_writing_score" id="ielts_writing_score" value={ielts_writing_score} onChange={(e)=>setIELTSWritingScore(e.target.value)} >
                <option value="0">Choose Writing Score</option>
                 <option value="5">5</option>
                  <option value="5.5">5.5</option>
                  <option  value="6">6</option>
                  <option  value="6.5" >6.5</option>
                  <option  value="7" >7</option>
                  <option value="7.5" >7.5</option>
                  <option value="8">8</option>
                  <option value="8.5">8.5</option>
                  <option value="9">9</option>
                  <option value="9.5">9.5</option>
                </select>
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold">Speaking Score<b className="text-danger">*</b></label> 
                <select className='form-control rounded user_input_dashboard' name="ielts_speaking_score" id="ielts_speaking_score" value={ielts_speaking_score} onChange={(e)=>setIELTSSpeakingScore(e.target.value)} >
                <option value="0">Choose Speaking Score</option>
                <option value="5">5</option>
                  <option value="5.5">5.5</option>
                  <option  value="6">6</option>
                  <option  value="6.5" >6.5</option>
                  <option  value="7" >7</option>
                  <option value="7.5" >7.5</option>
                  <option value="8">8</option>
                  <option value="8.5">8.5</option>
                  <option value="9">9</option>
                  <option value="9.5">9.5</option>
                </select>
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold">Overall Score<b className="text-danger">*</b></label> 
                <select className='form-control rounded user_input_dashboard' name="ielts_overall_score" id="ielts_overall_score" value={ielts_overall_score} onChange={(e)=>setIELTSOverallScore(e.target.value)} >
                <option value="0">Choose Overall Score</option>
                <option value="5">5</option>
                  <option value="5.5">5.5</option>
                  <option  value="6">6</option>
                  <option  value="6.5" >6.5</option>
                  <option  value="7" >7</option>
                  <option value="7.5" >7.5</option>
                  <option value="8">8</option>
                  <option value="8.5">8.5</option>
                  <option value="9">9</option>
                  <option value="9.5">9.5</option>
                </select>
                </div>
                
                
            </div>:language_test_taken===1 && language_test_type===2?<div className='row mt-5'>
                <div className='col-md-2'>
                   <label className="blue-color fw-bold">OET Candidate Number<b className="text-danger">*</b></label> 
                   <input type="number"  className='form-control rounded user_input_dashboard'name="oet_candidate_number" id="oet_candidate_number" value={oet_candidate_number} onChange={(e)=>setOetCandidateNumber(e.target.value)} />
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold"> Listening score<b className="text-danger">*</b></label> 
                <input type="number"className='form-control rounded user_input_dashboard' name="oet_listening_score" id="oet_listening_score" minLength={3} maxLength={3} value={oet_listening_score} onChange={(e)=>setOetListeningScore(e.target.value)} />
                </div>
              <div className='col-md-2'>
                <label className="blue-color fw-bold">Reading Score<b className="text-danger">*</b></label> 
                <input type="number" className='form-control rounded user_input_dashboard'name="oet_reading_score" id="oet_reading_score" value={oet_reading_score} onChange={(e)=>setOetReadingScore(e.target.value)} />
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold">Writing Score<b className="text-danger">*</b></label> 
                <input type="number"className='form-control rounded user_input_dashboard' name="oet_writing_score" id="oet_writing_score" value={oet_writing_score} onChange={(e)=>setOetWritingScore(e.target.value)} />
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold">Speaking Score<b className="text-danger">*</b></label> 
                <input type="number"className='form-control rounded user_input_dashboard' name="oet_speaking_score" id="oet_speaking_score" value={oet_speaking_score} onChange={(e)=>setOetSpeakingScore(e.target.value)} />
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold">Overall Score<b className="text-danger">*</b></label> 
                <input type="number" className='form-control rounded user_input_dashboard' name="oet_overall_score" id="oet_overall_score" value={oet_overall_score} onChange={(e)=>setOetOverallScore(e.target.value)} />
                </div>
              
                
                
            </div>:""}
           
            <div className='my-5 user_align_mobile'><Link to="/pmq_details" className='btn bg-blue text-white'>Previous</Link>&nbsp;&nbsp;&nbsp;<button type="button" onClick={saveDetails} className='btn bg-blue text-white'>Save</button>&nbsp;&nbsp;&nbsp;<button type="submit" disabled={submission === true} className='btn bg-blue text-white'>Save & Next</button></div>
            <ToastContainer/>
         </form>

        </div>

    </div>
  
</div>
      // <div className='container-fluid' >
      //         <div className='headerbar' style={{width:"100%",left:"0"}}>
      //     <UserHeader />
      // <h4 className='text-danger text-center'> Language Test Details</h4>
      // <hr className='bg-danger px-1' />
      //   <div className='jumbotron pt-2 mx-3'>
      //     <form className='w-100' id="language_test_details_form" onSubmit={LanguageTestDetails} >
         
      //       <div className='row'>
      //           <div className='col-md-4'>
      //              <label className="blue-color fw-bold">Have you passed IELTS/OET exam?<b className="text-danger">*</b></label> 
      //              <select className='form-control rounded user_input_dashboard' id="language_test_taken" name="language_test_taken" value={language_test_taken} onChange={(e)=>setLanguageTestTaken(parseFloat(e.target.value))} >
      //               <option value="0">Select Choice</option> 
      //               <option value="1">Yes</option>
      //               <option value="2">No</option>
      //              </select>
      //           </div>
      //           {language_test_taken===1?<div className='col-md-4'>
      //           <label className="blue-color fw-bold">Language test type<b className="text-danger">*</b></label> 
      //           <select className='form-control rounded user_input_dashboard' id="language_test_type" name="language_test_type" value={language_test_type} onChange={(e)=>setLanguageTestType(parseFloat(e.target.value))} >
      //               <option value="0">Select Test Type</option> 
      //               <option value="1">IELTS</option>
      //               <option value="2">OET</option>
      //           </select>
      //           </div>:""}
      //           {language_test_taken===1?<div className='col-md-4'>
      //           <label className="blue-color fw-bold">Test Date<b className="text-danger">*</b></label> 
      //             <input type="date" className='form-control rounded user_input_dashboard' name="language_test_date" id="language_test_date" value={language_test_date} onChange={(e)=>setLanguageTestDate(e.target.value)} />
      //           </div>:""}
                
                
      //       </div>
      //      {language_test_taken===1 && language_test_type===1?<div className='row  mt-5'>
      //           <div className='col-md-2'>
      //              <label className="blue-color fw-bold">TRF Number<b className="text-danger">*</b></label> 
      //              <input type="text"  className='form-control rounded user_input_dashboard' name="ielts_trf_number" id="ielts_trf_number" value={ielts_trf_number} onChange={(e)=>setIELTStrfNo(e.target.value)} required />
      //           </div>
      //           <div className='col-md-2'>
      //           <label className="blue-color fw-bold"> Listening Score<b className="text-danger">*</b></label> 
      //           <select className='form-control rounded user_input_dashboard' name="ielts_listening_score" id="ielts_listening_score" value={ielts_listening_score} onChange={(e)=>setIELTSListeningScore(e.target.value)}>
      //             <option value="0">Choose Listening Score</option>
      //             <option value="5">5</option>
      //             <option value="5.5">5.5</option>
      //             <option  value="6">6</option>
      //             <option  value="6.5" >6.5</option>
      //             <option  value="7" >7</option>
      //             <option value="7.5" >7.5</option>
      //             <option value="8">8</option>
      //             <option value="8.5">8.5</option>
      //             <option value="9">9</option>
      //             <option value="9.5">9.5</option>

      //           </select>
      //           </div>
      //         <div className='col-md-2'>
      //           <label className="blue-color fw-bold">Reading Score<b className="text-danger">*</b></label> 
      //           <select className='form-control rounded user_input_dashboard' name="ielts_reading_score" id="ielts_reading_score" value={ielts_reading_score} onChange={(e)=>setIELTSReadingScore(e.target.value)}>
      //           <option value="0">Choose Reading Score</option>
      //           <option value="5">5</option>
      //             <option value="5.5">5.5</option>
      //             <option  value="6">6</option>
      //             <option  value="6.5" >6.5</option>
      //             <option  value="7" >7</option>
      //             <option value="7.5" >7.5</option>
      //             <option value="8">8</option>
      //             <option value="8.5">8.5</option>
      //             <option value="9">9</option>
      //             <option value="9.5">9.5</option>
      //           </select>
      //           </div>
      //           <div className='col-md-2'>
      //           <label className="blue-color fw-bold">Writing Score<b className="text-danger">*</b></label> 
      //           <select className='form-control rounded user_input_dashboard' name="ielts_writing_score" id="ielts_writing_score" value={ielts_writing_score} onChange={(e)=>setIELTSWritingScore(e.target.value)} >
      //           <option value="0">Choose Writing Score</option>
      //            <option value="5">5</option>
      //             <option value="5.5">5.5</option>
      //             <option  value="6">6</option>
      //             <option  value="6.5" >6.5</option>
      //             <option  value="7" >7</option>
      //             <option value="7.5" >7.5</option>
      //             <option value="8">8</option>
      //             <option value="8.5">8.5</option>
      //             <option value="9">9</option>
      //             <option value="9.5">9.5</option>
      //           </select>
      //           </div>
      //           <div className='col-md-2'>
      //           <label className="blue-color fw-bold">Speaking Score<b className="text-danger">*</b></label> 
      //           <select className='form-control rounded user_input_dashboard' name="ielts_speaking_score" id="ielts_speaking_score" value={ielts_speaking_score} onChange={(e)=>setIELTSSpeakingScore(e.target.value)} >
      //           <option value="0">Choose Speaking Score</option>
      //           <option value="5">5</option>
      //             <option value="5.5">5.5</option>
      //             <option  value="6">6</option>
      //             <option  value="6.5" >6.5</option>
      //             <option  value="7" >7</option>
      //             <option value="7.5" >7.5</option>
      //             <option value="8">8</option>
      //             <option value="8.5">8.5</option>
      //             <option value="9">9</option>
      //             <option value="9.5">9.5</option>
      //           </select>
      //           </div>
      //           <div className='col-md-2'>
      //           <label className="blue-color fw-bold">Overall Score<b className="text-danger">*</b></label> 
      //           <select className='form-control rounded user_input_dashboard' name="ielts_overall_score" id="ielts_overall_score" value={ielts_overall_score} onChange={(e)=>setIELTSOverallScore(e.target.value)} >
      //           <option value="0">Choose Overall Score</option>
      //           <option value="5">5</option>
      //             <option value="5.5">5.5</option>
      //             <option  value="6">6</option>
      //             <option  value="6.5" >6.5</option>
      //             <option  value="7" >7</option>
      //             <option value="7.5" >7.5</option>
      //             <option value="8">8</option>
      //             <option value="8.5">8.5</option>
      //             <option value="9">9</option>
      //             <option value="9.5">9.5</option>
      //           </select>
      //           </div>
                
                
      //       </div>:language_test_taken===1 && language_test_type===2?<div className='row mt-5'>
      //           <div className='col-md-2'>
      //              <label className="blue-color fw-bold">OET Candidate Number<b className="text-danger">*</b></label> 
      //              <input type="number"  className='form-control rounded user_input_dashboard'name="oet_candidate_number" id="oet_candidate_number" value={oet_candidate_number} onChange={(e)=>setOetCandidateNumber(e.target.value)} />
      //           </div>
      //           <div className='col-md-2'>
      //           <label className="blue-color fw-bold"> Listening score<b className="text-danger">*</b></label> 
      //           <input type="number"className='form-control rounded user_input_dashboard' name="oet_listening_score" id="oet_listening_score" minLength={3} maxLength={3} value={oet_listening_score} onChange={(e)=>setOetListeningScore(e.target.value)} />
      //           </div>
      //         <div className='col-md-2'>
      //           <label className="blue-color fw-bold">Reading Score<b className="text-danger">*</b></label> 
      //           <input type="number" className='form-control rounded user_input_dashboard'name="oet_reading_score" id="oet_reading_score" value={oet_reading_score} onChange={(e)=>setOetReadingScore(e.target.value)} />
      //           </div>
      //           <div className='col-md-2'>
      //           <label className="blue-color fw-bold">Writing Score<b className="text-danger">*</b></label> 
      //           <input type="number"className='form-control rounded user_input_dashboard' name="oet_writing_score" id="oet_writing_score" value={oet_writing_score} onChange={(e)=>setOetWritingScore(e.target.value)} />
      //           </div>
      //           <div className='col-md-2'>
      //           <label className="blue-color fw-bold">Speaking Score<b className="text-danger">*</b></label> 
      //           <input type="number"className='form-control rounded user_input_dashboard' name="oet_speaking_score" id="oet_speaking_score" value={oet_speaking_score} onChange={(e)=>setOetSpeakingScore(e.target.value)} />
      //           </div>
      //           <div className='col-md-2'>
      //           <label className="blue-color fw-bold">Overall Score<b className="text-danger">*</b></label> 
      //           <input type="number" className='form-control rounded user_input_dashboard' name="oet_overall_score" id="oet_overall_score" value={oet_overall_score} onChange={(e)=>setOetOverallScore(e.target.value)} />
      //           </div>
              
                
                
      //       </div>:""}
           
      //       <div className='px-5 mx-5 mt-5'><Link to="/pmq_details" className='float-left btn btn-info text-white w-25'>Previous</Link>&nbsp;&nbsp;&nbsp;<button type="submit" className='float-right btn btn-success w-25'>Next</button></div>
      //       <ToastContainer/>
      //    </form>

      //   </div>
      //     </div>
        
      
     
  //  </div>
    
  )
}

export default UserLanguageTest;