import React, { useState, useEffect } from 'react';
import { useParams, Link, redirect } from 'react-router-dom';
import moment from 'moment';
import server from '../Utils';
import Loader from '../UserDocuments/Loader';
import { training_details } from '../../@types/training_details';
import UserSideBar from '../../pages/UserSideBar/UserSideBar';
import { FaRegQuestionCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { additionaldocumentstype } from '../../@types/additionaldocumenttype';
import { ToastContainer, toast } from 'react-toastify';
import { ProjectData } from '../../@types/projecttype';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import'./index.css';
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const AddUserDocuments = () => {
    const [pmq_status, setPmQStatus] = useState<number>(0);
    const [passport_photo_input, setpassphotInput] = useState<boolean>(false);
    const [client_photo_input, setClientPhotoInput] = useState<boolean>(false);
    const [provisional_degree_certificate_input, setProvisionalDegreeCertificateInput] = useState<boolean>(false);
    const [provisional_registration_certificate_input, setProvisionalRegistrationCertificateInput] = useState<boolean>(false);
    const [final_degree_certificate_input, setFinalDegreeCertificateInput] = useState<boolean>(false);
    const [final_registration_certificate_input, setFinalRegistrationCertificateInput] = useState<boolean>(false);
    const [internship_completion_certificate_input, setInternshipCompletionCertificateInput] = useState<boolean>(false);
    const [passport_photo, setPassportPhoto] = useState<string>('');
    const [client_photo, setClientPhoto] = useState<string>('');
    const [provisional_degree_certificate, setProvisionalDegreeCertificate] = useState<string>('');
    const [final_degree_certificate, setFinalDegreeCertificate] = useState<string>('');
    const [provisional_registration_certificate, setProvisionalRegistrationCertificate] = useState<string>('');
    const [final_registration_certificate, setFinalRegistrationCertificate] = useState<string>('');
    const [internship_completion_certificate, setInternshipCompletionCertificate] = useState<string>('');
    const [personal_note, setPersnolNote] = useState<string>('');
    const [welcome_call_date, setWelcomeCallDate] = useState<string>('');
    const [welcome_call_time, setWelcomeCallTime] = useState<number>(0);
    const [certificate_names, setcertificateNames] = useState<string>('');
    const [certificate_desc, setCertificateDesc] = useState<string>('');
    const [certificate_files, setCertificateFiles] = useState<string>('');
    const [file_count, setFileCount] = useState<number>(1); // Initialize with 1 to show the first row of certificates
    const [additional_documents, setAdditonalDocuments] = useState<Array<additionaldocumentstype>>([]);
    const [languagetestcertificate, setLanguageTestCertificate] = useState<string>(''); 
    const [plab1certificate, setPlab1Certificate] = useState<string>(''); 
    const [plab2certificate, setPlab2Certificate] = useState<string>(''); 
    const [good_standing_certificate, setGoodStandingCertificate] = useState<string>(''); 
    const [first_name, setfirstName] = useState<string>('');
    const [last_name, setlastName] = useState<string>('');
    const [user_email, setUserEmail] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [user_agreed, setUserAgreed] = useState<number>(0);
    const [projects, setProjects] = useState<Array<ProjectData>>([]);
    const [project_id, setProjectId] = useState<number>(0);
    const [error, setError] = useState<string>('');
    const id = localStorage.getItem('ClientID') ?? '';
    const loggedIn = localStorage.getItem('loggedIn');
    const [submission,setSubmission]=useState<boolean>(false);
     // Initialize with 1 to show the first row of certificates

    const [files, setFiles] = useState<any[][]>([[]]); // State to manage FilePond files for each row

    useEffect(() => {
        const projectid = JSON.parse(localStorage.getItem("project_id") ?? 'null');
        if (projectid) {
            setProjectId(+projectid);
        }

        if (loggedIn !== "true") {
            window.location.href = "/userlogin";
        }

        server.get(`/operationuser/${id}`)
            .then((response) => {
                if (response.data.user.user_agreement_flag === 1) {
                    setUserAgreed(1);
                } else {
                    setUserAgreed(0);
                }
            });

        server.get(`/operation_user_view/${id}`)
            .then((response) => {
                setPmQStatus(response.data.user.pmq_status);
                setPersnolNote(response.data.user.personal_note);
            });

        server.get(`/get_documents/${id}`)
            .then((response) => {
                if (response.data.code === 200) {
                    setAdditonalDocuments(response.data.additional_documents);
                   
                }
                setLoading(false);
            });
    }, [id, loggedIn]);

    const submitEvent = (id: number) => {
        const row = document.getElementById('sub' + id) as HTMLDivElement;
        
        if (id === 1) {
            toast('Cannot delete the first row');
        } else {
            
            const updatedFiles = [...files];
            updatedFiles.splice(id-1, 1);
            console.log("updatedFiles",updatedFiles)
            setFiles(updatedFiles);
            setFileCount(file_count - 1);
            
        }
        
    };
    const certificates = [];

    for (let i = 1; i <= file_count; i++) {
        certificates.push(
            <div className='row px-2 mt-3 box-shadow p-4' style={{borderRadius:"20px"}} id={"sub" + i} key={i}>
                
                <div className='col-md-4'>
                    <label className='mx-0 my-2'>Certificate</label>
                    <select className='form-control certificate_name' id={"certificate_name" + i} name={"certificate" + i}>
                        <option value="0">Select Certificate</option>
                        <option value="1">CPD webinar</option>
                        <option value="2">Course</option>
                        <option value="3">Publication</option>
                        <option value="4">Leadership</option>
                        <option value="5">Teaching</option>
                        <option value="6">Conference</option>
                        <option value="7">Presentation</option>
                        <option value="8">Language Test Result</option>
                        <option value="9">PLAB-1 Test Result</option>
                        <option value="10">PLAB-2 Test Result</option>
                        <option value="11">Good Standing Certificate</option>
                        <option value="12">Other Certificate</option>
                    </select>
                </div>
                
                <div className='col-md-6'>
                    <label className='mx-0 my-2'>Certificate Description</label>
                    <input className='w-100 form-control certificate_desc' id={"certificate_desc" + i} name={"certificate_desc" + i} required/>
                </div>
                <div className='col-md-4 mt-3' style={{height:"20px !important"}}>
                    <label style={{margin:"0px !important"}} >Certificate File</label>
                    <FilePond
                        files={files[i - 1]}
                        onupdatefiles={fileItems => {
                            setFiles(files => {
                                const updatedFiles = [...files];
                                updatedFiles[i - 1] = fileItems.map(fileItem => fileItem.file);
                                return updatedFiles;
                            });
                        }}
                        className={"user-file-pond"}
                        allowMultiple={false}
                        name={"certificate_file" + i}
                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />
                </div>
                <div className="col-md-1">
                    {i !== 1 && <button type="button" onClick={() => submitEvent(i)} className='btn btn-danger mt-4'>-</button>}
                </div>
            </div>
        );
    }

    const addRow = () => {
        const certificate_name = document.getElementsByClassName('certificate_name') as HTMLCollectionOf<HTMLInputElement>;
        const certificate_desc = document.getElementsByClassName('certificate_desc') as HTMLCollectionOf<HTMLInputElement>;
        
        if (certificate_name[certificate_name.length - 1].value === "0") {
            toast('Select the Certificate Name at row ' + certificate_name.length);
        } else if (certificate_desc[certificate_desc.length - 1].value === "") {
            toast('Enter the Description Name at row ' + certificate_desc.length);
        }else if (files[files.length - 1].length === 0) { // Check if the last row has a file
            toast('Upload a file at row ' + certificate_desc.length);
        }
         else {
            setFileCount(file_count + 1);
            setFiles([...files, []]); // Add an empty array for the new row
            
        }
    };

    
    const addAdditionalDetails = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // setLoading(true);
        const form = document.getElementById('add_additional_file_form') as HTMLFormElement;
        const formData = new FormData(form);
        const certificate_name = document.getElementsByClassName('certificate_name') as HTMLCollectionOf<HTMLInputElement>;
        const certificate_desc = document.getElementsByClassName('certificate_desc') as HTMLCollectionOf<HTMLInputElement>;

       
        // Append FilePond files to FormData
        files.forEach((fileList, index) => {
            fileList.forEach((file, fileIndex) => {
               
                formData.append(`certificate_file${index + 1}`, file);
            });
        });
        for (let i = 0; i < certificate_name.length; i++) {
            if (certificate_name[i].value === "0") {
                toast('Select the Certificate Name at row ' + (i + 1));
                return;
            }
        }

        for (let i = 0; i < certificate_desc.length; i++) {
            if (certificate_desc[i].value === "") {
                toast('Enter the Description Name at row ' + (i + 1));
                return;
            }
        }

        for (let i = 0; i < files.length; i++) {
            if (files[i].length === 0) {
                toast('Upload a file at row ' + (i + 1));
                return;
            }
        }

        
     setSubmission(true);
        server.post(`/add_additional_documents/${id}`, formData)
            .then((response) => {
                if (response.data.code === 200) {
                    setLoading(false);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Your work has been saved",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setTimeout(() => { window.history.back(); }, 3000);
                } else {
                    setLoading(false);
                    toast(response.data.message);
                }
            });
        
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className='container-fluid desktop_container'>
            <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name} />
            <div className='horizontal-menu' style={{ background: "transparent" }}>
                <div className='container-fluid mt-4 userpath_right_pad user_foarm_board'>
                    <div className='d-flex mb-2 mt-3 prog-info'>
                        <div className='w-75 text-left'>
                            <h1><b className='blue-color'>Additional Documents</b></h1>
                        </div>
                        <div className='w-25 text-right blue-color fw-bold' style={{ fontSize: "29px" }}>
                            <FaRegQuestionCircle className='mb-1' />
                            <span className='support_hide'> Support</span>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-md-12'>
                            <form id="add_additional_file_form" className='w-100' encType='multipart/form-data' onSubmit={addAdditionalDetails}>
                                <input type="hidden" name="file_count" id="file_count" value={file_count} />
                                <input type="hidden" name="user_id" id="user_id" value={id ?? ''} />
                                <input type="hidden" name="added_by_user" id="added_by_user" value={1}/>
                                {/* <div className='text-right mr-5'></div> */}
                                {certificates}
                                <ToastContainer />
                                <div className='text-center mt-4'><button type="button" onClick={addRow} className='btn bg-blue text-white mr-3'>Add More</button><button type="submit" className='btn bg-blue text-white' disabled={submission === true}>Submit</button></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddUserDocuments;
