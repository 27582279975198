import React, { useEffect, useState } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import server from '../Utils';
import Loader from './Loader';
import { ServiceData } from '../../@types/servicetype';
import { ProductData } from '../../@types/producttype';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Select } from 'antd';

const { Option } = Select;

const Services = () => {
  const [services, setServices] = useState<ServiceData[]>([]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<ProductData[]>([]);
  const [productId, setProductId] = useState<number>(0);
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [searchText, setSearchText] = useState<string>('');

  let service_categoryid: string = JSON.parse(localStorage.getItem("project_id") || '{}');
  let id = +service_categoryid;

  useEffect(() => {
    setLoading(true);
    Promise.all([
      server.get(`/get_product/${id}`),
      server.get(`/get_services/${id}`)
    ]).then(([productResponse, servicesResponse]) => {
      setLoading(false);
      if (productResponse.data.product.length > 0) {
        setProducts(productResponse.data.product);
      } else {
        setProducts([]);
      }
      setServices(servicesResponse.data.services);
    }).catch(error => {
      setLoading(false);
      console.error('Error fetching data:', error);
    });
  }, [id]);

  const displayStages = (productId: number) => {
    setProductId(productId);
    server.get(`/get_all_services/${id}/${productId}`)
      .then((response) => {
        setServices(response.data.services);
      });
  };

  const deleteProgram = (deleteId: number) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        server.delete(`/service/${deleteId}`)
          .then((response) => {
            setServices(response.data.services);
            swalWithBootstrapButtons.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success"
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your imaginary file is safe :)",
          icon: "error"
        });
      }
    });
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = services.filter(item => {
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          const val = item[key as keyof ServiceData];

          if (typeof val === 'string' && val.toLowerCase().includes(value)) {
            return true;
          } else if (typeof val === 'number' && val.toString().includes(value)) {
            return true;
          }
        }
      }
      return false;
    });

    gridApi?.setRowData(filteredData);
  };

  const columns = [
    { headerName: 'SL No', field: 'id', sortable: true, filter: false, floatingFilter: false },
    { headerName: 'Stage Name', field: 'stage_name', sortable: true, filter: true, floatingFilter: true },
    { headerName: 'Substage Name', field: 'sub_stage_name', sortable: true, filter: true, floatingFilter: true },
    { headerName: 'Service Category', field: 'service_category_name', sortable: true, filter: true, floatingFilter: true },
    { headerName: 'Service Category Type', field: 'service_category_type', sortable: true, filter: true, floatingFilter: true },
    { headerName: 'Service Name', field: 'service_name', sortable: true, filter: true, floatingFilter: true },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 120,
      filter:false,
      sortable:false,
      cellRenderer:(params: { data: ServiceData })  => (
        <div className="row">
          <div className="col-md-6">
            <Link to={`/service_edit/${params.data.id}`}>
              <i className="fa fa-edit text-success"></i>
            </Link>
          </div>
          <div className="col-md-6">
            <i onClick={() => deleteProgram(params.data.id)} className="fa fa-trash text-danger"></i>
          </div>
        </div>
      )
    }
  ];

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='container-fluid'>
      <div className='d-flex'>
        <SideBar />
        <div className='headerbar'>
          <Header />
          <h4 className='text-danger text-center'>Services</h4>
          <hr className='bg-danger px-1' />
          
          <div className='row'>
            <div className="col-md-6">
            <Select className='w-50  my-3' value={productId} onChange={(value: number) => displayStages(value)}>
                <Option value={0}>Select Product</Option>
                {products.map((product, index) => (
                  <Option key={product.id} value={product.id}>{product.product_name}</Option>
                ))}
              </Select>
            </div>
            <div className="col-md-6">
            <Link to="/add_service" className='btn w-25 btn-outline-primary float-right my-3'>Add Service</Link>
            </div>
          </div>
          <div className='row mt-2 px-3'>
          
            <div className='col-md-12'>
             
              
              <div className='ag-theme-alpine' style={{ flex: 1, width: '100%', marginTop: '10px' }}>
                <AgGridReact
                  onGridReady={onGridReady}
                  columnDefs={columns as any} // Ensure columns are typecasted as any
                  rowData={services}
                  defaultColDef={{
                    sortable: true,
                    filter: true,
                    floatingFilter: true,
                  }}
                  pagination={true}
                  paginationPageSize={10}
                  domLayout='autoHeight'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
