import React, { useState, useEffect } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { Link, useParams } from 'react-router-dom';
import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { Select, message } from 'antd';
import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import $ from 'jquery'; 
import validator from 'validator';
import { off } from 'process';
import { type } from 'os';
import { vendortype } from '../../@types/vendortype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import { ServiceData } from '../../@types/servicetype';
import { service_categorytypedata } from '../../@types/service_categorytypedata';
import server from '../Utils';
import UserInfo from '../UserInfo';
import Loader from './Loader';
import Multiselect from 'multiselect-react-dropdown';
import { multipleusertype } from '../../@types/multipleusertype';

const AddMultipleTraining = () => {
  const [project_id, setProjectId] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [assesment_date, setAssesmentDate] = useState<string>('');
  const [service_category_id, setServiceCategoryId] = useState<number>(0);
  const [service_categories, setServiceCategories] = useState<Array<ServiceCategoryType>>([]);
  const [services, setServices] = useState<Array<ServiceData>>([]);
  const [service_id, setServiceId] = useState<number>(0);
  const [vendors, setVendors] = useState<Array<vendortype>>([]);
  const [vendor_id, setVendorId] = useState<number>(0);
  const [mode_of_training, setModeOfTraining] = useState(0);
  const [start_date, setStartDate] = useState<string>('');
  const [end_date, setEndDate] = useState<string>('');
  const [service_category_type, setCategoryTypeId] = useState<number>(0);
  const { id } = useParams();
  const userid = id;
  const [service_category_types, setServiceCategorytypes] = useState<Array<service_categorytypedata>>([]);
  const [users, setUsers] = useState<Array<multipleusertype>>([]);
  const [user_ids, setUserIds] = useState<Array<number>>([]);
  
  let admin_id: string = JSON.parse(localStorage.getItem("admin_id") || '{}');
  const [user_id, setUserId] = useState<number>();
  const [products, setProducts] = useState<Array<ProductData>>([]);
  const [product_id, setProductId] = useState<number>(0);
  const [selectedValue, setSelectedValue] = useState<Array<multipleusertype>>([]);
  const [error, setError] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    let projectid: string = JSON.parse(localStorage.getItem("project_id") || '{}');
    let id = +projectid;
    setProjectId(id);
    
    server.get(`/get_product/${id}`)
      .then((response) => {
        setProducts(response.data.product);
      });
    
    server.get(`/service_types_basedon_categories/2`)
      .then((response) => {
        if (response.data.service_category_types.length > 0) {
          setServiceCategorytypes(response.data.service_category_types);
        } else {
          setServiceCategorytypes([]);
        }
      });
  }, []);

  const getVendors = (id: number) => {
    server.get(`/vendor_on_service_types/${id}`)
      .then((response) => {
        setVendors(response.data.vendors);
      });
  };

  const getServices = (id: number) => {
    setCategoryTypeId(id);
    getVendors(id);
  
    server.get(`/service_on_category_type/${id}`)
      .then((response) => {
        setServices(response.data.services);
      });
  };

  const addTraining = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    let date1 = new Date(start_date).getTime();
    let date2 = new Date(end_date).getTime();
  
    if (product_id === 0) {
      toast('Select the product');
    } else if (selectedValue.length === 0) {
      toast('Select at least one doctor');
    } else if (service_category_type === 0) {
      toast('Select the Category Type');
    } else if (service_id === 0) {
      toast('Select Service');
    } else if (vendor_id === 0) {
      toast('Select Vendor');
    } else if (mode_of_training === 0) {
      toast('Select Mode of Training');
    } else if (start_date === null || start_date === "") {
      toast('Start Date is required');
    } else if (end_date === null || end_date === "") {
      toast('End Date is required');
    } else if (date1 > date2) {
      toast('End Date should be greater than Start Date');
    } else if (date2 === date1) {
      toast('End Date & Start Date can\'t be equal');
    } else {
      var form = document.getElementById('addtrainingdetails') as HTMLFormElement;
  
      let successCount = 0;
      let hasError = false;
  
      const promises = selectedValue.map((element) => {
        var formData = new FormData(form);
        return server.post(`/add_training/${element.id}`, formData)
          .then((response) => {
            if (response.data.code === 200) {
              successCount += 1;
            } else {
              toast("Couldn't be added for " + element.user + " because " + response.data.message);
              hasError = true;
            }
          });
      });
  
      Promise.all(promises).then(() => {
        if (!hasError && successCount === selectedValue.length) {
          toast("All trainings added successfully.");
          setTimeout(() => { window.location.href = "/traininglist"; }, 1000);
        } else {
          toast("There were errors in adding some trainings.");
        }
      });
    }
  };
  
  if (loading) {
    return <Loader />;
  }

  const onSelect = (selectedList: multipleusertype[], selectedItem: multipleusertype) => {
    setSelectedValue(selectedList);
  };

  const onRemove = (selectedList: multipleusertype[], removedItem: multipleusertype) => {
    setSelectedValue(selectedList);
  };

  const setallUsers = (id: number) => {
    setProductId(id);
    setUserId(0);
    setError(true);
    setCount(count + 1);
    setSelectedValue([]);
    
    server.get(`get_operationuser/${id}`)
      .then((response) => {
        setUsers(response.data.users);
      });
  };

  

  return (
    <div className='container-fluid'>
      <div className='d-flex'>
        <SideBar />
        <div className='headerbar'>
          <Header />
          <h4 className='text-danger text-center'>Add Multiple Client Trainings</h4>
          <hr className='bg-danger px-1' />
          
          <div className='row mt-5 jumbotron mx-3'>
            {project_id !== 0 && (
              <div className='col-md-6'>
                <label>Products</label>
                <select className='form-control' name='product_id' id="product_id" value={product_id} onChange={(e) => setallUsers(parseInt(e.target.value))} required>
                  <option value={0}>Select Product</option>
                  {products.map((product) => (
                    <option value={product.id}>{product.product_name}</option>
                  ))}
                </select>
              </div>
            )}
            <div className='col-md-12'>
              {product_id !== 0 && (
                users.length !== 0 ? (
                  <div>
                    <label>Users</label>
                    <Multiselect
                      options={users}
                      placeholder="Select doctors here"
                      selectedValues={selectedValue}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="user"
                    />
                  </div>
                ) : (
                  <p className="text-danger">
                    Users List can't be displayed because no user filled their details yet
                  </p>
                )
              )}
            </div>
            <div className='col-md-12 px-5'>
              <br/>
             {product_id!==0? <form id="addtrainingdetails" onSubmit={addTraining} encType='multipart/form-data' className='w-100'>
                <div className='row'>
                  <input type={'hidden'} name={'admin_id'} value={admin_id} />
                  <input type={'hidden'} name={'project_id'} value={project_id} />
                  <input type={'hidden'} name={'product_id'} value={product_id} />
                  <div className='col-md-3'>
                    <label>Test Type<b className='text-danger'>*</b></label>
                    <select id="service_category_type" className='form-control' name="service_category_type" value={service_category_type} onChange={(e) => getServices(parseInt(e.target.value))}>
                      <option value="0">Select Test Category</option>
                      {service_category_types.map((service_category_type, index) => (
                        <option value={service_category_type.id}>{service_category_type.service_category_type}</option>
                      ))}
                    </select>
                  </div>
                  <input type="hidden" name={"service_category_id"} id={"service_category_id"} value="2" />
                  {service_category_type !== 0 && (
                    <div className='col-md-3'>
                      <label htmlFor="">Vendor<b className='text-danger'>*</b></label>
                      <select className='form-control' id="vendor_id" name="vendor_id" value={vendor_id} onChange={(e) => setVendorId(parseInt(e.target.value))}>
                        <option value={0}>Choose Training Vendor</option>
                        {vendors.map((vendor, index) => (
                          <option value={vendor.id}>{vendor.vendor_name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  {service_category_type !== 0 && (
                    <div className='col-md-2'>
                      <label htmlFor="">Program<b className='text-danger'>*</b></label>
                      <select className='form-control' id="service_id" name="service_id" value={service_id} onChange={(e) => setServiceId(parseInt(e.target.value))}>
                        <option value="0">Select Test</option>
                        {services.map((service, index) => (
                          <option value={service.id}>{service.service_name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  {service_id !== 0 && (
                    <div className='col-md-2'>
                      <label>Training Mode<b className='text-danger'>*</b></label>
                      <select className='form-control' name="mode_of_training" id="mode_of_training" value={mode_of_training} onChange={(e) => setModeOfTraining(parseInt(e.target.value))}>
                        <option value={0}>Select Mode of Training</option>
                        <option value={1}>Online</option>
                        <option value={2}>Offline</option>
                        <option value={3}>Online Crash Course</option>
                        <option value={4}>Offline Crash Course</option>
                      </select>
                    </div>
                  )}
                  {service_id !== 0 && (
                    <div className='col-md-2'>
                      <label>Start Date<b className='text-danger'>*</b></label>
                      <input className="form-control" type="date" name="start_date" id="start_date" value={start_date} onChange={(e) => setStartDate(e.target.value)} />
                    </div>
                  )}
                  {service_id !== 0 && (
                    <div className='col-md-2 mt-3'>
                      <label>End Date<b className='text-danger'>*</b></label>
                      <input className="form-control" type="date" name="end_date" id="end_date" value={end_date} onChange={(e) => setEndDate(e.target.value)} />
                    </div>
                  )}
                </div>
                <ToastContainer />
                <div className='text-center mt-4'>
                  <button type="submit" className='btn btn-info text-white mt-2'>Add Details</button>
                </div>
              </form>:""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMultipleTraining;
