import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';

import {Link,useParams} from 'react-router-dom';

import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { message } from 'antd';

import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import $ from 'jquery'; 

import validator from 'validator';
import { off } from 'process';
import { type } from 'os';
import { vendortype } from '../../@types/vendortype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import { ServiceData } from '../../@types/servicetype';
import { service_categorytypedata } from '../../@types/service_categorytypedata';
import server from '../Utils';
import UserInfo from '../UserInfo';
import Loader from './Loader';
const AddTraining = () => {
        
   
   
    const [project_id,setProjectId] = useState<number>(0);
    const [product_id, setProductId] = useState<number>(0);
    const [loading, setLoading] = useState(false);

    const [assesment_date,setAssesmentDate] =useState<string>('');
    const [service_category_id,setServiceCategoryId]=useState<number>(0);
    const [service_categories,setServiceCategories]=useState<Array<ServiceCategoryType>>([])
    const [services,setServices]=useState<Array<ServiceData>>([])
    const [service_id,setServiceId]=useState<number>(0)
    const[vendors,setVendors]=useState<Array<vendortype>>([]);
    const [vendor_id,setVendorId]=useState<number>(0);
    const [mode_of_training,setModeOfTraining]=useState(0);
    const[start_date,setStartDate]=useState<string>('')
    const[end_date,setEndDate]=useState<string>('')
    const [service_category_type,setCategoryTypeId]=useState<number>(0)
    const {id}= useParams();
    const userid=id;
    const [service_category_types,setServiceCategorytypes]=useState<Array<service_categorytypedata>>([])
    const [user_id,setUserId]=useState<number>(0)
    let  admin_id:string=JSON.parse(localStorage.getItem("admin_id")||'{}');
    useEffect(() => {
      let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
      let id = +projectid;
      let  productid:string=JSON.parse(localStorage.getItem("product_id")||'{}');
      setProductId(+productid)
      setProjectId(id);

      if(parseInt(productid)===1){
      server.get(`/service_types_basedon_categories/2`)
      .then((response)=>{
         
          if(response.data.service_category_types.length>0){
              setServiceCategorytypes(response.data.service_category_types)
          }else{
              setServiceCategorytypes([]);
          }
      })
    }else if(parseInt(productid)===2){
      server.get(`/service_types_basedon_categories/14`)
      .then((response)=>{
         
          if(response.data.service_category_types.length>0){
              setServiceCategorytypes(response.data.service_category_types)
          }else{
              setServiceCategorytypes([]);
          }
      })
      }else if(parseInt(productid)===3){
        server.get(`/service_types_basedon_categories/25`)
        .then((response)=>{
           
            if(response.data.service_category_types.length>0){
                setServiceCategorytypes(response.data.service_category_types)
            }else{
                setServiceCategorytypes([]);
            }
        })
        }
      // server.get(`/service_categories_for_training`)
      // .then((response)=>{
      //   setServiceCategories(response.data.services_categories)
  
      // })

     
    }, []);

const getVendors=(id:number)=>{
  server.get(`/vendor_on_service_types/${id}`)
  .then((response)=>{
    
    setVendors(response.data.vendors)

  })
}
    const getServices =(id:number)=>{
   
      setCategoryTypeId(id)
      getVendors(id);
      // console.log(vendors)
      server.get(`/service_on_category_type/${id}`)
      .then((response)=>{
       
          setServices(response.data.services)
      })
     }

    const addTraining=(e:React.FormEvent<HTMLFormElement>)=>{
      e.preventDefault();
      
      let date1 = new Date(start_date).getTime();
      let date2 = new Date(end_date).getTime();
     
    if(service_category_type===0){
      toast('Select the Category Type');
    }else if(service_id===0){
        toast('Select Service');
    }else if(vendor_id===0){
      toast('Select Vendor')
    }else if(mode_of_training===0){
      toast('Select Mode of Training')
    }else if(start_date===null||start_date===""){
      toast('Start Date is required');
    }else if(end_date===null||end_date===""){
      toast('End Date is Required');
    }else if(date1>date2){
      toast(`End Date should be greater than Start Date`);
    }else if(date2===date1){
      toast(`End Date &  Start Date cant be equal`);
    }else {
      var form= document.getElementById('addtrainingdetails') as HTMLFormElement;
      var formData=new FormData(form)
      server.post(`/add_training/${userid}`,formData)
      .then((response)=>{
        if(response.data.code===200){
         
          toast(response.data.message)
         
          // setTimeout(function () { window.history.back(); }, 1000);
        }else{
          // setLoading(false)
          toast(response.data.message)
           
        }
      })
    }
    }
    if (loading) {
      return <Loader />;
    }
  return (
    <div className='container-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}

<UserInfo />
    <h4 className='text-danger text-center'>Add Client Training Details</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5 jumbotron mx-3'>
   
    <div className='col-md-12 px-5'>
        <form id="addtrainingdetails" onSubmit={addTraining}  encType='multipart/form-data' className='w-100'>
          <div className='row'>
            {/* <div className='col-md-3'>
              <label>Assessment Date</label>
              <input type="date" className='form-control' name="assessment_date" id="assessment_date" value={assesment_date} onChange={(e)=>setAssesmentDate(e.target.value)} required />
            </div> */}
            <input type={'hidden'} name={'user_id'} value={user_id} />
            <input type={'hidden'} name={'admin_id'} value={admin_id} />
            <input type="hidden" name="project_id" id="project_id" value={project_id} />
            <input type="hidden" name="product_id" id="product_id" value={product_id} />
            <div className='col-md-3'>
              <label>Test Type<b className='text-danger'>*</b></label>
              <select id="service_category_type" className='form-control' name="service_category_type" value={service_category_type} onChange={(e)=>getServices(parseInt(e.target.value))}>
                <option value="0">Select Test Category</option>
                {service_category_types.map((service_category_type,index)=>(
                  <option value={service_category_type.id}>{service_category_type.service_category_type}</option>
                ))}
              </select>
            </div>
           
            <input type="hidden" name={"service_category_id"} id={"service_category_id"} value="2"/>
            {service_category_type!==0?<div className='col-md-3'>
                    <label htmlFor="">Vendor<b className='text-danger'>*</b></label>
                    <select className='form-control' id="vendor_id" name="vendor_id" value={vendor_id} onChange={(e)=>setVendorId(parseInt(e.target.value))}>
                    <option value={0}>Choose Training Vendor</option>
                   {vendors.map((vendor,index)=>(
                    <option value={vendor.id}>{vendor.vendor_name}</option>
                  ))}
              </select>
            </div>:""}
            {service_category_type!==0?<div className='col-md-2'>
              <label htmlFor="">Program<b className='text-danger'>*</b></label>
               <select className='form-control' id="service_id" name="service_id" value={service_id} onChange={(e)=>setServiceId(parseInt(e.target.value))}> 
             
                    <option value="0">Select Test</option>        
                         {services.map((service,index)=>(
                            <option value={service.id}>{service.service_name}</option>
                         ))}   
                </select>             
                            
                </div>:""}  
                
 {service_id!==0?
            
            <div className='col-md-2'>
              <label>Training Mode<b className='text-danger'>*</b></label>
              <select className='form-control' name="mode_of_training" id="mode_of_training" value={mode_of_training} onChange={(e)=>setModeOfTraining(parseInt(e.target.value))} >
                
                <option value={0}>Select Mode of Training</option>
                <option value={1}>Online</option>
                <option value={2}>Offline</option>
                <option value={3}>Online Crash Course</option>
                <option value={4}>Offline Crash Course</option>
              </select>
            </div>:""}

            {service_id!==0? <div className='col-md-2'>
              <label>Start Date<b className='text-danger'>*</b></label>
              <input className="form-control"  type="date" name="start_date" id="start_date" value={start_date} onChange={(e)=>setStartDate(e.target.value)} />
            </div>:""}
            
           {service_id!==0? <div className='col-md-2 mt-3'>
            <label>End Date<b className='text-danger'>*</b></label>
              <input className="form-control" type="date" name="end_date" id="end_date" value={end_date} onChange={(e)=>setEndDate(e.target.value)} />
            </div>:""}
          </div>
         
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-info  text-white mt-2'>Add Details</button></div>
        </form>
   
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default AddTraining;