import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import Addvendor from '../../components/Vendors/Addvendor';



const AddVendor = () => {
    const [products,setProducts]=useState<Array<ProductData>>([])
   
    const [project_id,setProjectId]=useState<number>(0);
    const [product_id,setProductId]=useState<number>(0);
    const [stage,setStage]=useState<string>();
    const [error,setError]=useState<string>();
    useEffect(() => {
        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let id= +projectid
        setProjectId(id)
        axios.get(`http://localhost:8000/api/admin/get_product/${id}`)
        .then((response)=>{
            
            setProducts(response.data.product)
            
        })
       
      }, []);
   
    
   
    
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar' style={{width:"100%"}}>
        <Header/>
        {/* Content Row */}

    {/* Vendor Component Loaded Here */}

     <Addvendor />
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default AddVendor