import { data } from 'jquery';
import React,{useState,useEffect} from 'react';
import { useParams,Link } from 'react-router-dom';
import server from '../Utils';
import { travelandaccomdationtype } from '../../@types/travelandaccomdationtype';
import moment from 'moment';
import Loader from './Loader';
const TravelAndAccomdation = () => {
    const {id}= useParams();
    const [records,setRecords]=useState<Array<travelandaccomdationtype>>([])
    const [loading, setLoading] = useState(false);
    const [user_agreed,setUserAgreed]=useState<number>(0);

    useEffect(() => {
      let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
      let project_id = +projectid;
      
      let productid:string=JSON.parse(localStorage.getItem("product_id")||'{}');
      let product_id = +productid;
      server.get(`/operationuser/${id}`)
        .then((response)=>{
         
           if(response.data.user.user_agreement_flag===1){
             setUserAgreed(1)
           }else{
             setUserAgreed(0)
           }
        })
     server.get(`/travelandaccomdations/${id}`)
     .then((response)=>{
   
      setRecords(response.data.travelandaccomdation)
  
     })
   },[id]);

   if (loading) {
    return <Loader />;
  }

  return (
    <div className='pb-3'>
        {user_agreed === 1 ? <Link to={`/add_travel_and_accomdation/${id}`} className='btn btn-info text-center text-white float-right' >Add Travel And Accomdation</Link>:<p className='text-danger'>Kindly ask the doctor to fill their details in their portal And Please Book the Plab2 Test for this client after client completes the details in thier portal</p>}
        {/* <Link to={`/add_travel_and_accomdation/${id}`} className='btn btn-info text-center text-white float-right' >Add Travel And Accomdation</Link> */}
        <br/>
        <br/>
        {records.map((record,index) => (
          <div className='jumbotron w-100'>
            <div className='row'>
              <div className='col-md-6 text-left'>
              
              </div>
              <div className='col-md-6 text-right'>
 <Link to={`/edit_travel_and_accomdation/${record.id}`} className='text-danger' ><i className= "fa fa-edit text-info"></i></Link></div>
 </div>
 
            <br/>
        <div className='row mb-4' key={record.id}>
         
          <div className='col-md-6'>
            
            {/* Add similar rows for Vendor ID, Accommodation Start Date, and Accommodation End Date */}
            <div className='row mb-2'>
              <div className='col-md-6 text-left'><label>Vendor Name:</label></div>
              <div className='col-md-6 text-left'>{record.vendor_name}</div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-6 text-left'><label>Accommodation Start Date:</label></div>
              <div className='col-md-6 text-left'>{moment(record.accommodationStartDate).format('Do MMMM YYYY')}</div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-6 text-left'><label>Accommodation End Date:</label></div>
              <div className='col-md-6 text-left'>{moment(record.accommodationEndDate).format('Do MMMM YYYY')}</div>
            </div>
            {/* Add similar rows for other fields */}
          </div>
          <div className='col-md-6'>
            <div className='row mb-2'>
              <div className='col-md-6 text-left'><label>Number of Days:</label></div>
              <div className='col-md-6 text-left'>{record.numberOfDays}</div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-6 text-left'><label>Days Excluded:</label></div>
              <div className='col-md-6 text-left'>{record.daysIncluded} Days</div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-6 text-left'><label>Days Included Package:</label></div>
              <div className='col-md-6 text-left'>{record.days_included_package} days</div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-6 text-left'><label>Room Type:</label></div>
              <div className='col-md-6 text-left'>{record.room_type===1?"Single Ensuite room with sea view (Double bed)":record.room_type===2?"Single Ensuite room with sea view (Single Bed)":record.room_type===3?"Triple Room (Double Bed)":record.room_type===4?"Triple Room (Single Bed)":record.room_type===5?"Twin Sharing Ensuite (Single Bed)":record.room_type===6?"Twin Sharing Ensuite (Double Bed)":record.room_type===7?"Single Ensuite room (Double Bed)":record.room_type===8?"Single Ensuite room (Single Bed)":""} days</div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-6 text-left'><label>Send Accommodation Email:</label></div>
              <div className='col-md-6 text-left'>{record.sendAccommodationEmail===1?"Sent":"NotSent"}</div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-6 text-left'><label>Send Travel Reminder Email:</label></div>
              <div className='col-md-6 text-left'>{record.sendTravelReminderEmail===1?"Sent":"NotSent"}</div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-6 text-left'><label>Return Date:</label></div>
              <div className='col-md-6 text-left'>{moment(record.returnDate).format('Do MMMM YYYY')}</div>
            </div>
            {/* Add similar rows for other fields */}
          </div>
          <div className='col-md-6'>
            <div className='row mb-2'>
              <div className='col-md-6 text-left'><label>Line 1:</label></div>
              <div className='col-md-6 text-left'>{record.line1}</div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-6 text-left'><label>Line 2:</label></div>
              <div className='col-md-6 text-left'>{record.line2}</div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-6 text-left'><label>City:</label></div>
              <div className='col-md-6 text-left'>{record.city}</div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-6 text-left'><label>State:</label></div>
              <div className='col-md-6 text-left'>{record.state}</div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-6 text-left'><label>Country:</label></div>
              <div className='col-md-6 text-left'>{record.country}</div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-6 text-left'><label>Pin:</label></div>
              <div className='col-md-6 text-left'>{record.pin}</div>
            </div>
            
            {/* Add similar rows for other fields */}
          </div>
         
        </div>
        {record.notes!==null?<div>
        <label className='float-left'>Notes:</label><br/>
         <div className='text-left w-100'>{record.notes}</div>
       </div>:<div></div>}
       </div>
      ))}
    </div>
  );
  
}

export default TravelAndAccomdation