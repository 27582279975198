
import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';

import {Link,NavLink,useParams} from 'react-router-dom';

import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { message } from 'antd';

import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import $ from 'jquery'; 

import validator from 'validator';
import { off } from 'process';
import { type } from 'os';
import { vendortype } from '../../@types/vendortype';
import TestBookings from '../TestBooking/TestBookings';

import { testbookingtype } from '../../@types/testbookingtype';
import Registrations from '../Registrations/Registrations';

import { ServiceCategoryType } from '../../@types/service_category_type';
import { ServiceData } from '../../@types/servicetype';
import { registrationtype } from '../../@types/registrationtype';
import { trainingsubcriptiontype } from '../../@types/training_and_subscription';
import moment from 'moment';

import { coursedatatype } from '../../@types/coursesdatatype';
import server from '../Utils';
import { PortfolioType } from '../../@types/portfoliotypes';
import Loader from './Loader';

const Portfolio = () => {
    let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
    let pro_id= +projectid;

    const {id}= useParams();
    const [project_id,setProjectId]=useState<number>(0);
    const [user_exist,setUserExists]=useState<boolean>(false);
    const [portfolios,setPortfolio] =useState<Array<PortfolioType>>();
    const [loading, setLoading] = useState(false);
    const [user_agreed,setUserAgreed]=useState<number>(0);
    useEffect(() => {
        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let pro_id= +projectid;

        server.get(`/operationuser/${id}`)
        .then((response)=>{
         
           if(response.data.user.user_agreement_flag===1){
             setUserAgreed(1)
           }else{
             setUserAgreed(0)
           }
        })
  server.get(`/get_portfolios/${id}`)
  .then((response)=>{
    setPortfolio(response.data.portfolio)
  })


      
        }, []);

 if (loading) {
  return <Loader />;
}
  return (
    <div>
    {user_agreed === 1 ?<Link className='btn btn-info text-info text-light float-right' to={`/add_portfolio_form/${id}`}>Add Portfolio</Link>:<p className='text-danger'>Kindly ask the doctor to fill their details in their portal</p>}
    <br/>
    <br/>
    { portfolios && portfolios.map((portfolio,index)=>(<div className=''>
    
    {portfolio.service_category_type===21?<div className='row my-2 jumbotron mx-1'>
      <div className='col-md-12'><div className='row'><div className='col-md-4'><h3>Research And Publication Details</h3> </div><div className='col-md-5'></div><div className='col-md-3'><Link to={`/edit_portfolio_form/${portfolio.id}`} className='nav-link'><i className='fa fa-edit text-info'></i></Link></div></div></div>
      
      <div className="col-md-4"><h6>Vendor Name</h6></div>
      <div className="col-md-4"><i>{portfolio.vendor_name}</i></div>
      <div className='col-md-4'></div>
      <div className='col-md-4'>
        <h6>Research Batch Name</h6>
      </div>
      <div className='col-md-4'>
      <i> {portfolio.research_batch_name}</i>
      </div>
      <div className='col-md-4'></div>

      <div className='col-md-4'>
        <h6>OET Login Password</h6>
      </div>
      <div className='col-md-4'>
      <i> {portfolio.research_topic}</i>
      </div>
      <div className='col-md-4'></div>

      <div className="col-md-4">
      <h6>Research Start Date</h6>
      </div>
      <div className="col-md-4">
     <i> {moment(portfolio.research_start_date).format('Do MMMM YYYY')}</i>
      </div>
      <div className="col-md-4"></div>


      <div className="col-md-4">
      <h6>Research End Date</h6>
      </div>
      <div className="col-md-4">
      <i> {moment(portfolio.research_end_date).format('Do MMMM YYYY')}</i>
      </div>
      <div className="col-md-4"></div>
      
      
      <div className="col-md-4">
      <h6>Research Bieng Published</h6>
      </div>
      <div className="col-md-4">
        <i>{portfolio.research_bieng_published===1?"Yes":portfolio.research_bieng_published===2?"No":"Not Specified"}</i>
      </div>
      <div className="col-md-4"></div>
      
      
      <div className="col-md-4">
        <h6>Publication Name</h6>
      </div>
      <div className="col-md-4">
      <i> {portfolio.publication_name}</i>
      </div>
      <div className="col-md-4"></div>

      <div className="col-md-4">
        <h6>Publication Uploaded</h6>
      </div>
      <div className="col-md-4">
      <i><a className='text-left' href={portfolio.publication_upload} >Publication Uploaded</a></i>
      </div>
      <div className="col-md-4"></div>

    </div>:portfolio.service_category_type===17?<div className='row my-3 jumbotron  mx-1'>
    <div className='col-md-12'><div className='row'><div className='col-md-4'><h3> Ngo Research Details</h3> </div><div className='col-md-5'></div><div className='col-md-3'><Link to={`/edit_portfolio_form/${portfolio.id}`} className='nav-link'><i className='fa fa-edit text-info'></i></Link></div></div></div>      
      <div className="col-md-4"><h6>Vendor Name</h6></div>
      <div className="col-md-4"><i>{portfolio.vendor_name}</i></div>
      <div className='col-md-4'></div>
      
      <div className='col-md-4'>
        <h6>Ngo Batch Name</h6>
      </div>
      <div className='col-md-4'>
      <i>{portfolio.ngo_batch_name}</i>
      </div>
      <div className='col-md-4'></div>

      <div className='col-md-4'>
        <h6>NGO Description </h6>
      </div>
      <div className='col-md-4'>
      <i>{portfolio.ngo_description}</i>
      </div>
      <div className='col-md-4'></div>

      <div className="col-md-4">
      <h6>NGO Start Date</h6>
      </div>
      <div className="col-md-4">
      <i>{moment(portfolio.ngo_start_date).format('Do MMMM YYYY')}</i>
      </div>
      <div className="col-md-4"></div>


      <div className="col-md-4">
      <h6>NGO Start Date</h6>
      </div>
      <div className="col-md-4">
      <i>{moment(portfolio.ngo_end_date).format('Do MMMM YYYY')}</i>
      </div>
      <div className="col-md-4"></div>


      <div className="col-md-4">
      <h6>NGO File </h6>
      </div>
      {portfolio.ngo_file!==null?<div className="col-md-4">
      <i><a className='text-left' href={portfolio.ngo_file}>NGO File</a></i>
      </div>:""}
      <div className="col-md-4"></div>

    </div>:portfolio.service_category_type===19?<div className='row my-3 jumbotron  mx-1'>
    <div className='col-md-12'><div className='row'>
      <div className='col-md-4'><h3>Teaching Details</h3> </div><div className='col-md-5'></div><div className='col-md-3 text-right'><Link to={`/edit_portfolio_form/${portfolio.id}`} className='nav-link'><i className='fa fa-edit text-info'></i></Link></div></div></div>   
    
        
      {portfolio.vendor_name && <div><div className="col-md-4"><h6>Vendor Name</h6></div>
      <div className="col-md-4"><i>{portfolio.vendor_name}</i></div>
      <div className='col-md-4'></div></div>}
      
      <div className='col-md-4'>
        <h6>Teaching Date</h6>
      </div>
      <div className='col-md-4'>
      <i>{moment(portfolio.teaching_date).format('Do MMMM YYYY')}</i>
      </div>
      <div className='col-md-4'></div>

      <div className='col-md-4'>
        <h6>Teaching Topic</h6>
      </div>
      <div className='col-md-4'>
      <i> {portfolio.teaching_topic}</i>
      </div>
      <div className='col-md-4'></div>

      <div className="col-md-4">
      <h6>Feed Back Form Link</h6>
      </div>
      <div className="col-md-4">
      <i> <a className='text-left' href={portfolio.feedback_form_link}>Feedback form Link</a></i>
      </div>
      <div className="col-md-4"></div>


      

    </div>:portfolio.service_category_type===20?<div className='row my-3 jumbotron  mx-1'>
    <div className='col-md-12'><div className='row'><div className='col-md-4'><h3>Audit And QIP Details</h3> </div><div className='col-md-5'></div><div className='col-md-3 text-right'><Link to={`/edit_portfolio_form/${portfolio.id}`} className='nav-link'><i className='fa fa-edit text-info'></i></Link></div></div></div>      
      
      <div className="col-md-4"><h6>Vendor Name</h6></div>
      <div className="col-md-4"><i>{portfolio.vendor_name}</i></div>
      <div className='col-md-4'></div>
      
      <div className='col-md-4'>
        <h6>Audit And QIP Batch Name</h6>
      </div>
      <div className='col-md-4'>
        {/* {moment(portfolio.a_qip_batch_name).format('Do MMMM YYYY')} */}
        <i> {portfolio.a_qip_batch_name}</i>
      </div>
      <div className='col-md-4'></div>

      <div className='col-md-4'>
        <h6>Audit And QIP Batch Name</h6>
      </div>
      <div className='col-md-4'>
      <i>{portfolio.a_qip_topic_name}</i>
      </div>
      <div className='col-md-4'></div>

      <div className="col-md-4">
      <h6> Audit And QIP Start Date</h6>
      </div>
      <div className="col-md-4">
      <i>{moment(portfolio.a_qip_start_date).format('Do MMMM YYYY')}</i>
      </div>
      <div className="col-md-4"></div>


      <div className="col-md-4">
      <h6>Audit And QIP End Date</h6>
      </div>
      <div className="col-md-4">
      <i>{moment(portfolio.a_qip_end_date).format('Do MMMM YYYY')}</i>
      </div>
      <div className="col-md-4"></div>

      <div className="col-md-4">
      <h6>QIP Name</h6>
      </div>
      <div className="col-md-4">
      <i>{portfolio.qip_name}</i>
      </div>
      <div className="col-md-4"></div>


      <div className="col-md-4">
      <h6>Certificate</h6>
      </div>
      <div className="col-md-4">
      <i> <a className='text-left' href={portfolio.upload_certificate}>Certificate Uploaded</a></i>
      </div>
      <div className="col-md-4"></div>

      <div className="col-md-4">
      <h6>QIP Uploaded</h6>
      </div>
      <div className="col-md-4">
        <a className='text-left' href={portfolio.upload_qip}>QIP Uploaded</a>
      </div>
      <div className="col-md-4"></div>


     

    </div>:portfolio.service_category_type===18?<div className='row my-3 jumbotron  mx-1'>
    <div className='col-md-12'><div className='row'><div className='col-md-5'><h3>Confernce Details</h3> </div><div className='col-md-4'></div><div className='col-md-3 text-right'><Link to={`/edit_portfolio_form/${portfolio.id}`} className='nav-link'><i className='fa fa-edit text-info'></i></Link></div></div></div>      
      
     { portfolio.vendor_name &&<div className='row'> <div className="col-md-4"><h6>Vendor Name</h6></div>
      <div className="col-md-4"><i>{portfolio.vendor_name}</i></div>
      <div className='col-md-4'></div></div>}
      
      <div className='col-md-4'>
        <h6>Participation Type</h6>
      </div>
      <div className='col-md-4'>
      <i>{portfolio.participation_type===1?"Presenter":portfolio.participation_type===2?"Attendee":""}</i>
      </div>
      <div className='col-md-4'></div>

      <div className='col-md-4'>
        <h6>Conference Mode</h6>
      </div>
      <div className='col-md-4'>
      <i> {portfolio.conference_mode===1?"Online":portfolio.conference_mode===2?"Offline":""}</i>
      </div>
      <div className='col-md-4'></div>
     
      <div className='col-md-4'>
        <h6>Conference Date</h6>
      </div>
      <div className='col-md-4'>
      <i>{moment(portfolio.conference_date).format('Do MMMM YYYY')}</i>
      </div>
      <div className='col-md-4'></div>

      

    </div>:""}


 </div>))}
     
    </div>
    
  )
}

export default Portfolio;