import React,{useState,useEffect, FormEvent} from 'react'
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { SpecialityData } from '../../@types/specialitytype';
import server from '../Utils';

import Loader from './Loader';

const AddSubSpecialites = () => {
  const [specialities,setSpecialities]=useState<Array<SpecialityData>>([]);
  const [speciality_id,setSpecialityId]=useState<number>(0);
  const [sub_speciality,setSubSpeciality]=useState<string>('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    server.get(`/specialities`)
    .then((response)=>{
        
      setSpecialities(response.data.specialities)
})
  }, []);

  const addSubSpeciality=(e:React.FormEvent<HTMLFormElement>)=>
  { 
    e.preventDefault();
    if(speciality_id===0)
    {
      toast('Select Speciality');    
     }else if(sub_speciality===""||sub_speciality===undefined){
      toast('Enter Speciality'); 
    }
    var form =document.getElementById('add_sub_speciality_form') as HTMLFormElement;
    var formData=new FormData(form);
    server.post(`/add_sub_speciality`,formData)
    .then((response)=>{
      if(response.data.code===200)
      {
        toast(response.data.message)
        setTimeout(function () { window.history.back(); }, 1000);
      }else
      {
        toast(response.data.message) 
      }
    })
  }
  if (loading) {
    return <Loader />;
  }
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
            <div className='headerbar'>
        <Header/>
        {/* Content Row */}

    <h4 className='text-danger text-center'>Add Sub Subspeciality</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5 px-5'>
   

      
        <form id="add_sub_speciality_form" className='w-100' onSubmit={addSubSpeciality} >
          <div className='row'>
            <div className='col-md-1'>
              

            </div>
            <div className='col-md-4'>
            <label>Speciality</label>
              <select name="speciality_id" className='form-control' value={speciality_id} onChange={(e)=>setSpecialityId(parseInt(e.target.value))} >
                <option value={0}>Select Speciality</option>
                {specialities.map((speciality)=>(
                  <option value={speciality.id}>{speciality.speciality}</option>
                  ))}
              </select>
            </div>
            <div className='col-md-4'>
              <label>Sub Speciality Name</label>
              <input type="text" className='form-control' name="sub_speciality" id="sub_speciality" value={sub_speciality} onChange={(e)=>setSubSpeciality(e.target.value)} />
            </div>
           
            
          </div>
          <div className='row'>
            <div className='col-md-6'>
                   
            </div>
            <div className='col-md-6'>
                
            </div>
          </div>
           
           <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Submit</button></div>
        </form>
     

  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}
 
export default AddSubSpecialites