import React, { useEffect, useState } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import moment from 'moment';
import { Link } from 'react-router-dom';
import server from '../Utils';
import Loader from './Loader';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GridApi, ColumnApi, ColDef } from 'ag-grid-community';
import { trainingtype } from '../../@types/trainingtype';

const TrainingSubscriptionList: React.FC = () => {
  const [trainings, setTrainings] = useState<Array<trainingtype>>([]);
  const [filteredTrainings, setFilteredTrainings] = useState<Array<trainingtype>>([]);
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const [searchText, setSearchText] = useState<string>('');

  let projectid: string = JSON.parse(localStorage.getItem('project_id') || '{}');
  let id = +projectid;

  useEffect(() => {
    setLoading(true);
    server
      .get(`/training_and_subscriptions_list/${id}`)
      .then((response) => {
        setTrainings(response.data.trainings);
        setFilteredTrainings(response.data.trainings); // Initialize filtered data
      })
      .catch((error) => {
        console.error('Error fetching training and subscriptions list:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const onGridReady = (params: { api: GridApi, columnApi: ColumnApi }) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = trainings.filter(training => {
      for (const key in training) {
        if (Object.prototype.hasOwnProperty.call(training, key)) {
          const val = training[key as keyof trainingtype]; // Use keyof to access typed properties

          if (typeof val === 'string' && val.toLowerCase().includes(value)) {
            return true;
          } else if (typeof val === 'number' && val.toString().includes(value)) {
            return true;
          }
        }
      }
      return false;
    });

    setFilteredTrainings(filteredData);
    gridApi?.setRowData(filteredData);
  };

  const resetFilters = () => {
    setFilteredTrainings(trainings); // Reset filtered data to original dataset
    gridApi?.setFilterModel(null); // Clear all applied filters
  };

  const getColumnDefs = (): ColDef[] => [
    {
      headerName: 'SL No',
      valueGetter: "node.rowIndex + 1",
      sortable: true,
      filter: false,
    },
    {
      headerName: 'First Name',
      field: 'first_name',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Last Name',
      field: 'last_name',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Service Category Type',
      field: 'service_category_type',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Service Name',
      field: 'service_name',
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Subscription Start Date',
      field: 'subscription_start_date',
      sortable: true,
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => moment(params.value).format('Do MMMM YYYY'),
    },
    {
      headerName: 'Subscription End Date',
      field: 'subscription_end_date',
      sortable: true,
      filter: 'agDateColumnFilter',
      valueFormatter: (params) => moment(params.value).format('Do MMMM YYYY'),
    },
    {
      headerName: 'Subscription Duration',
      valueGetter: (params) =>
        params.data.subscription_duration + ' ' +
        (params.data.subscription_period === 1 ? 'Days' :
         params.data.subscription_period === 2 ? 'Weeks' :
         params.data.subscription_period === 3 ? 'Months' :
         params.data.subscription_period === 4 ? 'Years' : ''),
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Actions',
      filter:false,
      cellRenderer: (params: { data: { user_id: number; id: number } }) => (
        <Link
          className='text-dark text-center'
          to={`/edit_training_and_subscription/${params.data.id}`}
        >
          <i className='fa fa-edit text-success'></i>
        </Link>
      ),
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='container-fluid'>
      <div className='d-flex'>
        <SideBar />
        <div className='headerbar'>
          <Header />
          <h4 className='text-danger text-center'>Training And Subscription Lists</h4>
          <hr className='bg-danger px-1' />
          <div className='row mt-5 px-2'>
            <div className='col-md-4'>
              {/* <input
                type='text'
                className='form-control'
                placeholder='Search...'
                value={searchText}
                onChange={handleSearchInputChange}
              /> */}
            </div>
            <div className='col-md-8 text-right'>
              <Link
                to={"/add_multiple_training_and_subscription"}
                className='btn btn-info float-right mr-2 text-white mb-3'
                role="button"
              >
                +
              </Link>
              <button
                className='btn btn-secondary float-right mr-2 text-white mb-3'
                onClick={resetFilters}
              >
                Reset Filters
              </button>
            </div>
            <div className='col-md-12 ag-theme-alpine' style={{ height: '500px' }}>
              <AgGridReact
                onGridReady={onGridReady}
                columnDefs={getColumnDefs()}
                rowData={filteredTrainings}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={50}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingSubscriptionList;
