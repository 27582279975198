import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { GMCRegistrationType } from '../../@types/gmc_registration';
import server from '../Utils';
import Loader from './Loader';

const GMCAndRegistration = () => {
  const { id } = useParams();
  const user_id = id;
  const [gmc_registrations, setGMCRegistrations] = useState<Array<GMCRegistrationType>>([]);
  const [loading, setLoading] = useState(false);
  const [user_agreed,setUserAgreed]=useState<number>(0);

  useEffect(() => {
    server.get(`/operationuser/${id}`)
        .then((response)=>{
         
           if(response.data.user.user_agreement_flag===1){
             setUserAgreed(1)
           }else{
             setUserAgreed(0)
           }
        })
    server.get(`/gmc_registration/${user_id}`)
      .then((response) => {
        setGMCRegistrations(response.data.gmc_registration);
      });
  }, [user_id]);

  if (loading) {
    return <Loader />;
  }
  return (
    <div className=''>
       {user_agreed === 1 ?  <Link to={`/add_gmc_registration_details/${id}`} className='btn btn-info float-right text-white'>Add GMC Registration</Link>:<p className='text-danger'>Kindly ask the doctor to fill their details in their portal And Please Book the Plab2 Test for this client after client completes the details in thier portal</p>}
     
      <br/>
      

     {gmc_registrations.length>=1?<div className='jumbotron mt-4'>
        {gmc_registrations.map((gmc_registration) => (
          <div className='row'>
            <Link to={`/edit_gmc_registration_details/${gmc_registration.id}`} className='text-danger nav-link'><i className='fa fa-edit text-info'></i></Link>
            <div className="col-md-6 mb-4" key={gmc_registration.id}>
              <div className="row">
                <div className="col-md-6">
                  <label>GMC License Status:</label>
                </div>
                <div className="col-md-6">
                  <p>{gmc_registration.gmc_license_status===1?"Yet To Start":gmc_registration.gmc_license_status===2?"In Progress":gmc_registration.gmc_license_status===3?"Submitted":gmc_registration.gmc_license_status===4?"Recived":""}</p>
                </div>
              </div>
             { gmc_registration.gmc_submission_date!==null?<div className="row">
                <div className="col-md-6">
                  <label>GMC Submission Date:</label>
                </div>
                <div className="col-md-6">
                  <p>{moment(gmc_registration.gmc_submission_date).format('Do MMMM YYYY')}</p>
                </div>
              </div>:""}
              { gmc_registration.gmc_license_date!==null? <div className="row">
                <div className="col-md-6">
                  <label>GMC License Date:</label>
                </div>
                <div className="col-md-6">
                  <p>{moment(gmc_registration.gmc_license_date).format('Do MMMM YYYY')}</p>
                </div>
              </div>:""}

              {/* Additional Fields */}
              {gmc_registration.trac_account!==null?<div className="row">
                <div className="col-md-6">
                  <label>Trac Account:</label>
                </div>
                <div className="col-md-6">
                  <p>{gmc_registration.trac_account}</p>
                </div>
              </div>:""}
              {gmc_registration.trac_password!==null?<div className="row">
                <div className="col-md-6">
                  <label>Trac Password:</label>
                </div>
                <div className="col-md-6">
                  <p>{gmc_registration.trac_password}</p>
                </div>
              </div>:""}
              {gmc_registration.cv_review!==0? <div className="row">
                <div className="col-md-6">
                  <label>CV Review:</label>
                </div>
                <div className="col-md-6">
                  <p>{gmc_registration.cv_review ? 'Yes' : 'No'}</p>
                </div>
              </div>:""}
            </div>
            <div className="col-md-6">
            {gmc_registration.apply_for_jobs!==null? <div className="row">
                <div className="col-md-6">
                  <label>Apply for Jobs:</label>
                </div>
                <div className="col-md-6">
                  <p>{gmc_registration.apply_for_jobs === 1 ? 'Active' : 'Inactive'}</p>
                </div>
              </div>:""}
              {gmc_registration.job_offer_date!==null?  <div className="row">
                <div className="col-md-6">
                  <label>Job Offer Date:</label>
                </div>
                <div className="col-md-6">
                  <p>{moment(gmc_registration.job_offer_date).format('Do MMMM YYYY')}</p>
                </div>
              </div>:""}
              {gmc_registration.employers_name!==null?  <div className="row">
                <div className="col-md-6">
                  <label>Employer's Name:</label>
                </div>
                <div className="col-md-6">
                  <p>{gmc_registration.employers_name}</p>
                </div>
              </div>:""}
              {gmc_registration.sendemaitocordinator!==0? <div className="row">
                <div className="col-md-6">
                  <label>Email to Visa Coordinator:</label>
                </div>
                <div className="col-md-6">
                  <p>{gmc_registration.sendemaitocordinator ? 'Yes' : 'No'}</p>
                </div>
              </div>:""}
              {gmc_registration.application_status!==null? <div className="row">
                <div className="col-md-6">
                  <label>Application Status:</label>
                </div>
                <div className="col-md-6">
                  <p>{gmc_registration.application_status===1?"Yet to Start":gmc_registration.application_status===2?"In-Progress":gmc_registration.application_status===3?"Submitted":""}</p>
                </div>
              </div>:""}
              {gmc_registration.application_submission_date!==null?<div className="row">
                <div className="col-md-6">
                  <label>Application Submission Date:</label>
                </div>
                <div className="col-md-6">
                  <p>{moment(gmc_registration.application_submission_date).format('Do MMMM YYYY')}</p>
                </div>
              </div>:""}
              {gmc_registration.visa_issued!==null?<div className="row">
                <div className="col-md-6">
                  <label>Visa Issued:</label>
                </div>
                <div className="col-md-6">
                  <p>{gmc_registration.visa_issued === 1 ? 'Yes' : 'No'}</p>
                </div>
              </div>:""}
              {gmc_registration.visa_issued_date!==null? <div className="row">
                <div className="col-md-6">
                  <label>Visa Issued Date:</label>
                </div>
                <div className="col-md-6">
                  <p>{moment(gmc_registration.visa_issued_date).format('Do MMMM YYYY')}</p>
                </div>
              </div>:""}
            </div>
            {/* End of Additional Fields */}
          </div>
        ))}
      </div>:""}
    </div>
  );
};

export default GMCAndRegistration;
