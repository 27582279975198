import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { welcomekittype } from '../../@types/welcomekitdata';
import {Link} from 'react-router-dom';
import pencil from '../../assets/images/pencil.png';
import { message } from 'antd';
import server from '../Utils';
import Loader from './Loader';
const Welcomekittype = () => {
    const [welcome_kits,setWelcomeKit]=useState<Array<welcomekittype>>([]);
    const [loading, setLoading] = useState(false);
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [product_id,setProductId]=useState<number>(0);
    
    let  service_categoryid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
    let id= +service_categoryid
    useEffect(() => {

      server.get(`/get_product/${id}`)
      .then((response)=>{
         
          if(response.data.product.length>0){
          setProducts(response.data.product)
          }else{
              setProducts([]);
          }
      })
        server.get(`/get_welcomekit_products_by_project/${id}`)
        .then((response)=>{

            setWelcomeKit(response.data.welcomekitproducts)

        })
      }, []);

      const deleteWelcomeKit=(deleteid:number)=>{
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
          });
          swalWithBootstrapButtons.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
                server.delete(`/welcome_kit_product/${deleteid}`)
                .then((response)=>{
                    setWelcomeKit(response.data.welcomekitproducts)
                    swalWithBootstrapButtons.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                      });
                })
             
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
              swalWithBootstrapButtons.fire({
                title: "Cancelled",
                text: "Your imaginary file is safe :)",
                icon: "error"
              });
            }
          });
       
    }
    const displayStages=(productid:number)=>{
      setProductId(productid)
      server.get(`/get_welcomekit_products_by_product/${productid}`)
      .then((response)=>{
         
        if(response.data.welcomekitproducts.length>0){
          setWelcomeKit(response.data.welcomekitproducts)
      }else{
        setWelcomeKit([]);
      }
    
      })
     }
    if (loading) {
      return <Loader />;
    } 
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}

    <h4 className='text-danger text-center'>Welcome Kit Products</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>


    <div className='col-md-12 px-5'>
    <Link to="/add_welcomekit" className='btn btn-outline-primary float-right my-3'>Add Welcome Kit Product</Link>
    {/* <select className='w-25 form-control my-4' value={product_id} onChange={(e)=>displayStages(parseInt(e.target.value))}>
     <option value={0}>Select Product</option>
     {products.map((product,index) => (
      <option value={product.id} >{product.product_name}</option>
      ))}
     </select> */}
     {welcome_kits.length!==0?<table className='table table-bordered'>
    <tr className='bg-warning'><th>Sl no</th><th>Welcome Kit Type</th><th>Welcome kit name</th><th>Welcome kit Price</th><th className='text-center' colSpan={3}>Actions</th></tr>

      {welcome_kits.map((welcome_kit,index)=>(
       <tr key={welcome_kit.id}><td>{index+1}</td><td>{welcome_kit.welcome_kit_type===1?"Offline":welcome_kit.welcome_kit_type===2?'Online':""}</td><td>{welcome_kit.welcome_kit_name}</td><td>{welcome_kit.welcome_kit_price}</td><td>< Link className='text-right' to={`/welcome_kit_edit/${welcome_kit.id}`} ><img width="20px" height="20px" src={pencil} alt="pencil"/></Link></td><td className='text-left'><i onClick={()=>deleteWelcomeKit(welcome_kit.id)} className='fa fa-trash'></i></td></tr>
      ))}
      </table>:<p>No Welcome kits found</p>}
    </div>
  </div>
        </div>
        {/* header bar ends here */}

        {/* Content Row */}

    </div>

</div>
  )
}

export default Welcomekittype
