import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { ProjectData } from '../../@types/projecttype';
  import {Link,useParams} from 'react-router-dom';
import server from '../Utils';
import Loader from './Loader';





const EditPackage = () => {
    const [projects,setProjects]=useState<Array<ProjectData>>([])
    const [project_id,setProjectId]=useState<number>(0);
    const [product_name,setproductName]=useState<string>("");
    const [product_price,setProductPrice]=useState<number>();
    const[error,setError]=useState<string>("");
    const [loading, setLoading] = useState(false);

    const {id}=useParams();
    let product_id=id;
    let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
    
    useEffect(() => {
        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let temp =+projectid
        setProjectId(temp)


        server.get(`/product/${product_id}`)
      .then((response)=>{
        setproductName(response.data.product.product_name);
        setProductPrice(response.data.product.product_price);
        setProjectId(response.data.product.product_id);
        
      })
        
      }, []);
     

      if (loading) {
        return <Loader />;
      }
      const updatePackage =(e:React.FormEvent<HTMLFormElement>)=>
      {
        e.preventDefault();
        var regEx = /^[A-Za-z]+$/;
        var name:any = product_name;
       if(!name)
        {
           setError("Enter the  Product Name");  
           toast(error);
        }
        else if(!product_price)
        {
           setError("Enter Package Name"); 
           toast(error);
        }
        else{
            setError('');
            var token=localStorage.getItem('auth_token');
            console.log(token)
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            var form=document.getElementById('update_package_form') as HTMLFormElement;
                var formdata=new FormData(form);
            server.post(`/product/${product_id}`,formdata,{ headers: { 'Authorization': 'Bearer '+token } })
            .then((response)=>{
                if(response.data.code===200)
                {
                    toast(response.data.message)
                    setTimeout(function () { window.history.back(); }, 1000);
                }else
                {
                    toast(response.data.message)
                }
            })

        }
      }  

  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}


    <h4 className='text-danger text-center'>Add Package</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>
    <div className='col-md-1'></div>
    <div className='col-md-10'>
        <form id="update_package_form" className='w-100' onSubmit={updatePackage}>
            <div className='row'>
                {/* <div className='col-md-4'>
                    <label >Programs</label>
                    <select className='form-control' defaultValue={project_id} onChange={(e)=>{setProjectId(parseInt(e.target.value))}} name='project_id' id="project_id" >
                        <option value={0}>Please select the program</option>
                        
                        {projects.map((project,index)=>(
                            <option value={project.id}>{project.project_name}</option>))}
                    </select>
                </div> */}
                  <input type="hidden" name="project_id"  id="project_id" value={projectid} />
                <div className='col-md-4'>
                    <label >Package</label>
                    <input className='form-control' type="text" placeholder='Enter Package'  value={product_name} onChange={(e)=>setproductName(e.target.value)} name="product_name" id="product_name" required />
                </div>
                <div className='col-md-4'>
                <label >Package Price</label>
                    <input className='form-control' type="number" value={(product_price)}  placeholder='Enter Price' name="product_price" onChange={(e)=>setProductPrice(parseInt(e.target.value))} id="product_price" required />
                </div>
            </div>
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Submit</button></div>
        </form>
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default EditPackage