import React,{useState,useEffect} from 'react'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link,redirect} from 'react-router-dom';
import axios from 'axios';
import UserSideBar from '../../pages/UserSideBar/UserSideBar';
import UserHeader from '../../pages/UserHeader/UserHeader';
import server from '../Utils';
import Swal from 'sweetalert2';
import Loader from './Loader';
import './ClientDashboard.css';
import userlogo from '../../assets/images/user_logo.png';
import user_logo_mobile from '../../assets/images/user_logo_mobile.png';
import clientavatar from '../../assets/images/user-doctor.jpeg'; 
import ticketicon from '../../assets/images/ticket_icon.png';
import { FaRegQuestionCircle } from 'react-icons/fa';
import ProgressBar from '../ProgressBar/ProgressBar';
import UserProgressBar from '../ProgressBar/UserProgressbar';
import moment from 'moment';
import { BiBorderBottom } from 'react-icons/bi';
import { coursedatatype } from '../../@types/coursesdatatype';

const ClientDashboard = () => {
  const [first_name,setfirstName]=useState<string>("");
  const [last_name,setlastName]=useState<string>('');
  var loggedIn=localStorage.getItem('loggedIn');
 
 
  const [passport_first_name,setPassportFirstName]=useState<string>("")
  const [passport_last_name,setPassportlastName]=useState<string>("")
  const [certificate_first_name,setCertificateFirstName]=useState<string>('')
  const [certificate_last_name,setCertificateLastName]=useState<string>('')
  const [primary_contact_country_code,setPrimaryCountryCode]=useState<string>('')
  const [primary_contact,setPrimaryContact]=useState<string>('')
  const [alernate_country_code,setAlternateCountryCode]=useState<string>('')
  const [alternate_phone_number,setAlternatePhoneNumber]=useState<string>('')
  const [user_email,setUserEmail]=useState<string>('')
  const [password,setPassword]=useState<string>('');
  const [last_password,setLastPassword]=useState<string>('');
  const [dob,setDob]=useState<string>('');
  const [fathers_full_name,setFathersFullName]=useState<string>('')
  const [fathers_email,setFathersEmail]=useState<string>('')
  const [fathers_contact_country_code,setFathersContactCountryCode]=useState('')
  const [fathers_contact,setFathersContact]=useState<number|undefined>()
  const [mothers_full_name,setMothersFullName]=useState<string>()
  const [mothers_email,setMothersEmail]=useState<string>('');
  const [mothers_country_code,setMothersCountryCode]=useState<string>();
  const [mothers_contact,setMothersContact]=useState<number|undefined>();
  const [passport_exist,setPassportExist]=useState<number>(0);
  const [passport_number,setPassportNumber]=useState<string>('');
  const [passport_issue_date,setPassportIssueDate]=useState<string>('');
  const [passport_expiry_date,setPassportExpiryDate]=useState<string>(''); 
  const [gender,setGender]=useState<number>(0); 
  const [loading, setLoading] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [regno,setRegNo]=useState<string>('');
  const [client_photo,setClientPhoto]=useState<string>('');
  const [registration_date,setRegistrationDate]=useState<string>('');
  const [current_stage,setCurrentStage]=useState<string>('');
  const [current_sub_stage,setCurrentSubStage]=useState<string>('');
  const [courses,setCourses]=useState<Array<coursedatatype>>([]);
  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };


  

  if(loggedIn!=="true")
  {
    window.location.href="/userlogin";
  }
  useEffect(() => {
    let userid= localStorage.getItem('ClientID');
    server.get(`/get_documents/${userid}`)
    .then((response) => {
      if (response.data.code === 200) {
        setClientPhoto(response.data.documents.client_photo);
       
      }
    });
    server.get(`/operation_user_view/${userid}`)
              .then((response)=>{
              
                  setCurrentStage(response.data.user.current_stage_name);
                  setCurrentSubStage(response.data.user.current_sub_stage_name);
                  
        
                 
                 
                  
            })

            server.get(`/user_allocated_courses/${userid}`)
            .then((response)=>{
                if(response.data.code===200){
                    setCourses(response.data.courses);
                }
            })

  server.get(`/operationuser/${userid}`)
  .then((response)=>{
 
    setfirstName(response.data.user.first_name)
    setlastName(response.data.user.last_name)
    setPassportFirstName(response.data.user.passport_first_name)
    setPassportlastName(response.data.user.passport_last_name)
    setCertificateFirstName(response.data.user.certificate_first_name)
    setCertificateLastName(response.data.user.certificate_last_name)
    setPrimaryCountryCode(response.data.user.primary_contact_country_code)
    setPrimaryContact(response.data.user.primary_contact)
    setGender(response.data.user.gender)
    setAlternateCountryCode(response.data.user.alernate_country_code)
    setAlternatePhoneNumber(response.data.user.alternate_phone_number)
    setUserEmail(response.data.user.user_email)
    setPassword(response.data.user.last_password)
    setLastPassword(response.data.user.last_password)
    setDob(response.data.user.dob)
    setRegistrationDate(response.data.user.date_of_registration)
    setFathersFullName(response.data.user.fathers_full_name)
    setFathersEmail(response.data.user.fathers_email)
    setFathersContactCountryCode(response.data.user.fathers_contact_country_code)
    setFathersContact(response.data.user.fathers_contact)
    setMothersFullName(response.data.user.mothers_full_name)
    setMothersEmail(response.data.user.mothers_email)
    setMothersCountryCode(response.data.user.mothers_country_code)
    setMothersContact(response.data.user.mothers_contact)
    setRegNo(response.data.user.goocampus_registration_number)
    if(response.data.user.passport_exist===1 ){
      setPassportExist(1)
    }else if(response.data.user.passport_exist===2){
      setPassportExist(2)
    }else{
      setPassportExist(0)
    }
    setPassportNumber(response.data.user.passport_number)
    setPassportIssueDate(response.data.user.passport_issue_date)
    setPassportExpiryDate(response.data.user.passport_expiry_date)
 if(response.data.user.user_agreement_flag===0 || response.data.user.user_agreement_flag===null){
  Swal.fire({
    title: "Profile Incomplete",
    text: "You won't be able to start services until profile is complete!",
    icon: "warning",
    showCancelButton: false,
    confirmButtonColor: "#3085d6",
    // cancelButtonColor: "#d33",
    confirmButtonText: "Complete Profile"
    
  }).then((result) => {
   
    if (result.isConfirmed) {
      
      
      redirect("/user_profile");
      window.location.href="/user_profile"
    }
  });
}
  })
  
  
  }, []);
  const logout=()=>
  {       
       localStorage.removeItem("loggedIn");
       setTimeout(function() { window.location.href="/userlogin" }, 1000);
      
  }

  if (loading) {
    return <Loader />;
  }
  return (
 
      <div className='container-fluid desktop_container' >
        <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name}/>
        <div className='horizontal-menu' style={{background:"transparent"}}>

         
            
         
          <br/>
          <div className='container-fluid userpath_right_pad user_foarm_board'>
    <div className='d-flex mb-2 mt-3 prog-info'>
        <div className='w-75 text-left'>
            <h1><b className='blue-color'>Hi, Dr. {first_name} <span className='support_hide'>{last_name}</span></b></h1>
        </div>
        <div className='w-25 text-right' >
            <Link to="/support" style={{ fontSize: "29px",color: "#233974" }} className='fw-bold nav-link'><FaRegQuestionCircle className='mb-1' />
            <span className='support_hide'> Support</span></Link>
        </div>
    </div>
    <div className='mb-5'>
        <UserProgressBar />
    </div>
    <div className='row'>
        <div className='col-md-4'>
            <Link className='blue-color' style={{ textDecoration: "none" }} to={"/user_profile_details"}>
                <div className='box-type pt-3 px-1'>
                    <div className='text-left pl-2 your_profile_top_card' style={{ color: "#233974", fontSize: "20px" }}>
                        <b>Your Profile</b>
                    </div>
                    <div className='row px-3 your_profile_details_margin'>
                        <div className='col-6 col-sm-5 text-left fw-bold'>Reg No :</div>
                        <div className='col-6 col-sm-7 text-left'>{regno}</div>
                    </div>
                    <div className='row px-3 your_profile_details_margin'>
                        <div className='col-6 col-sm-5 text-left fw-bold'>Joining Date :</div>
                        <div className='col-6 col-sm-7 text-left'>{moment(registration_date).format('Do MMMM YYYY')}</div>
                    </div>
                    <div className='row px-3 your_profile_details_margin'>
                        <div className='col-6 col-sm-5 text-left fw-bold'>Stage :</div>
                        <div className='col-6 col-sm-7 text-left'>{current_stage}</div>
                    </div>
                    <div className='row px-3 your_profile_details_margin'>
                        <div className='col-6 col-sm-5 text-left fw-bold'>Sub-Stage :</div>
                        <div className='col-6 col-sm-7 text-left'>{current_sub_stage}</div>
                    </div>
                </div>
            </Link>
        </div>
        <div className='col-md-4'>
            <div className='box-type pt-3 px-1'>
                <div className='text-left pl-2 mb-2' style={{ color: "#233974", fontSize: "20px" }}>
                    <b>Upcoming Events</b>
                </div>
            </div>
        </div>
        <div className='col-md-4'>
            <div className='box-type pt-3 px-1'>
                <div className='text-left pl-2 mb-2' style={{ color: "#233974", fontSize: "20px" }}>
                    <b>Upcoming Tests</b>
                </div>
            </div>
        </div>
    </div>
    <div className='row second-row'>
        <div className='col-md-4'>
            <div className='box-type'>
                <div className='text-left pl-3 pt-3 pb-2 mb-2' style={{ color: "#233974", fontSize: "20px" }}>
                    <b>Notifications</b>
                </div>
                <div className='card mx-3 mt-2 courses_pad' style={{ backgroundColor: "white", boxShadow: "4px 4px 4px 4px #d3d3d375" }}>
                    <div className='card-header bg-white' style={{ border: "none" }}></div>
                    <div className='card-body'></div>
                </div>
                <div className='card mx-3 mt-2 courses_pad' style={{ backgroundColor: "white", boxShadow: "4px 4px 4px 4px #d3d3d375" }}>
                    <div className='card-header bg-white' style={{ border: "none" }}></div>
                    <div className='card-body'></div>
                </div>
                <div className='card mx-3 mt-2 courses_pad' style={{ backgroundColor: "white", boxShadow: "4px 4px 4px 4px #d3d3d375" }}>
                    <div className='card-header bg-white' style={{ border: "none" }}></div>
                    <div className='card-body'></div>
                </div>
            </div>
        </div>
        <div className='col-md-4'>
            <div className='box-type'>
                <div className='text-left pl-3 pt-3 pb-2 mb-2' style={{ color: "#233974", fontSize: "20px" }}>
                    <b>Courses</b>
                </div>
                <div className='card mx-3 mt-2 courses_pad' style={{ backgroundColor: "white", boxShadow: "4px 4px 4px 4px #d3d3d375" }}>
                    <div className='card-header bg-white' style={{ border: "none" }}></div>
                    <div className='card-body'></div>
                </div>
                <div className='card mx-3 mt-2 courses_pad' style={{ backgroundColor: "white", boxShadow: "4px 4px 4px 4px #d3d3d375" }}>
                    <div className='card-header bg-white' style={{ border: "none" }}></div>
                    <div className='card-body'></div>
                </div>
                <div className='card mx-3 mt-2 courses_pad' style={{ backgroundColor: "white", boxShadow: "4px 4px 4px 4px #d3d3d375" }}>
                    <div className='card-header bg-white' style={{ border: "none" }}></div>
                    <div className='card-body'></div>
                </div>
            </div>
        </div>
        <div className='col-md-4'>
            <div className='box-type'>
                <div className='text-left pl-3 pt-3 pb-2 mb-2' style={{ color: "#233974", fontSize: "20px" }}>
                    <b>Reminders</b>
                </div>
                <div className='card mx-3 mt-2 courses_pad' style={{ backgroundColor: "white", boxShadow: "4px 4px 4px 4px #d3d3d375" }}>
                    <div className='card-header bg-white' style={{ border: "none" }}></div>
                    <div className='card-body'></div>
                </div>
                <div className='card mx-3 mt-2 courses_pad' style={{ backgroundColor: "white", boxShadow: "4px 4px 4px 4px #d3d3d375" }}>
                    <div className='card-header bg-white' style={{ border: "none" }}></div>
                    <div className='card-body'></div>
                </div>
                <div className='card mx-3 mt-2 courses_pad' style={{ backgroundColor: "white", boxShadow: "4px 4px 4px 4px #d3d3d375" }}>
                    <div className='card-header bg-white' style={{ border: "none" }}></div>
                    <div className='card-body'></div>
                </div>
            </div>
        </div>
    </div>
</div>


        </div>
      {/* <div className='d-flex'>
          
              <div className='headerbar' style={{width:"100%",left:"0"}}>
          <UserHeader />
        
  

      <h4 className='text-danger text-center'>Dashboard</h4>
      <hr className='bg-danger px-1' />
        <div className='container'>
            <div className='row'>

            </div>
            
        </div>
          </div>

            
      </div> */}
     
  </div>
    
  )
}

export default ClientDashboard