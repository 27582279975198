import React, { useEffect, useState } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import server from '../Utils';
import Loader from './Loader';
import { ServiceCategoryType } from '../../@types/service_category_type';
import { ProductData } from '../../@types/producttype';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GridApi, ColumnApi, ColDef } from 'ag-grid-community';
import { Select } from 'antd';

const { Option } = Select;

const Servicecategory: React.FC = () => {
  const [services, setServices] = useState<ServiceCategoryType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductData[]>([]);
  const [product_id, setProductId] = useState<number>(0);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);

  let serviceCategoryId: string = JSON.parse(localStorage.getItem("project_id") || '{}');
  let id = +serviceCategoryId;
  let projectId: string = JSON.parse(localStorage.getItem("project_id") || '{}');

  useEffect(() => {
    setLoading(true);
    Promise.all([
      server.get(`/get_product/${id}`),
      server.get(`/get_service_categories/${projectId}`)
    ]).then(([productResponse, serviceCategoriesResponse]) => {
      setLoading(false);
      if (productResponse.data.product.length > 0) {
        setProducts(productResponse.data.product);
      } else {
        setProducts([]);
      }
      setServices(serviceCategoriesResponse.data.service_categories);
    }).catch(error => {
      setLoading(false);
      console.error('Error fetching data:', error);
    });
  }, [id, projectId]);

  const displayStages = (productid: number) => {
    setProductId(productid);
    server.get(`/get_service_categories_all/${id}/${productid}`)
      .then((response) => {
        setServices(response.data.service_categories);
      });
  };

  const deleteServiceCategory = (deleteId: number) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });

    swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        server.delete(`/delete_service_category/${deleteId}`)
          .then((response) => {
            setServices(response.data.service_categories);
            swalWithBootstrapButtons.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success"
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your imaginary file is safe :)",
          icon: "error"
        });
      }
    });
  };

  const columns: ColDef[] = [
    { headerName: 'SlNo', valueGetter: "node.rowIndex + 1", sortable: true, filter: false, flex: 1,floatingFilter:false },
    { headerName: 'Product Name', field: 'product_name', sortable: true, filter: true, flex: 1 ,floatingFilter:true},
    { headerName: 'Service Category Name', field: 'service_category_name', sortable: true, filter: true, flex: 1 ,floatingFilter:true},
    {
      headerName: 'Actions', flex: 1, cellRenderer: (params: { data: ServiceCategoryType }) => (
        <div className="row">
          <div className="col-md-6">
            <Link to={`/service_category_edit/${params.data.id}`}>
              <i className="fa fa-edit text-success"></i>
            </Link>
          </div>
          <div className="col-md-6">
            <i onClick={() => deleteServiceCategory(params.data.id)} className="fa fa-trash text-danger"></i>
          </div>
        </div>
      ),
      sortable: false,
      filter: false,
    }
  ];

  const onGridReady = (params: { api: GridApi, columnApi: ColumnApi }) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='container-fluid'>
      <div className='d-flex'>
        <SideBar />
        <div className='headerbar'>
          <Header />
          <h4 className='text-danger text-center'>Service Category</h4>
          <hr className='bg-danger px-1' />
          <div className="row px-3">
            <div className="col-md-6">
              <Select className='w-50 my-3' value={product_id} onChange={(value: number) => displayStages(value)}>
                <Option value={0}>Select Product</Option>
                {products.map((product, index) => (
                  <Option key={product.id} value={product.id}>{product.product_name}</Option>
                ))}
              </Select>
            </div>
            <div className="col-md-6">
              <Link to="/add_service_category" className='btn btn-outline-primary float-right my-3'>Add Service Category</Link>
            </div>
          </div>
          <div className='row mt-2 px-3'>
            <div className='col-md-12'>
              <div className='ag-theme-alpine' style={{ flex: 1, width: '100%' }}>
                <AgGridReact
                  onGridReady={onGridReady}
                  columnDefs={columns}
                  rowData={services}
                  defaultColDef={{ sortable: true, filter: true }}
                  pagination={true}
                  paginationPageSize={10}
                  domLayout='autoHeight'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servicecategory;
