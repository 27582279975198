import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import { ServiceData } from '../../@types/servicetype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import {Link,useParams} from 'react-router-dom';
import server from '../Utils';
import validator from 'validator';
import Loader from './Loader';
import { Select } from 'antd';
const UserProfileEdit = () => {
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [loading, setLoading] = useState(false);

    const [project_id,setProjectId]=useState<number>(0);
    const [product_id,setProductId]=useState<number>(0);
    const [service_category_id,setServiceCategoryId]=useState<number>(0);
    const [service_categories,setServiceCategories]=useState<Array<ServiceCategoryType>>([])
    const [services,setServices]=useState<Array<ServiceData>>([])
    const [service_id,setServiceId]=useState<number>(0)
    const {id}=useParams();
  
  
  const [first_name,setfirstName]=useState<string>("");
  const [last_name,setlastName]=useState<string>('');
  const [passport_first_name,setPassportFirstName]=useState<string>("")
  const [passport_last_name,setPassportlastName]=useState<string>("")
  const [certificate_first_name,setCertificateFirstName]=useState<string>('')
  const [certificate_last_name,setCertificateLastName]=useState<string>('')
  const [primary_contact_country_code,setPrimaryCountryCode]=useState<string>('')
  const [primary_contact,setPrimaryContact]=useState<string>('')
  const [alernate_country_code,setAlternateCountryCode]=useState<string>('')
  const [alternate_phone_number,setAlternatePhoneNumber]=useState<string>('')
  const [user_email,setUserEmail]=useState<string>('')
  const [password,setPassword]=useState<string>('');
  const [last_password,setLastPassword]=useState<string>('');
  const [dob,setDob]=useState<string>('');
  const [fathers_full_name,setFathersFullName]=useState<string>('')
  const [fathers_email,setFathersEmail]=useState<string>('')
  const [fathers_contact_country_code,setFathersContactCountryCode]=useState('')
  const [fathers_contact,setFathersContact]=useState<number|undefined>()
  const [mothers_full_name,setMothersFullName]=useState<string>()
  const [mothers_email,setMothersEmail]=useState<string>('');
  const [mothers_country_code,setMothersCountryCode]=useState<string>();
  const [mothers_contact,setMothersContact]=useState<number|undefined>();
  const [passport_exist,setPassportExist]=useState<number>(0);
  const [passport_number,setPassportNumber]=useState<string>('');
  const [passport_issue_date,setPassportIssueDate]=useState<string>('');
  const [passport_expiry_date,setPassportExpiryDate]=useState<string>(''); 
  const [gender,setGender]=useState<number>(0); 
  

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const response = await server.get(`/operationuser/${id}`);
        const userData = response.data.user;

        setfirstName(userData.first_name);
        setlastName(userData.last_name);
        setPassportFirstName(userData.passport_first_name);
        setPassportlastName(userData.passport_last_name);
        setCertificateFirstName(userData.certificate_first_name);
        setCertificateLastName(userData.certificate_last_name);
        setPrimaryCountryCode(userData.primary_contact_country_code);
        setPrimaryContact(userData.primary_contact);
        setGender(userData.gender);
        setAlternateCountryCode(userData.alernate_country_code);
        setAlternatePhoneNumber(userData.alternate_phone_number);
        setUserEmail(userData.user_email);
        setPassword(userData.last_password);
        setLastPassword(userData.last_password);
        setDob(userData.dob);
        setFathersFullName(userData.fathers_full_name);
        setFathersEmail(userData.fathers_email);
        setFathersContactCountryCode(userData.fathers_contact_country_code);
        setFathersContact(userData.fathers_contact);
        setMothersFullName(userData.mothers_full_name);
        setMothersEmail(userData.mothers_email);
        setMothersCountryCode(userData.mothers_country_code);
        setMothersContact(userData.mothers_contact);
        setPassportExist(userData.passport_exist);
        setPassportNumber(userData.passport_number);
        setPassportIssueDate(userData.passport_issue_date);
        setPassportExpiryDate(userData.passport_expiry_date);
      } catch (error) {
        console.error("Error fetching user data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id]);
  
const submitUserPersonalDetails=(e:React.FormEvent<HTMLFormElement>)=>
{
  e.preventDefault();
  const re = /^[A-Za-z\s]*$/;
  const ne =/^[0-9]+$/;
//   const passport_last_name=document.getElementById('passport_last_name') as HTMLInputElement;
//   const passport_first_name=document.getElementById('passport_first_name')as HTMLInputElement;
//   const certificate_first_name=document.getElementById('certificate_first_name')as HTMLInputElement;
//   const  certificate_last_name =document.getElementById('certificate_last_name') as HTMLInputElement;
//   const user_email =document.getElementById('user_email') as HTMLInputElement;
//   const dob=document.getElementById('dob') as HTMLInputElement;
//   const primary_contact_country_code=document.getElementById('primary_contact_country_code') as HTMLInputElement;
//   const primary_contact=document.getElementById('primary_contact') as HTMLInputElement;
//   const alernate_country_code=document.getElementById('alernate_country_code') as HTMLInputElement;
//   const alternate_phone_number=document.getElementById('alternate_phone_number') as HTMLInputElement;
//   const fathers_full_name=document.getElementById('fathers_full_name') as HTMLInputElement;
//   const fathers_email=document.getElementById('fathers_email') as HTMLInputElement;
//   const fathers_contact_country_code=document.getElementById('fathers_contact_country_code') as HTMLInputElement;
//   const fathers_contact=document.getElementById('fathers_contact') as HTMLInputElement;
//   const mothers_full_name=document.getElementById('mothers_full_name') as HTMLInputElement;
//   const mothers_email=document.getElementById('mothers_email') as HTMLInputElement;
//   const mothers_country_code=document.getElementById('mothers_country_code') as HTMLInputElement;
//   const mothers_contact=document.getElementById('mothers_contact') as HTMLInputElement;
// // const passport_exist=document.getElementById('passport_exist') as HTMLInputElement;
//   const passport_number=document.getElementById('passport_number') as HTMLInputElement;
//   const passport_issue_date=document.getElementById('passport_issue_date') as HTMLInputElement;
//   const passport_expiry_date=document.getElementById('passport_expiry_date') as HTMLInputElement;
let date1 = new Date(passport_issue_date).getTime();
let date2 = new Date(passport_expiry_date).getTime();
  
  
  if(!passport_first_name.match(re)){
     toast('Passport first name should be aplhabets only');
   }else if(!passport_last_name.match(re))
   {
    toast('Passport last name should be aplhabets only');
   }else if(!certificate_first_name.match(re))
   {
    toast('Certificate first name should be aplhabets only');
   }else if(!certificate_last_name.match(re)){
    toast('Certificate last name should be aplhabets only');
   }else if(gender===0){
    toast('Select Gender');
   }
   else if(!validator.isEmail(user_email)){

    toast('Enter proper email');
   
  }else if(primary_contact_country_code.length>2 && primary_contact_country_code.length<1){
    toast("Primary contact code should be between 2 to 3 characters");
  }
  else if(primary_contact.length>10 && primary_contact.length<1 ){
      toast("Primary contact number Should be 10 digits");
    }else if(alernate_country_code.length>2 && alernate_country_code.length<1)
    {
     toast("Alternate contact code should be between 2 to 3 characters");
    }else if(alternate_phone_number.length>10 && alternate_phone_number.length<1){
      toast("Alternate contact number Should be 10 digits");
    }else if(!fathers_full_name.match(re))
    {
      toast("Fathers  Name should be aplhabets only");
    }else if(fathers_email!==null && !validator.isEmail(fathers_email))
    {
      toast("Invalid Fathers Email");
    }else if(mothers_email!==null && !validator.isEmail(mothers_email))
    {
      toast("Invalid Fathers Email");
    }
    else if(passport_exist===0){
      toast("Please select passport status");
    }

    else if(passport_exist && passport_number.length>8){
      
        toast('passport digits should be one alphabet and 7 numbers');
    }else if(date1===date2){
      toast('passport issue date and passport expiry date cant be equal')
    }
    
    else if(date1>date2)
      {
        toast('passport expiry date should be greater than passport issue date');
      }else{
    
      
      var form = document.getElementById('user_presonal_details_form') as HTMLFormElement;
      var formdata= new FormData(form);
        setLoading(true)
      server.post(`/add_personal_details/${id}`,formdata)
      .then((response)=>{
       if(response.data.code===200){
        setLoading(false)
        Swal.fire({
          title:"Details Saved!",
          text: "Details Saved Successfully",
          icon: "success"
        });
        
       }else{
        setLoading(false)
        toast(response.data.message);
       }
      })}
}

if (loading) {
  return <Loader />;
} 
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}


    <h4 className='text-danger text-center'>Edit User Profile</h4>
    <hr className='bg-danger px-1' />
    <div className='user_foarm_board userpath_right_pad pt-2 mx-3 mt-5'>
         <h3 className='text-left my-4 blue-color fw-bold'>Personal Details</h3>
           <form className='w-100' id="user_presonal_details_form" onSubmit={submitUserPersonalDetails}>
            <input type='hidden' name="form_progress" id="form_progress" value={1}/>
             <div className='row mt-3'>
            
           
              <div className='col-md-3'>
               <label className="blue-color">First Name<br/> <span  style={{fontSize:"10px"}} >(According to Aadhaar / Passport)</span> <b className="text-danger">*</b></label>
               <input type="text" className='form-control rounded user_input_dashboard' name="passport_first_name" id="passport_first_name" value={passport_first_name} onChange={(e)=>setPassportFirstName(e.target.value)}  placeholder='Passport First Name' required />
              </div>
              <div className='col-md-3'>
               <label className="blue-color">Last Name<br/> <span style={{fontSize:"10px"}} >(According to Aadhaar / Passport)</span><b className="text-danger">*</b></label>
               <input type="text"className='form-control rounded user_input_dashboard'  name="passport_last_name" id="passport_last_name"  value={passport_last_name} onChange={(e)=>setPassportlastName(e.target.value)} placeholder='Passport Last Name' required/>
              </div>
              <div className='col-md-3'>
               <label className="blue-color">First Name <br/> <span style={{fontSize:"10px"}} >(According to Degree Certificate)</span><b className="text-danger">*</b></label>
               <input type="text"className='form-control rounded user_input_dashboard'  name="certificate_first_name" id="certificate_first_name" value={certificate_first_name}  onChange={(e)=>setCertificateFirstName(e.target.value)}  placeholder='Certificate First Name' required/>
              </div>
              <div className='col-md-3'>
               <label className="blue-color">Last Name <br/> <span style={{fontSize:"10px"}} >(According to Degree Certificate)</span><b className="text-danger">*</b></label>
               <input type="text"className='form-control rounded user_input_dashboard'  name="certificate_last_name" id="certificate_last_name" value={certificate_last_name} onChange={(e)=>setCertificateLastName(e.target.value)}  placeholder='Certificate Last Name' required/>
              </div>
             
             </div>
            <div className='row my-4'>
            <div className="col-md-2">
               <label className="blue-color">Gender<b className="text-danger">*</b></label>
               <select className='form-control rounded user_input_dashboard mt-1' id="gender" name="gender" value={gender} onChange={(e)=>setGender(parseInt(e.target.value))}>
                 <option value={0}>Select Gender</option>
                 <option value={1}>Male</option>
                 <option value={2}>Female</option>
                 <option value={3}>Others</option>
               </select>
             </div>
             <div className='col-md-3'>
                <label className="blue-color">Email<b className="text-danger">*</b></label>
                 <input type="email" className='form-control rounded user_input_dashboard' name="user_email" id="user_email"  value={user_email} placeholder='Enter Email' onChange={(e)=>setUserEmail(e.target.value)} required/>
              </div>
              <div className='col-md-2'>
                 <label className="blue-color mt-2">Date of Birth<b className="text-danger">*</b></label>
                 <input type="date" className='form-control rounded user_input_dashboard'  name="dob" id="dob"  value={dob} placeholder='Enter Date Of Birth' onChange={(e)=>setDob(e.target.value)} required/>
              </div>
            </div>
             
             <div className='row mt-4'>
              
             

              <div className="col-md-4">
              <label className="blue-color">Primary Contact Number<b className="text-danger">*</b></label>
                <div className='d-flex mt-1'>
                
                
                <div className="user_profile_contact_code">
                 
                 {/* <input type="text" className='form-control rounded user_input_dashboard'  name="primary_contact_country_code" id="primary_contact_country_code"  value={primary_contact_country_code} placeholder='Enter Date Of Birth' onChange={(e)=>setPrimaryCountryCode(e.target.value)} required/> */}
                 {/* <select  className='form-control rounded user_input_dashboard' name="primary_contact_country_code" id="primary_contact_country_code" value={primary_contact_country_code!==""?primary_contact_country_code:"91"} onChange={(e)=>setPrimaryCountryCode(e.target.value)} required > */}
                 <Select
               id='primary_contact_country_code'
               data-name='primary_contact_country_code'
               // name={'fathers_contact_country_code'}
               className='w-100 user_input_dashboard'
               value={primary_contact_country_code}
               onChange={(value) => setPrimaryCountryCode(value)}
               showSearch
               placeholder='Select Country Code'
            optionFilterProp="children"
 >
                  
                   <option data-countryCode="DZ" value="213">+213</option>
                   <option data-countryCode="AD" value="376">+376</option>
                   <option data-countryCode="AO" value="244">+244</option>
                   <option data-countryCode="AI" value="1264">+1264</option>
                   <option data-countryCode="AG" value="1268">+1268</option>
                   <option data-countryCode="AR" value="54">+54</option>
                   <option data-countryCode="AM" value="374">+374</option>
                   <option data-countryCode="AW" value="297">+297</option>
                   <option data-countryCode="AU" value="61">+61</option>
                   <option data-countryCode="AT" value="43">+43</option>
                   <option data-countryCode="AZ" value="994">+994</option>
                   <option data-countryCode="BS" value="1242">+1242</option>
                   <option data-countryCode="BH" value="973">+973</option>
                   <option data-countryCode="BD" value="880">+880</option>
                   <option data-countryCode="BB" value="1246">+1246</option>
                   <option data-countryCode="BY" value="375">+375</option>
                   <option data-countryCode="BE" value="32">+32</option>
                   <option data-countryCode="BZ" value="501">+501</option>
                   <option data-countryCode="BJ" value="229">+229</option>
                   <option data-countryCode="BM" value="1441">+1441</option>
                   <option data-countryCode="BT" value="975">+975</option>
                   <option data-countryCode="BO" value="591">+591</option>
                   <option data-countryCode="BA" value="387">+387</option>
                   <option data-countryCode="BW" value="267">+267</option>
                   <option data-countryCode="BR" value="55">+55</option>
                   <option data-countryCode="BN" value="673">+673</option>
                   <option data-countryCode="BG" value="359">+359</option>
                   <option data-countryCode="BF" value="226">+226</option>
                   <option data-countryCode="BI" value="257">+257</option>
                   <option data-countryCode="KH" value="855">+855</option>
                   <option data-countryCode="CM" value="237">+237</option>
                   <option data-countryCode="CA" value="1">+1</option>
                   <option data-countryCode="CV" value="238">+238</option>
                   <option data-countryCode="KY" value="1345">+1345</option>
                   <option data-countryCode="CF" value="236">+236</option>
                   <option data-countryCode="CL" value="56">+56</option>
                   <option data-countryCode="CN" value="86">+86</option>
                   <option data-countryCode="CO" value="57">+57</option>
                   <option data-countryCode="KM" value="269">+269</option>
                   <option data-countryCode="CG" value="242">+242</option>
                   <option data-countryCode="CK" value="682">+682</option>
                   <option data-countryCode="CR" value="506">+506</option>
                   <option data-countryCode="HR" value="385">+385</option>
                   <option data-countryCode="CU" value="53">+53</option>
                   <option data-countryCode="CY" value="90392">+90392</option>
                   <option data-countryCode="CY" value="357">+357</option>
                   <option data-countryCode="CZ" value="42">+42</option>
                   <option data-countryCode="DK" value="45">+45</option>
                   <option data-countryCode="DJ" value="253">+253</option>
                   <option data-countryCode="DM" value="1809">+1809</option>
                   <option data-countryCode="DO" value="1809">+1809</option>
                   <option data-countryCode="EC" value="593">+593</option>
                   <option data-countryCode="EG" value="20">+20</option>
                   <option data-countryCode="SV" value="503">+503</option>
                   <option data-countryCode="GQ" value="240">+240</option>
                   <option data-countryCode="ER" value="291">+291</option>
                   <option data-countryCode="EE" value="372">+372</option>
                   <option data-countryCode="ET" value="251">+251</option>
                   <option data-countryCode="FK" value="500">+500</option>
                   <option data-countryCode="FO" value="298">+298</option>
                   <option data-countryCode="FJ" value="679">+679</option>
                   <option data-countryCode="FI" value="358">+358</option>
                   <option data-countryCode="FR" value="33">+33</option>
                   <option data-countryCode="GF" value="594">+594</option>
                   <option data-countryCode="PF" value="689">+689</option>
                   <option data-countryCode="GA" value="241">+241</option>
                   <option data-countryCode="GM" value="220">+220</option>
                   <option data-countryCode="GE" value="7880">+7880</option>
                   <option data-countryCode="DE" value="49">+49</option>
                   <option data-countryCode="GH" value="233">+233</option>
                   <option data-countryCode="GI" value="350">+350</option>
                   <option data-countryCode="GR" value="30">+30</option>
                   <option data-countryCode="GL" value="299">+299</option>
                   <option data-countryCode="GD" value="1473">+1473</option>
                   <option data-countryCode="GP" value="590">+590</option>
                   <option data-countryCode="GU" value="671">+671</option>
                   <option data-countryCode="GT" value="502">+502</option>
                   <option data-countryCode="GN" value="224">+224</option>
                   <option data-countryCode="GW" value="245">+245</option>
                   <option data-countryCode="GY" value="592">+592</option>
                   <option data-countryCode="HT" value="509">+509</option>
                   <option data-countryCode="HN" value="504">+504</option>
                   <option data-countryCode="HK" value="852">+852</option>
                   <option data-countryCode="HU" value="36">+36</option>
                   <option data-countryCode="IS" value="354">+354</option>
                   <option data-countryCode="IN" value="91">+91</option>
                   <option data-countryCode="ID" value="62">+62</option>
                   <option data-countryCode="IR" value="98">+98</option>
                   <option data-countryCode="IQ" value="964">+964</option>
                   <option data-countryCode="IE" value="353">+353</option>
                   <option data-countryCode="IL" value="972">+972</option>
                   <option data-countryCode="IT" value="39">+39</option>
                   <option data-countryCode="JM" value="1876">+1876</option>
                   <option data-countryCode="JP" value="81">+81</option>
                   <option data-countryCode="JO" value="962">+962</option>
                   <option data-countryCode="KZ" value="7">+7</option>
                   <option data-countryCode="KE" value="254">+254</option>
                   <option data-countryCode="KI" value="686">+686</option>
                   <option data-countryCode="KP" value="850">+850</option>
                   <option data-countryCode="KR" value="82">+82</option>
                   <option data-countryCode="KW" value="965">+965</option>
                   <option data-countryCode="KG" value="996">+996</option>
                   <option data-countryCode="LA" value="856">+856</option>
                   <option data-countryCode="LV" value="371">+371</option>
                   <option data-countryCode="LB" value="961">+961</option>
                   <option data-countryCode="LS" value="266">+266</option>
                   <option data-countryCode="LR" value="231">+231</option>
                   <option data-countryCode="LY" value="218">+218</option>
                   <option data-countryCode="LI" value="417">+417</option>
                   <option data-countryCode="LT" value="370">+370</option>
                   <option data-countryCode="LU" value="352">+352</option>
                   <option data-countryCode="MO" value="853">+853</option>
                   <option data-countryCode="MK" value="389">+389</option>
                   <option data-countryCode="MG" value="261">+261</option>
                   <option data-countryCode="MW" value="265">+265</option>
                   <option data-countryCode="MY" value="60">+60</option>
                   <option data-countryCode="MV" value="960">+960</option>
                   <option data-countryCode="ML" value="223">+223</option>
                   <option data-countryCode="MT" value="356">+356</option>
                   <option data-countryCode="MH" value="692">+692</option>
                   <option data-countryCode="MQ" value="596">+596</option>
                   <option data-countryCode="MR" value="222">+222</option>
                   <option data-countryCode="YT" value="269">+269</option>
                   <option data-countryCode="MX" value="52">+52</option>
                   <option data-countryCode="FM" value="691">+691</option>
                   <option data-countryCode="MD" value="373">+373</option>
                   <option data-countryCode="MC" value="377">+377</option>
                   <option data-countryCode="MN" value="976">+976</option>
                   <option data-countryCode="MS" value="1664">+1664</option>
                   <option data-countryCode="MA" value="212">+212</option>
                   <option data-countryCode="MZ" value="258">+258</option>
                   <option data-countryCode="MN" value="95">+95</option>
                   <option data-countryCode="NA" value="264">+264</option>
                   <option data-countryCode="NR" value="674">+674</option>
                   <option data-countryCode="NP" value="977">+977</option>
                   <option data-countryCode="NL" value="31">+31</option>
                   <option data-countryCode="NC" value="687">+687</option>
                   <option data-countryCode="NZ" value="64">+64</option>
                   <option data-countryCode="NI" value="505">+505</option>
                   <option data-countryCode="NE" value="227">+227</option>
                   <option data-countryCode="NG" value="234">+234</option>
                   <option data-countryCode="NU" value="683">+683</option>
                   <option data-countryCode="NF" value="672">+672</option>
                   <option data-countryCode="NP" value="670">+670</option>
                   <option data-countryCode="NO" value="47">+47</option>
                   <option data-countryCode="OM" value="968">+968</option>
                   <option data-countryCode="PW" value="680">+680</option>
                   <option data-countryCode="PA" value="507">+507</option>
                   <option data-countryCode="PG" value="675">+675</option>
                   <option data-countryCode="PY" value="595">+595</option>
                   <option data-countryCode="PE" value="51">+51</option>
                   <option data-countryCode="PH" value="63">+63</option>
                   <option data-countryCode="PL" value="48">+48</option>
                   <option data-countryCode="PT" value="351">+351</option>
                   <option data-countryCode="PR" value="1787">+1787</option>
                   <option data-countryCode="QA" value="974">+974</option>
                   <option data-countryCode="RE" value="262">+262</option>
                   <option data-countryCode="RO" value="40">+40</option>
                   <option data-countryCode="RU" value="7">+7</option>
                   <option data-countryCode="RW" value="250">+250</option>
                   <option data-countryCode="SM" value="378">+378</option>
                   <option data-countryCode="ST" value="239">+239</option>
                   <option data-countryCode="SA" value="966">+966</option>
                   <option data-countryCode="SN" value="221">+221</option>
                   <option data-countryCode="CS" value="381">+381</option>
                   <option data-countryCode="SC" value="248">+248</option>
                   <option data-countryCode="SL" value="232">+232</option>
                   <option data-countryCode="SG" value="65">+65</option>
                   <option data-countryCode="SK" value="421">+421</option>
                   <option data-countryCode="SI" value="386">+386</option>
                   <option data-countryCode="SB" value="677">+677</option>
                   <option data-countryCode="SO" value="252">+252</option>
                   <option data-countryCode="ZA" value="27">+27</option>
                   <option data-countryCode="ES" value="34">+34</option>
                   <option data-countryCode="LK" value="94">+94</option>
                   <option data-countryCode="SH" value="290">+290</option>
                   <option data-countryCode="KN" value="1869">+1869</option>
                   <option data-countryCode="SC" value="1758">+1758</option>
                   <option data-countryCode="SD" value="249">+249</option>
                   <option data-countryCode="SR" value="597">+597</option>
                   <option data-countryCode="SZ" value="268">+268</option>
                   <option data-countryCode="SE" value="46">+46</option>
                   <option data-countryCode="CH" value="41">+41</option>
                   <option data-countryCode="SI" value="963">+963</option>
                   <option data-countryCode="TW" value="886">+886</option>
                   <option data-countryCode="TJ" value="7">+7</option>
                   <option data-countryCode="TH" value="66">+66</option>
                   <option data-countryCode="TG" value="228">+228</option>
                   <option data-countryCode="TO" value="676">+676</option>
                   <option data-countryCode="TT" value="1868">+1868</option>
                   <option data-countryCode="TN" value="216">+216</option>
                   <option data-countryCode="TR" value="90">+90</option>
                   <option data-countryCode="TM" value="7">+7</option>
                   <option data-countryCode="TM" value="993">+993</option>
                   <option data-countryCode="TC" value="1649">+1649</option>
                   <option data-countryCode="TV" value="688">+688</option>
                   <option data-countryCode="UG" value="256">+256</option>
                   <option data-countryCode="GB" value="44">+44</option>
                   <option data-countryCode="UA" value="380">+380</option>
                   <option data-countryCode="AE" value="971">+971</option>
                   <option data-countryCode="UY" value="598">+598</option>
                   <option data-countryCode="US" value="1">+1</option>
                   <option data-countryCode="UZ" value="7">+7</option>
                   <option data-countryCode="VU" value="678">+678</option>
                   <option data-countryCode="VA" value="379">+379</option>
                   <option data-countryCode="VE" value="58">+58</option>
                   <option data-countryCode="VN" value="84">+84</option>
                   <option data-countryCode="VG" value="84">+1284</option>
                   <option data-countryCode="VI" value="84">+1340</option>
                   <option data-countryCode="WF" value="681">+681</option>
                   <option data-countryCode="YE" value="969">+969</option>
                   <option data-countryCode="YE" value="967">+967</option>
                   <option data-countryCode="ZM" value="260">+260</option>
                   <option data-countryCode="ZW" value="263">+263</option>    
             </Select>
             <input type="hidden" name={'primary_contact_country_code'} value={primary_contact_country_code}/>
              </div>
              <div className='w-75 px-1'>
                 
                 <input type="number" className='form-control rounded user_input_dashboard'  name="primary_contact" id="primary_contact"  value={primary_contact} placeholder='Enter phone number' onChange={(e)=>setPrimaryContact(e.target.value)} required/>
              </div>
                </div>
              </div>

              <div className='col-md-4 '>
              <label className="blue-color">Alternate Contact Number<b className="text-danger">*</b></label>
                <div className='d-flex mt-1'>
                     <div className="user_profile_contact_code">
               
                 {/* <select  className='form-control rounded user_input_dashboard' name="alernate_country_code" id="alernate_country_code" value={alernate_country_code!==""?alernate_country_code:"91"} onChange={(e)=>setAlternateCountryCode(e.target.value)} required > */}
                 <Select
               id='alernate_country_code'
               data-name='alernate_country_code'
               // name={'fathers_contact_country_code'}
               className='w-100 user_input_dashboard'
               value={alernate_country_code}
               onChange={(value) => setAlternateCountryCode(value)}
               showSearch
               placeholder='Select Country Code'
              optionFilterProp="children"
 >
                   <option data-countryCode="DZ" value="213">+213</option>
                   <option data-countryCode="AD" value="376">+376</option>
                   <option data-countryCode="AO" value="244">+244</option>
                   <option data-countryCode="AI" value="1264">+1264</option>
                   <option data-countryCode="AG" value="1268">+1268</option>
                   <option data-countryCode="AR" value="54">+54</option>
                   <option data-countryCode="AM" value="374">+374</option>
                   <option data-countryCode="AW" value="297">+297</option>
                   <option data-countryCode="AU" value="61">+61</option>
                   <option data-countryCode="AT" value="43">+43</option>
                   <option data-countryCode="AZ" value="994">+994</option>
                   <option data-countryCode="BS" value="1242">+1242</option>
                   <option data-countryCode="BH" value="973">+973</option>
                   <option data-countryCode="BD" value="880">+880</option>
                   <option data-countryCode="BB" value="1246">+1246</option>
                   <option data-countryCode="BY" value="375">+375</option>
                   <option data-countryCode="BE" value="32">+32</option>
                   <option data-countryCode="BZ" value="501">+501</option>
                   <option data-countryCode="BJ" value="229">+229</option>
                   <option data-countryCode="BM" value="1441">+1441</option>
                   <option data-countryCode="BT" value="975">+975</option>
                   <option data-countryCode="BO" value="591">+591</option>
                   <option data-countryCode="BA" value="387">+387</option>
                   <option data-countryCode="BW" value="267">+267</option>
                   <option data-countryCode="BR" value="55">+55</option>
                   <option data-countryCode="BN" value="673">+673</option>
                   <option data-countryCode="BG" value="359">+359</option>
                   <option data-countryCode="BF" value="226">+226</option>
                   <option data-countryCode="BI" value="257">+257</option>
                   <option data-countryCode="KH" value="855">+855</option>
                   <option data-countryCode="CM" value="237">+237</option>
                   <option data-countryCode="CA" value="1">+1</option>
                   <option data-countryCode="CV" value="238">+238</option>
                   <option data-countryCode="KY" value="1345">+1345</option>
                   <option data-countryCode="CF" value="236">+236</option>
                   <option data-countryCode="CL" value="56">+56</option>
                   <option data-countryCode="CN" value="86">+86</option>
                   <option data-countryCode="CO" value="57">+57</option>
                   <option data-countryCode="KM" value="269">+269</option>
                   <option data-countryCode="CG" value="242">+242</option>
                   <option data-countryCode="CK" value="682">+682</option>
                   <option data-countryCode="CR" value="506">+506</option>
                   <option data-countryCode="HR" value="385">+385</option>
                   <option data-countryCode="CU" value="53">+53</option>
                   <option data-countryCode="CY" value="90392">+90392</option>
                   <option data-countryCode="CY" value="357">+357</option>
                   <option data-countryCode="CZ" value="42">+42</option>
                   <option data-countryCode="DK" value="45">+45</option>
                   <option data-countryCode="DJ" value="253">+253</option>
                   <option data-countryCode="DM" value="1809">+1809</option>
                   <option data-countryCode="DO" value="1809">+1809</option>
                   <option data-countryCode="EC" value="593">+593</option>
                   <option data-countryCode="EG" value="20">+20</option>
                   <option data-countryCode="SV" value="503">+503</option>
                   <option data-countryCode="GQ" value="240">+240</option>
                   <option data-countryCode="ER" value="291">+291</option>
                   <option data-countryCode="EE" value="372">+372</option>
                   <option data-countryCode="ET" value="251">+251</option>
                   <option data-countryCode="FK" value="500">+500</option>
                   <option data-countryCode="FO" value="298">+298</option>
                   <option data-countryCode="FJ" value="679">+679</option>
                   <option data-countryCode="FI" value="358">+358</option>
                   <option data-countryCode="FR" value="33">+33</option>
                   <option data-countryCode="GF" value="594">+594</option>
                   <option data-countryCode="PF" value="689">+689</option>
                   <option data-countryCode="GA" value="241">+241</option>
                   <option data-countryCode="GM" value="220">+220</option>
                   <option data-countryCode="GE" value="7880">+7880</option>
                   <option data-countryCode="DE" value="49">+49</option>
                   <option data-countryCode="GH" value="233">+233</option>
                   <option data-countryCode="GI" value="350">+350</option>
                   <option data-countryCode="GR" value="30">+30</option>
                   <option data-countryCode="GL" value="299">+299</option>
                   <option data-countryCode="GD" value="1473">+1473</option>
                   <option data-countryCode="GP" value="590">+590</option>
                   <option data-countryCode="GU" value="671">+671</option>
                   <option data-countryCode="GT" value="502">+502</option>
                   <option data-countryCode="GN" value="224">+224</option>
                   <option data-countryCode="GW" value="245">+245</option>
                   <option data-countryCode="GY" value="592">+592</option>
                   <option data-countryCode="HT" value="509">+509</option>
                   <option data-countryCode="HN" value="504">+504</option>
                   <option data-countryCode="HK" value="852">+852</option>
                   <option data-countryCode="HU" value="36">+36</option>
                   <option data-countryCode="IS" value="354">+354</option>
                   <option data-countryCode="IN" value="91">+91</option>
                   <option data-countryCode="ID" value="62">+62</option>
                   <option data-countryCode="IR" value="98">+98</option>
                   <option data-countryCode="IQ" value="964">+964</option>
                   <option data-countryCode="IE" value="353">+353</option>
                   <option data-countryCode="IL" value="972">+972</option>
                   <option data-countryCode="IT" value="39">+39</option>
                   <option data-countryCode="JM" value="1876">+1876</option>
                   <option data-countryCode="JP" value="81">+81</option>
                   <option data-countryCode="JO" value="962">+962</option>
                   <option data-countryCode="KZ" value="7">+7</option>
                   <option data-countryCode="KE" value="254">+254</option>
                   <option data-countryCode="KI" value="686">+686</option>
                   <option data-countryCode="KP" value="850">+850</option>
                   <option data-countryCode="KR" value="82">+82</option>
                   <option data-countryCode="KW" value="965">+965</option>
                   <option data-countryCode="KG" value="996">+996</option>
                   <option data-countryCode="LA" value="856">+856</option>
                   <option data-countryCode="LV" value="371">+371</option>
                   <option data-countryCode="LB" value="961">+961</option>
                   <option data-countryCode="LS" value="266">+266</option>
                   <option data-countryCode="LR" value="231">+231</option>
                   <option data-countryCode="LY" value="218">+218</option>
                   <option data-countryCode="LI" value="417">+417</option>
                   <option data-countryCode="LT" value="370">+370</option>
                   <option data-countryCode="LU" value="352">+352</option>
                   <option data-countryCode="MO" value="853">+853</option>
                   <option data-countryCode="MK" value="389">+389</option>
                   <option data-countryCode="MG" value="261">+261</option>
                   <option data-countryCode="MW" value="265">+265</option>
                   <option data-countryCode="MY" value="60">+60</option>
                   <option data-countryCode="MV" value="960">+960</option>
                   <option data-countryCode="ML" value="223">+223</option>
                   <option data-countryCode="MT" value="356">+356</option>
                   <option data-countryCode="MH" value="692">+692</option>
                   <option data-countryCode="MQ" value="596">+596</option>
                   <option data-countryCode="MR" value="222">+222</option>
                   <option data-countryCode="YT" value="269">+269</option>
                   <option data-countryCode="MX" value="52">+52</option>
                   <option data-countryCode="FM" value="691">+691</option>
                   <option data-countryCode="MD" value="373">+373</option>
                   <option data-countryCode="MC" value="377">+377</option>
                   <option data-countryCode="MN" value="976">+976</option>
                   <option data-countryCode="MS" value="1664">+1664</option>
                   <option data-countryCode="MA" value="212">+212</option>
                   <option data-countryCode="MZ" value="258">+258</option>
                   <option data-countryCode="MN" value="95">+95</option>
                   <option data-countryCode="NA" value="264">+264</option>
                   <option data-countryCode="NR" value="674">+674</option>
                   <option data-countryCode="NP" value="977">+977</option>
                   <option data-countryCode="NL" value="31">+31</option>
                   <option data-countryCode="NC" value="687">+687</option>
                   <option data-countryCode="NZ" value="64">+64</option>
                   <option data-countryCode="NI" value="505">+505</option>
                   <option data-countryCode="NE" value="227">+227</option>
                   <option data-countryCode="NG" value="234">+234</option>
                   <option data-countryCode="NU" value="683">+683</option>
                   <option data-countryCode="NF" value="672">+672</option>
                   <option data-countryCode="NP" value="670">+670</option>
                   <option data-countryCode="NO" value="47">+47</option>
                   <option data-countryCode="OM" value="968">+968</option>
                   <option data-countryCode="PW" value="680">+680</option>
                   <option data-countryCode="PA" value="507">+507</option>
                   <option data-countryCode="PG" value="675">+675</option>
                   <option data-countryCode="PY" value="595">+595</option>
                   <option data-countryCode="PE" value="51">+51</option>
                   <option data-countryCode="PH" value="63">+63</option>
                   <option data-countryCode="PL" value="48">+48</option>
                   <option data-countryCode="PT" value="351">+351</option>
                   <option data-countryCode="PR" value="1787">+1787</option>
                   <option data-countryCode="QA" value="974">+974</option>
                   <option data-countryCode="RE" value="262">+262</option>
                   <option data-countryCode="RO" value="40">+40</option>
                   <option data-countryCode="RU" value="7">+7</option>
                   <option data-countryCode="RW" value="250">+250</option>
                   <option data-countryCode="SM" value="378">+378</option>
                   <option data-countryCode="ST" value="239">+239</option>
                   <option data-countryCode="SA" value="966">+966</option>
                   <option data-countryCode="SN" value="221">+221</option>
                   <option data-countryCode="CS" value="381">+381</option>
                   <option data-countryCode="SC" value="248">+248</option>
                   <option data-countryCode="SL" value="232">+232</option>
                   <option data-countryCode="SG" value="65">+65</option>
                   <option data-countryCode="SK" value="421">+421</option>
                   <option data-countryCode="SI" value="386">+386</option>
                   <option data-countryCode="SB" value="677">+677</option>
                   <option data-countryCode="SO" value="252">+252</option>
                   <option data-countryCode="ZA" value="27">+27</option>
                   <option data-countryCode="ES" value="34">+34</option>
                   <option data-countryCode="LK" value="94">+94</option>
                   <option data-countryCode="SH" value="290">+290</option>
                   <option data-countryCode="KN" value="1869">+1869</option>
                   <option data-countryCode="SC" value="1758">+1758</option>
                   <option data-countryCode="SD" value="249">+249</option>
                   <option data-countryCode="SR" value="597">+597</option>
                   <option data-countryCode="SZ" value="268">+268</option>
                   <option data-countryCode="SE" value="46">+46</option>
                   <option data-countryCode="CH" value="41">+41</option>
                   <option data-countryCode="SI" value="963">+963</option>
                   <option data-countryCode="TW" value="886">+886</option>
                   <option data-countryCode="TJ" value="7">+7</option>
                   <option data-countryCode="TH" value="66">+66</option>
                   <option data-countryCode="TG" value="228">+228</option>
                   <option data-countryCode="TO" value="676">+676</option>
                   <option data-countryCode="TT" value="1868">+1868</option>
                   <option data-countryCode="TN" value="216">+216</option>
                   <option data-countryCode="TR" value="90">+90</option>
                   <option data-countryCode="TM" value="7">+7</option>
                   <option data-countryCode="TM" value="993">+993</option>
                   <option data-countryCode="TC" value="1649">+1649</option>
                   <option data-countryCode="TV" value="688">+688</option>
                   <option data-countryCode="UG" value="256">+256</option>
                   <option data-countryCode="GB" value="44">+44</option>
                   <option data-countryCode="UA" value="380">+380</option>
                   <option data-countryCode="AE" value="971">+971</option>
                   <option data-countryCode="UY" value="598">+598</option>
                   <option data-countryCode="US" value="1">+1</option>
                   <option data-countryCode="UZ" value="7">+7</option>
                   <option data-countryCode="VU" value="678">+678</option>
                   <option data-countryCode="VA" value="379">+379</option>
                   <option data-countryCode="VE" value="58">+58</option>
                   <option data-countryCode="VN" value="84">+84</option>
                   <option data-countryCode="VG" value="84">+1284</option>
                   <option data-countryCode="VI" value="84">+1340</option>
                   <option data-countryCode="WF" value="681">+681</option>
                   <option data-countryCode="YE" value="969">+969</option>
                   <option data-countryCode="YE" value="967">+967</option>
                   <option data-countryCode="ZM" value="260">+260</option>
                   <option data-countryCode="ZW" value="263">+263</option>    
             </Select>
             <input type="hidden" name="alernate_country_code" value={alernate_country_code} />
              </div>
              <div className='w-75 px-1'>
                 
                 <input type="number" className='form-control rounded user_input_dashboard'  name="alternate_phone_number" id="alternate_phone_number"  value={alternate_phone_number} placeholder='Enter alternate phone number' onChange={(e)=>setAlternatePhoneNumber(e.target.value)} required/>
              </div>
                </div>
              </div>
              
           
              
             <hr className="mt-5"/>
             </div>
 
 
             <h3 className='my-4 text-left blue-color fw-bold'>Parents Details</h3>
             <div className='row'>
             <div className='col-md-3'>
               <label className="blue-color">Father's Full Name <b className="text-danger">*</b></label>
               <input type="text"className='form-control rounded user_input_dashboard'  name="fathers_full_name" value={fathers_full_name} id="fathers_full_name" onChange={(e)=>setFathersFullName(e.target.value)}  placeholder='Enter Fathers Full Name' required/>
              </div>
              <div className='col-md-3'>
              <label className="blue-color">Father's Email</label>
               <input type="email" className='form-control rounded user_input_dashboard'  name="fathers_email" id="fathers_email"  value={fathers_email} onChange={(e)=>setFathersEmail(e.target.value)}  placeholder='Enter fathers Email' />
 
              </div>
              <div className='col-md-4'>
              <label className="blue-color">Father's Contact Number<b className="text-danger">*</b></label>
                <div className='d-flex'>
                <div className="user_profile_contact_code">
              
             
               <Select
               id='fathers_contact_country_code'
               data-name='fathers_contact_country_code'
               // name={'fathers_contact_country_code'}
               className='w-100 user_input_dashboard'
             
               value={fathers_contact_country_code===""||fathers_contact_country_code===null?"91":fathers_contact_country_code}
               onChange={(value) => setFathersContactCountryCode(value)}
               showSearch
               placeholder='Select Country Code'
            optionFilterProp="children"
            defaultValue="+91"
 >
           
           <option data-countryCode="DZ" value="213">+213</option>
                   <option data-countryCode="AD" value="376">+376</option>
                   <option data-countryCode="AO" value="244">+244</option>
                   <option data-countryCode="AI" value="1264">+1264</option>
                   <option data-countryCode="AG" value="1268">+1268</option>
                   <option data-countryCode="AR" value="54">+54</option>
                   <option data-countryCode="AM" value="374">+374</option>
                   <option data-countryCode="AW" value="297">+297</option>
                   <option data-countryCode="AU" value="61">+61</option>
                   <option data-countryCode="AT" value="43">+43</option>
                   <option data-countryCode="AZ" value="994">+994</option>
                   <option data-countryCode="BS" value="1242">+1242</option>
                   <option data-countryCode="BH" value="973">+973</option>
                   <option data-countryCode="BD" value="880">+880</option>
                   <option data-countryCode="BB" value="1246">+1246</option>
                   <option data-countryCode="BY" value="375">+375</option>
                   <option data-countryCode="BE" value="32">+32</option>
                   <option data-countryCode="BZ" value="501">+501</option>
                   <option data-countryCode="BJ" value="229">+229</option>
                   <option data-countryCode="BM" value="1441">+1441</option>
                   <option data-countryCode="BT" value="975">+975</option>
                   <option data-countryCode="BO" value="591">+591</option>
                   <option data-countryCode="BA" value="387">+387</option>
                   <option data-countryCode="BW" value="267">+267</option>
                   <option data-countryCode="BR" value="55">+55</option>
                   <option data-countryCode="BN" value="673">+673</option>
                   <option data-countryCode="BG" value="359">+359</option>
                   <option data-countryCode="BF" value="226">+226</option>
                   <option data-countryCode="BI" value="257">+257</option>
                   <option data-countryCode="KH" value="855">+855</option>
                   <option data-countryCode="CM" value="237">+237</option>
                   <option data-countryCode="CA" value="1">+1</option>
                   <option data-countryCode="CV" value="238">+238</option>
                   <option data-countryCode="KY" value="1345">+1345</option>
                   <option data-countryCode="CF" value="236">+236</option>
                   <option data-countryCode="CL" value="56">+56</option>
                   <option data-countryCode="CN" value="86">+86</option>
                   <option data-countryCode="CO" value="57">+57</option>
                   <option data-countryCode="KM" value="269">+269</option>
                   <option data-countryCode="CG" value="242">+242</option>
                   <option data-countryCode="CK" value="682">+682</option>
                   <option data-countryCode="CR" value="506">+506</option>
                   <option data-countryCode="HR" value="385">+385</option>
                   <option data-countryCode="CU" value="53">+53</option>
                   <option data-countryCode="CY" value="90392">+90392</option>
                   <option data-countryCode="CY" value="357">+357</option>
                   <option data-countryCode="CZ" value="42">+42</option>
                   <option data-countryCode="DK" value="45">+45</option>
                   <option data-countryCode="DJ" value="253">+253</option>
                   <option data-countryCode="DM" value="1809">+1809</option>
                   <option data-countryCode="DO" value="1809">+1809</option>
                   <option data-countryCode="EC" value="593">+593</option>
                   <option data-countryCode="EG" value="20">+20</option>
                   <option data-countryCode="SV" value="503">+503</option>
                   <option data-countryCode="GQ" value="240">+240</option>
                   <option data-countryCode="ER" value="291">+291</option>
                   <option data-countryCode="EE" value="372">+372</option>
                   <option data-countryCode="ET" value="251">+251</option>
                   <option data-countryCode="FK" value="500">+500</option>
                   <option data-countryCode="FO" value="298">+298</option>
                   <option data-countryCode="FJ" value="679">+679</option>
                   <option data-countryCode="FI" value="358">+358</option>
                   <option data-countryCode="FR" value="33">+33</option>
                   <option data-countryCode="GF" value="594">+594</option>
                   <option data-countryCode="PF" value="689">+689</option>
                   <option data-countryCode="GA" value="241">+241</option>
                   <option data-countryCode="GM" value="220">+220</option>
                   <option data-countryCode="GE" value="7880">+7880</option>
                   <option data-countryCode="DE" value="49">+49</option>
                   <option data-countryCode="GH" value="233">+233</option>
                   <option data-countryCode="GI" value="350">+350</option>
                   <option data-countryCode="GR" value="30">+30</option>
                   <option data-countryCode="GL" value="299">+299</option>
                   <option data-countryCode="GD" value="1473">+1473</option>
                   <option data-countryCode="GP" value="590">+590</option>
                   <option data-countryCode="GU" value="671">+671</option>
                   <option data-countryCode="GT" value="502">+502</option>
                   <option data-countryCode="GN" value="224">+224</option>
                   <option data-countryCode="GW" value="245">+245</option>
                   <option data-countryCode="GY" value="592">+592</option>
                   <option data-countryCode="HT" value="509">+509</option>
                   <option data-countryCode="HN" value="504">+504</option>
                   <option data-countryCode="HK" value="852">+852</option>
                   <option data-countryCode="HU" value="36">+36</option>
                   <option data-countryCode="IS" value="354">+354</option>
                   <option data-countryCode="IN" value="91">+91</option>
                   <option data-countryCode="ID" value="62">+62</option>
                   <option data-countryCode="IR" value="98">+98</option>
                   <option data-countryCode="IQ" value="964">+964</option>
                   <option data-countryCode="IE" value="353">+353</option>
                   <option data-countryCode="IL" value="972">+972</option>
                   <option data-countryCode="IT" value="39">+39</option>
                   <option data-countryCode="JM" value="1876">+1876</option>
                   <option data-countryCode="JP" value="81">+81</option>
                   <option data-countryCode="JO" value="962">+962</option>
                   <option data-countryCode="KZ" value="7">+7</option>
                   <option data-countryCode="KE" value="254">+254</option>
                   <option data-countryCode="KI" value="686">+686</option>
                   <option data-countryCode="KP" value="850">+850</option>
                   <option data-countryCode="KR" value="82">+82</option>
                   <option data-countryCode="KW" value="965">+965</option>
                   <option data-countryCode="KG" value="996">+996</option>
                   <option data-countryCode="LA" value="856">+856</option>
                   <option data-countryCode="LV" value="371">+371</option>
                   <option data-countryCode="LB" value="961">+961</option>
                   <option data-countryCode="LS" value="266">+266</option>
                   <option data-countryCode="LR" value="231">+231</option>
                   <option data-countryCode="LY" value="218">+218</option>
                   <option data-countryCode="LI" value="417">+417</option>
                   <option data-countryCode="LT" value="370">+370</option>
                   <option data-countryCode="LU" value="352">+352</option>
                   <option data-countryCode="MO" value="853">+853</option>
                   <option data-countryCode="MK" value="389">+389</option>
                   <option data-countryCode="MG" value="261">+261</option>
                   <option data-countryCode="MW" value="265">+265</option>
                   <option data-countryCode="MY" value="60">+60</option>
                   <option data-countryCode="MV" value="960">+960</option>
                   <option data-countryCode="ML" value="223">+223</option>
                   <option data-countryCode="MT" value="356">+356</option>
                   <option data-countryCode="MH" value="692">+692</option>
                   <option data-countryCode="MQ" value="596">+596</option>
                   <option data-countryCode="MR" value="222">+222</option>
                   <option data-countryCode="YT" value="269">+269</option>
                   <option data-countryCode="MX" value="52">+52</option>
                   <option data-countryCode="FM" value="691">+691</option>
                   <option data-countryCode="MD" value="373">+373</option>
                   <option data-countryCode="MC" value="377">+377</option>
                   <option data-countryCode="MN" value="976">+976</option>
                   <option data-countryCode="MS" value="1664">+1664</option>
                   <option data-countryCode="MA" value="212">+212</option>
                   <option data-countryCode="MZ" value="258">+258</option>
                   <option data-countryCode="MN" value="95">+95</option>
                   <option data-countryCode="NA" value="264">+264</option>
                   <option data-countryCode="NR" value="674">+674</option>
                   <option data-countryCode="NP" value="977">+977</option>
                   <option data-countryCode="NL" value="31">+31</option>
                   <option data-countryCode="NC" value="687">+687</option>
                   <option data-countryCode="NZ" value="64">+64</option>
                   <option data-countryCode="NI" value="505">+505</option>
                   <option data-countryCode="NE" value="227">+227</option>
                   <option data-countryCode="NG" value="234">+234</option>
                   <option data-countryCode="NU" value="683">+683</option>
                   <option data-countryCode="NF" value="672">+672</option>
                   <option data-countryCode="NP" value="670">+670</option>
                   <option data-countryCode="NO" value="47">+47</option>
                   <option data-countryCode="OM" value="968">+968</option>
                   <option data-countryCode="PW" value="680">+680</option>
                   <option data-countryCode="PA" value="507">+507</option>
                   <option data-countryCode="PG" value="675">+675</option>
                   <option data-countryCode="PY" value="595">+595</option>
                   <option data-countryCode="PE" value="51">+51</option>
                   <option data-countryCode="PH" value="63">+63</option>
                   <option data-countryCode="PL" value="48">+48</option>
                   <option data-countryCode="PT" value="351">+351</option>
                   <option data-countryCode="PR" value="1787">+1787</option>
                   <option data-countryCode="QA" value="974">+974</option>
                   <option data-countryCode="RE" value="262">+262</option>
                   <option data-countryCode="RO" value="40">+40</option>
                   <option data-countryCode="RU" value="7">+7</option>
                   <option data-countryCode="RW" value="250">+250</option>
                   <option data-countryCode="SM" value="378">+378</option>
                   <option data-countryCode="ST" value="239">+239</option>
                   <option data-countryCode="SA" value="966">+966</option>
                   <option data-countryCode="SN" value="221">+221</option>
                   <option data-countryCode="CS" value="381">+381</option>
                   <option data-countryCode="SC" value="248">+248</option>
                   <option data-countryCode="SL" value="232">+232</option>
                   <option data-countryCode="SG" value="65">+65</option>
                   <option data-countryCode="SK" value="421">+421</option>
                   <option data-countryCode="SI" value="386">+386</option>
                   <option data-countryCode="SB" value="677">+677</option>
                   <option data-countryCode="SO" value="252">+252</option>
                   <option data-countryCode="ZA" value="27">+27</option>
                   <option data-countryCode="ES" value="34">+34</option>
                   <option data-countryCode="LK" value="94">+94</option>
                   <option data-countryCode="SH" value="290">+290</option>
                   <option data-countryCode="KN" value="1869">+1869</option>
                   <option data-countryCode="SC" value="1758">+1758</option>
                   <option data-countryCode="SD" value="249">+249</option>
                   <option data-countryCode="SR" value="597">+597</option>
                   <option data-countryCode="SZ" value="268">+268</option>
                   <option data-countryCode="SE" value="46">+46</option>
                   <option data-countryCode="CH" value="41">+41</option>
                   <option data-countryCode="SI" value="963">+963</option>
                   <option data-countryCode="TW" value="886">+886</option>
                   <option data-countryCode="TJ" value="7">+7</option>
                   <option data-countryCode="TH" value="66">+66</option>
                   <option data-countryCode="TG" value="228">+228</option>
                   <option data-countryCode="TO" value="676">+676</option>
                   <option data-countryCode="TT" value="1868">+1868</option>
                   <option data-countryCode="TN" value="216">+216</option>
                   <option data-countryCode="TR" value="90">+90</option>
                   <option data-countryCode="TM" value="7">+7</option>
                   <option data-countryCode="TM" value="993">+993</option>
                   <option data-countryCode="TC" value="1649">+1649</option>
                   <option data-countryCode="TV" value="688">+688</option>
                   <option data-countryCode="UG" value="256">+256</option>
                   <option data-countryCode="GB" value="44">+44</option>
                   <option data-countryCode="UA" value="380">+380</option>
                   <option data-countryCode="AE" value="971">+971</option>
                   <option data-countryCode="UY" value="598">+598</option>
                   <option data-countryCode="US" value="1">+1</option>
                   <option data-countryCode="UZ" value="7">+7</option>
                   <option data-countryCode="VU" value="678">+678</option>
                   <option data-countryCode="VA" value="379">+379</option>
                   <option data-countryCode="VE" value="58">+58</option>
                   <option data-countryCode="VN" value="84">+84</option>
                   <option data-countryCode="VG" value="84">+1284</option>
                   <option data-countryCode="VI" value="84">+1340</option>
                   <option data-countryCode="WF" value="681">+681</option>
                   <option data-countryCode="YE" value="969">+969</option>
                   <option data-countryCode="YE" value="967">+967</option>
                   <option data-countryCode="ZM" value="260">+260</option>
                   <option data-countryCode="ZW" value="263">+263</option>    
           </Select>
           <input type='hidden' name="fathers_contact_country_code" value={fathers_contact_country_code}/>
              </div>
              <div className='w-75 px-1'>
              
               <input type="number" className='form-control rounded user_input_dashboard'  name="fathers_contact" id="fathers_contact"  value={fathers_contact} onChange={(e)=>setFathersContact(parseInt(e.target.value))}  placeholder='Enter fathers contact' required />
               
              </div>
                </div>
              </div>
             
             
             </div>
             <div className='row mt-4'>
             <div className='col-md-3'>
               <label className="blue-color">Mother's Full Name <b className="text-danger">*</b></label>
               <input type="text"className='form-control rounded user_input_dashboard'  name="mothers_full_name" id="mothers_full_name" value={mothers_full_name} onChange={(e)=>setMothersFullName(e.target.value)}  placeholder='Enter mothers Full Name' required/>
              </div>
              <div className='col-md-3'>
              <label className="blue-color">Mother's Email</label>
               <input type="email" className='form-control rounded user_input_dashboard'  name="mothers_email" id="mothers_email" value={mothers_email} onChange={(e)=>setMothersEmail(e.target.value)} placeholder='Enter mothers Email' />
 
              </div>
              <div className='col-md-4'>
              <label className="blue-color">Mother's Contact Number<b className="text-danger">*</b></label>
                <div className='d-flex'>
                <div className="user_profile_contact_code">
              <label className="blue-color"></label>
               {/* <input type="text" className='form-control rounded user_input_dashboard'  name="mothers_country_code" id="mothers_country_code" value={mothers_country_code} onChange={(e)=>setMothersCountryCode(e.target.value)}  placeholder='Enter mothers country code ' /> */}
               {/* <select  className='form-control rounded user_input_dashboard' name="mothers_country_code" id="mothers_country_code" value={mothers_country_code!==""?mothers_country_code:"91"} onChange={(e)=>setMothersCountryCode(e.target.value)} required > */}
               <Select
               id='mothers_country_code'
               data-name='mothers_country_code'
               // name={'fathers_contact_country_code'}
               className='w-100 user_input_dashboard'
               
               value={mothers_country_code===null|| mothers_country_code===""?"+91":mothers_country_code}
               onChange={(value) => setMothersCountryCode(value)}
               showSearch
               placeholder='Select Country Code'
             optionFilterProp="children"
             defaultValue="+91"
 >
 
                   <option data-countryCode="DZ" value="213">+213</option>
                   <option data-countryCode="AD" value="376">+376</option>
                   <option data-countryCode="AO" value="244">+244</option>
                   <option data-countryCode="AI" value="1264">+1264</option>
                   <option data-countryCode="AG" value="1268">+1268</option>
                   <option data-countryCode="AR" value="54">+54</option>
                   <option data-countryCode="AM" value="374">+374</option>
                   <option data-countryCode="AW" value="297">+297</option>
                   <option data-countryCode="AU" value="61">+61</option>
                   <option data-countryCode="AT" value="43">+43</option>
                   <option data-countryCode="AZ" value="994">+994</option>
                   <option data-countryCode="BS" value="1242">+1242</option>
                   <option data-countryCode="BH" value="973">+973</option>
                   <option data-countryCode="BD" value="880">+880</option>
                   <option data-countryCode="BB" value="1246">+1246</option>
                   <option data-countryCode="BY" value="375">+375</option>
                   <option data-countryCode="BE" value="32">+32</option>
                   <option data-countryCode="BZ" value="501">+501</option>
                   <option data-countryCode="BJ" value="229">+229</option>
                   <option data-countryCode="BM" value="1441">+1441</option>
                   <option data-countryCode="BT" value="975">+975</option>
                   <option data-countryCode="BO" value="591">+591</option>
                   <option data-countryCode="BA" value="387">+387</option>
                   <option data-countryCode="BW" value="267">+267</option>
                   <option data-countryCode="BR" value="55">+55</option>
                   <option data-countryCode="BN" value="673">+673</option>
                   <option data-countryCode="BG" value="359">+359</option>
                   <option data-countryCode="BF" value="226">+226</option>
                   <option data-countryCode="BI" value="257">+257</option>
                   <option data-countryCode="KH" value="855">+855</option>
                   <option data-countryCode="CM" value="237">+237</option>
                   <option data-countryCode="CA" value="1">+1</option>
                   <option data-countryCode="CV" value="238">+238</option>
                   <option data-countryCode="KY" value="1345">+1345</option>
                   <option data-countryCode="CF" value="236">+236</option>
                   <option data-countryCode="CL" value="56">+56</option>
                   <option data-countryCode="CN" value="86">+86</option>
                   <option data-countryCode="CO" value="57">+57</option>
                   <option data-countryCode="KM" value="269">+269</option>
                   <option data-countryCode="CG" value="242">+242</option>
                   <option data-countryCode="CK" value="682">+682</option>
                   <option data-countryCode="CR" value="506">+506</option>
                   <option data-countryCode="HR" value="385">+385</option>
                   <option data-countryCode="CU" value="53">+53</option>
                   <option data-countryCode="CY" value="90392">+90392</option>
                   <option data-countryCode="CY" value="357">+357</option>
                   <option data-countryCode="CZ" value="42">+42</option>
                   <option data-countryCode="DK" value="45">+45</option>
                   <option data-countryCode="DJ" value="253">+253</option>
                   <option data-countryCode="DM" value="1809">+1809</option>
                   <option data-countryCode="DO" value="1809">+1809</option>
                   <option data-countryCode="EC" value="593">+593</option>
                   <option data-countryCode="EG" value="20">+20</option>
                   <option data-countryCode="SV" value="503">+503</option>
                   <option data-countryCode="GQ" value="240">+240</option>
                   <option data-countryCode="ER" value="291">+291</option>
                   <option data-countryCode="EE" value="372">+372</option>
                   <option data-countryCode="ET" value="251">+251</option>
                   <option data-countryCode="FK" value="500">+500</option>
                   <option data-countryCode="FO" value="298">+298</option>
                   <option data-countryCode="FJ" value="679">+679</option>
                   <option data-countryCode="FI" value="358">+358</option>
                   <option data-countryCode="FR" value="33">+33</option>
                   <option data-countryCode="GF" value="594">+594</option>
                   <option data-countryCode="PF" value="689">+689</option>
                   <option data-countryCode="GA" value="241">+241</option>
                   <option data-countryCode="GM" value="220">+220</option>
                   <option data-countryCode="GE" value="7880">+7880</option>
                   <option data-countryCode="DE" value="49">+49</option>
                   <option data-countryCode="GH" value="233">+233</option>
                   <option data-countryCode="GI" value="350">+350</option>
                   <option data-countryCode="GR" value="30">+30</option>
                   <option data-countryCode="GL" value="299">+299</option>
                   <option data-countryCode="GD" value="1473">+1473</option>
                   <option data-countryCode="GP" value="590">+590</option>
                   <option data-countryCode="GU" value="671">+671</option>
                   <option data-countryCode="GT" value="502">+502</option>
                   <option data-countryCode="GN" value="224">+224</option>
                   <option data-countryCode="GW" value="245">+245</option>
                   <option data-countryCode="GY" value="592">+592</option>
                   <option data-countryCode="HT" value="509">+509</option>
                   <option data-countryCode="HN" value="504">+504</option>
                   <option data-countryCode="HK" value="852">+852</option>
                   <option data-countryCode="HU" value="36">+36</option>
                   <option data-countryCode="IS" value="354">+354</option>
                   <option data-countryCode="IN" value="91">+91</option>
                   <option data-countryCode="ID" value="62">+62</option>
                   <option data-countryCode="IR" value="98">+98</option>
                   <option data-countryCode="IQ" value="964">+964</option>
                   <option data-countryCode="IE" value="353">+353</option>
                   <option data-countryCode="IL" value="972">+972</option>
                   <option data-countryCode="IT" value="39">+39</option>
                   <option data-countryCode="JM" value="1876">+1876</option>
                   <option data-countryCode="JP" value="81">+81</option>
                   <option data-countryCode="JO" value="962">+962</option>
                   <option data-countryCode="KZ" value="7">+7</option>
                   <option data-countryCode="KE" value="254">+254</option>
                   <option data-countryCode="KI" value="686">+686</option>
                   <option data-countryCode="KP" value="850">+850</option>
                   <option data-countryCode="KR" value="82">+82</option>
                   <option data-countryCode="KW" value="965">+965</option>
                   <option data-countryCode="KG" value="996">+996</option>
                   <option data-countryCode="LA" value="856">+856</option>
                   <option data-countryCode="LV" value="371">+371</option>
                   <option data-countryCode="LB" value="961">+961</option>
                   <option data-countryCode="LS" value="266">+266</option>
                   <option data-countryCode="LR" value="231">+231</option>
                   <option data-countryCode="LY" value="218">+218</option>
                   <option data-countryCode="LI" value="417">+417</option>
                   <option data-countryCode="LT" value="370">+370</option>
                   <option data-countryCode="LU" value="352">+352</option>
                   <option data-countryCode="MO" value="853">+853</option>
                   <option data-countryCode="MK" value="389">+389</option>
                   <option data-countryCode="MG" value="261">+261</option>
                   <option data-countryCode="MW" value="265">+265</option>
                   <option data-countryCode="MY" value="60">+60</option>
                   <option data-countryCode="MV" value="960">+960</option>
                   <option data-countryCode="ML" value="223">+223</option>
                   <option data-countryCode="MT" value="356">+356</option>
                   <option data-countryCode="MH" value="692">+692</option>
                   <option data-countryCode="MQ" value="596">+596</option>
                   <option data-countryCode="MR" value="222">+222</option>
                   <option data-countryCode="YT" value="269">+269</option>
                   <option data-countryCode="MX" value="52">+52</option>
                   <option data-countryCode="FM" value="691">+691</option>
                   <option data-countryCode="MD" value="373">+373</option>
                   <option data-countryCode="MC" value="377">+377</option>
                   <option data-countryCode="MN" value="976">+976</option>
                   <option data-countryCode="MS" value="1664">+1664</option>
                   <option data-countryCode="MA" value="212">+212</option>
                   <option data-countryCode="MZ" value="258">+258</option>
                   <option data-countryCode="MN" value="95">+95</option>
                   <option data-countryCode="NA" value="264">+264</option>
                   <option data-countryCode="NR" value="674">+674</option>
                   <option data-countryCode="NP" value="977">+977</option>
                   <option data-countryCode="NL" value="31">+31</option>
                   <option data-countryCode="NC" value="687">+687</option>
                   <option data-countryCode="NZ" value="64">+64</option>
                   <option data-countryCode="NI" value="505">+505</option>
                   <option data-countryCode="NE" value="227">+227</option>
                   <option data-countryCode="NG" value="234">+234</option>
                   <option data-countryCode="NU" value="683">+683</option>
                   <option data-countryCode="NF" value="672">+672</option>
                   <option data-countryCode="NP" value="670">+670</option>
                   <option data-countryCode="NO" value="47">+47</option>
                   <option data-countryCode="OM" value="968">+968</option>
                   <option data-countryCode="PW" value="680">+680</option>
                   <option data-countryCode="PA" value="507">+507</option>
                   <option data-countryCode="PG" value="675">+675</option>
                   <option data-countryCode="PY" value="595">+595</option>
                   <option data-countryCode="PE" value="51">+51</option>
                   <option data-countryCode="PH" value="63">+63</option>
                   <option data-countryCode="PL" value="48">+48</option>
                   <option data-countryCode="PT" value="351">+351</option>
                   <option data-countryCode="PR" value="1787">+1787</option>
                   <option data-countryCode="QA" value="974">+974</option>
                   <option data-countryCode="RE" value="262">+262</option>
                   <option data-countryCode="RO" value="40">+40</option>
                   <option data-countryCode="RU" value="7">+7</option>
                   <option data-countryCode="RW" value="250">+250</option>
                   <option data-countryCode="SM" value="378">+378</option>
                   <option data-countryCode="ST" value="239">+239</option>
                   <option data-countryCode="SA" value="966">+966</option>
                   <option data-countryCode="SN" value="221">+221</option>
                   <option data-countryCode="CS" value="381">+381</option>
                   <option data-countryCode="SC" value="248">+248</option>
                   <option data-countryCode="SL" value="232">+232</option>
                   <option data-countryCode="SG" value="65">+65</option>
                   <option data-countryCode="SK" value="421">+421</option>
                   <option data-countryCode="SI" value="386">+386</option>
                   <option data-countryCode="SB" value="677">+677</option>
                   <option data-countryCode="SO" value="252">+252</option>
                   <option data-countryCode="ZA" value="27">+27</option>
                   <option data-countryCode="ES" value="34">+34</option>
                   <option data-countryCode="LK" value="94">+94</option>
                   <option data-countryCode="SH" value="290">+290</option>
                   <option data-countryCode="KN" value="1869">+1869</option>
                   <option data-countryCode="SC" value="1758">+1758</option>
                   <option data-countryCode="SD" value="249">+249</option>
                   <option data-countryCode="SR" value="597">+597</option>
                   <option data-countryCode="SZ" value="268">+268</option>
                   <option data-countryCode="SE" value="46">+46</option>
                   <option data-countryCode="CH" value="41">+41</option>
                   <option data-countryCode="SI" value="963">+963</option>
                   <option data-countryCode="TW" value="886">+886</option>
                   <option data-countryCode="TJ" value="7">+7</option>
                   <option data-countryCode="TH" value="66">+66</option>
                   <option data-countryCode="TG" value="228">+228</option>
                   <option data-countryCode="TO" value="676">+676</option>
                   <option data-countryCode="TT" value="1868">+1868</option>
                   <option data-countryCode="TN" value="216">+216</option>
                   <option data-countryCode="TR" value="90">+90</option>
                   <option data-countryCode="TM" value="7">+7</option>
                   <option data-countryCode="TM" value="993">+993</option>
                   <option data-countryCode="TC" value="1649">+1649</option>
                   <option data-countryCode="TV" value="688">+688</option>
                   <option data-countryCode="UG" value="256">+256</option>
                   <option data-countryCode="GB" value="44">+44</option>
                   <option data-countryCode="UA" value="380">+380</option>
                   <option data-countryCode="AE" value="971">+971</option>
                   <option data-countryCode="UY" value="598">+598</option>
                   <option data-countryCode="US" value="1">+1</option>
                   <option data-countryCode="UZ" value="7">+7</option>
                   <option data-countryCode="VU" value="678">+678</option>
                   <option data-countryCode="VA" value="379">+379</option>
                   <option data-countryCode="VE" value="58">+58</option>
                   <option data-countryCode="VN" value="84">+84</option>
                   <option data-countryCode="VG" value="84">+1284</option>
                   <option data-countryCode="VI" value="84">+1340</option>
                   <option data-countryCode="WF" value="681">+681</option>
                   <option data-countryCode="YE" value="969">+969</option>
                   <option data-countryCode="YE" value="967">+967</option>
                   <option data-countryCode="ZM" value="260">+260</option>
                   <option data-countryCode="ZW" value="263">+263</option>    
             </Select>
             <input type="hidden" name="mothers_country_code" value={mothers_country_code}/>
              </div>
              <div className='w-75 px-1'>
              
               <input type="number" className='form-control rounded user_input_dashboard'  name="mothers_contact" id="mothers_contact" value={mothers_contact} onChange={(e)=>setMothersContact(parseInt(e.target.value))}  placeholder='Enter mothers contact' required/>
               
              </div>
                </div>
              </div>
        
             
              <hr className="mt-5"/>
              <h3 className='my-4 text-left blue-color fw-bold'>Passport Details</h3>
              <div className='row mb-3'>
               <div className='col-md-3'>
               <label className="blue-color">Do you have a passport ?<b className="text-danger">*</b></label>
                 <select className='form-control rounded user_input_dashboard' name='passport_exist' value={passport_exist} onChange={(e)=>{setPassportExist(parseInt(e.target.value))}}>
                   <option value="0">Choose</option>
                   <option value="1">Yes</option>
                   <option value="2">No</option>
                 </select>
               </div>
               {passport_exist===1?<div className='col-md-3'>
               <label className="blue-color">Passport Number<b className="text-danger">*</b></label>
                 <input type="text" className='form-control rounded user_input_dashboard' name="passport_number" value={passport_number} onChange={(e)=>setPassportNumber(e.target.value)} id="passport_number" placeholder='Enter passport number' required />
               </div>:""}
               {passport_exist===1? <div className='col-md-3'>
               <label className="blue-color">Passport Issue Date<b className="text-danger">*</b></label>
               <input type="date" className='form-control rounded user_input_dashboard' name ="passport_issue_date" value={passport_issue_date} onChange={(e)=>setPassportIssueDate(e.target.value)} id="passport_issue_date" placeholder='Enter passport issue date' required />
               </div>:""}
               {passport_exist===1?<div className='col-md-3'>
               <label className="blue-color">Passport Expiry Date<b className="text-danger">*</b></label>
               <input type="date" className='form-control rounded user_input_dashboard' name="passport_expiry_date"  value={passport_expiry_date}  onChange={(e)=>setPassportExpiryDate(e.target.value)} id="passport_expiry_date" placeholder='Enter passport expiry date' required />
               </div>:"" } 
               </div>
             </div>
             <ToastContainer />
               <div className='py-5 text-center pr-5'><button type="submit" className='w-25 btn bg-blue text-white btn-lg'> Update</button></div>
             </form>
 
         </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
</div>
  )
}

export default UserProfileEdit