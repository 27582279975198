import React, { useEffect } from 'react'
import { Fragment } from "react";
import Logo from './logo.png';
import {Link} from 'react-router-dom';
import { AiOutlineUsergroupAdd,AiFillTag,} from 'react-icons/ai'; 
import {GrSteps} from 'react-icons/gr';
import { FaNewspaper } from 'react-icons/fa6'
import { PiStudentFill } from 'react-icons/pi'
import {PiStepsFill} from 'react-icons/pi';
import {IoBagHandle} from 'react-icons/io5';
import { GrDocumentTest } from 'react-icons/gr'
import {BiCategory,BiCategoryAlt} from 'react-icons/bi';
import {FaUserGear,FaBox,FaBookMedical,FaBuildingUser,FaUserClock} from 'react-icons/fa6';
import {MdCurrencyRupee} from 'react-icons/md';
import {CiDiscount1} from 'react-icons/ci';
import { FaDiscourse } from 'react-icons/fa';
import { MdSubscriptions } from 'react-icons/md';
import { PiAirplaneTakeoffFill } from 'react-icons/pi';
import { FaLaptopFile } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";

import './sidebar.css';
const SideBar = () => {
    let role =localStorage.getItem('role');
    useEffect(() => {
       
        
    }, []);
    return (
        <Fragment>
             <div className='sidebar'>
              {/* Topbar */}
                    {/* Sidebar */}
                <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                {/* Sidebar - Brand */}
                <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/superadmindashboard">
                    <div className="sidebar-brand-icon text-right">
                    <Link className="nav-link" to="/superadmindashboard"><img src={Logo} width="100%" height="100%" alt="goocampuslogo"/></Link>
                    </div>
                    {/* <div className="sidebar-brand-text mx-3 text-start"><span>Goo</span>campus</div> */}
                </Link>
                {/* Divider */}
                <hr className="sidebar-divider my-0" />
                {/* Nav Item - Dashboard */}
                {/* <li className="nav-item active">
                    <Link className="nav-link" to="/">
                    <i className="fas fa-fw fa-tachometer-alt" />
                    <span>Dashboard</span></Link>
                </li> */}
                {/* Divider */}
                <hr className="sidebar-divider" />
                {/* Heading */}
                {/* <div className="sidebar-heading">
                    Interface
                </div> */}
                {/* Nav Item - Pages Collapse Menu bookinglist */}


               { role==="1" ||role==="2" || role==="3" || role==="5" ||role==="6"||role ==="8"  || role==="9"  ?<li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#users" aria-expanded="true" aria-controls="collapseUtilities">
                    <i ><AiOutlineUsergroupAdd /></i>
                    <span>Users</span>
                    </Link>
                    <div id="users" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                   <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Users:</h6>
                        <Link className="collapse-item text-left" to="/add_user_by_counsellor">Add User</Link>
                        <Link className="collapse-item text-left" to="/users">Users</Link> 
                       
                    </div>
                    </div>
                </li>:""}
                { role==="1" ||role==="2" || role==="3" || role==="5" ||role==="6" ?<li className="nav-item">
                    <Link className="nav-link collapsed" to={'/onboardinglist'} data-toggle="collapse" data-target="#onboardinglist" aria-expanded="true" aria-controls="collapseUtilities">
                    <i ><FaNewspaper/></i>
                    <span>Onboarding</span>
                    </Link>
                   
                </li>:""}
                { role==="1" || role==="3" || role==="5" ||role==="6"  ?<li className="nav-item">
                    <Link className="nav-link collapsed" to={'/traininglist'} data-toggle="collapse" data-target="#onboardinglist" aria-expanded="true" aria-controls="collapseUtilities">
                    <i ><PiStudentFill /></i>
                    <span>Training</span>
                    </Link>
                   
                </li>:""}
                { role==="1" || role==="3" || role==="5" ||role==="6"  ?<li className="nav-item">
                    <Link className="nav-link collapsed" to={'/bookinglist'} data-toggle="collapse" data-target="#onboardinglist" aria-expanded="true" aria-controls="collapseUtilities">
                    <i ><GrDocumentTest /></i>
                    <span>Test Booking</span>
                    </Link>
                   
                </li>:""}
                { role==="1" || role==="3" || role==="5" ||role==="6"  ?<li className="nav-item">
                    <Link className="nav-link collapsed" to={'/registration_list'} data-toggle="collapse" data-target="#onboardinglist" aria-expanded="true" aria-controls="collapseUtilities">
                    <i ><AiOutlineUsergroupAdd /></i>
                    <span>Registrations</span>
                    </Link>
                   
                </li>:""}
                { role==="1" || role==="3" || role==="5" ||role==="6"  ?<li className="nav-item">
                    <Link className="nav-link collapsed" to={'/courses_allocated_list'} data-toggle="collapse" data-target="#onboardinglist" aria-expanded="true" aria-controls="collapseUtilities">
                    <i ><FaDiscourse /></i>
                    <span>Courses Allocated</span>
                    </Link>
                   
                </li>:""}
                { role==="1" || role==="3" || role==="5" ||role==="6"  ?<li className="nav-item">
                    <Link className="nav-link collapsed" to={'/training_and_subscription_list'} data-toggle="collapse" data-target="#onboardinglist" aria-expanded="true" aria-controls="collapseUtilities">
                    <i ><MdSubscriptions /></i>
                    <span>Training And Subscription</span>
                    </Link>
                   
                </li>:""}
                { role==="1" || role==="3" || role==="5" ||role==="6"?<li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#visa_travel_and_accommodation" aria-expanded="true" aria-controls="collapseUtilities">
                    <i ><PiAirplaneTakeoffFill /></i>
                    <span>Visa Travel & Stay    </span>
                    </Link>
                    <div id="visa_travel_and_accommodation" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                   <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Visa Travel & Stay:</h6>
                        <Link className="collapse-item text-left" to="/tier4visa_list">Tier 4 Visa</Link>
                        <Link className="collapse-item text-left" to="/travel_and_accomdation_list">Travel & Accomadation</Link> 
                        <Link className="collapse-item text-left" to="/cablist">Airport Pick Up</Link>
                        <Link className="collapse-item text-left" to="/gmc_registration_list">GMC License & Job</Link> 
                       
                    </div>
                    </div>
                </li>:""}
                { role==="1" || role==="3" || role==="5" ||role==="6"  ?<li className="nav-item">
                    <Link className="nav-link collapsed" to={'/portfolio_list'} data-toggle="collapse" data-target="#onboardinglist" aria-expanded="true" aria-controls="collapseUtilities">
                    <i ><FaLaptopFile /></i>
                    <span>Portfolio</span>
                    </Link>
                   
                </li>:""}
               
                {/* Nav Item - Utilities Collapse Menu */}
                { role==="1"?<li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#packages" aria-expanded="true" aria-controls="collapseUtilities">
                    <i ><AiFillTag/></i>
                    <span>Products</span>
                    </Link>
                    <div id="packages" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Products:</h6>
                        <Link className="collapse-item text-left" to="/add_product">Add Products</Link>
                        <Link className="collapse-item text-left" to="/products">Products</Link>
                       
                    </div>
                    </div>
                </li>:""}

                { role==="1"? <li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#stages" aria-expanded="true" aria-controls="collapseUtilities">
                    <i ><GrSteps/></i>
                    <span>Stages</span>
                    </Link>
                    <div id="stages" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Stages:</h6>
                        <Link className="collapse-item text-left" to="/add_stage">Add Stage</Link>
                        <Link className="collapse-item text-left" to="/stages">Stages</Link> 
                       
                    </div>
                    </div>
                </li>:""}
                { role==="1"? <li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#substages" aria-expanded="true" aria-controls="collapseUtilities">
                    <i ><PiStepsFill/></i>
                    <span>SubStages</span>
                    </Link>
                    <div id="substages" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">SubStages:</h6>
                        <Link className="collapse-item text-left" to="/add_sub_stage">Add SubStage</Link>
                        <Link className="collapse-item text-left" to="/sub_stages">SubStages</Link> 
                       
                    </div>
                    </div>
                </li>:""}
                { role==="1"|| role==="3" || role==="5"?<li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#services" aria-expanded="true" aria-controls="collapseUtilities">
                    <i><IoBagHandle/></i>
                    <span>Services</span>
                    </Link>
                    <div id="services" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Services:</h6>
                        <Link className="collapse-item text-left" to="/add_service">Add Services</Link>
                        <Link className="collapse-item text-left" to="/services">Services</Link> 
                       
                    </div>
                    </div>
                </li>:""}
                { role==="1"|| role==="3" || role==="5"?<li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#services_categories" aria-expanded="true" aria-controls="collapseUtilities">
                    <i><BiCategory/></i>
                    <span>Services Categories</span>
                    </Link>
                    <div id="services_categories" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Services Categories:</h6>
                        <Link className="collapse-item text-left" to="/add_service_category">Add Services Category</Link>
                        <Link className="collapse-item text-left" to="/service_categories">Service Categories</Link> 
                       
                    </div>
                    </div>
                </li>:""}
                { role==="1"|| role==="3" || role==="5"? <li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#services_category_type" aria-expanded="true" aria-controls="collapseUtilities">
                    <i><BiCategoryAlt/></i>
                    <span>Services Category Type</span>
                    </Link>
                    <div id="services_category_type" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Services Category Type:</h6>
                        <Link className="collapse-item text-left" to="/add_service_category_type">Add Services Category Type</Link>
                        <Link className="collapse-item text-left" to="/service_category_types">Service Categories Types</Link> 
                       
                    </div>
                    </div>
                </li>:""}
                {/* { role==="1"|| role==="3" || role==="5"? <li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#training" aria-expanded="true" aria-controls="collapseUtilities">
                    <i><FaUserGear/></i>
                    <span>Service Categoriser</span>
                    </Link>
                    <div id="training" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Service Categoriser:</h6>
                        <Link className="collapse-item text-left" to="/add_service_categoriser">Add Service Categoriser</Link>
                        <Link className="collapse-item text-left" to="/service_categorisers">Service Categorisers</Link> 
                       
                    </div>
                    </div>
                </li>:""} */}
               
                { role==="1"||role==="5"?<li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#installments" aria-expanded="true" aria-controls="collapseUtilities">
                    <i><MdCurrencyRupee/></i>
                    <span>Installment Types</span>
                    </Link>
                    <div id="installments" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Installment Types:</h6>
                        <Link className="collapse-item text-left" to="/add_installment_type">Add Installment Types</Link>
                        <Link className="collapse-item text-left" to="/installments">Installments</Link> 
                       
                    </div>
                    </div>
                </li>:""}
                {/* Divider */}
                <hr className="sidebar-divider" />
                {/* Heading */}
                <div className="sidebar-heading">
                    Addons
                </div>
                {/* Nav Item - Pages Collapse Menu */}
                { role==="1"? <li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#specialities" aria-expanded="true" aria-controls="collapsePages">
                    <i><FaBookMedical/></i>
                    <span>Specialites</span>
                    </Link>
                    <div id="specialities" className="collapse"  aria-labelledby="headingPages" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Specialites</h6>
                        <Link className="collapse-item text-left" to="/add_speciality">Add Speciality</Link>
                        <Link className="collapse-item text-left" to="/specialities">Specialities</Link>
                       
                    </div>
                    </div>
                </li>:""}
                { role==="1"?  <li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#subspecialities" aria-expanded="true" aria-controls="collapsePages">
                    <i><FaBookMedical/></i>
                    <span>SubSpecialities</span>
                    </Link>
                    <div id="subspecialities" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">SubSpecialities</h6>
                        <Link className="collapse-item text-left" to="/add_sub_speciality">Add SubSpecialities</Link>
                        <Link className="collapse-item text-left" to="/sub_specialities">SubSpecialities</Link>
                        
                    </div>
                    </div>
                </li>:""}
                { role==="1"||role==="3"||role==="5"?  <li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#welcomekittype" aria-expanded="true" aria-controls="collapsePages">
                    <i><FaBox/></i>
                    <span>Welcome Kit</span>
                    </Link>
                    <div id="welcomekittype" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Welcome Kit</h6>
                        <Link className="collapse-item text-left" to="/add_welcomekit">Add Welcome Kit Type</Link>
                        <Link className="collapse-item text-left" to="/welcomekits">Welcome Kits</Link>
                        
                    </div>
                    </div>
                </li>:""}
                { role==="1"||role==="3"||role==="5"?  <li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#vendors" aria-expanded="true" aria-controls="collapsePages">
                    <i><FaBuildingUser/></i>
                    <span>Vendors</span>
                    </Link>
                    <div id="vendors" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Vendors</h6>
                        <Link className="collapse-item text-left" to="/add_vendor">Add Vendor</Link>
                        <Link className="collapse-item text-left" to="/vendors">Vendors</Link>
                        
                    </div>
                    </div>
                </li>:""}
                { role==="1"?  <li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#schedulers" aria-expanded="true" aria-controls="collapsePages">
                   <i><FaUserClock/></i>
                    <span>Task Schedulers</span>
                    </Link>
                    <div id="schedulers" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Task Schedulers</h6>
                        <Link className="collapse-item text-left" to="/add_task_schedule">Add Task Schedule</Link>
                        <Link className="collapse-item text-left" to="/task_schedules">Task Schedules</Link>
                        
                    </div>
                    </div>
                </li>:""}
                { role==="1" ||role==="3"||role==="5"?  <li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#discounttype" aria-expanded="true" aria-controls="collapsePages">
                    <i><CiDiscount1/></i>
                    <span>Discount Types</span>
                    </Link>
                    <div id="discounttype" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Discount Types</h6>
                        <Link className="collapse-item text-left" to="/add_discount_type">Add Discount Types</Link>
                        <Link className="collapse-item text-left" to="/discount_types">Discount Types</Link>
                        
                    </div>
                    </div>
                </li>:""}
                { role==="1"?  <li className="nav-item">
                    <Link className="nav-link collapsed" to="" data-toggle="collapse" data-target="#incharges" aria-expanded="true" aria-controls="collapsePages">
                    <i><CiDiscount1/></i>
                    <span>Incharges</span>
                    </Link>
                    <div id="incharges" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Incharges</h6>
                        <Link className="collapse-item text-left" to="/add_incharge">Add Incharge</Link>
                        <Link className="collapse-item text-left" to="/incharges">Incharges</Link>
                        
                    </div>
                    </div>
                </li>:""}

              
                
                

                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br />
                {/* Divider */}
                {/* <hr className="sidebar-divider d-none d-md-block" /> */}
                {/* Sidebar Toggler (Sidebar) */}
                {/* <div className="text-center d-none d-md-inline">
                    <button className="rounded-circle border-0" id="sidebarToggle" />
                </div> */}
              
                
                </ul>
    
                </div>
        </Fragment>
      )
}

export default SideBar;