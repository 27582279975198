import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';

import {Link,useParams} from 'react-router-dom';

import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { message } from 'antd';

import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import $ from 'jquery'; 

import validator from 'validator';
import { off } from 'process';
import { type } from 'os';
import { vendortype } from '../../@types/vendortype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import { ServiceData } from '../../@types/servicetype';
import { count } from 'console';
import server from '../Utils';
import UserInfo from '../UserInfo';
import Loader from './Loader';
const EditRegistrationDetails = () => {
        
   
   
    const [project_id,setProjectId] = useState<number>(0);
    
    const [assesment_date,setAssesmentDate] =useState<string>('');
    const [service_category_id,setServiceCategoryId]=useState<number>(0);
    const [service_categories,setServiceCategories]=useState<Array<ServiceCategoryType>>([])
    const [services,setServices]=useState<Array<ServiceData>>([])
    const [service_id,setServiceId]=useState<number>(0)
   
    //GMC
   const [gmc_reference_number,setGmcReferenceNumber]=useState<number>(0);
   const [gmc_login_password,setGmcLoginPassword]=useState<string>('');
   const [gmc_secret_question,setGmcSecretQuestion]=useState<string>('');
   const [gmc_secret_answer,setGmcSecretAnswer]=useState<string>('');
   const [gmc_exist,setGmcExist]=useState<number>(1);

   //IELTS
   const [ielts_login_id,setIeltsLoginId]=useState<string>('');
   const [ielts_login_password,setIeltsLoginPassword]=useState<string>('');
   const [ielts_secret_question,setIeltsSecretQuestion]=useState<string>('');
   const [ielts_secret_answer,setIeltsSecretAnswer]=useState<string>('');
   const [ielts_exist,setIeltsExist]=useState<number>(1);

   //OET

const [oet_login_id,setOetLoginId]=useState<string>('');
const [oet_login_password,setOetLoginPassword]=useState<string>('');
const [oet_secret_question,setOetSecretQuestion]=useState<string>('');
const [oet_secret_answer,setOetSecretAnswer]=useState<string>('');
const [oet_exist,setOetExist]=useState<number>(1);

//EPIC
const [epic_account_setup_date,setEpicAccountSetupDate]=useState<string>('');
const [epic_username,setEpicUsername]=useState<string>('');
const [epic_password,setEpicPassword]=useState<string>('');
const [epic_id,setEpicId]=useState<string>('');
const [epic_secret_question1,setEpicSecretQuestion1]=useState<string>('')
const [epic_secret_question2,setEpicSecretQuestion2]=useState<string>('')
const [epic_secret_question3,setEpicSecretQuestion3]=useState<string>('')
const [epic_secret_question4,setEpicSecretQuestion4]=useState<string>('')

const [epic_secret_answer1,setEpicSecretAnswer1]=useState<string>('')
const [epic_secret_answer2,setEpicSecretAnswer2]=useState<string>('')
const [epic_secret_answer3,setEpicSecretAnswer3]=useState<string>('')
const [epic_secret_answer4,setEpicSecretAnswer4]=useState<string>('')
const [epic_eif,setEpicEif]=useState<string>('');
const [epic_exist,setEpicExist]=useState<number>(1);
const [user_id,setUserId]=useState<string>('');

const[notarycam_username,setNotarycamUsername]=useState<string>('')
const[notarycam_password,setNotarycamPassword]=useState<string>('')

const [loading, setLoading] = useState(false);



let  productid:string=JSON.parse(localStorage.getItem("product_id")||'{}');
    
    const {id}= useParams();
    // const userid=id;
    
    useEffect(() => {
     
      if(parseInt(productid)===1){
        getServices(8)
        }else if(parseInt(productid)===2){
          getServices(20)
        }else if(parseInt(productid)===3){
          getServices(31)
        }
      
      server.get(`/service_categories_for_registration`)
      .then((response)=>{
        setServiceCategories(response.data.services_categories)
  
      })

     server.get(`/registration/${id}`)
     .then((response)=>{
      setServiceCategoryId(response.data.registration.service_category_id)
      
      setServiceId(response.data.registration.service_id)
      setGmcReferenceNumber(response.data.registration.gmc_reference_number)
      setGmcLoginPassword(response.data.registration.gmc_login_password)
      setGmcSecretQuestion(response.data.registration.gmc_secret_question)
      setGmcSecretAnswer(response.data.registration.gmc_secret_answer)
      setIeltsLoginId(response.data.registration.ielts_login_id)
      setIeltsLoginPassword(response.data.registration.ielts_login_password)
      setIeltsSecretQuestion(response.data.registration.ielts_secret_question)
      setIeltsSecretAnswer(response.data.registration.ielts_secret_answer)
      setOetLoginId(response.data.registration.oet_login_id)
      setOetLoginPassword(response.data.registration.oet_login_password)
      setOetSecretQuestion(response.data.registration.oet_secret_question)
      setOetSecretAnswer(response.data.registration.oet_secret_answer)
      setEpicAccountSetupDate(response.data.registration.epic_account_setup_date)
      setEpicUsername(response.data.registration.epic_username)
      setEpicPassword(response.data.registration.epic_password)
      setEpicId(response.data.registration.epic_id)
      setEpicSecretQuestion1(response.data.registration.epic_secret_question1)
      setEpicSecretQuestion2(response.data.registration.epic_secret_question2)
      setEpicSecretQuestion3(response.data.registration.epic_secret_question3)
      setEpicSecretQuestion4(response.data.registration.epic_secret_question4)
      setEpicSecretAnswer1(response.data.registration.epic_secret_answer1)
      setEpicSecretAnswer2(response.data.registration.epic_secret_answer2)
      setEpicSecretAnswer3(response.data.registration.epic_secret_answer3)
      setEpicSecretAnswer4(response.data.registration.epic_secret_answer4)
      setEpicEif(response.data.registration.epic_eif)
      setNotarycamUsername(response.data.registration.notarycam_username)
      setNotarycamPassword(response.data.registration.notarycam_password)
      setUserId(response.data.registration.user_id)
     })
    }, []);
    const getServices =(id:number)=>{
    
      setServiceCategoryId(id)
     
      server.get(`/service_on_category/${id}`)
      .then((response)=>{
          setServices(response.data.services)
      })
     }

    const addRegistration=(e:React.FormEvent<HTMLFormElement>)=>{
      e.preventDefault();
     
     setLoading(true)
     if(service_category_id===0){
      toast('Select Registration');
    }else if(service_category_id!==0){
      if(service_id===0){
        toast('Select Service');
      }}
    if(service_id!==0){
      
      var form= document.getElementById('addtrainingdetails') as HTMLFormElement;
      var formData=new FormData(form)
      server.post(`/registration/${id}`,formData)
      .then((response)=>{
        setLoading(false)
        if(response.data.code===200){
          Swal.fire(
            'Good job!',
            response.data.message,
            'success'
          )
         
          // setTimeout(function () { window.history.back(); }, 1000);
        }else{
          setLoading(false)
          toast(response.data.message)
        }
      })
    }
    }
    if (loading) {
      return <Loader />;
    }
  return (
    <div className='container-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}

<UserInfo />
    <h4 className='text-danger text-center'>Edit Registration Details</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5 jumbotron mx-3'>
   
  <div className='col-md-12 px-5 '>
        <form id="addtrainingdetails" onSubmit={addRegistration}  encType='multipart/form-data' className='w-100'>
          <div className='row'>
          <input type="hidden" name="project_id" id="project_id" value={project_id} />
          <input type="hidden" name="product_id" id="product_id" value={productid} />
          <input type='hidden' name={"service_category_id"} value={service_category_id} />
            {/* <div className='col-md-3'>
              <label>Registration</label>
              <select className='form-control' id="service_category_id" name="service_category_id"   onChange={(e)=>getServices(parseInt(e.target.value))} >
                <option value={0}>Select Category</option>
               {service_categories.map((service_category,index)=>(
                <option value={service_category.id}>{service_category.service_category_name}</option>
               ))}
              </select>
            </div> */}
            {service_category_id!==0?<div className='col-md-3'>
              <label htmlFor="">Services :</label>
               <select className='form-control' id="service_id" name="service_id" value={service_id} onChange={(e)=>setServiceId(parseInt(e.target.value))}> 
             
                    <option value="0">Select Service </option>        
                         {services.map((service,index)=>(
                            <option value={service.id}>{service.service_name}</option>
                         ))}   
                </select>             
                            
                </div>
                
                :""}   
                 </div>
                 {service_id===15 || service_id===105 || service_id===191?<div className='row my-5'>
                  <div className='col-md-3'>
                    <label>OET User Id</label>
                    <input type="text" className='form-control' name="oet_login_id" id="oet_login_id" value={oet_login_id} onChange={(e)=>setOetLoginId(e.target.value)} required />
                  </div>
                  <div className='col-md-2'>
                    <label>OET Password</label>
                    <input type="text" className='form-control' name="oet_login_password" id="oet_login_password" value={oet_login_password} onChange={(e)=>setOetLoginPassword(e.target.value)} required />

                  </div>
                  <div className='col-md-4'>
                  <label>OET Secret Question</label>
                    <input type="text" className='form-control' name="oet_secret_question" id="oet_secret_question" value={oet_secret_question} onChange={(e)=>setOetSecretQuestion(e.target.value)} required />

                  </div>
                  <div className='col-md-3'>
                  <label>OET Secret Answer</label>
                    <input type="text" className='form-control' name="oet_secret_answer" id="oet_secret_answer" value={oet_secret_answer} onChange={(e)=>setOetSecretAnswer(e.target.value)}required />

                  </div>

                 </div>:""}
                 {service_id===90 || service_id===176 || service_id===262?<div className='row my-5'>
                  <div className='col-md-2'>
                    <label> IELTS User Id</label>
                    <input type="text" className='form-control' name="ielts_login_id" id="ielts_login_id" value={ielts_login_id} onChange={(e)=>setIeltsLoginId(e.target.value)} required />
                  </div>
                  <div className='col-md-2'>
                    <label>IELTS Password</label>
                    <input type="text" className='form-control' name="ielts_login_password" id="ielts_login_password" value={ielts_login_password} onChange={(e)=>setIeltsLoginPassword(e.target.value)}required />

                  </div>
                  <div className='col-md-4'>
                  <label>IELTS Secret Question</label>
                    <input type="text" className='form-control' name="ielts_secret_question" id="ielts_secret_question" value={ielts_secret_question} onChange={(e)=>setIeltsSecretQuestion(e.target.value)}required />

                  </div>
                  <div className='col-md-4'>
                  <label>IELTS Secret Answer</label>
                    <input type="text" className='form-control' name="ielts_secret_answer" id="ielts_secret_answer" value={ielts_secret_answer} onChange={(e)=>setIeltsSecretAnswer(e.target.value)}required />

                  </div>

                 </div>:""}
                 {service_id===14 || service_id===104 || service_id===190?<div className='row my-5'>
                  <div className='col-md-3'>
                    <label>GMC Reference Number</label>
                    <input type="number" className='form-control' name="gmc_reference_number" id="gmc_reference_number" value={gmc_reference_number} onChange={(e)=>setGmcReferenceNumber(parseInt(e.target.value))} required />
                  </div>
                  <div className='col-md-2'>
                    <label>GMC Password</label>
                    <input type="text" className='form-control' name="gmc_login_password" id="gmc_login_password" value={gmc_login_password} onChange={(e)=>setGmcLoginPassword(e.target.value)} required />

                  </div>
                  <div className='col-md-4'>
                  <label>GMC Secret Question</label>
                    <input type="text" className='form-control' name="gmc_secret_question" id="gmc_secret_question" value={gmc_secret_question} onChange={(e)=>setGmcSecretQuestion(e.target.value)} required />

                  </div>
                  <div className='col-md-3'>
                  <label>GMC Secret Answer</label>
                    <input type="text" className='form-control' name="gmc_secret_answer" id="gmc_secret_answer" value={gmc_secret_answer} onChange={(e)=>setGmcSecretAnswer(e.target.value)}required />

                  </div>

                 </div>:""}
                 {service_id===33|| service_id===123 ||service_id===209?<div className='row my-5'>
                  <div className='col-md-3'>
                    <label>Epic Account Setup Date</label>
                    <input type="date" className='form-control' name="epic_account_setup_date" id="epic_account_setup_date" value={epic_account_setup_date} onChange={(e)=>setEpicAccountSetupDate(e.target.value)} required />
                  </div>
                  <div className='col-md-3'>
                    <label>Epic Username</label>
                    <input type="text" className='form-control' name="epic_username" id="epic_username" value={epic_username} onChange={(e)=>setEpicUsername(e.target.value)} required />

                  </div>
                  <div className='col-md-3'>
                    <label>Epic Password</label>
                    <input type="text" className='form-control' name="epic_password" id="epic_password" value={epic_password} onChange={(e)=>setEpicPassword(e.target.value)} required />

                  </div>
                  <div className='col-md-3'>
                  <label>Epic Id</label>
                    <input type="text" className='form-control' name="epic_id" id="epic_id" value={epic_id} onChange={(e)=>setEpicId(e.target.value)} required />
                  </div>
                  <div className='col-md-6 mt-3'>
                          <label> Secret Question 1</label>
                          <input type="text" className="form-control" name="epic_secret_question1" id="epic_secret_question1" value={epic_secret_question1} onChange={(e)=>setEpicSecretQuestion1(e.target.value)} required />
                  </div>
                  <div className='col-md-6 mt-3'>
                          <label> Secret Answer 1</label>
                          <input type="text" className="form-control" name="epic_secret_answer1" id="epic_secret_answer1" value={epic_secret_answer1} onChange={(e)=>setEpicSecretAnswer1(e.target.value)} required />
                  </div>

                  <div className='col-md-6 mt-3'>
                          <label> Secret Question 2</label>
                          <input type="text" className="form-control" name="epic_secret_question2" id="epic_secret_question2" value={epic_secret_question2} onChange={(e)=>setEpicSecretQuestion2(e.target.value)} required />
                  </div>
                  <div className='col-md-6 mt-3'>
                          <label> Secret Answer 2</label>
                          <input type="text" className="form-control" name="epic_secret_answer2" id="epic_secret_answer2" value={epic_secret_answer2} onChange={(e)=>setEpicSecretAnswer2(e.target.value)} required />
                  </div>
                  <div className='col-md-6 mt-3'>
                          <label> Secret Question 3</label>
                          <input type="text" className="form-control" name="epic_secret_question3" id="epic_secret_question3" value={epic_secret_question3} onChange={(e)=>setEpicSecretQuestion3(e.target.value)} required />
                  </div>
                  <div className='col-md-6 mt-3'>
                          <label> Secret Answer 3</label>
                          <input type="text" className="form-control" name="epic_secret_answer3" id="epic_secret_answer3" value={epic_secret_answer3} onChange={(e)=>setEpicSecretAnswer3(e.target.value)} required />
                  </div>
                  <div className='col-md-6 mt-3'>
                          <label> Secret Question 4</label>
                          <input type="text" className="form-control" name="epic_secret_question4" id="epic_secret_question4" value={epic_secret_question4} onChange={(e)=>setEpicSecretQuestion4(e.target.value)} required />
                  </div>
                  <div className='col-md-6 mt-3'>
                          <label> Secret Answer 4</label>
                          <input type="text"  className="form-control" name="epic_secret_answer4" id="epic_secret_answer4" value={epic_secret_answer4} onChange={(e)=>setEpicSecretAnswer4(e.target.value)} required />
                  </div>
                  <div className='col-md-6 mt-3'>
                          <label>Upload EIF</label>
                          <input type="file"  className="form-control" name="epic_eif" id="epic_eif"  required />
                  </div>

                 </div>:""}

                 {service_id===35 || service_id===125||service_id===211?<div className='row my-5'>
                  <div className='col-md-6'>
                    <label>Notarycam Username</label>
                    <input type="text" className='form-control' name="notarycam_username" id="notarycam_username" value={notarycam_username} onChange={(e)=>setNotarycamUsername(e.target.value)} required />
                  </div>
                  <div className='col-md-6'>
                    <label>Notarycam Password</label>
                    <input type="text" className='form-control' name="notarycam_password" id="notarycam_password" value={notarycam_password} onChange={(e)=>setNotarycamPassword(e.target.value)} required/>

                  </div>
                 

                 </div>:""}
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-info text-white mt-2'>Update Details</button></div>
        </form>
   
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default EditRegistrationDetails;