import React,{useState,useEffect, FormEvent} from 'react'
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { SpecialityData } from '../../@types/specialitytype';
import pencil from '../../assets/images/pencil.png';
import { SubSpecialityData } from '../../@types/subspeciality';
import Swal from 'sweetalert2';
import server from '../Utils';
import Loader from './Loader';


const SubSpeciality = () => {
  const[sub_specialities,setSubSpecialities]=useState<Array<SubSpecialityData>>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    server.get(`/sub_specialities`)
    .then((response)=>{
        if(response.data.sub_specialities.length>0){
        setSubSpecialities(response.data.sub_specialities)
    }else
        {
            setSubSpecialities([])
        }
    })
  }, []);
  const deleteSpeciality=(id:number)=>
  {
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
            server.delete(`/sub_speciality/${id}`)
            .then((response)=>{
                if(response.data.sub_specialities.length>0){
                setSubSpecialities(response.data.setSubSpecialities)
                swalWithBootstrapButtons.fire({
                    title: "Deleted!",
                    text: "Your file has been deleted.",
                    icon: "success"
                  });
                }else
                {
                    setSubSpecialities([])
                }
            })
         
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Your imaginary file is safe :)",
            icon: "error"
          });
        }
      });
   
  }

  if (loading) {
    return <Loader />;
  }
  return (
<div className='container-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}

    <h4 className='text-danger text-center'>Add SubSpecialities</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>
    
    <div className='col-md-11 ml-3'>
    <Link to="/add_sub_speciality" className='btn btn-outline-primary float-right my-3'>Add SubSpeciality</Link>
      <table className='table table-bordered table-striped table-hover'>
        <tr className='bg-warning'><th>Sl no</th><th>Speciality</th><th>SubSpeciality</th><th className='text-center' colSpan={2}>Actions</th></tr>
      
      {sub_specialities.length>=1 &&  sub_specialities.map((sub_speciality,index)=>(
        <tr><td>{index+1}</td><td>{sub_speciality.speciality}</td><td>{sub_speciality.sub_speciality}</td><td><Link className='text-right' to={`/sub_speciality/${sub_speciality.id}`}><img width="20px" height="20px" src={pencil} alt="pencil"/></Link></td><td><i style={{marginTop:"4%"}} onClick={()=>deleteSpeciality(sub_speciality.id)} className='fa fa-trash'></i></td></tr>
      ))}
       </table>
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default SubSpeciality