import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';

import {Link,NavLink,useParams} from 'react-router-dom';

import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { message } from 'antd';

import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import $ from 'jquery'; 

import validator from 'validator';
import { off } from 'process';
import { type } from 'os';
import { vendortype } from '../../@types/vendortype';
import {trainingtype} from '../../@types/trainingtype';
import { training_details } from '../../@types/training_details';
import server from '../Utils';
import moment from 'moment';
import Loader from './Loader';

interface props{
  user_agreed:number
}
const Training :React.FC<props> = ({user_agreed}) => {
        
   
   
    const [project_id,setProjectId]=useState<number>(0);
    const [user_exist,setUserExists]=useState<boolean>(false);
    const [training_exists,setTrainingExists]=useState<boolean>(false);
    const {id}= useParams();
    const [loading, setLoading] = useState(false);

    const [assesment_date,setAssesmentDate] =useState<string>('');
    const[service_category_name,setServiceCategoryName]=useState<string>('')
    const [service_name,setServiceName]=useState<string>('')
    const [vendor_name,setVendorName]=useState<string>('');
    const [vendor_id,setVendorId]=useState<number>(0);
    const [mode_of_training,setModeOfTraining]=useState(0);
    const [start_date,setStartDate]=useState<string>('')
    const [end_date,setEndDate]=useState<string>('');
    const [training_id,setTrainingId]=useState<number>(0);
    const [trainings,setTraining]=useState<Array<training_details>>([])
    

    
    useEffect(() => {
      // setLoading(true)
      
     
   
       server.get(`/training/${id}`)
       .then((response)=>{
        setTraining(response.data.training)
        
        // setLoading(false)
      //   setTrainingId(response.data.training.id)
      //   setAssesmentDate(response.data.training.assessment_date)
      //  setServiceCategoryName(response.data.training.service_category_name)
      //  setServiceName(response.data.training.service_name)
      //   setVendorName(response.data.training.vendor_name)
      //   setModeOfTraining(response.data.training.mode_of_training)
      //   setStartDate(response.data.training.start_date)
      //   setEndDate(response.data.training.end_date)
       })
      }, []);


    
      // if (loading) {
      //   return <Loader />;
      // }
  return ( <div >
    {/* {user_exist===false?<Link to={`/add_onboarding_details/${id}`}  className='btn btn-info text-light text-capitalize'>Add Onboarding details before proceeding to Training</Link>:""} */}

    {user_agreed === 1 ? 
  
    <Link to={`/add_training_details/${id}`} className='btn btn-info text-light float-right'>+</Link> :
  <p className='text-danger'>Kindly ask the doctor to fill their details in their portal</p>
}
    {/* <Link to={`/add_training_details/${id}`} className='btn btn-info text-white float-right' >Add Training </Link> */}
   <br/>
   <br/>
   {trainings.map((training,index)=>(
    <div className='jumbotron'>
       <Link to={`/edit_training_details/${training.id}`} className={"nav-link"} ><i className='fa fa-edit text-info'></i></Link>
       <div className='row my-2'>
        <div className="col-md-4"><label>Test Category</label></div>
        <div className="col-md-4"><i>{training.service_category_name}</i></div>
        <div className="col-md-4"></div>
      </div>

      <div className='row my-2'>
        <div className="col-md-4"><label>Test Name</label></div>
        <div className="col-md-4"><i>{training.service_name}</i></div>
        <div className="col-md-4"></div>
      </div>
      <div className='row my-2'>
        <div className="col-md-4"><label>Mode Of Training</label></div>
        <div className="col-md-4"><i>{training.mode_of_training===1?"Online":training.mode_of_training===2?"Offline":training.mode_of_training===3?"Online Crash Course":training.mode_of_training===4?"Offline Crash Course":""}</i></div>
        <div className="col-md-4"></div>
      </div>
      <div className='row my-2'>
        <div className="col-md-4"><label>Updated By</label></div>
        <div className="col-md-4"><i>{training.updated_first_name} {training.updated_last_name}</i></div>
        <div className="col-md-4"></div>
      </div>
      <div className='row my-2'>
        <div className="col-md-4"><label>Added By</label></div>
        <div className="col-md-4"><i>{training.created_first_name} {training.created_last_name}</i></div>
        <div className="col-md-4"></div>
      </div>
     
      <div className='row my-2'>
        <div className="col-md-4"><label>Start Date</label></div>
        <div className="col-md-4"><i>{moment(training.start_date).format('Do MMMM YYYY')}</i></div>
        <div className="col-md-4"></div>
      </div>
      <div className='row my-2'>
        <div className="col-md-4"><label>End Date</label></div>
        <div className="col-md-4"><i>{moment(training.end_date).format('Do MMMM YYYY')}</i></div>
        <div className="col-md-4"></div>
      </div>
    </div>

   ))}
   
    {/* {user_exist===true ?{trainings.map((training,index)=>(<div>
      
        
      
      
      
      
      <Link to={`/edit_training_details/${training.id}`} className={"nav-link mr-5 pr-5"} >Edit</Link>
     
      <div className='row'>
        <div className="col-md-4"><label>Service Category</label></div>
        <div className="col-md-4">{service_category_name}</div>
        <div className="col-md-4"></div>
      </div>
     <div className='row'>
        <div className="col-md-4"><label>Service Name</label></div>
        <div className="col-md-4">{service_name}</div>
        <div className="col-md-4"></div>
      </div>
      <div className='row'>
        <div className="col-md-4"><label>Vendor Name</label></div>
        <div className="col-md-4">{vendor_name}</div>
        <div className="col-md-4"></div>
      </div>
      <div className='row'>
        <div className="col-md-4"><label>Mode Of Training</label></div>
        <div className="col-md-4">{mode_of_training===1?"Online":mode_of_training===2?"Offline":mode_of_training===3?"Online Crash Course":mode_of_training===4?"Offline Crash Course":""}</div>
        <div className="col-md-4"></div>
      </div>
      <div className='row'>
        <div className="col-md-4"><label>Start Date</label></div>
        <div className="col-md-4">{start_date}</div>
        <div className="col-md-4"></div>
      </div>
      <div className='row'>
        <div className="col-md-4"><label>End Date</label></div>
        <div className="col-md-4">{end_date}</div>
        <div className="col-md-4"></div>
      </div>
      
      
    
    </div>
    ))} :""} */}
    </div>)
}

export default Training;