
import { trainingtype } from '../../@types/trainingtype';
import React,{useEffect, useState} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link} from 'react-router-dom';
import server from '../Utils';

const CoursesAllocated = () => {
   const [trainings,setTraining]=useState<Array<trainingtype>>([])
   const [loading, setLoading] = useState(false);

   let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
   let id= +projectid
   
   
   useEffect(() => {
    server.get(`/courses_allocated_crud/${id}`)
    .then((response)=>{
        
      setTraining(response.data.courses_allocated)
})
  }, []);
  const deleteProgram=(deleteid:number)=>{
    server.delete(`/delete_courses_allocated_crud/${deleteid}`)
    .then((response)=>{
      setTraining(response.data.courses_allocated)
    })}

 
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}

<div className="row px-2">
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-primary shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
        Earnings (Monthly)</div>
        <div className="h5 mb-0 font-weight-bold text-gray-800">$40,000</div>
    </div>
    <div className="col-auto">
        <i className="fas fa-calendar fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-success shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
        Earnings (Annual)</div>
        <div className="h5 mb-0 font-weight-bold text-gray-800">$215,000</div>
    </div>
    <div className="col-auto">
        <i className="fas fa-dollar-sign fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
{/* Earnings (Monthly) Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-info shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3  ">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Tasks
        </div>
        <div className="row no-gutters align-items-center">
        <div className="col-auto">
            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">50%</div>
        </div>
        <div className="col">
            <div className="progress progress-sm mr-2">
            <div className="progress-bar bg-info" role="progressbar" style={{width: '50%'}} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
            </div>
        </div>
        </div>
    </div>
    <div className="col-auto">
        <i className="fas fa-clipboard-list fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
{/* Pending Requests Card Example */}
<div className="col-xl-3 col-md-6 mb-4">
<div className="card border-left-warning shadow h-100 py-2">
<div className="card-body">
    <div className="row no-gutters align-items-center px-3">
    <div className="col mr-2">
        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
        Pending Requests</div>
        <div className="h5 mb-0 font-weight-bold text-gray-800">18</div>
    </div>
    <div className="col-auto">
        <i className="fas fa-comments fa-2x text-gray-300" />
    </div>
    </div>
</div>
</div>
</div>
</div>
    <h4 className='text-danger text-left'>Courses Allocated</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>
    
    <div className='col-md-11 ml-3'>
        <Link to="/add_training_subscriptions" className='btn btn-outline-primary float-right my-3'>Add TrainingAndSubscriptions </Link>
      <table className='table table-bordered table-striped table-hover'>
        <tr className='bg-warning'><th>Sl no</th><th>Product Name</th><th>Service Category</th><th>Service Name</th> <th className='text-center' colSpan={2}>Actions</th></tr>
      
      {trainings.map((training)=>(
        <tr><td>{training.id}</td><td><Link className='text-left' to={`/training_subscriptions_edit/${training.id}`}>{training.product_name}</Link></td><td>{training.service_category_name}</td><td>{training.service_name}</td><td>edit</td><td><i onClick={()=>deleteProgram(training.id)} className='fa fa-trash'></i></td></tr>
      ))}
       </table>
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
  
}

export default CoursesAllocated