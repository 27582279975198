import React, { useState,useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import SideBar from '../../pages/SideBar';
import Header from '../../pages/Header';
import {Link,useParams} from 'react-router-dom'
import { vendortype } from '../../@types/vendortype';
import server from '../Utils';
import axios from 'axios';
import { citytype } from '../../@types/citytype';
import { countrytype } from '../../@types/contrytype';
import { statetype } from '../../@types/statetype';
import validator from 'validator';
import Loader from './Loader';
import { multipleusertype } from '../../@types/multipleusertype';
import { Select } from 'antd';
import { ProductData } from '../../@types/producttype';



const AddMultipleGMCRegistration: React.FC = () => {
  const [project_id,setProjectId] = useState<number>(0);
  const [product_id,setProductId] = useState<number>(0);
  const [vendors,setVendors]=useState<Array<vendortype>>([]);
  const [vendor_id,setVendorId]=useState<number>(0)
  
  const [gmc_license_status,setGMCLicenseStatus]=useState<number>(1);
  const [gmc_license_date,setGMCLicenseDate]=useState<string>('');
  const [gmc_submission_date,setGMCSubmissionDate]=useState<string>('');
  const [trac_account,setTracAccount]=useState<string>('');
  const [trac_password,setTracPassword]=useState<string>('');
  const [cv_review,setCVReview]=useState<boolean>(false);
  const [apply_for_jobs,setApplyForJobs]=useState<number>(0);
  const [job_offer_date,setJobOfferDate]=useState<string>('');
  const [employers_name,setEmployersName]=useState<string>('');
  
  const [sendemaitocordinator,setSendEmailToCordinator]=useState<boolean>(false);
  const [application_status,setApplicationStatus]=useState<number>(0);
  const [application_submission_date,setApplicationSubmissionDate]=useState<string>('');
  const [visa_issued,setVisaIssued]=useState<number>(0);
  const [visa_issued_date,setVisaIssuedDate]=useState<string>('');

  const [countries,setCountries]=useState<Array<countrytype>>([]);
  const [country,setCountry]=useState<number>(0)
  const [states,setStates]=useState<Array<statetype>>([]);
  const [currentstate,setCurrentState]=useState<number>(0);
  const [cities,setCities]=useState<Array<citytype>>([]);
  const [city,setCity]=useState<number>(0);
  const [notes, setNotes] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [users,setUsers]=useState<Array<multipleusertype>>([]);
  const [user_id,setUserId]=useState<number>(0)
 

const [products,setProducts]=useState<Array<ProductData>>([])

// var options: SelectProps['options'] = [];

const [error,setError]=useState<boolean>(false);
const [count,setCount]=useState<number>(0);
const [userError,setUserError]=useState<string>('')
  useEffect(() => {
    let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
    let id = +projectid;
    setProjectId(id)
    server.get(`/get_product/${id}`)
    .then((response)=>{
        
        setProducts(response.data.product)
        
    })
    let productid:string=JSON.parse(localStorage.getItem("product_id")||'{}');
    let product_id = +productid;
    
    server.get('get_operationuser')
      .then((response)=>{
        setUsers(response.data.users)
         
      }) 
  server.get(`/vendor_for_visa/${projectid}/${product_id}`)
  .then((response)=>{
    setVendors(response.data.vendors)
  })
  server.get(`/get_countries`)
  .then((response)=>{
    setCountries(response.data.countries)
  })

  }, []);
  

  const setStatesArray=(id:number)=>{
    setCountry(id)
    server.get('get_operationuser')
      .then((response)=>{
        setUsers(response.data.users)
      }) 
    server.get(`/get_states/${id}`)
    .then((response)=>{
       setStates(response.data.states)
    })
  }
  const setCityArray=(id:number)=>{
    setCurrentState(id)
    server.get(`/get_cities/${id}`)
    .then((response)=>{
       setCities(response.data.cities)
    })
  }
  const handleValidation = () => {
    // Perform validation for each form field
  
    if (gmc_license_status === 0) {
      return 'Select the GMC License Status';
    }
  
    if (!gmc_license_date && gmc_license_status === 4) {
      return 'GMC License Date is required';
    }
  
    if (apply_for_jobs === 0 && gmc_license_status !== 1) {
      return 'Select the Applying for Jobs Status';
    }
    if(gmc_license_status !== 1 && gmc_license_status !== 2  && !gmc_submission_date){
      return 'GMC Submission Date is required';

    }
    // if (!job_offer_date && gmc_license_status !== 1) {
    //   return 'Job Offer Date is required';
    // }
  
    // if (apply_for_jobs === 1 && !employers_name && gmc_license_status !== 1) {
    //   return 'Employer Name is required';
    // }
  
    // if (application_submission_date==="" && application_status !== 1) {
    //   return 'Application Submission Date is required';
    // }
  

    if(user_id===0){
        toast.error('Select the doctor from list')
    }
    if (
      gmc_license_status === 4 &&
      gmc_submission_date &&
      new Date(gmc_submission_date) > new Date(gmc_license_date)
    ) {
      return 'Submission date should not be greater than GMC License date';
    }
    if(!validator.isEmpty(job_offer_date) && validator.isEmpty(employers_name)){
      return'Enter Employer/Hospital Name';
    }
    if(!validator.isEmpty(job_offer_date) && country===0){
      return'select country';
    }
    if(country!==0 && currentstate===0){
      return'select state';
    }
   
    if (visa_issued === 1 && !visa_issued_date && gmc_license_status !== 1) {
      return 'Visa Issued Date is required';
    }
  
    if (vendor_id !== 0) {
      if (application_status === 0 ) {
        return 'Select the Application Status';
      }
  
      if (application_status === 3 && !application_submission_date && gmc_license_status !== 1) {
        return 'Application Submission Date is required';
      }
  
      if (application_status === 3 && visa_issued === 0 && gmc_license_status !== 1) {
        return 'Select the Visa Issued Status';
      }
  
      if (application_status === 3 && visa_issued === 1 && !visa_issued_date && gmc_license_status !== 1) {
        return 'Visa Issued Date is required';
      }
    }
  
    // If there are no validation errors, return null
    return null;
  };
  
  const setallUsers=(id:number)=>{
    setUserError('loading the users..')
    setProductId(id)
    setUserId(0)
    setError(true);
    setCount(count+1)
  server.get(`get_operationuser/${id}`)
        .then((response)=>{
          setUsers(response.data.users)
         if(response.data.users.length===0){
            setUserError('There are no users for this project');
         }else{
            setUserError('')
         }
        })
  
  }  
  


  if (loading) {
    return <Loader />;
  }
  const onChange = (value:number) => {
    setUserId(value)
   };
  
   let options = [];
  
  for (let i = 0; i < users.length; i++) {
  options.push({
     value: users[i].id,
     label: users[i].user
  });
  }
   // Filter `option.label` match the user type `input`
   const filterOption = (input:any, option:any) =>
     (option?.label ?? '').toLowerCase().includes(input.toLowerCase());



  const addGMCRegistration = async (e: React.FormEvent) => {
    e.preventDefault();

    

    const validationError = handleValidation();

    if (validationError) {
      // Display validation error using a toast
      
      toast.error(validationError);
      
      return;
    }

    var form= document.getElementById('add_travel_and_accomdation') as HTMLFormElement;
      var formData=new FormData(form)

    try {
      // Your axios post request with FormData
      setLoading(true)
      await server.post(`/add_gmc_registration`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((reponse)=>{
        if(reponse.data.code===200){
          setLoading(false)
          toast(reponse.data.message);
          setTimeout(function () { window.history.back(); }, 1000);
        }else{
          setLoading(false)
          toast.error(reponse.data.message);
        }
      })

      // Handle success (existing code)

    } catch (error) {
      // Handle error (existing code)
    }
  };


  return (
    <div className='container-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}


    <h4 className='text-danger text-center'>Add Multiple Tier4Visas </h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>
   
       
  <div className="row">
        <div className="col-md-6">
     
        {project_id!==0?<div className='col-md-12'>
            <label >Products</label>
                     <select className='form-control' name='product_id' id="product_id" value={product_id} onChange={(e)=>setallUsers(parseInt(e.target.value))} required>
                        <option value={0}>Select Product</option>
                        {products.map((product)=>(
                            <option value={product.id}>{product.product_name}</option>))}
            </select>
            </div>:""}
      
        </div>
        <div className="col-md-6">

       

        {product_id !== 0 && (
                users.length !== 0 ? (
                  <div>
                    <label>Users</label>
                    <Select
showSearch
style={{ width: '100%' }}
placeholder="Select a person"
optionFilterProp="children"
onChange={onChange}
// onSearch={onSearch}
filterOption={filterOption}
options={options}
/>
                  </div>
                ) : (
                  <p className="text-danger">
                    {userError}
                  </p>
                )
              )}

        </div>
        </div>
    <div className='col-md-12 px-5'>
   
  <br/>
  <br/>
    {product_id!==0?<form id="add_travel_and_accomdation" onSubmit={addGMCRegistration} className="needs-validation w-100">
        <input type="hidden" value={project_id} name="project_id"/>
          <input type="hidden" value={product_id} name="product_id"/>
          <input type="hidden" value={user_id} name="user_id"/>
    <div className='row'>
        <div className='col-md-4'>
            <label>Applied for GMC License</label>
            <select className='form-control' id="gmc_license_status" value={gmc_license_status}  onChange={(e)=>setGMCLicenseStatus(parseInt(e.target.value))} name="gmc_license_status" >
               
                <option value={1}>Yet To Start</option>
                <option value={2}>In Progress</option>
                <option value={3}>Submitted</option>
                <option value={4}>Recieved</option>
            </select>
        </div>

        {gmc_license_status === 3 || gmc_license_status===4?  (
  <div className="col-md-3">
    <label>GMC submission Date</label>
    <input
      className="form-control"
      type="date"
      id="gmc_submission_date"
      value={gmc_submission_date}
      onChange={(e) => setGMCSubmissionDate(e.target.value)}
      name="gmc_submission_date"
    />
  </div>
) : (
  <div></div>
)}
        {gmc_license_status ===4?
  <div className="col-md-2">
    <label>GMC License Date</label>
    <input
      className="form-control"
      type="date"
      id="gmc_license_date"
      value={gmc_license_date}
      onChange={(e) => setGMCLicenseDate(e.target.value)}
      name="gmc_license_date"
    />
  </div>
 : 
  ""
}

        
    </div>
    { gmc_license_status!==1?  <div className='row mt-3'>
    <div className='col-md-3'>
            <label>Trac Account</label>
            <input className='form-control' type="text" id="trac_account" name="trac_account"  value={trac_account} onChange={(e)=>setTracAccount(e.target.value)} />
        </div>
        
        <div className='col-md-3'>
            <label>Trac Password</label>
            <input className='form-control' type="text" id="trac_password" name="trac_password"  value={trac_password} onChange={(e)=>setTracPassword(e.target.value)} />
        </div>
        <div className='col-md-2 text-center'>
            <label>CV Review </label><br/>
            <input type="checkbox" checked={cv_review} onChange={(e)=>setCVReview(!cv_review)} name="cv_review" id="cv_review" />
        </div>
    </div>:<div></div>}
    <div className='row mt-3'>
        
    { gmc_license_status!==1? <div className='col-md-2'>
            <label>Applying for Jobs</label>
            <select className='form-control' id="apply_for_jobs" value={apply_for_jobs}  onChange={(e)=>setApplyForJobs(parseInt(e.target.value))} name="apply_for_jobs" >
                <option value={0}>Choose Status</option>
                <option value={1}>Active</option>
                <option value={2}>InActive</option>
                
            </select>
        </div>:<div></div>}
        { gmc_license_status!==1?  <div className='col-md-3'>
            <label>Job Offer Date</label>
            <input className='form-control' type="date" id="job_offer_date" name="job_offer_date"  value={job_offer_date} onChange={(e)=>setJobOfferDate(e.target.value)} />
        </div>:<div></div>}
        
        {job_offer_date!==""?<div className='col-md-3'>
            <label>Hospital / Employer Name</label>
            <input className='form-control' type="text" id="employers_name" name="employers_name"  value={employers_name} onChange={(e)=>setEmployersName(e.target.value)} />
        </div>:<div></div>}
    </div>
    {job_offer_date!==""?<div className='row mt-3'>
        <h5>Hospital Address</h5>
    <div className='col-md-3'>
              <label>Country</label>
              {/* <input className="form-control"  type="text" name="country" id="country" value={country} onChange={(e)=>setCountry(e.target.value)}  required /> */}
              <select className='form-control'  name="country" id="country" value={country} onChange={(e)=>setStatesArray(parseInt(e.target.value))}  required>
                        <option value={0}>Select Country</option>
                        {countries.map((country,index)=>(
                          <option value={country.id}>{country.name}</option>
                        ))}
              </select>
            </div>
            <div className='col-md-3'>
            <label>State</label>
              {/* <input className="form-control" type="text" name="state" id="state" value={currentstate} onChange={(e)=>setCurrentState(e.target.value)}   required/> */}
              <select className='form-control' name="currentstate" id="state" value={currentstate} onChange={(e)=>setCityArray(parseInt(e.target.value))}   required>
              <option value={0}>Select State</option>
                        {states.map((state,index)=>(
                          <option value={state.id}>{state.name}</option>
                        ))}

              </select>
            </div>
            <div className='col-md-3'>
            <label>City</label>
              {/* <input className="form-control" type="city" name="city" id="city" value={city} onChange={(e)=>setCity(e.target.value)}  required /> */}
              <select className="form-control" name="city" id="city" value={city} onChange={(e)=>setCity(parseInt(e.target.value))}  >
              <option value={0}>Select City</option>
                        {cities.map((city,index)=>(
                          <option value={city.id}>{city.name}</option>
                        ))}
              </select>
            </div>
            </div>:<div></div>}
            <div className='row mt-4'>
            {job_offer_date!==""?  <h5>Job Visa Details</h5>:""}
              {job_offer_date!==""? <div className="col-sm-12 col-md-3 mb-3">
            <div className="form-group">
              <label htmlFor="visaFile">Select Vendor:</label>
              <select className='form-control' id={"vendor_id"} name={"vendor_id"} value={vendor_id} onChange={(e)=>setVendorId(parseInt(e.target.value))} >
                <option value={0}>Select Vendor</option>
                {vendors.map((vendor)=>(
                  <option value={vendor.id}>{vendor.vendor_name}</option>
                ))}
              </select>
            </div>
          </div>:""}
               {vendor_id!==0?<div className='col-md-3'>
                    <label>Email to Visa Cordinator</label><br/>
                    <input type="checkbox" checked={sendemaitocordinator} onChange={(e)=>setSendEmailToCordinator(!sendemaitocordinator)} name="sendemaitocordinator" id="sendemaitocordinator" />       
                </div>:<div></div>}
                
            </div>
            {vendor_id!==0? <div className='row mt-3'>
            <div className='col-md-2'>
                     <label>Application Status</label>
                 <select className='form-control' id="application_status" value={application_status}  onChange={(e)=>setApplicationStatus(parseInt(e.target.value))} name="application_status" >
                     <option value={0}>Choose Status</option>
                     <option value={1}>Yet To Start</option>
                     <option value={2}>Inprogress</option>
                     <option value={3}>Submitted</option>
                </select>
                 </div>
               {application_status===3 ? <div className='col-md-3'>
                    <label>Application Submission Date</label>
                    <input className='form-control' type="date" id="application_submission_date" value={application_submission_date} onChange={(e)=>setApplicationSubmissionDate(e.target.value)} name="application_submission_date"  />
                 </div>:<div></div>}
                 
                 { application_status===3?<div className='col-md-2'>
                     <label>Visa Issued</label>
                 <select className='form-control' id="visa_issued" value={visa_issued}  onChange={(e)=>setVisaIssued(parseInt(e.target.value))} name="visa_issued" >
                     <option value={0}>Choose Status</option>
                     <option value={1}>Yes</option>
                     <option value={2}>No</option>
                </select>
                 </div>:<div></div>}

                 { application_status===3? <div className='col-md-2'>
                    <label>Visa Issued  Date</label>
                    <input className='form-control' type="date" id="visa_issued_date" value={visa_issued_date} onChange={(e)=>setVisaIssuedDate(e.target.value)} name="visa_issued_date"  />
                 </div>:<div></div>}
            </div>:<div></div>}
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Add GMC Registration</button></div>
        </form>:""}
   
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default AddMultipleGMCRegistration;