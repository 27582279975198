import React, { useState, useEffect } from 'react';
import { useParams, Link, redirect } from 'react-router-dom';
import moment from 'moment';
import server from '../Utils';
import Loader from './Loader';
import { training_details } from '../../@types/training_details';
import UserSideBar from '../../pages/UserSideBar/UserSideBar';
import { FaRegQuestionCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { testbookingtype } from '../../@types/testbookingtype';
import { IoCheckmarkCircle } from "react-icons/io5";
import { FaCircleXmark } from "react-icons/fa6";
import Missed from '../../assets/images/Missed.png';
import booked from '../../assets/images/TestBooked.png';
import { GMCRegistrationType } from '../../@types/gmc_registration';
import yettostart from '../../assets/images/yettostart.png';
import inprogress from '../../assets/images/inprogress.png';
import submitted from '../../assets/images/submitted.png';
const UserGMCAndRegistrationDetails = () => {
    
    const [loading, setLoading] = useState(false);
    const [user_agreed,setUserAgreed]=useState<number>(0);
    const [first_name,setfirstName]=useState<string>("");
    const [last_name,setlastName]=useState<string>('');
    const [user_email,setUserEmail]=useState<string>('')
    const [gmc_registrations, setGMCRegistrations] = useState<Array<GMCRegistrationType>>([]);
   let id= localStorage.getItem('ClientID');
   var loggedIn=localStorage.getItem('loggedIn');
   useEffect(() => {
       if(loggedIn!=="true")
 {
   window.location.href="/userlogin";
 }
 setLoading(true)     
 server.get(`/operationuser/${id}`)
        .then((response)=>{
            setfirstName(response.data.user.first_name)
            setlastName(response.data.user.last_name)
            setUserEmail(response.data.user.user_email)
           if(response.data.user.user_agreement_flag===1){
             setUserAgreed(1)
           }else{
             setUserAgreed(2)
           }
        })
    
        server.get(`/gmc_registration/${id}`)
        .then((response) => {
          setGMCRegistrations(response.data.gmc_registration);
          setLoading(false);
        });
    }, [id]);

     if (loading) {
      return <Loader />;
    }
    
    return (

        <div className='container-fluid desktop_container' >
    <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name}/>
    <div className='horizontal-menu' style={{background:"transparent"}}>

      <div className='container-fluid mt-4 userpath_right_pad user_foarm_board'>
      <div className='d-flex mb-2 mt-3 prog-info'>
        <div className='w-75 text-left'>
            <h1><b className='blue-color'>GMC & Registrations</b></h1>
        </div>
        <div className='w-25 text-right blue-color fw-bold' style={{ fontSize: "29px" }}>
            <FaRegQuestionCircle className='mb-1' />
            <span className='support_hide'> Support</span>
        </div>
    </div>
    <div className='my-4'>
    {user_agreed===2?<p className="text-danger">
                            <Link to="/user_profile">Please Complete Profile here</Link>
                        </p>:""}
        { user_agreed === 0 ? (
                        <p className="text-danger">
                            {/* <Link to="/user_profile">Please Complete Profile here</Link> */}
                        </p>
                    ) : user_agreed === 1 && gmc_registrations.length > 0 ? (
                        <>
                         
                         {gmc_registrations.map((gmc_registration,index) => (
          <div className='row box-shadow p-4' style={{borderRadius:"20px"}}>
            <h3 className='blue-color fw-bold mb-3'>GMC License</h3>
           
              <div className="row">
              <hr/>
                <div className="col-md-4">
                 <label className="blue-color">GMC License Status:</label>
                </div>
                <div className="col-md-4">
                  <p>{gmc_registration.gmc_license_status===1?<span><img src={yettostart} width="40" height="40" alt="yet to start" /> Yet To Start</span>:gmc_registration.gmc_license_status===2?"In Progress":gmc_registration.gmc_license_status===3?"Submitted":gmc_registration.gmc_license_status===4?"Recieved":""}</p>
                </div>
              </div>
           
              { gmc_registration.gmc_license_date!==null? <div className="row">
                <div className="col-md-4">
                 <label className="blue-color">GMC License Date:</label>
                </div>
                <div className="col-md-4">
                  <p>{moment(gmc_registration.gmc_license_date).format('Do MMMM YYYY')}</p>
                </div>
              </div>:""}
              <h3 className='blue-color fw-bold mt-3'>Job Details</h3>
          
              {/* Additional Fields */}
              {gmc_registration.trac_account!==null?<div className="row">
              <hr/>
                <div className="col-md-4">
                 <label className="blue-color">Trac Account:</label>
                </div>
                <div className="col-md-4">
                  <p>{gmc_registration.trac_account}</p>
                </div>
              </div>:""}
              {gmc_registration.trac_password!==null?<div className="row">
                <div className="col-md-4">
                 <label className="blue-color">Trac Password:</label>
                </div>
                <div className="col-md-4">
                  <p>{gmc_registration.trac_password}</p>
                </div>
              </div>:""}
              {/* {gmc_registration.apply_for_jobs!==null? <div className="row">
                <div className="col-md-6">
                 <label className="blue-color">Apply for Jobs:</label>
                </div>
                <div className="col-md-6">
                  <p>{gmc_registration.apply_for_jobs === 1 ? 'Active' : 'Inactive'}</p>
                </div>
              </div>:""} */}
              {gmc_registration.job_offer_date!==null?  <div className="row">
                <div className="col-md-4">
                 <label className="blue-color">Job Offer Date:</label>
                </div>
                <div className="col-md-4">
                  <p>{moment(gmc_registration.job_offer_date).format('Do MMMM YYYY')}</p>
                </div>
              </div>:""}
              {gmc_registration.employers_name!==null?  <div className="row">
                <div className="col-md-4">
                 <label className="blue-color">Employer's Name:</label>
                </div>
                <div className="col-md-4">
                  <p>{gmc_registration.employers_name}</p>
                </div>
              </div>:""}
              {/* {gmc_registration.cv_review!==0? <div className="row">
                <div className="col-md-6">
                 <label className="blue-color">CV Review:</label>
                </div>
                <div className="col-md-6">
                  <p>{gmc_registration.cv_review ? 'Yes' : 'No'}</p>
                </div>
              </div>:""} */}
           
            
              {/* {gmc_registration.sendemaitocordinator!==0? <div className="row">
                <div className="col-md-6">
                 <label className="blue-color">Email to Visa Coordinator:</label>
                </div>
                <div className="col-md-6">
                  <p>{gmc_registration.sendemaitocordinator ? 'Yes' : 'No'}</p>
                </div>
              </div>:""} */}
              <h3 className='blue-color fw-bold mt-3'>Visa Details</h3>
              {gmc_registration.application_status!==null? <div className="row">
                <hr/>
                <div className="col-md-4">
                 <label className="blue-color">Application Status:</label>
                </div>
                <div className="col-md-4">
                  <p>{gmc_registration.application_status===1?"Yet to Start":gmc_registration.application_status===2?"In-Progress":gmc_registration.application_status===3?"Submitted":""}</p>
                </div>
              </div>:""}
              {gmc_registration.application_submission_date!==null?<div className="row">
                <div className="col-md-4">
                 <label className="blue-color">Application Submission Date:</label>
                </div>
                <div className="col-md-4">
                  <p>{moment(gmc_registration.application_submission_date).format('Do MMMM YYYY')}</p>
                </div>
              </div>:""}
              {gmc_registration.visa_issued!==null?<div className="row">
                <div className="col-md-4">
                 <label className="blue-color">Visa Issued:</label>
                </div>
                <div className="col-md-4">
                  <p>{gmc_registration.visa_issued === 1 ? 'Yes' : 'No'}</p>
                </div>
              </div>:""}
              {gmc_registration.visa_issued_date!==null? <div className="row">
                <div className="col-md-4">
                 <label className="blue-color">Visa Issued Date:</label>
                </div>
                <div className="col-md-4">
                  <p>{moment(gmc_registration.visa_issued_date).format('Do MMMM YYYY')}</p>
                </div>
              </div>:""}
          
            {/* End of Additional Fields */}
          </div>
        ))}
                        </>
                    ) : user_agreed === 1 && gmc_registrations.length === 0 ? (
                        <div className="text-danger bold pl-3 pt-2" style={{ position: 'absolute', top: "12%" }}>
                            Hi there! You do not have any GMC And Registrations allocated at the moment, kindly contact <a style={{ display: 'content' }} className='text-left' href="tel:+919019925836">9019925836</a>
                        </div>
                    ) : null}
        </div>
        
        </div>
       
   

    </div>
  
</div>


        
    );
};

export default UserGMCAndRegistrationDetails;
