import React, { useState, useEffect } from 'react';
import { useParams, Link, redirect } from 'react-router-dom';
import moment from 'moment';
import server from '../Utils';
import Loader from '../UserDocuments/Loader';
import { training_details } from '../../@types/training_details';
import UserSideBar from '../../pages/UserSideBar/UserSideBar';
import { FaRegQuestionCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { additionaldocumentstype } from '../../@types/additionaldocumenttype';

const UserDocuments = () => {
    
    var loggedIn=localStorage.getItem('loggedIn');
    const [pmq_status,setPmQStatus]=useState<number>(0);
    const [passport_photo_input,setpassphotInput]=useState<boolean>(false);
    const [client_photo_input,setClientPhotoInput]=useState<boolean>(false);
    const [provisional_degree_certificate_input,setProvisionalDegreeCertificateInput]=useState<boolean>(false);
    const [provisional_registration_certificate_input,setProvisionalRegistrationCertificateInput]=useState<boolean>(false);
    const [final_degree_certificate_input,setFinalDegreeCertificateInput]=useState<boolean>(false);
    const [final_registration_certificate_input,setFinalRegistrationCertificateInput]=useState<boolean>(false);
    const [internship_completion_certificate_input,setInternshipCompletionCertificateInput]=useState<boolean>(false);
    const [passport_photo,setPassportPhoto]=useState<string>('');
    const [client_photo,setClientPhoto]=useState<string>('');
    const [provisional_degree_certificate,setProvisionalDegreeCertificate]=useState<string>('');
    const [final_degree_certificate,setFinalDegreeCertificate]=useState<string>('');
    const [provisional_registration_certificate,setProvisionalRegistrationCertificate]=useState<string>('');
    const [final_registration_certificate,setFinalRegistrationCertificate]=useState<string>('');
    const [internship_completion_certificate,setInternshipCompletionCertificate]=useState<string>('');
    const[personal_note,setPersnolNote]=useState<string>("");
    const [welcome_call_date,setWelcomeCallDate]=useState<string>('');
    const [welcome_call_time,setWelcomeCallTime]=useState<number>(0);
    const [certificate_names,setcertificateNames]=useState<string>('');
    const [certificate_desc,setCertificateDesc]=useState<string>('');
    const [certificate_files,setCertificateFiles]=useState<string>('');
    const [file_count,setFileCount]=useState<number>(0);
    const [additional_documents,setAdditonalDocuments]=useState<Array<additionaldocumentstype>>([]);
    const[languagetestcertificate,setLanguageTestCertificate]=useState<string>(''); 
    const[plab1certificate,setPlab1Certificate]=useState<string>(''); 
    const[plab2certificate,setPlab2Certificate]=useState<string>(''); 
    const[good_standing_certificate,setGoodStandingCertificate]=useState<string>(''); 
    // const { id } = useParams();
    const [first_name,setfirstName]=useState<string>("");
    const [last_name,setlastName]=useState<string>('');
    const [user_email,setUserEmail]=useState<string>('')
    const [loading, setLoading] = useState(false);
  const [user_agreed,setUserAgreed]=useState<number>(0);
   let id= localStorage.getItem('ClientID');
   var loggedIn=localStorage.getItem('loggedIn');
   useEffect(() => {
       if(loggedIn!=="true")
 {
   window.location.href="/userlogin";
 }
 setLoading(true)
    server.get(`/operationuser/${id}`)
    .then((response)=>{
     
       if(response.data.user.user_agreement_flag===1){
         setUserAgreed(1)
       }else{
         setUserAgreed(2)
       }
    })
    
    server.get(`/operation_user_view/${id}`)
    .then((response)=>{
         setPmQStatus(response.data.user.pmq_status);
         setPersnolNote(response.data.user.personal_note);
         

    })

    server.get(`/get_documents/${id}`)
  .then((response)=>{
    
    if(response.data.code===200){

    // setPmQStatus(response.data.documents.pmq_status);
    // setClientPhoto(response.data.documents.client_photo);
    // setPassportPhoto(response.data.documents.passport_photo);
    // setProvisionalDegreeCertificate(response.data.documents.provisional_degree_certificate);
    // setFinalDegreeCertificate(response.data.documents.final_degree_certificate);
    // setProvisionalRegistrationCertificate(response.data.documents.provisional_registration_certificate);
    // setFinalRegistrationCertificate(response.data.documents.final_registration_certificate);
    // setInternshipCompletionCertificate(response.data.documents.internship_completion_certificate);
    // setcertificateNames(response.data.documents.certificate_names);
    // setCertificateDesc(response.data.documents.certificate_description);
    // setCertificateFiles(response.data.documents.certificate_file);
    // setFileCount(response.data.documents.file_count);
    setAdditonalDocuments(response.data.additional_documents)
    console.log(response)
    // setLanguageTestCertificate(response.data.documents.language_test_result)
    // setPlab1Certificate(response.data.documents.plab1_result)
    // setPlab2Certificate(response.data.documents.plab2_result)
    // setGoodStandingCertificate(response.data.documents.good_standing_certificate)
    setLoading(false)
 }else{
    setLoading(false)
 }
})
    
  }, []);

  const deleteDocument=(documentid:number)=>{

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        server.delete(`/delete_document/${documentid}`)
        .then((response)=>{
            if(response.data.code===200){
                server.get(`/get_documents/${id}`)
                .then((response)=>{
                  
                  if(response.data.code===200){
                  
                  // setPmQStatus(response.data.documents.pmq_status);
                  setClientPhoto(response.data.documents.client_photo);
                  setPassportPhoto(response.data.documents.passport_photo);
                  setProvisionalDegreeCertificate(response.data.documents.provisional_degree_certificate);
                  setFinalDegreeCertificate(response.data.documents.final_degree_certificate);
                  setProvisionalRegistrationCertificate(response.data.documents.provisional_registration_certificate);
                  setFinalRegistrationCertificate(response.data.documents.final_registration_certificate);
                  setInternshipCompletionCertificate(response.data.documents.internship_completion_certificate);
                  setcertificateNames(response.data.documents.certificate_names);
                  setCertificateDesc(response.data.documents.certificate_description);
                  setCertificateFiles(response.data.documents.certificate_file);
                  setFileCount(response.data.documents.file_count);
                  setAdditonalDocuments(response.data.additional_documents)
                  setLanguageTestCertificate(response.data.documents.language_test_result)
                  setPlab1Certificate(response.data.documents.plab1_result)
                  setPlab2Certificate(response.data.documents.plab2_result)
                  setGoodStandingCertificate(response.data.documents.good_standing_certificate)
               }
              })
            }
        })
        
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your file is safe :)",
          icon: "error"
        });
      }
    })  


}
  if (loading) {
    return <Loader />;
  }
    return (

        <div className='container-fluid desktop_container' >
    <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name}/>
    <div className='horizontal-menu' style={{background:"transparent"}}>

      <div className='container-fluid mt-4 userpath_right_pad user_foarm_board'>
      <div className='d-flex mb-2 mt-3 prog-info'>
        <div className='w-75 text-left'>
            <h1><b className='blue-color'>Additional Documents</b></h1>
        </div>
        <div className='w-25 text-right blue-color fw-bold' style={{ fontSize: "29px"}} >
            <FaRegQuestionCircle className='mb-1' />
            <span className='support_hide'> Support</span>
        </div>
    </div>
    <div className='my-4'>
    {user_agreed===2?<p className="text-danger">
                            <Link to="/user_profile">Please Complete Profile here</Link>
                        </p>:""}
        { user_agreed === 0 ? (
                        <p className="text-danger">
                            {/* <Link to="/user_profile">Please Complete Profile here</Link> */}
                        </p>
                    ) : user_agreed === 1  ? (
                        <>
                         
                         {<div className='box-shadow p-4' style={{borderRadius:"20px"}}>
       <div className='d-flex'>
        <div className='w-50 text-left'>
          {additional_documents.length>0?<h3 className='text-left blue-color fw-bold'>Documents</h3>:<p className='text-danger'>documents are not uploaded yet ,add the documents </p>}
         </div>
         
         <div className='w-50 text-right'>
          <Link to="/add_user_additional_documents"  className='btn bg-blue text-white'>Upload</Link>
         </div>
        </div>
        {additional_documents.length>0 &&<hr/>}
      { additional_documents.map((document) =>(
         <div className='row px-2 mt-5' id={"sub"}>
            
          {/* <div className='col-md-2'>
            
          </div>
          <div className='col-md-3'>
            
        
         </div> */}
         <div className='col-md-3'>
            
         {document.certificate_file.endsWith('.pdf')?<><iframe src={document.certificate_file} width={"100%"} height={"200px"}  className='rounded' style={{border:"2px solid #233974"}}/><br/><a href={document.certificate_file} className='text-center' target='_blank'>View Pdf</a></>:<img className='rounded' style={{border:"2px solid #233974"}} src={document.certificate_file!==null?document.certificate_file:""} width={200} height={200} alt=""/>}{document.added_by_user===1? <span className='text-right delete_mobile'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className='fa fa-trash text-danger' onClick={(e)=>deleteDocument(document.id)}></i></span>:""}
         </div>
         <div className='col-md-6 '>
         <h6 className="blue-color my-2">  <b> {document.certificate_type===1?"CPD webinar":document.certificate_type===2?"Course":document.certificate_type===3?"Publication":document.certificate_type===4?"leadership":document.certificate_type===5?"Teaching":document.certificate_type===6?"Conference":document.certificate_type===7?"Presentation":document.certificate_type===8?"Language Test Result":document.certificate_type===9?"PLAB-1 Test Result":document.certificate_type===10?"PLAB-2 Test Result":document.certificate_type===11?"Good Standing Certificate":document.certificate_type===12?"Others":""}</b></h6>
           <div className='border rounded' style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word',minHeight:"50px"}}>  {document.certificate_name!==null?document.certificate_name:""}</div>
         </div>
         <div className='col-md-3 text-center pt-4 delete_desktop'>
            {document.added_by_user===1?<i className='fa fa-trash text-danger' onClick={(e)=>deleteDocument(document.id)}></i>:"N/A"}
         </div>
        
         
     </div>
    )) }
        </div>}
                        </>
                    ) : user_agreed === 1 && additional_documents.length === 0 ? (
                        <div className="text-danger bold pl-3 pt-2" style={{ position: 'absolute', top: "12%" }}>
                            Hi there! Documents are not uploaded untill now, kindly contact <a style={{ display: 'content' }} className='text-left' href="tel:+919019925836">9019925836</a> or upload yourself
                        </div>
                    ) : null}
        </div>
        
        </div>
       
   

    </div>
  
</div>


        
    );
};

export default UserDocuments;
