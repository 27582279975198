import React, { useState, useContext,useEffect} from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { LoginContext } from '../../context/actions/LoginAction';
import wave from '../../assets/images/wave.png';

import logo from '../../assets/images/logo.png';
import avatar from '../../assets/images/avatar.png';
import './login.css';
import server from '../Utils';
// import bgImage from '../../assets/images/bgImage.png';
import bgImage from '../../assets/images/bgImage.jpg';
import Loader from './Loader'; // import the Loader component



// const useLogin = ({ email, password, setEmail, setPassword }: { email: string, password: string, setEmail: Dispatch<SetStateAction<string>>, setPassword: Dispatch<SetStateAction<string>> }) => {
  const Login = () => {
  let history = useNavigate();

  

  const [email,setEmail]= useState<string>("")
  const [password,setPassword]=useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const { loginDetails, loginstate, login, loginSuccess, setToken, loginError } = useContext(LoginContext);
  var isloggedIn=localStorage.getItem("isLoggedIn");
  var role=localStorage.getItem('role');
  
  

  

  if (isloggedIn === "true") {
    console.log("sadasds");
    window.location.href='/superadmindashboard';
  }


  const onLogin=(e:React.FormEvent<HTMLFormElement>)=>{
    e.preventDefault();
    setLoading(true)
  var loginform = document.getElementById("login_form");
  var formdata = new FormData(loginform as HTMLFormElement)
  axios.post("https://api.prod.goocampus.in/api/admin/login",formdata)
   .then((response) => {
      if (response.data.code === 200) {
        
        localStorage.setItem("login_details", response.data.admin_info)
        localStorage.setItem('admin_id',response.data.admin_info.id)
        localStorage.setItem("auth_token", loginstate.token)
        localStorage.setItem("isLoggedIn","true");
        localStorage.setItem("admin_name",response.data.admin_info.first_name+" "+response.data.admin_info.last_name)
        
        localStorage.setItem("role",response.data.admin_info.role)
        if(response.data.admin_info.role===1||response.data.admin_info.role===2||response.data.admin_info.role===3||response.data.admin_info.role===5||response.data.admin_info.role===6||response.data.admin_info.role===9)
          setTimeout(function() { history('/superadmindashboard') }, 1000);
            console.log('loggedIn');
        }else{
          setLoading(false)
          setError("You are not authorised")  
          console.log('not Logged In')
        }
        
        //loginSuccess(response.data.admin_info)
          
        setToken(response.data.auth_token)
        var isloggedIn=localStorage.getItem("isLoggedIn");
        if (isloggedIn==="true") {
        
      } else {
        setLoading(false)
        loginError(response.data.error)
        setError(loginstate.error[0])
      }


    });
  }

  if (loading) {
        return <Loader />;
      }


  return (    <div>
    <img className="wave" src={bgImage} width="100%" alt=" goocampus wave" />
    <div className="container" >
      <div className="img">
        {/* <img src={logo} alt="goocampus logo" /> */}
      </div>
      <div className="login-content">
        <form id="login_form" className='p-5' onSubmit={onLogin} style={{backgroundColor:"white",borderRadius:"5%",marginLeft:"10%"}} autoComplete="off">
          <img src={avatar} alt="goocampus avatar" />
          <h2 className="title">Welcome</h2>
          <div className="input-div one">
            <div className="i">
              <i className="fas fa-user" />
            </div>
            <div className="div">

              <input type="text" name="email" onChange={(e) => setEmail(e.target.value)} value={email} className="input" placeholder='Email' />
            </div>
          </div>
          <div className="input-div pass">
            <div className="i">
              <i className="fas fa-lock" />
            </div>
            <div className="div">

              <input type="password" name="password" placeholder='Password' onChange={(e) => setPassword(e.target.value)} value={password} className="input" />
            </div>
          </div>
          <br/>
         {/* <div > <Link style={{textAlign:"center"}} to={"https://api.prod.goocampus.in/password/reset"}>Forgot Password</Link></div> */}
          <div className='text-danger mt-4'>{error}</div>
          <button type="submit"  className="login_btn">Login</button>
        </form>
      </div>
    </div>
  </div>)
   
}

 export default Login;
