import React, { useEffect, useState } from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import server from '../Utils';
import Loader from './Loader';
import { service_categorytypedata } from '../../@types/service_categorytypedata';
import { ProductData } from '../../@types/producttype';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { GridApi, ColumnApi, ColDef } from 'ag-grid-community';
import { Select } from 'antd';

const { Option } = Select;

const Servicecategory: React.FC = () => {
  const [service_category_types, setServiceCategorytypes] = useState<service_categorytypedata[]>([]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<ProductData[]>([]);
  const [product_id, setProductId] = useState<number>(0);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);

  let service_categoryid: string = JSON.parse(localStorage.getItem("project_id") || '{}');
  let id = +service_categoryid;

  useEffect(() => {
    setLoading(true);
    Promise.all([
      server.get(`/get_product/${id}`),
      server.get(`/service_category_types/${id}`)
    ]).then(([productResponse, serviceCategoriesResponse]) => {
      setLoading(false);
      setProducts(productResponse.data.product.length > 0 ? productResponse.data.product : []);
      setServiceCategorytypes(serviceCategoriesResponse.data.service_category_types.length > 0 ? serviceCategoriesResponse.data.service_category_types : []);
    }).catch(error => {
      setLoading(false);
      console.error('Error fetching data:', error);
    });
  }, [id]);

  const displayStages = (productid: number) => {
    setProductId(productid);
    server.get(`/service_category_type_all/${id}/${productid}`)
      .then((response) => {
        setServiceCategorytypes(response.data.service_category_types.length > 0 ? response.data.service_category_types : []);
      });
  };

  const deleteServiceCategory = (deleteid: number) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        server.delete(`/service_category_type/${deleteid}/${service_categoryid}`)
          .then((response) => {
            setServiceCategorytypes(response.data.service_category_types.length > 0 ? response.data.service_category_types : []);
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success"
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your imaginary file is safe :)",
          icon: "error"
        });
      }
    });
  };

  const columns: ColDef[] = [
    { headerName: 'SL No', valueGetter: "node.rowIndex + 1", sortable: true, 
      // filter: 'agNumberColumnFilter',
       filter:false,floatingFilter: true, flex: 1 },
    { headerName: 'Product Name', field: 'product_name', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true, flex: 1 },
    { headerName: 'Service Category', field: 'service_category_name', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true, flex: 1 },
    { headerName: 'Service Category Type', field: 'service_category_type', sortable: true, filter: 'agTextColumnFilter', floatingFilter: true, flex: 1 },
    { headerName: 'Display', field: 'display', valueFormatter: (params) => params.value === 1 ? 'Yes' : 'No', sortable: true,filter:false,
      //  filter: 'agSetColumnFilter', 
       floatingFilter: true, flex: 1 },
    { headerName: 'Fixed', field: 'fixed', valueFormatter: (params) => params.value === 1 ? 'Fixed' : 'Unfixed', sortable: true,filter:false,
      //    filter: 'agSetColumnFilter', floatingFilter: true,
        flex: 1 },
    {
      headerName: 'Actions', flex: 1, cellRenderer: (params: { data: service_categorytypedata }) => (
        <div className="row">
          <div className="col-md-6">
            <Link to={`/service_category_type_edit/${params.data.id}`}>
              <i className="fa fa-edit text-success"></i>
            </Link>
          </div>
          <div className="col-md-6">
            <i onClick={() => deleteServiceCategory(params.data.id)} className="fa fa-trash text-danger"></i>
          </div>
        </div>
      ),
      sortable: false,
      filter: false,
    }
  ];

  const onGridReady = (params: { api: GridApi, columnApi: ColumnApi }) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='container-fluid'>
      <div className='d-flex'>
        <SideBar />
        <div className='headerbar'>
          <Header />
          <h4 className='text-danger text-center'>Service Category Types</h4>
          <hr className='bg-danger px-1' />
          <div className='row px-3'>
            <div className="col-md-6"> <Select className='w-50 my-3' value={product_id} onChange={(value) => displayStages(value)}>
                <Option value={0}>Select Product</Option>
                {products.map((product) => (
                  <Option key={product.id} value={product.id}>{product.product_name}</Option>
                ))}
              </Select></div>
            <div className="col-md-6">
            <Link to="/add_service_category_type" className='btn btn-outline-primary float-right my-3'>Add Service Category Type</Link>
            </div>
          </div>
          <div className='row mt-2 px-3'>
            <div className='col-md-12'>
              
             
              <div className='ag-theme-alpine' style={{ height: '500px', width: '100%' }}>
                <AgGridReact
                  onGridReady={onGridReady}
                  columnDefs={columns}
                  rowData={service_category_types}
                  defaultColDef={{ sortable: true, filter: true, floatingFilter: true }}
                  pagination={true}
                  paginationPageSize={10}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servicecategory;
