import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { message } from 'antd';
import { ServiceData } from '../../@types/servicetype';
import { DiscountTypes } from '../../@types/discounttypes';
import $ from 'jquery';
import {EditBoard} from './EditServicesDND/board';
import {Board} from './ServicesDND/board';
import server from '../Utils';
import { Link, useParams } from 'react-router-dom';
import { ColumnTypes } from '../../@types/columntype';
import Loader from './Loader';
import validator from 'validator';
import { counsellortype } from '../../@types/counsellortype';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import UserSideBar from '../../pages/UserSideBar/Index';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
const EditUser = () => {
    let  admin_id:string=JSON.parse(localStorage.getItem("admin_id")||'{}');
    const {id}= useParams();
    const userid=id;
    const[user_id,setUserId]=useState<number|any>(id)
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [projects,setProjects]=useState<Array<ProjectData>>([])
    const [discount_types,setDiscountTypes]=useState<Array<DiscountTypes>>([])
    const [discounted_amount,setDiscountedAmount]=useState<number>(0)
    const [project_id,setProjectId]=useState<number>(0);
    const [product_id,setProductId]=useState<number>(0);
    const [product_price,setProductPrice]=useState<number>(0);
    const [stages,setStages]=useState<Array<StageData>>([])
    const [stage_id,setStageId]=useState<number>(0);
    const [password,setPassword]=useState<string>("");
    const [confirm_password,setConfirmPassword]=useState<string>("");
    
    const [loading, setLoading] = useState(false);

     const[first_name,setfirstName]=useState<string>("");
     const[last_name,setlastName]=useState<string>("");
     const[primary_country_code,setPrimaryCountryCode]=useState<number>();
     const[primary_contact,setPrimaryContact]=useState<number>();
     const[alernate_country_code,setAlternateCountryCode]=useState<number>();
     const[alternatecontact,setAlternateContact]=useState<number>();
     const [allservicechecked,setAllServicesChecked]=useState<boolean>(true);
     
     const[user_email,setEmail]=useState<string>("");

     const [services,setServices]=useState<Array<ServiceData>>([])
     const[instalmenttypeid,setinstallmentTypeId]=useState<number>(0);
     const[installment1,setInstallment1]=useState<number>(0);
     const[installment2,setInstallment2]=useState<number>(0);
     const[installment3,setInstallment3]=useState<number>(0);
     const[installment4,setInstallment4]=useState<number>(0);
     const[installment5,setInstallment5]=useState<number>();
     const[installmen6,setInstallment6]=useState<number>();
     const [payable_balance,setPayableBalance]=useState<number>(0);
     const[installment2temp,setInstallment2Temp]=useState<number>(0);
     const[installment3temp,setInstallment3Temp]=useState<number>(0);
     const[installment4temp,setInstallment4Temp]=useState<number>(0);

     const[installmentdiscount1,setInstallmentDiscount1]=useState<number>(0);
     const[installmentdiscount2,setInstallmentDiscount2]=useState<number>(0);
     const[installmentdiscount3,setInstallmentDiscount3]=useState<number>(0);
     const[installmentdiscount4,setInstallmentDiscount4]=useState<number>(0);
     const[installmentdiscount5,setInstallmentDiscount5]=useState<number>(0);
     const[installmentdiscount6,setInstallmentDiscount6]=useState<number>(0);

     const[institutionaldiscount1,setInstitutionalDiscount1]=useState<number>(0);  
     const[institutionaldiscount2,setInstitutionalDiscount2]=useState<number>(0);  
     const[institutionaldiscount3,setInstitutionalDiscount3]=useState<number>(0);  
     const[institutionaldiscount4,setInstitutionalDiscount4]=useState<number>(0);  
     const[institutionaldiscount5,setInstitutionalDiscount5]=useState<number>(0);  
     const[institutionaldiscount6,setInstitutionalDiscount6]=useState<number>(0);  

     const [payments,setPayments]=useState<number>(1);
     const [payment1,setPayment1]=useState<number>(0);
     const [payment2,setPayment2]=useState<number>(0);
     const [payment3,setPayment3]=useState<number>(0);
     const [payment4,setPayment4]=useState<number>(0);
     const [payment5,setPayment5]=useState<number>(0);
     const [payment6,setPayment6]=useState<number>(0);

     const [payment_no_of_days1,setPaymentNoOfDays1]=useState<number>(0);
     const [payment_no_of_days2,setPaymentNoOfDays2]=useState<number>(0);
     const [payment_no_of_days3,setPaymentNoOfDays3]=useState<number>(0);
     const [payment_no_of_days4,setPaymentNoOfDays4]=useState<number>(0);
     const [payment_no_of_days5,setPaymentNoOfDays5]=useState<number>(0);
     const [payment_no_of_days6,setPaymentNoOfDays6]=useState<number>(0);

     const [paymenttrigger1,setPaymentTrigger1]=useState<number>(0);
     const [paymenttrigger2,setPaymentTrigger2]=useState<number>(0);
     const [paymenttrigger3,setPaymentTrigger3]=useState<number>(0);
     const [paymenttrigger4,setPaymentTrigger4]=useState<number>(0);
     const [paymenttrigger5,setPaymentTrigger5]=useState<number>(0);
     const [paymenttrigger6,setPaymentTrigger6]=useState<number>(0);

     const [payment_date1,setPaymentDate1]=useState<string>('');
     const [payment_date2,setPaymentDate2]=useState<string>('');
     const [payment_date3,setPaymentDate3]=useState<string>('');
     const [payment_date4,setPaymentDate4]=useState<string>('');
     const [payment_date5,setPaymentDate5]=useState<string>('');
     const [payment_date6,setPaymentDate6]=useState<string>('');
     
     const [timefunction1,setFunctionalTime1]=useState<number>(0);
     const [timefunction2,setFunctionalTime2]=useState<number>(0);
     const [timefunction3,setFunctionalTime3]=useState<number>(0);
     const [timefunction4,setFunctionalTime4]=useState<number>(0);
     const [timefunction5,setFunctionalTime5]=useState<number>(0);
     
     const [installment_date1,setInstallmentDate1]=useState<string>('');
     const [installment_date2,setInstallmentDate2]=useState<string>('');
     const [installment_date3,setInstallmentDate3]=useState<string>('');
     const [installment_date4,setInstallmentDate4]=useState<string>('');
     const [installment_date5,setInstallmentDate5]=useState<string>('');

     const [installment_recieve_date1,setInstallmentReciveDate1]=useState<string>('');
     const [installment_recieve_date2,setInstallmentReciveDate2]=useState<string>('');
     const [installment_recieve_date3,setInstallmentReciveDate3]=useState<string>('');
     const [installment_recieve_date4,setInstallmentReciveDate4]=useState<string>('');
     const [installment_recieve_date5,setInstallmentReciveDate5]=useState<string>('');
     const [installment_recieve_date6,setInstallmentReciveDate6]=useState<string>('');


     const [installment_no_of_days1,setInstallmentNoOfDays1]=useState<number>(0)
     const [installment_no_of_days2,setInstallmentNoOfDays2]=useState<number>(0)
     const [installment_no_of_days3,setInstallmentNoOfDays3]=useState<number>(0)
     const [installment_no_of_days4,setInstallmentNoOfDays4]=useState<number>(0)
     const [installment_no_of_days5,setInstallmentNoOfDays5]=useState<number>(0)
     const [installment_no_of_days6,setInstallmentNoOfDays6]=useState<number>(0)
    
     const [final_trigger1,setFinalTrigger1]=useState<number>(0);
     const [final_trigger2,setFinalTrigger2]=useState<number>(0);
     const [final_trigger3,setFinalTrigger3]=useState<number>(0);
     const [final_trigger4,setFinalTrigger4]=useState<number>(0);
     const [final_trigger5,setFinalTrigger5]=useState<number>(0);
     const [final_trigger6,setFinalTrigger6]=useState<number>(0);
     
     
     const [final_installment1,setFinalInstallment1]=useState<number>(0);
     const [final_installment2,setFinalInstallment2]=useState<number>(0);
     const [final_installment3,setFinalInstallment3]=useState<number>(0);
     const [final_installment4,setFinalInstallment4]=useState<number>(0);
     const [final_installment5,setFinalInstallment5]=useState<number>(0);
     const [final_installment6,setFinalInstallment6]=useState<number>(0);



     
     const[installment2stage, setInstallment2Stage]=useState<number>(0);
     const[installment3stage, setInstallment3Stage]=useState<number>(0);
     const[installment4stage, setInstallment4Stage]=useState<number>(0);
    
    const[stage_discount_amout,setStageDiscountAmount]=useState<number>(0);
     const[date_of_registration,setDateOfRegistration]=useState<string>("");
    
     const[product_amount,setProductAmount]=useState<number>();
     const[contract_file,setContractFile]=useState<any[]>([]);;
     const[counsellor_note,setCounsellorNote]=useState<string>();
    const[file,setFile]=useState<File>();
     const [installtypes,setInstalltypes]=useState<Array<InstallmentTypeData>>([])
     const [noofintallments,setNoOfInstallments]=useState<number>(0)
     const[discounted_installment_number,setDiscountedInstallmentNumber]=useState<number>()
     const[error,setError]=useState('');
     const [columns,setColumns]=useState<Array<ColumnTypes>>([])
     const [account_status,setAccountStatus]=useState<number>(0);
     const [temp,setTemp]=useState<number>(0);
     const [count,setCount]=useState<number>(0);
     const [counsellors,setCounsellors]=useState<Array<counsellortype>>();
     const [counsellor_id,setCounsellorId] =useState<number>(0);
     
       var projectid=localStorage.getItem('project_id') as string;
    
    var productid=localStorage.getItem('product_id') as string;
    const [boardKey, setBoardKey] = useState(0);
    useEffect(() => {

        
//    var count=localStorage.getItem("refresh_count");
 
  
//     if(count==='0'){
//       localStorage.setItem('refresh_count','1');
//       window.location.reload();
  
  
//     }
        let  service_categoryid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let id= +service_categoryid
        setProjectId(id)
       setLoading(true)
        server.get(`/discount_types`)
        .then((response)=>{
          
            if(response.data.discount_types.length>0){
            setDiscountTypes(response.data.discount_types)
            setLoading(false)
            }else{
                setDiscountTypes([]);
                setLoading(false)
            }
        })
        setLoading(true)
        server.get(`/get_table_columns`)
        .then((response)=>{
          setColumns(response.data.columns)
          setLoading(false)
        })
        setLoading(true)
        server.get(`/get_projects`)
        .then((response)=>{
          setProjects(response.data.projects)
          setLoading(false)
  
        })
        server.get('/get_counsellors')
        .then((response)=>{
            setCounsellors(response.data.counsellors)
        })
        setLoading(true)
        server.get(`/get_product/${project_id}`)
        .then((response)=>{
           
            if(response.data.product.length>0){
            setProducts(response.data.product)
            setLoading(false)
            }else{
                setProducts([]);
                setLoading(false)
            }
        })
        setLoading(true)
        server.get(`/get_stages`)
        .then((response)=>{
           
            setStages(response.data.stages)
            setLoading(false)
  
        })
        setLoading(true)
        server.get(`/get_installment_types`)
        .then((response)=>{
       
        setInstalltypes(response.data.installment_types)
        setLoading(false)

        })
        setLoading(true)
    server.get(`/get_services`)
    .then((response)=>{
        
        setServices(response.data.services)
        setLoading(false)
})
setLoading(true)  
server.get(`/operationuser/${userid}`)
.then((response)=>{
    setfirstName(response.data.user.first_name);
    setlastName(response.data.user.last_name);
    setPrimaryCountryCode(response.data.user.primary_contact_country_code);
    setPrimaryContact(response.data.user.primary_contact);
    setAlternateCountryCode(response.data.user.alernate_country_code);
    setAlternateContact(response.data.user.alternate_phone_number);
    setEmail(response.data.user.user_email);
    setProjectId(response.data.user.project_id);
    setProject(response.data.user.project_id)
    setAccountStatus(response.data.user.account_status);
    setContractFile(response.data.user.contract_file? [{ source: response.data.user.contract_file }] : []);
    server.get(`/get_product/${response.data.user.project_id}`)
    .then((response)=>{
       
        if(response.data.product.length>0){
        setProducts(response.data.product)
        }else{
            setProducts([]);
        }
    })
   
    setProductId(response.data.user.product_id);
    localStorage.setItem("product_id",response.data.user.product_id.toString())
    server.get(`/get_stage/${response.data.user.product_id}`)
          .then((response)=>{
             
            setStages(response.data.stage);
              
          })
          server.get(`/getallinstallments/${response.data.user.product_id}`)
          .then((response)=>{
             
              setInstalltypes(response.data.installment_types)
      
          })
    setDateOfRegistration(response.data.user.date_of_registration);
    setStageId(response.data.user.stage_id);
    setDiscountedAmount(response.data.user.discounted_amount);
    
    setProductAmount(response.data.user.product_amount);
    setinstallmentTypeId(response.data.user.installment_type);
    calNoofInstallments(response.data.user.installment_type)
    setNoOfInstallments(response.data.user.noofintallments);
    setInstallment1(response.data.user.installment1);
    setInstallment2(response.data.user.installment2);
    setInstallment3(response.data.user.installment3);
    setInstallment4(response.data.user.installment4);
    setInstallment5(response.data.user.installment5);
    setInstallment6(response.data.user.installment6);
    setCounsellorNote(response.data.user.counsellor_note);
    setCounsellorId(response.data.user.counsellor_id);
    setProductPrice(response.data.user.product_price);
    setDiscountedInstallmentNumber(response.data.user.discounted_installement_number);
    setStageDiscountAmount(response.data.user.stage_discount_amout);
    setInstallment2Stage(response.data.user.installment2stage);
    setInstallment3Stage(response.data.user.installment3stage);
    setInstallment4Stage(response.data.user.installment4stage);
    setLoading(false)
    
})
setLoading(true)
server.get(`/get_installments/${userid}`)
.then((response)=>{
   
    setInstallment1(response.data.user.installment1)
    setInstallment2(response.data.user.installment2)
    setInstallment3(response.data.user.installment3)
    setInstallment4(response.data.user.installment4)
    setInstallmentDiscount1(response.data.user.installmentdiscount1)
    
    setInstallmentDiscount2(response.data.user.installmentdiscount2)
    setInstallmentDiscount3(response.data.user.installmentdiscount3)
    setInstallmentDiscount4(response.data.user.installmentdiscount4)
    setInstallmentDiscount5(response.data.user.installmentdiscount5)
    setInstitutionalDiscount1(response.data.user.idiscount1)
    setInstitutionalDiscount2(response.data.user.idiscount2)
    setInstitutionalDiscount3(response.data.user.idiscount3)
    setInstitutionalDiscount4(response.data.user.idiscount4)
    setPayment1(response.data.user.payment1)
    setPayment2(response.data.user.payment2)
    setPayment3(response.data.user.payment3)
    setPaymentDate1(response.data.user.payment_date1)
    setPaymentDate2(response.data.user.payment_date2)
    setPaymentDate3(response.data.user.payment_date3)
    setPaymentDate4(response.data.user.payment_date4)
    setPaymentNoOfDays1(response.data.user.no_of_days1)
    setPaymentNoOfDays2(response.data.user.no_of_days2)
    setPaymentNoOfDays3(response.data.user.no_of_days3)
    setPaymentNoOfDays4(response.data.user.no_of_days4)
    setFinalTrigger1(response.data.user.final_trigger1)
    setFinalTrigger2(response.data.user.final_trigger2)
    setFinalTrigger3(response.data.user.final_trigger3)
    setFinalTrigger4(response.data.user.final_trigger4)
    setFinalTrigger5(response.data.user.final_trigger5)
    setFinalInstallment1(response.data.user.finalinstallment1)
    setFinalInstallment2(response.data.user.finalinstallment2)
    setFinalInstallment3(response.data.user.finalinstallment3)
    setFinalInstallment4(response.data.user.finalinstallment4)
    setFinalInstallment5(response.data.user.finalinstallment5)
    setInstallmentDate1(response.data.user.installment_date1)
    setInstallmentDate2(response.data.user.installment_date2)
    setInstallmentDate3(response.data.user.installment_date3)
    setInstallmentDate4(response.data.user.installment_date4)
    setInstallmentDate5(response.data.user.installment_date5)
    setFunctionalTime1(response.data.user.timefunction1)
    setFunctionalTime2(response.data.user.timefunction2)
    setFunctionalTime3(response.data.user.timefunction3)
    setFunctionalTime4(response.data.user.timefunction4)
  
    setInstallmentNoOfDays2(response.data.user.no_of_days2)
    setInstallmentNoOfDays3(response.data.user.no_of_days3)
    setInstallmentNoOfDays4(response.data.user.no_of_days4)
    setInstallmentReciveDate1(response.data.user.installment_recieve_date1)
    setInstallmentReciveDate2(response.data.user.installment_recieve_date2)
    setInstallmentReciveDate3(response.data.user.installment_recieve_date3)
    setInstallmentReciveDate4(response.data.user.installment_recieve_date4)

    setLoading(false)
    
})



}, []);


const getCurrentDate = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const year = today.getFullYear();

    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedDay = day < 10 ? `0${day}` : `${day}`;

    return `${year}-${formattedMonth}-${formattedDay}`;
  };

     
      const setProject=(id:number)=>
      {
          setProjectId(id);
          server.get(`/get_product/${id}`)
          .then((response)=>{
              
              setProducts(response.data.product)
             
              
              
          })
      }
      const setStage=(id:number)=>
      {
        
        
        setProductId(id);
        setStageId(0);
        setBoardKey(prevKey => prevKey + 1);
        setCount(1);
          server.get(`/get_stage/${id}`)
          .then((response)=>{
              
            setStages(response.data.stage);
              
          })
          server.get(`/product/${id}`)
          .then((response)=>{
              
            setProductPrice(response.data.product.product_price)
              
          })
      }

      const calNoofInstallments=(id:number)=>
      {
        setinstallmentTypeId(id);
        server.get(`/installment_type/${id}`)
        .then((response)=>{
            setNoOfInstallments(response.data.installments.no_of_installments);
            setInstallment1(response.data.installments.installment1);
            setInstallment2(response.data.installments.installment2);
            setInstallment3(response.data.installments.installment3);
            setInstallment4(response.data.installments.installment4);
     })
      }
    const installment_types = [];
//    const  setInstallmentDiscount=(i:number,value:number)=>{
   
//     if(i===1){
//         setInstallmentDiscount1(value)
//         setApplyDiscount1(!applydiscount1)
//     }else if(i===2){

//     setInstallmentDiscount2(value)
//     setApplyDiscount2(!applydiscount1)
//     }else if(i===3){

//         setInstallmentDiscount3(value)
//         setApplyDiscount3(!applydiscount1)
//     } else if(i===4){

//         setInstallmentDiscount4(value)
//         setApplyDiscount4(!applydiscount1)
//     }else if(i===5){

//         setInstallmentDiscount5(value)
//         setApplyDiscount1(!applydiscount1)
//     }else if(i===6){

//         setInstallmentDiscount6(value)
//           setApplyDiscount1(!applydiscount1)
//     } 
    
// }
// const applyDiscount=(i:number)=>{
//     if(i===1){
//         setApplyDiscount1(!applydiscount1)

//     }else if(i===2){

//     setApplyDiscount2(!applydiscount2)
//     }else if(i===3){

//         setApplyDiscount3(!applydiscount3)
//     } else if(i===4){

//         setApplyDiscount4(!applydiscount4)
//     }else if(i===5){

//         setApplyDiscount5(!applydiscount5)
//     }else if(i===6){

//         setApplyDiscount6(!applydiscount6)
//     } 
// } 


// const applyInstitutionalDiscount=(i:number)=>{
//     if(i===1){
//         setInstitutionalDiscount1()

//     }else if(i===2){

//     setInstitutionalDiscount2(!applydiscount2)
//     }else if(i===3){

//         setInstitutionalDiscount3(!applydiscount3)
//     } else if(i===4){

//         setInstitutionalDiscount4(!applydiscount4)
//     }else if(i===5){

//         setInstitutionalDiscount5(!applydiscount5)
//     }else if(i===6){

//         setInstitutionalDiscount6(!applydiscount6)
//     } 
// } 

let divide_payments=[];


for(let i=1;i<=payments;i++){
    
    
    divide_payments.push(<div className='mt-3'>
        <label>Recived Amount</label>
        <input type="number" min="0" className='form-control' name={"payment"+i} id={"payment"+i}  value={i===1?payment1:i===2?payment2:i===3?payment3:i===4?payment4:i===5?payment5:0} onChange={(e)=>i===1?setPayment1(parseInt(e.target.value)):i===2?setPayment2(parseInt(e.target.value)):i===3?setPayment3(parseInt(e.target.value)):i===4?setPayment4(parseInt(e.target.value)):i===5?setPayment5(parseInt(e.target.value)):""} placeholder={'Enter Payment'}  />
        <br/>
       {i!==1?
       <div><label>No of Days</label>
        <input type="number" min="0" className='form-control w-25' name={"payment_no_of_days"+i} id={"payment_no_of_days"+i} value={i===1?payment_no_of_days1:i===2?payment_no_of_days2:i===3?payment_no_of_days3:i===4?payment_no_of_days4:i===5?payment_no_of_days5:0} onChange={(e)=>i===1?setPaymentNoOfDays1(parseInt(e.target.value)):i===2?setPaymentNoOfDays2(parseInt(e.target.value)):i===3?setPaymentNoOfDays3(parseInt(e.target.value)):i===4?setPaymentNoOfDays4(parseInt(e.target.value)):i===5?setPaymentNoOfDays5(parseInt(e.target.value)):""} required />
        <br/>
        <select className='form-control w-75' name={"payment_trigger"+i} id={"payment_trigger"+i} value={i===1?paymenttrigger1:i===2?paymenttrigger2:i===3?paymenttrigger3:i===4?paymenttrigger4:i===5?paymenttrigger5:0} onChange={(e)=>i===1?setPaymentTrigger1(parseInt(e.target.value)):i===2?setPaymentTrigger2(parseInt(e.target.value)):i===3?setPaymentTrigger3(parseInt(e.target.value)):i===4?setPaymentTrigger4(parseInt(e.target.value)):i===5?setPaymentTrigger5(parseInt(e.target.value)):""}>
        <option value={0}>Select service group</option>
        {columns.map((column,index)=>(

            column.id===27||column.id===28||column.id===29?<option value={column.id}>{column.name}</option>:<option></option>
        ))}

        </select>
        <br/>
        <label>OR</label><br/><br/></div>:""}
        <label>Recevied Date</label>
        <input className='form-control' type="date" 
        // max={getCurrentDate()}
         name={"payment_date"+i} id={"payment_date"+i} value={i===1?payment_date1:i===2?payment_date2:i===3?payment_date3:i===4?payment_date4:i===5?payment_date5:0} onChange={(e)=>i===1?setPaymentDate1(e.target.value):i===2?setPaymentDate2(e.target.value):i===3?setPaymentDate3(e.target.value):i===4?setPaymentDate4(e.target.value):i===5?setPaymentDate5(e.target.value):""} required />
        
    </div>)
    
}


const addPayment= (i:number)=>{

    if(i===1){
        if(payment1===0||isNaN(payment1)===true||payment1===null){
            toast('please enter payment1')
            
        }
        else if(payment_date1===""||payment_date1===null||payment_date1===undefined){
           
            toast('please enter paymentDate1')
        } else if(payments<4){
            
            setPayments(payments+1)
     }else{
            toast("Only five payments allowed")
        }
        
    }else if(i===2){
        if(payment2===0||isNaN(payment2)===true||payment2===null){
            toast('please enter payment2')
        }else if((payment_date2===""||payment_date2===null)&&(payment_no_of_days2===0||payment_no_of_days2===null||isNaN(payment_no_of_days2)===true)){
            // if(payment_no_of_days2===0||payment_no_of_days2===null||isNaN(payment_no_of_days2)===true){

                toast('please enter paymentDate2 OR No Of days for payment date 2')
            // }
            
        }else if((payment_date2!==""||payment_date2!==null)&&(payment_no_of_days2!==0||payment_no_of_days2!==null||isNaN(payment_no_of_days2)===false)){
            toast('please choose either date or no of days for payment 2')
        }
        else if(payments<4){
            
            setPayments(payments+1)
     }else{
            toast("Only five payments allowed")
        }
    }
    else if(i===3){
        if(payment3===0 || isNaN(payment3)===true||payment3===null){
            toast('please enter payment3')
        }else if(payment_date3===""||payment_date3===null||payment_date3===undefined){
            toast('please enter paymentDate3')
        }else if(payments<4){
            setPayments(payments+1)
     }else{
            toast("Only five payments allowed")
        }
    }
    else if(i===4){
        if(payment4===0|| isNaN(payment4)===true||payment3===null){
            toast('please enter payment4')
        }else if(payment_date4===""||payment_date4===null||payment_date4===undefined){
            toast('please enter paymentDate3')
        }else if(payments<4){
            setPayments(payments+1)
     }else{
            toast("Only Four payments allowed")
        }
    }
   
}



    
for(let i=0;i<noofintallments;i++){
    if(i+1===1){
    installment_types.push(<div className='col-md-3'>
    <label>Installment-{i+1}</label>
    <input className='form-control'  type="text" id={"installment"+(i+1)}   name={"installment"+(i+1)} value={i+1===1?installment1:i+1===2 && installment2temp!==0?installment2temp:i+1===2?installment2:i+1===3 &&installment3temp!==0?installment3temp:i+1===3?installment3:i+1===4 && installment4temp!==0?installment4temp:i+1===4?installment4:installment4}  disabled />
        <input type="hidden" name={"installment"+(i+1)} value={i+1===1?installment1:i+1===2 && installment2temp!==0?installment2temp:i+1===2?installment2:i+1===3 &&installment3temp!==0?installment3temp:i+1===3?installment3:i+1===4 && installment4temp!==0?installment4temp:i+1===4?installment4:installment4} />
        <br />
        <label>Discount For Installment {(i+1)}</label>
         <input className='form-control' type="number" min="0" id={"installmentdiscount"+(i+1)} name={'installmentdiscount'+(i+1)} value={i+1===1?installmentdiscount1:i+1===2?installmentdiscount2:i+1===3?installmentdiscount3:i+1===4?installmentdiscount4:i+1===5?installmentdiscount5:0} onChange={(e)=>i+1===1?setInstallmentDiscount1(parseInt(e.target.value)):i+1===2?setInstallmentDiscount2(parseInt(e.target.value)):i+1===3?setInstallmentDiscount3(parseInt(e.target.value)):i+1===4?setInstallmentDiscount4(parseInt(e.target.value)):i+1===5?setInstallmentDiscount5(parseInt(e.target.value)):""} placeholder={'Enter Discount for installment'+(i+1)} required />

        <br/>
         {/* <label> Apply discount</label>
         <br/><input type='text' className='form-control' name={"applydiscount"+(i+1)} onChange={(e)=>applyDiscount(i+1)}  />
         <br/>
         <br/> */}

         <label>Institutional discount </label>
         <br/><input type='number' min="0" className='form-control' name={"idiscount"+(i+1)} value={i+1===1?institutionaldiscount1:i+1===2?institutionaldiscount2:i+1===3?institutionaldiscount3:i+1===4?institutionaldiscount4:i+1===5?institutionaldiscount5:0} onChange={(e)=>i+1===1?setInstitutionalDiscount1(parseInt(e.target.value)):i+1===2?setInstitutionalDiscount2(parseInt(e.target.value)):i+1===3?setInstitutionalDiscount3(parseInt(e.target.value)):i+1===4?setInstitutionalDiscount4(parseInt(e.target.value)):i+1===5?setInstitutionalDiscount5(parseInt(e.target.value)):""} placeholder={'Enter Discount for installment'+(i+1)} required   />
         <br/>
         <br/>
         
         <label className='my-3' >Final Price for Installment-1</label>                                                                  
         <input type="number" min="0" className='form-control' name={"finalinstallment"+(i+1)} value={installment1-installmentdiscount1-institutionaldiscount1}  disabled />
         <input type="hidden" min="0" className='form-control' name={"finalinstallment"+(i+1)} value={installment1-installmentdiscount1-institutionaldiscount1}   />
         <br/>
        
         {divide_payments}

         <br/>
         
            
        {payment1+installmentdiscount1+institutionaldiscount1<installment1?<div className="my-2">

            <label>Balance Payable</label>
            {/* <input type="number" min="0" className='form-control' name={"payment2"}  id={"payment2"} value={installment1-(payment1+installmentdiscount1+institutionaldiscount1)} onChange={(e)=>setPayment1(parseInt(e.target.value))} required  /> */}
            <input type="number"  className='form-control' name={"payment2"}  id={"payment2"} value={Math.abs(installment1-(payment1+installmentdiscount1+institutionaldiscount1))} onChange={(e)=>setPayment1(parseInt(e.target.value))} required  />
           <br/>
            <label>Expected date to receive pending amount</label>
            <input type="date"  className='form-control' min={getCurrentDate()} name={"payment_date2"} id={"payment_date2"} value={payment_date2} onChange={(e)=>setPaymentDate2(e.target.value)}  required />
        </div>:""}
        
         {/* <button type="button" className='btn btn-primary mt-2' onClick={(e)=>addPayment(payments)}>{payments===0?"Divide Installment":"Add payment"}</button> */}

    </div>)}else{
         installment_types.push(<div className='col-md-3'>
              <label>Installment {i+1}</label>
        <input className='form-control'  type="text" id={"installment"+(i+1)}   name={"installment"+(i+1)} value={i+1===1?installment1:i+1===2 && installment2temp!==0?installment2temp:i+1===2?installment2:i+1===3 &&installment3temp!==0?installment3temp:i+1===3?installment3:i+1===4 && installment4temp!==0?installment4temp:i+1===4?installment4:installment4}  disabled />
        <input type="hidden" name={"installment"+(i+1)} value={i+1===1?installment1:i+1===2 && installment2temp!==0?installment2temp:i+1===2?installment2:i+1===3 &&installment3temp!==0?installment3temp:i+1===3?installment3:i+1===4 && installment4temp!==0?installment4temp:i+1===4?installment4:installment4} />
        <br />
        <label>Discount For Installment {(i+1)}</label>
        <input className='form-control' type="number" min="0" id={"installmentdiscount"+(i+1)}  name={'installmentdiscount'+(i+1)} value={i+1===1?installmentdiscount1:i+1===2?installmentdiscount2:i+1===3?installmentdiscount3:i+1===4?installmentdiscount4:i+1===5?installmentdiscount5:0} onChange={(e)=>i+1===1?setInstallmentDiscount1(parseInt(e.target.value)):i+1===2?setInstallmentDiscount2(parseInt(e.target.value)):i+1===3?setInstallmentDiscount3(parseInt(e.target.value)):i+1===4?setInstallmentDiscount4(parseInt(e.target.value)):i+1===5?setInstallmentDiscount5(parseInt(e.target.value)):""}  placeholder={'Enter Discount for installment'+(i+1)} />
        <br/>
         {/* <label>Apply discount </label>
         <br/><input type='text' className='form-control'  onChange={(e)=>applyDiscount(i+1)}  />
         <br/>
         <br/> */}
         <label>Institutional Discount </label>
         <br/><input type='number' className='form-control' name={"idiscount"+(i+1)} value={i+1===1?institutionaldiscount1:i+1===2?institutionaldiscount2:i+1===3?institutionaldiscount3:i+1===4?institutionaldiscount4:i+1===5?institutionaldiscount5:0} onChange={(e)=>i+1===1?setInstitutionalDiscount1(parseInt(e.target.value)):i+1===2?setInstitutionalDiscount2(parseInt(e.target.value)):i+1===3?setInstitutionalDiscount3(parseInt(e.target.value)):i+1===4?setInstitutionalDiscount4(parseInt(e.target.value)):i+1===5?setInstitutionalDiscount5(parseInt(e.target.value)):""} placeholder={'Enter Discount for installment'+(i+1)}   />
         <br/>
         <br/>
         <label className='my-3'>Installment-{i+1}</label>
            <input type="number" min="0" className='form-control' name={"finalinstallment"+(i+1)}  value={i+1===2?installment2-installmentdiscount2-institutionaldiscount2:i+1===3?installment3-installmentdiscount3-institutionaldiscount3:i+1===4?installment4-installmentdiscount4-institutionaldiscount4:i+1===5?installment_date4:0}  disabled />
            <input type="hidden" className='form-control' name={"finalinstallment"+(i+1)}  value={i+1===2?installment2-installmentdiscount2-institutionaldiscount2:i+1===3?installment3-installmentdiscount3-institutionaldiscount3:i+1===4?installment4-installmentdiscount4-institutionaldiscount4:i+1===5?installment_date4:0}   />
   <br/>

         <label>No of Days</label>
        
     <input type="number" min="0" className='form-control w-25' name={"no_of_days"+(i+1)}value={i+1===1?installment_no_of_days1:i+1===2?installment_no_of_days2:i+1===3?installment_no_of_days3:i+1===4?installment_no_of_days4:i+1===5?installment_no_of_days5:0} onChange={(e)=>i+1===1?setInstallmentNoOfDays1(parseInt(e.target.value)):i+1===2?setInstallmentNoOfDays2(parseInt(e.target.value)):i+1===3?setInstallmentNoOfDays3(parseInt(e.target.value)):i+1===4?setInstallmentNoOfDays4(parseInt(e.target.value)):i+1===5?setInstallmentNoOfDays5(parseInt(e.target.value)):""}  />
        <br/>
        <div className='py-3'>
        <label className=''>Before/After</label>
        <select className='form-control' name={"timefunction"+(i+1)} value={i+1===1?timefunction1:i+1===2?timefunction2:i+1===3?timefunction3:i+1===4?timefunction4:i+1===5?timefunction5:0} onChange={(e)=>i+1===1?setFunctionalTime1(parseInt(e.target.value)):i+1===2?setFunctionalTime2(parseInt(e.target.value)):i+1===3?setFunctionalTime3(parseInt(e.target.value)):i+1===4?setFunctionalTime4(parseInt(e.target.value)):i+1===5?setFunctionalTime5(parseInt(e.target.value)):""}>
            <option value={0}>Select Before/After</option>
            <option value={1}>Before </option>
            <option value={2}>After</option>
        </select>
    </div>
         <select className='form-control w-75' name={"final_trigger"+(i+1)} id={"final_trigger"+(i+1) } value={i+1===1?final_trigger1:i+1===2?final_trigger2:i+1===3?final_trigger3:i+1===4?final_trigger4:i+1===5?final_trigger5:0} onChange={(e)=>i+1===1?setFinalTrigger1(parseInt(e.target.value)):i+1===2?setFinalTrigger2(parseInt(e.target.value)):i+1===3?setFinalTrigger3(parseInt(e.target.value)):i+1===4?setFinalTrigger4(parseInt(e.target.value)):i+1===5?setFinalTrigger5(parseInt(e.target.value)):""} required>
    
    <option value={0}>Select service group</option>
    {columns.map((column, index) => (
     (column.id === 27 || column.id === 28 || column.id === 29) && (
    <option key={index} value={column.id}>{column.name}</option>
     )
))}
    </select>
   
    <br/>
    <label>OR</label><br/><br/>

    <input className='form-control' min={getCurrentDate()}  type="date" name={"installment_date"+(i+1)} id={"installment_date"+(i+1)} value={i+1===1?installment_date1:i+1===2?installment_date2:i+1===3?installment_date3:i+1===4?installment_date4:i+1===5?installment_date5:0} onChange={(e)=>i+1===1?setInstallmentDate1(e.target.value):i+1===2?setInstallmentDate2(e.target.value):i+1===3?setInstallmentDate3(e.target.value):i+1===4?setInstallmentDate4(e.target.value):i+1===5?setInstallmentDate5(e.target.value):""}  />
    <br/>

    
        </div>)
    }
}

    //   for(let i=0;i<noofintallments;i++){
    //     installment_types.push(<div className='col-md-3'>
    //             <label>Installment{i+1}</label>
    //             <input className='form-control'  type="hidden" id={"installment"+(i+1)}   name={"installment"+(i+1)} value={i+1===1?installment1:i+1===2 && installment2temp!==0?installment2temp:i+1===2?installment2:i+1===3 &&installment3temp!==0?installment3temp:i+1===3?installment3:i+1===4 && installment4temp!==0?installment4temp:i+1===4?installment4:installment4}  />
    //             <div><select className='form-control' name={"installment"+(i+1)+"stage"} id={"installment"+(i+1)+"stage"}  onChange={(e)=>i+1===2?setInstallment2Stage(parseInt(e.target.value)):i+1===3?setInstallment3Stage(parseInt(e.target.value)):i+1===4?setInstallment4Stage(parseInt(e.target.value)):0} required>
    //                      <option value={0}>Select the stage</option>
    //                     {stages.map((stage,index)=>(
    //                         <option value={stage.id}>{stage.stage_name}</option>))}
    //         </select><br/>
    //         <label>{"Installment"+(i+1)} Final Date </label>
    //              <input className='form-control' name={"collectiondata"+(i+1)} id={"collectiondata"+(i+1)} type='date' />
    //         <br />
    //         <label>Discount Given</label>
    //              <input type="number" min="0" className='form-control' name={"installmentdiscount"+(i+1)}  id={"installmentdiscount"+(i+1)} placeholder={"Installment Discount"+(i+1)}  />
    //              <br/>
    //              <label>Installment{i+1} Final Price</label>
    //              <input type="number" min="0" className='form-control' name={"installmentdiscount"+(i+1)}  id={"installmentdiscount"+(i+1)} placeholder={"Installment Discount"+(i+1)}  />
    //         </div>

            
    //     </div> )       
    //   }




    //   for(let i=0;i<noofintallments;i++)
    //   {
        
    //     installment_types.push(<div className='col-md-3'>
    //         <label>Installment{i+1}</label>
    //         <input className='form-control' type="number" min="0" id={"installmentdisabled"+(i+1)} value={i+1===1?installment1:i+1===2 && installment2temp!==0?installment2temp:i+1===2?installment2:i+1===3 &&installment3temp!==0?installment3temp:i+1===3?installment3:i+1===4 && installment4temp!==0?installment4temp:i+1===4?installment4:installment4}  name={"installmentdisabled"+(i+1)} disabled />
    //         <input className='form-control'  type="hidden" id={"installment"+(i+1)}   name={"installment"+(i+1)} value={i+1===1?installment1:i+1===2 && installment2temp!==0?installment2temp:i+1===2?installment2:i+1===3 &&installment3temp!==0?installment3temp:i+1===3?installment3:i+1===4 && installment4temp!==0?installment4temp:i+1===4?installment4:installment4}  />
    //         <br/>
            
    //         {i+1>1?<div><select className='form-control' name={"installment"+(i+1)+"stage"} id={"installment"+(i+1)+"stage"}  onChange={(e)=>i+1===2?setInstallment2Stage(parseInt(e.target.value)):i+1===3?setInstallment3Stage(parseInt(e.target.value)):i+1===4?setInstallment4Stage(parseInt(e.target.value)):0} required>
    //                     <option value={0}>Select the stage</option>
    //                     {stages.map((stage,index)=>(
    //                         <option value={stage.id}>{stage.stage_name}</option>))}
    //         </select><br/><input className='form-control' name={"collectiondata"+(i+1)} id={"collectiondata"+(i+1)} type='number' placeholder={'Number of days for installment'+(i+1)}/></div>:""}
            
    //     </div>)
    //   }



     
     
      let all_installments=[];

      for(let i=1;i<=noofintallments;i++)
      {
        
        all_installments.push( <option  value={i}>{"installment"+i}</option>)
      }
      
    //   const checkInstallmentEmpty = (installment_no_of_days:number, timefunction:number) => {
    //     return !((installment_no_of_days === 0 || isNaN(installment_no_of_days) || installment_no_of_days) &&
    //         (timefunction === 0 || timefunction === null));
    // };

    const installmentCheck = (installment_no_of_days:number, timefunction:number, final_trigger:number, installment_date:string, installmentNumber:number) => {
        if ((installment_date===""||installment_date===null) && (installment_no_of_days === 0 || isNaN(installment_no_of_days))) {
         toast(`Enter The number of days for Installment ${installmentNumber}`);
         return false;
     } else if (installment_no_of_days !== 0 && !isNaN(installment_no_of_days) && (timefunction === 0 || timefunction === null)) {
         toast(`Select the Time Function for Installment ${installmentNumber}`);
         return false;
     } else if (installment_no_of_days !== 0 && !isNaN(installment_no_of_days) && (final_trigger === 0 || final_trigger === null)) {
         toast(`Select the Action for Installment ${installmentNumber}`);
         return false;
     } else if ((installment_no_of_days === 0 || isNaN(installment_no_of_days)) && (final_trigger === 0 || final_trigger === null) && (installment_date === "" || installment_date === null)) {
         toast(`Please Select the Installment Date ${installmentNumber}`);
         return false;
     } else {
         return true;
     }
 };
 
 const checkInstallmentEmpty = (installment_no_of_days:number, timefunction:number) => {
     return !((installment_no_of_days === 0 || isNaN(installment_no_of_days) || installment_no_of_days) &&
         (timefunction === 0 || timefunction === null));
 };
 
     const addUserData=async (e: React.FormEvent<HTMLFormElement>)=>
     {
        e.preventDefault();
       
        var installment_type=document.getElementById('installment_type') as HTMLInputElement;
        var discounted_installement_number=document.getElementById('discounted_installement_number') as HTMLInputElement;
        let form= document.getElementById('updateuserdataform') as HTMLFormElement;
        const formData = new FormData(form);
        formData.append('stagelength',stages.length.toString())
        for(var i=0;i<stages.length;i++){
            var x=document.getElementsByClassName(stages[i].stage_name) as HTMLCollectionOf<HTMLInputElement>;
            let y=x.length.toString();
             formData.append(stages[i].stage_name+"length",y)
             formData.append("stage"+(i+1),stages[i].stage_name)
        }

    var excluded = document.getElementsByClassName('Excluded') as HTMLCollectionOf<HTMLInputElement>;
        formData.append('excluded_length',excluded.length.toString())    
    // const certificate_name = document.getElementsByClassName('certificate_name') as HTMLCollectionOf<HTMLInputElement>;
    
        if(project_id===0)
        {
            toast('Select project');
        }else if(product_id===0){
            toast('Select product');
        }else if(stage_id===0){
            toast('Select stage ');
        }else if(installment_type.value==="0")
        {
            toast('Select payment breakup ');
         }
        else if(payment1===0){
            toast('Enter the Amount Recived');
         }else if(payment2<0){
            toast('Enter the recived amount not exceeding the final price For Installment');
         }else if(counsellor_id===0){
            toast('Select The Counsellor');
        }
         else if(account_status===0){
            toast('Select Account Status')
         }
        
         else{
            
            for (let i = 2; i <= noofintallments; i++) {
                const installment_no_of_days = eval(`installment_no_of_days${i}`);
                const timefunction = eval(`timefunction${i}`);
                const final_trigger = eval(`final_trigger${i}`);
                const installment_date = eval(`installment_date${i}`);
    
                if (noofintallments >= i && !installmentCheck(installment_no_of_days, timefunction, final_trigger, installment_date, i)) {
                    console.log(`installment${i} Error`);
                    return;
                }
                // } else if (noofintallments >= i && validator.isEmpty(eval(`installment_date${i}`)) === false && checkInstallmentEmpty(installment_no_of_days, timefunction)) {
                //     toast(`Please Choose Either Action No Of days And Of Installment Date ${i} for Installment ${i}`);
                //     return;
                // }
                else if (noofintallments >= i && eval(`installment_date${i}`)!==null  && checkInstallmentEmpty(installment_no_of_days, timefunction)) {
                    toast(`Please Choose Either Action No Of days And Of Installment Date ${i} for Installment ${i}`);
                    return;
                }
            }
             setLoading(true)
          
            server.post(`/operationuser/${userid}`,formData,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                }})
                .then((response)=>{
                    if(response.data.code===200)
                    {
                     setLoading(false)
                        toast(response.data.message);
                        setTemp(1)
                        if(temp===1){
                         setTimeout(function () { window.location.href = "/users"; }, 1000);
                        }
                        //  setTimeout(function () { window.location.href = "/users"; }, 1000);
                    }else if(response.data.code===204){
                        setLoading(false)
                        toast(response.data.message);
                    }
                })
     } 
    }


   
     const setinstalldiscount= async (id:number)=>{
        setInstallment2Temp(0)
        setInstallment3Temp(0)
        setInstallment4Temp(0)
        var stage_discount_amout_value=document.getElementById("stage_discount_amout") as HTMLInputElement;
        var stage_discount_amout=parseInt(stage_discount_amout_value.value);
        
        if(stage_discount_amout!==0 ||stage_discount_amout!==undefined && discounted_amount!==0)
        {
            calNoofInstallments(instalmenttypeid)
            setProductAmount(product_price-(stage_discount_amout+discounted_amount))
            if(id===2)
            {   
                // var installment2 =document.getElementById("region") as HTMLInputElement;
                         
                 setInstallment2Temp(installment2-(stage_discount_amout+discounted_amount))
                 
            }else if(id===3)
            {
                setInstallment3Temp(installment3-(stage_discount_amout+discounted_amount))
            }else if(id===4)
            {
                setInstallment4Temp(installment4-(stage_discount_amout+discounted_amount))
            }
        }else
        {
            toast("discount amount and stage discount cant be empty");
        }
     }



     const setinstallmentDiscounted =(id:number)=>{
       
        setInstallment2Temp(0)
        setInstallment3Temp(0)
        setInstallment4Temp(0)
        var stage_discount_amout_value=document.getElementById("stage_discount_amout") as HTMLInputElement;
        var stage_discount_amout=parseInt(stage_discount_amout_value.value);
        

        calNoofInstallments(instalmenttypeid)
        setProductAmount(product_price-(stage_discount_amout+discounted_amount))
        if(discounted_installment_number===2)
        {   
            // var installment2 =document.getElementById("region") as HTMLInputElement;
                     
             setInstallment2Temp(installment2-(stage_discount_amout+discounted_amount))
             
        }else if(discounted_installment_number===3)
        {
            setInstallment3Temp(installment3-(stage_discount_amout+discounted_amount))
        }else if(discounted_installment_number===4)
        {
            setInstallment4Temp(installment4-(stage_discount_amout+discounted_amount))
        }

        
     }

    
     if (loading) {
        return <Loader />;
      } 
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}

    <h4 className='text-danger text-center'>Edit user by Counsellor</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>
   <h5 className='text-danger text-center'>Client Details</h5>
   
    <div className='col-md-12 px-5'>
        <form id="updateuserdataform" onSubmit={addUserData} encType='multipart/form-data' className='w-100'>
        <input type="hidden" name="admin_id" value={admin_id}/>
         <div className='row'>
            <div className='col-md-3'>
            <label>First Name</label>
            <input type="text" className='form-control' name='first_name' id="first_name" value={first_name}  onChange={(e)=>setfirstName(e.target.value)}  placeholder='Enter the first name' required/> 
            </div>   
            <div className='col-md-3'>
            <label>Last Name</label>
            <input type="text" className='form-control' name='last_name' id="last_name" value={last_name} onChange={(e)=>setlastName(e.target.value)} placeholder='Enter the Last name' required /> 
            
            </div>   
            {/* <div className='col-md-1'>
            <label> Primary Country code </label>
            
            </div>    */}
            <div className='col-md-5'>
            <label> Primary Contact Number </label>
            {/* <input type="hidden" name="payments" id="payments" value={payments} /> */}
           <div className='row'>
            <div className='col-md-3'>
         <select  className='form-control' name="primary_contact_country_code" id="primary_contact_country_code" value={primary_country_code} onChange={(e)=>setPrimaryCountryCode(parseInt(e.target.value))} required >
            <option value="213">+213</option>
            <option value="376">+376</option>
            <option value="244">+244</option>
            <option value="1264">+1264</option>
            <option value="1268">+1268</option>
            <option value="54">+54</option>
            <option value="374">+374</option>
            <option value="297">+297</option>
            <option value="61">+61</option>
            <option value="43">+43</option>
            <option value="994">+994</option>
            <option value="1242">+1242</option>
            <option value="973">+973</option>
            <option value="880">+880</option>
            <option value="1246">+1246</option>
            <option value="375">+375</option>
            <option value="32">+32</option>
            <option value="501">+501</option>
            <option value="229">+229</option>
            <option value="1441">+1441</option>
            <option value="975">+975</option>
            <option value="591">+591</option>
            <option value="387">+387</option>
            <option value="267">+267</option>
            <option value="55">+55</option>
            <option value="673">+673</option>
            <option value="359">+359</option>
            <option value="226">+226</option>
            <option value="257">+257</option>
            <option value="855">+855</option>
            <option value="237">+237</option>
            <option value="1">+1</option>
            <option value="238">+238</option>
            <option value="1345">+1345</option>
            <option value="236">+236</option>
            <option value="56">+56</option>
            <option value="86">+86</option>
            <option value="57">+57</option>
            <option value="269">+269</option>
            <option value="242">+242</option>
            <option value="682">+682</option>
            <option value="506">+506</option>
            <option value="385">+385</option>
            <option value="53">+53</option>
            <option value="90392">+90392</option>
            <option value="357">+357</option>
            <option value="42">+42</option>
            <option value="45">+45</option>
            <option value="253">+253</option>
            <option value="1809">+1809</option>
            <option value="1809">+1809</option>
            <option value="593">+593</option>
            <option value="20">+20</option>
            <option value="503">+503</option>
            <option value="240">+240</option>
            <option value="291">+291</option>
            <option value="372">+372</option>
            <option value="251">+251</option>
            <option value="500">+500</option>
            <option value="298">+298</option>
            <option value="679">+679</option>
            <option value="358">+358</option>
            <option value="33">+33</option>
            <option value="594">+594</option>
            <option value="689">+689</option>
            <option value="241">+241</option>
            <option value="220">+220</option>
            <option value="7880">+7880</option>
            <option value="49">+49</option>
            <option value="233">+233</option>
            <option value="350">+350</option>
            <option value="30">+30</option>
            <option value="299">+299</option>
            <option value="1473">+1473</option>
            <option value="590">+590</option>
            <option value="671">+671</option>
            <option value="502">+502</option>
            <option value="224">+224</option>
            <option value="245">+245</option>
            <option value="592">+592</option>
            <option value="509">+509</option>
            <option value="504">+504</option>
            <option value="852">+852</option>
            <option value="36">+36</option>
            <option value="354">+354</option>
            <option value="91">+91</option>
            <option value="62">+62</option>
            <option value="98">+98</option>
            <option value="964">+964</option>
            <option value="353">+353</option>
            <option value="972">+972</option>
            <option value="39">+39</option>
            <option value="1876">+1876</option>
            <option value="81">+81</option>
            <option value="962">+962</option>
            <option value="7">+7</option>
            <option value="254">+254</option>
            <option value="686">+686</option>
            <option value="850">+850</option>
            <option value="82">+82</option>
            <option value="965">+965</option>
            <option  value="996">+996</option>
            <option  value="856">+856</option>
            <option  value="371">+371</option>
            <option  value="961">+961</option>
            <option  value="266">+266</option>
            <option  value="231">+231</option>
            <option  value="218">+218</option>
            <option  value="417">+417</option>
            <option  value="370">+370</option>
            <option  value="352">+352</option>
            <option  value="853">+853</option>
            <option  value="389">+389</option>
            <option  value="261">+261</option>
            <option  value="265">+265</option>
            <option  value="60">+60</option>
            <option  value="960">+960</option>
            <option  value="223">+223</option>
            <option  value="356">+356</option>
            <option  value="692">+692</option>
            <option  value="596">+596</option>
            <option  value="222">+222</option>
            <option  value="269">+269</option>
            <option  value="52">+52</option>
            <option  value="691">+691</option>
            <option  value="373">+373</option>
            <option  value="377">+377</option>
            <option  value="976">+976</option>
            <option  value="1664">+1664</option>
            <option  value="212">+212</option>
            <option  value="258">+258</option>
            <option  value="95">+95</option>
            <option  value="264">+264</option>
            <option  value="674">+674</option>
            <option  value="977">+977</option>
            <option  value="31">+31</option>
            <option  value="687">+687</option>
            <option  value="64">+64</option>
            <option  value="505">+505</option>
            <option  value="227">+227</option>
            <option  value="234">+234</option>
            <option  value="683">+683</option>
            <option  value="672">+672</option>
            <option  value="670">+670</option>
            <option  value="47">+47</option>
            <option  value="968">+968</option>
            <option  value="680">+680</option>
            <option  value="507">+507</option>
            <option  value="675">+675</option>
            <option  value="595">+595</option>
            <option  value="51">+51</option>
            <option  value="63">+63</option>
            <option  value="48">+48</option>
            <option  value="351">+351</option>
            <option  value="1787">+1787</option>
            <option  value="974">+974</option>
            <option  value="262">+262</option>
            <option  value="40">+40</option>
            <option  value="7">+7</option>
            <option  value="250">+250</option>
            <option  value="378">+378</option>
            <option  value="239">+239</option>
            <option  value="966">+966</option>
            <option  value="221">+221</option>
            <option  value="381">+381</option>
            <option  value="248">+248</option>
            <option  value="232">+232</option>
            <option  value="65">+65</option>
            <option  value="421">+421</option>
            <option  value="386">+386</option>
            <option  value="677">+677</option>
            <option  value="252">+252</option>
            <option  value="27">+27</option>
            <option  value="34">+34</option>
            <option  value="94">+94</option>
            <option  value="290">+290</option>
            <option  value="1869">+1869</option>
            <option  value="1758">+1758</option>
            <option  value="249">+249</option>
            <option  value="597">+597</option>
            <option  value="268">+268</option>
            <option  value="46">+46</option>
            <option  value="41">+41</option>
            <option  value="963">+963</option>
            <option  value="886">+886</option>
            <option  value="7">+7</option>
            <option  value="66">+66</option>
            <option  value="228">+228</option>
            <option  value="676">+676</option>
            <option  value="1868">+1868</option>
            <option  value="216">+216</option>
            <option  value="90">+90</option>
            <option  value="7">+7</option>
            <option  value="993">+993</option>
            <option  value="1649">+1649</option>
            <option  value="688">+688</option>
            <option  value="256">+256</option>
            <option  value="44">+44</option>
            <option  value="380">+380</option>
            <option  value="971">+971</option>
            <option  value="598">+598</option>
            <option  value="1">+1</option>
            <option  value="7">+7</option>
            <option  value="678">+678</option>
            <option  value="379">+379</option>
            <option  value="58">+58</option>
            <option  value="84">+84</option>
            <option  value="84">+1284</option>
            <option  value="84">+1340</option>
            <option  value="681">+681</option>
            <option  value="969">+969</option>
            <option  value="967">+967</option>
            <option  value="260">+260</option>
            <option  value="263">+263</option>    
            </select>
            </div>
            <div className='col-md-9'>
            <input type="number" min="0" className='form-control' name="primary_contact" id="primary_contact" value={primary_contact} placeholder='Primary contact number' onChange={(e)=>setPrimaryContact(parseInt(e.target.value))} required /> 
            </div>
            </div>
            </div>

         
         </div>  
         <div className='row mt-4'>
             
            <div className='col-md-5'>
            <label> Alternate Contact Number </label>
            <div className='row'>
             <div className='col-md-3'>
             <select  className='form-control' name="alernate_country_code" id="alernate_country_code" value={alernate_country_code} onChange={(e)=>setPrimaryCountryCode(parseInt(e.target.value))} required >
             <option value="213">+213</option>
            <option value="376">+376</option>
            <option value="244">+244</option>
            <option value="1264">+1264</option>
            <option value="1268">+1268</option>
            <option value="54">+54</option>
            <option value="374">+374</option>
            <option value="297">+297</option>
            <option value="61">+61</option>
            <option value="43">+43</option>
            <option value="994">+994</option>
            <option value="1242">+1242</option>
            <option value="973">+973</option>
            <option value="880">+880</option>
            <option value="1246">+1246</option>
            <option value="375">+375</option>
            <option value="32">+32</option>
            <option value="501">+501</option>
            <option value="229">+229</option>
            <option value="1441">+1441</option>
            <option value="975">+975</option>
            <option value="591">+591</option>
            <option value="387">+387</option>
            <option value="267">+267</option>
            <option value="55">+55</option>
            <option value="673">+673</option>
            <option value="359">+359</option>
            <option value="226">+226</option>
            <option value="257">+257</option>
            <option value="855">+855</option>
            <option value="237">+237</option>
            <option value="1">+1</option>
            <option value="238">+238</option>
            <option value="1345">+1345</option>
            <option value="236">+236</option>
            <option value="56">+56</option>
            <option value="86">+86</option>
            <option value="57">+57</option>
            <option value="269">+269</option>
            <option value="242">+242</option>
            <option value="682">+682</option>
            <option value="506">+506</option>
            <option value="385">+385</option>
            <option value="53">+53</option>
            <option value="90392">+90392</option>
            <option value="357">+357</option>
            <option value="42">+42</option>
            <option value="45">+45</option>
            <option value="253">+253</option>
            <option value="1809">+1809</option>
            <option value="1809">+1809</option>
            <option value="593">+593</option>
            <option value="20">+20</option>
            <option value="503">+503</option>
            <option value="240">+240</option>
            <option value="291">+291</option>
            <option value="372">+372</option>
            <option value="251">+251</option>
            <option value="500">+500</option>
            <option value="298">+298</option>
            <option value="679">+679</option>
            <option value="358">+358</option>
            <option value="33">+33</option>
            <option value="594">+594</option>
            <option value="689">+689</option>
            <option value="241">+241</option>
            <option value="220">+220</option>
            <option value="7880">+7880</option>
            <option value="49">+49</option>
            <option value="233">+233</option>
            <option value="350">+350</option>
            <option value="30">+30</option>
            <option value="299">+299</option>
            <option value="1473">+1473</option>
            <option value="590">+590</option>
            <option value="671">+671</option>
            <option value="502">+502</option>
            <option value="224">+224</option>
            <option value="245">+245</option>
            <option value="592">+592</option>
            <option value="509">+509</option>
            <option value="504">+504</option>
            <option value="852">+852</option>
            <option value="36">+36</option>
            <option value="354">+354</option>
            <option value="91">+91</option>
            <option value="62">+62</option>
            <option value="98">+98</option>
            <option value="964">+964</option>
            <option value="353">+353</option>
            <option value="972">+972</option>
            <option value="39">+39</option>
            <option value="1876">+1876</option>
            <option value="81">+81</option>
            <option value="962">+962</option>
            <option value="7">+7</option>
            <option value="254">+254</option>
            <option value="686">+686</option>
            <option value="850">+850</option>
            <option value="82">+82</option>
            <option value="965">+965</option>
            <option  value="996">+996</option>
            <option  value="856">+856</option>
            <option  value="371">+371</option>
            <option  value="961">+961</option>
            <option  value="266">+266</option>
            <option  value="231">+231</option>
            <option  value="218">+218</option>
            <option  value="417">+417</option>
            <option  value="370">+370</option>
            <option  value="352">+352</option>
            <option  value="853">+853</option>
            <option  value="389">+389</option>
            <option  value="261">+261</option>
            <option  value="265">+265</option>
            <option  value="60">+60</option>
            <option  value="960">+960</option>
            <option  value="223">+223</option>
            <option  value="356">+356</option>
            <option  value="692">+692</option>
            <option  value="596">+596</option>
            <option  value="222">+222</option>
            <option  value="269">+269</option>
            <option  value="52">+52</option>
            <option  value="691">+691</option>
            <option  value="373">+373</option>
            <option  value="377">+377</option>
            <option  value="976">+976</option>
            <option  value="1664">+1664</option>
            <option  value="212">+212</option>
            <option  value="258">+258</option>
            <option  value="95">+95</option>
            <option  value="264">+264</option>
            <option  value="674">+674</option>
            <option  value="977">+977</option>
            <option  value="31">+31</option>
            <option  value="687">+687</option>
            <option  value="64">+64</option>
            <option  value="505">+505</option>
            <option  value="227">+227</option>
            <option  value="234">+234</option>
            <option  value="683">+683</option>
            <option  value="672">+672</option>
            <option  value="670">+670</option>
            <option  value="47">+47</option>
            <option  value="968">+968</option>
            <option  value="680">+680</option>
            <option  value="507">+507</option>
            <option  value="675">+675</option>
            <option  value="595">+595</option>
            <option  value="51">+51</option>
            <option  value="63">+63</option>
            <option  value="48">+48</option>
            <option  value="351">+351</option>
            <option  value="1787">+1787</option>
            <option  value="974">+974</option>
            <option  value="262">+262</option>
            <option  value="40">+40</option>
            <option  value="7">+7</option>
            <option  value="250">+250</option>
            <option  value="378">+378</option>
            <option  value="239">+239</option>
            <option  value="966">+966</option>
            <option  value="221">+221</option>
            <option  value="381">+381</option>
            <option  value="248">+248</option>
            <option  value="232">+232</option>
            <option  value="65">+65</option>
            <option  value="421">+421</option>
            <option  value="386">+386</option>
            <option  value="677">+677</option>
            <option  value="252">+252</option>
            <option  value="27">+27</option>
            <option  value="34">+34</option>
            <option  value="94">+94</option>
            <option  value="290">+290</option>
            <option  value="1869">+1869</option>
            <option  value="1758">+1758</option>
            <option  value="249">+249</option>
            <option  value="597">+597</option>
            <option  value="268">+268</option>
            <option  value="46">+46</option>
            <option  value="41">+41</option>
            <option  value="963">+963</option>
            <option  value="886">+886</option>
            <option  value="7">+7</option>
            <option  value="66">+66</option>
            <option  value="228">+228</option>
            <option  value="676">+676</option>
            <option  value="1868">+1868</option>
            <option  value="216">+216</option>
            <option  value="90">+90</option>
            <option  value="7">+7</option>
            <option  value="993">+993</option>
            <option  value="1649">+1649</option>
            <option  value="688">+688</option>
            <option  value="256">+256</option>
            <option  value="44">+44</option>
            <option  value="380">+380</option>
            <option  value="971">+971</option>
            <option  value="598">+598</option>
            <option  value="1">+1</option>
            <option  value="7">+7</option>
            <option  value="678">+678</option>
            <option  value="379">+379</option>
            <option  value="58">+58</option>
            <option  value="84">+84</option>
            <option  value="84">+1284</option>
            <option  value="84">+1340</option>
            <option  value="681">+681</option>
            <option  value="969">+969</option>
            <option  value="967">+967</option>
            <option  value="260">+260</option>
            <option  value="263">+263</option>     
            </select>
             </div>   
            <div className='col-md-9'>
            <input type="text" className='form-control' name="alternate_phone_number" id="alternate_phone_number" value={alternatecontact} placeholder='Alternate contact number' onChange={(e)=>setAlternateContact(parseInt(e.target.value))}  />
            </div>
            </div>
            </div>
            <div className='col-md-4'>
            <label> Email</label>
            <input type="email" className='form-control' name="user_email" id="user_email" value={user_email} placeholder='Email Id' onChange={(e)=>setEmail(e.target.value)} required />
            </div>
         </div> 
        
          
         <h5 className='text-danger text-center my-5'>Product Details</h5>
            <div className='row'>
                {/* <div className='col-md-2'>
                <label> Program Selected</label>
                <select className='form-control' name='project_id' id="project_id" value={project_id} onChange={(e)=>setProject(parseInt(e.target.value))} required>
             
                        <option value={0}>Select Project</option>
                        {projects.map((project)=>(
                            <option value={project.id}>{project.project_name}</option>))}
                    </select>
            
                </div> */}
                <input type="hidden" name='project_id' id="project_id" value={project_id} />
               <div className='col-md-2'>
            <label >Product</label>
                     <select className='form-control' name='product_id' id="product_id" value={product_id} onChange={(e)=>setStage(parseInt(e.target.value))} required>
                        <option value={0}>Select Product</option>
                        {products.map((product)=>(
                            <option value={product.id}>{product.product_name}</option>))}
            </select>
            </div>
                <div className='col-md-2'>
                    <label >Joining Stage</label>
                    <select className='form-control' name='stage_id' id="stage_id" value={stage_id} onChange={(e)=>setStageId(parseInt(e.target.value))} required>
                        <option value={0}>Select the stage</option>
                        {stages.map((stage,index)=>(
                            <option value={stage.id}>{stage.stage_name}</option>))}
                    </select>
                </div>
                <div className='col-md-2'>
                    <label>Date of registration</label>
                   <input type="date" className='form-control' name="date_of_registration" id="date_of_registration" value={date_of_registration} onChange={(e)=>setDateOfRegistration(e.target.value)} placeholder='Enter the Date of Registration' required />
                </div>
                
               
                
            </div>
            <h5 className='text-danger text-center my-5'>Services Offered</h5>  
           
           {product_id!==0?<div className='row py-3 bg-info'>{count===0?<EditBoard project_id={projectid} product_id={productid} user_id={user_id} />:<Board key={boardKey} project_id={project_id} product_id={product_id}  />}</div>:<div></div>}
               {/* <div className='row mt-3'>  
               <h5 className='text-danger text-center my-5'>Services Offered</h5>  
                 <input type="hidden" name="services_length" value={services.length} />  
                 <input type='hidden' name="stages_length" value={stages.length} />
                 
                {stages.map((outerstage,stageindex)=>( 
                    <div>
                        <h2>Services For {outerstage.stage_name}</h2>  
                        <input type='hidden' name={"stage"+(stageindex+1)} value={outerstage.id} />
                   <div className='row'>         
                 {stages.map((stage,stageindex)=>(
                         
                         <div className='col-md-3'>
                           <h5 className='text-danger'>{stage.stage_name}</h5>
                                        {services.map((service,index)=>(
                service.stage_id===stage.id?<div className='col-md-12'>
                    
                 <span>{service.service_name}</span>
                 <input  className='check' type="checkbox" name={outerstage.stage_name+"_"+"service"+(index+1)} id={outerstage.stage_name+"service"+(index+1)} value={service.id}  defaultChecked />
                </div>:""
      ))}
                    
                    </div>
                           ))}
                   </div> 
                   </div>
                   ))}       

      </div> */}


            <h5 className='text-danger text-center my-5'>Payment Details</h5>
            <div className='row'>
                <div className='col-md-4'>

                   
                </div>
                
            </div>
            <div className='row mt-5'>
                <div className='col-md-3'>
                    <label>Payment Break-up</label>
                    <select name="installment_type" className='form-control' id="installment_type" value={instalmenttypeid} onChange={(e)=>calNoofInstallments(parseInt(e.target.value))} required >
                        <option value="0">Select the Installment Type</option>
                        {installtypes.map((installtype)=>(
                            <option value={installtype.id}>{installtype.installment_type_name}</option>
                        ))}
                    </select>
                    <input type="hidden" name="noofintallments" value={noofintallments} />
                </div>
              
            </div>
            <div className='row pt-5'>
            {installment_types}
                        </div>
                        <hr />
            <h5>Account Status</h5>
            <div className='row'>
            <div className='col-md-4'>
            <select id="account_status" className='form-control w-100' name="account_status" value={account_status} onChange={(e)=>setAccountStatus(parseInt(e.target.value))}  >
                <option value={0}>Select Account Status</option>
                <option value={1}>In Progress</option>
                <option value={2}>On Hold</option>
                <option value={3}>Switched Program</option>
                <option value={4}>Backed Out</option>
                <option value={5}>Refunded</option>
                <option value={6}>Completed</option>
            </select>
            </div>
            <div className='col-md-4'>
                <select id="counsellor_id" className='form-control w-100' name="counsellor_id" value={counsellor_id} onChange={(e)=>setCounsellorId(parseInt(e.target.value))}>
                    <option value={0}>Select Counsellor</option>
                    {counsellors && counsellors.map((counsellor, index) => (
                    <option key={index} value={counsellor.id}>
                        {counsellor.first_name} {counsellor.last_name}
                    </option>
))}

                </select>
            </div>
            </div>
            <hr />
            <h5 className='text-danger text-center mt-5'>Contract Upload</h5>
            <div className='row pt-5'>
               
                <div className='col-md-5'>
                    <input type="file" className='form-control' name='contract_file' id="contract_file"  />
                   {/* <FilePond
                      files={contract_file}
                      onupdatefiles={setContractFile}
                      allowMultiple={false}
                      name="contract_file"
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    /> */}
                    </div>
               
            </div>
            <div className='row mt-5'>
                <label>Note</label>
                <textarea className='form-control w-100' name="counsellor_note" value={counsellor_note} onChange={(e)=>setCounsellorNote(e.target.value)} required></textarea>
            </div>

            <ToastContainer />
            <div>{error}</div>
           <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Update User</button></div>
        </form>
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default EditUser;