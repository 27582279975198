import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import { ServiceData } from '../../@types/servicetype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import {Link,useParams} from 'react-router-dom';
import server from '../Utils';
import validator from 'validator';
import { CollegeData } from '../../@types/collegedata';
import Loader from './Loader';


const UserlanguageTestEdiT = () => {
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [loading, setLoading] = useState(false);

    const [project_id,setProjectId]=useState<number>(0);
    const [product_id,setProductId]=useState<number>(0);
    const [service_category_id,setServiceCategoryId]=useState<number>(0);
    const [service_categories,setServiceCategories]=useState<Array<ServiceCategoryType>>([])
    const [services,setServices]=useState<Array<ServiceData>>([])
    const [service_id,setServiceId]=useState<number>(0)
    const {id}=useParams();
    const [language_test_taken,setLanguageTestTaken]=useState<number>(0);
    const [language_test_type,setLanguageTestType]=useState<number>(0);
  const [language_test_date,setLanguageTestDate]=useState<string>("");
  const [ielts_trf_number,setIELTStrfNo]=useState<string>("");
  const [ielts_listening_score,setIELTSListeningScore]=useState<string>('');
  const [ielts_reading_score,setIELTSReadingScore]=useState<string>('');
  const [ielts_writing_score,setIELTSWritingScore]=useState<string>('');
  const [ielts_speaking_score,setIELTSSpeakingScore]=useState<string>('');
  const [ielts_overall_score,setIELTSOverallScore]=useState<string>('');
  const [oet_candidate_number,setOetCandidateNumber]=useState<string>('');
  const [oet_listening_score,setOetListeningScore]=useState<string>('');
  const [oet_reading_score,setOetReadingScore]=useState<string>('');
  const [oet_writing_score,setOetWritingScore]=useState<string>('');
  const [oet_speaking_score,setOetSpeakingScore]=useState<string>('');
  const [oet_overall_score,setOetOverallScore]=useState<string>('');
  const [submission,setSubmission]=useState<boolean>(false);
    const userid=id;
  var isloggedIn=localStorage.getItem("isLoggedIn");
  if (isloggedIn !== "true") {
      
    window.location.href='/';
  }
 
  useEffect(() => {
   
  server.get(`/operationuser/${id}`)
  .then((response)=>{
    console.log(response)
    if(response.data.code===200){
    
      setLanguageTestTaken(parseInt(response.data.user.language_test_taken))
      setLanguageTestType(parseInt(response.data.user.language_test_type))
      setLanguageTestDate(response.data.user.date_of_test)
      setIELTStrfNo(response.data.user.ielts_trf_number)
      if(response.data.user.ielts_trf_number===null||response.data.user.ielts_trf_number===""){
        setIELTStrfNo("")
      }
      setIELTSListeningScore(response.data.user.ielts_listening_score)
      setIELTSReadingScore(response.data.user.ielts_reading_score)
      setIELTSWritingScore(response.data.user.ielts_writing_score)
      setIELTSSpeakingScore(response.data.user.ielts_speaking_score)
      setIELTSOverallScore(response.data.user.ielts_overall_score)
      
      if(response.data.user.oet_candidate_number===null||response.data.user.oet_candidate_number===""){
        setOetCandidateNumber('')
      }else{
        setOetCandidateNumber(response.data.user.oet_candidate_number)
      }
      setOetListeningScore(response.data.user.oet_listening_score)
      setOetReadingScore(response.data.user.oet_reading_score)
      setOetWritingScore(response.data.user.oet_writing_score)
      setOetSpeakingScore(response.data.user.oet_speaking_score)
      setOetOverallScore(response.data.user.oet_overall_score)
    }
})
  }, []);
  
  console.log('oet_candidate_number',oet_candidate_number)
  const LanguageTestDetails=(e:React.FormEvent<HTMLFormElement>)=>{
    e.preventDefault();
    // let userid= localStorage.getItem('ClientID');
   
   

    if((language_test_taken===0))
    {
      toast('Select whether test taken');
    }else if(language_test_taken===1 && language_test_type===0)
    {
      toast('Choose the language test type');
    }else if(language_test_type===1 && language_test_date===null){
      toast('Enter the date of the test');
    }else if(language_test_type===1 && language_test_date===""){
      toast('Enter the date of the test');
    }
    else if(language_test_type===1 && ielts_trf_number==="")
    {
      toast('Enter the ielts trf number');
    }
    else if(language_test_type===1 && ielts_listening_score===""){
      toast('Choose IElTS Listening Score')
    }else if(language_test_type===1 && ielts_listening_score===null){
      toast('Choose IElTS Listening Score')
    } else if(language_test_type===1 && ielts_reading_score===""){
      toast('Select IElTS Reading Score')
    } else if(language_test_type===1 && ielts_reading_score===null){
      toast('Select IElTS Reading Score')
    }else if(language_test_type===1 && ielts_writing_score===""){
      toast('Select IElTS Writing Score')
    }else if(language_test_type===1 && ielts_writing_score===null){
      toast('Select IElTS Writing Score')
    }else if(language_test_type===1 && ielts_speaking_score===""){
      toast('Select IElTS Speaking Score')
    }else if(language_test_type===1 && ielts_speaking_score===null){
      toast('Select IElTS Speaking Score')
    }else if(language_test_type===1 && ielts_overall_score===""){
      toast('Select IElTS Overall Score')
    }else if(language_test_type===1 && ielts_overall_score===null){
      toast('Select IElTS Overall Score')
    }
    else if(language_test_type===2 && oet_candidate_number===""){
     
      toast('Enter the oet candidate number with 10digits !')
    } else if(language_test_type===2 && oet_candidate_number===null){
      toast('Enter the oet candidate number with 10digits !')
    }else if(language_test_type===2 && oet_listening_score===""){
      toast('Enter Proper OET Listening Score with 3 digits')
    }else if(language_test_type===2 && oet_listening_score===null){
      toast('Enter Proper OET Listening Score with 3 digits')
    }  else if(language_test_type===2 && oet_reading_score===""){
      toast('Enter Proper OET Reading Score with 3 digits')
    }else if(language_test_type===2 && oet_reading_score===null){
      toast('Enter Proper OET Reading Score with 3 digits')
    }else if(language_test_type===2 && oet_writing_score===""){
      toast('Enter Proper OET Writing Score with 3 digits')
    }else if(language_test_type===2 && oet_writing_score===null){
      toast('Enter Proper OET Writing Score with 3 digits')
    }else if(language_test_type===2 && oet_speaking_score===null){
      toast('Enter Proper OET Speaking Score with 3 digits')
    }else if(language_test_type===2 && oet_overall_score===""){
      toast('Enter Proper OET Overall Score with 3 digits')
    }else if(language_test_type===2 && oet_overall_score===null){
      toast('Enter Proper OET Overall Score with 3 digits')
    }
    else if(language_test_type===2 && oet_candidate_number.toString().length!==10){
      toast('Enter the oet candidate number with 10digits !')
    } else if(language_test_type===2 && oet_listening_score.toString().length!==3){
      toast('Enter Proper OET Listening Score with 3 digits')
    }  else if(language_test_type===2 && oet_reading_score.toString().length!==3){
      toast('Enter Proper OET Reading Score with 3 digits')
    }else if(language_test_type===2 && oet_writing_score.toString().length!==3){
      toast('Enter Proper OET Writing Score with 3 digits')
    }else if(language_test_type===2 && oet_speaking_score.toString().length!==3){
      toast('Enter Proper OET Speaking Score with 3 digits')
    }else if(language_test_type===2 && oet_overall_score.toString().length!==3){
      toast('Enter Proper OET Overall Score with 3 digits')
    }else{

      var form = document.getElementById('language_test_details_form') as HTMLFormElement;
      var formData= new FormData(form)
      setLoading(true)
      setSubmission(true)
      server.post(`/update_language_test_details/${id}`,formData)
      .then((response)=>{
        if(response.data.code===200){
          setLoading(false)
          
         
          
        }else{
          setLoading(false)
          toast(response.data.message)
        }
      })
    }

  }

  
  if (loading) {
    return <Loader />;
  } 
  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}


 
 
<div className='user_foarm_board userpath_right_pad pt-2'>
        <h3 className='blue-color fw-bold my-4'> Language Test Details</h3>
          <form className='w-100' id="language_test_details_form" onSubmit={LanguageTestDetails} >
          <input type='hidden' name="form_progress" id="form_progress" value={4}/>
            <div className='row'>
                <div className='col-md-4'>
                   <label className="blue-color fw-bold">Have you passed IELTS/OET exam?<b className="text-danger">*</b></label> 
                   <select className='form-control rounded user_input_dashboard' id="language_test_taken" name="language_test_taken" value={language_test_taken} onChange={(e)=>setLanguageTestTaken(parseFloat(e.target.value))} >
                    <option value="0">Select Choice</option> 
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                   </select>
                </div>
                {language_test_taken===1?<div className='col-md-4'>
                <label className="blue-color fw-bold">Language test type<b className="text-danger">*</b></label> 
                <select className='form-control rounded user_input_dashboard' id="language_test_type" name="language_test_type" value={language_test_type} onChange={(e)=>setLanguageTestType(parseFloat(e.target.value))} >
                    <option value="0">Select Test Type</option> 
                    <option value="1">IELTS</option>
                    <option value="2">OET</option>
                </select>
                </div>:""}
                {language_test_taken===1?<div className='col-md-4'>
                <label className="blue-color fw-bold">Test Date<b className="text-danger">*</b></label> 
                  <input type="date" className='form-control rounded user_input_dashboard' name="language_test_date" id="language_test_date" value={language_test_date} onChange={(e)=>setLanguageTestDate(e.target.value)} />
                </div>:""}
                
                
            </div>
           {language_test_taken===1 && language_test_type===1?<div className='row  mt-5'>
                <div className='col-md-2'>
                   <label className="blue-color fw-bold">TRF Number<b className="text-danger">*</b></label> 
                   <input type="text"  className='form-control rounded user_input_dashboard' name="ielts_trf_number" id="ielts_trf_number" value={ielts_trf_number} onChange={(e)=>setIELTStrfNo(e.target.value)} required />
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold"> Listening Score<b className="text-danger">*</b></label> 
                <select className='form-control rounded user_input_dashboard' name="ielts_listening_score" id="ielts_listening_score" value={ielts_listening_score} onChange={(e)=>setIELTSListeningScore(e.target.value)}>
                  <option value="0">Choose Listening Score</option>
                  <option value="5">5</option>
                  <option value="5.5">5.5</option>
                  <option  value="6">6</option>
                  <option  value="6.5" >6.5</option>
                  <option  value="7" >7</option>
                  <option value="7.5" >7.5</option>
                  <option value="8">8</option>
                  <option value="8.5">8.5</option>
                  <option value="9">9</option>
                  <option value="9.5">9.5</option>

                </select>
                </div>
              <div className='col-md-2'>
                <label className="blue-color fw-bold">Reading Score<b className="text-danger">*</b></label> 
                <select className='form-control rounded user_input_dashboard' name="ielts_reading_score" id="ielts_reading_score" value={ielts_reading_score} onChange={(e)=>setIELTSReadingScore(e.target.value)}>
                <option value="0">Choose Reading Score</option>
                <option value="5">5</option>
                  <option value="5.5">5.5</option>
                  <option  value="6">6</option>
                  <option  value="6.5" >6.5</option>
                  <option  value="7" >7</option>
                  <option value="7.5" >7.5</option>
                  <option value="8">8</option>
                  <option value="8.5">8.5</option>
                  <option value="9">9</option>
                  <option value="9.5">9.5</option>
                </select>
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold">Writing Score<b className="text-danger">*</b></label> 
                <select className='form-control rounded user_input_dashboard' name="ielts_writing_score" id="ielts_writing_score" value={ielts_writing_score} onChange={(e)=>setIELTSWritingScore(e.target.value)} >
                <option value="0">Choose Writing Score</option>
                 <option value="5">5</option>
                  <option value="5.5">5.5</option>
                  <option  value="6">6</option>
                  <option  value="6.5" >6.5</option>
                  <option  value="7" >7</option>
                  <option value="7.5" >7.5</option>
                  <option value="8">8</option>
                  <option value="8.5">8.5</option>
                  <option value="9">9</option>
                  <option value="9.5">9.5</option>
                </select>
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold">Speaking Score<b className="text-danger">*</b></label> 
                <select className='form-control rounded user_input_dashboard' name="ielts_speaking_score" id="ielts_speaking_score" value={ielts_speaking_score} onChange={(e)=>setIELTSSpeakingScore(e.target.value)} >
                <option value="0">Choose Speaking Score</option>
                <option value="5">5</option>
                  <option value="5.5">5.5</option>
                  <option  value="6">6</option>
                  <option  value="6.5" >6.5</option>
                  <option  value="7" >7</option>
                  <option value="7.5" >7.5</option>
                  <option value="8">8</option>
                  <option value="8.5">8.5</option>
                  <option value="9">9</option>
                  <option value="9.5">9.5</option>
                </select>
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold">Overall Score<b className="text-danger">*</b></label> 
                <select className='form-control rounded user_input_dashboard' name="ielts_overall_score" id="ielts_overall_score" value={ielts_overall_score} onChange={(e)=>setIELTSOverallScore(e.target.value)} >
                <option value="0">Choose Overall Score</option>
                <option value="5">5</option>
                  <option value="5.5">5.5</option>
                  <option  value="6">6</option>
                  <option  value="6.5" >6.5</option>
                  <option  value="7" >7</option>
                  <option value="7.5" >7.5</option>
                  <option value="8">8</option>
                  <option value="8.5">8.5</option>
                  <option value="9">9</option>
                  <option value="9.5">9.5</option>
                </select>
                </div>
                
                
            </div>:language_test_taken===1 && language_test_type===2?<div className='row mt-5'>
                <div className='col-md-2'>
                   <label className="blue-color fw-bold">OET Candidate Number<b className="text-danger">*</b></label> 
                   <input type="number"  className='form-control rounded user_input_dashboard'name="oet_candidate_number" id="oet_candidate_number" value={oet_candidate_number} onChange={(e)=>setOetCandidateNumber(e.target.value)} />
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold"> Listening score<b className="text-danger">*</b></label> 
                <input type="number"className='form-control rounded user_input_dashboard' name="oet_listening_score" id="oet_listening_score" minLength={3} maxLength={3} value={oet_listening_score} onChange={(e)=>setOetListeningScore(e.target.value)} />
                </div>
              <div className='col-md-2'>
                <label className="blue-color fw-bold">Reading Score<b className="text-danger">*</b></label> 
                <input type="number" className='form-control rounded user_input_dashboard'name="oet_reading_score" id="oet_reading_score" value={oet_reading_score} onChange={(e)=>setOetReadingScore(e.target.value)} />
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold">Writing Score<b className="text-danger">*</b></label> 
                <input type="number"className='form-control rounded user_input_dashboard' name="oet_writing_score" id="oet_writing_score" value={oet_writing_score} onChange={(e)=>setOetWritingScore(e.target.value)} />
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold">Speaking Score<b className="text-danger">*</b></label> 
                <input type="number"className='form-control rounded user_input_dashboard' name="oet_speaking_score" id="oet_speaking_score" value={oet_speaking_score} onChange={(e)=>setOetSpeakingScore(e.target.value)} />
                </div>
                <div className='col-md-2'>
                <label className="blue-color fw-bold">Overall Score<b className="text-danger">*</b></label> 
                <input type="number" className='form-control rounded user_input_dashboard' name="oet_overall_score" id="oet_overall_score" value={oet_overall_score} onChange={(e)=>setOetOverallScore(e.target.value)} />
                </div>
              
                
                
            </div>:""}
           
            <div className='my-5 text-center'><button type="submit" disabled={submission === true} className='btn bg-blue text-white w-25'>Update</button></div>
            <ToastContainer/>
         </form>

        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
</div>
  )
}

export default UserlanguageTestEdiT