import React,{useState,useEffect} from 'react'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
import {Link} from 'react-router-dom';
import axios from 'axios';
import UserSideBar from '../../pages/UserSideBar/UserSideBar';
import UserHeader from '../../pages/UserHeader/UserHeader';
import {UserData} from '../../@types/usertype';
import validator from 'validator';
import server from '../Utils';
import { citytype } from '../../@types/citytype';
import { countrytype } from '../../@types/contrytype';
import { statetype } from '../../@types/statetype';
import Loader from './Loader';
import './UserAddress.css';
import UserProgressBar from '../UserProgressBar';
import Swal from 'sweetalert2';

const UserAddress = () => {
  var loggedIn=localStorage.getItem('loggedIn');
  const [first_name,setfirstName]=useState<string>("");
  const [last_name,setlastName]=useState<string>('');
  const [user_email,setUserEmail]=useState<string>('')
    const [current_door_no,setCurrentDoorNo]=useState<string>('');
    const [current_street,setCurrentStreet]=useState<string>('');
    const [current_locality,setCurrentLocality]=useState<string>('');
    const [current_city,setCurrentCity]=useState<number>(0);
    const [current_country,setCurrentCountry]=useState<number>(0);
    const [current_postal_code,setCurrentPostalCode]=useState<string>('');
    const [loading, setLoading] = useState(false);

    const [permanent_door_no,setPermanentDoorNo]=useState<string>('');
    const [permanent_street,setPermanentStreet]=useState<string>('');
    const [permanent_locality,setPermanentLocality]=useState<string>('');
    const [permanent_city,setPermanentCity]=useState<number>(0);
    const [permanent_state,setPermanentState]=useState<number>(0);
    const [permanent_country,setPermanentCountry]=useState<number>(0);
    const [permanent_postal_code,setPermanentPostalCode]=useState<string>('');
    const [address_same_flag,setAddressSameFlag]=useState<number>(0);
    const [current_countries,setCurrentCountries]=useState<Array<countrytype>>([]);
    const [permanent_countries,setPermanentCountries]=useState<Array<countrytype>>([]);
    const [country,setCountry]=useState<number>(0)
    const [states,setStates]=useState<Array<statetype>>([]);
    const [permanent_states,setPermanentStates]=useState<Array<statetype>>([]);
    const [current_state,setCurrentState]=useState<number>(0);
    const [cities,setCities]=useState<Array<citytype>>([]);
    const [permanent_cites,setPermanentCities]=useState<Array<citytype>>([]);
    const [city,setCity]=useState<number>(0);
    const [form_progress,setFormProgress]=useState<number>(0);
    const [submission,setSubmission]=useState<boolean>(false);
  if(loggedIn!=="true")
  {
    window.location.href="/userlogin";
  }
  useEffect(() => {
    let userid= localStorage.getItem('ClientID');
    server.get(`/get_countries`)
  .then((response)=>{
    setCurrentCountries(response.data.countries)
  })
  server.get(`/get_countries`)
  .then((response)=>{
    setPermanentCountries(response.data.countries)
  })
  setLoading(true)
  server.get(`/operationuser/${userid}`)
  .then((response)=>{
    setfirstName(response.data.user.first_name)
    setlastName(response.data.user.last_name)
    setUserEmail(response.data.user.user_email)
    setCurrentDoorNo(response.data.user.current_door_no)
    setCurrentStreet(response.data.user.current_street)
    setCurrentLocality(response.data.user.current_locality)
    setCurrentCountry(response.data.user.current_country)
    if(response.data.user.current_country!==null){
    setCurrentStatesArray(response.data.user.current_country)
    }
    setCurrentState(response.data.user.current_state)
    if(response.data.user.current_state !==null){
    setCurrentCityArray(response.data.user.current_state)
    }
    setCurrentCity(response.data.user.current_city)
    setCurrentPostalCode(response.data.user.current_postal_code)
    setPermanentDoorNo(response.data.user.permanent_door_no)
    setPermanentStreet(response.data.user.permanent_street)
    setPermanentLocality(response.data.user.permanent_locality)
    setPermanentCountry(response.data.user.permanent_country)
    if(response.data.user.permanent_country !==null){
    setPermanentStatesArray(response.data.user.permanent_country)
    }
    
    setPermanentState(response.data.user.permanent_state)
      if(response.data.user.permanent_state!==null){
        setPermanentCityArray(response.data.user.permanent_state)
      }
    setPermanentCity(response.data.user.permanent_city) 
    setPermanentPostalCode(response.data.user.permanent_postal_code)
    if(response.data.user.form_progress!==null){
      setFormProgress(response.data.user.form_progress)
    }else{
      setFormProgress(0)
    }
setLoading(false)

  })
  
  }, []);

const setPermanentAddress = () => {
  const isSameAddress = address_same_flag === 0;
  setAddressSameFlag(isSameAddress ? 1 : 0);

  if (isSameAddress) {

    setPermanentDoorNo(current_door_no);
    setPermanentStreet(current_street);
    setPermanentLocality(current_locality);

    setPermanentCountry(current_country);
    setPermanentStatesArray(current_country);
    
    setPermanentState(current_state);
    setPermanentCityArray(current_state);
    setPermanentCity(current_city);
   
    setPermanentPostalCode(current_postal_code);

  } else {
    setPermanentDoorNo('');
    setPermanentStreet('');
    setPermanentLocality('');
    setPermanentCity(0);
    setPermanentState(0);
    setPermanentCountry(0);
    setPermanentPostalCode('');
  }
};

const setPermanentStatesArray = (id: number) => {
  setPermanentCountry(id);

  server.get(`/get_states/${id}`)
    .then((response) => {
      setPermanentStates(response.data.states);
    })
    .catch((error) => {
      console.error('Error fetching states:', error);
    });
};

const setPermanentCityArray = (id: number) => {
  setPermanentState(id);

  server.get(`/get_cities/${id}`)
    .then((response) => {
      setPermanentCities(response.data.cities);
    })
    .catch((error) => {
      console.error('Error fetching cities:', error);
    });
};
const setCurrentStatesArray = (id: number) => {
  setCurrentCountry(id);
  server.get(`/get_states/${id}`)
    .then((response) => {
      setStates(response.data.states);
    })
    .catch((error) => {
      console.error('Error fetching states:', error);
    });
};

const setCurrentCityArray = (id: number) => {
  setCurrentState(id);
  server.get(`/get_cities/${id}`)
    .then((response) => {
      setCities(response.data.cities);
    })
    .catch((error) => {
      console.error('Error fetching cities:', error);
    });
};


 const addUserAddress=(e:React.FormEvent<HTMLFormElement>)=>{
    e.preventDefault();
    const re = /^[A-Za-z\s]*$/;
    const ne =/^[0-9]+$/;
    var form = document.getElementById('user_address_details_form') as HTMLFormElement;
    let userid= localStorage.getItem('ClientID');
    var formData=new FormData(form);
 
    if(current_country===0){
      toast.error('select current country');
    }else if(current_state===0){
      toast.error('select current state');
    }else if( permanent_country===0){
      toast.error('select permanent country');
    }else if(permanent_state===0){
      toast.error('select permanent state');
    }
    
else{
  setLoading(true)
  setSubmission(true)
    server.post(`/add_address_details/${userid}`,formData)
   .then((response)=>{
   
    if(response.data.code===200){
      setLoading(false)
      toast('Address Added Successfully');
      if(loading===false){
      window.location.href="/pmq_details";
      }
    }else{
      setLoading(false)
      toast('Address Couldnt be Added')
    }
   })
  }
 }

 const saveDetails=()=>{
  const re = /^[A-Za-z\s]*$/;
    const ne =/^[0-9]+$/;
    var form = document.getElementById('user_address_details_form') as HTMLFormElement;
    let userid= localStorage.getItem('ClientID');
    var formData=new FormData(form);
 
    if(current_country===0){
      toast.error('select current country');
    }else if(current_state===0){
      toast.error('select current state');
    }else if(current_city===0){
      toast.error('select current city');
    }
    else if( permanent_country===0){
      toast.error('select permanent country');
    }else if(permanent_state===0){
      toast.error('select permanent state');
    }else if(permanent_city===0){
      toast.error('select permanent city');
    }
    
else{
  setLoading(true)
    server.post(`/add_address_details/${userid}`,formData)
   .then((response)=>{
   
    if(response.data.code===200){
      setLoading(false)
      Swal.fire({
        title: "Details Saved!",
        text: "Address Saved Successfully",
        icon: "success"
      });
     
    }else{
      setLoading(false)
      toast('Address Couldnt be Added')
    }
   })
  }
 }
 if (loading) {
  return <Loader />;
}
  return (
 

    <div className='container-fluid desktop_container' >
    <UserSideBar user_email={user_email} first_name={first_name} last_name={last_name}/>
    <div className='horizontal-menu' style={{background:"transparent"}}>
    <div className="container-fluid mt-3"><br /></div>
      <div className='container-fluid mt-3'>
      <div className='row mt-3'>
          <div className='col-md-3 text-center px-4'><h2 className='blue-color'><b>Address</b></h2></div>
          <div className='col-md-9 mt-2 px-5'><UserProgressBar form_progress={form_progress}/></div>
      </div>
       
        <br/>
        <div className='user_foarm_board userpath_right_pad pt-1 mt-5'>
          <form className='w-100' id="user_address_details_form" onSubmit={addUserAddress}>
          <input type='hidden' name="form_progress" id="form_progress" value={3}/>
            <div className='row'>
            
                <div className='col-md-5'>
                    <h3 className='blue-color fw-bold'>Current Address</h3>
                    <br/>
                    <br/>
                    <div className='form-group mt-1'>
                        <label className="blue-color fw-bold">Current Door Number<b className="text-danger">*</b></label>
                        <input className="form-control rounded user_input_dashboard"  type="text" name="current_door_no" id="current_door_no" placeholder='Current door number' value={current_door_no} onChange={(e)=>setCurrentDoorNo(e.target.value)} required />
                    </div>
                    <div className='form-group'>
                    <label className="blue-color fw-bold">Current Street<b className="text-danger">*</b></label>
                     <input className="form-control rounded user_input_dashboard" type="text" name="current_street" id="current_street" placeholder='Current street' value={current_street} onChange={(e)=>setCurrentStreet(e.target.value)}  required/>
                    </div>
                    <div className='form-group'>
                    <label className="blue-color fw-bold">Current Locality<b className="text-danger">*</b></label>
                     <input className="form-control rounded user_input_dashboard" type="text" name="current_locality" id="current_locality" placeholder='Current locality' value={current_locality} onChange={(e)=>setCurrentLocality(e.target.value)} required/>
                    </div>
                    <div className='form-group'>
              <label className="blue-color fw-bold">Country<b className="text-danger">*</b></label>
              {/* <input className="form-control rounded user_input_dashboard"  type="text" name="country" id="country" value={country} onChange={(e)=>setCountry(e.target.value)}  required /> */}
              <select className='form-control rounded user_input_dashboard'  name="current_country" id="current_country" value={current_country} onChange={(e)=>setCurrentStatesArray(parseInt(e.target.value))}  required>
                        <option value={0}>Select Country</option>
                        {current_countries.map((country,index)=>(
                          <option value={country.id}>{country.name}</option>
                        ))}
              </select>
            </div>
            <div className='form-group'>
            <label className="blue-color fw-bold">State<b className="text-danger">*</b></label>
              {/* <input className="form-control rounded user_input_dashboard" type="text" name="state" id="state" value={currentstate} onChange={(e)=>setCurrentState(e.target.value)}   required/> */}
              <select className='form-control rounded user_input_dashboard' name="current_state" id="current_state" value={current_state} onChange={(e)=>setCurrentCityArray(parseInt(e.target.value))}   required>
              <option value={0}>Select State</option>
                        {states.map((state,index)=>(
                          <option value={state.id}>{state.name}</option>
                        ))}

              </select>
            </div>
            <div className='form-group'>
            <label className="blue-color fw-bold">City<b className="text-danger">*</b></label>
              {/* <input className="form-control rounded user_input_dashboard" type="city" name="city" id="city" value={city} onChange={(e)=>setCity(e.target.value)}  required /> */}
              <select className="form-control rounded user_input_dashboard" name="current_city" id="current_city" value={current_city} onChange={(e)=>setCurrentCity(parseInt(e.target.value))}  >
              <option value={0}>Select City</option>
                        {cities.map((city,index)=>(
                          <option value={city.id}>{city.name}</option>
                        ))}
              </select>
            </div>
                    <div className='form-group'>
                    <label className="blue-color fw-bold">Current Postal Code<b className="text-danger">*</b></label>
                    <input className="form-control rounded user_input_dashboard" type="text" name="current_postal_code" id="current_postal_code" value={current_postal_code} placeholder='Current postal ' onChange={(e)=>setCurrentPostalCode(e.target.value)} required/>
                    </div>
                </div>
                <div className='col-md-1'></div>
                <div className='col-md-5 pt-2'>
                <h3 className='blue-color fw-bold'>Permanent Address</h3>

                    <input type='checkbox' name="address_same_flag"  id="address_same_flag" checked={address_same_flag===1}  onChange={(e)=>{setAddressSameFlag(address_same_flag===0?1:address_same_flag===1?0:0);setPermanentAddress()}}  /><label className="blue-color fw-bold">&nbsp;&nbsp;&nbsp;Same as Current Address</label>
                    <br/>
                    
                    <div className='form-group'>
                    <label className="blue-color fw-bold">Permanent Door Number<b className="text-danger">*</b></label>
                        <input className="form-control rounded user_input_dashboard" type="text" name="permanent_door_no" id="permanent_door_no" placeholder='Permanent door number' value={permanent_door_no} onChange={(e)=>setPermanentDoorNo(e.target.value)}  required/>
                    </div>
                    <div className='form-group'>
                    <label className="blue-color fw-bold">Permanent Street<b className="text-danger">*</b></label>
                     <input className="form-control rounded user_input_dashboard" type="text" name="permanent_street" id="permanent_street" placeholder='Permanent street' value={permanent_street} onChange={(e)=>setPermanentStreet(e.target.value)} required />
                    </div>
                    <div className='form-group'>
                    <label className="blue-color fw-bold">Permanent Locality<b className="text-danger">*</b></label>
                     <input className="form-control rounded user_input_dashboard" type="text" name="permanent_locality" id="permanent_locality" placeholder='Permanent locality' value={permanent_locality} onChange={(e)=>setPermanentLocality(e.target.value)}  required/>
                    </div>
                    <div className='form-group'>
              <label className="blue-color fw-bold">Country<b className="text-danger">*</b></label>
              {/* <input className="form-control rounded user_input_dashboard"  type="text" name="country" id="country" value={country} onChange={(e)=>setCountry(e.target.value)}  required /> */}
              <select className='form-control rounded user_input_dashboard'  name="permanent_country" id="permanent_country" value={permanent_country} onChange={(e)=>setPermanentStatesArray(parseInt(e.target.value))}  required>
                        <option value={0}>Select Country</option>
                        {permanent_countries.map((country,index)=>(
                          <option value={country.id}>{country.name}</option>
                        ))}
              </select>
            </div>
            <div className='form-group'>
            <label className="blue-color fw-bold">State<b className="text-danger">*</b></label>
              {/* <input className="form-control rounded user_input_dashboard" type="text" name="state" id="state" value={currentstate} onChange={(e)=>setCurrentState(e.target.value)}   required/> */}
              <select className='form-control rounded user_input_dashboard' name="permanent_state" id="permanent_state" value={permanent_state} onChange={(e)=>setPermanentCityArray(parseInt(e.target.value))}   required>
              <option value={0}>Select State</option>
                        {permanent_states.map((state,index)=>(
                          <option value={state.id}>{state.name}</option>
                        ))}

              </select>
            </div>
            <div className='form-group'>
            <label className="blue-color fw-bold">City<b className="text-danger">*</b></label>
              {/* <input className="form-control rounded user_input_dashboard" type="city" name="city" id="city" value={city} onChange={(e)=>setCity(e.target.value)}  required /> */}
              <select className="form-control rounded user_input_dashboard" name="permanent_city" id="permanent_city" value={permanent_city} onChange={(e)=>setPermanentCity(parseInt(e.target.value))}  >
              <option value={0}>Select City</option>
                        {permanent_cites.map((city,index)=>(
                          <option value={city.id}>{city.name}</option>
                        ))}
              </select>
            </div>
                    <div className='form-group'>
                    <label className="blue-color fw-bold">Permanent Postal Code<b className="text-danger">*</b></label>
                    <input className="form-control rounded user_input_dashboard" type="text" name="permanent_postal_code" id="permanent_postal_code" value={permanent_postal_code} placeholder='Permanent postal code' onChange={(e)=>setPermanentPostalCode(e.target.value)}  />
                    </div>
                </div>
               
            </div>  
            <div className='mt-5 user_align_mobile'><Link to="/user_profile" className='btn bg-blue text-white'>Previous</Link><span className='span_class'>&nbsp;&nbsp;&nbsp;</span><button type="button" onClick={()=>saveDetails()} className='btn bg-blue text-white'>Save</button><span className='span_class'>&nbsp;&nbsp;&nbsp;</span><button type="submit" disabled={submission === true} className='btn bg-blue text-white'>Save & Next</button></div>
            <br/>
            <br/>
            <ToastContainer/>
         </form>

        </div>
        </div>
     
     

    </div>
      {/* <div className='container-fluid' >
              <div className='headerbar' style={{width:"100%",left:"0"}}>
          <UserHeader />
      <h4 className='text-danger text-center'> Address</h4>
      <hr className='bg-danger px-1' />
        
          </div>
      
            
      
     
  </div> */}
  </div>
    
  )
}

export default UserAddress