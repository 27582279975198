import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';

import {Link,useParams} from 'react-router-dom';

import { ProjectData } from '../../@types/projecttype';
import { StageData } from '../../@types/stagetype';
import { InstallmentTypeData } from '../../@types/installmenttypes';
import { Select, message } from 'antd';

import { admintype as Admintype } from '../../@types/admintype';
import { welcomekittype } from '../../@types/welcomekitdata';
import $ from 'jquery'; 

import validator from 'validator';
import { off } from 'process';
import { type } from 'os';
import { vendortype } from '../../@types/vendortype';
import { ServiceCategoryType } from '../../@types/service_category_type';
import { ServiceData } from '../../@types/servicetype';
import { count } from 'console';
import {service_categorytypedata} from '../../@types/service_categorytypedata';
import server from '../Utils';
import { countrytype } from '../../@types/contrytype';
import { statetype } from '../../@types/statetype';
import { citytype } from '../../@types/citytype';
import UserInfo from '../UserInfo';
import Loader from './Loader';
import { multipleusertype } from '../../@types/multipleusertype';

const AddMultipleTier4Visa = () => {
        
   
   
    const [project_id,setProjectId] = useState<number>(0);
    const [product_id,setProductId] = useState<number>(0);

    const [sendEmail,setSendEmail] = useState<boolean>(false);
    const [applicationStatus, setApplicationStatus] = useState<number>(0);
    const [appointmentDate, setAppointmentDate] = useState<string>('');
    const [visaStatus, setVisaStatus] = useState<number>(0);
    const [visaDuration, setVisaDuration] = useState<number>(0)
    const [visaPeriod,setVisaPeriod]=useState<number>(0)
    const [notes,setNotes]=useState<string>('')
    const [visa_issued_date,setVisaIssuedDate]=useState<string>('')
    const [vendors,setVendors]=useState<Array<vendortype>>([]);
    const [vendor_id,setVendorId]=useState<number>(0)
    
    const [loading, setLoading] = useState(false);
    const [users,setUsers]=useState<Array<multipleusertype>>([]);
    const [user_id,setUserId]=useState<number>(0)
    

const [products,setProducts]=useState<Array<ProductData>>([])

// var options: SelectProps['options'] = [];

const [error,setError]=useState<boolean>(false);
const [count,setCount]=useState<number>(0);
const [userError,setUserError]=useState<string>('')
let  admin_id:string=JSON.parse(localStorage.getItem("admin_id")||'{}');
// var options: SelectProps['options'] = [];
const [selectedValue,setSelectedValue]=useState<Array<multipleusertype>>([])


    useEffect(() => {
      let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
      let id = +projectid;
      setProjectId(id)
      server.get(`/get_product/${id}`)
      .then((response)=>{
          
          setProducts(response.data.product)
          
      })
      let productid:string=JSON.parse(localStorage.getItem("product_id")||'{}');
      let product_id = +productid;
      // setProductId(product_id);
    
      server.get('get_operationuser')
      .then((response)=>{
        setUsers(response.data.users)
         
      }) 
    server.get(`/vendor_for_visa/${projectid}/${product_id}`)
    .then((response)=>{
      setVendors(response.data.vendors)
    })
      
     
    }, []);
   
    const getCurrentDate = () => {
      const today = new Date();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const year = today.getFullYear();
  
      const formattedMonth = month < 10 ? `0${month}` : `${month}`;
      const formattedDay = day < 10 ? `0${day}` : `${day}`;
  
      return `${year}-${formattedMonth}-${formattedDay}`;
    };
    const addTier4Visa = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      
  
      if(user_id===0){
        toast('select the user')
      } // Individual field validations
      else if (!validator.isInt(vendor_id.toString()) || vendor_id <= 0) {
        toast.error('Please select a vendor.');
      } else if (applicationStatus === 3 && appointmentDate === "") {
        toast.error('Please enter a valid appointment date.');
      } else if (applicationStatus === 3 && visaStatus === 0) {
        toast.error('Please select a valid Visa Status.');
      } else if (applicationStatus === 3 && visaStatus===2 && visaDuration <= 0) {
        toast.error('Please enter a valid visa duration.');
      } else if (applicationStatus === 3 && visaStatus === 2 && visa_issued_date !== "" && new Date(appointmentDate) > new Date(visa_issued_date)) {
        toast.error('Appointment date should be before visa issue date.');
      }else {
        // setLoading(true)
        // Your existing form submission logic here
        var form = document.getElementById('addtrainingdetails') as HTMLFormElement;
        var formData = new FormData(form);
        server.post(`/add_tier4visa`, formData)
          .then((response) => {
            if (response.data.code === 200) {
              // setLoading(false)
              toast(response.data.message);
              setTimeout(function () { window.history.back(); }, 1000);
            } else {
              // setLoading(false)

              toast.error(response.data.message);
            }
          });
      }
    };
    
    
// const setStatesArray=(id:number)=>{
//   setCountry(id)
//   server.get(`/get_states/${id}`)
//   .then((response)=>{
//      setStates(response.data.states)
//   })
// }
// const setCityArray=(id:number)=>{
//   setCurrentState(id)
//   server.get(`/get_cities/${id}`)
//   .then((response)=>{
//      setCities(response.data.cities)
//   })
// }

if (loading) {
  return <Loader />;
}

const onChange = (value:number) => {
  setUserId(value)
 };

 let options = [];

for (let i = 0; i < users.length; i++) {
options.push({
   value: users[i].id,
   label: users[i].user
});
}

const setallUsers=(id:number)=>{
  setUserError('loading the users..')
  setProductId(id)
  setUserId(0)
  setError(true);
  setCount(count+1)
server.get(`get_operationuser/${id}`)
      .then((response)=>{
        setUsers(response.data.users)
       if(response.data.users.length===0){
          setUserError('There are no users for this project');
       }else{
          setUserError('')
       }
      })

}

 // Filter `option.label` match the user type `input`
 const filterOption = (input:any, option:any) =>
   (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  return (
    <div className='container-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}
{/* 
      <div className="d-sm-flex align-items-center justify-content-between mb-4">

     </div> */}
{/* <UserInfo/> */}
    <h4 className='text-danger text-center'>Add Tier 4 Visa </h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5 jumbotron mx-3'>
   
  <div className="row">
        <div className="col-md-6">
     
        {project_id!==0?<div className='col-md-12'>
            <label >Products</label>
                     <select className='form-control' name='product_id' id="product_id" value={product_id} onChange={(e)=>setallUsers(parseInt(e.target.value))} required>
                        <option value={0}>Select Product</option>
                        {products.map((product)=>(
                            <option value={product.id}>{product.product_name}</option>))}
            </select>
            </div>:""}
      
        </div>
        <div className="col-md-6">

       

        {product_id !== 0 && (
                users.length !== 0 ? (
                  <div>
                    <label>Users</label>
                    <Select
showSearch
style={{ width: '100%' }}
placeholder="Select a person"
optionFilterProp="children"
onChange={onChange}
// onSearch={onSearch}
filterOption={filterOption}
options={options}
/>
                  </div>
                ) : (
                  <p className="text-danger">
                    {userError}
                  </p>
                )
              )}

        </div>
        </div>
    <div className='col-md-12 px-5'>
   
  <br/>
  <br/>
       {product_id!==0? <form id="addtrainingdetails" onSubmit={addTier4Visa}  encType='multipart/form-data' className='w-100'>
        <div className="row">
          <input type="hidden" value={project_id} name="project_id"/>
          <input type="hidden" value={product_id} name="product_id"/>
          <input type="hidden" value={user_id} name="user_id"/>

          <div className="col-sm-12 col-md-3 mb-3">
            <div className="form-group">
              <label htmlFor="visaFile">Select Vendor:</label>
              <select className='form-control' id={"vendor_id"} name={"vendor_id"} value={vendor_id} onChange={(e)=>setVendorId(parseInt(e.target.value))} >
                <option value={0}>Select Vendor</option>
                {vendors.map((vendor)=>(
                  <option value={vendor.id}>{vendor.vendor_name}</option>
                ))}
              </select>
            </div>
          </div>
          {/* Checkbox for Sending Email to Visa Coordinator */}
          <div className="col-sm-12 col-md-3 mb-3">
          <label className="form-check-label" htmlFor="sendEmailCheckbox">
                Send Email to Visa Coordinator
              </label>
            <div className="form-check">
           
              <input
                type="checkbox"
                className="form-check-input"
                id="sendEmailCheckbox"
                checked={sendEmail}
                name="sendEmail"
                onChange={() => setSendEmail(!sendEmail)}
              />
              
            </div>
          </div>
            

          {/* Drop-down for Application Status */}
          <div className="col-sm-12 col-md-3 mb-3">
            <div className="form-group">
              <label htmlFor="applicationStatus">Application Status:</label>
              <select
                className="form-control"
                id="applicationStatus"
                value={applicationStatus}
                name="applicationStatus"
                onChange={(e) => setApplicationStatus(parseInt(e.target.value))}
              >
                <option value="1">Yet To Start</option>
                <option value="2">In Progress</option>
                <option value="3">Submitted</option>
              </select>
            </div>
          </div>

          {applicationStatus===3?<div className="col-sm-12 col-md-3 mb-3">
            <div className="form-group">
              <label htmlFor="appointmentDate">Visa Appointment Date:</label>
              <input
                type="date"
                className="form-control"
                id="appointmentDate"
                name="appointmentDate"
                value={appointmentDate}
                min={getCurrentDate()}
                onChange={(e) => setAppointmentDate(e.target.value)}
                required
              />
            </div>
          </div>:<div></div>}
          {/* Drop-down for Visa Status */}
          {applicationStatus===3? <div className="col-sm-12 col-md-3 mb-3">
            <div className="form-group">
              <label htmlFor="visaStatus">Visa Status:</label>
              <select
                className="form-control"
                id="visaStatus"
                value={visaStatus}
                name="visaStatus"
                onChange={(e) => setVisaStatus(parseInt(e.target.value))}
              >
                <option value="1">Processing</option>
                <option value="2">Approved</option>
                <option value="3">Rejected</option>
              </select>
            </div>
          </div>:<div></div>}
        </div>

        

      { visaStatus===2? <div className="row">
        <div className="col-sm-12 col-md-3 mb-3">
            <div className="form-group">
              <label htmlFor="appointmentDate">Visa Issue Date:</label>
              <input
                type="date"
                className="form-control"
                id="visa_issued_date"
                name="visa_issued_date"
                value={visa_issued_date}
                onChange={(e) => setVisaIssuedDate(e.target.value)}
                required
              />
            </div>
          </div>
          {/* Duration field for Visa Duration */}

          <div className="col-sm-12 col-md-4 mb-3">
          <div className='row'>
           <label htmlFor="visaDuration">Visa Duration:</label>
          <div className='col-md-3'>
              <div className="form-group">
             
              <input
                type="number"
                className="form-control"
                id="visaDuration"
                value={visaDuration}
                name="visaDuration"
                onChange={(e) => setVisaDuration(parseInt(e.target.value))}
                placeholder="e.g., 30 days"
                required
              />
            </div>
            </div>
           <div className='col-md-9'>
               <div className="form-group">
              
              <select
                className="form-control"
                id="visaPeriod"
                value={visaPeriod}
                name="visaPeriod"
                onChange={(e) => setVisaPeriod(parseInt(e.target.value))}
              >
                <option value="1">Days</option>
                <option value="2">Weeks</option>
                <option value="3">Months</option>
                <option value="4">Year</option>
              </select>
            </div>
          </div>
         
            
          </div>
          </div>
         
        

          {/* File Upload for Visa */}
          <div className="col-sm-12 col-md-3 mb-3">
            <div className="form-group">
              <label htmlFor="visaFile">Upload Visa:</label>
              <input
                type="file"
                className="form-control-file"
                id="visa_file"
                name="visa_file"
                accept=".pdf,.jpeg,.png,.JPG,.jpg,.JPEG"
                required
              />
            </div>
          </div>
         
          
        </div>:<div></div>}

        <div className='w-100'>
          <label>Notes</label>
            <textarea className='form-control w-100' name="notes" value={notes} onChange={(e)=>setNotes(e.target.value)}></textarea>
          </div>
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-info text-white mt-2'>Submit</button></div>
        </form>:""}
   
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default AddMultipleTier4Visa;