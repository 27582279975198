import React,{useState,useEffect} from 'react';
import Header from '../../pages/Header';
import SideBar from '../../pages/SideBar';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProductData } from '../../@types/producttype';
import { ProjectData } from '../../@types/projecttype';
import server from '../Utils';
import Loader from './Loader';

const AddStage = () => {
    const [products,setProducts]=useState<Array<ProductData>>([])
    const [projects,setProjects]=useState<Array<ProjectData>>([])
    const [project_id,setProjectId]=useState<number>(0);
    const [product_id,setProductId]=useState<number>(0);
    const [stage,setStage]=useState<string>();
    const [error,setError]=useState<string>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let  projectid:string=JSON.parse(localStorage.getItem("project_id")||'{}');
        let id= +projectid
        setProjectId(id)
        server.get(`/get_product/${id}`)
        .then((response)=>{
            
            setProducts(response.data.product)
            
        })
       
      }, []);
   
    const addStage=(e:React.FormEvent<HTMLFormElement>)=>{
        e.preventDefault();
        
        // var regEx = /^[A-Za-z]+$/;
        var name:any = stage;
        if(project_id===0)
        {
            setError("Please Select the project"); 
            toast(error);
        }
       else if(product_id===0)
       {
        setError("Please Select the product"); 
        toast(error);
       }
       
       else
        {
            setError('');
            var token=localStorage.getItem('auth_token');
            var form=document.getElementById('add_stage_form') as HTMLFormElement;
            var formdata=new FormData(form);
                

            server.post(`/add_stage`,formdata,{ headers: { 'Authorization': 'Bearer '+token } })
            .then((response)=>{
                if(response.data.code===200)
                {
                    toast(response.data.message)
                    setTimeout(function () { window.location.href = "/stages"; }, 1000);
                }else
                {
                    toast(response.data.message)
                }
            })
        }
    }
    // const setProject=(id:number)=>
    // {
    //     setProjectId(id);
    //     server.get(`/get_product/${id}`)
    //     .then((response)=>{
            
    //         setProducts(response.data.product)
            
    //     })
    // }
    
    if (loading) {
        return <Loader />;
      }


  return (
    <div className='conatiner-fluid'>
    <div className='d-flex'>
        <SideBar/>
        <div className='headerbar'>
        <Header/>
        {/* Content Row */}

    <h4 className='text-danger text-center'>Add Stage</h4>
    <hr className='bg-danger px-1' />
  <div className='row mt-5'>
    <div className='col-md-1'></div>
    <div className='col-md-8'>
      
        <form id="add_stage_form" className='w-100' onSubmit={addStage}>
          <div className='row'>
            <input type="hidden" name="project_id" value={project_id} id="project_id" />
            {/* <div className='col-md-4'>
            <select className='form-control' name='project_id' id="project_id" onChange={(e)=>setProject(parseInt(e.target.value))} required> 
                        <option value={0}>Select Project</option>
                        {projects.map((project)=>(
                            <option value={project.id}>{project.project_name}</option>))}
            </select>
            </div> */}
            {project_id!==0?<div className='col-md-4'>
            <select className='form-control' name='product_id' id="product_id" onChange={(e)=>setProductId(parseInt(e.target.value))} required>
                        <option value={0}>Select Product</option>
                        {products.map((product)=>(
                            <option value={product.id}>{product.product_name}</option>))}
            </select>
            </div>:""}
                            
            {product_id!==0? <div className='col-md-3'>
                    
                    <input className='form-control' type="text" placeholder='Enter Stage' value={stage} onChange={(e)=>setStage(e.target.value)} name="stage_name" id="stage_name" required />
                </div>:""}

          </div>
          
            <ToastContainer />
           <div className='text-center mt-4'> <button type="submit" className='btn btn-primary mt-2'>Submit</button></div>
        </form>
     
    </div>
  </div>
        </div>
        {/* header bar ends here */}
       
        {/* Content Row */}
          
    </div>
   
</div>
  )
}

export default AddStage